const platformList = {
  'idibu CV manager': 1229,
  'idibu Developer Board': 517,
  SEEK: 334,
};

export const categoryList = {
  Accounting: {
    catId: 1,
    SEEK_sec: '2WejkktVM',
    subCategories: {
      'Accounts Officers/Clerks': '3AXwHk3Km',
      'Accounts Payable': 'CTriSTrf',
      'Accounts Receivable/Credit Control': '3B2pTpXrP',
      'Analysis & Reporting': '3C2aoyWud',
      'Assistant Accountants': '3BXhdu2P1',
      'Audit - External': '13i3kvRd',
      'Audit - Internal': 'YbDqQxF',
      'Bookkeeping & Small Practice Accounting': '23UPuuUs',
      'Business Services & Corporate Advisory': '2YMZzQ1V',
      'Company Secretaries': '33Ek4tY7',
      'Compliance & Risk': '3Y7v9P4j',
      'Cost Accounting': '4316DsbM',
      'Financial Accounting & Reporting': '4XtGJN7y',
      'Financial Managers & Controllers': '52mSNreb',
      'Forensic Accounting & Investigation': '5XecTMBD',
      'Insolvency & Corporate Recovery': '62XnXqhq',
      'Inventory & Fixed Assets': '6XQxcLET',
      Management: '72J8gpm5',
      'Management Accounting & Budgeting': '7XBJmKHh',
      Payroll: '2cdSPDcy5',
      'Strategy & Planning': '824UqopK',
      'Systems Accounting & IT Audit': '8WwevJLw',
      Taxation: '91ppznsZ',
      Treasury: '9Wi15HQB',
      Other: 'A1bB9mvo',
    },
  },
  'Accountancy (Qualified)': {
    catId: 2,
    SEEK_sec: '',
    subCategories: {
      'Accounts Officers/Clerks': '3AXwHk3Km',
      'Accounts Payable': 'CTriSTrf',
      'Accounts Receivable/Credit Control': '3B2pTpXrP',
      'Analysis & Reporting': '3C2aoyWud',
      'Assistant Accountants': '3BXhdu2P1',
      'Audit - External': '13i3kvRd',
      'Audit - Internal': 'YbDqQxF',
      'Bookkeeping & Small Practice Accounting': '23UPuuUs',
      'Business Services & Corporate Advisory': '2YMZzQ1V',
      'Company Secretaries': '33Ek4tY7',
      'Compliance & Risk': '3Y7v9P4j',
      'Cost Accounting': '4316DsbM',
      'Financial Accounting & Reporting': '4XtGJN7y',
      'Financial Managers & Controllers': '52mSNreb',
      'Forensic Accounting & Investigation': '5XecTMBD',
      'Insolvency & Corporate Recovery': '62XnXqhq',
      'Inventory & Fixed Assets': '6XQxcLET',
      Management: '72J8gpm5',
      'Management Accounting & Budgeting': '7XBJmKHh',
      Payroll: '2cdSPDcy5',
      'Strategy & Planning': '824UqopK',
      'Systems Accounting & IT Audit': '8WwevJLw',
      Taxation: '91ppznsZ',
      Treasury: '9Wi15HQB',
      Other: 'A1bB9mvo',
    },
  },
  'Administration & Office Support': {
    catId: 3,
    SEEK_sec: 'vL7e3Yv3',
    subCategories: {
      'Administrative Assistants': 'vpzp83Sf',
      'Client & Sales Administration': 'wKszCXyH',
      'Contracts Administration': 'wpmAH2Vu',
      'Data Entry & Word Processing': 'xKeLMX2X',
      'Office Management': 'xpXWS1Z9',
      'PA, EA & Secretarial': 'yKQgWW5m',
      Receptionists: 'ypHrazcP',
      'Records Management & Document Control': 'zKB2fV91',
      Other: 'zp4Cjyfd',
    },
  },
  'Advertising, Arts & Media': {
    catId: 2778,
    SEEK_sec: '2Nisr9ctw',
    subCategories: {
      'Agency Account Management': '2PDm2E7RZ',
      'Art Direction': '2PieCJbxB',
      'Editing & Publishing': '2QDXNP6Uo',
      'Event Management': '2QiQYTb1R',
      'Journalism & Writing': '2RDHiY5Y3',
      Management: '2RiAtca4f',
      'Media Strategy, Planning & Buying': '2SD44h4bH',
      'Performing Arts': '2ShwEmZ7u',
      Photography: '2TCpQr3eX',
      'Programming & Production': '2ThhavYB9',
      Promotions: '2UCam12hm',
      Other: '2UhTw5XEP',
    },
  },
  Aerospace: { catId: 2776, SEEK_sec: '' },
  'Farming, Animals & Conservation': {
    catId: 2780,
    SEEK_sec: 'XRXeUvdV',
    subCategories: {
      'Agronomy & Farm Services': 'XvQpZRA7',
      'Conservation, Parks & Wildlife': 'YRHzdugj',
      'Farm Labour': 'YvBAiQDM',
      'Farm Management': 'ZR4Lntjy',
      'Fishing & Aquaculture': 'ZuwWsPGb',
      Horticulture: 'aQpgwsoD',
      'Veterinary Services & Animal Welfare': 'auhs2NKq',
      'Winery & Viticulture': 'bQb36rrT',
      Other: 'buUDBMP5',
    },
  },
  'Design & Architecture': {
    catId: 2782,
    SEEK_sec: '22JhiyTFV',
    subCategories: {
      'Architectural Drafting': '23JU58SJj',
      Architecture: '22oau3wn7',
      'Fashion & Textile Design': '24JERHRMy',
      'Graphic Design': '24o7bMutb',
      'Illustration & Animation': '23oMFCvqM',
      'Industrial Design': '25HzmSQRD',
      'Interior Design': '25nswWtwq',
      'Landscape Architecture': '26Hm7bPUT',
      'Urban Design & Planning': '26neHft15',
      'Web & Interaction Design': '27HXTkNXh',
      Other: '27nQdps4K',
    },
  },
  'Manufacturing, Transport & Logistics': {
    catId: 4,
    SEEK_sec: '2kdax2Ryy',
    subCategories: {
      'Analysis & Reporting': '2n8EUFuZq',
      'Assembly & Process Work': '2m8U86vWb',
      'Aviation Services': '2mdMJBR3D',
      'Couriers, Drivers & Postal Services': '2nd7eLQ6T',
      'Fleet Management': '2o7zpQtd5',
      'Freight/Cargo Forwarding': '2ocszVP9h',
      'Import/Export & Customs': '2p7mAZsgK',
      'Machine Operators': '2q7XWirjZ',
      Management: '2qcQgoMGB',
      'Pattern Makers & Garment Technicians': '2rcB2xLKR',
      'Pickers & Packers': '2s74D2pr3',
      'Production, Planning & Scheduling': '2r7Hrsqno',
      'Public Transport & Taxi Services': '2pceLeNCw',
      'Purchasing, Procurement & Inventory': '2u6auLnxX',
      'Quality Assurance & Control': '2sbwP7KNf',
      'Rail & Maritime Transport': '2ubU5RHV9',
      'Road Transport': '2v6MFVn1m',
      'Team Leaders/Supervisors': '2t6pZBouH',
      'Warehousing, Storage & Distribution': '2tbhjGJRu',
      Other: '2vbERaGYP',
    },
  },
  'Banking & Financial Services': {
    catId: 5,
    SEEK_sec: '2Y9PGzN5D',
    subCategories: {
      'Account & Relationship Management': 'FzBG5gGF',
      'Analysis & Reporting': 'GV4SAAns',
      'Banking - Business': 'HUpnK9r7',
      'Banking - Corporate & Institutional': 'HyhxPeNj',
      'Banking - Retail/Branch': 'GywcEfKV',
      'Client Services': 'cs6EEZj5',
      'Compliance & Risk': 'JUb8U8uM',
      'Corporate Finance & Investment Banking': 'JyUJYdRy',
      Credit: 'KUMUd7xb',
      'Financial Planning': 'xnPBLEqu',
      'Funds Management': '24kyGG9BM',
      Management: 'KyEehcVD',
      Mortgages: 'LU7pn71q',
      Settlements: 'LxzzrbYT',
      'Stockbroking & Trading': 'MTtAw655',
      Treasury: 'MxmM1abh',
      Other: 'NTeX658K',
    },
  },
  Construction: {
    catId: 7,
    SEEK_sec: '2Ze2oDqf5',
    subCategories: {
      'Contracts Management': '2w67bem51',
      Estimating: 'vHyJwnCo',
      'Foreperson/Supervisors': '2wazmjFbd',
      'Health, Safety & Environment': '2x5swok8F',
      Management: '2xam7tEes',
      'Planning & Scheduling': '2y5eHxjBV',
      'Plant & Machinery Operators': '2yaXU3Di7',
      'Project Management': '2z5Qe7iEj',
      'Quality Assurance & Control': '2zaHpCCmM',
      Surveying: '315AzGhHy',
      Other: '31a4AMBpb',
    },
  },
  'Call Centre & Customer Service': {
    catId: 8,
    SEEK_sec: '2YeGT4rbq',
    subCategories: {
      Collections: '2geXZQVm1',
      'Customer Service - Call Centre': '2h9QjUzHd',
      'Customer Service - Customer Facing': '2heHuZUpF',
      'Management & Support': '2i9B5dyLs',
      'Sales - Inbound': '2ie4FiTsV',
      'Sales - Outbound': '2j8wRnxQ7',
      'Supervisors/Team Leaders': '2jdpbsSvj',
      Other: '2k8hmwwTM',
    },
  },
  'Community Services & Development': {
    catId: 6,
    SEEK_sec: 'AWUMEGTR',
    subCategories: {
      'Aged & Disability Support': 'B1MXJkz3',
      'Child Welfare, Youth & Family Services': 'BWEhPFWf',
      'Community Development': 'C17sTk3H',
      'Employment Services': 'CW13YEZu',
      Fundraising: 'CztDcj6X',
      'Housing & Homelessness Services': 'DVmPhDd9',
      'Indigenous & Multicultural Services': 'DzeZmi9m',
      Management: 'EVXjrCgP',
      'Volunteer Coordination & Support': 'EzQuvhD1',
      Other: 'FVJ61Bjd',
    },
  },
  'Consulting & Strategy': {
    catId: 2784,
    SEEK_sec: '2cfUAnZY3',
    subCategories: {
      Analysts: '2dAMLs44f',
      'Corporate Development': '2dfEWwYbH',
      'Environment & Sustainability Consulting': '2eA7h237u',
      'Management & Change Consulting': '2eezs6XeX',
      Policy: '2f9t3B2B9',
      'Strategy & Planning': '2femDFWhm',
      Other: '2g9ePL1EP',
    },
  },
  'Government & Defence': {
    catId: 2786,
    SEEK_sec: '2bdZVXomZ',
    subCategories: {
      'Air Force': 'HwZdHsfV',
      Army: 'Mvd1uotT',
      'Emergency Services': 'qp2kFMTD',
      Government: '2pcktBCMD',
      'Government - Federal': '27FP8ebpT',
      'Government - Local': '27kGJj6M5',
      'Government - State': '28F9Uoash',
      'Local Government': '2q7e4Fgsq',
      Navy: '2TfZFpmTu',
      'Police & Corrections': '2r7QQQfw5',
      'Policy, Planning & Regulation': '2rcHaVATh',
      'Regional Council': '2qcXELBQT',
      Other: '2s7AkZezK',
    },
  },
  'Design & Creative': {
    catId: 2788,
    SEEK_sec: '22JhiyTFV',
    subCategories: {
      'Architectural Drafting': '23JU58SJj',
      Architecture: '22oau3wn7',
      'Fashion & Textile Design': '24JERHRMy',
      'Graphic Design': '24o7bMutb',
      'Illustration & Animation': '23oMFCvqM',
      'Industrial Design': '25HzmSQRD',
      'Interior Design': '25nswWtwq',
      'Landscape Architecture': '26Hm7bPUT',
      'Urban Design & Planning': '26neHft15',
      'Web & Interaction Design': '27HXTkNXh',
      Other: '27nQdps4K',
    },
  },
  'Education & Training': {
    catId: 9,
    SEEK_sec: '324wLRgMD',
    subCategories: {
      'Childcare & Outside School Hours Care': '32ZpWWAsq',
      'Library Services & Information Management': '334hgafQT',
      'Management - Schools': '33Zarf9w5',
      'Management - Universities': '344U2jeTh',
      'Management - Vocational': '34ZMCp8zK',
      'Research & Fellowships': '354ENtdWw',
      'Student Services': '35Z7Yy83Z',
      'Teaching - Early Childhood': '36Ysu876o',
      'Teaching - Primary': '373m5CbdR',
      'Teaching - Secondary': '37YeFH6A3',
      'Teaching - Tertiary': '383XRMagf',
      'Teaching - Vocational': '38YQbS5DH',
      'Teaching Aides & Special Needs': '363zj3caB',
      Tutoring: '393HmWZju',
      'Workplace Training & Assessment': '39YAwb4GX',
      Other: '3A347fYo9',
    },
  },
  Electronics: { catId: 2790, SEEK_sec: '' },
  'Energy & Renewables': { catId: 2818, SEEK_sec: '' },
  Engineering: {
    catId: 12,
    SEEK_sec: '2b8gKTKEw',
    subCategories: {
      'Aerospace Engineering': '29mpnc12X',
      'Automotive Engineering': '2AGhxgVZ9',
      'Building Services Engineering': '2Amb8kz5m',
      'Chemical Engineering': '2BmMUuy91',
      'Civil/Structural Engineering': '2CGEezTfd',
      'Electrical/Electronic Engineering': '2Cm7q4xCF',
      'Engineering Drafting': '2BGUJqUcP',
      'Environmental Engineering': '2DG119Sis',
      'Field Engineering': '2DktBDwFV',
      'Industrial Engineering': '2EFmMJRn7',
      Maintenance: '2EkeXNvJj',
      Management: '2FFXhTQqM',
      'Materials Handling Engineering': '2FkQsXuMy',
      'Mechanical Engineering': '2GFJ3cPtb',
      'Process Engineering': '2GkBDgtRD',
      'Project Engineering': '2HjwZqsUT',
      'Project Management': '2HF4PmNwq',
      Supervisors: '2JEpjvN15',
      'Systems Engineering': '2JjhuzrXh',
      'Water & Waste Engineering': '2KEb65M4K',
      Other: '2KjUG9qaw',
    },
  },
  'CEO & General Management': {
    catId: 2816,
    SEEK_sec: 'vLTGe3Ls',
    subCategories: {
      'Board Appointments': 'vqLSiXsV',
      CEO: 'wLDco2Q7',
      'COO & MD': 'wq6nsWvj',
      'General/Business Unit Manager': 'xKyxx1TM',
      Other: 'xps92Vyy',
    },
  },
  Fashion: { catId: 2792, SEEK_sec: '' },
  'Financial Services': { catId: 11, SEEK_sec: '' },
  FMCG: { catId: 12, SEEK_sec: '' },
  'Graduate Roles': { catId: 14, SEEK_sec: '' },
  'Healthcare & Medical': {
    catId: 15,
    SEEK_sec: '2c8SfcJJB',
    subCategories: {
      'Ambulance/Paramedics': '2bAwC5v6T',
      'Chiropractic & Osteopathic': '2bfpNAQd5',
      'Clinical/Medical Research': 'dMyQK4Fh',
      Dental: 'npjhnQHV',
      Dieticians: '2cAhYEu9h',
      'Environmental Services': 'uo68sHgB',
      'General Practitioners': '2cfaiKPgK',
      Management: '2dATtPtCw',
      'Medical Administration': '2dfM4UNjZ',
      'Medical Imaging': '2eAEEYsGB',
      'Medical Specialists': '2ef7QdMno',
      'Natural Therapies & Alternative Medicine': '2f9zahrKR',
      'Nursing - A&E, Critical Care & ICU': '',
      'Nursing - Aged Care': '2g9kvrqNf',
      'Nursing - Community, Maternal & Child Health': '2gee6wKuH',
      'Nursing - Educators & Facilitators': '2h9XH1pRu',
      'Nursing - General Medical & Surgical': '2heQT6JxX',
      'Nursing - High Acuity': '2i9HdAoV9',
      'Nursing - Management': '2ieAoFJ1m',
      'Nursing - Midwifery, Neo-Natal, SCN & NICU': '2j93yKnYP',
      'Nursing - Paediatric & PICU': '2jdw9QH51',
      'Nursing - Psych, Forensic & Correctional Health': '2k8pKUmbd',
      'Nursing - Theatre & Recovery': '2kdhVZG8F',
      Optical: '2m8afdkes',
      Pathology: '2c8ZD98ST',
      'Pharmaceuticals & Medical Devices': '2mdTqiFBV',
      Pharmacy: '2ddCjNc2K',
      'Physiotherapy, OT & Rehabilitation': '2ndEBsEEj',
      'Psychology, Counselling & Social Work': '2n8M1nji7',
      'Residents & Registrars': '2wb7KG5ju',
      Sales: '2o87MwimM',
      'Speech Therapy': '2oczY2DHy',
      Other: '2p7si6hpb',
    },
  },
  'Hospitality & Tourism': {
    catId: 16,
    SEEK_sec: '2cdKqgnpo',
    subCategories: {
      Airlines: 'JSSoNNC7',
      'Bar & Beverage Staff': 'SuSkgjAf',
      'Chefs/Cooks': '2QiHzvks9',
      'Front Office & Guest Services': '2RDBB1FPm',
      Gaming: '25FrSLdhy',
      Housekeeping: '2AEgB7YzB',
      'Kitchen & Sandwich Hands': '2Ri4M5jvP',
      Management: '2SCwXAET1',
      Reservations: '2ShphEiyd',
      'Tour Guides': 'WZSGUPH',
      'Travel Agents/Consultants': '3zyJew2P',
      'Waiting Staff': '7V9XCNij',
      Other: '2TChsKDWF',
    },
  },
  'Human Resources & Recruitment': {
    catId: 17,
    SEEK_sec: '2VCM7A1m1',
    subCategories: {
      'Consulting & Generalist HR': '2VhEHEWHd',
      'Industrial & Employee Relations': '2WC7TJzpF',
      'Management - Agency': '2WgzdPVLs',
      'Management - Internal': '2XBsoTysV',
      'Occupational Health & Safety': '2XgkyYUQ7',
      'Organisational Development': '2YBe9cxvj',
      'Recruitment - Agency': '2YgXKhTTM',
      'Recruitment - Internal': '2ZBQVmwyy',
      'Remuneration & Benefits': '2ZgHfrSWb',
      'Training & Development': '2aBAqvw3D',
      Other: '2ag421RZq',
    },
  },
  'Insurance & Superannuation': {
    catId: 13,
    SEEK_sec: '2dd6Bqmt3',
    subCategories: {
      Actuarial: '28HHouMaw',
      Assessment: '28nAyyr7Z',
      Brokerage: '29H4A4LeB',
      Claims: 'bsKt5afq',
      'Fund Administration': '29mwL8qAo',
      Management: '2AGpWDKhR',
      'Risk Consulting': '2t4nmcsLK',
      Superannuation: '36Wr7ZAXq',
      Underwriting: '4zjeov5d',
      "Workers' Compensation": '9UgDWLqD',
      Other: '2AmhgHpE3',
    },
  },
  'Internet & New Media': { catId: 2002, SEEK_sec: '' },
  'Information & Communication Technology': {
    catId: 18,
    SEEK_sec: '2BGarNJkf',
    subCategories: {
      Architects: '2BmU2SoHH',
      'Business/Systems Analysts': '2CGMCXHou',
      'Computer Operators': '2CmENbnLX',
      Consultants: '2DG7YgGs9',
      'Database Development & Administration': '2DkzikmPm',
      'Developers/Programmers': '2EFstqFvP',
      'Engineering - Hardware': '2Ekm4ukT1',
      'Engineering - Network': '2FFeEzEyd',
      'Engineering - Software': '2FkXR4jWF',
      'Help Desk & IT Support': '2GFQb9E2s',
      Management: '2GkHmDiZV',
      'Networks & Systems Administration': '2HFAwJD67',
      'Product Management & Development': '2Hk47Nhcj',
      'Programme & Project Management': '2JEwHTC9M',
      'Sales - Pre & Post': '2JjpTXgfy',
      Security: '2KEhdcBCb',
      'Team Leaders': '2KjaogfjD',
      'Technical Writing': '2LETymAFq',
      Telecommunications: '2LjM9qenT',
      'Testing & Quality Assurance': '2MEEKv9K5',
      'Web Development & Production': '2Mj7Vzdqh',
      Other: '2NDzg58NK',
    },
  },
  Legal: {
    catId: 19,
    SEEK_sec: '2ecrXzkwH',
    subCategories: {
      'Banking & Finance Law': 'NxXhAZew',
      'Construction Law': 'PxJ3KYiB',
      'Corporate & Commercial Law': 'QTBDQ3Eo',
      'Criminal & Civil Law': 'PTQsF4BZ',
      'Environment & Planning Law': 'Qx4PUXmR',
      'Family Law': 'RSwZZ2J3',
      'Generalists - In-house': 'RwpjdWpf',
      'Generalists - Law Firm': 'SShui1MH',
      'Industrial Relations & Employment Law': 'Swb5nVsu',
      'Insurance & Superannuation Law': 'TSUFrzQX',
      'Intellectual Property Law': 'TwMRwUw9',
      'Law Clerks & Paralegals': '2HD2cCSNs',
      'Legal Practice Management': 'USEc1yTm',
      'Legal Secretaries': '2JCnxMRS7',
      'Litigation & Dispute Resolution': 'Uw7n6TzP',
      'Personal Injury Law': 'VRzxAxX1',
      'Property Law': 'Vvt8FT3d',
      'Tax Law': 'WRmJKwaF',
      Other: 'WveUQS6s',
    },
  },
  'Sport & Recreation': { catId: 20, SEEK_sec: 'sqhmf6Gw' },
  'Trades & Services': { catId: 2794, SEEK_sec: '2j6o6hBgs' },
  'Retail & Consumer Products': { catId: 2808, SEEK_sec: '2LEMSEL7Z' },
  'Marketing & Communications': {
    catId: 21,
    SEEK_sec: '22oUMX7dq',
    subCategories: {
      'Brand Management': '23JMXbcAT',
      'Digital & Search Marketing': '23oEhg6h5',
      'Direct Marketing & CRM': '24J7skbDh',
      'Event Management': '24o13q5kK',
      'Internal Communications': '25HtDuaGw',
      Management: '27HQvDYPR',
      'Market Research & Analysis': '25nmPz4oZ',
      'Marketing Assistants/Coordinators': '26Hea4ZLB',
      'Marketing Communications': '26nXk93ro',
      'Product Management & Development': '27nJ6J2v3',
      'Public Relations & Corporate Affairs': '28HBGNXSf',
      'Trade Marketing': '28n4ST1yH',
      Other: '29GwcXWVu',
    },
  },
  Media: { catId: 22, SEEK_sec: '' },
  'Medical & Nursing': { catId: 2796, SEEK_sec: '' },
  'Self Employment': { catId: 31, SEEK_sec: '21JwNpUCF' },
  Pharmaceuticals: { catId: 2804, SEEK_sec: '' },
  'Real Estate & Property': { catId: 2806, SEEK_sec: '2gcPEJj3m' },
  'Public Sector & Government': { catId: 23, SEEK_sec: '' },
  'Purchasing & Procurement': { catId: 24, SEEK_sec: '' },
  'Recruitment Consultancy': { catId: 25, SEEK_sec: '' },
  Retail: { catId: 26, SEEK_sec: '' },
  Sales: { catId: 27, SEEK_sec: '2sc3ve9Ww' },
  'Science & Technology': { catId: 28, SEEK_sec: '2i72kYCdd' },
  'Social Care': { catId: 29, SEEK_sec: '' },
  Telecommunications: { catId: 2798, SEEK_sec: '' },
  'Transport & Rail': { catId: 30, SEEK_sec: '' },
  'Travel & Tourism': { catId: 2810, SEEK_sec: '' },
  'Mining, Resources & Energy': { catId: 2812, SEEK_sec: '2Thb3Pi2s' },
};

const CurrencyCode = {
  'AUD (Australian Dollar)': 'AUD',
};

export const payType = {
  annum: 'annum',
  month: 'month',
  hour: 'hour',
};

export const employeementTerm = {
  Permanent: 2,
  Contract: 1,
  Temporary: 4,
};

export const countriesList = [
  {
    countryId: 1,
    countryCode: 'GB',
    country: 'United Kingdom',
  },
  {
    countryId: 2,
    countryCode: 'AD',
    country: 'Andorra',
  },
  {
    countryId: 3,
    countryCode: 'AN',
    country: 'Angola',
  },
  {
    countryId: 4,
    countryCode: 'AR',
    country: 'Argentina',
  },
  {
    countryId: 5,
    countryCode: 'AU',
    country: 'Australia',
  },
  {
    countryId: 6,
    countryCode: 'AT',
    country: 'Austria',
  },
  {
    countryId: 7,
    countryCode: 'BD',
    country: 'Bangladesh',
  },
  {
    countryId: 8,
    countryCode: 'BB',
    country: 'Barbados',
  },
  {
    countryId: 9,
    countryCode: 'BE',
    country: 'Belgium',
  },
  {
    countryId: 10,
    countryCode: 'BM',
    country: 'Bermuda',
  },
  {
    countryId: 11,
    countryCode: 'BR',
    country: 'Brazil',
  },
  {
    countryId: 12,
    countryCode: 'BN',
    country: 'Brunei',
  },
  {
    countryId: 13,
    countryCode: 'BG',
    country: 'Bulgaria',
  },
  {
    countryId: 14,
    countryCode: 'CA',
    country: 'Canada',
  },
  {
    countryId: 15,
    countryCode: 'GG',
    country: 'Channel Islands',
  },
  {
    countryId: 16,
    countryCode: 'CN',
    country: 'China',
  },
  {
    countryId: 17,
    countryCode: 'CO',
    country: 'Colombia',
  },
  {
    countryId: 18,
    countryCode: 'HR',
    country: 'Croatia',
  },
  {
    countryId: 19,
    countryCode: 'CZ',
    country: 'Czech Republic',
  },
  {
    countryId: 20,
    countryCode: 'DM',
    country: 'Denmark',
  },
  {
    countryId: 21,
    countryCode: 'EG',
    country: 'Egypt',
  },
  {
    countryId: 22,
    countryCode: 'GQ',
    country: 'Equatorial Guinea',
  },
  {
    countryId: 23,
    countryCode: 'FO',
    country: 'Faroe Islands',
  },
  {
    countryId: 24,
    countryCode: 'FR',
    country: 'France',
  },
  {
    countryId: 25,
    countryCode: 'GA',
    country: 'Gabon',
  },
  {
    countryId: 26,
    countryCode: 'DE',
    country: 'Germany',
  },
  {
    countryId: 27,
    countryCode: 'GH',
    country: 'Ghana',
  },
  {
    countryId: 28,
    countryCode: 'GR',
    country: 'Greece',
  },
  {
    countryId: 29,
    countryCode: 'GP',
    country: 'Guadeloupe',
  },
  {
    countryId: 30,
    countryCode: 'GU',
    country: 'Guam',
  },
  {
    countryId: 31,
    countryCode: 'HK',
    country: 'Hong Kong',
  },
  {
    countryId: 32,
    countryCode: 'HU',
    country: 'Hungary',
  },
  {
    countryId: 33,
    countryCode: 'IN',
    country: 'India',
  },
  {
    countryId: 34,
    countryCode: 'ID',
    country: 'Indonesia',
  },
  {
    countryId: 35,
    countryCode: 'IQ',
    country: 'Iraq',
  },
  {
    countryId: 36,
    countryCode: 'IE',
    country: 'Ireland',
  },
  {
    countryId: 37,
    countryCode: 'IM',
    country: 'Isle of Man',
  },
  {
    countryId: 38,
    countryCode: 'IT',
    country: 'Italy',
  },
  {
    countryId: 39,
    countryCode: 'JP',
    country: 'Japan',
  },

  {
    countryId: 40,
    countryCode: 'JE',
    country: 'Jersey',
  },
  {
    countryId: 41,
    countryCode: 'JO',
    country: 'Jordan',
  },
  {
    countryId: 42,
    countryCode: 'LY',
    country: 'Libya',
  },
  {
    countryId: 43,
    countryCode: 'LI',
    country: 'Liechtenstein',
  },
  {
    countryId: 44,
    countryCode: 'LU',
    country: 'Luxembourg',
  },
  {
    countryId: 45,
    countryCode: 'MK',
    country: 'Macedonia',
  },
  {
    countryId: 46,
    countryCode: 'MY',
    country: 'Malaysia',
  },
  {
    countryId: 47,
    countryCode: 'MA',
    country: 'Malta',
  },
  {
    countryId: 48,
    countryCode: 'MH',
    country: 'Marshall Islands',
  },
  {
    countryId: 49,
    countryCode: 'MQ',
    country: 'Martinique',
  },
  {
    countryId: 50,
    countryCode: 'YT',
    country: 'Mayotte',
  },
  {
    countryId: 51,
    countryCode: 'MX',
    country: 'Mexico',
  },
  {
    countryId: 52,
    countryCode: 'MD',
    country: 'Moldova',
  },
  {
    countryId: 53,
    countryCode: 'MC',
    country: 'Monaco',
  },
  {
    countryId: 54,
    countryCode: 'MZ',
    country: 'Mozambique',
  },
  {
    countryId: 55,
    countryCode: 'MM',
    country: 'Myanmar',
  },
  {
    countryId: 56,
    countryCode: 'NA',
    country: 'Namibia',
  },
  {
    countryId: 57,
    countryCode: 'NL',
    country: 'Netherlands',
  },
  {
    countryId: 58,
    countryCode: 'NZ',
    country: 'New Zealand',
  },
  {
    countryId: 59,
    countryCode: 'NG',
    country: 'Nigeria',
  },
  {
    countryId: 60,
    countryCode: 'MP',
    country: 'Northern Mariana Islands',
  },
  {
    countryId: 61,
    countryCode: 'NO',
    country: 'Norway',
  },
  {
    countryId: 62,
    countryCode: 'OM',
    country: 'Oman',
  },
  {
    countryId: 63,
    countryCode: 'PK',
    country: 'Pakistan',
  },
  {
    countryId: 64,
    countryCode: 'PE',
    country: 'Peru',
  },
  {
    countryId: 65,
    countryCode: 'PH',
    country: 'Philippines',
  },
  {
    countryId: 66,
    countryCode: 'PL',
    country: 'Poland',
  },
  {
    countryId: 67,
    countryCode: 'PT',
    country: 'Portugal',
  },
  {
    countryId: 68,
    countryCode: 'PR',
    country: 'Puerto Rico',
  },
  {
    countryId: 69,
    countryCode: 'QA',
    country: 'Qatar',
  },
  {
    countryId: 70,
    countryCode: 'RE',
    country: 'Reunion',
  },
  {
    countryId: 71,
    countryCode: 'RO',
    country: 'Romania',
  },
  {
    countryId: 72,
    countryCode: 'RU',
    country: 'Russia',
  },
  {
    countryId: 73,
    countryCode: 'SM',
    country: 'San Marino',
  },
  {
    countryId: 74,
    countryCode: 'SA',
    country: 'Saudi Arabia',
  },
  {
    countryId: 75,
    countryCode: 'RS',
    country: 'Serbia',
  },
  {
    countryId: 76,
    countryCode: 'SI',
    country: 'Singapore',
  },
  {
    countryId: 77,
    countryCode: 'ZA',
    country: 'South Africa',
  },
  {
    countryId: 78,
    countryCode: 'KR',
    country: 'South Korea',
  },
  {
    countryId: 79,
    countryCode: 'ES',
    country: 'Spain',
  },
  {
    countryId: 80,
    countryCode: 'LK',
    country: 'Sri Lanka',
  },
  {
    countryId: 81,
    countryCode: 'PM',
    country: 'St. Pierre and Miquelon',
  },
  {
    countryId: 82,
    countryCode: 'SE',
    country: 'Sweden',
  },
  {
    countryId: 83,
    countryCode: 'CH',
    country: 'Switzerland',
  },
  {
    countryId: 84,
    countryCode: 'TW',
    country: 'Taiwan',
  },
  {
    countryId: 85,
    countryCode: 'TZ',
    country: 'Tanzania',
  },
  {
    countryId: 86,
    countryCode: 'TH',
    country: 'Thailand',
  },
  {
    countryId: 87,
    countryCode: 'ZZ',
    country: 'Rest of the World',
  },
  {
    countryId: 88,
    countryCode: 'TN',
    country: 'Tunisia',
  },
  {
    countryId: 89,
    countryCode: 'TR',
    country: 'Turkey',
  },
  {
    countryId: 90,
    countryCode: 'UE',
    country: 'United Arab Emirates',
  },
  {
    countryId: 91,
    countryCode: 'US',
    country: 'United States',
  },
  {
    countryId: 92,
    countryCode: 'VA',
    country: 'Vatican City (Holy See)',
  },
  {
    countryId: 93,
    countryCode: 'VE',
    country: 'Venezuela',
  },
  {
    countryId: 94,
    countryCode: 'VN',
    country: 'Vietnam',
  },
  {
    countryId: 95,
    countryCode: 'VI',
    country: 'Virgin Islands (U.S.)',
  },
];

export const subLocations = [
  {
    label: 'Abba River, Western Australia',
    value: 10008974,
  },
  {
    label: 'Abbey, Western Australia',
    value: 10008970,
  },
  {
    label: 'Abbeywood, Queensland',
    value: 10004097,
  },
  {
    label: 'Abbotsbury, New South Wales',
    value: 10000582,
  },
  {
    label: 'Abbotsford, New South Wales',
    value: 10000281,
  },
  {
    label: 'Abbotsford, Queensland',
    value: 10004320,
  },
  {
    label: 'Abbotsford, Victoria',
    value: 10006797,
  },
  {
    label: 'Abbotsham, Tasmania',
    value: 10006589,
  },
  {
    label: 'Abeckett Street, Victoria',
    value: 10008393,
  },
  {
    label: 'Abels Bay, Tasmania',
    value: 10006191,
  },
  {
    label: 'Abercorn, Queensland',
    value: 10004191,
  },
  {
    label: 'Aberdare, New South Wales',
    value: 10001042,
  },
  {
    label: 'Aberdeen, New South Wales',
    value: 10001098,
  },
  {
    label: 'Aberdeen, Tasmania',
    value: 10006575,
  },
  {
    label: 'Aberfeldie, Victoria',
    value: 10006746,
  },
  {
    label: 'Aberfeldy, Victoria',
    value: 10008125,
  },
  {
    label: 'Aberfoyle Park, South Australia',
    value: 10005624,
  },
  {
    label: 'Abergowrie, Queensland',
    value: 10005002,
  },
  {
    label: 'Abermain, New South Wales',
    value: 10001048,
  },
  {
    label: 'Abernethy, New South Wales',
    value: 10001039,
  },
  {
    label: 'Abington, Queensland',
    value: 10004279,
  },
  {
    label: 'Acacia Gardens, New South Wales',
    value: 10002357,
  },
  {
    label: 'Acacia Hills, Tasmania',
    value: 10006553,
  },
  {
    label: 'Acacia Plateau, New South Wales',
    value: 10001541,
  },
  {
    label: 'Acacia Ridge, Queensland',
    value: 10002964,
  },
  {
    label: 'Acacia Ridge Dc, Queensland',
    value: 10002967,
  },
  {
    label: 'Acheron, Victoria',
    value: 10007956,
  },
  {
    label: 'Acland, Queensland',
    value: 10003623,
  },
  {
    label: 'Acton, Australian Capital Territory',
    value: 10000013,
  },
  {
    label: 'Acton Park, Tasmania',
    value: 10006296,
  },
  {
    label: 'Acton Park, Western Australia',
    value: 10008981,
  },
  {
    label: 'Adaminaby, New South Wales',
    value: 10002071,
  },
  {
    label: 'Adamstown, New South Wales',
    value: 10000883,
  },
  {
    label: 'Adamstown Heights, New South Wales',
    value: 10000885,
  },
  {
    label: 'Adamsvale, Western Australia',
    value: 10009330,
  },
  {
    label: 'Adare, Queensland',
    value: 10003356,
  },
  {
    label: 'Adavale, Queensland',
    value: 10003748,
  },
  {
    label: 'Addington, Victoria',
    value: 10007364,
  },
  {
    label: 'Adelaide, South Australia',
    value: 10005233,
  },
  {
    label: 'Adelaide, South Australia',
    value: 10005239,
  },
  {
    label: 'Adelaide Airport, South Australia',
    value: 10006061,
  },
  {
    label: 'Adelaide Bc, South Australia',
    value: 10005236,
  },
  {
    label: 'Adelaide Park, Queensland',
    value: 10004550,
  },
  {
    label: 'Adelaide River, Northern Territory',
    value: 10002758,
  },
  {
    label: 'Adelaide University, South Australia',
    value: 10005241,
  },
  {
    label: 'Adelong, New South Wales',
    value: 10002278,
  },
  {
    label: 'Adjungbilly, New South Wales',
    value: 10002271,
  },
  {
    label: 'Advancetown, Queensland',
    value: 10003117,
  },
  {
    label: 'Adventure Bay, Tasmania',
    value: 10006276,
  },
  {
    label: 'Aeroglen, Queensland',
    value: 10005106,
  },
  {
    label: 'Agery, South Australia',
    value: 10005950,
  },
  {
    label: 'Agnes, Victoria',
    value: 10008328,
  },
  {
    label: 'Agnes Water, Queensland',
    value: 10004385,
  },
  {
    label: 'Ainslie, Australian Capital Territory',
    value: 10000018,
  },
  {
    label: 'Airdmillan, Queensland',
    value: 10004847,
  },
  {
    label: 'Airds, New South Wales',
    value: 10001898,
  },
  {
    label: 'Aireys Inlet, Victoria',
    value: 10007126,
  },
  {
    label: 'Airlie Beach, Queensland',
    value: 10004807,
  },
  {
    label: 'Airport West, Victoria',
    value: 10006751,
  },
  {
    label: 'Airville, Queensland',
    value: 10004844,
  },
  {
    label: 'Aitkenvale, Queensland',
    value: 10004888,
  },
  {
    label: 'Ajana, Western Australia',
    value: 10009799,
  },
  {
    label: 'Akaroa, Tasmania',
    value: 10006376,
  },
  {
    label: 'Alabama Hill, Queensland',
    value: 10004952,
  },
  {
    label: 'Alawa, Northern Territory',
    value: 10002683,
  },
  {
    label: 'Alawoona, South Australia',
    value: 10005791,
  },
  {
    label: 'Albanvale, Victoria',
    value: 10006705,
  },
  {
    label: 'Albany, Western Australia',
    value: 10009193,
  },
  {
    label: 'Albany, Western Australia',
    value: 10009224,
  },
  {
    label: 'Albany Creek, Queensland',
    value: 10002867,
  },
  {
    label: 'Albany Dc, Western Australia',
    value: 10009223,
  },
  {
    label: 'Albert, New South Wales',
    value: 10002648,
  },
  {
    label: 'Albert Park, South Australia',
    value: 10005280,
  },
  {
    label: 'Albert Park, Victoria',
    value: 10007055,
  },
  {
    label: 'Alberta, Queensland',
    value: 10004489,
  },
  {
    label: 'Alberton, Queensland',
    value: 10003100,
  },
  {
    label: 'Alberton, South Australia',
    value: 10005277,
  },
  {
    label: 'Alberton, Victoria',
    value: 10008338,
  },
  {
    label: 'Albion, Queensland',
    value: 10002821,
  },
  {
    label: 'Albion, Victoria',
    value: 10006704,
  },
  {
    label: 'Albion Park, New South Wales',
    value: 10001689,
  },
  {
    label: 'Albion Park Rail, New South Wales',
    value: 10001690,
  },
  {
    label: 'Albury, New South Wales',
    value: 10002095,
  },
  {
    label: 'Alcomie, Tasmania',
    value: 10006666,
  },
  {
    label: 'Alderley, Queensland',
    value: 10002870,
  },
  {
    label: 'Aldersyde, Western Australia',
    value: 10009075,
  },
  {
    label: 'Aldgate, South Australia',
    value: 10005610,
  },
  {
    label: 'Aldinga, South Australia',
    value: 10005651,
  },
  {
    label: 'Aldinga Beach, South Australia',
    value: 10005650,
  },
  {
    label: 'Aldoga, Queensland',
    value: 10004429,
  },
  {
    label: 'Alectown, New South Wales',
    value: 10002642,
  },
  {
    label: 'Alexander Heights, Western Australia',
    value: 10008549,
  },
  {
    label: 'Alexandra, Queensland',
    value: 10004704,
  },
  {
    label: 'Alexandra, Victoria',
    value: 10007957,
  },
  {
    label: 'Alexandra Bridge, Western Australia',
    value: 10009032,
  },
  {
    label: 'Alexandra Headland, Queensland',
    value: 10004004,
  },
  {
    label: 'Alexandra Hills, Queensland',
    value: 10003043,
  },
  {
    label: 'Alexandria, New South Wales',
    value: 10000139,
  },
  {
    label: 'Alexandria, New South Wales',
    value: 10000214,
  },
  {
    label: 'Alford, South Australia',
    value: 10005939,
  },
  {
    label: 'Alfords Point, New South Wales',
    value: 10000706,
  },
  {
    label: 'Alfred Cove, Western Australia',
    value: 10008684,
  },
  {
    label: 'Alfredton, Victoria',
    value: 10007329,
  },
  {
    label: 'Algester, Queensland',
    value: 10002977,
  },
  {
    label: 'Ali Curung, Northern Territory',
    value: 10002791,
  },
  {
    label: 'Alice Creek, Queensland',
    value: 10004087,
  },
  {
    label: 'Alice River, Queensland',
    value: 10004924,
  },
  {
    label: 'Alice Springs, Northern Territory',
    value: 10002779,
  },
  {
    label: 'Alice Springs, Northern Territory',
    value: 10002780,
  },
  {
    label: 'Alice Springs, Northern Territory',
    value: 10002786,
  },
  {
    label: 'Alkimos, Western Australia',
    value: 10008483,
  },
  {
    label: 'Allambie, New South Wales',
    value: 10000389,
  },
  {
    label: 'Allambie Heights, New South Wales',
    value: 10000387,
  },
  {
    label: 'Allanooka, Western Australia',
    value: 10009736,
  },
  {
    label: 'Allans Flat, Victoria',
    value: 10007916,
  },
  {
    label: 'Allansford, Victoria',
    value: 10007215,
  },
  {
    label: 'Allanson, Western Australia',
    value: 10008837,
  },
  {
    label: 'Allawah, New South Wales',
    value: 10000660,
  },
  {
    label: 'Allenby Gardens, South Australia',
    value: 10005256,
  },
  {
    label: 'Allendale, Victoria',
    value: 10007426,
  },
  {
    label: 'Allendale East, South Australia',
    value: 10005772,
  },
  {
    label: 'Allens Rivulet, Tasmania',
    value: 10006268,
  },
  {
    label: 'Allenstown, Queensland',
    value: 10004445,
  },
  {
    label: 'Allestree, Victoria',
    value: 10007260,
  },
  {
    label: 'Alligator Creek, Queensland',
    value: 10004690,
  },
  {
    label: 'Alligator Creek, Queensland',
    value: 10004914,
  },
  {
    label: 'Allingham, Queensland',
    value: 10005001,
  },
  {
    label: 'Allora, Queensland',
    value: 10003562,
  },
  {
    label: 'Alloway, Queensland',
    value: 10004302,
  },
  {
    label: 'Allworth, New South Wales',
    value: 10001305,
  },
  {
    label: 'Allynbrook, New South Wales',
    value: 10000941,
  },
  {
    label: 'Alma, Victoria',
    value: 10007575,
  },
  {
    label: 'Alma, Western Australia',
    value: 10009824,
  },
  {
    label: 'Almaden, Queensland',
    value: 10005145,
  },
  {
    label: 'Almurta, Victoria',
    value: 10008355,
  },
  {
    label: 'Alonnah, Tasmania',
    value: 10006281,
  },
  {
    label: 'Aloomba, Queensland',
    value: 10005141,
  },
  {
    label: 'Alpha, Queensland',
    value: 10004632,
  },
  {
    label: 'Alphington, Victoria',
    value: 10006812,
  },
  {
    label: 'Alsace, Queensland',
    value: 10004493,
  },
  {
    label: 'Alstonville, New South Wales',
    value: 10001550,
  },
  {
    label: 'Altona, Victoria',
    value: 10006700,
  },
  {
    label: 'Altona Meadows, Victoria',
    value: 10006720,
  },
  {
    label: 'Altona North, Victoria',
    value: 10006717,
  },
  {
    label: 'Alva, Queensland',
    value: 10004837,
  },
  {
    label: 'Alvie, Victoria',
    value: 10007155,
  },
  {
    label: 'Alyangula, Northern Territory',
    value: 10002798,
  },
  {
    label: 'Amamoor, Queensland',
    value: 10003982,
  },
  {
    label: 'Amamoor Creek, Queensland',
    value: 10003989,
  },
  {
    label: 'Amaroo, Australian Capital Territory',
    value: 10000125,
  },
  {
    label: 'Amaroo, Queensland',
    value: 10004992,
  },
  {
    label: 'Ambania, Western Australia',
    value: 10009910,
  },
  {
    label: 'Ambarvale, New South Wales',
    value: 10001888,
  },
  {
    label: 'Ambergate, Western Australia',
    value: 10008992,
  },
  {
    label: 'Amberley, Queensland',
    value: 10003288,
  },
  {
    label: 'Amby, Queensland',
    value: 10003740,
  },
  {
    label: 'Amelup, Western Australia',
    value: 10009263,
  },
  {
    label: 'American River, South Australia',
    value: 10005675,
  },
  {
    label: 'Amiens, Queensland',
    value: 10003425,
  },
  {
    label: 'Amity, Queensland',
    value: 10003072,
  },
  {
    label: 'Amity Point, Queensland',
    value: 10003073,
  },
  {
    label: 'Amphitheatre, Victoria',
    value: 10007580,
  },
  {
    label: 'Anakie, Queensland',
    value: 10004499,
  },
  {
    label: 'Anakie, Victoria',
    value: 10007097,
  },
  {
    label: 'Ancona, Victoria',
    value: 10007960,
  },
  {
    label: 'Andamooka, South Australia',
    value: 10006046,
  },
  {
    label: 'Andergrove, Queensland',
    value: 10004680,
  },
  {
    label: 'Anderleigh, Queensland',
    value: 10003961,
  },
  {
    label: 'Ando, New South Wales',
    value: 10002082,
  },
  {
    label: 'Andover, Tasmania',
    value: 10006210,
  },
  {
    label: 'Andrews Farm, South Australia',
    value: 10005551,
  },
  {
    label: 'Andromache, Queensland',
    value: 10004791,
  },
  {
    label: 'Angaston, South Australia',
    value: 10005826,
  },
  {
    label: 'Angelo River, Western Australia',
    value: 10009927,
  },
  {
    label: 'Angle Park, South Australia',
    value: 10005259,
  },
  {
    label: 'Angle Vale, South Australia',
    value: 10005561,
  },
  {
    label: 'Anglesea, Victoria',
    value: 10007122,
  },
  {
    label: 'Angurugu, Northern Territory',
    value: 10002718,
  },
  {
    label: 'Anketell, Western Australia',
    value: 10008737,
  },
  {
    label: 'Anna Bay, New South Wales',
    value: 10000954,
  },
  {
    label: 'Annandale, New South Wales',
    value: 10000268,
  },
  {
    label: 'Annandale, Queensland',
    value: 10004893,
  },
  {
    label: 'Annangrove, New South Wales',
    value: 10000521,
  },
  {
    label: 'Annerley, Queensland',
    value: 10002947,
  },
  {
    label: 'Anniebrook, Western Australia',
    value: 10008993,
  },
  {
    label: 'Ansons Bay, Tasmania',
    value: 10006372,
  },
  {
    label: 'Anstead, Queensland',
    value: 10002915,
  },
  {
    label: 'Antigua, Queensland',
    value: 10004238,
  },
  {
    label: 'Antill Ponds, Tasmania',
    value: 10006220,
  },
  {
    label: 'Antonymyre, Western Australia',
    value: 10009990,
  },
  {
    label: 'Antwerp, Victoria',
    value: 10007491,
  },
  {
    label: 'Anula, Northern Territory',
    value: 10002692,
  },
  {
    label: 'Apollo Bay, Victoria',
    value: 10007128,
  },
  {
    label: 'Appila, South Australia',
    value: 10005894,
  },
  {
    label: 'Appin, New South Wales',
    value: 10001901,
  },
  {
    label: 'Appin Park, Victoria',
    value: 10007880,
  },
  {
    label: 'Applecross, Western Australia',
    value: 10008681,
  },
  {
    label: 'Applecross, Western Australia',
    value: 10010130,
  },
  {
    label: 'Applecross North, Western Australia',
    value: 10008678,
  },
  {
    label: 'Applethorpe, Queensland',
    value: 10003579,
  },
  {
    label: 'Apslawn, Tasmania',
    value: 10006329,
  },
  {
    label: 'Apsley, Tasmania',
    value: 10006127,
  },
  {
    label: 'Apsley, Victoria',
    value: 10007283,
  },
  {
    label: 'Araluen, New South Wales',
    value: 10002052,
  },
  {
    label: 'Araluen, Queensland',
    value: 10003951,
  },
  {
    label: 'Aramac, Queensland',
    value: 10004634,
  },
  {
    label: 'Arana Hills, Queensland',
    value: 10002884,
  },
  {
    label: 'Aranbanga, Queensland',
    value: 10004148,
  },
  {
    label: 'Aranda, Australian Capital Territory',
    value: 10000065,
  },
  {
    label: 'Ararat, Victoria',
    value: 10007435,
  },
  {
    label: 'Aratula, Queensland',
    value: 10003291,
  },
  {
    label: 'Arcadia, New South Wales',
    value: 10000527,
  },
  {
    label: 'Arcadia, Queensland',
    value: 10004941,
  },
  {
    label: 'Arcadia, Victoria',
    value: 10007805,
  },
  {
    label: 'Arcadia Vale, New South Wales',
    value: 10000851,
  },
  {
    label: 'Archer, Northern Territory',
    value: 10002743,
  },
  {
    label: 'Archerfield, Queensland',
    value: 10002957,
  },
  {
    label: 'Archies Creek, Victoria',
    value: 10008384,
  },
  {
    label: 'Ardath, Western Australia',
    value: 10009446,
  },
  {
    label: 'Ardeer, Victoria',
    value: 10006710,
  },
  {
    label: 'Ardglen, New South Wales',
    value: 10001114,
  },
  {
    label: 'Ardlethan, New South Wales',
    value: 10002183,
  },
  {
    label: 'Ardmona, Victoria',
    value: 10007796,
  },
  {
    label: 'Ardross, Western Australia',
    value: 10008680,
  },
  {
    label: 'Ardrossan, South Australia',
    value: 10005959,
  },
  {
    label: 'Areegra, Victoria',
    value: 10007597,
  },
  {
    label: 'Areyonga, Northern Territory',
    value: 10002785,
  },
  {
    label: 'Argenton, New South Wales',
    value: 10000865,
  },
  {
    label: 'Argents Hill, New South Wales',
    value: 10001424,
  },
  {
    label: 'Argoon, New South Wales',
    value: 10002235,
  },
  {
    label: 'Argoon, Queensland',
    value: 10004532,
  },
  {
    label: 'Argyle, Western Australia',
    value: 10008889,
  },
  {
    label: 'Argyll, Queensland',
    value: 10004629,
  },
  {
    label: 'Ariah Park, New South Wales',
    value: 10002184,
  },
  {
    label: 'Armadale, Victoria',
    value: 10006927,
  },
  {
    label: 'Armadale, Western Australia',
    value: 10008644,
  },
  {
    label: 'Armadale, Western Australia',
    value: 10010159,
  },
  {
    label: 'Armadale North, Victoria',
    value: 10006926,
  },
  {
    label: 'Armatree, New South Wales',
    value: 10002553,
  },
  {
    label: 'Armidale, New South Wales',
    value: 10001157,
  },
  {
    label: 'Armstrong Beach, Queensland',
    value: 10004657,
  },
  {
    label: 'Arncliffe, New South Wales',
    value: 10000623,
  },
  {
    label: 'Arno Bay, South Australia',
    value: 10005988,
  },
  {
    label: 'Arnold, Victoria',
    value: 10007683,
  },
  {
    label: 'Aroona, Queensland',
    value: 10003878,
  },
  {
    label: 'Arrawarra Headland, New South Wales',
    value: 10001460,
  },
  {
    label: 'Arrino, Western Australia',
    value: 10009712,
  },
  {
    label: 'Arrowsmith, Western Australia',
    value: 10009738,
  },
  {
    label: 'Arrowsmith East, Western Australia',
    value: 10009711,
  },
  {
    label: 'Artarmon, New South Wales',
    value: 10000157,
  },
  {
    label: 'Artarmon, New South Wales',
    value: 10000307,
  },
  {
    label: 'Arthur River, Tasmania',
    value: 10006662,
  },
  {
    label: 'Arthur River, Western Australia',
    value: 10009113,
  },
  {
    label: 'Arthurs Creek, Victoria',
    value: 10006846,
  },
  {
    label: 'Arthurs Seat, Victoria',
    value: 10008280,
  },
  {
    label: 'Arthurton, South Australia',
    value: 10005960,
  },
  {
    label: 'Arthurville, New South Wales',
    value: 10002529,
  },
  {
    label: 'Arundel, Queensland',
    value: 10003140,
  },
  {
    label: 'Arundel Bc, Queensland',
    value: 10003139,
  },
  {
    label: 'Arundel Dc, Queensland',
    value: 10003141,
  },
  {
    label: 'Ascot, Queensland',
    value: 10002818,
  },
  {
    label: 'Ascot, Queensland',
    value: 10003543,
  },
  {
    label: 'Ascot, Victoria',
    value: 10007420,
  },
  {
    label: 'Ascot, Western Australia',
    value: 10008611,
  },
  {
    label: 'Ascot Park, South Australia',
    value: 10005356,
  },
  {
    label: 'Ascot Vale, Victoria',
    value: 10006732,
  },
  {
    label: 'Ashbourne, South Australia',
    value: 10005618,
  },
  {
    label: 'Ashbourne, Victoria',
    value: 10007520,
  },
  {
    label: 'Ashburton, Victoria',
    value: 10006936,
  },
  {
    label: 'Ashbury, New South Wales',
    value: 10000601,
  },
  {
    label: 'Ashby, Western Australia',
    value: 10008564,
  },
  {
    label: 'Ashcroft, New South Wales',
    value: 10000559,
  },
  {
    label: 'Ashfield, New South Wales',
    value: 10000180,
  },
  {
    label: 'Ashfield, New South Wales',
    value: 10000460,
  },
  {
    label: 'Ashfield, Queensland',
    value: 10004322,
  },
  {
    label: 'Ashfield, Western Australia',
    value: 10008506,
  },
  {
    label: 'Ashford, New South Wales',
    value: 10001190,
  },
  {
    label: 'Ashford, South Australia',
    value: 10005328,
  },
  {
    label: 'Ashgrove, Queensland',
    value: 10002893,
  },
  {
    label: 'Ashley, New South Wales',
    value: 10001248,
  },
  {
    label: 'Ashmont, New South Wales',
    value: 10002133,
  },
  {
    label: 'Ashmore, Queensland',
    value: 10003138,
  },
  {
    label: 'Ashton, South Australia',
    value: 10005588,
  },
  {
    label: 'Ashtonfield, New South Wales',
    value: 10000996,
  },
  {
    label: 'Ashwood, Victoria',
    value: 10006935,
  },
  {
    label: 'Aspendale, Victoria',
    value: 10007030,
  },
  {
    label: 'Aspendale Gardens, Victoria',
    value: 10007031,
  },
  {
    label: 'Aspley, Queensland',
    value: 10002865,
  },
  {
    label: 'Asquith, New South Wales',
    value: 10000344,
  },
  {
    label: 'Athelstone, South Australia',
    value: 10005465,
  },
  {
    label: 'Atherton, Queensland',
    value: 10005212,
  },
  {
    label: 'Athlone, Victoria',
    value: 10008113,
  },
  {
    label: 'Athol, Queensland',
    value: 10003386,
  },
  {
    label: 'Athol Park, South Australia',
    value: 10005265,
  },
  {
    label: 'Attadale, Western Australia',
    value: 10008689,
  },
  {
    label: 'Attunga, New South Wales',
    value: 10001144,
  },
  {
    label: 'Attwood, Victoria',
    value: 10006767,
  },
  {
    label: 'Atwell, Western Australia',
    value: 10008716,
  },
  {
    label: 'Aubin Grove, Western Australia',
    value: 10008718,
  },
  {
    label: 'Auburn, New South Wales',
    value: 10000184,
  },
  {
    label: 'Auburn, New South Wales',
    value: 10000487,
  },
  {
    label: 'Auburn, South Australia',
    value: 10005879,
  },
  {
    label: 'Auchenflower, Queensland',
    value: 10002901,
  },
  {
    label: 'Auchmore, Victoria',
    value: 10007731,
  },
  {
    label: 'Auckland, Auckland',
    value: 10010180,
  },
  {
    label: 'Audley, New South Wales',
    value: 10000694,
  },
  {
    label: 'Augathella, Queensland',
    value: 10003750,
  },
  {
    label: 'Augusta, Western Australia',
    value: 10009035,
  },
  {
    label: 'Augustine Heights, Queensland',
    value: 10003204,
  },
  {
    label: 'Auldana, South Australia',
    value: 10005453,
  },
  {
    label: 'Aurukun, Queensland',
    value: 10005156,
  },
  {
    label: 'Austinmer, New South Wales',
    value: 10001668,
  },
  {
    label: 'Austins Ferry, Tasmania',
    value: 10006092,
  },
  {
    label: 'Austinville, Queensland',
    value: 10003135,
  },
  {
    label: 'Austral, New South Wales',
    value: 10000595,
  },
  {
    label: 'Australia, Australia',
    value: 10010170,
  },
  {
    label: 'Australia Square, New South Wales',
    value: 10000126,
  },
  {
    label: 'Australian Capital Territory, Australian Capital Territory',
    value: 10010172,
  },
  {
    label: 'Australian Defence Forces, New South Wales',
    value: 10002668,
  },
  {
    label: 'Australian National University, Australian Capital Territory',
    value: 10000000,
  },
  {
    label: 'Australind, Western Australia',
    value: 10008864,
  },
  {
    label: 'Avalon Beach, New South Wales',
    value: 10000407,
  },
  {
    label: 'Avenel, Victoria',
    value: 10007850,
  },
  {
    label: 'Avenell Heights, Queensland',
    value: 10004330,
  },
  {
    label: 'Avenue Range, South Australia',
    value: 10005744,
  },
  {
    label: 'Avoca, New South Wales',
    value: 10001960,
  },
  {
    label: 'Avoca, Queensland',
    value: 10004304,
  },
  {
    label: 'Avoca, Tasmania',
    value: 10006349,
  },
  {
    label: 'Avoca, Victoria',
    value: 10007579,
  },
  {
    label: 'Avoca Beach, New South Wales',
    value: 10000742,
  },
  {
    label: 'Avoca Vale, Queensland',
    value: 10003265,
  },
  {
    label: 'Avondale, Queensland',
    value: 10004309,
  },
  {
    label: 'Avondale Heights, Victoria',
    value: 10006735,
  },
  {
    label: 'Avonsleigh, Victoria',
    value: 10008061,
  },
  {
    label: 'Awaba, New South Wales',
    value: 10000850,
  },
  {
    label: 'Axedale, Victoria',
    value: 10007691,
  },
  {
    label: 'Aylmerton, New South Wales',
    value: 10001952,
  },
  {
    label: 'Ayr, Queensland',
    value: 10004841,
  },
  {
    label: 'Baan Baa, New South Wales',
    value: 10001237,
  },
  {
    label: 'Baandee, Western Australia',
    value: 10009435,
  },
  {
    label: 'Baarmutha, Victoria',
    value: 10008019,
  },
  {
    label: 'Babakin, Western Australia',
    value: 10009476,
  },
  {
    label: 'Babbage Island, Western Australia',
    value: 10009949,
  },
  {
    label: 'Babinda, Queensland',
    value: 10005084,
  },
  {
    label: 'Bacchus Marsh, Victoria',
    value: 10007315,
  },
  {
    label: 'Backmede, New South Wales',
    value: 10001518,
  },
  {
    label: 'Baddaginnie, Victoria',
    value: 10007867,
  },
  {
    label: 'Baden, Tasmania',
    value: 10006209,
  },
  {
    label: 'Badgebup, Western Australia',
    value: 10009126,
  },
  {
    label: 'Badgerin Rock, Western Australia',
    value: 10009612,
  },
  {
    label: 'Badgerys Creek, New South Wales',
    value: 10001878,
  },
  {
    label: 'Badgin, Western Australia',
    value: 10009059,
  },
  {
    label: 'Badgingarra, Western Australia',
    value: 10009717,
  },
  {
    label: 'Badjaling, Western Australia',
    value: 10009339,
  },
  {
    label: 'Badu Island, Queensland',
    value: 10005191,
  },
  {
    label: 'Baerami Creek, New South Wales',
    value: 10001084,
  },
  {
    label: 'Baffle Creek, Queensland',
    value: 10004382,
  },
  {
    label: 'Baffle West, Queensland',
    value: 10003711,
  },
  {
    label: 'Bagdad, Tasmania',
    value: 10006128,
  },
  {
    label: 'Bagnoo, New South Wales',
    value: 10001408,
  },
  {
    label: 'Bagot, Northern Territory',
    value: 10002708,
  },
  {
    label: 'Bahrs Scrub, Queensland',
    value: 10003091,
  },
  {
    label: 'Bailieston, Victoria',
    value: 10007769,
  },
  {
    label: 'Bairnsdale, Victoria',
    value: 10008204,
  },
  {
    label: 'Bajool, Queensland',
    value: 10004440,
  },
  {
    label: 'Bakers Creek, Queensland',
    value: 10004700,
  },
  {
    label: 'Bakers Hill, Western Australia',
    value: 10009841,
  },
  {
    label: 'Bakers Swamp, New South Wales',
    value: 10002528,
  },
  {
    label: 'Bakery Hill, Victoria',
    value: 10007405,
  },
  {
    label: 'Bakewell, Northern Territory',
    value: 10002748,
  },
  {
    label: 'Balaclava, Victoria',
    value: 10007003,
  },
  {
    label: 'Balaklava, South Australia',
    value: 10005886,
  },
  {
    label: 'Balbarrup, Western Australia',
    value: 10008935,
  },
  {
    label: 'Balberra, Queensland',
    value: 10004670,
  },
  {
    label: 'Balcatta, Western Australia',
    value: 10008443,
  },
  {
    label: 'Balcatta, Western Australia',
    value: 10010099,
  },
  {
    label: 'Balcomba, Queensland',
    value: 10004481,
  },
  {
    label: 'Bald Hills, Queensland',
    value: 10002868,
  },
  {
    label: 'Bald Ridge, New South Wales',
    value: 10002451,
  },
  {
    label: 'Baldivis, Western Australia',
    value: 10008751,
  },
  {
    label: 'Baldry, New South Wales',
    value: 10002631,
  },
  {
    label: 'Balga, Western Australia',
    value: 10008540,
  },
  {
    label: 'Balgowan, Queensland',
    value: 10003619,
  },
  {
    label: 'Balgowan, South Australia',
    value: 10005963,
  },
  {
    label: 'Balgowlah, New South Wales',
    value: 10000372,
  },
  {
    label: 'Balgownie, New South Wales',
    value: 10001677,
  },
  {
    label: 'Balhannah, South Australia',
    value: 10005693,
  },
  {
    label: 'Balickera, New South Wales',
    value: 10001013,
  },
  {
    label: 'Balingup, Western Australia',
    value: 10008908,
  },
  {
    label: 'Balkuling, Western Australia',
    value: 10009347,
  },
  {
    label: 'Balla Balla, Western Australia',
    value: 10009984,
  },
  {
    label: 'Balladong, Western Australia',
    value: 10009060,
  },
  {
    label: 'Balladonia, Western Australia',
    value: 10009545,
  },
  {
    label: 'Balladoran, New South Wales',
    value: 10002555,
  },
  {
    label: 'Ballajura, Western Australia',
    value: 10008568,
  },
  {
    label: 'Ballalaba, New South Wales',
    value: 10002050,
  },
  {
    label: 'Ballan, Victoria',
    value: 10007324,
  },
  {
    label: 'Ballandean, Queensland',
    value: 10003583,
  },
  {
    label: 'Ballarat, Victoria',
    value: 10007333,
  },
  {
    label: 'Ballarat, Victoria',
    value: 10007403,
  },
  {
    label: 'Ballarat Mc, Victoria',
    value: 10007404,
  },
  {
    label: 'Ballarat North, Victoria',
    value: 10007332,
  },
  {
    label: 'Ballarat West, Victoria',
    value: 10007340,
  },
  {
    label: 'Ballard, Queensland',
    value: 10003456,
  },
  {
    label: 'Ballaying, Western Australia',
    value: 10009118,
  },
  {
    label: 'Balldale, New South Wales',
    value: 10002117,
  },
  {
    label: 'Ballengarra, New South Wales',
    value: 10001384,
  },
  {
    label: 'Balliang, Victoria',
    value: 10007314,
  },
  {
    label: 'Ballidu, Western Australia',
    value: 10009874,
  },
  {
    label: 'Ballimore, New South Wales',
    value: 10002549,
  },
  {
    label: 'Ballina, New South Wales',
    value: 10001560,
  },
  {
    label: 'Ballogie, Queensland',
    value: 10004075,
  },
  {
    label: 'Bally Bally, Western Australia',
    value: 10009068,
  },
  {
    label: 'Ballyroe, New South Wales',
    value: 10002457,
  },
  {
    label: 'Balmain, New South Wales',
    value: 10000273,
  },
  {
    label: 'Balmattum, Victoria',
    value: 10007855,
  },
  {
    label: 'Balmoral, New South Wales',
    value: 10000848,
  },
  {
    label: 'Balmoral, Queensland',
    value: 10003055,
  },
  {
    label: 'Balmoral, Victoria',
    value: 10007480,
  },
  {
    label: 'Balmoral Ridge, Queensland',
    value: 10003895,
  },
  {
    label: 'Balmoral Village, New South Wales',
    value: 10001935,
  },
  {
    label: 'Balnagowan, Queensland',
    value: 10004695,
  },
  {
    label: 'Balnarring, Victoria',
    value: 10008271,
  },
  {
    label: 'Balook, Victoria',
    value: 10008334,
  },
  {
    label: 'Balranald, New South Wales',
    value: 10002257,
  },
  {
    label: 'Balwyn, Victoria',
    value: 10006852,
  },
  {
    label: 'Balwyn North, Victoria',
    value: 10006853,
  },
  {
    label: 'Bamaga, Queensland',
    value: 10005192,
  },
  {
    label: 'Bamarang, New South Wales',
    value: 10001808,
  },
  {
    label: 'Bamawm, Victoria',
    value: 10007719,
  },
  {
    label: 'Bamboo Creek, Queensland',
    value: 10005079,
  },
  {
    label: 'Bambra, Victoria',
    value: 10007145,
  },
  {
    label: 'Bambun, Western Australia',
    value: 10009668,
  },
  {
    label: 'Ban Ban, Queensland',
    value: 10004154,
  },
  {
    label: 'Ban Ban Springs, Queensland',
    value: 10004146,
  },
  {
    label: 'Banana, Queensland',
    value: 10004528,
  },
  {
    label: 'Bandiana Milpo, Victoria',
    value: 10007923,
  },
  {
    label: 'Bandon Grove, New South Wales',
    value: 10001267,
  },
  {
    label: 'Bandy Creek, Western Australia',
    value: 10009563,
  },
  {
    label: 'Bandya, Western Australia',
    value: 10009531,
  },
  {
    label: 'Bangalee, New South Wales',
    value: 10001824,
  },
  {
    label: 'Bangalee, Queensland',
    value: 10004564,
  },
  {
    label: 'Bangalow, New South Wales',
    value: 10001564,
  },
  {
    label: 'Bangholme, Victoria',
    value: 10006992,
  },
  {
    label: 'Bangor, New South Wales',
    value: 10000709,
  },
  {
    label: 'Bangor, Tasmania',
    value: 10006471,
  },
  {
    label: 'Banjup, Western Australia',
    value: 10008717,
  },
  {
    label: 'Banks, Australian Capital Territory',
    value: 10000117,
  },
  {
    label: 'Banks Creek, Queensland',
    value: 10003259,
  },
  {
    label: 'Banks Pocket, Queensland',
    value: 10003987,
  },
  {
    label: 'Banksia, New South Wales',
    value: 10000649,
  },
  {
    label: 'Banksia Beach, Queensland',
    value: 10003820,
  },
  {
    label: 'Banksia Grove, Western Australia',
    value: 10008474,
  },
  {
    label: 'Banksia Park, South Australia',
    value: 10005499,
  },
  {
    label: 'Banksiadale, Western Australia',
    value: 10008798,
  },
  {
    label: 'Banksmeadow, New South Wales',
    value: 10000222,
  },
  {
    label: 'Bankstown, New South Wales',
    value: 10000189,
  },
  {
    label: 'Bankstown, New South Wales',
    value: 10000614,
  },
  {
    label: 'Bankstown North, New South Wales',
    value: 10000618,
  },
  {
    label: 'Bankstown Square, New South Wales',
    value: 10000615,
  },
  {
    label: 'Bannister, New South Wales',
    value: 10001978,
  },
  {
    label: 'Bannister, Western Australia',
    value: 10009354,
  },
  {
    label: 'Bannockburn, Queensland',
    value: 10003090,
  },
  {
    label: 'Bannockburn, Victoria',
    value: 10007297,
  },
  {
    label: 'Banora Point, New South Wales',
    value: 10001627,
  },
  {
    label: 'Banyena, Victoria',
    value: 10007457,
  },
  {
    label: 'Banyo, Queensland',
    value: 10002829,
  },
  {
    label: 'Banyule, Victoria',
    value: 10006826,
  },
  {
    label: 'Bapaume, Queensland',
    value: 10003445,
  },
  {
    label: 'Bar Beach, New South Wales',
    value: 10000918,
  },
  {
    label: 'Baradine, New South Wales',
    value: 10001242,
  },
  {
    label: 'Baraimal, New South Wales',
    value: 10001508,
  },
  {
    label: 'Baralaba, Queensland',
    value: 10004484,
  },
  {
    label: 'Barambah, Queensland',
    value: 10004034,
  },
  {
    label: 'Baranduda, Victoria',
    value: 10007920,
  },
  {
    label: 'Barangaroo, New South Wales',
    value: 10010185,
  },
  {
    label: 'Barbalin, Western Australia',
    value: 10009622,
  },
  {
    label: 'Barberton, Western Australia',
    value: 10009691,
  },
  {
    label: 'Barcaldine, Queensland',
    value: 10004633,
  },
  {
    label: 'Barden Ridge, New South Wales',
    value: 10000708,
  },
  {
    label: 'Bardia, New South Wales',
    value: 10010196,
  },
  {
    label: 'Bardon, Queensland',
    value: 10002898,
  },
  {
    label: 'Bardwell Park, New South Wales',
    value: 10000633,
  },
  {
    label: 'Bardwell Valley, New South Wales',
    value: 10000631,
  },
  {
    label: 'Baree, Queensland',
    value: 10004590,
  },
  {
    label: 'Barellan, New South Wales',
    value: 10002185,
  },
  {
    label: 'Barellan Point, Queensland',
    value: 10003267,
  },
  {
    label: 'Bargara, Queensland',
    value: 10004295,
  },
  {
    label: 'Bargo, New South Wales',
    value: 10001946,
  },
  {
    label: 'Barham, New South Wales',
    value: 10002284,
  },
  {
    label: 'Baringhup, Victoria',
    value: 10007565,
  },
  {
    label: 'Barker Creek Flat, Queensland',
    value: 10004107,
  },
  {
    label: 'Barkers Creek, Victoria',
    value: 10007539,
  },
  {
    label: 'Barkly, Victoria',
    value: 10007449,
  },
  {
    label: 'Barkstead, Victoria',
    value: 10007384,
  },
  {
    label: 'Barlil, Queensland',
    value: 10004040,
  },
  {
    label: 'Barlows Hill, Queensland',
    value: 10004560,
  },
  {
    label: 'Barlyne, Queensland',
    value: 10004156,
  },
  {
    label: 'Barmah, Victoria',
    value: 10007826,
  },
  {
    label: 'Barmaryee, Queensland',
    value: 10004541,
  },
  {
    label: 'Barmedman, New South Wales',
    value: 10002192,
  },
  {
    label: 'Barmera, South Australia',
    value: 10005812,
  },
  {
    label: 'Barmoya, Queensland',
    value: 10004548,
  },
  {
    label: 'Barnadown, Victoria',
    value: 10007711,
  },
  {
    label: 'Barnard, Queensland',
    value: 10004475,
  },
  {
    label: 'Barnawartha, Victoria',
    value: 10007898,
  },
  {
    label: 'Barnes Bay, Tasmania',
    value: 10006282,
  },
  {
    label: 'Barney Point, Queensland',
    value: 10004403,
  },
  {
    label: 'Barnsley, New South Wales',
    value: 10000831,
  },
  {
    label: 'Barongarook, Victoria',
    value: 10007157,
  },
  {
    label: 'Barooga, New South Wales',
    value: 10002672,
  },
  {
    label: 'Barraba, New South Wales',
    value: 10001146,
  },
  {
    label: 'Barrabool, Victoria',
    value: 10007091,
  },
  {
    label: 'Barrabup, Western Australia',
    value: 10008963,
  },
  {
    label: 'Barrack Heights, New South Wales',
    value: 10001692,
  },
  {
    label: 'Barrack Point, New South Wales',
    value: 10001694,
  },
  {
    label: 'Barragga Bay, New South Wales',
    value: 10001830,
  },
  {
    label: 'Barragup, Western Australia',
    value: 10008784,
  },
  {
    label: 'Barramornie, Queensland',
    value: 10003675,
  },
  {
    label: 'Barramunga, Victoria',
    value: 10007159,
  },
  {
    label: 'Barratta, Queensland',
    value: 10004851,
  },
  {
    label: 'Barrengarry, New South Wales',
    value: 10001965,
  },
  {
    label: 'Barretta, Tasmania',
    value: 10006156,
  },
  {
    label: 'Barrine, Queensland',
    value: 10005164,
  },
  {
    label: 'Barringha, Queensland',
    value: 10004903,
  },
  {
    label: 'Barrington, New South Wales',
    value: 10001280,
  },
  {
    label: 'Barrington, Tasmania',
    value: 10006552,
  },
  {
    label: 'Barringun, New South Wales',
    value: 10002580,
  },
  {
    label: 'Barringun, Queensland',
    value: 10003783,
  },
  {
    label: 'Barron, Queensland',
    value: 10005194,
  },
  {
    label: 'Barrow Creek, Northern Territory',
    value: 10002788,
  },
  {
    label: 'Barrow Island, Western Australia',
    value: 10009970,
  },
  {
    label: 'Barry, New South Wales',
    value: 10002494,
  },
  {
    label: 'Bartle Frere, Queensland',
    value: 10005083,
  },
  {
    label: 'Barton, Australian Capital Territory',
    value: 10000005,
  },
  {
    label: 'Barunah Park, Victoria',
    value: 10007293,
  },
  {
    label: 'Barwon Downs, Victoria',
    value: 10007150,
  },
  {
    label: 'Barwon Heads, Victoria',
    value: 10007118,
  },
  {
    label: 'Baryulgil, New South Wales',
    value: 10001472,
  },
  {
    label: 'Basilisk, Queensland',
    value: 10005160,
  },
  {
    label: 'Basin Pocket, Queensland',
    value: 10003245,
  },
  {
    label: 'Basin View, New South Wales',
    value: 10001796,
  },
  {
    label: 'Baskerville, Western Australia',
    value: 10008529,
  },
  {
    label: 'Basket Range, South Australia',
    value: 10005589,
  },
  {
    label: 'Bass, Victoria',
    value: 10008379,
  },
  {
    label: 'Bass Hill, New South Wales',
    value: 10000609,
  },
  {
    label: 'Bassendean, Western Australia',
    value: 10008503,
  },
  {
    label: 'Bassendean, Western Australia',
    value: 10010117,
  },
  {
    label: 'Bassendean Dc, Western Australia',
    value: 10010122,
  },
  {
    label: 'Batchelor, Northern Territory',
    value: 10002757,
  },
  {
    label: 'Bateau Bay, New South Wales',
    value: 10000795,
  },
  {
    label: 'Batehaven, New South Wales',
    value: 10001733,
  },
  {
    label: 'Bateman, Western Australia',
    value: 10008668,
  },
  {
    label: 'Batemans Bay, New South Wales',
    value: 10001740,
  },
  {
    label: 'Batesford, Victoria',
    value: 10007095,
  },
  {
    label: 'Bathurst, New South Wales',
    value: 10002480,
  },
  {
    label: 'Batlow, New South Wales',
    value: 10002280,
  },
  {
    label: 'Battery Hill, Queensland',
    value: 10003882,
  },
  {
    label: 'Battery Point, Tasmania',
    value: 10006071,
  },
  {
    label: 'Baudin, Western Australia',
    value: 10009014,
  },
  {
    label: 'Bauhinia, Queensland',
    value: 10004617,
  },
  {
    label: 'Baulkham Hills, New South Wales',
    value: 10000177,
  },
  {
    label: 'Baulkham Hills, New South Wales',
    value: 10000514,
  },
  {
    label: 'Bauple, Queensland',
    value: 10004242,
  },
  {
    label: 'Bauple Forest, Queensland',
    value: 10004223,
  },
  {
    label: 'Bawley Point, New South Wales',
    value: 10001776,
  },
  {
    label: 'Baxter, Victoria',
    value: 10008252,
  },
  {
    label: 'Bay Village, New South Wales',
    value: 10000793,
  },
  {
    label: 'Bayindeen, Victoria',
    value: 10007433,
  },
  {
    label: 'Bayles, Victoria',
    value: 10008361,
  },
  {
    label: 'Baynton, Western Australia',
    value: 10009985,
  },
  {
    label: 'Bayonet Head, Western Australia',
    value: 10009207,
  },
  {
    label: 'Bayrick, Queensland',
    value: 10003758,
  },
  {
    label: 'Bayswater, Victoria',
    value: 10006949,
  },
  {
    label: 'Bayswater, Western Australia',
    value: 10008502,
  },
  {
    label: 'Bayswater, Western Australia',
    value: 10010116,
  },
  {
    label: 'Bayswater North, Victoria',
    value: 10006950,
  },
  {
    label: 'Bayview, New South Wales',
    value: 10000400,
  },
  {
    label: 'Bayview Heights, Queensland',
    value: 10005090,
  },
  {
    label: 'Beachlands, Western Australia',
    value: 10009755,
  },
  {
    label: 'Beachmere, Queensland',
    value: 10003830,
  },
  {
    label: 'Beachport, South Australia',
    value: 10005758,
  },
  {
    label: 'Beacon, Western Australia',
    value: 10009600,
  },
  {
    label: 'Beacon Hill, New South Wales',
    value: 10000391,
  },
  {
    label: 'Beaconsfield, New South Wales',
    value: 10000215,
  },
  {
    label: 'Beaconsfield, Queensland',
    value: 10004685,
  },
  {
    label: 'Beaconsfield, Tasmania',
    value: 10006479,
  },
  {
    label: 'Beaconsfield, Victoria',
    value: 10008094,
  },
  {
    label: 'Beaconsfield, Western Australia',
    value: 10008701,
  },
  {
    label: 'Beaconsfield Upper, Victoria',
    value: 10008095,
  },
  {
    label: 'Beadell, Western Australia',
    value: 10009533,
  },
  {
    label: 'Bealiba, Victoria',
    value: 10007591,
  },
  {
    label: 'Beaudesert, Queensland',
    value: 10003201,
  },
  {
    label: 'Beaufort, Victoria',
    value: 10007432,
  },
  {
    label: 'Beaufort River, Western Australia',
    value: 10009383,
  },
  {
    label: 'Beaumaris, Tasmania',
    value: 10006360,
  },
  {
    label: 'Beaumaris, Victoria',
    value: 10007026,
  },
  {
    label: 'Beaumont, New South Wales',
    value: 10001961,
  },
  {
    label: 'Beaumont, South Australia',
    value: 10005415,
  },
  {
    label: 'Beaumont, Western Australia',
    value: 10009575,
  },
  {
    label: 'Beaumont Hills, New South Wales',
    value: 10000516,
  },
  {
    label: 'Beauty Point, New South Wales',
    value: 10001836,
  },
  {
    label: 'Beauty Point, Tasmania',
    value: 10006481,
  },
  {
    label: 'Beaver Rock, Queensland',
    value: 10004231,
  },
  {
    label: 'Beckenham, Western Australia',
    value: 10008623,
  },
  {
    label: 'Beckom, New South Wales',
    value: 10002180,
  },
  {
    label: 'Bedford, Western Australia',
    value: 10008501,
  },
  {
    label: 'Bedford Park, South Australia',
    value: 10005347,
  },
  {
    label: 'Bedford Road, Victoria',
    value: 10006906,
  },
  {
    label: 'Bedfordale, Western Australia',
    value: 10008642,
  },
  {
    label: 'Bedgerebong, New South Wales',
    value: 10002646,
  },
  {
    label: 'Bedourie, Queensland',
    value: 10004989,
  },
  {
    label: 'Beeac, Victoria',
    value: 10007172,
  },
  {
    label: 'Beebo, Queensland',
    value: 10003591,
  },
  {
    label: 'Beech Forest, Victoria',
    value: 10007132,
  },
  {
    label: 'Beechboro, Western Australia',
    value: 10008546,
  },
  {
    label: 'Beechford, Tasmania',
    value: 10006404,
  },
  {
    label: 'Beechina, Western Australia',
    value: 10009833,
  },
  {
    label: 'Beechmont, Queensland',
    value: 10003119,
  },
  {
    label: 'Beechwood, New South Wales',
    value: 10001412,
  },
  {
    label: 'Beechworth, Victoria',
    value: 10008017,
  },
  {
    label: 'Beecroft, New South Wales',
    value: 10000443,
  },
  {
    label: 'Beela, Western Australia',
    value: 10008822,
  },
  {
    label: 'Beelbangera, New South Wales',
    value: 10002212,
  },
  {
    label: 'Beelbi Creek, Queensland',
    value: 10004271,
  },
  {
    label: 'Beelerup, Western Australia',
    value: 10008882,
  },
  {
    label: 'Beeliar, Western Australia',
    value: 10008711,
  },
  {
    label: 'Beenaam Valley, Queensland',
    value: 10003963,
  },
  {
    label: 'Beenleigh, Queensland',
    value: 10003098,
  },
  {
    label: 'Beenong, Western Australia',
    value: 10009282,
  },
  {
    label: 'Beerburrum, Queensland',
    value: 10003848,
  },
  {
    label: 'Beermullah, Western Australia',
    value: 10009657,
  },
  {
    label: 'Beeron, Queensland',
    value: 10004180,
  },
  {
    label: 'Beerwah, Queensland',
    value: 10003853,
  },
  {
    label: 'Bega, New South Wales',
    value: 10001870,
  },
  {
    label: 'Beilba, Queensland',
    value: 10003719,
  },
  {
    label: 'Bejoording, Western Australia',
    value: 10009852,
  },
  {
    label: 'Belair, South Australia',
    value: 10005384,
  },
  {
    label: 'Belbora, New South Wales',
    value: 10001282,
  },
  {
    label: 'Belconnen, Australian Capital Territory',
    value: 10000086,
  },
  {
    label: 'Belconnen, Australian Capital Territory',
    value: 10000090,
  },
  {
    label: 'Beldon, Western Australia',
    value: 10008455,
  },
  {
    label: 'Belfield, New South Wales',
    value: 10000599,
  },
  {
    label: 'Belford, New South Wales',
    value: 10001092,
  },
  {
    label: 'Belgian Gardens, Queensland',
    value: 10004866,
  },
  {
    label: 'Belgrave, Victoria',
    value: 10006961,
  },
  {
    label: 'Belhus, Western Australia',
    value: 10008574,
  },
  {
    label: 'Belivah, Queensland',
    value: 10003086,
  },
  {
    label: 'Bell, New South Wales',
    value: 10002418,
  },
  {
    label: 'Bell, Queensland',
    value: 10003660,
  },
  {
    label: 'Bell Bay, Tasmania',
    value: 10006411,
  },
  {
    label: 'Bell Park, Victoria',
    value: 10007066,
  },
  {
    label: 'Bell Post Hill, Victoria',
    value: 10007065,
  },
  {
    label: 'Bella Creek, Queensland',
    value: 10003996,
  },
  {
    label: 'Bella Vista, New South Wales',
    value: 10000512,
  },
  {
    label: 'Bellamack, Northern Territory',
    value: 10002747,
  },
  {
    label: 'Bellambi, New South Wales',
    value: 10001672,
  },
  {
    label: 'Bellangry, New South Wales',
    value: 10001413,
  },
  {
    label: 'Bellara, Queensland',
    value: 10003822,
  },
  {
    label: 'Bellarine, Victoria',
    value: 10007093,
  },
  {
    label: 'Bellata, New South Wales',
    value: 10001244,
  },
  {
    label: 'Bellawongarah, New South Wales',
    value: 10001726,
  },
  {
    label: 'Bellbird, New South Wales',
    value: 10001031,
  },
  {
    label: 'Bellbird Park, Queensland',
    value: 10003210,
  },
  {
    label: 'Bellbowrie, Queensland',
    value: 10002916,
  },
  {
    label: 'Bellbrae, Victoria',
    value: 10007120,
  },
  {
    label: 'Bellbridge, Victoria',
    value: 10007902,
  },
  {
    label: 'Bellbrook, New South Wales',
    value: 10001366,
  },
  {
    label: 'Bellenden Ker, Queensland',
    value: 10005129,
  },
  {
    label: 'Bellerive, Tasmania',
    value: 10006104,
  },
  {
    label: 'Bellevue, Western Australia',
    value: 10008514,
  },
  {
    label: 'Bellevue Heights, South Australia',
    value: 10005377,
  },
  {
    label: 'Bellevue Hill, New South Wales',
    value: 10000231,
  },
  {
    label: 'Bellimbopinni, New South Wales',
    value: 10001368,
  },
  {
    label: 'Bellingen, New South Wales',
    value: 10001454,
  },
  {
    label: 'Bellingham, Tasmania',
    value: 10006415,
  },
  {
    label: 'Bells Creek, Queensland',
    value: 10003883,
  },
  {
    label: 'Belmont, New South Wales',
    value: 10000835,
  },
  {
    label: 'Belmont, Queensland',
    value: 10003029,
  },
  {
    label: 'Belmont, Victoria',
    value: 10007068,
  },
  {
    label: 'Belmont, Western Australia',
    value: 10008612,
  },
  {
    label: 'Belmore, New South Wales',
    value: 10000600,
  },
  {
    label: 'Belmunda, Queensland',
    value: 10004691,
  },
  {
    label: 'Belrose, New South Wales',
    value: 10000360,
  },
  {
    label: 'Belrose West, New South Wales',
    value: 10000358,
  },
  {
    label: 'Beltana, South Australia',
    value: 10006052,
  },
  {
    label: 'Belvedere, Queensland',
    value: 10005048,
  },
  {
    label: 'Belvedere Park, Victoria',
    value: 10007042,
  },
  {
    label: 'Bemboka, New South Wales',
    value: 10001872,
  },
  {
    label: 'Bemm River, Victoria',
    value: 10008229,
  },
  {
    label: 'Ben Buckler, New South Wales',
    value: 10000237,
  },
  {
    label: 'Ben Lomond, New South Wales',
    value: 10001194,
  },
  {
    label: 'Bena, Victoria',
    value: 10008299,
  },
  {
    label: 'Benair, Queensland',
    value: 10004081,
  },
  {
    label: 'Benalla, Victoria',
    value: 10007868,
  },
  {
    label: 'Benalla, Victoria',
    value: 10007869,
  },
  {
    label: 'Benalla, Victoria',
    value: 10007872,
  },
  {
    label: 'Benambra, Victoria',
    value: 10008243,
  },
  {
    label: 'Benandarah, New South Wales',
    value: 10001742,
  },
  {
    label: 'Benaraby, Queensland',
    value: 10004408,
  },
  {
    label: 'Benarch, Victoria',
    value: 10007803,
  },
  {
    label: 'Benarkin, Queensland',
    value: 10003272,
  },
  {
    label: 'Benarkin North, Queensland',
    value: 10003251,
  },
  {
    label: 'Benayeo, Victoria',
    value: 10007284,
  },
  {
    label: 'Bencubbin, Western Australia',
    value: 10009615,
  },
  {
    label: 'Bend Of Islands, Victoria',
    value: 10006843,
  },
  {
    label: 'Bendalong, New South Wales',
    value: 10001772,
  },
  {
    label: 'Bendemeer, New South Wales',
    value: 10001168,
  },
  {
    label: 'Bendick Murrell, New South Wales',
    value: 10002508,
  },
  {
    label: 'Bendigo, Victoria',
    value: 10007672,
  },
  {
    label: 'Bendigo, Victoria',
    value: 10007701,
  },
  {
    label: 'Bendigo Dc, Victoria',
    value: 10007702,
  },
  {
    label: 'Bendoc, Victoria',
    value: 10008224,
  },
  {
    label: 'Benerembah, New South Wales',
    value: 10002211,
  },
  {
    label: 'Benger, Western Australia',
    value: 10008821,
  },
  {
    label: 'Bengworden, Victoria',
    value: 10008202,
  },
  {
    label: 'Benholme, Queensland',
    value: 10004756,
  },
  {
    label: 'Benjaberring, Western Australia',
    value: 10009591,
  },
  {
    label: 'Benjinup, Western Australia',
    value: 10008912,
  },
  {
    label: 'Bennetts Green, New South Wales',
    value: 10000892,
  },
  {
    label: 'Bennettswood, Victoria',
    value: 10006879,
  },
  {
    label: 'Bennison, Victoria',
    value: 10008324,
  },
  {
    label: 'Benowa, Queensland',
    value: 10003155,
  },
  {
    label: 'Bensville, New South Wales',
    value: 10000741,
  },
  {
    label: 'Bentleigh, Victoria',
    value: 10007052,
  },
  {
    label: 'Bentleigh East, Victoria',
    value: 10006972,
  },
  {
    label: 'Bentley, New South Wales',
    value: 10001585,
  },
  {
    label: 'Bentley, Western Australia',
    value: 10008608,
  },
  {
    label: 'Bentley, Western Australia',
    value: 10010149,
  },
  {
    label: 'Bentley Dc, Western Australia',
    value: 10010150,
  },
  {
    label: 'Bentley Park, Queensland',
    value: 10005092,
  },
  {
    label: 'Berajondo, Queensland',
    value: 10004375,
  },
  {
    label: 'Berala, New South Wales',
    value: 10000477,
  },
  {
    label: 'Beremboke, Victoria',
    value: 10007325,
  },
  {
    label: 'Beresfield, New South Wales',
    value: 10000993,
  },
  {
    label: 'Beresford, Western Australia',
    value: 10009753,
  },
  {
    label: 'Bergalia, New South Wales',
    value: 10001766,
  },
  {
    label: 'Bergen, Queensland',
    value: 10003487,
  },
  {
    label: 'Berkeley, New South Wales',
    value: 10001656,
  },
  {
    label: 'Berkeley Vale, New South Wales',
    value: 10000802,
  },
  {
    label: 'Berkshire Park, New South Wales',
    value: 10002365,
  },
  {
    label: 'Berkshire Valley, Western Australia',
    value: 10009688,
  },
  {
    label: 'Bermagui, New South Wales',
    value: 10001840,
  },
  {
    label: 'Bernier Island, Western Australia',
    value: 10009932,
  },
  {
    label: 'Berowra, New South Wales',
    value: 10000349,
  },
  {
    label: 'Berowra Heights, New South Wales',
    value: 10000351,
  },
  {
    label: 'Berowra Waters, New South Wales',
    value: 10000350,
  },
  {
    label: 'Berrambool, New South Wales',
    value: 10001844,
  },
  {
    label: 'Berrara, New South Wales',
    value: 10001806,
  },
  {
    label: 'Berri, South Australia',
    value: 10005809,
  },
  {
    label: 'Berridale, New South Wales',
    value: 10002067,
  },
  {
    label: 'Berriedale, Tasmania',
    value: 10006091,
  },
  {
    label: 'Berrigan, New South Wales',
    value: 10002252,
  },
  {
    label: 'Berrilee, New South Wales',
    value: 10000528,
  },
  {
    label: 'Berrima, New South Wales',
    value: 10001963,
  },
  {
    label: 'Berrimah, Northern Territory',
    value: 10002733,
  },
  {
    label: 'Berrimal, Victoria',
    value: 10007639,
  },
  {
    label: 'Berrinba, Queensland',
    value: 10002983,
  },
  {
    label: 'Berringa, Victoria',
    value: 10007345,
  },
  {
    label: 'Berringama, Victoria',
    value: 10007912,
  },
  {
    label: 'Berringer Lake, New South Wales',
    value: 10001774,
  },
  {
    label: 'Berriwillock, Victoria',
    value: 10007657,
  },
  {
    label: 'Berry, New South Wales',
    value: 10001723,
  },
  {
    label: 'Berry Springs, Northern Territory',
    value: 10002755,
  },
  {
    label: 'Berrybank, Victoria',
    value: 10007287,
  },
  {
    label: 'Berrys Creek, Victoria',
    value: 10008305,
  },
  {
    label: 'Berserker, Queensland',
    value: 10004465,
  },
  {
    label: 'Bertram, Western Australia',
    value: 10008733,
  },
  {
    label: 'Berwick, Victoria',
    value: 10008091,
  },
  {
    label: 'Bet Bet, Victoria',
    value: 10007585,
  },
  {
    label: 'Bethanga, Victoria',
    value: 10007921,
  },
  {
    label: 'Bethania, Queensland',
    value: 10003081,
  },
  {
    label: 'Bethungra, New South Wales',
    value: 10002024,
  },
  {
    label: 'Betley, Victoria',
    value: 10007583,
  },
  {
    label: 'Beulah, Tasmania',
    value: 10006557,
  },
  {
    label: 'Beulah, Victoria',
    value: 10007464,
  },
  {
    label: 'Beulah Park, South Australia',
    value: 10005426,
  },
  {
    label: 'Beverford, Victoria',
    value: 10007757,
  },
  {
    label: 'Beveridge, Victoria',
    value: 10008025,
  },
  {
    label: 'Beverley, South Australia',
    value: 10005257,
  },
  {
    label: 'Beverley, Western Australia',
    value: 10009070,
  },
  {
    label: 'Beverley Park, New South Wales',
    value: 10000653,
  },
  {
    label: 'Beverly Hills, New South Wales',
    value: 10000637,
  },
  {
    label: 'Bexhill, New South Wales',
    value: 10001584,
  },
  {
    label: 'Bexley, New South Wales',
    value: 10000630,
  },
  {
    label: 'Bexley North, New South Wales',
    value: 10000629,
  },
  {
    label: 'Bexley South, New South Wales',
    value: 10000632,
  },
  {
    label: 'Bibbenluke, New South Wales',
    value: 10002086,
  },
  {
    label: 'Bibra Lake, Western Australia',
    value: 10008708,
  },
  {
    label: 'Bibra Lake Dc, Western Australia',
    value: 10010141,
  },
  {
    label: 'Bicheno, Tasmania',
    value: 10006363,
  },
  {
    label: 'Bickley, Western Australia',
    value: 10008588,
  },
  {
    label: 'Bicton, Western Australia',
    value: 10008693,
  },
  {
    label: 'Biddelia, Western Australia',
    value: 10008950,
  },
  {
    label: 'Bidwill, New South Wales',
    value: 10002378,
  },
  {
    label: 'Big Grove, Western Australia',
    value: 10009198,
  },
  {
    label: 'Bigga, New South Wales',
    value: 10002009,
  },
  {
    label: 'Biggara, Victoria',
    value: 10007945,
  },
  {
    label: 'Biggenden, Queensland',
    value: 10004137,
  },
  {
    label: 'Biggera Waters, Queensland',
    value: 10003152,
  },
  {
    label: 'Biggs Flat, South Australia',
    value: 10005602,
  },
  {
    label: 'Bilambil, New South Wales',
    value: 10001629,
  },
  {
    label: 'Bilbarin, Western Australia',
    value: 10009332,
  },
  {
    label: 'Bilbul, New South Wales',
    value: 10002215,
  },
  {
    label: 'Bilgola, New South Wales',
    value: 10000409,
  },
  {
    label: 'Bilinga, Queensland',
    value: 10003179,
  },
  {
    label: 'Bilingurr, Western Australia',
    value: 10010021,
  },
  {
    label: 'Billa Billa, Queensland',
    value: 10003608,
  },
  {
    label: 'Billinudgel, New South Wales',
    value: 10001606,
  },
  {
    label: 'Biloela, Queensland',
    value: 10004603,
  },
  {
    label: 'Bilpin, New South Wales',
    value: 10002345,
  },
  {
    label: 'Bimbi, New South Wales',
    value: 10002514,
  },
  {
    label: 'Bimbijy, Western Australia',
    value: 10009599,
  },
  {
    label: 'Bimbimbie, New South Wales',
    value: 10001751,
  },
  {
    label: 'Binalong, New South Wales',
    value: 10002012,
  },
  {
    label: 'Binalong Bay, Tasmania',
    value: 10006370,
  },
  {
    label: 'Binda, New South Wales',
    value: 10002007,
  },
  {
    label: 'Bindi Bindi, Western Australia',
    value: 10009865,
  },
  {
    label: 'Bindoon, Western Australia',
    value: 10009651,
  },
  {
    label: 'Bindoon Training Area, Western Australia',
    value: 10009652,
  },
  {
    label: 'Binduli, Western Australia',
    value: 10009493,
  },
  {
    label: 'Bingara, New South Wales',
    value: 10001256,
  },
  {
    label: 'Bingegang, Queensland',
    value: 10004467,
  },
  {
    label: 'Bingie, New South Wales',
    value: 10001755,
  },
  {
    label: 'Bingil Bay, Queensland',
    value: 10005016,
  },
  {
    label: 'Binginwarri, Victoria',
    value: 10008332,
  },
  {
    label: 'Binna Burra, New South Wales',
    value: 10001565,
  },
  {
    label: 'Binnaway, New South Wales',
    value: 10001240,
  },
  {
    label: 'Binningup, Western Australia',
    value: 10008866,
  },
  {
    label: 'Binnu, Western Australia',
    value: 10009775,
  },
  {
    label: 'Binnum, South Australia',
    value: 10005730,
  },
  {
    label: 'Binya, New South Wales',
    value: 10002179,
  },
  {
    label: 'Birchgrove, New South Wales',
    value: 10000272,
  },
  {
    label: 'Birchip, Victoria',
    value: 10007602,
  },
  {
    label: 'Birchmont, Western Australia',
    value: 10008803,
  },
  {
    label: 'Birchs Bay, Tasmania',
    value: 10006290,
  },
  {
    label: 'Birdsville, Queensland',
    value: 10003766,
  },
  {
    label: 'Birdwood, South Australia',
    value: 10005681,
  },
  {
    label: 'Birkdale, Queensland',
    value: 10003040,
  },
  {
    label: 'Birkenhead, South Australia',
    value: 10005283,
  },
  {
    label: 'Birmingham Gardens, New South Wales',
    value: 10000876,
  },
  {
    label: 'Birnam, Queensland',
    value: 10003485,
  },
  {
    label: 'Birralee, Tasmania',
    value: 10006518,
  },
  {
    label: 'Birregurra, Victoria',
    value: 10007148,
  },
  {
    label: 'Birriwa, New South Wales',
    value: 10002591,
  },
  {
    label: 'Birrong, New South Wales',
    value: 10000484,
  },
  {
    label: 'Birtinya, Queensland',
    value: 10004013,
  },
  {
    label: 'Bishops Bridge, New South Wales',
    value: 10001047,
  },
  {
    label: 'Bishopsbourne, Tasmania',
    value: 10006512,
  },
  {
    label: 'Bittern, Victoria',
    value: 10008260,
  },
  {
    label: 'Black Duck Creek, Queensland',
    value: 10003363,
  },
  {
    label: 'Black Forest, South Australia',
    value: 10005329,
  },
  {
    label: 'Black Hill, New South Wales',
    value: 10000986,
  },
  {
    label: 'Black Hill, South Australia',
    value: 10005822,
  },
  {
    label: 'Black Hill, Victoria',
    value: 10007342,
  },
  {
    label: 'Black Hills, Tasmania',
    value: 10006243,
  },
  {
    label: 'Black Mountain, Australian Capital Territory',
    value: 10000016,
  },
  {
    label: 'Black Mountain, New South Wales',
    value: 10001197,
  },
  {
    label: 'Black River, Queensland',
    value: 10004931,
  },
  {
    label: 'Black River, Tasmania',
    value: 10006629,
  },
  {
    label: 'Black Rock, Victoria',
    value: 10007025,
  },
  {
    label: 'Black Snake, Queensland',
    value: 10004026,
  },
  {
    label: 'Black Springs, New South Wales',
    value: 10002424,
  },
  {
    label: 'Black Swamp, New South Wales',
    value: 10001214,
  },
  {
    label: 'Blackall, Queensland',
    value: 10003746,
  },
  {
    label: 'Blackalls Park, New South Wales',
    value: 10000849,
  },
  {
    label: 'Blackburn, Victoria',
    value: 10006893,
  },
  {
    label: 'Blackburn North, Victoria',
    value: 10006894,
  },
  {
    label: 'Blackburn South, Victoria',
    value: 10006891,
  },
  {
    label: 'Blackbutt, New South Wales',
    value: 10001697,
  },
  {
    label: 'Blackbutt, Queensland',
    value: 10003276,
  },
  {
    label: 'Blackbutt North, Queensland',
    value: 10003263,
  },
  {
    label: 'Blackbutt South, Queensland',
    value: 10003257,
  },
  {
    label: 'Blackdown, Queensland',
    value: 10004525,
  },
  {
    label: 'Blackett, New South Wales',
    value: 10002376,
  },
  {
    label: 'Blackheath, New South Wales',
    value: 10002412,
  },
  {
    label: 'Blackmans Bay, Tasmania',
    value: 10006150,
  },
  {
    label: 'Blackmans Point, New South Wales',
    value: 10001398,
  },
  {
    label: 'Blacks Beach, Queensland',
    value: 10004696,
  },
  {
    label: 'Blacksmiths, New South Wales',
    value: 10000839,
  },
  {
    label: 'Blacksoil, Queensland',
    value: 10003275,
  },
  {
    label: 'Blackstone, Queensland',
    value: 10003221,
  },
  {
    label: 'Blacktown, New South Wales',
    value: 10000503,
  },
  {
    label: 'Blacktown Westpoint, New South Wales',
    value: 10000502,
  },
  {
    label: 'Blackville, New South Wales',
    value: 10001136,
  },
  {
    label: 'Blackwall, New South Wales',
    value: 10000747,
  },
  {
    label: 'Blackwall, Tasmania',
    value: 10006489,
  },
  {
    label: 'Blackwater, Queensland',
    value: 10004610,
  },
  {
    label: 'Blackwood, South Australia',
    value: 10005382,
  },
  {
    label: 'Blackwood, Victoria',
    value: 10007548,
  },
  {
    label: 'Blackwood Creek, Tasmania',
    value: 10006511,
  },
  {
    label: 'Blair Athol, New South Wales',
    value: 10001892,
  },
  {
    label: 'Blair Athol, South Australia',
    value: 10005482,
  },
  {
    label: 'Blairgowrie, Victoria',
    value: 10008292,
  },
  {
    label: 'Blairmore, Queensland',
    value: 10004142,
  },
  {
    label: 'Blairmount, New South Wales',
    value: 10001886,
  },
  {
    label: 'Blakehurst, New South Wales',
    value: 10000668,
  },
  {
    label: 'Blakeview, South Australia',
    value: 10005554,
  },
  {
    label: 'Blakeville, Victoria',
    value: 10007323,
  },
  {
    label: 'Blampied, Victoria',
    value: 10007424,
  },
  {
    label: 'Blanchetown, South Australia',
    value: 10005834,
  },
  {
    label: 'Blanchview, Queensland',
    value: 10003443,
  },
  {
    label: 'Blandford, New South Wales',
    value: 10001115,
  },
  {
    label: 'Blanket Flat, New South Wales',
    value: 10002011,
  },
  {
    label: 'Blaxcell, New South Wales',
    value: 10000479,
  },
  {
    label: 'Blaxland, New South Wales',
    value: 10002388,
  },
  {
    label: 'Blaxlands Ridge, New South Wales',
    value: 10002344,
  },
  {
    label: 'Blayney, New South Wales',
    value: 10002492,
  },
  {
    label: 'Blenheim Road, New South Wales',
    value: 10000425,
  },
  {
    label: 'Blessington, Tasmania',
    value: 10006348,
  },
  {
    label: 'Bli Bli, Queensland',
    value: 10003918,
  },
  {
    label: 'Bligh Park, New South Wales',
    value: 10002330,
  },
  {
    label: 'Blighty, New South Wales',
    value: 10002254,
  },
  {
    label: 'Blind Bight, Victoria',
    value: 10008358,
  },
  {
    label: 'Blinman, South Australia',
    value: 10006054,
  },
  {
    label: 'Bloomsbury, Queensland',
    value: 10004772,
  },
  {
    label: 'Blowhard, Victoria',
    value: 10007366,
  },
  {
    label: 'Blue Bay, New South Wales',
    value: 10000799,
  },
  {
    label: 'Blue Haven, New South Wales',
    value: 10000804,
  },
  {
    label: 'Blue Mountain, Queensland',
    value: 10004656,
  },
  {
    label: 'Blue Rocks, Tasmania',
    value: 10006421,
  },
  {
    label: 'Bluewater, Queensland',
    value: 10004937,
  },
  {
    label: 'Bluewater Park, Queensland',
    value: 10004934,
  },
  {
    label: 'Bluff, Queensland',
    value: 10004492,
  },
  {
    label: 'Bluff Point, Western Australia',
    value: 10009760,
  },
  {
    label: 'Blumont, Tasmania',
    value: 10006446,
  },
  {
    label: 'Blyth, South Australia',
    value: 10005887,
  },
  {
    label: 'Blythdale, Queensland',
    value: 10003730,
  },
  {
    label: 'Blythewood, Western Australia',
    value: 10008768,
  },
  {
    label: 'Boallia, Western Australia',
    value: 10008973,
  },
  {
    label: 'Boambee, New South Wales',
    value: 10001433,
  },
  {
    label: 'Boat Harbour, New South Wales',
    value: 10000952,
  },
  {
    label: 'Boat Harbour, Tasmania',
    value: 10006626,
  },
  {
    label: 'Boat Harbour Beach, Tasmania',
    value: 10006621,
  },
  {
    label: 'Bobalong, Western Australia',
    value: 10009149,
  },
  {
    label: 'Bobin, New South Wales',
    value: 10001329,
  },
  {
    label: 'Bobs Farm, New South Wales',
    value: 10000953,
  },
  {
    label: 'Bodalla, New South Wales',
    value: 10001829,
  },
  {
    label: 'Bodallin, Western Australia',
    value: 10009457,
  },
  {
    label: 'Bodangora, New South Wales',
    value: 10002527,
  },
  {
    label: 'Boddington, Western Australia',
    value: 10009352,
  },
  {
    label: 'Bogan, New South Wales',
    value: 10002535,
  },
  {
    label: 'Bogan Gate, New South Wales',
    value: 10002653,
  },
  {
    label: 'Bogangar, New South Wales',
    value: 10001639,
  },
  {
    label: 'Boggabilla, New South Wales',
    value: 10001262,
  },
  {
    label: 'Boggabri, New South Wales',
    value: 10001230,
  },
  {
    label: 'Bogie, Queensland',
    value: 10004819,
  },
  {
    label: 'Bogong, Victoria',
    value: 10007930,
  },
  {
    label: 'Bohle, Queensland',
    value: 10004930,
  },
  {
    label: 'Bohle Plains, Queensland',
    value: 10004923,
  },
  {
    label: 'Bohnock, New South Wales',
    value: 10001347,
  },
  {
    label: 'Boho South, Victoria',
    value: 10007862,
  },
  {
    label: 'Boilup, Western Australia',
    value: 10009381,
  },
  {
    label: 'Boisdale, Victoria',
    value: 10008181,
  },
  {
    label: 'Bokal, Western Australia',
    value: 10009372,
  },
  {
    label: 'Bokarina, Queensland',
    value: 10004018,
  },
  {
    label: 'Bolgart, Western Australia',
    value: 10009858,
  },
  {
    label: 'Bolinda, Victoria',
    value: 10007511,
  },
  {
    label: 'Bolivar, South Australia',
    value: 10005534,
  },
  {
    label: 'Bolivia, New South Wales',
    value: 10001217,
  },
  {
    label: 'Bollier, Queensland',
    value: 10003955,
  },
  {
    label: 'Bollon, Queensland',
    value: 10003771,
  },
  {
    label: 'Bolong, New South Wales',
    value: 10001799,
  },
  {
    label: 'Bolton, Victoria',
    value: 10007670,
  },
  {
    label: 'Bolton Point, New South Wales',
    value: 10000858,
  },
  {
    label: 'Bolwarra, New South Wales',
    value: 10000971,
  },
  {
    label: 'Bolwarra, Victoria',
    value: 10007258,
  },
  {
    label: 'Bolwarrah, Victoria',
    value: 10007382,
  },
  {
    label: 'Bomaderry, New South Wales',
    value: 10001825,
  },
  {
    label: 'Bombala, New South Wales',
    value: 10002085,
  },
  {
    label: 'Bombeeta, Queensland',
    value: 10005143,
  },
  {
    label: 'Bombo, New South Wales',
    value: 10001712,
  },
  {
    label: 'Bon Accord, Queensland',
    value: 10004160,
  },
  {
    label: 'Bonalbo, New South Wales',
    value: 10001499,
  },
  {
    label: 'Bonang, Victoria',
    value: 10008228,
  },
  {
    label: 'Bonbeach, Victoria',
    value: 10007037,
  },
  {
    label: 'Bond University, Queensland',
    value: 10003187,
  },
  {
    label: 'Bondi, New South Wales',
    value: 10000236,
  },
  {
    label: 'Bondi Beach, New South Wales',
    value: 10000235,
  },
  {
    label: 'Bondi Junction, New South Wales',
    value: 10000136,
  },
  {
    label: 'Bondi Junction, New South Wales',
    value: 10000229,
  },
  {
    label: 'Bonegilla, Victoria',
    value: 10007914,
  },
  {
    label: 'Bongaree, Queensland',
    value: 10003823,
  },
  {
    label: 'Bongeen, Queensland',
    value: 10003507,
  },
  {
    label: 'Bonnells Bay, New South Wales',
    value: 10000818,
  },
  {
    label: 'Bonnet Bay, New South Wales',
    value: 10000680,
  },
  {
    label: 'Bonnet Hill, Tasmania',
    value: 10006152,
  },
  {
    label: 'Bonnie Doon, Victoria',
    value: 10007972,
  },
  {
    label: 'Bonnie Rock, Western Australia',
    value: 10009620,
  },
  {
    label: 'Bonniefield, Western Australia',
    value: 10009739,
  },
  {
    label: 'Bonny Hills, New South Wales',
    value: 10001401,
  },
  {
    label: 'Bonnyrigg, New South Wales',
    value: 10000590,
  },
  {
    label: 'Bonnyrigg Heights, New South Wales',
    value: 10000589,
  },
  {
    label: 'Bonogin, Queensland',
    value: 10003133,
  },
  {
    label: 'Bonshaw, New South Wales',
    value: 10001191,
  },
  {
    label: 'Bonshaw, Queensland',
    value: 10003588,
  },
  {
    label: 'Bonville, New South Wales',
    value: 10001378,
  },
  {
    label: 'Bonython, Australian Capital Territory',
    value: 10000114,
  },
  {
    label: 'Booborowie, South Australia',
    value: 10005860,
  },
  {
    label: 'Boodarie, Western Australia',
    value: 10010011,
  },
  {
    label: 'Boodarockin, Western Australia',
    value: 10009452,
  },
  {
    label: 'Boodua, Queensland',
    value: 10003618,
  },
  {
    label: 'Boogan, Queensland',
    value: 10005134,
  },
  {
    label: 'Booie, Queensland',
    value: 10004083,
  },
  {
    label: 'Bookara, Western Australia',
    value: 10009734,
  },
  {
    label: 'Booker Bay, New South Wales',
    value: 10000757,
  },
  {
    label: 'Bookham, New South Wales',
    value: 10001997,
  },
  {
    label: 'Boolading, Western Australia',
    value: 10009370,
  },
  {
    label: 'Boolambayte, New South Wales',
    value: 10001288,
  },
  {
    label: 'Boolaroo, New South Wales',
    value: 10000868,
  },
  {
    label: 'Boolarra, Victoria',
    value: 10008190,
  },
  {
    label: 'Boolboonda, Queensland',
    value: 10004337,
  },
  {
    label: 'Booleroo Centre, South Australia',
    value: 10005898,
  },
  {
    label: 'Booligal, New South Wales',
    value: 10002250,
  },
  {
    label: 'Boomi, New South Wales',
    value: 10001258,
  },
  {
    label: 'Boompa, Queensland',
    value: 10004134,
  },
  {
    label: 'Boonah, Queensland',
    value: 10003294,
  },
  {
    label: 'Boonanarring, Western Australia',
    value: 10009663,
  },
  {
    label: 'Boonara, Queensland',
    value: 10004032,
  },
  {
    label: 'Boondall, Queensland',
    value: 10002861,
  },
  {
    label: 'Boondandilla, Queensland',
    value: 10003650,
  },
  {
    label: 'Boondooma, Queensland',
    value: 10004099,
  },
  {
    label: 'Boonoo Boonoo, New South Wales',
    value: 10001212,
  },
  {
    label: 'Boonooroo, Queensland',
    value: 10004220,
  },
  {
    label: 'Boonooroo Plains, Queensland',
    value: 10004221,
  },
  {
    label: 'Boorabbin, Western Australia',
    value: 10009482,
  },
  {
    label: 'Booragoon, Western Australia',
    value: 10008683,
  },
  {
    label: 'Booragoon, Western Australia',
    value: 10010131,
  },
  {
    label: 'Booragul, New South Wales',
    value: 10000863,
  },
  {
    label: 'Booral, New South Wales',
    value: 10001304,
  },
  {
    label: 'Booral, Queensland',
    value: 10004251,
  },
  {
    label: 'Booralaming, Western Australia',
    value: 10009608,
  },
  {
    label: 'Boorara, Western Australia',
    value: 10009507,
  },
  {
    label: 'Boorcan, Victoria',
    value: 10007182,
  },
  {
    label: 'Boorhaman, Victoria',
    value: 10007885,
  },
  {
    label: 'Boorhaman North, Victoria',
    value: 10007892,
  },
  {
    label: 'Booroobin, Queensland',
    value: 10003890,
  },
  {
    label: 'Boorowa, New South Wales',
    value: 10002017,
  },
  {
    label: 'Boort, Victoria',
    value: 10007662,
  },
  {
    label: 'Bootenal, Western Australia',
    value: 10009793,
  },
  {
    label: 'Boothendarra, Western Australia',
    value: 10009716,
  },
  {
    label: 'Booubyjan, Queensland',
    value: 10004030,
  },
  {
    label: 'Booval, Queensland',
    value: 10003219,
  },
  {
    label: 'Booyal, Queensland',
    value: 10004345,
  },
  {
    label: 'Borallon, Queensland',
    value: 10003254,
  },
  {
    label: 'Borambil, New South Wales',
    value: 10001057,
  },
  {
    label: 'Boraning, Western Australia',
    value: 10009364,
  },
  {
    label: 'Boranup, Western Australia',
    value: 10009025,
  },
  {
    label: 'Borden, Western Australia',
    value: 10009257,
  },
  {
    label: 'Borderdale, Western Australia',
    value: 10009147,
  },
  {
    label: 'Bordertown, South Australia',
    value: 10005737,
  },
  {
    label: 'Boree Creek, New South Wales',
    value: 10002153,
  },
  {
    label: 'Boreen Point, Queensland',
    value: 10003940,
  },
  {
    label: 'Borenore, New South Wales',
    value: 10002504,
  },
  {
    label: 'Bornholm, Western Australia',
    value: 10009187,
  },
  {
    label: 'Boro, New South Wales',
    value: 10002055,
  },
  {
    label: 'Boronia, Victoria',
    value: 10006952,
  },
  {
    label: 'Boronia Heights, Queensland',
    value: 10003003,
  },
  {
    label: 'Bororen, Queensland',
    value: 10004393,
  },
  {
    label: 'Borroloola, Northern Territory',
    value: 10002772,
  },
  {
    label: 'Borung, Victoria',
    value: 10007645,
  },
  {
    label: 'Boscabel, Western Australia',
    value: 10009380,
  },
  {
    label: 'Bossley Park, New South Wales',
    value: 10000583,
  },
  {
    label: 'Bostobrick, New South Wales',
    value: 10001441,
  },
  {
    label: 'Botany, New South Wales',
    value: 10000142,
  },
  {
    label: 'Botany, New South Wales',
    value: 10000223,
  },
  {
    label: 'Bothwell, Tasmania',
    value: 10006133,
  },
  {
    label: 'Boulder, Western Australia',
    value: 10009512,
  },
  {
    label: 'Boulder Creek, Queensland',
    value: 10004598,
  },
  {
    label: 'Bouldercombe, Queensland',
    value: 10004497,
  },
  {
    label: 'Boulia, Queensland',
    value: 10004993,
  },
  {
    label: 'Boundain, Western Australia',
    value: 10009102,
  },
  {
    label: 'Boundary Bend, Victoria',
    value: 10007763,
  },
  {
    label: 'Bourke, New South Wales',
    value: 10002587,
  },
  {
    label: 'Bournda, New South Wales',
    value: 10001862,
  },
  {
    label: 'Bouvard, Western Australia',
    value: 10008793,
  },
  {
    label: 'Bovell, Western Australia',
    value: 10008987,
  },
  {
    label: 'Bow Bowing, New South Wales',
    value: 10001910,
  },
  {
    label: 'Bow Bridge, Western Australia',
    value: 10009231,
  },
  {
    label: 'Bowden, South Australia',
    value: 10005247,
  },
  {
    label: 'Bowelling, Western Australia',
    value: 10008841,
  },
  {
    label: 'Bowen, New South Wales',
    value: 10002498,
  },
  {
    label: 'Bowen, Queensland',
    value: 10004820,
  },
  {
    label: 'Bowen Hills, Queensland',
    value: 10002815,
  },
  {
    label: 'Bowen Mountain, New South Wales',
    value: 10002318,
  },
  {
    label: 'Bowenfels, New South Wales',
    value: 10002429,
  },
  {
    label: 'Bowenville, Queensland',
    value: 10003640,
  },
  {
    label: 'Bower, South Australia',
    value: 10005845,
  },
  {
    label: 'Bowes, Western Australia',
    value: 10009821,
  },
  {
    label: 'Bowgada, Western Australia',
    value: 10009891,
  },
  {
    label: 'Bowhill, South Australia',
    value: 10005688,
  },
  {
    label: 'Bowling Alley Point, New South Wales',
    value: 10001132,
  },
  {
    label: 'Bowmans, South Australia',
    value: 10005928,
  },
  {
    label: 'Bowmans Forest, Victoria',
    value: 10007997,
  },
  {
    label: 'Bowna, New South Wales',
    value: 10002109,
  },
  {
    label: 'Bowning, New South Wales',
    value: 10001998,
  },
  {
    label: 'Bowral, New South Wales',
    value: 10001954,
  },
  {
    label: 'Bowraville, New South Wales',
    value: 10001422,
  },
  {
    label: 'Box Hill, New South Wales',
    value: 10002361,
  },
  {
    label: 'Box Hill, Victoria',
    value: 10006884,
  },
  {
    label: 'Box Hill North, Victoria',
    value: 10006889,
  },
  {
    label: 'Box Hill South, Victoria',
    value: 10006885,
  },
  {
    label: 'Boxwood, Victoria',
    value: 10007982,
  },
  {
    label: 'Boxwood Hill, Western Australia',
    value: 10009258,
  },
  {
    label: 'Boya, Western Australia',
    value: 10008518,
  },
  {
    label: 'Boyanup, Western Australia',
    value: 10008876,
  },
  {
    label: 'Boyatup, Western Australia',
    value: 10009565,
  },
  {
    label: 'Boyer, Tasmania',
    value: 10006230,
  },
  {
    label: 'Boyerine, Western Australia',
    value: 10009124,
  },
  {
    label: 'Boyne Island, Queensland',
    value: 10004406,
  },
  {
    label: 'Boyne Valley, Queensland',
    value: 10004397,
  },
  {
    label: 'Boynedale, Queensland',
    value: 10004412,
  },
  {
    label: 'Boyneside, Queensland',
    value: 10004076,
  },
  {
    label: 'Boynewood, Queensland',
    value: 10004181,
  },
  {
    label: 'Boyup Brook, Western Australia',
    value: 10008902,
  },
  {
    label: 'Bracalba, Queensland',
    value: 10003836,
  },
  {
    label: 'Bracewell, Queensland',
    value: 10004437,
  },
  {
    label: 'Bracken Ridge, Queensland',
    value: 10002834,
  },
  {
    label: 'Bracknell, Tasmania',
    value: 10006517,
  },
  {
    label: 'Bradbury, New South Wales',
    value: 10001894,
  },
  {
    label: 'Bradbury, South Australia',
    value: 10005608,
  },
  {
    label: 'Braddon, Australian Capital Territory',
    value: 10000061,
  },
  {
    label: 'Bradys Lake, Tasmania',
    value: 10006236,
  },
  {
    label: 'Braemore, Queensland',
    value: 10003315,
  },
  {
    label: 'Braeside, Victoria',
    value: 10007032,
  },
  {
    label: 'Brahma Lodge, South Australia',
    value: 10005531,
  },
  {
    label: 'Braidwood, New South Wales',
    value: 10002045,
  },
  {
    label: 'Bramley, Western Australia',
    value: 10009017,
  },
  {
    label: 'Brampton Island, Queensland',
    value: 10004707,
  },
  {
    label: 'Branch Creek, Queensland',
    value: 10004144,
  },
  {
    label: 'Branchview, Queensland',
    value: 10003446,
  },
  {
    label: 'Branditt, Victoria',
    value: 10007802,
  },
  {
    label: 'Brandon, Queensland',
    value: 10004848,
  },
  {
    label: 'Brandy Creek, Queensland',
    value: 10004773,
  },
  {
    label: 'Brandy Hill, New South Wales',
    value: 10001009,
  },
  {
    label: 'Branxholm, Tasmania',
    value: 10006453,
  },
  {
    label: 'Branxholme, Victoria',
    value: 10007253,
  },
  {
    label: 'Branxton, New South Wales',
    value: 10001090,
  },
  {
    label: 'Branyan, Queensland',
    value: 10004300,
  },
  {
    label: 'Brassall, Queensland',
    value: 10003227,
  },
  {
    label: 'Bray Park, New South Wales',
    value: 10001609,
  },
  {
    label: 'Bray Park, Queensland',
    value: 10003803,
  },
  {
    label: 'Braybrook, Victoria',
    value: 10006702,
  },
  {
    label: 'Brazier, Western Australia',
    value: 10008903,
  },
  {
    label: 'Breadalbane, New South Wales',
    value: 10001990,
  },
  {
    label: 'Breadalbane, Queensland',
    value: 10004779,
  },
  {
    label: 'Breadalbane, Tasmania',
    value: 10006437,
  },
  {
    label: 'Breakaway, Queensland',
    value: 10004966,
  },
  {
    label: 'Breakwater, Victoria',
    value: 10007081,
  },
  {
    label: 'Bream Creek, Tasmania',
    value: 10006313,
  },
  {
    label: 'Breamlea, Victoria',
    value: 10007116,
  },
  {
    label: 'Bredbo, New South Wales',
    value: 10002062,
  },
  {
    label: 'Breera, Western Australia',
    value: 10009661,
  },
  {
    label: 'Breeza, New South Wales',
    value: 10001225,
  },
  {
    label: 'Bremer, Queensland',
    value: 10003237,
  },
  {
    label: 'Bremer Bay, Western Australia',
    value: 10009259,
  },
  {
    label: 'Brendale, Queensland',
    value: 10003802,
  },
  {
    label: 'Brentford Square, Victoria',
    value: 10006896,
  },
  {
    label: 'Brentwood, South Australia',
    value: 10005967,
  },
  {
    label: 'Brentwood, Western Australia',
    value: 10008679,
  },
  {
    label: 'Breona, Tasmania',
    value: 10006533,
  },
  {
    label: 'Breton Bay, Western Australia',
    value: 10008491,
  },
  {
    label: 'Bretti, New South Wales',
    value: 10001276,
  },
  {
    label: 'Brewarrina, New South Wales',
    value: 10002577,
  },
  {
    label: 'Brewongle, New South Wales',
    value: 10002482,
  },
  {
    label: 'Briagolong, Victoria',
    value: 10008179,
  },
  {
    label: 'Briar Hill, Victoria',
    value: 10006830,
  },
  {
    label: 'Bribbaree, New South Wales',
    value: 10002029,
  },
  {
    label: 'Bribie Island, Queensland',
    value: 10003816,
  },
  {
    label: 'Bribie Island North, Queensland',
    value: 10003818,
  },
  {
    label: 'Bridgeman Downs, Queensland',
    value: 10002866,
  },
  {
    label: 'Bridgenorth, Tasmania',
    value: 10006499,
  },
  {
    label: 'Bridgetown, Western Australia',
    value: 10008914,
  },
  {
    label: 'Bridgewater, South Australia',
    value: 10005612,
  },
  {
    label: 'Bridgewater, Tasmania',
    value: 10006144,
  },
  {
    label: 'Bridgewater On Loddon, Victoria',
    value: 10007634,
  },
  {
    label: 'Bridport, Tasmania',
    value: 10006455,
  },
  {
    label: 'Brigadoon, Western Australia',
    value: 10008573,
  },
  {
    label: 'Brigalow, Queensland',
    value: 10003663,
  },
  {
    label: 'Briggsvale, New South Wales',
    value: 10001443,
  },
  {
    label: 'Bright, Victoria',
    value: 10008011,
  },
  {
    label: 'Brighton, Queensland',
    value: 10002836,
  },
  {
    label: 'Brighton, South Australia',
    value: 10005370,
  },
  {
    label: 'Brighton, Tasmania',
    value: 10006125,
  },
  {
    label: 'Brighton, Victoria',
    value: 10007010,
  },
  {
    label: 'Brighton East, Victoria',
    value: 10007013,
  },
  {
    label: 'Brighton Road, Victoria',
    value: 10007006,
  },
  {
    label: 'Brighton-Le-Sands, New South Wales',
    value: 10000652,
  },
  {
    label: 'Brightwaters, New South Wales',
    value: 10000816,
  },
  {
    label: 'Brim, Victoria',
    value: 10007460,
  },
  {
    label: 'Brindabella, New South Wales',
    value: 10002030,
  },
  {
    label: 'Bringalily, Queensland',
    value: 10003521,
  },
  {
    label: 'Bringelly, New South Wales',
    value: 10001879,
  },
  {
    label: 'Bringenbrong, New South Wales',
    value: 10002673,
  },
  {
    label: 'Bringo, Western Australia',
    value: 10009785,
  },
  {
    label: 'Brinkin, Northern Territory',
    value: 10002687,
  },
  {
    label: 'Brinkworth, South Australia',
    value: 10005889,
  },
  {
    label: 'Brinsmead, Queensland',
    value: 10005100,
  },
  {
    label: 'Brisbane, Queensland',
    value: 10002803,
  },
  {
    label: 'Brisbane, Queensland',
    value: 10002807,
  },
  {
    label: 'Brisbane Adelaide Street, Queensland',
    value: 10002801,
  },
  {
    label: 'Brisbane Market, Queensland',
    value: 10002953,
  },
  {
    label: 'Brittons Swamp, Tasmania',
    value: 10006652,
  },
  {
    label: 'Broadbeach, Queensland',
    value: 10003161,
  },
  {
    label: 'Broadbeach Waters, Queensland',
    value: 10003162,
  },
  {
    label: 'Broadford, Victoria',
    value: 10007842,
  },
  {
    label: 'Broadmarsh, Tasmania',
    value: 10006140,
  },
  {
    label: 'Broadmeadow, New South Wales',
    value: 10000902,
  },
  {
    label: 'Broadmeadows, Victoria',
    value: 10006762,
  },
  {
    label: 'Broadmere, Queensland',
    value: 10003687,
  },
  {
    label: 'Broadview, South Australia',
    value: 10005477,
  },
  {
    label: 'Broadwater, New South Wales',
    value: 10001528,
  },
  {
    label: 'Broadwater, New South Wales',
    value: 10001847,
  },
  {
    label: 'Broadwater, Victoria',
    value: 10007249,
  },
  {
    label: 'Broadwater, Western Australia',
    value: 10008985,
  },
  {
    label: 'Broadway, New South Wales',
    value: 10000201,
  },
  {
    label: 'Broadway Nedlands, Western Australia',
    value: 10008410,
  },
  {
    label: 'Broadwood, Western Australia',
    value: 10009487,
  },
  {
    label: 'Brocklehurst, New South Wales',
    value: 10002546,
  },
  {
    label: 'Brocklesby, New South Wales',
    value: 10002100,
  },
  {
    label: 'Brockman, Western Australia',
    value: 10009938,
  },
  {
    label: 'Brogo, New South Wales',
    value: 10001868,
  },
  {
    label: 'Broke, New South Wales',
    value: 10001067,
  },
  {
    label: 'Broke, Western Australia',
    value: 10009393,
  },
  {
    label: 'Broken Head, New South Wales',
    value: 10001594,
  },
  {
    label: 'Broken Hill, New South Wales',
    value: 10002667,
  },
  {
    label: 'Broken River, Queensland',
    value: 10004764,
  },
  {
    label: 'Brompton, South Australia',
    value: 10005245,
  },
  {
    label: 'Bronte, New South Wales',
    value: 10000233,
  },
  {
    label: 'Bronte Park, Tasmania',
    value: 10006229,
  },
  {
    label: 'Brookdale, Western Australia',
    value: 10008638,
  },
  {
    label: 'Brookfield, Queensland',
    value: 10002910,
  },
  {
    label: 'Brookfield, Victoria',
    value: 10007311,
  },
  {
    label: 'Brookhampton, Western Australia',
    value: 10008888,
  },
  {
    label: 'Brookhill, Queensland',
    value: 10004920,
  },
  {
    label: 'Brooklet, New South Wales',
    value: 10001563,
  },
  {
    label: 'Brooklyn, New South Wales',
    value: 10000355,
  },
  {
    label: 'Brooklyn, Tasmania',
    value: 10006612,
  },
  {
    label: 'Brooklyn, Victoria',
    value: 10006692,
  },
  {
    label: 'Brooklyn Park, South Australia',
    value: 10005311,
  },
  {
    label: 'Brookside Centre, Queensland',
    value: 10002881,
  },
  {
    label: 'Brookstead, Queensland',
    value: 10003567,
  },
  {
    label: 'Brookton, Western Australia',
    value: 10009072,
  },
  {
    label: 'Brookvale, New South Wales',
    value: 10000388,
  },
  {
    label: 'Brookwater, Queensland',
    value: 10003206,
  },
  {
    label: 'Broome, Western Australia',
    value: 10010019,
  },
  {
    label: 'Broomehill, Western Australia',
    value: 10009140,
  },
  {
    label: 'Broomehill East, Western Australia',
    value: 10009142,
  },
  {
    label: 'Broomehill Village, Western Australia',
    value: 10009141,
  },
  {
    label: 'Broomehill West, Western Australia',
    value: 10009143,
  },
  {
    label: 'Broomfield, Victoria',
    value: 10007416,
  },
  {
    label: 'Brooms Head, New South Wales',
    value: 10001489,
  },
  {
    label: 'Brooweena, Queensland',
    value: 10004122,
  },
  {
    label: 'Broulee, New South Wales',
    value: 10001756,
  },
  {
    label: 'Brovinia, Queensland',
    value: 10004171,
  },
  {
    label: 'Brown Hill, Victoria',
    value: 10007338,
  },
  {
    label: 'Brown Hill, Western Australia',
    value: 10009509,
  },
  {
    label: 'Brown Hill Creek, South Australia',
    value: 10005394,
  },
  {
    label: 'Brown Range, Western Australia',
    value: 10009936,
  },
  {
    label: 'Browns Creek, New South Wales',
    value: 10002491,
  },
  {
    label: 'Browns Plains, Queensland',
    value: 10002984,
  },
  {
    label: 'Browns Plains, Victoria',
    value: 10007894,
  },
  {
    label: 'Brownsville, New South Wales',
    value: 10001711,
  },
  {
    label: 'Broxburn, Queensland',
    value: 10003506,
  },
  {
    label: 'Bruce, Australian Capital Territory',
    value: 10000093,
  },
  {
    label: 'Bruce Rock, Western Australia',
    value: 10009445,
  },
  {
    label: 'Brukunga, South Australia',
    value: 10005704,
  },
  {
    label: 'Brundee, New South Wales',
    value: 10001810,
  },
  {
    label: 'Brungle, New South Wales',
    value: 10002265,
  },
  {
    label: 'Brunkerville, New South Wales',
    value: 10001002,
  },
  {
    label: 'Brunswick, Victoria',
    value: 10006778,
  },
  {
    label: 'Brunswick, Western Australia',
    value: 10008823,
  },
  {
    label: 'Brunswick East, Victoria',
    value: 10006779,
  },
  {
    label: 'Brunswick Heads, New South Wales',
    value: 10001604,
  },
  {
    label: 'Brunswick West, Victoria',
    value: 10006777,
  },
  {
    label: 'Brush Creek, Queensland',
    value: 10003595,
  },
  {
    label: 'Brushgrove, New South Wales',
    value: 10001483,
  },
  {
    label: 'Bruthen, Victoria',
    value: 10008217,
  },
  {
    label: 'Bryden, Queensland',
    value: 10003309,
  },
  {
    label: 'Brymaroo, Queensland',
    value: 10003633,
  },
  {
    label: 'Buangor, Victoria',
    value: 10007434,
  },
  {
    label: 'Buaraba South, Queensland',
    value: 10003298,
  },
  {
    label: 'Bucasia, Queensland',
    value: 10004749,
  },
  {
    label: 'Buccan, Queensland',
    value: 10003096,
  },
  {
    label: 'Buchan, Victoria',
    value: 10008211,
  },
  {
    label: 'Buchfelde, South Australia',
    value: 10005562,
  },
  {
    label: 'Buckenbowra, New South Wales',
    value: 10001752,
  },
  {
    label: 'Buckingham, Queensland',
    value: 10004970,
  },
  {
    label: 'Buckingham, Western Australia',
    value: 10008829,
  },
  {
    label: 'Buckland, Tasmania',
    value: 10006332,
  },
  {
    label: 'Buckland, Western Australia',
    value: 10009410,
  },
  {
    label: 'Buckland Park, South Australia',
    value: 10005570,
  },
  {
    label: 'Buckleboo, South Australia',
    value: 10006012,
  },
  {
    label: 'Buckley, Victoria',
    value: 10007144,
  },
  {
    label: 'Budderoo, New South Wales',
    value: 10001721,
  },
  {
    label: 'Buddina, Queensland',
    value: 10004017,
  },
  {
    label: 'Buderim, Queensland',
    value: 10003908,
  },
  {
    label: 'Budgee, Queensland',
    value: 10003545,
  },
  {
    label: 'Budgewoi, New South Wales',
    value: 10000807,
  },
  {
    label: 'Buff Point, New South Wales',
    value: 10000806,
  },
  {
    label: 'Buffalo, Victoria',
    value: 10008318,
  },
  {
    label: 'Buffalo River, Victoria',
    value: 10008002,
  },
  {
    label: 'Bugaldie, New South Wales',
    value: 10001173,
  },
  {
    label: 'Bugle Ranges, South Australia',
    value: 10005701,
  },
  {
    label: 'Builyan, Queensland',
    value: 10004422,
  },
  {
    label: 'Bukali, Queensland',
    value: 10004202,
  },
  {
    label: 'Bukkulla, New South Wales',
    value: 10001181,
  },
  {
    label: 'Bulahdelah, New South Wales',
    value: 10001293,
  },
  {
    label: 'Bulga, New South Wales',
    value: 10001060,
  },
  {
    label: 'Bulgarra, Western Australia',
    value: 10009976,
  },
  {
    label: 'Bulimba, Queensland',
    value: 10003057,
  },
  {
    label: 'Bull Creek, Western Australia',
    value: 10008666,
  },
  {
    label: 'Bulla, Victoria',
    value: 10007507,
  },
  {
    label: 'Bullabulling, Western Australia',
    value: 10009480,
  },
  {
    label: 'Bullaburra, New South Wales',
    value: 10002411,
  },
  {
    label: 'Bullaring, Western Australia',
    value: 10009329,
  },
  {
    label: 'Bullarook, Victoria',
    value: 10007372,
  },
  {
    label: 'Bullarto, Victoria',
    value: 10007552,
  },
  {
    label: 'Bullcamp, Queensland',
    value: 10004109,
  },
  {
    label: 'Bulleen, Victoria',
    value: 10006854,
  },
  {
    label: 'Bullengarook, Victoria',
    value: 10007515,
  },
  {
    label: 'Buller, Western Australia',
    value: 10009811,
  },
  {
    label: 'Bullfinch, Western Australia',
    value: 10009628,
  },
  {
    label: 'Bulli, New South Wales',
    value: 10001669,
  },
  {
    label: 'Bulli Creek, Queensland',
    value: 10003536,
  },
  {
    label: 'Bullock Hills, Western Australia',
    value: 10009133,
  },
  {
    label: 'Bullsbrook, Western Australia',
    value: 10008600,
  },
  {
    label: 'Buln Buln, Victoria',
    value: 10008116,
  },
  {
    label: 'Bulong, Western Australia',
    value: 10009506,
  },
  {
    label: 'Bulwer, Queensland',
    value: 10002852,
  },
  {
    label: 'Bulyee, Western Australia',
    value: 10009073,
  },
  {
    label: 'Bumbaldry, New South Wales',
    value: 10002447,
  },
  {
    label: 'Bunbartha, Victoria',
    value: 10007817,
  },
  {
    label: 'Bunbury, Western Australia',
    value: 10008849,
  },
  {
    label: 'Bunbury, Western Australia',
    value: 10008860,
  },
  {
    label: 'Bundabah, New South Wales',
    value: 10001007,
  },
  {
    label: 'Bundaberg, Queensland',
    value: 10004311,
  },
  {
    label: 'Bundaberg Central, Queensland',
    value: 10004336,
  },
  {
    label: 'Bundaberg Dc, Queensland',
    value: 10004298,
  },
  {
    label: 'Bundaberg East, Queensland',
    value: 10004334,
  },
  {
    label: 'Bundaberg North, Queensland',
    value: 10004294,
  },
  {
    label: 'Bundaberg South, Queensland',
    value: 10004308,
  },
  {
    label: 'Bundaberg West, Queensland',
    value: 10004335,
  },
  {
    label: 'Bundalaguah, Victoria',
    value: 10008160,
  },
  {
    label: 'Bundall, Queensland',
    value: 10003158,
  },
  {
    label: 'Bundalong, Victoria',
    value: 10007992,
  },
  {
    label: 'Bundamba, Queensland',
    value: 10003224,
  },
  {
    label: 'Bundanoon, New South Wales',
    value: 10001969,
  },
  {
    label: 'Bundanoon, Western Australia',
    value: 10009725,
  },
  {
    label: 'Bundarra, New South Wales',
    value: 10001179,
  },
  {
    label: 'Bundeena, New South Wales',
    value: 10000692,
  },
  {
    label: 'Bundook, New South Wales',
    value: 10001285,
  },
  {
    label: 'Bundoora, Victoria',
    value: 10006820,
  },
  {
    label: 'Bungador, Victoria',
    value: 10007178,
  },
  {
    label: 'Bungalow, Queensland',
    value: 10005115,
  },
  {
    label: 'Bungarby, New South Wales',
    value: 10002074,
  },
  {
    label: 'Bungaree, Victoria',
    value: 10007376,
  },
  {
    label: 'Bungeet, Victoria',
    value: 10007984,
  },
  {
    label: 'Bungendore, New South Wales',
    value: 10002043,
  },
  {
    label: 'Bungeworgorai, Queensland',
    value: 10003738,
  },
  {
    label: 'Bungil, Queensland',
    value: 10003731,
  },
  {
    label: 'Bungonia, New South Wales',
    value: 10001982,
  },
  {
    label: 'Bungulla, New South Wales',
    value: 10001218,
  },
  {
    label: 'Bungulla, Western Australia',
    value: 10009427,
  },
  {
    label: 'Bungunya, Queensland',
    value: 10003788,
  },
  {
    label: 'Bungwahl, New South Wales',
    value: 10001291,
  },
  {
    label: 'Buniche, Western Australia',
    value: 10009286,
  },
  {
    label: 'Buninyong, Victoria',
    value: 10007411,
  },
  {
    label: 'Bunjil, Western Australia',
    value: 10009892,
  },
  {
    label: 'Bunnaloo, New South Wales',
    value: 10002283,
  },
  {
    label: 'Bunnan, New South Wales',
    value: 10001107,
  },
  {
    label: 'Buntine, Western Australia',
    value: 10009886,
  },
  {
    label: 'Bunya, Queensland',
    value: 10002887,
  },
  {
    label: 'Bunya Creek, Queensland',
    value: 10004253,
  },
  {
    label: 'Bunya Mountains, Queensland',
    value: 10003643,
  },
  {
    label: 'Bunyah, New South Wales',
    value: 10001324,
  },
  {
    label: 'Bunyan, New South Wales',
    value: 10002072,
  },
  {
    label: 'Bunyip, Victoria',
    value: 10008107,
  },
  {
    label: 'Burakin, Western Australia',
    value: 10009594,
  },
  {
    label: 'Buraminya, Western Australia',
    value: 10009580,
  },
  {
    label: 'Buranda, Queensland',
    value: 10002944,
  },
  {
    label: 'Burbank, Queensland',
    value: 10003035,
  },
  {
    label: 'Burburgate, New South Wales',
    value: 10001220,
  },
  {
    label: 'Burcher, New South Wales',
    value: 10002204,
  },
  {
    label: 'Burekup, Western Australia',
    value: 10008843,
  },
  {
    label: 'Burges, Western Australia',
    value: 10009043,
  },
  {
    label: 'Burgooney, New South Wales',
    value: 10002207,
  },
  {
    label: 'Burgowan, Queensland',
    value: 10004273,
  },
  {
    label: 'Burketown, Queensland',
    value: 10004998,
  },
  {
    label: 'Burleigh Heads, Queensland',
    value: 10003168,
  },
  {
    label: 'Burleigh Town, Queensland',
    value: 10003169,
  },
  {
    label: 'Burleigh Waters, Queensland',
    value: 10003167,
  },
  {
    label: 'Burlong, Western Australia',
    value: 10009402,
  },
  {
    label: 'Burma Road, Western Australia',
    value: 10009774,
  },
  {
    label: 'Burnett Heads, Queensland',
    value: 10004319,
  },
  {
    label: 'Burnie, Tasmania',
    value: 10006615,
  },
  {
    label: 'Burnley, Victoria',
    value: 10006869,
  },
  {
    label: 'Burns Beach, Western Australia',
    value: 10008464,
  },
  {
    label: 'Burns Creek, Tasmania',
    value: 10006343,
  },
  {
    label: 'Burnside, South Australia',
    value: 10005418,
  },
  {
    label: 'Burnside, Victoria',
    value: 10006714,
  },
  {
    label: 'Burnside, Western Australia',
    value: 10009018,
  },
  {
    label: 'Buronga, New South Wales',
    value: 10002293,
  },
  {
    label: 'Burpengary, Queensland',
    value: 10003814,
  },
  {
    label: 'Burra, New South Wales',
    value: 10002036,
  },
  {
    label: 'Burra, South Australia',
    value: 10005861,
  },
  {
    label: 'Burracoppin, Western Australia',
    value: 10009450,
  },
  {
    label: 'Burradoo, New South Wales',
    value: 10001955,
  },
  {
    label: 'Burraga, New South Wales',
    value: 10002452,
  },
  {
    label: 'Burragate, New South Wales',
    value: 10001863,
  },
  {
    label: 'Burran Rock, Western Australia',
    value: 10009645,
  },
  {
    label: 'Burrawang, New South Wales',
    value: 10001959,
  },
  {
    label: 'Burrell Creek, New South Wales',
    value: 10001333,
  },
  {
    label: 'Burren Junction, New South Wales',
    value: 10001231,
  },
  {
    label: 'Burrier, New South Wales',
    value: 10001821,
  },
  {
    label: 'Burrill Lake, New South Wales',
    value: 10001783,
  },
  {
    label: 'Burringbar, New South Wales',
    value: 10001601,
  },
  {
    label: 'Burrinjuck, New South Wales',
    value: 10001996,
  },
  {
    label: 'Burrowye, Victoria',
    value: 10007948,
  },
  {
    label: 'Burrum Heads, Queensland',
    value: 10004275,
  },
  {
    label: 'Burrum River, Queensland',
    value: 10004277,
  },
  {
    label: 'Burrum Town, Queensland',
    value: 10004274,
  },
  {
    label: 'Burrumbeet, Victoria',
    value: 10007374,
  },
  {
    label: 'Burrumbuttock, New South Wales',
    value: 10002102,
  },
  {
    label: 'Burrup, Western Australia',
    value: 10009981,
  },
  {
    label: 'Burswood, Western Australia',
    value: 10008603,
  },
  {
    label: 'Burton, Queensland',
    value: 10004739,
  },
  {
    label: 'Burton, South Australia',
    value: 10005533,
  },
  {
    label: 'Burtville, Western Australia',
    value: 10009532,
  },
  {
    label: 'Burwood, New South Wales',
    value: 10000181,
  },
  {
    label: 'Burwood, New South Wales',
    value: 10000464,
  },
  {
    label: 'Burwood, Victoria',
    value: 10006878,
  },
  {
    label: 'Burwood East, Victoria',
    value: 10006944,
  },
  {
    label: 'Burwood Heights, New South Wales',
    value: 10000466,
  },
  {
    label: 'Burwood Heights, Victoria',
    value: 10006943,
  },
  {
    label: 'Busby, New South Wales',
    value: 10000554,
  },
  {
    label: 'Bushfield, Victoria',
    value: 10007222,
  },
  {
    label: 'Bushland Beach, Queensland',
    value: 10004935,
  },
  {
    label: 'Bushley, Queensland',
    value: 10004490,
  },
  {
    label: 'Bushy Park, Tasmania',
    value: 10006231,
  },
  {
    label: 'Busselton, Western Australia',
    value: 10008976,
  },
  {
    label: 'Butchers Ridge, Victoria',
    value: 10008219,
  },
  {
    label: 'Bute, South Australia',
    value: 10005954,
  },
  {
    label: 'Butler, South Australia',
    value: 10005990,
  },
  {
    label: 'Butler, Western Australia',
    value: 10008481,
  },
  {
    label: 'Butlers Gorge, Tasmania',
    value: 10006235,
  },
  {
    label: 'Buttaba, New South Wales',
    value: 10000859,
  },
  {
    label: 'Buxton, New South Wales',
    value: 10001938,
  },
  {
    label: 'Buxton, Queensland',
    value: 10004282,
  },
  {
    label: 'Buxton, Victoria',
    value: 10007951,
  },
  {
    label: 'Byabarra, New South Wales',
    value: 10001406,
  },
  {
    label: 'Byaduk, Victoria',
    value: 10007251,
  },
  {
    label: 'Bybera, Queensland',
    value: 10003597,
  },
  {
    label: 'Byellee, Queensland',
    value: 10004419,
  },
  {
    label: 'Byfield, Queensland',
    value: 10004555,
  },
  {
    label: 'Byford, Western Australia',
    value: 10008649,
  },
  {
    label: 'Bygalorie, New South Wales',
    value: 10002198,
  },
  {
    label: 'Bylands, Victoria',
    value: 10008043,
  },
  {
    label: 'Bylong, New South Wales',
    value: 10002603,
  },
  {
    label: 'Bymount, Queensland',
    value: 10003722,
  },
  {
    label: 'Byrneside, Victoria',
    value: 10007780,
  },
  {
    label: 'Byrock, New South Wales',
    value: 10002559,
  },
  {
    label: 'Byron Bay, New South Wales',
    value: 10001595,
  },
  {
    label: 'Cabarita, New South Wales',
    value: 10000470,
  },
  {
    label: 'Cabarita, Victoria',
    value: 10007625,
  },
  {
    label: 'Cabarita Beach, New South Wales',
    value: 10001638,
  },
  {
    label: 'Cabarlah, Queensland',
    value: 10003482,
  },
  {
    label: 'Cabbage Tree Creek, Victoria',
    value: 10008232,
  },
  {
    label: 'Cabbage Tree Island, New South Wales',
    value: 10001545,
  },
  {
    label: 'Cable Beach, Western Australia',
    value: 10010026,
  },
  {
    label: 'Caboolture, Queensland',
    value: 10003828,
  },
  {
    label: 'Caboolture South, Queensland',
    value: 10003827,
  },
  {
    label: 'Caboonbah, Queensland',
    value: 10003305,
  },
  {
    label: 'Cabramatta, New South Wales',
    value: 10000547,
  },
  {
    label: 'Cabramurra, New South Wales',
    value: 10002068,
  },
  {
    label: 'Cadell, South Australia',
    value: 10005794,
  },
  {
    label: 'Cadoux, Western Australia',
    value: 10009593,
  },
  {
    label: 'Caffey, Queensland',
    value: 10003345,
  },
  {
    label: 'Caiguna, Western Australia',
    value: 10009544,
  },
  {
    label: 'Cairnlea, Victoria',
    value: 10006712,
  },
  {
    label: 'Cairns, Queensland',
    value: 10005105,
  },
  {
    label: 'Cairns Bay, Tasmania',
    value: 10006201,
  },
  {
    label: 'Cairns City, Queensland',
    value: 10005113,
  },
  {
    label: 'Cairns North, Queensland',
    value: 10005116,
  },
  {
    label: 'Cairns Orchid Plaza, Queensland',
    value: 10005114,
  },
  {
    label: 'Cal Lal, New South Wales',
    value: 10002129,
  },
  {
    label: 'Calala, New South Wales',
    value: 10001118,
  },
  {
    label: 'Calamvale, Queensland',
    value: 10002981,
  },
  {
    label: 'Calavos, Queensland',
    value: 10004306,
  },
  {
    label: 'Calca, South Australia',
    value: 10006026,
  },
  {
    label: 'Calder, Tasmania',
    value: 10006636,
  },
  {
    label: 'Caldermeade, Victoria',
    value: 10008372,
  },
  {
    label: 'Caldervale, Queensland',
    value: 10003756,
  },
  {
    label: 'Caldwell, New South Wales',
    value: 10002245,
  },
  {
    label: 'Calen, Queensland',
    value: 10004769,
  },
  {
    label: 'Calga, New South Wales',
    value: 10000720,
  },
  {
    label: 'Calgoa, Queensland',
    value: 10003953,
  },
  {
    label: 'California Gully, Victoria',
    value: 10007709,
  },
  {
    label: 'Calingiri, Western Australia',
    value: 10009861,
  },
  {
    label: 'Calingunee, Queensland',
    value: 10003612,
  },
  {
    label: 'Calista, Western Australia',
    value: 10008732,
  },
  {
    label: 'Calivil, Victoria',
    value: 10007740,
  },
  {
    label: 'Caljie, Western Australia',
    value: 10009062,
  },
  {
    label: 'Callaghan, New South Wales',
    value: 10000938,
  },
  {
    label: 'Callala Bay, New South Wales',
    value: 10001792,
  },
  {
    label: 'Callala Beach, New South Wales',
    value: 10001798,
  },
  {
    label: 'Callandoon, Queensland',
    value: 10003609,
  },
  {
    label: 'Callemondah, Queensland',
    value: 10004407,
  },
  {
    label: 'Callide, Queensland',
    value: 10004601,
  },
  {
    label: 'Callignee, Victoria',
    value: 10008149,
  },
  {
    label: 'Callington, South Australia',
    value: 10005709,
  },
  {
    label: 'Calliope, Queensland',
    value: 10004428,
  },
  {
    label: 'Caloundra, Queensland',
    value: 10003879,
  },
  {
    label: 'Caloundra West, Queensland',
    value: 10003886,
  },
  {
    label: 'Caltowie, South Australia',
    value: 10005901,
  },
  {
    label: 'Calulu, Victoria',
    value: 10008200,
  },
  {
    label: 'Calwell, Australian Capital Territory',
    value: 10000110,
  },
  {
    label: 'Camberwell, New South Wales',
    value: 10001062,
  },
  {
    label: 'Camberwell, Victoria',
    value: 10006873,
  },
  {
    label: 'Camberwell East, Victoria',
    value: 10006880,
  },
  {
    label: 'Camberwell North, Victoria',
    value: 10006875,
  },
  {
    label: 'Camberwell South, Victoria',
    value: 10006874,
  },
  {
    label: 'Camberwell West, Victoria',
    value: 10006876,
  },
  {
    label: 'Cambewarra, New South Wales',
    value: 10001793,
  },
  {
    label: 'Camboon, Queensland',
    value: 10004622,
  },
  {
    label: 'Cambooya, Queensland',
    value: 10003539,
  },
  {
    label: 'Cambrai, South Australia',
    value: 10005827,
  },
  {
    label: 'Cambrian Hill, Victoria',
    value: 10007393,
  },
  {
    label: 'Cambridge, Tasmania',
    value: 10006295,
  },
  {
    label: 'Cambridge Gulf, Western Australia',
    value: 10010039,
  },
  {
    label: 'Cambridge Park, New South Wales',
    value: 10002300,
  },
  {
    label: 'Cambroon, Queensland',
    value: 10003896,
  },
  {
    label: 'Camden, New South Wales',
    value: 10001929,
  },
  {
    label: 'Camden Head, New South Wales',
    value: 10001387,
  },
  {
    label: 'Camden Park, New South Wales',
    value: 10001922,
  },
  {
    label: 'Camden Park, South Australia',
    value: 10005335,
  },
  {
    label: 'Camellia, New South Wales',
    value: 10000481,
  },
  {
    label: 'Camena, Tasmania',
    value: 10006601,
  },
  {
    label: 'Camira, Queensland',
    value: 10003212,
  },
  {
    label: 'Cammeray, New South Wales',
    value: 10000305,
  },
  {
    label: 'Camoola, Queensland',
    value: 10004642,
  },
  {
    label: 'Camooweal, Queensland',
    value: 10004988,
  },
  {
    label: 'Camp Creek, Queensland',
    value: 10005153,
  },
  {
    label: 'Camp Hill, Queensland',
    value: 10003025,
  },
  {
    label: 'Camp Mountain, Queensland',
    value: 10003867,
  },
  {
    label: 'Campania, Tasmania',
    value: 10006122,
  },
  {
    label: 'Campbell, Australian Capital Territory',
    value: 10000063,
  },
  {
    label: 'Campbell Creek, Queensland',
    value: 10004150,
  },
  {
    label: 'Campbell Town, Tasmania',
    value: 10006339,
  },
  {
    label: 'Campbellfield, Victoria',
    value: 10006786,
  },
  {
    label: 'Campbells Bridge, Victoria',
    value: 10007447,
  },
  {
    label: 'Campbells Creek, Victoria',
    value: 10007538,
  },
  {
    label: 'Campbells Forest, Victoria',
    value: 10007707,
  },
  {
    label: 'Campbelltown, New South Wales',
    value: 10001891,
  },
  {
    label: 'Campbelltown, South Australia',
    value: 10005461,
  },
  {
    label: 'Campbelltown, Victoria',
    value: 10007418,
  },
  {
    label: 'Camperdown, New South Wales',
    value: 10000141,
  },
  {
    label: 'Camperdown, New South Wales',
    value: 10000293,
  },
  {
    label: 'Camperdown, Victoria',
    value: 10007180,
  },
  {
    label: 'Campsie, New South Wales',
    value: 10000604,
  },
  {
    label: 'Campvale, New South Wales',
    value: 10000957,
  },
  {
    label: 'Campwin Beach, Queensland',
    value: 10004655,
  },
  {
    label: 'Cams Wharf, New South Wales',
    value: 10000843,
  },
  {
    label: 'Canada Bay, New South Wales',
    value: 10000284,
  },
  {
    label: 'Canadian, Victoria',
    value: 10007335,
  },
  {
    label: 'Canal Creek, Queensland',
    value: 10004503,
  },
  {
    label: 'Canary Island, Victoria',
    value: 10007660,
  },
  {
    label: 'Canberra, Australian Capital Territory',
    value: 10000006,
  },
  {
    label: 'Canberra, Australian Capital Territory',
    value: 10000014,
  },
  {
    label: 'Canberra Bc, Australian Capital Territory',
    value: 10000049,
  },
  {
    label: 'Cancanning, Western Australia',
    value: 10009119,
  },
  {
    label: 'Candelo, New South Wales',
    value: 10001855,
  },
  {
    label: 'Cane, Western Australia',
    value: 10009966,
  },
  {
    label: 'Cangai, New South Wales',
    value: 10001471,
  },
  {
    label: 'Cania, Queensland',
    value: 10004207,
  },
  {
    label: 'Caniambo, Victoria',
    value: 10007800,
  },
  {
    label: 'Canina, Queensland',
    value: 10003971,
  },
  {
    label: 'Canley Heights, New South Wales',
    value: 10000550,
  },
  {
    label: 'Canley Vale, New South Wales',
    value: 10000548,
  },
  {
    label: 'Cann River, Victoria',
    value: 10008234,
  },
  {
    label: 'Canna, Western Australia',
    value: 10009898,
  },
  {
    label: 'Cannie, Victoria',
    value: 10007663,
  },
  {
    label: 'Cannindah, Queensland',
    value: 10004206,
  },
  {
    label: 'Canning Bridge Applecross, Western Australia',
    value: 10008682,
  },
  {
    label: 'Canning Creek, Queensland',
    value: 10003519,
  },
  {
    label: 'Canning Mills, Western Australia',
    value: 10008634,
  },
  {
    label: 'Canning Vale, Western Australia',
    value: 10008686,
  },
  {
    label: 'Canning Vale Dc, Western Australia',
    value: 10010146,
  },
  {
    label: 'Canning Vale South, Western Australia',
    value: 10008688,
  },
  {
    label: 'Cannington, Western Australia',
    value: 10008620,
  },
  {
    label: 'Cannington, Western Australia',
    value: 10010154,
  },
  {
    label: 'Cannon Hill, Queensland',
    value: 10003051,
  },
  {
    label: 'Cannons Creek, Victoria',
    value: 10008350,
  },
  {
    label: 'Cannonvale, Queensland',
    value: 10004806,
  },
  {
    label: 'Canonba, New South Wales',
    value: 10002533,
  },
  {
    label: 'Canoona, Queensland',
    value: 10004482,
  },
  {
    label: 'Canowindra, New South Wales',
    value: 10002509,
  },
  {
    label: 'Canterbury, New South Wales',
    value: 10000603,
  },
  {
    label: 'Canterbury, Victoria',
    value: 10006881,
  },
  {
    label: 'Canungra, Queensland',
    value: 10003194,
  },
  {
    label: 'Capalaba, Queensland',
    value: 10003036,
  },
  {
    label: 'Capalaba West, Queensland',
    value: 10003037,
  },
  {
    label: 'Caparra, New South Wales',
    value: 10001321,
  },
  {
    label: 'Cape Arid, Western Australia',
    value: 10009578,
  },
  {
    label: 'Cape Barren Island, Tasmania',
    value: 10006435,
  },
  {
    label: 'Cape Burney, Western Australia',
    value: 10009784,
  },
  {
    label: 'Cape Clear, Victoria',
    value: 10007347,
  },
  {
    label: 'Cape Conway, Queensland',
    value: 10004798,
  },
  {
    label: 'Cape Gloucester, Queensland',
    value: 10004795,
  },
  {
    label: 'Cape Hillsborough, Queensland',
    value: 10004673,
  },
  {
    label: 'Cape Le Grand, Western Australia',
    value: 10009559,
  },
  {
    label: 'Cape Moreton, Queensland',
    value: 10002848,
  },
  {
    label: 'Cape Paterson, Victoria',
    value: 10008381,
  },
  {
    label: 'Cape Portland, Tasmania',
    value: 10006461,
  },
  {
    label: 'Cape Range National Park, Western Australia',
    value: 10009963,
  },
  {
    label: 'Cape Riche, Western Australia',
    value: 10009172,
  },
  {
    label: 'Cape Woolamai, Victoria',
    value: 10008266,
  },
  {
    label: 'Capel, Western Australia',
    value: 10008956,
  },
  {
    label: 'Capel River, Western Australia',
    value: 10008957,
  },
  {
    label: 'Capella, Queensland',
    value: 10004631,
  },
  {
    label: 'Capertee, New South Wales',
    value: 10002597,
  },
  {
    label: 'Capricorn, Western Australia',
    value: 10009926,
  },
  {
    label: 'Captain Creek, Queensland',
    value: 10004387,
  },
  {
    label: 'Captains Flat, New South Wales',
    value: 10002057,
  },
  {
    label: 'Captains Mountain, Queensland',
    value: 10003515,
  },
  {
    label: 'Caraban, Western Australia',
    value: 10008487,
  },
  {
    label: 'Carabooda, Western Australia',
    value: 10008477,
  },
  {
    label: 'Carabost, New South Wales',
    value: 10002132,
  },
  {
    label: 'Caragabal, New South Wales',
    value: 10002516,
  },
  {
    label: 'Caramut, Victoria',
    value: 10007211,
  },
  {
    label: 'Carani, Western Australia',
    value: 10009845,
  },
  {
    label: 'Carapook, Victoria',
    value: 10007267,
  },
  {
    label: 'Caravonica, Queensland',
    value: 10005197,
  },
  {
    label: 'Carbrook, Queensland',
    value: 10003015,
  },
  {
    label: 'Carbunup River, Western Australia',
    value: 10008988,
  },
  {
    label: 'Carcoar, New South Wales',
    value: 10002442,
  },
  {
    label: 'Cardiff, New South Wales',
    value: 10000870,
  },
  {
    label: 'Cardiff, Western Australia',
    value: 10008827,
  },
  {
    label: 'Cardigan, Victoria',
    value: 10007381,
  },
  {
    label: 'Cardinia, Victoria',
    value: 10008353,
  },
  {
    label: 'Cardross, Victoria',
    value: 10007617,
  },
  {
    label: 'Cardstone, Queensland',
    value: 10005019,
  },
  {
    label: 'Cardup, Western Australia',
    value: 10008647,
  },
  {
    label: 'Cardwell, Queensland',
    value: 10004999,
  },
  {
    label: 'Careel Bay, New South Wales',
    value: 10000410,
  },
  {
    label: 'Carey Bay, New South Wales',
    value: 10000855,
  },
  {
    label: 'Carey Gully, South Australia',
    value: 10005594,
  },
  {
    label: 'Carey Park, Western Australia',
    value: 10008854,
  },
  {
    label: 'Cargo, New South Wales',
    value: 10002499,
  },
  {
    label: 'Carina, Queensland',
    value: 10003028,
  },
  {
    label: 'Carina Heights, Queensland',
    value: 10003027,
  },
  {
    label: 'Carinda, New South Wales',
    value: 10002564,
  },
  {
    label: 'Carindale, Queensland',
    value: 10003026,
  },
  {
    label: 'Carine, Western Australia',
    value: 10008440,
  },
  {
    label: 'Caringbah, New South Wales',
    value: 10000152,
  },
  {
    label: 'Caringbah, New South Wales',
    value: 10000685,
  },
  {
    label: 'Carisbrook, Victoria',
    value: 10007568,
  },
  {
    label: 'Carlingford, New South Wales',
    value: 10000441,
  },
  {
    label: 'Carlingford Court, New South Wales',
    value: 10000442,
  },
  {
    label: 'Carlisle, Western Australia',
    value: 10008607,
  },
  {
    label: 'Carlisle River, Victoria',
    value: 10007137,
  },
  {
    label: 'Carlotta, Western Australia',
    value: 10008969,
  },
  {
    label: 'Carlsruhe, Victoria',
    value: 10007521,
  },
  {
    label: 'Carlton, New South Wales',
    value: 10000659,
  },
  {
    label: 'Carlton, Tasmania',
    value: 10006311,
  },
  {
    label: 'Carlton, Victoria',
    value: 10006775,
  },
  {
    label: 'Carlton North, Victoria',
    value: 10006776,
  },
  {
    label: 'Carlton South, Victoria',
    value: 10006774,
  },
  {
    label: 'Carmel, Western Australia',
    value: 10008584,
  },
  {
    label: 'Carmila, Queensland',
    value: 10004662,
  },
  {
    label: 'Carnamah, Western Australia',
    value: 10009704,
  },
  {
    label: 'Carnarvon, Western Australia',
    value: 10009931,
  },
  {
    label: 'Carnegie, Victoria',
    value: 10006969,
  },
  {
    label: 'Carngham, Victoria',
    value: 10007349,
  },
  {
    label: 'Carole Park, Queensland',
    value: 10003211,
  },
  {
    label: 'Caroline Springs, Victoria',
    value: 10006713,
  },
  {
    label: 'Carool, New South Wales',
    value: 10001631,
  },
  {
    label: 'Caroona, New South Wales',
    value: 10001138,
  },
  {
    label: 'Carpendale, Queensland',
    value: 10003365,
  },
  {
    label: 'Carpenter Rocks, South Australia',
    value: 10005762,
  },
  {
    label: 'Carrabin, Western Australia',
    value: 10009454,
  },
  {
    label: 'Carrai, New South Wales',
    value: 10001357,
  },
  {
    label: 'Carrajung, Victoria',
    value: 10008148,
  },
  {
    label: 'Carrajung South, Victoria',
    value: 10008194,
  },
  {
    label: 'Carramar, New South Wales',
    value: 10000538,
  },
  {
    label: 'Carramar, Western Australia',
    value: 10008473,
  },
  {
    label: 'Carranballac, Victoria',
    value: 10007413,
  },
  {
    label: 'Carrandotta, Queensland',
    value: 10004980,
  },
  {
    label: 'Carrara, Queensland',
    value: 10003116,
  },
  {
    label: 'Carrathool, New South Wales',
    value: 10002246,
  },
  {
    label: 'Carrick, Tasmania',
    value: 10006502,
  },
  {
    label: 'Carrickalinga, South Australia',
    value: 10005659,
  },
  {
    label: 'Carrieton, South Australia',
    value: 10005872,
  },
  {
    label: 'Carrington, New South Wales',
    value: 10000905,
  },
  {
    label: 'Carrington, New South Wales',
    value: 10001006,
  },
  {
    label: 'Carrington, Queensland',
    value: 10005209,
  },
  {
    label: 'Carroll, New South Wales',
    value: 10001129,
  },
  {
    label: 'Carrolup, Western Australia',
    value: 10009132,
  },
  {
    label: 'Carrowbrook, New South Wales',
    value: 10001059,
  },
  {
    label: 'Carrum, Victoria',
    value: 10007041,
  },
  {
    label: 'Carrum Downs, Victoria',
    value: 10007048,
  },
  {
    label: 'Carseldine, Queensland',
    value: 10002864,
  },
  {
    label: 'Carss Park, New South Wales',
    value: 10000665,
  },
  {
    label: 'Carstairs, Queensland',
    value: 10004826,
  },
  {
    label: 'Cartmeticup, Western Australia',
    value: 10009121,
  },
  {
    label: 'Cartwright, New South Wales',
    value: 10000552,
  },
  {
    label: 'Carwarp, Victoria',
    value: 10007611,
  },
  {
    label: 'Cascade, Western Australia',
    value: 10009560,
  },
  {
    label: 'Casey, Tasmania',
    value: 10006288,
  },
  {
    label: 'Cashmere, Queensland',
    value: 10003798,
  },
  {
    label: 'Casino, New South Wales',
    value: 10001517,
  },
  {
    label: 'Cassilis, New South Wales',
    value: 10001058,
  },
  {
    label: 'Castambul, South Australia',
    value: 10005466,
  },
  {
    label: 'Castaways Beach, Queensland',
    value: 10003942,
  },
  {
    label: 'Casterton, Victoria',
    value: 10007264,
  },
  {
    label: 'Castle Cove, New South Wales',
    value: 10000327,
  },
  {
    label: 'Castle Creek, Queensland',
    value: 10004605,
  },
  {
    label: 'Castle Forbes Bay, Tasmania',
    value: 10006199,
  },
  {
    label: 'Castle Hill, New South Wales',
    value: 10000178,
  },
  {
    label: 'Castle Hill, New South Wales',
    value: 10000515,
  },
  {
    label: 'Castle Hill, Queensland',
    value: 10004859,
  },
  {
    label: 'Castlecrag, New South Wales',
    value: 10000325,
  },
  {
    label: 'Castlemaine, Victoria',
    value: 10007536,
  },
  {
    label: 'Castlereagh, New South Wales',
    value: 10002308,
  },
  {
    label: 'Castletown, Western Australia',
    value: 10009557,
  },
  {
    label: 'Casuarina, Northern Territory',
    value: 10002679,
  },
  {
    label: 'Casuarina, Northern Territory',
    value: 10002691,
  },
  {
    label: 'Casuarina, Western Australia',
    value: 10008734,
  },
  {
    label: 'Casula, New South Wales',
    value: 10000565,
  },
  {
    label: 'Casula Mall, New South Wales',
    value: 10000561,
  },
  {
    label: 'Cataby, Western Australia',
    value: 10009676,
  },
  {
    label: 'Catalina, New South Wales',
    value: 10001735,
  },
  {
    label: 'Catamaran, Tasmania',
    value: 10006169,
  },
  {
    label: 'Catani, Victoria',
    value: 10008366,
  },
  {
    label: 'Cathcart, New South Wales',
    value: 10002084,
  },
  {
    label: 'Catherine Field, New South Wales',
    value: 10001880,
  },
  {
    label: 'Catherine Hill Bay, New South Wales',
    value: 10000840,
  },
  {
    label: 'Cathkin, Victoria',
    value: 10007955,
  },
  {
    label: 'Cattai, New South Wales',
    value: 10002334,
  },
  {
    label: 'Catterick, Western Australia',
    value: 10008913,
  },
  {
    label: 'Cattle Creek, Queensland',
    value: 10003656,
  },
  {
    label: 'Cattle Creek, Queensland',
    value: 10004179,
  },
  {
    label: 'Caulfield, Victoria',
    value: 10006964,
  },
  {
    label: 'Caulfield East, Victoria',
    value: 10006933,
  },
  {
    label: 'Caulfield Junction, Victoria',
    value: 10006963,
  },
  {
    label: 'Caulfield North, Victoria',
    value: 10006962,
  },
  {
    label: 'Caulfield South, Victoria',
    value: 10006965,
  },
  {
    label: 'Causeway, Australian Capital Territory',
    value: 10000029,
  },
  {
    label: 'Causeway Lake, Queensland',
    value: 10004562,
  },
  {
    label: 'Cavan, South Australia',
    value: 10005507,
  },
  {
    label: 'Cavendish, Victoria',
    value: 10007275,
  },
  {
    label: 'Caversham, Western Australia',
    value: 10008508,
  },
  {
    label: 'Caves Beach, New South Wales',
    value: 10000841,
  },
  {
    label: 'Caveside, Tasmania',
    value: 10006542,
  },
  {
    label: 'Cawdor, New South Wales',
    value: 10001934,
  },
  {
    label: 'Cawongla, New South Wales',
    value: 10001533,
  },
  {
    label: 'Cecil Hills, New South Wales',
    value: 10000571,
  },
  {
    label: 'Cecil Park, New South Wales',
    value: 10000592,
  },
  {
    label: 'Cecil Plains, Queensland',
    value: 10003659,
  },
  {
    label: 'Cedar Creek, Queensland',
    value: 10003092,
  },
  {
    label: 'Cedar Creek, Queensland',
    value: 10003864,
  },
  {
    label: 'Cedar Grove, Queensland',
    value: 10003199,
  },
  {
    label: 'Cedar Vale, Queensland',
    value: 10003200,
  },
  {
    label: 'Ceduna, South Australia',
    value: 10006035,
  },
  {
    label: 'Cells River, New South Wales',
    value: 10001015,
  },
  {
    label: 'Cement Mills, Queensland',
    value: 10003483,
  },
  {
    label: 'Centenary Heights, Queensland',
    value: 10003388,
  },
  {
    label: 'Centennial Park, New South Wales',
    value: 10000227,
  },
  {
    label: 'Centennial Park, Western Australia',
    value: 10009202,
  },
  {
    label: 'Central Macdonald, New South Wales',
    value: 10002391,
  },
  {
    label: 'Central Park, Victoria',
    value: 10006930,
  },
  {
    label: 'Central Plaza, Queensland',
    value: 10002805,
  },
  {
    label: 'Central Queensland Mc, Queensland',
    value: 10004471,
  },
  {
    label: 'Central Queensland University, Queensland',
    value: 10004453,
  },
  {
    label: 'Central Tilba, New South Wales',
    value: 10001843,
  },
  {
    label: 'Ceratodus, Queensland',
    value: 10004188,
  },
  {
    label: 'Ceres, Victoria',
    value: 10007103,
  },
  {
    label: 'Cervantes, Western Australia',
    value: 10009692,
  },
  {
    label: 'Cessnock, New South Wales',
    value: 10001037,
  },
  {
    label: 'Cessnock West, New South Wales',
    value: 10001034,
  },
  {
    label: 'Chadstone, Victoria',
    value: 10006938,
  },
  {
    label: 'Chadwick, Western Australia',
    value: 10009572,
  },
  {
    label: 'Chahpingah, Queensland',
    value: 10004069,
  },
  {
    label: 'Chain Of Lagoons, Tasmania',
    value: 10006358,
  },
  {
    label: 'Chain Valley Bay, New South Wales',
    value: 10000761,
  },
  {
    label: 'Chakola, New South Wales',
    value: 10002079,
  },
  {
    label: 'Chambers Flat, Queensland',
    value: 10003023,
  },
  {
    label: 'Champion Lakes, Western Australia',
    value: 10008632,
  },
  {
    label: 'Chandler, Queensland',
    value: 10003033,
  },
  {
    label: 'Chandler, Western Australia',
    value: 10009644,
  },
  {
    label: 'Chandlers Hill, South Australia',
    value: 10005626,
  },
  {
    label: 'Changerup, Western Australia',
    value: 10009379,
  },
  {
    label: 'Chapel Hill, Queensland',
    value: 10002909,
  },
  {
    label: 'Chapman, Australian Capital Territory',
    value: 10000054,
  },
  {
    label: 'Chapman Hill, Western Australia',
    value: 10008991,
  },
  {
    label: 'Chapple Vale, Victoria',
    value: 10007139,
  },
  {
    label: 'Charbon, New South Wales',
    value: 10002602,
  },
  {
    label: 'Charleroi, Victoria',
    value: 10007925,
  },
  {
    label: 'Charles Darwin University, Northern Territory',
    value: 10002702,
  },
  {
    label: 'Charles Darwin University, Northern Territory',
    value: 10002800,
  },
  {
    label: 'Charles Sturt University, New South Wales',
    value: 10002210,
  },
  {
    label: 'Charleston, South Australia',
    value: 10005697,
  },
  {
    label: 'Charlestown, New South Wales',
    value: 10000895,
  },
  {
    label: 'Charlestown, Queensland',
    value: 10004064,
  },
  {
    label: 'Charleville, Queensland',
    value: 10003744,
  },
  {
    label: 'Charley Creek, Western Australia',
    value: 10008887,
  },
  {
    label: 'Charlotte Bay, New South Wales',
    value: 10001317,
  },
  {
    label: 'Charlottes Pass, New South Wales',
    value: 10002058,
  },
  {
    label: 'Charlton, Victoria',
    value: 10007653,
  },
  {
    label: 'Charmhaven, New South Wales',
    value: 10000813,
  },
  {
    label: 'Charnwood, Australian Capital Territory',
    value: 10000083,
  },
  {
    label: 'Charters Towers, Queensland',
    value: 10004946,
  },
  {
    label: 'Chatham, New South Wales',
    value: 10001343,
  },
  {
    label: 'Chatswood, New South Wales',
    value: 10000296,
  },
  {
    label: 'Chatswood, New South Wales',
    value: 10000323,
  },
  {
    label: 'Chatswood West, New South Wales',
    value: 10000322,
  },
  {
    label: 'Chatsworth, New South Wales',
    value: 10001500,
  },
  {
    label: 'Chatsworth, Victoria',
    value: 10007442,
  },
  {
    label: 'Cheepie, Queensland',
    value: 10003749,
  },
  {
    label: 'Chelmer, Queensland',
    value: 10002905,
  },
  {
    label: 'Chelmsford, Queensland',
    value: 10004062,
  },
  {
    label: 'Chelsea, Victoria',
    value: 10007036,
  },
  {
    label: 'Chelsea Heights, Victoria',
    value: 10007039,
  },
  {
    label: 'Cheltenham, New South Wales',
    value: 10000444,
  },
  {
    label: 'Cheltenham, Queensland',
    value: 10004187,
  },
  {
    label: 'Cheltenham, South Australia',
    value: 10005275,
  },
  {
    label: 'Cheltenham, Victoria',
    value: 10007023,
  },
  {
    label: 'Cheltenham East, Victoria',
    value: 10007024,
  },
  {
    label: 'Chepstowe, Victoria',
    value: 10007351,
  },
  {
    label: 'Cherbourg, Queensland',
    value: 10004043,
  },
  {
    label: 'Chermside, Queensland',
    value: 10002859,
  },
  {
    label: 'Chermside West, Queensland',
    value: 10002860,
  },
  {
    label: 'Cherry Creek, Queensland',
    value: 10003255,
  },
  {
    label: 'Cherry Gardens, South Australia',
    value: 10005615,
  },
  {
    label: 'Cherry Tree Pool, Western Australia',
    value: 10009388,
  },
  {
    label: 'Cherrybrook, New South Wales',
    value: 10000454,
  },
  {
    label: 'Cherryville, South Australia',
    value: 10005586,
  },
  {
    label: 'Cherwell, Queensland',
    value: 10004289,
  },
  {
    label: 'Cheshunt, Victoria',
    value: 10007883,
  },
  {
    label: 'Chester Hill, New South Wales',
    value: 10000536,
  },
  {
    label: 'Chetwynd, Victoria',
    value: 10007270,
  },
  {
    label: 'Chevron Island, Queensland',
    value: 10003154,
  },
  {
    label: 'Chewton, Victoria',
    value: 10007537,
  },
  {
    label: 'Cheynes, Western Australia',
    value: 10009167,
  },
  {
    label: 'Chichester, Western Australia',
    value: 10010050,
  },
  {
    label: 'Chidlow, Western Australia',
    value: 10009834,
  },
  {
    label: 'Chifley, Australian Capital Territory',
    value: 10000035,
  },
  {
    label: 'Chifley, New South Wales',
    value: 10000258,
  },
  {
    label: 'Chigwell, Tasmania',
    value: 10006090,
  },
  {
    label: 'Childers, Queensland',
    value: 10004284,
  },
  {
    label: 'Childers, Victoria',
    value: 10008122,
  },
  {
    label: 'Chillagoe, Queensland',
    value: 10005131,
  },
  {
    label: 'Chillingham, New South Wales',
    value: 10001611,
  },
  {
    label: 'Chiltern, Victoria',
    value: 10007891,
  },
  {
    label: 'Chinchilla, Queensland',
    value: 10003665,
  },
  {
    label: 'Chinderah, New South Wales',
    value: 10001635,
  },
  {
    label: 'Chinkapook, Victoria',
    value: 10007668,
  },
  {
    label: 'Chippendale, New South Wales',
    value: 10000203,
  },
  {
    label: 'Chipping Norton, New South Wales',
    value: 10000560,
  },
  {
    label: 'Chirn Park, Queensland',
    value: 10003145,
  },
  {
    label: 'Chirnside Park, Victoria',
    value: 10006867,
  },
  {
    label: 'Chisholm, Australian Capital Territory',
    value: 10000113,
  },
  {
    label: 'Chiswick, New South Wales',
    value: 10000282,
  },
  {
    label: 'Chittaway Bay, New South Wales',
    value: 10000791,
  },
  {
    label: 'Chittaway Point, New South Wales',
    value: 10000798,
  },
  {
    label: 'Chittering, Western Australia',
    value: 10008599,
  },
  {
    label: 'Chorregon, Queensland',
    value: 10004639,
  },
  {
    label: 'Chowerup, Western Australia',
    value: 10008900,
  },
  {
    label: 'Christie Downs, South Australia',
    value: 10005634,
  },
  {
    label: 'Christies Beach, South Australia',
    value: 10005635,
  },
  {
    label: 'Christmas Hills, Tasmania',
    value: 10006661,
  },
  {
    label: 'Christmas Hills, Victoria',
    value: 10008051,
  },
  {
    label: 'Christmas Island, Western Australia',
    value: 10010065,
  },
  {
    label: 'Chudleigh, Tasmania',
    value: 10006543,
  },
  {
    label: 'Chullora, New South Wales',
    value: 10000596,
  },
  {
    label: 'Church Point, New South Wales',
    value: 10000402,
  },
  {
    label: 'Churchable, Queensland',
    value: 10003296,
  },
  {
    label: 'Churchill, Queensland',
    value: 10003232,
  },
  {
    label: 'Churchill, Victoria',
    value: 10008146,
  },
  {
    label: 'Churchlands, Western Australia',
    value: 10008431,
  },
  {
    label: 'Chuwar, Queensland',
    value: 10003253,
  },
  {
    label: 'City, Australian Capital Territory',
    value: 10000015,
  },
  {
    label: 'City Beach, Western Australia',
    value: 10008425,
  },
  {
    label: 'City East, Queensland',
    value: 10002809,
  },
  {
    label: 'Civic Square, Australian Capital Territory',
    value: 10000045,
  },
  {
    label: 'Clackline, Western Australia',
    value: 10009843,
  },
  {
    label: 'Clagiraba, Queensland',
    value: 10003118,
  },
  {
    label: 'Clandulla, New South Wales',
    value: 10002601,
  },
  {
    label: 'Clapham, South Australia',
    value: 10005388,
  },
  {
    label: 'Clare, Queensland',
    value: 10004839,
  },
  {
    label: 'Clare, South Australia',
    value: 10005882,
  },
  {
    label: 'Claremont, Tasmania',
    value: 10006089,
  },
  {
    label: 'Claremont, Western Australia',
    value: 10008417,
  },
  {
    label: 'Claremont, Western Australia',
    value: 10010095,
  },
  {
    label: 'Claremont North, Western Australia',
    value: 10008418,
  },
  {
    label: 'Clarence, New South Wales',
    value: 10002432,
  },
  {
    label: 'Clarence Gardens, South Australia',
    value: 10005340,
  },
  {
    label: 'Clarence Park, South Australia',
    value: 10005321,
  },
  {
    label: 'Clarence Point, Tasmania',
    value: 10006476,
  },
  {
    label: 'Clarence Town, New South Wales',
    value: 10000984,
  },
  {
    label: 'Clarendon, New South Wales',
    value: 10002328,
  },
  {
    label: 'Clarendon, South Australia',
    value: 10005617,
  },
  {
    label: 'Clarendon, Victoria',
    value: 10007378,
  },
  {
    label: 'Clarendon Vale, Tasmania',
    value: 10006113,
  },
  {
    label: 'Clarinda, Victoria',
    value: 10006980,
  },
  {
    label: 'Clarke Creek, Queensland',
    value: 10004567,
  },
  {
    label: 'Clarkefield, Victoria',
    value: 10007509,
  },
  {
    label: 'Clarkes Hill, Victoria',
    value: 10007380,
  },
  {
    label: 'Clarkson, Western Australia',
    value: 10008468,
  },
  {
    label: 'Claude Road, Tasmania',
    value: 10006554,
  },
  {
    label: 'Claverton, Queensland',
    value: 10003745,
  },
  {
    label: 'Clayfield, Queensland',
    value: 10002823,
  },
  {
    label: 'Claymore, New South Wales',
    value: 10001885,
  },
  {
    label: 'Clayton, South Australia',
    value: 10005715,
  },
  {
    label: 'Clayton, Victoria',
    value: 10006978,
  },
  {
    label: 'Clayton South, Victoria',
    value: 10006981,
  },
  {
    label: 'Clear Island Waters, Queensland',
    value: 10003182,
  },
  {
    label: 'Clear Mountain, Queensland',
    value: 10003800,
  },
  {
    label: 'Clearview, South Australia',
    value: 10005485,
  },
  {
    label: 'Cleary, Western Australia',
    value: 10009602,
  },
  {
    label: 'Cleaverville, Western Australia',
    value: 10009986,
  },
  {
    label: 'Clematis, Victoria',
    value: 10008060,
  },
  {
    label: 'Clemton Park, New South Wales',
    value: 10000626,
  },
  {
    label: 'Clermont, Queensland',
    value: 10004628,
  },
  {
    label: 'Cleve, South Australia',
    value: 10006011,
  },
  {
    label: 'Cleveland, Queensland',
    value: 10003044,
  },
  {
    label: 'Cleveland, Tasmania',
    value: 10006342,
  },
  {
    label: 'Clifton, New South Wales',
    value: 10001664,
  },
  {
    label: 'Clifton, Queensland',
    value: 10003561,
  },
  {
    label: 'Clifton, Western Australia',
    value: 10008788,
  },
  {
    label: 'Clifton Beach, Queensland',
    value: 10005202,
  },
  {
    label: 'Clifton Beach, Tasmania',
    value: 10006115,
  },
  {
    label: 'Clifton Hill, Victoria',
    value: 10006798,
  },
  {
    label: 'Clifton Springs, Victoria',
    value: 10007105,
  },
  {
    label: 'Clinton, Queensland',
    value: 10004399,
  },
  {
    label: 'Clinton, South Australia',
    value: 10005955,
  },
  {
    label: 'Cloisters Square, Western Australia',
    value: 10010083,
  },
  {
    label: 'Clonbinane, Victoria',
    value: 10007838,
  },
  {
    label: 'Cloncurry, Queensland',
    value: 10004962,
  },
  {
    label: 'Clontarf, New South Wales',
    value: 10000373,
  },
  {
    label: 'Clontarf, Queensland',
    value: 10002840,
  },
  {
    label: 'Clontarf, Queensland',
    value: 10003518,
  },
  {
    label: 'Closeburn, Queensland',
    value: 10003861,
  },
  {
    label: 'Clovelly, New South Wales',
    value: 10000250,
  },
  {
    label: 'Clovelly Park, South Australia',
    value: 10005348,
  },
  {
    label: 'Clovelly West, New South Wales',
    value: 10000248,
  },
  {
    label: 'Cloverdale, Western Australia',
    value: 10008614,
  },
  {
    label: 'Cloverdale, Western Australia',
    value: 10010152,
  },
  {
    label: 'Cloyna, Queensland',
    value: 10004050,
  },
  {
    label: 'Club Terrace, Victoria',
    value: 10008231,
  },
  {
    label: 'Cluden, Queensland',
    value: 10004870,
  },
  {
    label: 'Clunes, New South Wales',
    value: 10001588,
  },
  {
    label: 'Clunes, Victoria',
    value: 10007427,
  },
  {
    label: 'Clybucca, New South Wales',
    value: 10001371,
  },
  {
    label: 'Clyde, Victoria',
    value: 10008354,
  },
  {
    label: 'Coal Point, New South Wales',
    value: 10000861,
  },
  {
    label: 'Coalcliff, New South Wales',
    value: 10001659,
  },
  {
    label: 'Coaldale, New South Wales',
    value: 10001477,
  },
  {
    label: 'Coalfalls, Queensland',
    value: 10003231,
  },
  {
    label: 'Coalville, Victoria',
    value: 10008131,
  },
  {
    label: 'Coatesville, Victoria',
    value: 10006971,
  },
  {
    label: 'Cobar, New South Wales',
    value: 10002574,
  },
  {
    label: 'Cobar Park, New South Wales',
    value: 10002430,
  },
  {
    label: 'Cobargo, New South Wales',
    value: 10001867,
  },
  {
    label: 'Cobbadah, New South Wales',
    value: 10001147,
  },
  {
    label: 'Cobbitty, New South Wales',
    value: 10001932,
  },
  {
    label: 'Cobbora, New South Wales',
    value: 10002594,
  },
  {
    label: 'Cobbs Hill, Queensland',
    value: 10004039,
  },
  {
    label: 'Cobden, Victoria',
    value: 10007194,
  },
  {
    label: 'Cobdogla, South Australia',
    value: 10005814,
  },
  {
    label: 'Coblinine, Western Australia',
    value: 10009129,
  },
  {
    label: 'Cobraball, Queensland',
    value: 10004540,
  },
  {
    label: 'Cobram, Victoria',
    value: 10007830,
  },
  {
    label: 'Cobram, Victoria',
    value: 10007832,
  },
  {
    label: 'Coburg, Victoria',
    value: 10006780,
  },
  {
    label: 'Coburg North, Victoria',
    value: 10006781,
  },
  {
    label: 'Cockatoo, Queensland',
    value: 10003682,
  },
  {
    label: 'Cockatoo, Victoria',
    value: 10008059,
  },
  {
    label: 'Cockatoo Valley, South Australia',
    value: 10005818,
  },
  {
    label: 'Cockburn, South Australia',
    value: 10005877,
  },
  {
    label: 'Cockburn Central, Western Australia',
    value: 10010192,
  },
  {
    label: 'Cocklebiddy, Western Australia',
    value: 10009536,
  },
  {
    label: 'Coconut Grove, Northern Territory',
    value: 10002685,
  },
  {
    label: 'Coconuts, Queensland',
    value: 10005076,
  },
  {
    label: 'Codjatotine, Western Australia',
    value: 10009076,
  },
  {
    label: 'Coen, Queensland',
    value: 10005124,
  },
  {
    label: 'Coffin Bay, South Australia',
    value: 10005993,
  },
  {
    label: 'Coffs Harbour, New South Wales',
    value: 10001438,
  },
  {
    label: 'Coffs Harbour Jetty, New South Wales',
    value: 10001429,
  },
  {
    label: 'Coffs Harbour Plaza, New South Wales',
    value: 10001425,
  },
  {
    label: 'Coghills Creek, Victoria',
    value: 10007422,
  },
  {
    label: 'Cohuna, Victoria',
    value: 10007730,
  },
  {
    label: 'Coila, New South Wales',
    value: 10001770,
  },
  {
    label: 'Coimadai, Victoria',
    value: 10007317,
  },
  {
    label: 'Colac, Victoria',
    value: 10007170,
  },
  {
    label: 'Colac Colac, Victoria',
    value: 10007943,
  },
  {
    label: 'Colbinabbin, Victoria',
    value: 10007716,
  },
  {
    label: 'Cold Harbour, Western Australia',
    value: 10009054,
  },
  {
    label: 'Coldstream, Victoria',
    value: 10008049,
  },
  {
    label: 'Coleambally, New South Wales',
    value: 10002234,
  },
  {
    label: 'Colebrook, Tasmania',
    value: 10006123,
  },
  {
    label: 'Coledale, New South Wales',
    value: 10001666,
  },
  {
    label: 'Coleraine, Victoria',
    value: 10007276,
  },
  {
    label: 'Coles Bay, Tasmania',
    value: 10006364,
  },
  {
    label: 'Coles Creek, Queensland',
    value: 10003983,
  },
  {
    label: 'Colevale, Queensland',
    value: 10004849,
  },
  {
    label: 'Colignan, Victoria',
    value: 10007612,
  },
  {
    label: 'Colinton, Queensland',
    value: 10003282,
  },
  {
    label: 'Collanilling, Western Australia',
    value: 10009115,
  },
  {
    label: 'Collarenebri, New South Wales',
    value: 10002572,
  },
  {
    label: 'Collaroy, New South Wales',
    value: 10000381,
  },
  {
    label: 'Collaroy, Queensland',
    value: 10004573,
  },
  {
    label: 'Collaroy Beach, New South Wales',
    value: 10000380,
  },
  {
    label: 'Collector, New South Wales',
    value: 10001991,
  },
  {
    label: 'College Grove, Western Australia',
    value: 10008851,
  },
  {
    label: 'College Park, South Australia',
    value: 10005437,
  },
  {
    label: 'College View, Queensland',
    value: 10003342,
  },
  {
    label: 'Collie, New South Wales',
    value: 10002539,
  },
  {
    label: 'Collie, Western Australia',
    value: 10008833,
  },
  {
    label: 'Collie Burn, Western Australia',
    value: 10008835,
  },
  {
    label: 'Collingullie, New South Wales',
    value: 10002143,
  },
  {
    label: 'Collingwood, Victoria',
    value: 10006796,
  },
  {
    label: 'Collingwood Heights, Western Australia',
    value: 10009180,
  },
  {
    label: 'Collingwood Park, Queensland',
    value: 10003214,
  },
  {
    label: 'Collingwood Park, Western Australia',
    value: 10009178,
  },
  {
    label: 'Collins Street East, Victoria',
    value: 10008390,
  },
  {
    label: 'Collins Street West, Victoria',
    value: 10008394,
  },
  {
    label: 'Collinsvale, Tasmania',
    value: 10006094,
  },
  {
    label: 'Collinsville, Queensland',
    value: 10004815,
  },
  {
    label: 'Collinswood, South Australia',
    value: 10005467,
  },
  {
    label: 'Colliver, Victoria',
    value: 10007799,
  },
  {
    label: 'Collombatti, New South Wales',
    value: 10001355,
  },
  {
    label: 'Colly Blue, New South Wales',
    value: 10001139,
  },
  {
    label: 'Colo, New South Wales',
    value: 10002335,
  },
  {
    label: 'Colo Vale, New South Wales',
    value: 10001951,
  },
  {
    label: 'Colonel Light Gardens, South Australia',
    value: 10005345,
  },
  {
    label: 'Colosseum, Queensland',
    value: 10004386,
  },
  {
    label: 'Columbia, Queensland',
    value: 10004945,
  },
  {
    label: 'Colyton, New South Wales',
    value: 10002348,
  },
  {
    label: 'Comara, New South Wales',
    value: 10001372,
  },
  {
    label: 'Combara, New South Wales',
    value: 10002544,
  },
  {
    label: 'Combienbar, Victoria',
    value: 10008230,
  },
  {
    label: 'Comboyne, New South Wales',
    value: 10001330,
  },
  {
    label: 'Come By Chance, New South Wales',
    value: 10002570,
  },
  {
    label: 'Comet, Queensland',
    value: 10004469,
  },
  {
    label: 'Comet Hill, Victoria',
    value: 10007705,
  },
  {
    label: 'Commissioners Flat, Queensland',
    value: 10003841,
  },
  {
    label: 'Commodine, Western Australia',
    value: 10009095,
  },
  {
    label: 'Como, New South Wales',
    value: 10000678,
  },
  {
    label: 'Como, Western Australia',
    value: 10008674,
  },
  {
    label: 'Como, Western Australia',
    value: 10010129,
  },
  {
    label: 'Comoon Loop, Queensland',
    value: 10005038,
  },
  {
    label: 'Conara, Tasmania',
    value: 10006341,
  },
  {
    label: 'Conargo, New South Wales',
    value: 10002243,
  },
  {
    label: 'Concord, New South Wales',
    value: 10000471,
  },
  {
    label: 'Concord Repatriation Hospital, New South Wales',
    value: 10000475,
  },
  {
    label: 'Concord West, New South Wales',
    value: 10000473,
  },
  {
    label: 'Condah, Victoria',
    value: 10007254,
  },
  {
    label: 'Condamine, Queensland',
    value: 10003674,
  },
  {
    label: 'Condamine Farms, Queensland',
    value: 10003531,
  },
  {
    label: 'Condamine Plains, Queensland',
    value: 10003422,
  },
  {
    label: 'Condell Park, New South Wales',
    value: 10000613,
  },
  {
    label: 'Conder, Australian Capital Territory',
    value: 10000116,
  },
  {
    label: 'Condingup, Western Australia',
    value: 10009564,
  },
  {
    label: 'Condobolin, New South Wales',
    value: 10002658,
  },
  {
    label: 'Condon, Queensland',
    value: 10004898,
  },
  {
    label: 'Condong, New South Wales',
    value: 10001620,
  },
  {
    label: 'Congelin, Western Australia',
    value: 10009363,
  },
  {
    label: 'Congewai, New South Wales',
    value: 10001026,
  },
  {
    label: 'Congo, New South Wales',
    value: 10001768,
  },
  {
    label: 'Congupna, Victoria',
    value: 10007814,
  },
  {
    label: 'Coningham, Tasmania',
    value: 10006163,
  },
  {
    label: 'Coningsby, Queensland',
    value: 10004706,
  },
  {
    label: 'Coniston, New South Wales',
    value: 10001649,
  },
  {
    label: 'Conjola, New South Wales',
    value: 10001775,
  },
  {
    label: 'Conjola Park, New South Wales',
    value: 10001784,
  },
  {
    label: 'Connells Point, New South Wales',
    value: 10000669,
  },
  {
    label: 'Connellys Marsh, Tasmania',
    value: 10006310,
  },
  {
    label: 'Connewarre, Victoria',
    value: 10007117,
  },
  {
    label: 'Connewirricoo, Victoria',
    value: 10007280,
  },
  {
    label: 'Connolly, Western Australia',
    value: 10008456,
  },
  {
    label: 'Conoble, New South Wales',
    value: 10002660,
  },
  {
    label: 'Conondale, Queensland',
    value: 10003889,
  },
  {
    label: 'Contine, Western Australia',
    value: 10009094,
  },
  {
    label: 'Conway Beach, Queensland',
    value: 10004777,
  },
  {
    label: 'Coober Pedy, South Australia',
    value: 10006047,
  },
  {
    label: 'Coobowie, South Australia',
    value: 10005981,
  },
  {
    label: 'Coochiemudlo Island, Queensland',
    value: 10003075,
  },
  {
    label: 'Coochin Creek, Queensland',
    value: 10003850,
  },
  {
    label: 'Coocooboonah, New South Wales',
    value: 10001224,
  },
  {
    label: 'Coodanup, Western Australia',
    value: 10008775,
  },
  {
    label: 'Cooee, Tasmania',
    value: 10006613,
  },
  {
    label: 'Cooee Bay, Queensland',
    value: 10004552,
  },
  {
    label: 'Cooeeimbardi, Queensland',
    value: 10003313,
  },
  {
    label: 'Coogee, New South Wales',
    value: 10000255,
  },
  {
    label: 'Coogee, Western Australia',
    value: 10008722,
  },
  {
    label: 'Cook, Australian Capital Territory',
    value: 10000067,
  },
  {
    label: 'Cook, South Australia',
    value: 10006041,
  },
  {
    label: 'Cookamidgera, New South Wales',
    value: 10002637,
  },
  {
    label: 'Cookardinia, New South Wales',
    value: 10002136,
  },
  {
    label: 'Cooke Plains, South Australia',
    value: 10005724,
  },
  {
    label: 'Cookernup, Western Australia',
    value: 10008816,
  },
  {
    label: 'Cooks Hill, New South Wales',
    value: 10000916,
  },
  {
    label: 'Cooktown, Queensland',
    value: 10005229,
  },
  {
    label: 'Coolabah, New South Wales',
    value: 10002561,
  },
  {
    label: 'Coolabunia, Queensland',
    value: 10004070,
  },
  {
    label: 'Coolac, New South Wales',
    value: 10002272,
  },
  {
    label: 'Cooladar Hill, Western Australia',
    value: 10009913,
  },
  {
    label: 'Cooladdi, Queensland',
    value: 10003761,
  },
  {
    label: 'Coolah, New South Wales',
    value: 10002590,
  },
  {
    label: 'Coolalinga, Northern Territory',
    value: 10002751,
  },
  {
    label: 'Coolamon, New South Wales',
    value: 10002225,
  },
  {
    label: 'Coolangatta, New South Wales',
    value: 10001725,
  },
  {
    label: 'Coolangatta, Queensland',
    value: 10003178,
  },
  {
    label: 'Coolaroo, Victoria',
    value: 10006765,
  },
  {
    label: 'Coolatai, New South Wales',
    value: 10001252,
  },
  {
    label: 'Coolbellup, Western Australia',
    value: 10008705,
  },
  {
    label: 'Coolbinia, Western Australia',
    value: 10008497,
  },
  {
    label: 'Coolcalalaya, Western Australia',
    value: 10009802,
  },
  {
    label: 'Cooleys Creek, New South Wales',
    value: 10002273,
  },
  {
    label: 'Coolgardie, Western Australia',
    value: 10009484,
  },
  {
    label: 'Cooljarloo, Western Australia',
    value: 10009681,
  },
  {
    label: 'Coolmunda, Queensland',
    value: 10003603,
  },
  {
    label: 'Coolongolook, New South Wales',
    value: 10001290,
  },
  {
    label: 'Cooloola, Queensland',
    value: 10004021,
  },
  {
    label: 'Cooloola Cove, Queensland',
    value: 10004020,
  },
  {
    label: 'Cooloongup, Western Australia',
    value: 10008741,
  },
  {
    label: 'Cooltong, South Australia',
    value: 10005805,
  },
  {
    label: 'Coolum Beach, Queensland',
    value: 10004007,
  },
  {
    label: 'Coolup, Western Australia',
    value: 10008804,
  },
  {
    label: 'Cooma, New South Wales',
    value: 10002080,
  },
  {
    label: 'Cooma, Victoria',
    value: 10007776,
  },
  {
    label: 'Coomalbidgup, Western Australia',
    value: 10009568,
  },
  {
    label: 'Coomandook, South Australia',
    value: 10005723,
  },
  {
    label: 'Coombabah, Queensland',
    value: 10003147,
  },
  {
    label: 'Coombell, New South Wales',
    value: 10001516,
  },
  {
    label: 'Coomberdale, Western Australia',
    value: 10009693,
  },
  {
    label: 'Coomboona, Victoria',
    value: 10007794,
  },
  {
    label: 'Coomera, Queensland',
    value: 10003108,
  },
  {
    label: 'Coominglah, Queensland',
    value: 10004212,
  },
  {
    label: 'Coominglah Forest, Queensland',
    value: 10004198,
  },
  {
    label: 'Coominya, Queensland',
    value: 10003297,
  },
  {
    label: 'Coomoo, Queensland',
    value: 10004531,
  },
  {
    label: 'Coonabarabran, New South Wales',
    value: 10001172,
  },
  {
    label: 'Coonabidgee, Western Australia',
    value: 10009665,
  },
  {
    label: 'Coonalpyn, South Australia',
    value: 10005734,
  },
  {
    label: 'Coonamble, New South Wales',
    value: 10002543,
  },
  {
    label: 'Coonambula, Queensland',
    value: 10004173,
  },
  {
    label: 'Coonawarra, Northern Territory',
    value: 10002703,
  },
  {
    label: 'Coonawarra, South Australia',
    value: 10005732,
  },
  {
    label: 'Coondle, Western Australia',
    value: 10009851,
  },
  {
    label: 'Coongoola, Queensland',
    value: 10003774,
  },
  {
    label: 'Coopernook, New South Wales',
    value: 10001307,
  },
  {
    label: 'Coopers Plains, Queensland',
    value: 10002958,
  },
  {
    label: 'Coorabie, South Australia',
    value: 10006032,
  },
  {
    label: 'Coorada, Queensland',
    value: 10003686,
  },
  {
    label: 'Cooran, Queensland',
    value: 10003949,
  },
  {
    label: 'Cooranbong, New South Wales',
    value: 10000827,
  },
  {
    label: 'Cooroibah, Queensland',
    value: 10003936,
  },
  {
    label: 'Cooroo Lands, Queensland',
    value: 10005072,
  },
  {
    label: 'Coorooman, Queensland',
    value: 10004488,
  },
  {
    label: 'Coorow, Western Australia',
    value: 10009702,
  },
  {
    label: 'Cooroy, Queensland',
    value: 10003930,
  },
  {
    label: 'Cooroy Mountain, Queensland',
    value: 10003927,
  },
  {
    label: 'Coorparoo, Queensland',
    value: 10003024,
  },
  {
    label: 'Coorumba, Queensland',
    value: 10005071,
  },
  {
    label: 'Cootamundra, New South Wales',
    value: 10002023,
  },
  {
    label: 'Cootharaba, Queensland',
    value: 10003935,
  },
  {
    label: 'Coowonga, Queensland',
    value: 10004526,
  },
  {
    label: 'Cooya Pooya, Western Australia',
    value: 10009983,
  },
  {
    label: 'Cooyar, Queensland',
    value: 10003632,
  },
  {
    label: 'Copacabana, New South Wales',
    value: 10000735,
  },
  {
    label: 'Copeland, New South Wales',
    value: 10001277,
  },
  {
    label: 'Copeville, South Australia',
    value: 10005786,
  },
  {
    label: 'Copley, South Australia',
    value: 10006057,
  },
  {
    label: 'Copley, Western Australia',
    value: 10009842,
  },
  {
    label: 'Copmanhurst, New South Wales',
    value: 10001466,
  },
  {
    label: 'Coppabella, Queensland',
    value: 10004717,
  },
  {
    label: 'Copping, Tasmania',
    value: 10006312,
  },
  {
    label: 'Coquette Point, Queensland',
    value: 10005069,
  },
  {
    label: 'Cora Lynn, Victoria',
    value: 10008102,
  },
  {
    label: 'Corack, Victoria',
    value: 10007600,
  },
  {
    label: 'Coragulac, Victoria',
    value: 10007169,
  },
  {
    label: 'Coraki, New South Wales',
    value: 10001524,
  },
  {
    label: 'Coral Bay, Western Australia',
    value: 10009937,
  },
  {
    label: 'Coral Cove, Queensland',
    value: 10004312,
  },
  {
    label: 'Coramba, New South Wales',
    value: 10001434,
  },
  {
    label: 'Corbie Hill, New South Wales',
    value: 10002229,
  },
  {
    label: 'Cordalba, Queensland',
    value: 10004278,
  },
  {
    label: 'Cordeaux Heights, New South Wales',
    value: 10001684,
  },
  {
    label: 'Cordering, Western Australia',
    value: 10009375,
  },
  {
    label: 'Corella, Queensland',
    value: 10003954,
  },
  {
    label: 'Corfield, Queensland',
    value: 10004648,
  },
  {
    label: 'Corinda, Queensland',
    value: 10002932,
  },
  {
    label: 'Corindhap, Victoria',
    value: 10007377,
  },
  {
    label: 'Corindi Beach, New South Wales',
    value: 10001458,
  },
  {
    label: 'Corinella, Victoria',
    value: 10008369,
  },
  {
    label: 'Coringa, Queensland',
    value: 10004131,
  },
  {
    label: 'Corinthia, Western Australia',
    value: 10009467,
  },
  {
    label: 'Corio, Victoria',
    value: 10007062,
  },
  {
    label: 'Corlette, New South Wales',
    value: 10000948,
  },
  {
    label: 'Corndale, New South Wales',
    value: 10001593,
  },
  {
    label: 'Corndale, Queensland',
    value: 10004079,
  },
  {
    label: 'Cornubia, Queensland',
    value: 10003014,
  },
  {
    label: 'Cornwall, Queensland',
    value: 10003729,
  },
  {
    label: 'Cornwall, Tasmania',
    value: 10006366,
  },
  {
    label: 'Cornwallis, New South Wales',
    value: 10002332,
  },
  {
    label: 'Corny Point, South Australia',
    value: 10005971,
  },
  {
    label: 'Corobimilla, New South Wales',
    value: 10002222,
  },
  {
    label: 'Coromandel East, South Australia',
    value: 10005614,
  },
  {
    label: 'Coromandel Valley, South Australia',
    value: 10005380,
  },
  {
    label: 'Coronet Bay, Victoria',
    value: 10008367,
  },
  {
    label: 'Corop, Victoria',
    value: 10007717,
  },
  {
    label: 'Cororooke, Victoria',
    value: 10007175,
  },
  {
    label: 'Corowa, New South Wales',
    value: 10002119,
  },
  {
    label: 'Corrigin, Western Australia',
    value: 10009331,
  },
  {
    label: 'Corrimal, New South Wales',
    value: 10001673,
  },
  {
    label: 'Corryong, Victoria',
    value: 10007946,
  },
  {
    label: 'Corunnun, Victoria',
    value: 10007153,
  },
  {
    label: 'Cosgrove, Victoria',
    value: 10007811,
  },
  {
    label: 'Cosmo Newbery, Western Australia',
    value: 10009530,
  },
  {
    label: 'Cossack, Western Australia',
    value: 10010000,
  },
  {
    label: 'Costerfield, Victoria',
    value: 10007649,
  },
  {
    label: 'Cottesloe, Western Australia',
    value: 10008419,
  },
  {
    label: 'Cottesloe, Western Australia',
    value: 10010096,
  },
  {
    label: 'Cottles Bridge, Victoria',
    value: 10006845,
  },
  {
    label: 'Cotton Tree, Queensland',
    value: 10003911,
  },
  {
    label: 'Cottonvale, Queensland',
    value: 10003576,
  },
  {
    label: 'Coulta, South Australia',
    value: 10006003,
  },
  {
    label: 'Couridjah, New South Wales',
    value: 10001940,
  },
  {
    label: 'Courtenay, Western Australia',
    value: 10009029,
  },
  {
    label: 'Coutts Crossing, New South Wales',
    value: 10001482,
  },
  {
    label: 'Coverty, Queensland',
    value: 10004098,
  },
  {
    label: 'Cowalellup, Western Australia',
    value: 10009247,
  },
  {
    label: 'Cowalla, Western Australia',
    value: 10009662,
  },
  {
    label: 'Cowan, New South Wales',
    value: 10000348,
  },
  {
    label: 'Cowan Cowan, Queensland',
    value: 10002849,
  },
  {
    label: 'Cowandilla, South Australia',
    value: 10005318,
  },
  {
    label: 'Cowangie, Victoria',
    value: 10007627,
  },
  {
    label: 'Cowaramup, Western Australia',
    value: 10009011,
  },
  {
    label: 'Cowcowing, Western Australia',
    value: 10009630,
  },
  {
    label: 'Cowell, South Australia',
    value: 10005986,
  },
  {
    label: 'Cowes, Victoria',
    value: 10008264,
  },
  {
    label: 'Cowley, Queensland',
    value: 10005132,
  },
  {
    label: 'Cowley Beach, Queensland',
    value: 10005136,
  },
  {
    label: 'Cowley Creek, Queensland',
    value: 10005122,
  },
  {
    label: 'Cowper, New South Wales',
    value: 10001484,
  },
  {
    label: 'Cowra, New South Wales',
    value: 10002449,
  },
  {
    label: 'Cowwarr, Victoria',
    value: 10008172,
  },
  {
    label: 'Coyrecup, Western Australia',
    value: 10009139,
  },
  {
    label: 'Crabbes Creek, New South Wales',
    value: 10001603,
  },
  {
    label: 'Crabtree, Tasmania',
    value: 10006168,
  },
  {
    label: 'Cracow, Queensland',
    value: 10004624,
  },
  {
    label: 'Cradle Mountain, Tasmania',
    value: 10006549,
  },
  {
    label: 'Cradoc, Tasmania',
    value: 10006165,
  },
  {
    label: 'Cradock, South Australia',
    value: 10005871,
  },
  {
    label: 'Crafers, South Australia',
    value: 10005599,
  },
  {
    label: 'Craigburn Farm, South Australia',
    value: 10005379,
  },
  {
    label: 'Craigie, New South Wales',
    value: 10002083,
  },
  {
    label: 'Craigie, Victoria',
    value: 10007573,
  },
  {
    label: 'Craigie, Western Australia',
    value: 10008449,
  },
  {
    label: 'Craigieburn, Victoria',
    value: 10006794,
  },
  {
    label: 'Craigmore, South Australia',
    value: 10005555,
  },
  {
    label: 'Cramphorne, Western Australia',
    value: 10009448,
  },
  {
    label: 'Cranbourne, Victoria',
    value: 10008349,
  },
  {
    label: 'Cranbourne North, Victoria',
    value: 10008348,
  },
  {
    label: 'Cranbourne South, Victoria',
    value: 10008346,
  },
  {
    label: 'Cranbrook, Queensland',
    value: 10004889,
  },
  {
    label: 'Cranbrook, Tasmania',
    value: 10006334,
  },
  {
    label: 'Cranbrook, Western Australia',
    value: 10009151,
  },
  {
    label: 'Cranebrook, New South Wales',
    value: 10002309,
  },
  {
    label: 'Cranley, Queensland',
    value: 10003414,
  },
  {
    label: 'Craven, New South Wales',
    value: 10001286,
  },
  {
    label: 'Crawley, Western Australia',
    value: 10008413,
  },
  {
    label: 'Crediton, Queensland',
    value: 10004766,
  },
  {
    label: 'Creek Junction, Victoria',
    value: 10007860,
  },
  {
    label: 'Creighton, Victoria',
    value: 10007858,
  },
  {
    label: 'Cremorne, New South Wales',
    value: 10000369,
  },
  {
    label: 'Cremorne, Tasmania',
    value: 10006119,
  },
  {
    label: 'Cremorne, Victoria',
    value: 10006868,
  },
  {
    label: 'Cremorne Junction, New South Wales',
    value: 10000368,
  },
  {
    label: 'Crescent Head, New South Wales',
    value: 10001373,
  },
  {
    label: 'Cressy, Tasmania',
    value: 10006516,
  },
  {
    label: 'Cressy, Victoria',
    value: 10007286,
  },
  {
    label: 'Crestmead, Queensland',
    value: 10003018,
  },
  {
    label: 'Creswick, Victoria',
    value: 10007415,
  },
  {
    label: 'Crib Point, Victoria',
    value: 10008261,
  },
  {
    label: 'Cringila, New South Wales',
    value: 10001653,
  },
  {
    label: 'Crohamhurst, Queensland',
    value: 10003852,
  },
  {
    label: 'Croker Island, Northern Territory',
    value: 10002730,
  },
  {
    label: 'Cromarty, Queensland',
    value: 10004852,
  },
  {
    label: 'Cromer, New South Wales',
    value: 10000382,
  },
  {
    label: 'Cromer, Victoria',
    value: 10007027,
  },
  {
    label: 'Cronulla, New South Wales',
    value: 10000689,
  },
  {
    label: 'Crooble, New South Wales',
    value: 10001250,
  },
  {
    label: 'Crooked Brook, Western Australia',
    value: 10008871,
  },
  {
    label: 'Crooked Corner, New South Wales',
    value: 10001999,
  },
  {
    label: 'Crookwell, New South Wales',
    value: 10002008,
  },
  {
    label: 'Croppa Creek, New South Wales',
    value: 10001264,
  },
  {
    label: 'Cross Roads, South Australia',
    value: 10005941,
  },
  {
    label: 'Crossdale, Queensland',
    value: 10003310,
  },
  {
    label: 'Crossmaglen, New South Wales',
    value: 10001377,
  },
  {
    label: 'Crossman, Western Australia',
    value: 10009358,
  },
  {
    label: 'Croudace Bay, New South Wales',
    value: 10000832,
  },
  {
    label: 'Crowdy Head, New South Wales',
    value: 10001309,
  },
  {
    label: 'Crowea, Western Australia',
    value: 10008944,
  },
  {
    label: 'Crowlands, Victoria',
    value: 10007439,
  },
  {
    label: 'Crows Nest, New South Wales',
    value: 10000158,
  },
  {
    label: 'Crows Nest, New South Wales',
    value: 10000311,
  },
  {
    label: 'Crows Nest, Queensland',
    value: 10003492,
  },
  {
    label: 'Crowther, New South Wales',
    value: 10002506,
  },
  {
    label: 'Croydon, New South Wales',
    value: 10000461,
  },
  {
    label: 'Croydon, Queensland',
    value: 10005158,
  },
  {
    label: 'Croydon, South Australia',
    value: 10005252,
  },
  {
    label: 'Croydon, Victoria',
    value: 10006912,
  },
  {
    label: 'Croydon Hills, Victoria',
    value: 10006911,
  },
  {
    label: 'Croydon North, Victoria',
    value: 10006910,
  },
  {
    label: 'Croydon Park, New South Wales',
    value: 10000462,
  },
  {
    label: 'Croydon South, Victoria',
    value: 10006909,
  },
  {
    label: 'Cryon, New South Wales',
    value: 10002571,
  },
  {
    label: 'Crystal Brook, Queensland',
    value: 10004794,
  },
  {
    label: 'Crystal Brook, South Australia',
    value: 10005921,
  },
  {
    label: 'Crystal Creek, New South Wales',
    value: 10001623,
  },
  {
    label: 'Cuballing, Western Australia',
    value: 10009092,
  },
  {
    label: 'Cubbine, Western Australia',
    value: 10009338,
  },
  {
    label: 'Cudal, New South Wales',
    value: 10002622,
  },
  {
    label: 'Cudgee, Victoria',
    value: 10007190,
  },
  {
    label: 'Cudgen, New South Wales',
    value: 10001636,
  },
  {
    label: 'Cudgera Creek, New South Wales',
    value: 10001619,
  },
  {
    label: 'Cudgewa, Victoria',
    value: 10007941,
  },
  {
    label: 'Cudlee Creek, South Australia',
    value: 10005679,
  },
  {
    label: 'Cudmirrah, New South Wales',
    value: 10001790,
  },
  {
    label: 'Cue, Western Australia',
    value: 10009921,
  },
  {
    label: 'Culbin, Western Australia',
    value: 10009365,
  },
  {
    label: 'Culburra, South Australia',
    value: 10005727,
  },
  {
    label: 'Culburra Beach, New South Wales',
    value: 10001788,
  },
  {
    label: 'Culcairn, New South Wales',
    value: 10002175,
  },
  {
    label: 'Culgoa, Victoria',
    value: 10007656,
  },
  {
    label: 'Culham, Western Australia',
    value: 10009847,
  },
  {
    label: 'Cullacabardee, Western Australia',
    value: 10008569,
  },
  {
    label: 'Cullalla, Western Australia',
    value: 10009660,
  },
  {
    label: 'Cullen Bullen, New South Wales',
    value: 10002434,
  },
  {
    label: 'Cullinane, Queensland',
    value: 10005054,
  },
  {
    label: 'Cullulleraine, Victoria',
    value: 10007614,
  },
  {
    label: 'Cumberland Park, South Australia',
    value: 10005343,
  },
  {
    label: 'Cumborah, New South Wales',
    value: 10002569,
  },
  {
    label: 'Cummins, South Australia',
    value: 10006008,
  },
  {
    label: 'Cumnock, New South Wales',
    value: 10002630,
  },
  {
    label: 'Cundare North, Victoria',
    value: 10007174,
  },
  {
    label: 'Cundeelee, Western Australia',
    value: 10009519,
  },
  {
    label: 'Cunderdin, Western Australia',
    value: 10009418,
  },
  {
    label: 'Cundinup, Western Australia',
    value: 10008964,
  },
  {
    label: 'Cundle Flat, New South Wales',
    value: 10001298,
  },
  {
    label: 'Cundletown, New South Wales',
    value: 10001336,
  },
  {
    label: 'Cungena, South Australia',
    value: 10006023,
  },
  {
    label: 'Cungulla, Queensland',
    value: 10004900,
  },
  {
    label: 'Cunjardine, Western Australia',
    value: 10009405,
  },
  {
    label: 'Cunjurong Point, New South Wales',
    value: 10001780,
  },
  {
    label: 'Cunnamulla, Queensland',
    value: 10003778,
  },
  {
    label: 'Cuprona, Tasmania',
    value: 10006603,
  },
  {
    label: 'Curban, New South Wales',
    value: 10002538,
  },
  {
    label: 'Curdie Vale, Victoria',
    value: 10007200,
  },
  {
    label: 'Curdies River, Victoria',
    value: 10007199,
  },
  {
    label: 'Curl Curl, New South Wales',
    value: 10000378,
  },
  {
    label: 'Curlewis, New South Wales',
    value: 10001226,
  },
  {
    label: 'Curlewis, Victoria',
    value: 10007107,
  },
  {
    label: 'Curlwaa, New South Wales',
    value: 10002122,
  },
  {
    label: 'Curra, Queensland',
    value: 10003980,
  },
  {
    label: 'Currabubula, New South Wales',
    value: 10001134,
  },
  {
    label: 'Currajah, Queensland',
    value: 10005154,
  },
  {
    label: 'Currajong, Queensland',
    value: 10004878,
  },
  {
    label: 'Currambine, Western Australia',
    value: 10008462,
  },
  {
    label: 'Curramore, Queensland',
    value: 10003894,
  },
  {
    label: 'Curramulka, South Australia',
    value: 10005977,
  },
  {
    label: 'Currans Hill, New South Wales',
    value: 10001916,
  },
  {
    label: 'Currarong, New South Wales',
    value: 10001805,
  },
  {
    label: 'Currency Creek, South Australia',
    value: 10005672,
  },
  {
    label: 'Currie, Tasmania',
    value: 10006434,
  },
  {
    label: 'Currimundi, Queensland',
    value: 10003880,
  },
  {
    label: 'Currowan, New South Wales',
    value: 10001732,
  },
  {
    label: 'Currumbin, Queensland',
    value: 10003173,
  },
  {
    label: 'Currumbin Dc, Queensland',
    value: 10003174,
  },
  {
    label: 'Currumbin Valley, Queensland',
    value: 10003175,
  },
  {
    label: 'Currumbin Waters, Queensland',
    value: 10003176,
  },
  {
    label: 'Curtin, Australian Capital Territory',
    value: 10000033,
  },
  {
    label: 'Cutella, Queensland',
    value: 10003471,
  },
  {
    label: 'Cuthbert, Western Australia',
    value: 10009179,
  },
  {
    label: 'Cuttabri, New South Wales',
    value: 10001233,
  },
  {
    label: 'Cuttaburra, Queensland',
    value: 10003781,
  },
  {
    label: 'Cuttagee, New South Wales',
    value: 10001834,
  },
  {
    label: 'Cygnet, Tasmania',
    value: 10006196,
  },
  {
    label: 'Cynthia, Queensland',
    value: 10004192,
  },
  {
    label: 'Cypress Gardens, Queensland',
    value: 10003525,
  },
  {
    label: "D'Aguilar, Queensland",
    value: 10003842,
  },
  {
    label: 'Daadenning Creek, Western Australia',
    value: 10009431,
  },
  {
    label: 'Daceyville, New South Wales',
    value: 10000253,
  },
  {
    label: 'Dadswells Bridge, Victoria',
    value: 10007454,
  },
  {
    label: 'Daggar Hills, Western Australia',
    value: 10009916,
  },
  {
    label: 'Daglish, Western Australia',
    value: 10008408,
  },
  {
    label: 'Daintree, Queensland',
    value: 10005181,
  },
  {
    label: 'Dairy Plains, Tasmania',
    value: 10006526,
  },
  {
    label: 'Daisy Hill, Queensland',
    value: 10003007,
  },
  {
    label: 'Daisy Hill, Victoria',
    value: 10007570,
  },
  {
    label: 'Dajarra, Queensland',
    value: 10004971,
  },
  {
    label: 'Dakabin, Queensland',
    value: 10003810,
  },
  {
    label: 'Dakenba, Queensland',
    value: 10004606,
  },
  {
    label: 'Dalbeg, Queensland',
    value: 10004833,
  },
  {
    label: 'Dalby, Queensland',
    value: 10003644,
  },
  {
    label: 'Dale, Western Australia',
    value: 10009067,
  },
  {
    label: 'Daleys Point, New South Wales',
    value: 10000750,
  },
  {
    label: 'Dalgety, New South Wales',
    value: 10002066,
  },
  {
    label: 'Daliak, Western Australia',
    value: 10009048,
  },
  {
    label: 'Dalkeith, Western Australia',
    value: 10008411,
  },
  {
    label: 'Dallas, Victoria',
    value: 10006763,
  },
  {
    label: 'Dalma, Queensland',
    value: 10004496,
  },
  {
    label: 'Dalmeny, New South Wales',
    value: 10001832,
  },
  {
    label: 'Dalmore, Victoria',
    value: 10008362,
  },
  {
    label: 'Dalrymple Heights, Queensland',
    value: 10004765,
  },
  {
    label: 'Dalton, New South Wales',
    value: 10001992,
  },
  {
    label: 'Dalveen, Queensland',
    value: 10003575,
  },
  {
    label: 'Dalwallinu, Western Australia',
    value: 10009879,
  },
  {
    label: 'Dalwood, New South Wales',
    value: 10001089,
  },
  {
    label: 'Dalwood, New South Wales',
    value: 10001547,
  },
  {
    label: 'Daly River, Northern Territory',
    value: 10002722,
  },
  {
    label: 'Daly Waters, Northern Territory',
    value: 10002762,
  },
  {
    label: 'Dalyellup, Western Australia',
    value: 10008858,
  },
  {
    label: 'Dalysford, Queensland',
    value: 10004363,
  },
  {
    label: 'Dalyston, Victoria',
    value: 10008380,
  },
  {
    label: 'Dalyup, Western Australia',
    value: 10009574,
  },
  {
    label: 'Damascus, Queensland',
    value: 10004366,
  },
  {
    label: 'Dampier, Western Australia',
    value: 10009972,
  },
  {
    label: 'Dampier Archipelago, Western Australia',
    value: 10009971,
  },
  {
    label: 'Dampier Peninsula, Western Australia',
    value: 10010023,
  },
  {
    label: 'Danbulla, Queensland',
    value: 10005170,
  },
  {
    label: 'Dandanning, Western Australia',
    value: 10009621,
  },
  {
    label: 'Dandaragan, Western Australia',
    value: 10009679,
  },
  {
    label: 'Dandenong, Victoria',
    value: 10006991,
  },
  {
    label: 'Dandenong South, Victoria',
    value: 10006970,
  },
  {
    label: 'Dandenong South, Victoria',
    value: 10006990,
  },
  {
    label: 'Dandongadale, Victoria',
    value: 10008004,
  },
  {
    label: 'Dangar, New South Wales',
    value: 10000939,
  },
  {
    label: 'Dangar Island, New South Wales',
    value: 10000353,
  },
  {
    label: 'Dangin, Western Australia',
    value: 10009337,
  },
  {
    label: 'Dangore, Queensland',
    value: 10004068,
  },
  {
    label: 'Dapto, New South Wales',
    value: 10001706,
  },
  {
    label: 'Daradgee, Queensland',
    value: 10005051,
  },
  {
    label: 'Darbys Falls, New South Wales',
    value: 10002444,
  },
  {
    label: 'Darch, Western Australia',
    value: 10008563,
  },
  {
    label: 'Dardanup, Western Australia',
    value: 10008870,
  },
  {
    label: 'Dardanup West, Western Australia',
    value: 10008873,
  },
  {
    label: 'Dareton, New South Wales',
    value: 10002260,
  },
  {
    label: 'Dargal Road, Queensland',
    value: 10003723,
  },
  {
    label: 'Dargan, New South Wales',
    value: 10002414,
  },
  {
    label: 'Dargo, Victoria',
    value: 10008183,
  },
  {
    label: 'Dark Corner, New South Wales',
    value: 10002472,
  },
  {
    label: 'Darkan, Western Australia',
    value: 10009371,
  },
  {
    label: 'Darke Peak, South Australia',
    value: 10006015,
  },
  {
    label: 'Darkes Forest, New South Wales',
    value: 10001657,
  },
  {
    label: 'Darlimurla, Victoria',
    value: 10008191,
  },
  {
    label: 'Darling, Victoria',
    value: 10006931,
  },
  {
    label: 'Darling Downs, Western Australia',
    value: 10008648,
  },
  {
    label: 'Darling Heights, Queensland',
    value: 10003392,
  },
  {
    label: 'Darling Point, New South Wales',
    value: 10000241,
  },
  {
    label: 'Darlinghurst, New South Wales',
    value: 10000132,
  },
  {
    label: 'Darlinghurst, New South Wales',
    value: 10000206,
  },
  {
    label: 'Darlington, New South Wales',
    value: 10000204,
  },
  {
    label: 'Darlington, South Australia',
    value: 10005366,
  },
  {
    label: 'Darlington, Victoria',
    value: 10007206,
  },
  {
    label: 'Darlington, Western Australia',
    value: 10008577,
  },
  {
    label: 'Darlington Point, New South Wales',
    value: 10002233,
  },
  {
    label: 'Darnley Island, New South Wales',
    value: 10010204,
  },
  {
    label: 'Darnum, Victoria',
    value: 10008120,
  },
  {
    label: 'Daroobalgie, New South Wales',
    value: 10002640,
  },
  {
    label: 'Darra, Queensland',
    value: 10002933,
  },
  {
    label: 'Darradup, Western Australia',
    value: 10008968,
  },
  {
    label: 'Darraweit Guim, Victoria',
    value: 10008031,
  },
  {
    label: 'Darriman, Victoria',
    value: 10008164,
  },
  {
    label: 'Dartbrook, New South Wales',
    value: 10001095,
  },
  {
    label: 'Dartmoor, Victoria',
    value: 10007256,
  },
  {
    label: 'Dartmoor, Western Australia',
    value: 10009807,
  },
  {
    label: 'Dartmouth, Victoria',
    value: 10007936,
  },
  {
    label: 'Dartnall, Western Australia',
    value: 10009145,
  },
  {
    label: 'Darts Creek, Queensland',
    value: 10004432,
  },
  {
    label: 'Darwin, Northern Territory',
    value: 10002674,
  },
  {
    label: 'Darwin, Northern Territory',
    value: 10002675,
  },
  {
    label: 'Darwin Mc, Northern Territory',
    value: 10002716,
  },
  {
    label: 'Datatine, Western Australia',
    value: 10009134,
  },
  {
    label: 'Davenport, Western Australia',
    value: 10008848,
  },
  {
    label: 'Daveson, Queensland',
    value: 10005024,
  },
  {
    label: 'Davidson, New South Wales',
    value: 10000359,
  },
  {
    label: 'Davis, Tasmania',
    value: 10006285,
  },
  {
    label: 'Davis Creek, New South Wales',
    value: 10001097,
  },
  {
    label: 'Davistown, New South Wales',
    value: 10000743,
  },
  {
    label: 'Davoren Park, South Australia',
    value: 10005545,
  },
  {
    label: 'Daw Park, South Australia',
    value: 10005342,
  },
  {
    label: 'Dawes Point, New South Wales',
    value: 10000194,
  },
  {
    label: 'Dawesville, Western Australia',
    value: 10008789,
  },
  {
    label: 'Dayboro, Queensland',
    value: 10003871,
  },
  {
    label: 'Daydream Island, Queensland',
    value: 10004715,
  },
  {
    label: 'Daylesford, Victoria',
    value: 10007551,
  },
  {
    label: 'Daymar, Queensland',
    value: 10003793,
  },
  {
    label: 'Daysdale, New South Wales',
    value: 10002114,
  },
  {
    label: 'De Grey, Western Australia',
    value: 10010013,
  },
  {
    label: 'Deagon, Queensland',
    value: 10002833,
  },
  {
    label: 'Deakin, Australian Capital Territory',
    value: 10000007,
  },
  {
    label: 'Deakin University, Victoria',
    value: 10007076,
  },
  {
    label: 'Deakin West, Australian Capital Territory',
    value: 10000003,
  },
  {
    label: 'Dean, Victoria',
    value: 10007390,
  },
  {
    label: 'Dean Park, New South Wales',
    value: 10002351,
  },
  {
    label: 'Deanmill, Western Australia',
    value: 10008925,
  },
  {
    label: 'Deans Marsh, Victoria',
    value: 10007130,
  },
  {
    label: 'Deception Bay, Queensland',
    value: 10003824,
  },
  {
    label: 'Deddington, Tasmania',
    value: 10006345,
  },
  {
    label: 'Dederang, Victoria',
    value: 10007918,
  },
  {
    label: 'Dee, Tasmania',
    value: 10006264,
  },
  {
    label: 'Dee Why, New South Wales',
    value: 10000384,
  },
  {
    label: 'Deebing Heights, Queensland',
    value: 10003285,
  },
  {
    label: 'Deep Bay, Tasmania',
    value: 10006189,
  },
  {
    label: 'Deep Creek, Queensland',
    value: 10004138,
  },
  {
    label: 'Deepdale, Western Australia',
    value: 10009766,
  },
  {
    label: 'Deepdene, Western Australia',
    value: 10009040,
  },
  {
    label: 'Deepwater, New South Wales',
    value: 10001208,
  },
  {
    label: 'Deepwater, Queensland',
    value: 10004374,
  },
  {
    label: 'Deer Park, Victoria',
    value: 10006711,
  },
  {
    label: 'Deer Park East, Victoria',
    value: 10006709,
  },
  {
    label: 'Deeragun, Queensland',
    value: 10004933,
  },
  {
    label: 'Deeral, Queensland',
    value: 10005128,
  },
  {
    label: 'Degilbo, Queensland',
    value: 10004132,
  },
  {
    label: 'Delacombe, Victoria',
    value: 10007410,
  },
  {
    label: 'Delahey, Victoria',
    value: 10006740,
  },
  {
    label: 'Delamere, South Australia',
    value: 10005662,
  },
  {
    label: 'Delan, Queensland',
    value: 10004338,
  },
  {
    label: 'Delegate, New South Wales',
    value: 10002088,
  },
  {
    label: 'Delissaville, Northern Territory',
    value: 10002720,
  },
  {
    label: 'Deloraine, Tasmania',
    value: 10006540,
  },
  {
    label: 'Delungra, New South Wales',
    value: 10001255,
  },
  {
    label: 'Denbarker, Western Australia',
    value: 10009154,
  },
  {
    label: 'Dendy, Victoria',
    value: 10007011,
  },
  {
    label: 'Denham, Western Australia',
    value: 10009832,
  },
  {
    label: 'Denham Court, New South Wales',
    value: 10001908,
  },
  {
    label: 'Denhams Beach, New South Wales',
    value: 10001743,
  },
  {
    label: 'Deniliquin, New South Wales',
    value: 10002238,
  },
  {
    label: 'Denistone, New South Wales',
    value: 10000433,
  },
  {
    label: 'Denistone East, New South Wales',
    value: 10000422,
  },
  {
    label: 'Denistone West, New South Wales',
    value: 10000431,
  },
  {
    label: 'Denman, New South Wales',
    value: 10001054,
  },
  {
    label: 'Denmark, Western Australia',
    value: 10009230,
  },
  {
    label: 'Dennes Point, Tasmania',
    value: 10006277,
  },
  {
    label: 'Dennington, Victoria',
    value: 10007219,
  },
  {
    label: 'Derby, Western Australia',
    value: 10010183,
  },
  {
    label: 'Derby, Tasmania',
    value: 10006464,
  },
  {
    label: 'Derby, Victoria',
    value: 10007632,
  },
  {
    label: 'Derby, Western Australia',
    value: 10010027,
  },
  {
    label: 'Dereel, Victoria',
    value: 10007363,
  },
  {
    label: 'Dergholm, Victoria',
    value: 10007271,
  },
  {
    label: 'Dernancourt, South Australia',
    value: 10005464,
  },
  {
    label: 'Derri Derra, Queensland',
    value: 10004183,
  },
  {
    label: 'Derrimut, Victoria',
    value: 10006725,
  },
  {
    label: 'Derrinallum, Victoria',
    value: 10007290,
  },
  {
    label: 'Derriwong, New South Wales',
    value: 10002657,
  },
  {
    label: 'Derrymore, Queensland',
    value: 10003426,
  },
  {
    label: 'Derwent Bridge, Tasmania',
    value: 10006255,
  },
  {
    label: 'Derwent Park, Tasmania',
    value: 10006080,
  },
  {
    label: 'Deua, New South Wales',
    value: 10001763,
  },
  {
    label: 'Deua River Valley, New South Wales',
    value: 10001759,
  },
  {
    label: 'Deuchar, Queensland',
    value: 10003563,
  },
  {
    label: 'Devenish, Victoria',
    value: 10007986,
  },
  {
    label: 'Devereux Creek, Queensland',
    value: 10004754,
  },
  {
    label: 'Devils Creek, Western Australia',
    value: 10009900,
  },
  {
    label: 'Deviot, Tasmania',
    value: 10006490,
  },
  {
    label: 'Devon Hills, Tasmania',
    value: 10006507,
  },
  {
    label: 'Devon Meadows, Victoria',
    value: 10008351,
  },
  {
    label: 'Devon North, Victoria',
    value: 10008336,
  },
  {
    label: 'Devon Park, Queensland',
    value: 10003625,
  },
  {
    label: 'Devon Park, South Australia',
    value: 10005254,
  },
  {
    label: 'Devonport, Tasmania',
    value: 10006585,
  },
  {
    label: 'Dewars Pool, Western Australia',
    value: 10009855,
  },
  {
    label: 'Dharruk, New South Wales',
    value: 10002382,
  },
  {
    label: 'Dhurringile, Victoria',
    value: 10007770,
  },
  {
    label: 'Diamantina Lakes, Queensland',
    value: 10004649,
  },
  {
    label: 'Diamond Beach, New South Wales',
    value: 10001339,
  },
  {
    label: 'Diamond Creek, Victoria',
    value: 10006833,
  },
  {
    label: 'Diamond Tree, Western Australia',
    value: 10008934,
  },
  {
    label: 'Diamond Valley, Queensland',
    value: 10003900,
  },
  {
    label: 'Dianella, Western Australia',
    value: 10008534,
  },
  {
    label: 'Dickson, Australian Capital Territory',
    value: 10000023,
  },
  {
    label: 'Dicky Beach, Queensland',
    value: 10003876,
  },
  {
    label: 'Didcot, Queensland',
    value: 10004135,
  },
  {
    label: 'Diddillibah, Queensland',
    value: 10003915,
  },
  {
    label: 'Digby, Victoria',
    value: 10007262,
  },
  {
    label: 'Diggers Rest, Victoria',
    value: 10007506,
  },
  {
    label: 'Diglum, Queensland',
    value: 10004427,
  },
  {
    label: 'Dignams Creek, New South Wales',
    value: 10001839,
  },
  {
    label: 'Dilston, Tasmania',
    value: 10006410,
  },
  {
    label: 'Dimboola, Victoria',
    value: 10007493,
  },
  {
    label: 'Dimbulah, Queensland',
    value: 10005172,
  },
  {
    label: 'Dindiloa, Western Australia',
    value: 10009777,
  },
  {
    label: 'Dingee, Victoria',
    value: 10007735,
  },
  {
    label: 'Dingley Village, Victoria',
    value: 10006985,
  },
  {
    label: 'Dingo, Queensland',
    value: 10004498,
  },
  {
    label: 'Dingup, Western Australia',
    value: 10008948,
  },
  {
    label: 'Dinmore, Queensland',
    value: 10003218,
  },
  {
    label: 'Dinner Plain, Victoria',
    value: 10008241,
  },
  {
    label: 'Dinninup, Western Australia',
    value: 10008899,
  },
  {
    label: 'Dinoga, New South Wales',
    value: 10001257,
  },
  {
    label: 'Direk, South Australia',
    value: 10005535,
  },
  {
    label: 'Dirnbir, Queensland',
    value: 10004158,
  },
  {
    label: 'Dirranbandi, Queensland',
    value: 10003767,
  },
  {
    label: 'Dittmer, Queensland',
    value: 10004784,
  },
  {
    label: 'Diwan, Queensland',
    value: 10005180,
  },
  {
    label: 'Dixalea, Queensland',
    value: 10004480,
  },
  {
    label: 'Dixons Creek, Victoria',
    value: 10008054,
  },
  {
    label: 'Dixvale, Western Australia',
    value: 10008938,
  },
  {
    label: 'Djiru, Queensland',
    value: 10005017,
  },
  {
    label: 'Djuan, Queensland',
    value: 10003465,
  },
  {
    label: 'Djugun, Western Australia',
    value: 10010022,
  },
  {
    label: 'Dobies Bight, New South Wales',
    value: 10001512,
  },
  {
    label: 'Docklands, Victoria',
    value: 10006683,
  },
  {
    label: 'Doctor Creek, Queensland',
    value: 10003462,
  },
  {
    label: 'Doctor George Mountain, New South Wales',
    value: 10001852,
  },
  {
    label: 'Doctors Rocks, Tasmania',
    value: 10006640,
  },
  {
    label: 'Dodges Ferry, Tasmania',
    value: 10006306,
  },
  {
    label: 'Dog Swamp, Western Australia',
    value: 10008538,
  },
  {
    label: 'Dolans Bay, New South Wales',
    value: 10000687,
  },
  {
    label: 'Dolphin Heads, Queensland',
    value: 10004663,
  },
  {
    label: 'Domville, Queensland',
    value: 10003538,
  },
  {
    label: 'Don, Tasmania',
    value: 10006586,
  },
  {
    label: 'Donald, Victoria',
    value: 10007599,
  },
  {
    label: 'Doncaster, Victoria',
    value: 10006857,
  },
  {
    label: 'Doncaster East, Victoria',
    value: 10006859,
  },
  {
    label: 'Doncaster Heights, Victoria',
    value: 10006858,
  },
  {
    label: 'Dongara, Western Australia',
    value: 10009731,
  },
  {
    label: 'Dongolocking, Western Australia',
    value: 10009117,
  },
  {
    label: 'Donnelly River, Western Australia',
    value: 10008930,
  },
  {
    label: 'Donnybrook, Queensland',
    value: 10003829,
  },
  {
    label: 'Donnybrook, Victoria',
    value: 10006791,
  },
  {
    label: 'Donnybrook, Western Australia',
    value: 10008884,
  },
  {
    label: 'Donovans, South Australia',
    value: 10005760,
  },
  {
    label: 'Donvale, Victoria',
    value: 10006862,
  },
  {
    label: 'Doodenanning, Western Australia',
    value: 10009341,
  },
  {
    label: 'Doodlakine, Western Australia',
    value: 10009433,
  },
  {
    label: 'Dooen, Victoria',
    value: 10007473,
  },
  {
    label: 'Dookie, Victoria',
    value: 10007834,
  },
  {
    label: 'Dookie College, Victoria',
    value: 10007836,
  },
  {
    label: 'Doolandella, Queensland',
    value: 10002938,
  },
  {
    label: 'Doomadgee, Queensland',
    value: 10004997,
  },
  {
    label: 'Doon Doon, New South Wales',
    value: 10001617,
  },
  {
    label: 'Doongin, Western Australia',
    value: 10009425,
  },
  {
    label: 'Doongul, Queensland',
    value: 10004125,
  },
  {
    label: 'Doonside, New South Wales',
    value: 10002370,
  },
  {
    label: 'Dooralong, New South Wales',
    value: 10000777,
  },
  {
    label: 'Dora Creek, New South Wales',
    value: 10000820,
  },
  {
    label: 'Doreen, Victoria',
    value: 10008026,
  },
  {
    label: 'Dorre Island, Western Australia',
    value: 10009934,
  },
  {
    label: 'Dorrigo, New South Wales',
    value: 10001447,
  },
  {
    label: 'Dorrington, Queensland',
    value: 10002894,
  },
  {
    label: 'Dorroughby, New South Wales',
    value: 10001577,
  },
  {
    label: 'Double Bay, New South Wales',
    value: 10000137,
  },
  {
    label: 'Double Bay, New South Wales',
    value: 10000242,
  },
  {
    label: 'Doubleview, Western Australia',
    value: 10008430,
  },
  {
    label: 'Doubtful Creek, New South Wales',
    value: 10001515,
  },
  {
    label: 'Doughboy, Queensland',
    value: 10004340,
  },
  {
    label: 'Douglas, Queensland',
    value: 10003489,
  },
  {
    label: 'Douglas, Queensland',
    value: 10004887,
  },
  {
    label: 'Douglas, Victoria',
    value: 10007485,
  },
  {
    label: 'Douglas Park, New South Wales',
    value: 10001921,
  },
  {
    label: 'Douglas River, Tasmania',
    value: 10006357,
  },
  {
    label: 'Dover, Tasmania',
    value: 10006205,
  },
  {
    label: 'Dover Gardens, South Australia',
    value: 10005371,
  },
  {
    label: 'Dover Heights, New South Wales',
    value: 10000247,
  },
  {
    label: 'Doveton, Victoria',
    value: 10006994,
  },
  {
    label: 'Dowerin, Western Australia',
    value: 10009586,
  },
  {
    label: 'Downer, Australian Capital Territory',
    value: 10000020,
  },
  {
    label: 'Downsfield, Queensland',
    value: 10003966,
  },
  {
    label: 'Downside, New South Wales',
    value: 10002144,
  },
  {
    label: 'Dows Creek, Queensland',
    value: 10004761,
  },
  {
    label: 'Dowsing Point, Tasmania',
    value: 10006083,
  },
  {
    label: 'Doyalson, New South Wales',
    value: 10000809,
  },
  {
    label: 'Draper, Queensland',
    value: 10003856,
  },
  {
    label: 'Drayton, Queensland',
    value: 10003413,
  },
  {
    label: 'Dreeite, Victoria',
    value: 10007164,
  },
  {
    label: 'Drewvale, Queensland',
    value: 10002979,
  },
  {
    label: 'Drillham, Queensland',
    value: 10003698,
  },
  {
    label: 'Drillham South, Queensland',
    value: 10003697,
  },
  {
    label: 'Drinan, Queensland',
    value: 10004351,
  },
  {
    label: 'Dripstone, New South Wales',
    value: 10002519,
  },
  {
    label: 'Driver, Northern Territory',
    value: 10002739,
  },
  {
    label: 'Dromana, Victoria',
    value: 10008281,
  },
  {
    label: 'Drome, Western Australia',
    value: 10009174,
  },
  {
    label: 'Dromedary, Queensland',
    value: 10004615,
  },
  {
    label: 'Dromedary, Tasmania',
    value: 10006138,
  },
  {
    label: 'Drouin, Victoria',
    value: 10008112,
  },
  {
    label: 'Drummond, Victoria',
    value: 10007558,
  },
  {
    label: 'Drummond Cove, Western Australia',
    value: 10009773,
  },
  {
    label: 'Drummond North, Victoria',
    value: 10007530,
  },
  {
    label: 'Drummoyne, New South Wales',
    value: 10000146,
  },
  {
    label: 'Drummoyne, New South Wales',
    value: 10000288,
  },
  {
    label: 'Dry Creek, South Australia',
    value: 10005508,
  },
  {
    label: 'Dryandra, Western Australia',
    value: 10009096,
  },
  {
    label: 'Drysdale, Victoria',
    value: 10007104,
  },
  {
    label: 'Drysdale River, Western Australia',
    value: 10010031,
  },
  {
    label: 'Duaringa, Queensland',
    value: 10004581,
  },
  {
    label: 'Dubbo, New South Wales',
    value: 10002548,
  },
  {
    label: 'Dublin, South Australia',
    value: 10005910,
  },
  {
    label: 'Duckinwilla, Queensland',
    value: 10004235,
  },
  {
    label: 'Duckmaloi, New South Wales',
    value: 10002419,
  },
  {
    label: 'Dudawa, Western Australia',
    value: 10009710,
  },
  {
    label: 'Dudinin, Western Australia',
    value: 10009311,
  },
  {
    label: 'Dudley, New South Wales',
    value: 10000896,
  },
  {
    label: 'Dudley Park, South Australia',
    value: 10005249,
  },
  {
    label: 'Dudley Park, Western Australia',
    value: 10008781,
  },
  {
    label: 'Duffy, Australian Capital Territory',
    value: 10000056,
  },
  {
    label: 'Duffys Forest, New South Wales',
    value: 10000356,
  },
  {
    label: 'Duingal, Queensland',
    value: 10004346,
  },
  {
    label: 'Dukin, Western Australia',
    value: 10009611,
  },
  {
    label: 'Dulacca, Queensland',
    value: 10003700,
  },
  {
    label: 'Dulbelling, Western Australia',
    value: 10009343,
  },
  {
    label: 'Dulcot, Tasmania',
    value: 10006121,
  },
  {
    label: 'Dulong, Queensland',
    value: 10003919,
  },
  {
    label: 'Dululu, Queensland',
    value: 10004522,
  },
  {
    label: 'Dulwich, South Australia',
    value: 10005411,
  },
  {
    label: 'Dulwich Hill, New South Wales',
    value: 10000619,
  },
  {
    label: 'Dulyalbin, Western Australia',
    value: 10009459,
  },
  {
    label: 'Dumbalk, Victoria',
    value: 10008312,
  },
  {
    label: 'Dumbarton, Western Australia',
    value: 10009846,
  },
  {
    label: 'Dumberning, Western Australia',
    value: 10009105,
  },
  {
    label: 'Dumbleyung, Western Australia',
    value: 10009273,
  },
  {
    label: 'Dumgree, Queensland',
    value: 10004602,
  },
  {
    label: 'Dumpy Creek, Queensland',
    value: 10004479,
  },
  {
    label: 'Dunalley, Tasmania',
    value: 10006316,
  },
  {
    label: 'Dunbible, New South Wales',
    value: 10001622,
  },
  {
    label: 'Dunbogan, New South Wales',
    value: 10001393,
  },
  {
    label: 'Duncraig, Western Australia',
    value: 10008446,
  },
  {
    label: 'Dundarrah, Queensland',
    value: 10004145,
  },
  {
    label: 'Dundas, New South Wales',
    value: 10000437,
  },
  {
    label: 'Dundas, Western Australia',
    value: 10009541,
  },
  {
    label: 'Dundathu, Queensland',
    value: 10004213,
  },
  {
    label: 'Dundee, New South Wales',
    value: 10001204,
  },
  {
    label: 'Dundee Beach, Northern Territory',
    value: 10002756,
  },
  {
    label: 'Dundonnell, Victoria',
    value: 10007205,
  },
  {
    label: 'Dundowran Beach, Queensland',
    value: 10004265,
  },
  {
    label: 'Dundula, Queensland',
    value: 10004667,
  },
  {
    label: 'Dundurrabin, New South Wales',
    value: 10001444,
  },
  {
    label: 'Dunedoo, New South Wales',
    value: 10002592,
  },
  {
    label: 'Dungay, New South Wales',
    value: 10001616,
  },
  {
    label: 'Dungog, New South Wales',
    value: 10001270,
  },
  {
    label: 'Dungowan, New South Wales',
    value: 10001119,
  },
  {
    label: 'Dunkeld, New South Wales',
    value: 10002476,
  },
  {
    label: 'Dunkeld, Victoria',
    value: 10007245,
  },
  {
    label: 'Dunkirk, Victoria',
    value: 10007798,
  },
  {
    label: 'Dunlop, Australian Capital Territory',
    value: 10000073,
  },
  {
    label: 'Dunmora, Queensland',
    value: 10004215,
  },
  {
    label: 'Dunmore, New South Wales',
    value: 10001699,
  },
  {
    label: 'Dunmore, Queensland',
    value: 10003658,
  },
  {
    label: 'Dunn Rock, Western Australia',
    value: 10009291,
  },
  {
    label: 'Dunnrock, Queensland',
    value: 10004675,
  },
  {
    label: 'Dunnstown, Victoria',
    value: 10007394,
  },
  {
    label: 'Dunolly, Victoria',
    value: 10007584,
  },
  {
    label: 'Dunoon, New South Wales',
    value: 10001586,
  },
  {
    label: 'Dunorlan, Tasmania',
    value: 10006532,
  },
  {
    label: 'Dunsborough, Western Australia',
    value: 10009004,
  },
  {
    label: 'Duntroon, Australian Capital Territory',
    value: 10000010,
  },
  {
    label: 'Dunwich, Queensland',
    value: 10003074,
  },
  {
    label: 'Durack, Queensland',
    value: 10002935,
  },
  {
    label: 'Durack, Western Australia',
    value: 10010037,
  },
  {
    label: 'Dural, New South Wales',
    value: 10000525,
  },
  {
    label: 'Dural, New South Wales',
    value: 10001071,
  },
  {
    label: 'Duramana, New South Wales',
    value: 10002459,
  },
  {
    label: 'Duranbah, New South Wales',
    value: 10001633,
  },
  {
    label: 'Duranillin, Western Australia',
    value: 10009374,
  },
  {
    label: 'Durawah, Western Australia',
    value: 10009772,
  },
  {
    label: 'Durham Downs, Queensland',
    value: 10003713,
  },
  {
    label: 'Durham Lead, Victoria',
    value: 10007396,
  },
  {
    label: 'Durham Ox, Victoria',
    value: 10007745,
  },
  {
    label: 'Duri, New South Wales',
    value: 10001142,
  },
  {
    label: 'Durong, Queensland',
    value: 10004072,
  },
  {
    label: 'Durong South, Queensland',
    value: 10004067,
  },
  {
    label: 'Durras North, New South Wales',
    value: 10001745,
  },
  {
    label: 'Durren Durren, New South Wales',
    value: 10000774,
  },
  {
    label: 'Dutton Park, Queensland',
    value: 10002946,
  },
  {
    label: 'Duverney, Victoria',
    value: 10007288,
  },
  {
    label: 'Dwarda, Western Australia',
    value: 10009081,
  },
  {
    label: 'Dwellingup, Western Australia',
    value: 10008796,
  },
  {
    label: 'Dyers Crossing, New South Wales',
    value: 10001334,
  },
  {
    label: 'Dykehead, Queensland',
    value: 10004178,
  },
  {
    label: 'Dynnyrne, Tasmania',
    value: 10006074,
  },
  {
    label: 'Dyraaba, New South Wales',
    value: 10001504,
  },
  {
    label: 'Dyraaba Central, New South Wales',
    value: 10001506,
  },
  {
    label: 'Dyraaba Creek, New South Wales',
    value: 10001502,
  },
  {
    label: 'Dysart, Queensland',
    value: 10004745,
  },
  {
    label: 'Dysart, Tasmania',
    value: 10006145,
  },
  {
    label: 'Eagle Bay, Western Australia',
    value: 10009007,
  },
  {
    label: 'Eagle Farm, Queensland',
    value: 10002820,
  },
  {
    label: 'Eagle Heights, Queensland',
    value: 10003190,
  },
  {
    label: 'Eagle On The Hill, South Australia',
    value: 10005596,
  },
  {
    label: 'Eagle Point, Victoria',
    value: 10008207,
  },
  {
    label: 'Eagle Vale, New South Wales',
    value: 10001884,
  },
  {
    label: 'Eagleby, Queensland',
    value: 10003087,
  },
  {
    label: 'Eaglefield, Queensland',
    value: 10004731,
  },
  {
    label: 'Eaglehawk, Victoria',
    value: 10007708,
  },
  {
    label: 'Eaglehawk Neck, Tasmania',
    value: 10006318,
  },
  {
    label: 'Eaglemont, Victoria',
    value: 10006824,
  },
  {
    label: 'Eagleton, New South Wales',
    value: 10001005,
  },
  {
    label: 'Earlville, Queensland',
    value: 10005112,
  },
  {
    label: 'Earlwood, New South Wales',
    value: 10000627,
  },
  {
    label: 'East Albury, New South Wales',
    value: 10002089,
  },
  {
    label: 'East Armidale, New South Wales',
    value: 10001149,
  },
  {
    label: 'East Augusta, Western Australia',
    value: 10009038,
  },
  {
    label: 'East Ballidu, Western Australia',
    value: 10009875,
  },
  {
    label: 'East Barron, Queensland',
    value: 10005213,
  },
  {
    label: 'East Beverley, Western Australia',
    value: 10009066,
  },
  {
    label: 'East Bowes, Western Australia',
    value: 10009826,
  },
  {
    label: 'East Brisbane, Queensland',
    value: 10003050,
  },
  {
    label: 'East Bunbury, Western Australia',
    value: 10008853,
  },
  {
    label: 'East Carnarvon, Western Australia',
    value: 10009952,
  },
  {
    label: 'East Chapman, Western Australia',
    value: 10009798,
  },
  {
    label: 'East Cooyar, Queensland',
    value: 10003488,
  },
  {
    label: 'East Damboring, Western Australia',
    value: 10009878,
  },
  {
    label: 'East End, Queensland',
    value: 10004434,
  },
  {
    label: 'East Fremantle, Western Australia',
    value: 10008695,
  },
  {
    label: 'East Geelong, Victoria',
    value: 10007080,
  },
  {
    label: 'East Gosford, New South Wales',
    value: 10000724,
  },
  {
    label: 'East Gresford, New South Wales',
    value: 10000943,
  },
  {
    label: 'East Haldon, Queensland',
    value: 10003350,
  },
  {
    label: 'East Hills, New South Wales',
    value: 10000646,
  },
  {
    label: 'East Innisfail, Queensland',
    value: 10005058,
  },
  {
    label: 'East Ipswich, Queensland',
    value: 10003225,
  },
  {
    label: 'East Launceston, Tasmania',
    value: 10006388,
  },
  {
    label: 'East Lindfield, New South Wales',
    value: 10000331,
  },
  {
    label: 'East Lynne, New South Wales',
    value: 10001731,
  },
  {
    label: 'East Lyons River, Western Australia',
    value: 10009958,
  },
  {
    label: 'East Mackay, Queensland',
    value: 10004689,
  },
  {
    label: 'East Maitland, New South Wales',
    value: 10000995,
  },
  {
    label: 'East Melbourne, Victoria',
    value: 10006679,
  },
  {
    label: 'East Melbourne, Victoria',
    value: 10008389,
  },
  {
    label: 'East Moonta, South Australia',
    value: 10005947,
  },
  {
    label: 'East Munglinup, Western Australia',
    value: 10009570,
  },
  {
    label: 'East Murchison, Western Australia',
    value: 10009920,
  },
  {
    label: 'East Nabawa, Western Australia',
    value: 10009796,
  },
  {
    label: 'East Nanango, Queensland',
    value: 10004113,
  },
  {
    label: 'East Nannup, Western Australia',
    value: 10008961,
  },
  {
    label: 'East Newdegate, Western Australia',
    value: 10009297,
  },
  {
    label: 'East Palmerston, Queensland',
    value: 10005073,
  },
  {
    label: 'East Perth, Western Australia',
    value: 10008401,
  },
  {
    label: 'East Perth, Western Australia',
    value: 10010086,
  },
  {
    label: 'East Pingelly, Western Australia',
    value: 10009083,
  },
  {
    label: 'East Point, Northern Territory',
    value: 10002705,
  },
  {
    label: 'East Popanyinning, Western Australia',
    value: 10009088,
  },
  {
    label: 'East Rockingham, Western Australia',
    value: 10008740,
  },
  {
    label: 'East Russell, Queensland',
    value: 10005082,
  },
  {
    label: 'East Ryde, New South Wales',
    value: 10000427,
  },
  {
    label: 'East Sale Raaf, Victoria',
    value: 10008168,
  },
  {
    label: 'East Seaham, New South Wales',
    value: 10001011,
  },
  {
    label: 'East Toowoomba, Queensland',
    value: 10003408,
  },
  {
    label: 'East Trinity, Queensland',
    value: 10005144,
  },
  {
    label: 'East Victoria Park, Western Australia',
    value: 10008606,
  },
  {
    label: 'East Victoria Park, Western Australia',
    value: 10010148,
  },
  {
    label: 'East Wickepin, Western Australia',
    value: 10009325,
  },
  {
    label: 'East Yuna, Western Australia',
    value: 10009792,
  },
  {
    label: 'Eastbrook, Western Australia',
    value: 10008949,
  },
  {
    label: 'Eastern Creek, New South Wales',
    value: 10002367,
  },
  {
    label: 'Eastern Heights, Queensland',
    value: 10003241,
  },
  {
    label: 'Eastern Suburbs Mc, New South Wales',
    value: 10000199,
  },
  {
    label: 'Eastern View, Victoria',
    value: 10007125,
  },
  {
    label: 'Eastgardens, New South Wales',
    value: 10000262,
  },
  {
    label: 'Eastlakes, New South Wales',
    value: 10000220,
  },
  {
    label: 'Eastwood, New South Wales',
    value: 10000450,
  },
  {
    label: 'Eastwood, South Australia',
    value: 10005402,
  },
  {
    label: 'Eaton, Queensland',
    value: 10005060,
  },
  {
    label: 'Eaton, Western Australia',
    value: 10008862,
  },
  {
    label: 'Eatons Hill, Queensland',
    value: 10002869,
  },
  {
    label: 'Ebbw Vale, Queensland',
    value: 10003222,
  },
  {
    label: 'Ebden, Victoria',
    value: 10007913,
  },
  {
    label: 'Ebenezer, New South Wales',
    value: 10002341,
  },
  {
    label: 'Ebor, New South Wales',
    value: 10001448,
  },
  {
    label: 'Echuca, Victoria',
    value: 10007724,
  },
  {
    label: 'Echunga, South Australia',
    value: 10005604,
  },
  {
    label: 'Edderton, New South Wales',
    value: 10001081,
  },
  {
    label: 'Eddington, Victoria',
    value: 10007589,
  },
  {
    label: 'Eden, New South Wales',
    value: 10001877,
  },
  {
    label: 'Eden Hill, Western Australia',
    value: 10008504,
  },
  {
    label: 'Eden Hills, South Australia',
    value: 10005378,
  },
  {
    label: 'Eden Park, Victoria',
    value: 10008037,
  },
  {
    label: 'Eden Valley, South Australia',
    value: 10005684,
  },
  {
    label: 'Edenhope, Victoria',
    value: 10007281,
  },
  {
    label: 'Edens Landing, Queensland',
    value: 10003094,
  },
  {
    label: 'Edensor Park, New South Wales',
    value: 10000587,
  },
  {
    label: 'Edge Hill, Queensland',
    value: 10005107,
  },
  {
    label: 'Edgecliff, New South Wales',
    value: 10000239,
  },
  {
    label: 'Edgeroi, New South Wales',
    value: 10001239,
  },
  {
    label: 'Edgewater, Western Australia',
    value: 10008459,
  },
  {
    label: 'Edgeworth, New South Wales',
    value: 10000871,
  },
  {
    label: 'Edillilie, South Australia',
    value: 10006007,
  },
  {
    label: 'Edinburgh, South Australia',
    value: 10005538,
  },
  {
    label: 'Edith, New South Wales',
    value: 10002421,
  },
  {
    label: 'Edith Creek, Tasmania',
    value: 10006657,
  },
  {
    label: 'Edithburgh, South Australia',
    value: 10005980,
  },
  {
    label: 'Edithvale, Victoria',
    value: 10007038,
  },
  {
    label: 'Edmondson Park, New South Wales',
    value: 10000580,
  },
  {
    label: 'Edmonton, Queensland',
    value: 10005095,
  },
  {
    label: 'Edward River, Queensland',
    value: 10005150,
  },
  {
    label: 'Edwardstown, South Australia',
    value: 10005338,
  },
  {
    label: 'Eganu, Western Australia',
    value: 10009699,
  },
  {
    label: 'Egg Lagoon, Tasmania',
    value: 10006432,
  },
  {
    label: 'Eggs And Bacon Bay, Tasmania',
    value: 10006197,
  },
  {
    label: 'Eglinton, New South Wales',
    value: 10002478,
  },
  {
    label: 'Eglinton, Western Australia',
    value: 10008478,
  },
  {
    label: 'Egypt, Queensland',
    value: 10003367,
  },
  {
    label: 'Eidsvold, Queensland',
    value: 10004184,
  },
  {
    label: 'Eidsvold East, Queensland',
    value: 10004185,
  },
  {
    label: 'Eidsvold West, Queensland',
    value: 10004189,
  },
  {
    label: 'Eight Mile Creek, Queensland',
    value: 10004834,
  },
  {
    label: 'Eight Mile Plains, Queensland',
    value: 10002971,
  },
  {
    label: 'Eighty Mile Beach, Western Australia',
    value: 10010025,
  },
  {
    label: 'Eildon, Victoria',
    value: 10007954,
  },
  {
    label: 'Eimeo, Queensland',
    value: 10004686,
  },
  {
    label: 'Einasleigh, Queensland',
    value: 10005146,
  },
  {
    label: 'El Arish, Queensland',
    value: 10005023,
  },
  {
    label: 'Elabbin, Western Australia',
    value: 10009649,
  },
  {
    label: 'Elachbutting, Western Australia',
    value: 10009623,
  },
  {
    label: 'Elaine, Victoria',
    value: 10007305,
  },
  {
    label: 'Elaman Creek, Queensland',
    value: 10003892,
  },
  {
    label: 'Elands, New South Wales',
    value: 10001327,
  },
  {
    label: 'Elanora, Queensland',
    value: 10003172,
  },
  {
    label: 'Elanora Heights, New South Wales',
    value: 10000394,
  },
  {
    label: 'Elderslie, New South Wales',
    value: 10001094,
  },
  {
    label: 'Elderslie, New South Wales',
    value: 10001933,
  },
  {
    label: 'Elderslie, Tasmania',
    value: 10006141,
  },
  {
    label: 'Eldorado, Victoria',
    value: 10008015,
  },
  {
    label: 'Electra, Queensland',
    value: 10004316,
  },
  {
    label: 'Electrona, Tasmania',
    value: 10006159,
  },
  {
    label: 'Eleebana, New South Wales',
    value: 10000845,
  },
  {
    label: 'Elermore Vale, New South Wales',
    value: 10000880,
  },
  {
    label: 'Elgin, Western Australia',
    value: 10008877,
  },
  {
    label: 'Eli Waters, Queensland',
    value: 10004249,
  },
  {
    label: 'Elimbah, Queensland',
    value: 10003847,
  },
  {
    label: 'Elizabeth, South Australia',
    value: 10005539,
  },
  {
    label: 'Elizabeth Bay, New South Wales',
    value: 10000208,
  },
  {
    label: 'Elizabeth Downs, South Australia',
    value: 10005548,
  },
  {
    label: 'Elizabeth East, South Australia',
    value: 10005540,
  },
  {
    label: 'Elizabeth Grove, South Australia',
    value: 10005542,
  },
  {
    label: 'Elizabeth North, South Australia',
    value: 10005546,
  },
  {
    label: 'Elizabeth Park, South Australia',
    value: 10005547,
  },
  {
    label: 'Elizabeth South, South Australia',
    value: 10005541,
  },
  {
    label: 'Elizabeth Town, Tasmania',
    value: 10006541,
  },
  {
    label: 'Elizabeth Vale, South Australia',
    value: 10005543,
  },
  {
    label: 'Elizabeth West, South Australia',
    value: 10005549,
  },
  {
    label: 'Ellalong, New South Wales',
    value: 10001032,
  },
  {
    label: 'Ellangowan, New South Wales',
    value: 10001521,
  },
  {
    label: 'Ellangowan, Queensland',
    value: 10003558,
  },
  {
    label: 'Elleker, Western Australia',
    value: 10009189,
  },
  {
    label: 'Ellen Grove, Queensland',
    value: 10002939,
  },
  {
    label: 'Ellenborough, New South Wales',
    value: 10001409,
  },
  {
    label: 'Ellenbrook, Western Australia',
    value: 10008572,
  },
  {
    label: 'Ellenbrook East, Western Australia',
    value: 10008576,
  },
  {
    label: 'Ellendale, Tasmania',
    value: 10006265,
  },
  {
    label: 'Ellendale, Western Australia',
    value: 10009816,
  },
  {
    label: 'Ellerslie, Victoria',
    value: 10007189,
  },
  {
    label: 'Ellerston, New South Wales',
    value: 10001103,
  },
  {
    label: 'Elliminyt, Victoria',
    value: 10007171,
  },
  {
    label: 'Elliott, Northern Territory',
    value: 10002777,
  },
  {
    label: 'Elliott, Queensland',
    value: 10004303,
  },
  {
    label: 'Elliott, Tasmania',
    value: 10006649,
  },
  {
    label: 'Elliott Heads, Queensland',
    value: 10004325,
  },
  {
    label: 'Ellis Beach, Queensland',
    value: 10005201,
  },
  {
    label: 'Ellis Lane, New South Wales',
    value: 10001924,
  },
  {
    label: 'Elliston, South Australia',
    value: 10006025,
  },
  {
    label: 'Elmhurst, Victoria',
    value: 10007582,
  },
  {
    label: 'Elmore, Victoria',
    value: 10007715,
  },
  {
    label: 'Elong Elong, New South Wales',
    value: 10002563,
  },
  {
    label: 'Elphinstone, Queensland',
    value: 10003552,
  },
  {
    label: 'Elphinstone, Queensland',
    value: 10004732,
  },
  {
    label: 'Elphinstone, Victoria',
    value: 10007534,
  },
  {
    label: 'Elrington, New South Wales',
    value: 10001030,
  },
  {
    label: 'Elsmore, New South Wales',
    value: 10001189,
  },
  {
    label: 'Elsternwick, Victoria',
    value: 10007007,
  },
  {
    label: 'Eltham, New South Wales',
    value: 10001592,
  },
  {
    label: 'Eltham, Victoria',
    value: 10006838,
  },
  {
    label: 'Eltham North, Victoria',
    value: 10006840,
  },
  {
    label: 'Elvina Bay, New South Wales',
    value: 10000404,
  },
  {
    label: 'Elwick, Tasmania',
    value: 10006085,
  },
  {
    label: 'Elwood, Victoria',
    value: 10007005,
  },
  {
    label: 'Embleton, Western Australia',
    value: 10008544,
  },
  {
    label: 'Emerald, Queensland',
    value: 10004626,
  },
  {
    label: 'Emerald, Victoria',
    value: 10008062,
  },
  {
    label: 'Emerald Beach, New South Wales',
    value: 10001462,
  },
  {
    label: 'Emerton, New South Wales',
    value: 10002374,
  },
  {
    label: 'Emita, Tasmania',
    value: 10006426,
  },
  {
    label: 'Emmaville, New South Wales',
    value: 10001210,
  },
  {
    label: 'Empire Bay, New South Wales',
    value: 10000758,
  },
  {
    label: 'Empire Vale, New South Wales',
    value: 10001556,
  },
  {
    label: 'Emu, Victoria',
    value: 10007590,
  },
  {
    label: 'Emu Flat, Western Australia',
    value: 10009504,
  },
  {
    label: 'Emu Park, Queensland',
    value: 10004577,
  },
  {
    label: 'Emu Plains, New South Wales',
    value: 10002312,
  },
  {
    label: 'Emu Point, Western Australia',
    value: 10009183,
  },
  {
    label: 'Emu Vale, Queensland',
    value: 10003571,
  },
  {
    label: 'Encounter Bay, South Australia',
    value: 10005666,
  },
  {
    label: 'Endeavour Hills, Victoria',
    value: 10008085,
  },
  {
    label: 'Eneabba, Western Australia',
    value: 10009707,
  },
  {
    label: 'Enfield, New South Wales',
    value: 10000468,
  },
  {
    label: 'Enfield, South Australia',
    value: 10005486,
  },
  {
    label: 'Enfield, Victoria',
    value: 10007362,
  },
  {
    label: 'Enfield Plaza, South Australia',
    value: 10005487,
  },
  {
    label: 'Enfield South, New South Wales',
    value: 10000463,
  },
  {
    label: 'Engadine, New South Wales',
    value: 10000701,
  },
  {
    label: 'Englorie Park, New South Wales',
    value: 10001902,
  },
  {
    label: 'Enmore, New South Wales',
    value: 10000274,
  },
  {
    label: 'Enmore, New South Wales',
    value: 10001155,
  },
  {
    label: 'Enmore, New South Wales',
    value: 10001176,
  },
  {
    label: 'Enngonia, New South Wales',
    value: 10002581,
  },
  {
    label: 'Ennuin, Western Australia',
    value: 10009627,
  },
  {
    label: 'Enoggera, Queensland',
    value: 10002875,
  },
  {
    label: 'Enoggera Reservoir, Queensland',
    value: 10003854,
  },
  {
    label: 'Ensay, Victoria',
    value: 10008239,
  },
  {
    label: 'Eppalock, Victoria',
    value: 10007692,
  },
  {
    label: 'Epping, New South Wales',
    value: 10000173,
  },
  {
    label: 'Epping, New South Wales',
    value: 10000448,
  },
  {
    label: 'Epping, Victoria',
    value: 10006811,
  },
  {
    label: 'Epping Dc, Victoria',
    value: 10006810,
  },
  {
    label: 'Epping Forest, Tasmania',
    value: 10006340,
  },
  {
    label: 'Epsom, Queensland',
    value: 10004719,
  },
  {
    label: 'Epsom, Victoria',
    value: 10007699,
  },
  {
    label: 'Eradu, Western Australia',
    value: 10009786,
  },
  {
    label: 'Eradu South, Western Australia',
    value: 10009768,
  },
  {
    label: 'Erakala, Queensland',
    value: 10004703,
  },
  {
    label: 'Eraring, New South Wales',
    value: 10000817,
  },
  {
    label: 'Erica, Victoria',
    value: 10008132,
  },
  {
    label: 'Erigolia, New South Wales',
    value: 10002201,
  },
  {
    label: 'Erina, New South Wales',
    value: 10000733,
  },
  {
    label: 'Erina Fair, New South Wales',
    value: 10000714,
  },
  {
    label: 'Erina Heights, New South Wales',
    value: 10000788,
  },
  {
    label: 'Erindale, South Australia',
    value: 10005416,
  },
  {
    label: 'Erindale Centre, Australian Capital Territory',
    value: 10000103,
  },
  {
    label: 'Ermington, New South Wales',
    value: 10000171,
  },
  {
    label: 'Ermington, New South Wales',
    value: 10000435,
  },
  {
    label: 'Ernabella, South Australia',
    value: 10005231,
  },
  {
    label: 'Ernestina, Queensland',
    value: 10004641,
  },
  {
    label: 'Eromanga, Queensland',
    value: 10003763,
  },
  {
    label: 'Erowal Bay, New South Wales',
    value: 10001800,
  },
  {
    label: 'Erriba, Tasmania',
    value: 10006571,
  },
  {
    label: 'Erskine, Western Australia',
    value: 10008778,
  },
  {
    label: 'Erskine Park, New South Wales',
    value: 10002346,
  },
  {
    label: 'Erskineville, New South Wales',
    value: 10000276,
  },
  {
    label: 'Erub Island, New South Wales',
    value: 10010202,
  },
  {
    label: 'Eschol Park, New South Wales',
    value: 10001883,
  },
  {
    label: 'Esk, Queensland',
    value: 10003307,
  },
  {
    label: 'Eskdale, Victoria',
    value: 10007935,
  },
  {
    label: 'Esperance, Western Australia',
    value: 10009556,
  },
  {
    label: 'Essendon, Victoria',
    value: 10006745,
  },
  {
    label: 'Essendon North, Victoria',
    value: 10006747,
  },
  {
    label: 'Essendon West, Victoria',
    value: 10006744,
  },
  {
    label: 'Ethelton, South Australia',
    value: 10005284,
  },
  {
    label: 'Etmilyn, Western Australia',
    value: 10008799,
  },
  {
    label: 'Etna Creek, Queensland',
    value: 10004494,
  },
  {
    label: 'Eton, Queensland',
    value: 10004727,
  },
  {
    label: 'Eton North, Queensland',
    value: 10004722,
  },
  {
    label: 'Ettalong Beach, New South Wales',
    value: 10000755,
  },
  {
    label: 'Ettrick, New South Wales',
    value: 10001536,
  },
  {
    label: 'Etty Bay, Queensland',
    value: 10005042,
  },
  {
    label: 'Euabalong, New South Wales',
    value: 10002659,
  },
  {
    label: 'Eubenangee, Queensland',
    value: 10005080,
  },
  {
    label: 'Euchareena, New South Wales',
    value: 10002628,
  },
  {
    label: 'Eucla, Western Australia',
    value: 10009538,
  },
  {
    label: 'Eucumbene, New South Wales',
    value: 10002064,
  },
  {
    label: 'Eudlo, Queensland',
    value: 10003902,
  },
  {
    label: 'Eudunda, South Australia',
    value: 10005844,
  },
  {
    label: 'Eugenana, Tasmania',
    value: 10006573,
  },
  {
    label: 'Eugowra, New South Wales',
    value: 10002511,
  },
  {
    label: 'Euleilah, Queensland',
    value: 10004379,
  },
  {
    label: 'Eulo, Queensland',
    value: 10003784,
  },
  {
    label: 'Eumamurrin, Queensland',
    value: 10003725,
  },
  {
    label: 'Eumundi, Queensland',
    value: 10003926,
  },
  {
    label: 'Eumungerie, New South Wales',
    value: 10002566,
  },
  {
    label: 'Eungai Creek, New South Wales',
    value: 10001382,
  },
  {
    label: 'Eungai Rail, New South Wales',
    value: 10001375,
  },
  {
    label: 'Eungella, New South Wales',
    value: 10001612,
  },
  {
    label: 'Eungella, Queensland',
    value: 10004767,
  },
  {
    label: 'Eungella Dam, Queensland',
    value: 10004768,
  },
  {
    label: 'Eungella Hinterland, Queensland',
    value: 10004725,
  },
  {
    label: 'Euramo, Queensland',
    value: 10005021,
  },
  {
    label: 'Eurardy, Western Australia',
    value: 10009818,
  },
  {
    label: 'Eureka, New South Wales',
    value: 10001581,
  },
  {
    label: 'Eureka, Queensland',
    value: 10004285,
  },
  {
    label: 'Eureka, Victoria',
    value: 10007328,
  },
  {
    label: 'Eurimbula, Queensland',
    value: 10004391,
  },
  {
    label: 'Euroa, Victoria',
    value: 10007854,
  },
  {
    label: 'Eurobin, Victoria',
    value: 10008006,
  },
  {
    label: 'Eurong, Queensland',
    value: 10004024,
  },
  {
    label: 'Euston, New South Wales',
    value: 10002290,
  },
  {
    label: 'Euthulla, Queensland',
    value: 10003737,
  },
  {
    label: 'Evandale, South Australia',
    value: 10005436,
  },
  {
    label: 'Evandale, Tasmania',
    value: 10006347,
  },
  {
    label: 'Evans Head, New South Wales',
    value: 10001529,
  },
  {
    label: 'Evans Landing, Queensland',
    value: 10005184,
  },
  {
    label: 'Evansford, Victoria',
    value: 10007429,
  },
  {
    label: 'Evanslea, Queensland',
    value: 10003505,
  },
  {
    label: 'Evanston, South Australia',
    value: 10005560,
  },
  {
    label: 'Evatt, Australian Capital Territory',
    value: 10000094,
  },
  {
    label: 'Eveleigh, New South Wales',
    value: 10000216,
  },
  {
    label: 'Evelyn, Queensland',
    value: 10005225,
  },
  {
    label: 'Everard Park, South Australia',
    value: 10005326,
  },
  {
    label: 'Evergreen, Queensland',
    value: 10003473,
  },
  {
    label: 'Everton, Victoria',
    value: 10007886,
  },
  {
    label: 'Everton Hills, Queensland',
    value: 10002882,
  },
  {
    label: 'Everton Park, Queensland',
    value: 10002879,
  },
  {
    label: 'Ewlyamartup, Western Australia',
    value: 10009136,
  },
  {
    label: 'Exeter, New South Wales',
    value: 10001971,
  },
  {
    label: 'Exeter, South Australia',
    value: 10005293,
  },
  {
    label: 'Exeter, Tasmania',
    value: 10006487,
  },
  {
    label: 'Exford, Victoria',
    value: 10007312,
  },
  {
    label: 'Exmouth, Western Australia',
    value: 10009961,
  },
  {
    label: 'Exmouth Gulf, Western Australia',
    value: 10009960,
  },
  {
    label: 'Export Park, South Australia',
    value: 10006062,
  },
  {
    label: 'Exton, Tasmania',
    value: 10006520,
  },
  {
    label: 'Fadden, Australian Capital Territory',
    value: 10000108,
  },
  {
    label: 'Failford, New South Wales',
    value: 10001348,
  },
  {
    label: 'Fairbridge, Western Australia',
    value: 10008765,
  },
  {
    label: 'Fairdale, Queensland',
    value: 10004057,
  },
  {
    label: 'Fairfield, New South Wales',
    value: 10000186,
  },
  {
    label: 'Fairfield, New South Wales',
    value: 10000544,
  },
  {
    label: 'Fairfield, Queensland',
    value: 10002948,
  },
  {
    label: 'Fairfield, Victoria',
    value: 10006813,
  },
  {
    label: 'Fairfield East, New South Wales',
    value: 10000543,
  },
  {
    label: 'Fairfield Heights, New South Wales',
    value: 10000546,
  },
  {
    label: 'Fairfield West, New South Wales',
    value: 10000545,
  },
  {
    label: 'Fairhaven, Victoria',
    value: 10007124,
  },
  {
    label: 'Fairlight, New South Wales',
    value: 10000374,
  },
  {
    label: 'Fairney View, Queensland',
    value: 10003281,
  },
  {
    label: 'Fairview Park, South Australia',
    value: 10005577,
  },
  {
    label: 'Fairy Bower, Queensland',
    value: 10004443,
  },
  {
    label: 'Fairy Hill, New South Wales',
    value: 10001522,
  },
  {
    label: 'Fairy Meadow, New South Wales',
    value: 10001678,
  },
  {
    label: 'Falcon, Western Australia',
    value: 10008786,
  },
  {
    label: 'Falls Creek, New South Wales',
    value: 10001820,
  },
  {
    label: 'Falls Creek, Victoria',
    value: 10007931,
  },
  {
    label: 'Falmouth, Tasmania',
    value: 10006365,
  },
  {
    label: 'Fannie Bay, Northern Territory',
    value: 10002712,
  },
  {
    label: 'Farleigh, Queensland',
    value: 10004708,
  },
  {
    label: 'Farley, New South Wales',
    value: 10000976,
  },
  {
    label: 'Farmborough Heights, New South Wales',
    value: 10001687,
  },
  {
    label: 'Farnham, New South Wales',
    value: 10002524,
  },
  {
    label: 'Farrar, Northern Territory',
    value: 10002741,
  },
  {
    label: 'Farrell Flat, South Australia',
    value: 10005859,
  },
  {
    label: 'Farrer, Australian Capital Territory',
    value: 10000040,
  },
  {
    label: 'Fassifern, New South Wales',
    value: 10000852,
  },
  {
    label: 'Faulconbridge, New South Wales',
    value: 10002397,
  },
  {
    label: 'Fawkner, Victoria',
    value: 10006785,
  },
  {
    label: 'Federal, New South Wales',
    value: 10001587,
  },
  {
    label: 'Federal, Queensland',
    value: 10003946,
  },
  {
    label: 'Felixstow, South Australia',
    value: 10005443,
  },
  {
    label: 'Felton, Queensland',
    value: 10003542,
  },
  {
    label: 'Felton South, Queensland',
    value: 10003540,
  },
  {
    label: 'Fennell Bay, New South Wales',
    value: 10000860,
  },
  {
    label: 'Fentonbury, Tasmania',
    value: 10006227,
  },
  {
    label: 'Ferguson, Western Australia',
    value: 10008872,
  },
  {
    label: 'Fern Bay, New South Wales',
    value: 10000906,
  },
  {
    label: 'Fern Hill, Victoria',
    value: 10007549,
  },
  {
    label: 'Fern Tree, Tasmania',
    value: 10006160,
  },
  {
    label: 'Fernbank, Victoria',
    value: 10008186,
  },
  {
    label: 'Ferndale, Western Australia',
    value: 10008664,
  },
  {
    label: 'Ferney, Queensland',
    value: 10004233,
  },
  {
    label: 'Fernihurst, Victoria',
    value: 10007642,
  },
  {
    label: 'Fernleigh, New South Wales',
    value: 10001562,
  },
  {
    label: 'Fernmount, New South Wales',
    value: 10001456,
  },
  {
    label: 'Ferntree Gully, Victoria',
    value: 10006955,
  },
  {
    label: 'Fernvale, Queensland',
    value: 10003274,
  },
  {
    label: 'Ferny Creek, Victoria',
    value: 10008065,
  },
  {
    label: 'Ferny Grove, Queensland',
    value: 10002886,
  },
  {
    label: 'Ferny Hills, Queensland',
    value: 10002888,
  },
  {
    label: 'Ferryden Park, South Australia',
    value: 10005258,
  },
  {
    label: 'Feysville, Western Australia',
    value: 10009500,
  },
  {
    label: 'Fiddletown, New South Wales',
    value: 10000529,
  },
  {
    label: 'Fifield, New South Wales',
    value: 10002650,
  },
  {
    label: 'Fifteen Mile, Queensland',
    value: 10003438,
  },
  {
    label: 'Fig Tree Pocket, Queensland',
    value: 10002913,
  },
  {
    label: 'Figtree, New South Wales',
    value: 10001683,
  },
  {
    label: 'Fimiston, Western Australia',
    value: 10009511,
  },
  {
    label: 'Finch Hatton, Queensland',
    value: 10004763,
  },
  {
    label: 'Findon, South Australia',
    value: 10005300,
  },
  {
    label: 'Fingal, Tasmania',
    value: 10006354,
  },
  {
    label: 'Fingal, Victoria',
    value: 10008286,
  },
  {
    label: 'Fingal Bay, New South Wales',
    value: 10000950,
  },
  {
    label: 'Fingal Head, New South Wales',
    value: 10001632,
  },
  {
    label: 'Finke, Northern Territory',
    value: 10002792,
  },
  {
    label: 'Finley, New South Wales',
    value: 10002253,
  },
  {
    label: 'Finnie, Queensland',
    value: 10003401,
  },
  {
    label: 'Finniss, South Australia',
    value: 10005712,
  },
  {
    label: 'Finucane, Western Australia',
    value: 10010014,
  },
  {
    label: 'Firefly, New South Wales',
    value: 10001319,
  },
  {
    label: 'Firle, South Australia',
    value: 10005441,
  },
  {
    label: 'Fish Creek, Victoria',
    value: 10008321,
  },
  {
    label: 'Fisher, Australian Capital Territory',
    value: 10000050,
  },
  {
    label: 'Fisher, Queensland',
    value: 10004968,
  },
  {
    label: 'Fishermans Pocket, Queensland',
    value: 10003957,
  },
  {
    label: 'Fishery Falls, Queensland',
    value: 10005138,
  },
  {
    label: 'Fishing Point, New South Wales',
    value: 10000853,
  },
  {
    label: 'Fitzgerald, Tasmania',
    value: 10006253,
  },
  {
    label: 'Fitzgerald, Western Australia',
    value: 10009255,
  },
  {
    label: 'Fitzgerald Creek, Queensland',
    value: 10005068,
  },
  {
    label: 'Fitzgibbon, Queensland',
    value: 10002838,
  },
  {
    label: 'Fitzroy, South Australia',
    value: 10005476,
  },
  {
    label: 'Fitzroy, Victoria',
    value: 10006795,
  },
  {
    label: 'Fitzroy Crossing, Western Australia',
    value: 10010057,
  },
  {
    label: 'Fitzroy Falls, New South Wales',
    value: 10001966,
  },
  {
    label: 'Fitzroy North, Victoria',
    value: 10006799,
  },
  {
    label: 'Five Dock, New South Wales',
    value: 10000286,
  },
  {
    label: 'Five Ways, Victoria',
    value: 10008347,
  },
  {
    label: 'Flagstaff Hill, South Australia',
    value: 10005625,
  },
  {
    label: 'Flagstone Creek, Queensland',
    value: 10003370,
  },
  {
    label: 'Flametree, Queensland',
    value: 10004810,
  },
  {
    label: 'Flaxley, South Australia',
    value: 10005601,
  },
  {
    label: 'Flemington, Victoria',
    value: 10006729,
  },
  {
    label: 'Fletcher, New South Wales',
    value: 10000874,
  },
  {
    label: 'Fletcher Creek, Queensland',
    value: 10004587,
  },
  {
    label: 'Flinders, New South Wales',
    value: 10001703,
  },
  {
    label: 'Flinders, Victoria',
    value: 10008274,
  },
  {
    label: 'Flinders Lane, Victoria',
    value: 10008396,
  },
  {
    label: 'Flinders Park, South Australia',
    value: 10005306,
  },
  {
    label: 'Flinders University, South Australia',
    value: 10005349,
  },
  {
    label: 'Flinders View, Queensland',
    value: 10003247,
  },
  {
    label: 'Flint, Western Australia',
    value: 10009042,
  },
  {
    label: 'Floraville, New South Wales',
    value: 10000837,
  },
  {
    label: 'Floreat, Western Australia',
    value: 10008422,
  },
  {
    label: 'Florey, Australian Capital Territory',
    value: 10000085,
  },
  {
    label: 'Flowerdale, Tasmania',
    value: 10006647,
  },
  {
    label: 'Flowerdale, Victoria',
    value: 10007967,
  },
  {
    label: 'Flowerpot, Tasmania',
    value: 10006292,
  },
  {
    label: 'Flowery Gully, Tasmania',
    value: 10006480,
  },
  {
    label: 'Flying Fish Point, Queensland',
    value: 10005050,
  },
  {
    label: 'Flynn, Australian Capital Territory',
    value: 10000075,
  },
  {
    label: 'Flynn, Victoria',
    value: 10008151,
  },
  {
    label: 'Flynn, Western Australia',
    value: 10009056,
  },
  {
    label: 'Flynns Beach, New South Wales',
    value: 10001396,
  },
  {
    label: 'Footscray, Victoria',
    value: 10006687,
  },
  {
    label: 'Forbes, New South Wales',
    value: 10002644,
  },
  {
    label: 'Forcett, Tasmania',
    value: 10006309,
  },
  {
    label: 'Fords Bridge, New South Wales',
    value: 10002582,
  },
  {
    label: 'Fordsdale, Queensland',
    value: 10003362,
  },
  {
    label: 'Foreshores, Queensland',
    value: 10004394,
  },
  {
    label: 'Forest, Tasmania',
    value: 10006667,
  },
  {
    label: 'Forest Grove, Western Australia',
    value: 10009024,
  },
  {
    label: 'Forest Hill, New South Wales',
    value: 10002145,
  },
  {
    label: 'Forest Hill, Queensland',
    value: 10003339,
  },
  {
    label: 'Forest Hill, Victoria',
    value: 10006895,
  },
  {
    label: 'Forest Hill, Western Australia',
    value: 10009160,
  },
  {
    label: 'Forest Lake, Queensland',
    value: 10002940,
  },
  {
    label: 'Forest Range, South Australia',
    value: 10005590,
  },
  {
    label: 'Forest Reefs, New South Wales',
    value: 10002488,
  },
  {
    label: 'Forest Ridge, Queensland',
    value: 10003513,
  },
  {
    label: 'Forestdale, Queensland',
    value: 10002988,
  },
  {
    label: 'Forestville, New South Wales',
    value: 10000362,
  },
  {
    label: 'Forestville, South Australia',
    value: 10005327,
  },
  {
    label: 'Formartin, Queensland',
    value: 10003639,
  },
  {
    label: 'Forrest, Australian Capital Territory',
    value: 10000027,
  },
  {
    label: 'Forrest, Victoria',
    value: 10007131,
  },
  {
    label: 'Forrest, Western Australia',
    value: 10009517,
  },
  {
    label: 'Forrest Beach, Western Australia',
    value: 10008955,
  },
  {
    label: 'Forrestania, Western Australia',
    value: 10009306,
  },
  {
    label: 'Forrestdale, Western Australia',
    value: 10008639,
  },
  {
    label: 'Forresters Beach, New South Wales',
    value: 10000786,
  },
  {
    label: 'Forrestfield, Western Australia',
    value: 10008533,
  },
  {
    label: 'Forsayth, Queensland',
    value: 10005123,
  },
  {
    label: 'Forster, New South Wales',
    value: 10001314,
  },
  {
    label: 'Forster Shopping Village, New South Wales',
    value: 10001313,
  },
  {
    label: 'Fortescue, Western Australia',
    value: 10009993,
  },
  {
    label: 'Forth, Tasmania',
    value: 10006583,
  },
  {
    label: 'Fortitude Valley, Queensland',
    value: 10002816,
  },
  {
    label: 'Foster, Victoria',
    value: 10008325,
  },
  {
    label: 'Fosterville, Victoria',
    value: 10007713,
  },
  {
    label: 'Foulden, Queensland',
    value: 10004671,
  },
  {
    label: 'Fountain Gate, Victoria',
    value: 10008089,
  },
  {
    label: 'Four Mile Creek, Tasmania',
    value: 10006362,
  },
  {
    label: 'Foxdale, Queensland',
    value: 10004774,
  },
  {
    label: 'Foxground, New South Wales',
    value: 10001717,
  },
  {
    label: 'Framlingham, Victoria',
    value: 10007183,
  },
  {
    label: 'Frances, South Australia',
    value: 10005728,
  },
  {
    label: 'Frankford, Tasmania',
    value: 10006494,
  },
  {
    label: 'Frankland, Western Australia',
    value: 10009390,
  },
  {
    label: 'Franklin, Tasmania',
    value: 10006198,
  },
  {
    label: 'Franklinford, Victoria',
    value: 10007560,
  },
  {
    label: 'Frankston, Victoria',
    value: 10007045,
  },
  {
    label: 'Frankston North, Victoria',
    value: 10007046,
  },
  {
    label: 'Fraser, Australian Capital Territory',
    value: 10000084,
  },
  {
    label: 'Fraser Range, Western Australia',
    value: 10009542,
  },
  {
    label: 'Frazerview, Queensland',
    value: 10003290,
  },
  {
    label: 'Fredericksfield, Queensland',
    value: 10004823,
  },
  {
    label: 'Frederickton, New South Wales',
    value: 10001363,
  },
  {
    label: 'Freeburgh, Victoria',
    value: 10008010,
  },
  {
    label: 'Freeling, South Australia',
    value: 10005841,
  },
  {
    label: 'Freemans Reach, New South Wales',
    value: 10002326,
  },
  {
    label: 'Freemans Waterhole, New South Wales',
    value: 10001000,
  },
  {
    label: 'Freemantle, New South Wales',
    value: 10002453,
  },
  {
    label: 'Fregon, South Australia',
    value: 10005232,
  },
  {
    label: 'Fremantle, Western Australia',
    value: 10008697,
  },
  {
    label: 'Fremantle, Western Australia',
    value: 10010136,
  },
  {
    label: 'French Park, New South Wales',
    value: 10002166,
  },
  {
    label: 'Frenchman Bay, Western Australia',
    value: 10009188,
  },
  {
    label: 'Frenchs Forest, New South Wales',
    value: 10000163,
  },
  {
    label: 'Frenchs Forest, New South Wales',
    value: 10000164,
  },
  {
    label: 'Frenchs Forest, New South Wales',
    value: 10000361,
  },
  {
    label: 'Frenchville, Queensland',
    value: 10004451,
  },
  {
    label: 'Freshwater, Queensland',
    value: 10005099,
  },
  {
    label: 'Freshwater Creek, Victoria',
    value: 10007070,
  },
  {
    label: 'Freshwater Point, Queensland',
    value: 10004659,
  },
  {
    label: 'Frewville, South Australia',
    value: 10005403,
  },
  {
    label: 'Friday Pocket, Queensland',
    value: 10005029,
  },
  {
    label: 'Fryerstown, Victoria',
    value: 10007542,
  },
  {
    label: 'Fulham, Queensland',
    value: 10003318,
  },
  {
    label: 'Fulham, South Australia',
    value: 10005304,
  },
  {
    label: 'Fulham Gardens, South Australia',
    value: 10005302,
  },
  {
    label: 'Fullarton, South Australia',
    value: 10005400,
  },
  {
    label: 'Fullerton, New South Wales',
    value: 10002005,
  },
  {
    label: 'Fullerton Cove, New South Wales',
    value: 10000959,
  },
  {
    label: 'Furnissdale, Western Australia',
    value: 10008780,
  },
  {
    label: 'Furracabad, New South Wales',
    value: 10001206,
  },
  {
    label: 'Fyansford, Victoria',
    value: 10007102,
  },
  {
    label: 'Fyshwick, Australian Capital Territory',
    value: 10000047,
  },
  {
    label: 'Gabalong, Western Australia',
    value: 10009864,
  },
  {
    label: 'Gabbadah, Western Australia',
    value: 10008484,
  },
  {
    label: 'Gabbin, Western Australia',
    value: 10009614,
  },
  {
    label: 'Gaeta, Queensland',
    value: 10004349,
  },
  {
    label: 'Gagebrook, Tasmania',
    value: 10006126,
  },
  {
    label: 'Gailes, Queensland',
    value: 10003208,
  },
  {
    label: 'Gainsford, Queensland',
    value: 10004491,
  },
  {
    label: 'Gairdner, Western Australia',
    value: 10009254,
  },
  {
    label: 'Gairloch, Queensland',
    value: 10005008,
  },
  {
    label: 'Galiwinku, Northern Territory',
    value: 10002728,
  },
  {
    label: 'Galong, New South Wales',
    value: 10002013,
  },
  {
    label: 'Galore, New South Wales',
    value: 10002159,
  },
  {
    label: 'Galston, New South Wales',
    value: 10000526,
  },
  {
    label: 'Ganmain, New South Wales',
    value: 10002226,
  },
  {
    label: 'Gap Ridge, Western Australia',
    value: 10009989,
  },
  {
    label: 'Gapsted, Victoria',
    value: 10008001,
  },
  {
    label: 'Garah, New South Wales',
    value: 10001259,
  },
  {
    label: 'Garbutt, Queensland',
    value: 10004894,
  },
  {
    label: 'Garden City, Victoria',
    value: 10007058,
  },
  {
    label: 'Garden Island, Western Australia',
    value: 10008744,
  },
  {
    label: 'Garden Island Creek, Tasmania',
    value: 10006190,
  },
  {
    label: 'Garden Suburb, New South Wales',
    value: 10000888,
  },
  {
    label: 'Gardenvale, Victoria',
    value: 10007009,
  },
  {
    label: 'Gardners Bay, Tasmania',
    value: 10006192,
  },
  {
    label: 'Garema, New South Wales',
    value: 10002643,
  },
  {
    label: 'Garfield, Victoria',
    value: 10008104,
  },
  {
    label: 'Gargett, Queensland',
    value: 10004730,
  },
  {
    label: 'Garland, New South Wales',
    value: 10002486,
  },
  {
    label: 'Garnant, Queensland',
    value: 10004473,
  },
  {
    label: 'Garners Beach, Queensland',
    value: 10005014,
  },
  {
    label: 'Garra, New South Wales',
    value: 10002629,
  },
  {
    label: 'Garradunga, Queensland',
    value: 10005053,
  },
  {
    label: 'Garran, Australian Capital Territory',
    value: 10000032,
  },
  {
    label: 'Garthowen, New South Wales',
    value: 10001143,
  },
  {
    label: 'Garvoc, Victoria',
    value: 10007185,
  },
  {
    label: 'Gascoyne Junction, Western Australia',
    value: 10009955,
  },
  {
    label: 'Gascoyne River, Western Australia',
    value: 10009957,
  },
  {
    label: 'Gateshead, New South Wales',
    value: 10000890,
  },
  {
    label: 'Gatton, Queensland',
    value: 10003355,
  },
  {
    label: 'Gatton College, Queensland',
    value: 10003377,
  },
  {
    label: 'Gatum, Victoria',
    value: 10007478,
  },
  {
    label: 'Gaven, Queensland',
    value: 10003122,
  },
  {
    label: 'Gawler, South Australia',
    value: 10005563,
  },
  {
    label: 'Gawler, Tasmania',
    value: 10006590,
  },
  {
    label: 'Gawler Belt, South Australia',
    value: 10005568,
  },
  {
    label: 'Gayndah, Queensland',
    value: 10004153,
  },
  {
    label: 'Gaythorne, Queensland',
    value: 10002873,
  },
  {
    label: 'Geebung, Queensland',
    value: 10002862,
  },
  {
    label: 'Geelong, Victoria',
    value: 10007086,
  },
  {
    label: 'Geelong West, Victoria',
    value: 10007077,
  },
  {
    label: 'Geeveston, Tasmania',
    value: 10006200,
  },
  {
    label: 'Geham, Queensland',
    value: 10003481,
  },
  {
    label: 'Geilston Bay, Tasmania',
    value: 10006097,
  },
  {
    label: 'Gelantipy, Victoria',
    value: 10008212,
  },
  {
    label: 'Gellibrand, Victoria',
    value: 10007140,
  },
  {
    label: 'Gellibrand Lower, Victoria',
    value: 10007133,
  },
  {
    label: 'Gelliondale, Victoria',
    value: 10008337,
  },
  {
    label: 'Gelorup, Western Australia',
    value: 10008855,
  },
  {
    label: 'Gemalla, New South Wales',
    value: 10002470,
  },
  {
    label: 'Gembrook, Victoria',
    value: 10008064,
  },
  {
    label: 'Geneva, New South Wales',
    value: 10001532,
  },
  {
    label: 'Genoa, Victoria',
    value: 10008236,
  },
  {
    label: 'Geographe, Western Australia',
    value: 10008989,
  },
  {
    label: 'George Street, Queensland',
    value: 10002810,
  },
  {
    label: 'George Town, Tasmania',
    value: 10006413,
  },
  {
    label: 'Georges Hall, New South Wales',
    value: 10000610,
  },
  {
    label: 'Georges Plains, New South Wales',
    value: 10002473,
  },
  {
    label: 'Georgetown, New South Wales',
    value: 10000910,
  },
  {
    label: 'Georgetown, Queensland',
    value: 10005137,
  },
  {
    label: 'Georgetown, South Australia',
    value: 10005892,
  },
  {
    label: 'Georgica, New South Wales',
    value: 10001578,
  },
  {
    label: 'Georgina, Queensland',
    value: 10004984,
  },
  {
    label: 'Georgina, Western Australia',
    value: 10009815,
  },
  {
    label: 'Gepps Cross, South Australia',
    value: 10005506,
  },
  {
    label: 'Geraldton, Western Australia',
    value: 10009746,
  },
  {
    label: 'Geraldton, Western Australia',
    value: 10009765,
  },
  {
    label: 'Gerang Gerung, Victoria',
    value: 10007496,
  },
  {
    label: 'Gerangamete, Victoria',
    value: 10007151,
  },
  {
    label: 'Geranium, South Australia',
    value: 10005778,
  },
  {
    label: 'Germantown, Queensland',
    value: 10005147,
  },
  {
    label: 'Gerogery, New South Wales',
    value: 10002106,
  },
  {
    label: 'Gerringong, New South Wales',
    value: 10001719,
  },
  {
    label: 'Gerroa, New South Wales',
    value: 10001716,
  },
  {
    label: 'Geurie, New South Wales',
    value: 10002567,
  },
  {
    label: 'Gheringhap, Victoria',
    value: 10007298,
  },
  {
    label: 'Ghinghinda, Queensland',
    value: 10003691,
  },
  {
    label: 'Ghinni Ghinni, New South Wales',
    value: 10001342,
  },
  {
    label: 'Ghooli, Western Australia',
    value: 10009472,
  },
  {
    label: 'Gibb, Western Australia',
    value: 10010036,
  },
  {
    label: 'Gibson, Western Australia',
    value: 10009554,
  },
  {
    label: 'Gidgegannup, Western Australia',
    value: 10008597,
  },
  {
    label: 'Gigoomgan, Queensland',
    value: 10004128,
  },
  {
    label: 'Gilberton, Queensland',
    value: 10003104,
  },
  {
    label: 'Gilberton, South Australia',
    value: 10005470,
  },
  {
    label: 'Gilderoy, Victoria',
    value: 10008077,
  },
  {
    label: 'Gilead, New South Wales',
    value: 10001900,
  },
  {
    label: 'Gilgai, New South Wales',
    value: 10001186,
  },
  {
    label: 'Gilgandra, New South Wales',
    value: 10002540,
  },
  {
    label: 'Gilgering, Western Australia',
    value: 10009049,
  },
  {
    label: 'Gilldora, Queensland',
    value: 10003964,
  },
  {
    label: 'Gilles Plains, South Australia',
    value: 10005488,
  },
  {
    label: 'Gillieston, Victoria',
    value: 10007779,
  },
  {
    label: 'Gillieston Heights, New South Wales',
    value: 10000977,
  },
  {
    label: 'Gillimanning, Western Australia',
    value: 10009082,
  },
  {
    label: 'Gillingarra, Western Australia',
    value: 10009690,
  },
  {
    label: 'Gillman, South Australia',
    value: 10005272,
  },
  {
    label: 'Gilmore, Australian Capital Territory',
    value: 10000112,
  },
  {
    label: 'Gilmore, New South Wales',
    value: 10002263,
  },
  {
    label: 'Gilston, Queensland',
    value: 10003121,
  },
  {
    label: 'Gin Gin, Queensland',
    value: 10004361,
  },
  {
    label: 'Gindoran, Queensland',
    value: 10004383,
  },
  {
    label: 'Gingerah, Western Australia',
    value: 10010017,
  },
  {
    label: 'Gingin, Western Australia',
    value: 10009655,
  },
  {
    label: 'Ginginup, Western Australia',
    value: 10009669,
  },
  {
    label: 'Gingkin, New South Wales',
    value: 10002425,
  },
  {
    label: 'Ginninderra Village, Australian Capital Territory',
    value: 10000122,
  },
  {
    label: 'Ginoondan, Queensland',
    value: 10004165,
  },
  {
    label: 'Gippsland Mc, Victoria',
    value: 10008145,
  },
  {
    label: 'Gipsy Point, Victoria',
    value: 10008235,
  },
  {
    label: 'Giralang, Australian Capital Territory',
    value: 10000087,
  },
  {
    label: 'Girgarre, Victoria',
    value: 10007793,
  },
  {
    label: 'Girilambone, New South Wales',
    value: 10002550,
  },
  {
    label: 'Girral, New South Wales',
    value: 10002193,
  },
  {
    label: 'Girraween, New South Wales',
    value: 10000488,
  },
  {
    label: 'Girrawheen, Western Australia',
    value: 10008550,
  },
  {
    label: 'Giru, Queensland',
    value: 10004854,
  },
  {
    label: 'Girvan, New South Wales',
    value: 10001302,
  },
  {
    label: 'Gisborne, Victoria',
    value: 10007516,
  },
  {
    label: 'Givelda, Queensland',
    value: 10004331,
  },
  {
    label: 'Gladesville, New South Wales',
    value: 10000168,
  },
  {
    label: 'Gladesville, New South Wales',
    value: 10000419,
  },
  {
    label: 'Gladstone, New South Wales',
    value: 10001360,
  },
  {
    label: 'Gladstone, Queensland',
    value: 10004426,
  },
  {
    label: 'Gladstone, South Australia',
    value: 10005893,
  },
  {
    label: 'Gladstone, Tasmania',
    value: 10006462,
  },
  {
    label: 'Gladstone Park, Victoria',
    value: 10006755,
  },
  {
    label: 'Gladysdale, Victoria',
    value: 10008079,
  },
  {
    label: 'Glan Devon, Queensland',
    value: 10004120,
  },
  {
    label: 'Glandore, South Australia',
    value: 10005331,
  },
  {
    label: 'Glanmire, Queensland',
    value: 10003999,
  },
  {
    label: 'Glanville, South Australia',
    value: 10005282,
  },
  {
    label: 'Glass House Mountains, Queensland',
    value: 10003849,
  },
  {
    label: 'Glaziers Bay, Tasmania',
    value: 10006181,
  },
  {
    label: 'Glebe, New South Wales',
    value: 10000267,
  },
  {
    label: 'Glebe, Tasmania',
    value: 10006065,
  },
  {
    label: 'Gledhow, Western Australia',
    value: 10009221,
  },
  {
    label: 'Gledswood Hills, New South Wales',
    value: 10010194,
  },
  {
    label: 'Glen Alpine, New South Wales',
    value: 10001890,
  },
  {
    label: 'Glen Alvie, Victoria',
    value: 10008356,
  },
  {
    label: 'Glen Aplin, Queensland',
    value: 10003582,
  },
  {
    label: 'Glen Boughton, Queensland',
    value: 10005140,
  },
  {
    label: 'Glen Cairn, Queensland',
    value: 10003340,
  },
  {
    label: 'Glen Creek, Victoria',
    value: 10007917,
  },
  {
    label: 'Glen Davis, New South Wales',
    value: 10002596,
  },
  {
    label: 'Glen Dhu, Tasmania',
    value: 10006386,
  },
  {
    label: 'Glen Echo, Queensland',
    value: 10003998,
  },
  {
    label: 'Glen Eden, Queensland',
    value: 10004409,
  },
  {
    label: 'Glen Esk, Queensland',
    value: 10003308,
  },
  {
    label: 'Glen Forbes, Victoria',
    value: 10008378,
  },
  {
    label: 'Glen Forrest, Western Australia',
    value: 10008578,
  },
  {
    label: 'Glen Huntly, Victoria',
    value: 10006968,
  },
  {
    label: 'Glen Huon, Tasmania',
    value: 10006179,
  },
  {
    label: 'Glen Innes, New South Wales',
    value: 10001203,
  },
  {
    label: 'Glen Iris, Victoria',
    value: 10006934,
  },
  {
    label: 'Glen Iris, Western Australia',
    value: 10008847,
  },
  {
    label: 'Glen Isla, Queensland',
    value: 10004800,
  },
  {
    label: 'Glen Mervyn, Western Australia',
    value: 10008886,
  },
  {
    label: 'Glen Osmond, South Australia',
    value: 10005407,
  },
  {
    label: 'Glen Ruth, Queensland',
    value: 10005173,
  },
  {
    label: 'Glen Waverley, Victoria',
    value: 10006941,
  },
  {
    label: 'Glen William, New South Wales',
    value: 10000979,
  },
  {
    label: 'Glenalta, South Australia',
    value: 10005383,
  },
  {
    label: 'Glenarbon, Queensland',
    value: 10003590,
  },
  {
    label: 'Glenaubyn, Queensland',
    value: 10003699,
  },
  {
    label: 'Glenaven, Queensland',
    value: 10003493,
  },
  {
    label: 'Glenbar, Queensland',
    value: 10004127,
  },
  {
    label: 'Glenbrook, New South Wales',
    value: 10002386,
  },
  {
    label: 'Glenburn, Victoria',
    value: 10007964,
  },
  {
    label: 'Glencoe, New South Wales',
    value: 10001195,
  },
  {
    label: 'Glencoe, South Australia',
    value: 10005771,
  },
  {
    label: 'Glencoe, Western Australia',
    value: 10009122,
  },
  {
    label: 'Glendale, New South Wales',
    value: 10000869,
  },
  {
    label: 'Glendale, Queensland',
    value: 10004579,
  },
  {
    label: 'Glendalough, Western Australia',
    value: 10008427,
  },
  {
    label: 'Glenden, Queensland',
    value: 10004743,
  },
  {
    label: 'Glendenning, New South Wales',
    value: 10002355,
  },
  {
    label: 'Glendevie, Tasmania',
    value: 10006177,
  },
  {
    label: 'Glenelg, South Australia',
    value: 10005359,
  },
  {
    label: 'Glenelg South, South Australia',
    value: 10005360,
  },
  {
    label: 'Glenella, Queensland',
    value: 10004692,
  },
  {
    label: 'Glenfern, Tasmania',
    value: 10006241,
  },
  {
    label: 'Glenfield, New South Wales',
    value: 10000551,
  },
  {
    label: 'Glenfield, Western Australia',
    value: 10009808,
  },
  {
    label: 'Glenfield Park, New South Wales',
    value: 10002135,
  },
  {
    label: 'Glenfyne, Victoria',
    value: 10007195,
  },
  {
    label: 'Glengarry, Tasmania',
    value: 10006492,
  },
  {
    label: 'Glengarry, Victoria',
    value: 10008170,
  },
  {
    label: 'Glengowrie, South Australia',
    value: 10005358,
  },
  {
    label: 'Glenhaughton, Queensland',
    value: 10003690,
  },
  {
    label: 'Glenhaven, New South Wales',
    value: 10000520,
  },
  {
    label: 'Glenlee, Queensland',
    value: 10004580,
  },
  {
    label: 'Glenleigh, Queensland',
    value: 10004194,
  },
  {
    label: 'Glenlusk, Tasmania',
    value: 10006093,
  },
  {
    label: 'Glenlynn, Western Australia',
    value: 10008921,
  },
  {
    label: 'Glenlyon, Victoria',
    value: 10007557,
  },
  {
    label: 'Glenmaggie, Victoria',
    value: 10008174,
  },
  {
    label: 'Glenmoral, Queensland',
    value: 10004621,
  },
  {
    label: 'Glenmore, Victoria',
    value: 10007316,
  },
  {
    label: 'Glenmore Park, New South Wales',
    value: 10002297,
  },
  {
    label: 'Glenmorgan, Queensland',
    value: 10003696,
  },
  {
    label: 'Glennies Creek, New South Wales',
    value: 10001066,
  },
  {
    label: 'Glenning Valley, New South Wales',
    value: 10000801,
  },
  {
    label: 'Glenora, Tasmania',
    value: 10006250,
  },
  {
    label: 'Glenoran, Western Australia',
    value: 10008943,
  },
  {
    label: 'Glenorchy, Queensland',
    value: 10004229,
  },
  {
    label: 'Glenorchy, Tasmania',
    value: 10006084,
  },
  {
    label: 'Glenorchy, Victoria',
    value: 10007455,
  },
  {
    label: 'Glenore Grove, Queensland',
    value: 10003337,
  },
  {
    label: 'Glenorie, New South Wales',
    value: 10000523,
  },
  {
    label: 'Glenrae, Queensland',
    value: 10004169,
  },
  {
    label: 'Glenreagh, New South Wales',
    value: 10001430,
  },
  {
    label: 'Glenrock, Queensland',
    value: 10004049,
  },
  {
    label: 'Glenrowan, Victoria',
    value: 10007876,
  },
  {
    label: 'Glenroy, Queensland',
    value: 10004501,
  },
  {
    label: 'Glenroy, Victoria',
    value: 10006759,
  },
  {
    label: 'Glenside, South Australia',
    value: 10005410,
  },
  {
    label: 'Glenthompson, Victoria',
    value: 10007243,
  },
  {
    label: 'Glentromie, Western Australia',
    value: 10009684,
  },
  {
    label: 'Glenunga, South Australia',
    value: 10005408,
  },
  {
    label: 'Glenvale, Queensland',
    value: 10003406,
  },
  {
    label: 'Glenwood, New South Wales',
    value: 10002371,
  },
  {
    label: 'Glenwood, Queensland',
    value: 10003985,
  },
  {
    label: 'Globe Derby Park, South Australia',
    value: 10005537,
  },
  {
    label: 'Glossodia, New South Wales',
    value: 10002340,
  },
  {
    label: 'Glossop, South Australia',
    value: 10005811,
  },
  {
    label: 'Gloucester, New South Wales',
    value: 10001284,
  },
  {
    label: 'Glynde, South Australia',
    value: 10005446,
  },
  {
    label: 'Glynde Plaza, South Australia',
    value: 10005442,
  },
  {
    label: 'Gnangara, Western Australia',
    value: 10008561,
  },
  {
    label: 'Gnarabup, Western Australia',
    value: 10009021,
  },
  {
    label: 'Gnarwarre, Victoria',
    value: 10007090,
  },
  {
    label: 'Gnoorea, Western Australia',
    value: 10009991,
  },
  {
    label: 'Gnotuk, Victoria',
    value: 10007179,
  },
  {
    label: 'Gnowangerup, Western Australia',
    value: 10009242,
  },
  {
    label: 'Gnowellen, Western Australia',
    value: 10009170,
  },
  {
    label: 'Gobur, Victoria',
    value: 10007970,
  },
  {
    label: 'Godwin Beach, Queensland',
    value: 10003833,
  },
  {
    label: 'Gogango, Queensland',
    value: 10004486,
  },
  {
    label: 'Gol Gol, New South Wales',
    value: 10002292,
  },
  {
    label: 'Golconda, Tasmania',
    value: 10006420,
  },
  {
    label: 'Gold Coast, Queensland',
    value: 10005230,
  },
  {
    label: 'Golden Bay, Western Australia',
    value: 10008754,
  },
  {
    label: 'Golden Beach, Queensland',
    value: 10003881,
  },
  {
    label: 'Golden Fleece, Queensland',
    value: 10004133,
  },
  {
    label: 'Golden Grove, South Australia',
    value: 10005573,
  },
  {
    label: 'Golden Grove Village, South Australia',
    value: 10005574,
  },
  {
    label: 'Golden Point, Victoria',
    value: 10007330,
  },
  {
    label: 'Golden Point, Victoria',
    value: 10007541,
  },
  {
    label: 'Golden Point, Victoria',
    value: 10007571,
  },
  {
    label: 'Golden Square, Victoria',
    value: 10007703,
  },
  {
    label: 'Golden Valley, Tasmania',
    value: 10006544,
  },
  {
    label: 'Goldsborough, Queensland',
    value: 10005085,
  },
  {
    label: 'Goldsborough, Victoria',
    value: 10007588,
  },
  {
    label: 'Goldsworthy, Western Australia',
    value: 10010015,
  },
  {
    label: 'Golspie, New South Wales',
    value: 10001979,
  },
  {
    label: 'Good Night, Queensland',
    value: 10004352,
  },
  {
    label: 'Goodar, Queensland',
    value: 10003610,
  },
  {
    label: 'Goode Beach, Western Australia',
    value: 10009196,
  },
  {
    label: 'Goodger, Queensland',
    value: 10004088,
  },
  {
    label: 'Goodlands, Western Australia',
    value: 10009595,
  },
  {
    label: 'Goodna, Queensland',
    value: 10003205,
  },
  {
    label: 'Goodnight, New South Wales',
    value: 10002289,
  },
  {
    label: 'Goodooga, New South Wales',
    value: 10002552,
  },
  {
    label: 'Goodwood, Queensland',
    value: 10004288,
  },
  {
    label: 'Goodwood, South Australia',
    value: 10005324,
  },
  {
    label: 'Goodwood, Tasmania',
    value: 10006086,
  },
  {
    label: 'Googa Creek, Queensland',
    value: 10003279,
  },
  {
    label: 'Goolboo, Queensland',
    value: 10005034,
  },
  {
    label: 'Goolgowi, New South Wales',
    value: 10002161,
  },
  {
    label: 'Goolma, New South Wales',
    value: 10002620,
  },
  {
    label: 'Goolman, Queensland',
    value: 10003280,
  },
  {
    label: 'Goolmangar, New South Wales',
    value: 10001569,
  },
  {
    label: 'Gooloogong, New South Wales',
    value: 10002510,
  },
  {
    label: 'Goolwa, South Australia',
    value: 10005673,
  },
  {
    label: 'Goomalling, Western Australia',
    value: 10009582,
  },
  {
    label: 'Goomally, Queensland',
    value: 10004505,
  },
  {
    label: 'Goomarin, Western Australia',
    value: 10009441,
  },
  {
    label: 'Goombungee, Queensland',
    value: 10003490,
  },
  {
    label: 'Goomburra, Queensland',
    value: 10003564,
  },
  {
    label: 'Goomeri, Queensland',
    value: 10004029,
  },
  {
    label: 'Goomeribong, Queensland',
    value: 10004036,
  },
  {
    label: 'Goondi, Queensland',
    value: 10005055,
  },
  {
    label: 'Goondi Bend, Queensland',
    value: 10005075,
  },
  {
    label: 'Goondi Hill, Queensland',
    value: 10005074,
  },
  {
    label: 'Goondiwindi, Queensland',
    value: 10003615,
  },
  {
    label: 'Goonellabah, New South Wales',
    value: 10001583,
  },
  {
    label: 'Goonengerry, New South Wales',
    value: 10001599,
  },
  {
    label: 'Goonumbla, New South Wales',
    value: 10002636,
  },
  {
    label: 'Gooram, Victoria',
    value: 10007856,
  },
  {
    label: 'Gooramadda, Victoria',
    value: 10007895,
  },
  {
    label: 'Goorambat, Victoria',
    value: 10007983,
  },
  {
    label: 'Goorganga Creek, Queensland',
    value: 10004783,
  },
  {
    label: 'Goorganga Plains, Queensland',
    value: 10004799,
  },
  {
    label: 'Goornong, Victoria',
    value: 10007712,
  },
  {
    label: 'Gooseberry Hill, Western Australia',
    value: 10008592,
  },
  {
    label: 'Gootchie, Queensland',
    value: 10004228,
  },
  {
    label: 'Goovigen, Queensland',
    value: 10004468,
  },
  {
    label: 'Goowarra, Queensland',
    value: 10004478,
  },
  {
    label: 'Gorae, Victoria',
    value: 10007259,
  },
  {
    label: 'Goranba, Queensland',
    value: 10003694,
  },
  {
    label: 'Gordon, Australian Capital Territory',
    value: 10000118,
  },
  {
    label: 'Gordon, New South Wales',
    value: 10000333,
  },
  {
    label: 'Gordon, Tasmania',
    value: 10006271,
  },
  {
    label: 'Gordon, Victoria',
    value: 10007327,
  },
  {
    label: 'Gordon Park, Queensland',
    value: 10002858,
  },
  {
    label: 'Gordonbrook, Queensland',
    value: 10004085,
  },
  {
    label: 'Gordonvale, Queensland',
    value: 10005086,
  },
  {
    label: 'Gore, Queensland',
    value: 10003484,
  },
  {
    label: 'Gore Hill, New South Wales',
    value: 10000313,
  },
  {
    label: 'Gorge Rock, Western Australia',
    value: 10009335,
  },
  {
    label: 'Gormandale, Victoria',
    value: 10008193,
  },
  {
    label: 'Gorokan, New South Wales',
    value: 10000815,
  },
  {
    label: 'Goroke, Victoria',
    value: 10007486,
  },
  {
    label: 'Gorrie, Western Australia',
    value: 10009835,
  },
  {
    label: 'Gosford, New South Wales',
    value: 10000728,
  },
  {
    label: 'Goshen, Tasmania',
    value: 10006369,
  },
  {
    label: 'Gosnells, Western Australia',
    value: 10008627,
  },
  {
    label: 'Gosnells, Western Australia',
    value: 10010157,
  },
  {
    label: 'Goughs Bay, Victoria',
    value: 10007976,
  },
  {
    label: 'Goulburn, New South Wales',
    value: 10001987,
  },
  {
    label: 'Goulburn Island, Northern Territory',
    value: 10002727,
  },
  {
    label: 'Goulburn Weir, Victoria',
    value: 10007767,
  },
  {
    label: 'Goulds Country, Tasmania',
    value: 10006375,
  },
  {
    label: 'Gowanbrae, Victoria',
    value: 10006754,
  },
  {
    label: 'Gowangardie, Victoria',
    value: 10007865,
  },
  {
    label: 'Gowrie, Australian Capital Territory',
    value: 10000107,
  },
  {
    label: 'Gowrie, New South Wales',
    value: 10001125,
  },
  {
    label: 'Gowrie Little Plain, Queensland',
    value: 10003430,
  },
  {
    label: 'Gowrie Park, Tasmania',
    value: 10006555,
  },
  {
    label: 'Grabben Gullen, New South Wales',
    value: 10002003,
  },
  {
    label: 'Gracemere, Queensland',
    value: 10004502,
  },
  {
    label: 'Gracetown, Western Australia',
    value: 10009012,
  },
  {
    label: 'Graceville, Queensland',
    value: 10002930,
  },
  {
    label: 'Graceville East, Queensland',
    value: 10002928,
  },
  {
    label: 'Grafton, New South Wales',
    value: 10001475,
  },
  {
    label: 'Grafton West, New South Wales',
    value: 10001467,
  },
  {
    label: 'Grahams Creek, Queensland',
    value: 10004244,
  },
  {
    label: 'Grahamstown, New South Wales',
    value: 10002274,
  },
  {
    label: 'Graman, New South Wales',
    value: 10001185,
  },
  {
    label: 'Granadilla, Queensland',
    value: 10005026,
  },
  {
    label: 'Grand Secret, Queensland',
    value: 10004950,
  },
  {
    label: 'Grandchester, Queensland',
    value: 10003327,
  },
  {
    label: 'Grange, Queensland',
    value: 10002871,
  },
  {
    label: 'Grange, South Australia',
    value: 10005297,
  },
  {
    label: 'Grantham, Queensland',
    value: 10003380,
  },
  {
    label: 'Granton, Tasmania',
    value: 10006134,
  },
  {
    label: 'Grantville, Victoria',
    value: 10008374,
  },
  {
    label: 'Granville, New South Wales',
    value: 10000483,
  },
  {
    label: 'Granville, Queensland',
    value: 10004225,
  },
  {
    label: 'Granville, Western Australia',
    value: 10009672,
  },
  {
    label: 'Granya, Victoria',
    value: 10007938,
  },
  {
    label: 'Grapetree, Queensland',
    value: 10003423,
  },
  {
    label: 'Grasmere, New South Wales',
    value: 10001926,
  },
  {
    label: 'Grass Patch, Western Australia',
    value: 10009549,
  },
  {
    label: 'Grass Valley, Western Australia',
    value: 10009413,
  },
  {
    label: 'Grassdale, Queensland',
    value: 10003645,
  },
  {
    label: 'Grassmere, Victoria',
    value: 10007221,
  },
  {
    label: 'Grasstree Beach, Queensland',
    value: 10004697,
  },
  {
    label: 'Grasstree Hill, Tasmania',
    value: 10006100,
  },
  {
    label: 'Grassy, Tasmania',
    value: 10006433,
  },
  {
    label: 'Grassy Head, New South Wales',
    value: 10001379,
  },
  {
    label: 'Gravelly Beach, Tasmania',
    value: 10006496,
  },
  {
    label: 'Gravesend, New South Wales',
    value: 10001251,
  },
  {
    label: 'Gray, Northern Territory',
    value: 10002735,
  },
  {
    label: 'Gray, Tasmania',
    value: 10006367,
  },
  {
    label: 'Grays Gate, Queensland',
    value: 10003537,
  },
  {
    label: 'Grays Point, New South Wales',
    value: 10000699,
  },
  {
    label: 'Graytown, Victoria',
    value: 10007768,
  },
  {
    label: 'Great Bay, Tasmania',
    value: 10006278,
  },
  {
    label: 'Great Keppel Island, Queensland',
    value: 10004449,
  },
  {
    label: 'Great Sandy Strait, Queensland',
    value: 10004257,
  },
  {
    label: 'Great Western, Victoria',
    value: 10007437,
  },
  {
    label: 'Gredgwin, Victoria',
    value: 10007661,
  },
  {
    label: 'Green Fields, South Australia',
    value: 10005521,
  },
  {
    label: 'Green Head, Western Australia',
    value: 10009698,
  },
  {
    label: 'Green Hills, New South Wales',
    value: 10000994,
  },
  {
    label: 'Green Hills, New South Wales',
    value: 10002279,
  },
  {
    label: 'Green Island, Queensland',
    value: 10005120,
  },
  {
    label: 'Green Point, New South Wales',
    value: 10000737,
  },
  {
    label: 'Green Point, New South Wales',
    value: 10001311,
  },
  {
    label: 'Green Range, Western Australia',
    value: 10009171,
  },
  {
    label: 'Green Valley, New South Wales',
    value: 10000558,
  },
  {
    label: 'Green Valley, Western Australia',
    value: 10009182,
  },
  {
    label: 'Greenacre, New South Wales',
    value: 10000597,
  },
  {
    label: 'Greenacres, South Australia',
    value: 10005492,
  },
  {
    label: 'Greenbank, Queensland',
    value: 10003004,
  },
  {
    label: 'Greenbushes, Western Australia',
    value: 10008910,
  },
  {
    label: 'Greendale, New South Wales',
    value: 10002295,
  },
  {
    label: 'Greendale, Victoria',
    value: 10007321,
  },
  {
    label: 'Greenethorpe, New South Wales',
    value: 10002513,
  },
  {
    label: 'Greenfield Park, New South Wales',
    value: 10000585,
  },
  {
    label: 'Greenfields, Western Australia',
    value: 10008791,
  },
  {
    label: 'Greenhill, New South Wales',
    value: 10001374,
  },
  {
    label: 'Greenhill, South Australia',
    value: 10005591,
  },
  {
    label: 'Greenhills, Western Australia',
    value: 10009051,
  },
  {
    label: 'Greenlake, Queensland',
    value: 10004456,
  },
  {
    label: 'Greenlands, New South Wales',
    value: 10001063,
  },
  {
    label: 'Greenmount, Queensland',
    value: 10003546,
  },
  {
    label: 'Greenmount, Queensland',
    value: 10004751,
  },
  {
    label: 'Greenmount, Western Australia',
    value: 10008526,
  },
  {
    label: 'Greenmount East, Queensland',
    value: 10003547,
  },
  {
    label: 'Greenock, South Australia',
    value: 10005837,
  },
  {
    label: 'Greenough, Western Australia',
    value: 10009805,
  },
  {
    label: 'Greens Beach, Tasmania',
    value: 10006483,
  },
  {
    label: 'Greens Creek, Queensland',
    value: 10003997,
  },
  {
    label: 'Greensborough, Victoria',
    value: 10006832,
  },
  {
    label: 'Greenslopes, Queensland',
    value: 10002990,
  },
  {
    label: 'Greenup, Queensland',
    value: 10003601,
  },
  {
    label: 'Greenvale, Queensland',
    value: 10004918,
  },
  {
    label: 'Greenvale, Victoria',
    value: 10006784,
  },
  {
    label: 'Greenway, Australian Capital Territory',
    value: 10000098,
  },
  {
    label: 'Greenways, South Australia',
    value: 10005742,
  },
  {
    label: 'Greenwell Point, New South Wales',
    value: 10001813,
  },
  {
    label: 'Greenwich, New South Wales',
    value: 10000309,
  },
  {
    label: 'Greenwith, South Australia',
    value: 10005575,
  },
  {
    label: 'Greenwood, Queensland',
    value: 10003621,
  },
  {
    label: 'Greenwood, Western Australia',
    value: 10008447,
  },
  {
    label: 'Greenwood, Western Australia',
    value: 10010110,
  },
  {
    label: 'Greenwoods Valley, Western Australia',
    value: 10009416,
  },
  {
    label: 'Gregory, Western Australia',
    value: 10009822,
  },
  {
    label: 'Gregory Hills, New South Wales',
    value: 10010195,
  },
  {
    label: 'Gregory River, Queensland',
    value: 10004291,
  },
  {
    label: 'Gregory River, Queensland',
    value: 10004775,
  },
  {
    label: 'Greigs Flat, New South Wales',
    value: 10001850,
  },
  {
    label: 'Grenfell, New South Wales',
    value: 10002515,
  },
  {
    label: 'Gresford, New South Wales',
    value: 10000944,
  },
  {
    label: 'Greta, New South Wales',
    value: 10001088,
  },
  {
    label: 'Greta, Victoria',
    value: 10007877,
  },
  {
    label: 'Gretna, Tasmania',
    value: 10006261,
  },
  {
    label: 'Grevillia, New South Wales',
    value: 10001538,
  },
  {
    label: 'Grey, Western Australia',
    value: 10009715,
  },
  {
    label: 'Greycliffe, Queensland',
    value: 10004607,
  },
  {
    label: 'Greys Plain, Western Australia',
    value: 10009948,
  },
  {
    label: 'Greystanes, New South Wales',
    value: 10000490,
  },
  {
    label: 'Griffin, Queensland',
    value: 10003811,
  },
  {
    label: 'Griffith, Australian Capital Territory',
    value: 10000025,
  },
  {
    label: 'Griffith, New South Wales',
    value: 10002220,
  },
  {
    label: 'Grimwade, Western Australia',
    value: 10008909,
  },
  {
    label: 'Grindelwald, Tasmania',
    value: 10006497,
  },
  {
    label: 'Grogan, New South Wales',
    value: 10002191,
  },
  {
    label: 'Grong Grong, New South Wales',
    value: 10002157,
  },
  {
    label: 'Groomsville, Queensland',
    value: 10003452,
  },
  {
    label: 'Grose Vale, New South Wales',
    value: 10002322,
  },
  {
    label: 'Grose Wold, New South Wales',
    value: 10002320,
  },
  {
    label: 'Grosmont, Queensland',
    value: 10003684,
  },
  {
    label: 'Grosvenor, Queensland',
    value: 10004193,
  },
  {
    label: 'Grosvenor Place, New South Wales',
    value: 10000127,
  },
  {
    label: 'Grove, Tasmania',
    value: 10006186,
  },
  {
    label: 'Grovedale, Victoria',
    value: 10007074,
  },
  {
    label: 'Gruyere, Victoria',
    value: 10008050,
  },
  {
    label: 'Guanaba, Queensland',
    value: 10003111,
  },
  {
    label: 'Gubbata, New South Wales',
    value: 10002194,
  },
  {
    label: 'Guerilla Bay, New South Wales',
    value: 10001739,
  },
  {
    label: 'Guilderton, Western Australia',
    value: 10008485,
  },
  {
    label: 'Guildford, New South Wales',
    value: 10000532,
  },
  {
    label: 'Guildford, Victoria',
    value: 10007543,
  },
  {
    label: 'Guildford, Western Australia',
    value: 10008510,
  },
  {
    label: 'Guildford, Western Australia',
    value: 10010118,
  },
  {
    label: 'Guildford West, New South Wales',
    value: 10000534,
  },
  {
    label: 'Gulargambone, New South Wales',
    value: 10002541,
  },
  {
    label: 'Gulfview Heights, South Australia',
    value: 10005513,
  },
  {
    label: 'Gulgong, New South Wales',
    value: 10002621,
  },
  {
    label: 'Gulliver, Queensland',
    value: 10004884,
  },
  {
    label: 'Gulnare, South Australia',
    value: 10005891,
  },
  {
    label: 'Gulpa, New South Wales',
    value: 10002241,
  },
  {
    label: 'Guluguba, Queensland',
    value: 10003681,
  },
  {
    label: 'Gum Flat, New South Wales',
    value: 10001180,
  },
  {
    label: 'Gum Scrub, New South Wales',
    value: 10001380,
  },
  {
    label: 'Gumdale, Queensland',
    value: 10003030,
  },
  {
    label: 'Gumeracha, South Australia',
    value: 10005680,
  },
  {
    label: 'Gumly Gumly, New South Wales',
    value: 10002155,
  },
  {
    label: 'Gunalda, Queensland',
    value: 10003978,
  },
  {
    label: 'Gunbar, New South Wales',
    value: 10002248,
  },
  {
    label: 'Gunbower, Victoria',
    value: 10007728,
  },
  {
    label: 'Gundagai, New South Wales',
    value: 10002266,
  },
  {
    label: 'Gundaring, Western Australia',
    value: 10009110,
  },
  {
    label: 'Gundaroo, New South Wales',
    value: 10002039,
  },
  {
    label: 'Gundiah, Queensland',
    value: 10004236,
  },
  {
    label: 'Gundillion, New South Wales',
    value: 10002046,
  },
  {
    label: 'Gundowring, Victoria',
    value: 10007907,
  },
  {
    label: 'Gundy, New South Wales',
    value: 10001105,
  },
  {
    label: 'Gungahlin, Australian Capital Territory',
    value: 10000120,
  },
  {
    label: 'Gungal, New South Wales',
    value: 10001080,
  },
  {
    label: 'Gungaloon, Queensland',
    value: 10004123,
  },
  {
    label: 'Gunn, Northern Territory',
    value: 10002745,
  },
  {
    label: 'Gunnawarra, Queensland',
    value: 10005163,
  },
  {
    label: 'Gunnedah, New South Wales',
    value: 10001221,
  },
  {
    label: 'Gunnewin, Queensland',
    value: 10003728,
  },
  {
    label: 'Gunning, New South Wales',
    value: 10001989,
  },
  {
    label: 'Gunningbland, New South Wales',
    value: 10002655,
  },
  {
    label: 'Gunns Plains, Tasmania',
    value: 10006588,
  },
  {
    label: 'Gununa, Queensland',
    value: 10005130,
  },
  {
    label: 'Gunyarra, Queensland',
    value: 10004780,
  },
  {
    label: 'Gunyidi, Western Australia',
    value: 10009696,
  },
  {
    label: 'Gurley, New South Wales',
    value: 10001245,
  },
  {
    label: 'Gurulmundi, Queensland',
    value: 10003672,
  },
  {
    label: 'Gutha, Western Australia',
    value: 10009896,
  },
  {
    label: 'Guthalungra, Queensland',
    value: 10004818,
  },
  {
    label: 'Guthridge, Victoria',
    value: 10008157,
  },
  {
    label: 'Guyra, New South Wales',
    value: 10001196,
  },
  {
    label: 'Guys Forest, Victoria',
    value: 10007949,
  },
  {
    label: 'Guys Hill, Victoria',
    value: 10008093,
  },
  {
    label: 'Gwabegar, New South Wales',
    value: 10001170,
  },
  {
    label: 'Gwambegwine, Queensland',
    value: 10003689,
  },
  {
    label: 'Gwambygine, Western Australia',
    value: 10009047,
  },
  {
    label: 'Gwandalan, New South Wales',
    value: 10000773,
  },
  {
    label: 'Gwelup, Western Australia',
    value: 10008434,
  },
  {
    label: 'Gwindinup, Western Australia',
    value: 10008879,
  },
  {
    label: 'Gwynneville, New South Wales',
    value: 10001645,
  },
  {
    label: 'Gymbowen, Victoria',
    value: 10007476,
  },
  {
    label: 'Gymea, New South Wales',
    value: 10000681,
  },
  {
    label: 'Gympie, Queensland',
    value: 10003992,
  },
  {
    label: 'Haasts Bluff, Northern Territory',
    value: 10002787,
  },
  {
    label: 'Habana, Queensland',
    value: 10004702,
  },
  {
    label: 'Haberfield, New South Wales',
    value: 10000280,
  },
  {
    label: 'Hackett, Australian Capital Territory',
    value: 10000022,
  },
  {
    label: 'Hacketts Gully, Western Australia',
    value: 10008589,
  },
  {
    label: 'Hackham, South Australia',
    value: 10005632,
  },
  {
    label: 'Hackney, South Australia',
    value: 10005435,
  },
  {
    label: 'Haddon, Victoria',
    value: 10007352,
  },
  {
    label: 'Haden, Queensland',
    value: 10003486,
  },
  {
    label: 'Hadfield, Victoria',
    value: 10006761,
  },
  {
    label: 'Hadspen, Tasmania',
    value: 10006501,
  },
  {
    label: 'Hagley, Tasmania',
    value: 10006506,
  },
  {
    label: 'Hahndorf, South Australia',
    value: 10005699,
  },
  {
    label: 'Haigslea, Queensland',
    value: 10003287,
  },
  {
    label: 'Hail Creek, Queensland',
    value: 10004741,
  },
  {
    label: 'Halekulani, New South Wales',
    value: 10000808,
  },
  {
    label: 'Halfway Creek, New South Wales',
    value: 10001469,
  },
  {
    label: 'Haliday Bay, Queensland',
    value: 10004687,
  },
  {
    label: 'Halifax, Queensland',
    value: 10005011,
  },
  {
    label: 'Halifax Street, South Australia',
    value: 10005238,
  },
  {
    label: 'Hall, Australian Capital Territory',
    value: 10000095,
  },
  {
    label: 'Hallam, Victoria',
    value: 10008086,
  },
  {
    label: 'Hallett, South Australia',
    value: 10005863,
  },
  {
    label: 'Hallett Cove, South Australia',
    value: 10005622,
  },
  {
    label: 'Hallidays Point, New South Wales',
    value: 10001337,
  },
  {
    label: 'Halloran, New South Wales',
    value: 10000771,
  },
  {
    label: 'Halls Creek, Western Australia',
    value: 10010063,
  },
  {
    label: 'Halls Gap, Victoria',
    value: 10007451,
  },
  {
    label: 'Halls Head, Western Australia',
    value: 10008783,
  },
  {
    label: 'Hallston, Victoria',
    value: 10008307,
  },
  {
    label: 'Halton, New South Wales',
    value: 10000945,
  },
  {
    label: 'Haly Creek, Queensland',
    value: 10004086,
  },
  {
    label: 'Hamel, Western Australia',
    value: 10008806,
  },
  {
    label: 'Hamelin Bay, Western Australia',
    value: 10009028,
  },
  {
    label: 'Hamelin Pool, Western Australia',
    value: 10009814,
  },
  {
    label: 'Hamersley, Western Australia',
    value: 10008445,
  },
  {
    label: 'Hamersley Range, Western Australia',
    value: 10009996,
  },
  {
    label: 'Hamilton, New South Wales',
    value: 10000924,
  },
  {
    label: 'Hamilton, Queensland',
    value: 10002817,
  },
  {
    label: 'Hamilton, South Australia',
    value: 10005842,
  },
  {
    label: 'Hamilton, Tasmania',
    value: 10006260,
  },
  {
    label: 'Hamilton, Victoria',
    value: 10007248,
  },
  {
    label: 'Hamilton Creek, Queensland',
    value: 10004585,
  },
  {
    label: 'Hamilton East, New South Wales',
    value: 10000922,
  },
  {
    label: 'Hamilton Hill, Western Australia',
    value: 10008703,
  },
  {
    label: 'Hamilton Hill, Western Australia',
    value: 10010139,
  },
  {
    label: 'Hamilton Island, Queensland',
    value: 10004812,
  },
  {
    label: 'Hamilton Plains, Queensland',
    value: 10004796,
  },
  {
    label: 'Hamilton South, New South Wales',
    value: 10000923,
  },
  {
    label: 'Hamley, South Australia',
    value: 10005948,
  },
  {
    label: 'Hamley Bridge, South Australia',
    value: 10005850,
  },
  {
    label: 'Hamlyn Heights, Victoria',
    value: 10007064,
  },
  {
    label: 'Hamlyn Terrace, New South Wales',
    value: 10000775,
  },
  {
    label: 'Hammond, South Australia',
    value: 10005869,
  },
  {
    label: 'Hammond Park, Western Australia',
    value: 10008712,
  },
  {
    label: 'Hammondville, New South Wales',
    value: 10000563,
  },
  {
    label: 'Hampden, Queensland',
    value: 10004726,
  },
  {
    label: 'Hampshire, Tasmania',
    value: 10006625,
  },
  {
    label: 'Hampstead Gardens, South Australia',
    value: 10005493,
  },
  {
    label: 'Hampton, New South Wales',
    value: 10002436,
  },
  {
    label: 'Hampton, Victoria',
    value: 10007014,
  },
  {
    label: 'Hampton East, Victoria',
    value: 10007016,
  },
  {
    label: 'Hampton North, Victoria',
    value: 10007015,
  },
  {
    label: 'Hampton Park, Victoria',
    value: 10008345,
  },
  {
    label: 'Hannaford, Queensland',
    value: 10003649,
  },
  {
    label: 'Hannam Vale, New South Wales',
    value: 10001389,
  },
  {
    label: 'Hannans, Western Australia',
    value: 10009496,
  },
  {
    label: 'Hannans, Western Australia',
    value: 10009516,
  },
  {
    label: 'Hansonville, Victoria',
    value: 10007878,
  },
  {
    label: 'Hanwood, New South Wales',
    value: 10002218,
  },
  {
    label: 'Happy Valley, Queensland',
    value: 10004263,
  },
  {
    label: 'Happy Valley, Queensland',
    value: 10004977,
  },
  {
    label: 'Happy Valley, South Australia',
    value: 10005623,
  },
  {
    label: 'Harbord, New South Wales',
    value: 10000376,
  },
  {
    label: 'Harcourt, Victoria',
    value: 10007545,
  },
  {
    label: 'Harden, New South Wales',
    value: 10002018,
  },
  {
    label: 'Hardys Bay, New South Wales',
    value: 10000752,
  },
  {
    label: 'Harefield, New South Wales',
    value: 10002138,
  },
  {
    label: 'Harford, Tasmania',
    value: 10006562,
  },
  {
    label: 'Hargraves, New South Wales',
    value: 10002618,
  },
  {
    label: 'Harkaway, Victoria',
    value: 10008092,
  },
  {
    label: 'Harlaxton, Queensland',
    value: 10003395,
  },
  {
    label: 'Harlin, Queensland',
    value: 10003266,
  },
  {
    label: 'Harman, Australian Capital Territory',
    value: 10000004,
  },
  {
    label: 'Harper Creek, Queensland',
    value: 10003898,
  },
  {
    label: 'Harrami, Queensland',
    value: 10004204,
  },
  {
    label: 'Harriet, Queensland',
    value: 10004164,
  },
  {
    label: 'Harrietville, Victoria',
    value: 10008012,
  },
  {
    label: 'Harrington, New South Wales',
    value: 10001310,
  },
  {
    label: 'Harrington Park, New South Wales',
    value: 10001918,
  },
  {
    label: 'Harris Park, New South Wales',
    value: 10000508,
  },
  {
    label: 'Harris River, Western Australia',
    value: 10008838,
  },
  {
    label: 'Harrismith, Western Australia',
    value: 10009309,
  },
  {
    label: 'Harristown, Queensland',
    value: 10003405,
  },
  {
    label: 'Harrisville, Queensland',
    value: 10003289,
  },
  {
    label: 'Harrow, Victoria',
    value: 10007279,
  },
  {
    label: 'Harston, Victoria',
    value: 10007778,
  },
  {
    label: 'Hartley, New South Wales',
    value: 10002438,
  },
  {
    label: 'Harvey, Western Australia',
    value: 10008814,
  },
  {
    label: 'Harwood, New South Wales',
    value: 10001494,
  },
  {
    label: 'Haslam, South Australia',
    value: 10006029,
  },
  {
    label: 'Hassall Grove, New South Wales',
    value: 10002353,
  },
  {
    label: 'Hastings, Tasmania',
    value: 10006170,
  },
  {
    label: 'Hastings, Victoria',
    value: 10008257,
  },
  {
    label: 'Hastings, Western Australia',
    value: 10009084,
  },
  {
    label: 'Hastings Point, New South Wales',
    value: 10001641,
  },
  {
    label: 'Hat Head, New South Wales',
    value: 10001367,
  },
  {
    label: 'Hatfield, New South Wales',
    value: 10002256,
  },
  {
    label: 'Hatherleigh, South Australia',
    value: 10005755,
  },
  {
    label: 'Havenview, Tasmania',
    value: 10006617,
  },
  {
    label: 'Havilah, New South Wales',
    value: 10002608,
  },
  {
    label: 'Hawker, Australian Capital Territory',
    value: 10000071,
  },
  {
    label: 'Hawker, South Australia',
    value: 10005874,
  },
  {
    label: 'Hawkesbury Heights, New South Wales',
    value: 10002398,
  },
  {
    label: 'Hawkesdale, Victoria',
    value: 10007239,
  },
  {
    label: 'Hawkins Creek, Queensland',
    value: 10005010,
  },
  {
    label: 'Hawksburn, Victoria',
    value: 10006925,
  },
  {
    label: 'Hawkwood, Queensland',
    value: 10004174,
  },
  {
    label: 'Hawley Beach, Tasmania',
    value: 10006563,
  },
  {
    label: 'Hawthorn, South Australia',
    value: 10005397,
  },
  {
    label: 'Hawthorn, Victoria',
    value: 10006871,
  },
  {
    label: 'Hawthorn East, Victoria',
    value: 10006872,
  },
  {
    label: 'Hawthorndene, South Australia',
    value: 10005381,
  },
  {
    label: 'Hawthorne, Queensland',
    value: 10003056,
  },
  {
    label: 'Hay, New South Wales',
    value: 10002251,
  },
  {
    label: 'Hay, Western Australia',
    value: 10009238,
  },
  {
    label: 'Hay Point, Queensland',
    value: 10004694,
  },
  {
    label: 'Hayborough, South Australia',
    value: 10005667,
  },
  {
    label: 'Hayes, Tasmania',
    value: 10006244,
  },
  {
    label: 'Hayman Island, Queensland',
    value: 10004803,
  },
  {
    label: 'Haymarket, New South Wales',
    value: 10000131,
  },
  {
    label: 'Haymarket, New South Wales',
    value: 10000192,
  },
  {
    label: 'Hazelbrook, New South Wales',
    value: 10002405,
  },
  {
    label: 'Hazeldene, Victoria',
    value: 10007840,
  },
  {
    label: 'Hazelgrove, New South Wales',
    value: 10002427,
  },
  {
    label: 'Hazelmere, Western Australia',
    value: 10008512,
  },
  {
    label: 'Hazelvale, Western Australia',
    value: 10009237,
  },
  {
    label: 'Hazelwood Park, South Australia',
    value: 10005421,
  },
  {
    label: 'Hazledean, Queensland',
    value: 10003845,
  },
  {
    label: 'Hazledean, Queensland',
    value: 10004710,
  },
  {
    label: 'Headington Hill, Queensland',
    value: 10003553,
  },
  {
    label: 'Healesville, Victoria',
    value: 10008055,
  },
  {
    label: 'Healy, Queensland',
    value: 10004985,
  },
  {
    label: 'Heath Hill, Victoria',
    value: 10008365,
  },
  {
    label: 'Heathcote, New South Wales',
    value: 10000705,
  },
  {
    label: 'Heathcote, Victoria',
    value: 10007650,
  },
  {
    label: 'Heathcote Junction, Victoria',
    value: 10008038,
  },
  {
    label: 'Heatherbrae, New South Wales',
    value: 10001017,
  },
  {
    label: 'Heatherton, Victoria',
    value: 10007049,
  },
  {
    label: 'Heathfield, South Australia',
    value: 10005603,
  },
  {
    label: 'Heathmont, Victoria',
    value: 10006907,
  },
  {
    label: 'Heathpool, South Australia',
    value: 10005429,
  },
  {
    label: 'Heathridge, Western Australia',
    value: 10008461,
  },
  {
    label: 'Heathwood, Queensland',
    value: 10002965,
  },
  {
    label: 'Heatley, Queensland',
    value: 10004895,
  },
  {
    label: 'Hebden, New South Wales',
    value: 10001064,
  },
  {
    label: 'Hebel, Queensland',
    value: 10003768,
  },
  {
    label: 'Hebersham, New South Wales',
    value: 10002385,
  },
  {
    label: 'Heckenberg, New South Wales',
    value: 10000557,
  },
  {
    label: 'Hectorville, South Australia',
    value: 10005459,
  },
  {
    label: 'Heddon Greta, New South Wales',
    value: 10000980,
  },
  {
    label: 'Hedley, Victoria',
    value: 10008333,
  },
  {
    label: 'Heidelberg, Victoria',
    value: 10006822,
  },
  {
    label: 'Heidelberg Heights, Victoria',
    value: 10006818,
  },
  {
    label: 'Heidelberg Rgh, Victoria',
    value: 10006817,
  },
  {
    label: 'Heidelberg West, Victoria',
    value: 10006816,
  },
  {
    label: 'Helena Valley, Western Australia',
    value: 10008530,
  },
  {
    label: 'Helensburgh, New South Wales',
    value: 10001660,
  },
  {
    label: 'Helensvale, Queensland',
    value: 10003131,
  },
  {
    label: 'Helensvale Town Centre, Queensland',
    value: 10003130,
  },
  {
    label: 'Helidon, Queensland',
    value: 10003373,
  },
  {
    label: 'Helidon Spa, Queensland',
    value: 10003371,
  },
  {
    label: 'Hemmant, Queensland',
    value: 10003062,
  },
  {
    label: 'Henderson, Western Australia',
    value: 10008723,
  },
  {
    label: 'Hendon, South Australia',
    value: 10005276,
  },
  {
    label: 'Hendra, Queensland',
    value: 10002822,
  },
  {
    label: 'Henley, New South Wales',
    value: 10000420,
  },
  {
    label: 'Henley Beach, South Australia',
    value: 10005299,
  },
  {
    label: 'Henley Beach South, South Australia',
    value: 10005298,
  },
  {
    label: 'Henley Brook, Western Australia',
    value: 10008513,
  },
  {
    label: 'Henty, New South Wales',
    value: 10002170,
  },
  {
    label: 'Henty, Victoria',
    value: 10007273,
  },
  {
    label: 'Henty, Western Australia',
    value: 10008869,
  },
  {
    label: 'Hepburn Springs, Victoria',
    value: 10007554,
  },
  {
    label: 'Herberton, Queensland',
    value: 10005218,
  },
  {
    label: 'Herdsman, Western Australia',
    value: 10008429,
  },
  {
    label: 'Heritage Park, Queensland',
    value: 10002987,
  },
  {
    label: 'Hermannsburg, Northern Territory',
    value: 10002784,
  },
  {
    label: 'Hermidale, New South Wales',
    value: 10002554,
  },
  {
    label: 'Hermit Park, Queensland',
    value: 10004880,
  },
  {
    label: 'Hernani, New South Wales',
    value: 10001445,
  },
  {
    label: 'Herne Hill, Victoria',
    value: 10007079,
  },
  {
    label: 'Herne Hill, Western Australia',
    value: 10008520,
  },
  {
    label: 'Hernes Oak, Victoria',
    value: 10008135,
  },
  {
    label: 'Heron Island, Queensland',
    value: 10004417,
  },
  {
    label: 'Herons Creek, New South Wales',
    value: 10001392,
  },
  {
    label: 'Herrick, Tasmania',
    value: 10006465,
  },
  {
    label: 'Herron, Western Australia',
    value: 10008776,
  },
  {
    label: 'Herston, Queensland',
    value: 10002813,
  },
  {
    label: 'Hervey Bay, Queensland',
    value: 10004259,
  },
  {
    label: 'Hervey Bay Dc, Queensland',
    value: 10004261,
  },
  {
    label: 'Hervey Range, Queensland',
    value: 10004927,
  },
  {
    label: 'Hesket, Victoria',
    value: 10007524,
  },
  {
    label: 'Hester, Western Australia',
    value: 10008916,
  },
  {
    label: 'Hester Brook, Western Australia',
    value: 10008918,
  },
  {
    label: 'Hewett, South Australia',
    value: 10005566,
  },
  {
    label: 'Hexham, New South Wales',
    value: 10000992,
  },
  {
    label: 'Hexham, Victoria',
    value: 10007210,
  },
  {
    label: 'Heybridge, Tasmania',
    value: 10006605,
  },
  {
    label: 'Heyfield, Victoria',
    value: 10008173,
  },
  {
    label: 'Heywood, Victoria',
    value: 10007257,
  },
  {
    label: 'Hickety, Western Australia',
    value: 10009794,
  },
  {
    label: 'Hickeys Creek, New South Wales',
    value: 10001358,
  },
  {
    label: 'Hidden Valley, Queensland',
    value: 10004563,
  },
  {
    label: 'Hidden Valley, Queensland',
    value: 10004916,
  },
  {
    label: 'Hidden Valley, Victoria',
    value: 10008029,
  },
  {
    label: 'Hideaway Bay, Queensland',
    value: 10004801,
  },
  {
    label: 'Higgins, Australian Capital Territory',
    value: 10000082,
  },
  {
    label: 'Higginsville, Western Australia',
    value: 10009543,
  },
  {
    label: 'High Wycombe, Western Australia',
    value: 10008532,
  },
  {
    label: 'Highbury, South Australia',
    value: 10005497,
  },
  {
    label: 'Highbury, Western Australia',
    value: 10009107,
  },
  {
    label: 'Highclere, Tasmania',
    value: 10006630,
  },
  {
    label: 'Highcroft, Tasmania',
    value: 10006321,
  },
  {
    label: 'Highett, Victoria',
    value: 10007020,
  },
  {
    label: 'Highfields, New South Wales',
    value: 10000887,
  },
  {
    label: 'Highfields, Queensland',
    value: 10003450,
  },
  {
    label: 'Highgate, South Australia',
    value: 10005401,
  },
  {
    label: 'Highgate, Western Australia',
    value: 10008400,
  },
  {
    label: 'Highgate Hill, Queensland',
    value: 10002942,
  },
  {
    label: 'Highgrove, Queensland',
    value: 10003447,
  },
  {
    label: 'Highland Park, Queensland',
    value: 10003127,
  },
  {
    label: 'Highland Plains, Queensland',
    value: 10003617,
  },
  {
    label: 'Highland Plains, Queensland',
    value: 10003721,
  },
  {
    label: 'Highpoint City, Victoria',
    value: 10006731,
  },
  {
    label: 'Highton, Victoria',
    value: 10007069,
  },
  {
    label: 'Highvale, Queensland',
    value: 10003866,
  },
  {
    label: 'Hill End, New South Wales',
    value: 10002607,
  },
  {
    label: 'Hill End, Victoria',
    value: 10008137,
  },
  {
    label: 'Hill River, Western Australia',
    value: 10009718,
  },
  {
    label: 'Hill Top, New South Wales',
    value: 10001948,
  },
  {
    label: 'Hillarys, Western Australia',
    value: 10008452,
  },
  {
    label: 'Hillarys, Western Australia',
    value: 10010109,
  },
  {
    label: 'Hillbank, South Australia',
    value: 10005544,
  },
  {
    label: 'Hillcrest, Queensland',
    value: 10002985,
  },
  {
    label: 'Hillcrest, South Australia',
    value: 10005491,
  },
  {
    label: 'Hillcrest, Tasmania',
    value: 10006616,
  },
  {
    label: 'Hillcrest, Victoria',
    value: 10007344,
  },
  {
    label: 'Hilldale, New South Wales',
    value: 10001271,
  },
  {
    label: 'Hillgrove, New South Wales',
    value: 10001150,
  },
  {
    label: 'Hillier, South Australia',
    value: 10005559,
  },
  {
    label: 'Hillman, Western Australia',
    value: 10008743,
  },
  {
    label: 'Hillman River, Western Australia',
    value: 10009368,
  },
  {
    label: 'Hillsborough, New South Wales',
    value: 10000894,
  },
  {
    label: 'Hillsborough, New South Wales',
    value: 10000967,
  },
  {
    label: 'Hillsdale, New South Wales',
    value: 10000260,
  },
  {
    label: 'Hillside, Victoria',
    value: 10006739,
  },
  {
    label: 'Hillside, Victoria',
    value: 10008205,
  },
  {
    label: 'Hillside, Western Australia',
    value: 10009101,
  },
  {
    label: 'Hillston, New South Wales',
    value: 10002209,
  },
  {
    label: 'Hilltown, South Australia',
    value: 10005884,
  },
  {
    label: 'Hillwood, Tasmania',
    value: 10006407,
  },
  {
    label: 'Hilton, South Australia',
    value: 10005316,
  },
  {
    label: 'Hilton, Western Australia',
    value: 10008709,
  },
  {
    label: 'Hilton Plaza, South Australia',
    value: 10005319,
  },
  {
    label: 'Hinchinbrook, New South Wales',
    value: 10000556,
  },
  {
    label: 'Hindmarsh, South Australia',
    value: 10005246,
  },
  {
    label: 'Hindmarsh, Western Australia',
    value: 10009590,
  },
  {
    label: 'Hines Hill, Western Australia',
    value: 10009436,
  },
  {
    label: 'Hinton, New South Wales',
    value: 10000982,
  },
  {
    label: 'Hithergreen, Western Australia',
    value: 10009002,
  },
  {
    label: 'Hivesville, Queensland',
    value: 10004095,
  },
  {
    label: 'Hmas Albatross, New South Wales',
    value: 10001804,
  },
  {
    label: 'Hmas Cerberus, Victoria',
    value: 10008262,
  },
  {
    label: 'Hmas Creswell, Australian Capital Territory',
    value: 10000001,
  },
  {
    label: 'Hobart, Tasmania',
    value: 10006064,
  },
  {
    label: 'Hobart, Tasmania',
    value: 10006068,
  },
  {
    label: 'Hobartville, New South Wales',
    value: 10002321,
  },
  {
    label: 'Hobbys Yards, New South Wales',
    value: 10002461,
  },
  {
    label: 'Hocking, Western Australia',
    value: 10008559,
  },
  {
    label: 'Hoddles Creek, Victoria',
    value: 10006919,
  },
  {
    label: 'Hoddys Well, Western Australia',
    value: 10009850,
  },
  {
    label: 'Hodgleigh, Queensland',
    value: 10004084,
  },
  {
    label: 'Hodgson, Queensland',
    value: 10003724,
  },
  {
    label: 'Hoffman, Western Australia',
    value: 10008818,
  },
  {
    label: 'Holbrook, New South Wales',
    value: 10002112,
  },
  {
    label: 'Holden Hill, South Australia',
    value: 10005496,
  },
  {
    label: 'Holder, Australian Capital Territory',
    value: 10000052,
  },
  {
    label: 'Holland Park, Queensland',
    value: 10002992,
  },
  {
    label: 'Holland Park West, Queensland',
    value: 10002994,
  },
  {
    label: 'Holleton, Western Australia',
    value: 10009462,
  },
  {
    label: 'Hollisdale, New South Wales',
    value: 10001402,
  },
  {
    label: 'Hollow Tree, Tasmania',
    value: 10006252,
  },
  {
    label: 'Holloways Beach, Queensland',
    value: 10005199,
  },
  {
    label: 'Hollydeen, New South Wales',
    value: 10001052,
  },
  {
    label: 'Hollywell, Queensland',
    value: 10003151,
  },
  {
    label: 'Holmesglen, Victoria',
    value: 10006937,
  },
  {
    label: 'Holmesville, New South Wales',
    value: 10000872,
  },
  {
    label: 'Holmview, Queensland',
    value: 10003088,
  },
  {
    label: 'Holmwood, Western Australia',
    value: 10009726,
  },
  {
    label: 'Holroyd, New South Wales',
    value: 10000480,
  },
  {
    label: 'Holsworthy, New South Wales',
    value: 10000578,
  },
  {
    label: 'Holt, Australian Capital Territory',
    value: 10000081,
  },
  {
    label: 'Holt Rock, Western Australia',
    value: 10009294,
  },
  {
    label: 'Holwell, Tasmania',
    value: 10006488,
  },
  {
    label: 'Holyoake, Western Australia',
    value: 10008802,
  },
  {
    label: 'Home Hill, Queensland',
    value: 10004828,
  },
  {
    label: 'Home Island Cocos (Keeling) Islands, Western Australia',
    value: 10010067,
  },
  {
    label: 'Homebush, New South Wales',
    value: 10000476,
  },
  {
    label: 'Homebush, Queensland',
    value: 10004668,
  },
  {
    label: 'Homebush Bay, New South Wales',
    value: 10000456,
  },
  {
    label: 'Homestead, Queensland',
    value: 10004919,
  },
  {
    label: 'Homewood, Victoria',
    value: 10007965,
  },
  {
    label: 'Honeywood, Tasmania',
    value: 10006103,
  },
  {
    label: 'Hookswood, Queensland',
    value: 10003671,
  },
  {
    label: 'Hope Island, Queensland',
    value: 10003129,
  },
  {
    label: 'Hope Vale, New South Wales',
    value: 10010208,
  },
  {
    label: 'Hope Valley, South Australia',
    value: 10005498,
  },
  {
    label: 'Hope Valley, Western Australia',
    value: 10008720,
  },
  {
    label: 'Hopeland, Queensland',
    value: 10003664,
  },
  {
    label: 'Hopeland, Western Australia',
    value: 10008654,
  },
  {
    label: 'Hopetoun, Victoria',
    value: 10007465,
  },
  {
    label: 'Hopetoun, Western Australia',
    value: 10009271,
  },
  {
    label: 'Hopetoun Gardens, Victoria',
    value: 10006966,
  },
  {
    label: 'Hoppers Crossing, Victoria',
    value: 10006723,
  },
  {
    label: 'Hordern Vale, Victoria',
    value: 10007136,
  },
  {
    label: 'Horn, Queensland',
    value: 10010193,
  },
  {
    label: 'Hornet Bank, Queensland',
    value: 10003692,
  },
  {
    label: 'Horningsea Park, New South Wales',
    value: 10000574,
  },
  {
    label: 'Hornsby, New South Wales',
    value: 10000161,
  },
  {
    label: 'Hornsby, New South Wales',
    value: 10000343,
  },
  {
    label: 'Hornsby Westfield, New South Wales',
    value: 10000162,
  },
  {
    label: 'Horrocks, Western Australia',
    value: 10009825,
  },
  {
    label: 'Horse Camp, Queensland',
    value: 10004356,
  },
  {
    label: 'Horse Creek, Queensland',
    value: 10004595,
  },
  {
    label: 'Horseshoe Bay, Queensland',
    value: 10004943,
  },
  {
    label: 'Horseshoe Lagoon, Queensland',
    value: 10004857,
  },
  {
    label: 'Horsham, Victoria',
    value: 10007466,
  },
  {
    label: 'Horsham, Victoria',
    value: 10007477,
  },
  {
    label: 'Horsley, New South Wales',
    value: 10001709,
  },
  {
    label: 'Horsley Park, New South Wales',
    value: 10000581,
  },
  {
    label: 'Horton, Queensland',
    value: 10004280,
  },
  {
    label: 'Hotham Heights, Victoria',
    value: 10008013,
  },
  {
    label: 'Hotham Hill, Victoria',
    value: 10006770,
  },
  {
    label: 'Houghton, South Australia',
    value: 10005581,
  },
  {
    label: 'Houston, Victoria',
    value: 10006887,
  },
  {
    label: 'Hove, South Australia',
    value: 10005369,
  },
  {
    label: 'Hovea, Western Australia',
    value: 10008579,
  },
  {
    label: 'Howard, Queensland',
    value: 10004272,
  },
  {
    label: 'Howard Springs, Northern Territory',
    value: 10002752,
  },
  {
    label: 'Howatharra, Western Australia',
    value: 10009781,
  },
  {
    label: 'Howden, Tasmania',
    value: 10006154,
  },
  {
    label: 'Howes Valley, New South Wales',
    value: 10001068,
  },
  {
    label: 'Howick, Western Australia',
    value: 10009555,
  },
  {
    label: 'Howlong, New South Wales',
    value: 10002108,
  },
  {
    label: 'Howrah, Tasmania',
    value: 10006109,
  },
  {
    label: 'Howth, Tasmania',
    value: 10006608,
  },
  {
    label: 'Hoxton Park, New South Wales',
    value: 10000573,
  },
  {
    label: 'Hoyleton, South Australia',
    value: 10005881,
  },
  {
    label: 'Hudson, Queensland',
    value: 10005067,
  },
  {
    label: 'Hughenden, Queensland',
    value: 10004956,
  },
  {
    label: 'Hughes, Australian Capital Territory',
    value: 10000031,
  },
  {
    label: 'Hughesdale, Victoria',
    value: 10006976,
  },
  {
    label: 'Hulongine, Western Australia',
    value: 10009581,
  },
  {
    label: 'Hume, Australian Capital Territory',
    value: 10000096,
  },
  {
    label: 'Hume Weir, Victoria',
    value: 10007922,
  },
  {
    label: 'Humeburn, Queensland',
    value: 10003779,
  },
  {
    label: 'Humevale, Victoria',
    value: 10008033,
  },
  {
    label: 'Humphery, Queensland',
    value: 10004147,
  },
  {
    label: 'Humpty Doo, Northern Territory',
    value: 10002753,
  },
  {
    label: 'Humula, New South Wales',
    value: 10002158,
  },
  {
    label: 'Hunchy, Queensland',
    value: 10003905,
  },
  {
    label: 'Hungerford, Queensland',
    value: 10003786,
  },
  {
    label: 'Hunter, Victoria',
    value: 10007714,
  },
  {
    label: 'Hunter Region Mc, New South Wales',
    value: 10000940,
  },
  {
    label: 'Hunters Hill, New South Wales',
    value: 10000415,
  },
  {
    label: 'Huntfield Heights, South Australia',
    value: 10005631,
  },
  {
    label: 'Huntingdale, Victoria',
    value: 10006974,
  },
  {
    label: 'Huntingdale, Western Australia',
    value: 10008629,
  },
  {
    label: 'Huntingdon, New South Wales',
    value: 10001411,
  },
  {
    label: 'Huntingfield, Tasmania',
    value: 10006164,
  },
  {
    label: 'Huntleys Cove, New South Wales',
    value: 10000416,
  },
  {
    label: 'Huntleys Point, New South Wales',
    value: 10000418,
  },
  {
    label: 'Huntly, Victoria',
    value: 10007686,
  },
  {
    label: 'Huon, Victoria',
    value: 10007926,
  },
  {
    label: 'Huonville, Tasmania',
    value: 10006175,
  },
  {
    label: 'Hurlstone Park, New South Wales',
    value: 10000602,
  },
  {
    label: 'Hurstbridge, Victoria',
    value: 10006847,
  },
  {
    label: 'Hurstville, New South Wales',
    value: 10000664,
  },
  {
    label: 'Hurstville Bc, New South Wales',
    value: 10000149,
  },
  {
    label: 'Hurstville Westfield, New South Wales',
    value: 10000663,
  },
  {
    label: 'Huskisson, New South Wales',
    value: 10001795,
  },
  {
    label: 'Hutt Street, South Australia',
    value: 10005234,
  },
  {
    label: 'Hutton Creek, Queensland',
    value: 10003715,
  },
  {
    label: 'Hyams Beach, New South Wales',
    value: 10001802,
  },
  {
    label: 'Hyde Park, Queensland',
    value: 10004879,
  },
  {
    label: 'Hyde Park, South Australia',
    value: 10005385,
  },
  {
    label: 'Hyden, Western Australia',
    value: 10009307,
  },
  {
    label: 'Hynam, South Australia',
    value: 10005729,
  },
  {
    label: 'Idalia, Queensland',
    value: 10004875,
  },
  {
    label: 'Ideraway, Queensland',
    value: 10004141,
  },
  {
    label: 'Ikewa, Western Australia',
    value: 10009727,
  },
  {
    label: 'Ilford, New South Wales',
    value: 10002613,
  },
  {
    label: 'Ilfracombe, Queensland',
    value: 10004635,
  },
  {
    label: 'Ilkley, Queensland',
    value: 10003903,
  },
  {
    label: 'Illabarook, Victoria',
    value: 10007346,
  },
  {
    label: 'Illabo, New South Wales',
    value: 10002025,
  },
  {
    label: 'Illaroo, New South Wales',
    value: 10010190,
  },
  {
    label: 'Illawong, New South Wales',
    value: 10000707,
  },
  {
    label: 'Illowa, Victoria',
    value: 10007225,
  },
  {
    label: 'Iluka, New South Wales',
    value: 10001495,
  },
  {
    label: 'Iluka, Western Australia',
    value: 10008463,
  },
  {
    label: 'Imbil, Queensland',
    value: 10003984,
  },
  {
    label: 'Inala, Queensland',
    value: 10002937,
  },
  {
    label: 'Indee, Western Australia',
    value: 10010007,
  },
  {
    label: 'Indented Head, Victoria',
    value: 10007111,
  },
  {
    label: 'Indooroopilly, Queensland',
    value: 10002903,
  },
  {
    label: 'Inggarda, Western Australia',
    value: 10009954,
  },
  {
    label: 'Ingham, Queensland',
    value: 10005003,
  },
  {
    label: 'Ingle Farm, South Australia',
    value: 10005517,
  },
  {
    label: 'Ingleburn, New South Wales',
    value: 10000190,
  },
  {
    label: 'Ingleburn, New South Wales',
    value: 10001906,
  },
  {
    label: 'Inglehope, Western Australia',
    value: 10008801,
  },
  {
    label: 'Ingleside, New South Wales',
    value: 10000396,
  },
  {
    label: 'Inglewood, Queensland',
    value: 10003602,
  },
  {
    label: 'Inglewood, South Australia',
    value: 10005584,
  },
  {
    label: 'Inglewood, Victoria',
    value: 10007637,
  },
  {
    label: 'Inglewood, Western Australia',
    value: 10008500,
  },
  {
    label: 'Ingoldsby, Queensland',
    value: 10003358,
  },
  {
    label: 'Injune, Queensland',
    value: 10003716,
  },
  {
    label: 'Inkerman, Queensland',
    value: 10004824,
  },
  {
    label: 'Inkerman, Victoria',
    value: 10007587,
  },
  {
    label: 'Inkpen, Western Australia',
    value: 10009063,
  },
  {
    label: 'Inman Valley, South Australia',
    value: 10005669,
  },
  {
    label: 'Innaloo, Western Australia',
    value: 10008433,
  },
  {
    label: 'Innaloo, Western Australia',
    value: 10010104,
  },
  {
    label: 'Innawanga, Western Australia',
    value: 10010045,
  },
  {
    label: 'Innisfail, Queensland',
    value: 10005059,
  },
  {
    label: 'Innisfail Estate, Queensland',
    value: 10005064,
  },
  {
    label: 'Innot Hot Springs, Queensland',
    value: 10005166,
  },
  {
    label: 'Inskip, Queensland',
    value: 10004022,
  },
  {
    label: 'Interlaken, Tasmania',
    value: 10006142,
  },
  {
    label: 'Inverell, New South Wales',
    value: 10001188,
  },
  {
    label: 'Invergordon, Victoria',
    value: 10007822,
  },
  {
    label: 'Invergordon South, Victoria',
    value: 10007815,
  },
  {
    label: 'Invergowrie, New South Wales',
    value: 10001151,
  },
  {
    label: 'Inverlaw, Queensland',
    value: 10004077,
  },
  {
    label: 'Inverleigh, Victoria',
    value: 10007285,
  },
  {
    label: 'Inverloch, Victoria',
    value: 10008387,
  },
  {
    label: 'Invermay, Tasmania',
    value: 10006381,
  },
  {
    label: 'Invermay, Victoria',
    value: 10007368,
  },
  {
    label: 'Inverness, Queensland',
    value: 10004554,
  },
  {
    label: 'Iona, Victoria',
    value: 10008106,
  },
  {
    label: 'Ipswich, Queensland',
    value: 10003228,
  },
  {
    label: 'Iredale, Queensland',
    value: 10003432,
  },
  {
    label: 'Irishtown, Tasmania',
    value: 10006660,
  },
  {
    label: 'Irishtown, Western Australia',
    value: 10009406,
  },
  {
    label: 'Iron Baron, South Australia',
    value: 10005983,
  },
  {
    label: 'Iron Knob, South Australia',
    value: 10005985,
  },
  {
    label: 'Ironbank, South Australia',
    value: 10005609,
  },
  {
    label: 'Ironbark, Queensland',
    value: 10003271,
  },
  {
    label: 'Irongate, Queensland',
    value: 10003501,
  },
  {
    label: 'Ironpot, Queensland',
    value: 10004073,
  },
  {
    label: 'Irrewarra, Victoria',
    value: 10007154,
  },
  {
    label: 'Irvinebank, Queensland',
    value: 10005221,
  },
  {
    label: 'Irvingdale, Queensland',
    value: 10003641,
  },
  {
    label: 'Irvington, New South Wales',
    value: 10001510,
  },
  {
    label: 'Irwin, Western Australia',
    value: 10009733,
  },
  {
    label: 'Irymple, Victoria',
    value: 10007619,
  },
  {
    label: 'Isaacs, Australian Capital Territory',
    value: 10000042,
  },
  {
    label: 'Isabella, New South Wales',
    value: 10002467,
  },
  {
    label: 'Isabella Plains, Australian Capital Territory',
    value: 10000115,
  },
  {
    label: 'Isis Central, Queensland',
    value: 10004287,
  },
  {
    label: 'Isis River, Queensland',
    value: 10004283,
  },
  {
    label: 'Isisford, Queensland',
    value: 10004646,
  },
  {
    label: 'Isla, Queensland',
    value: 10004625,
  },
  {
    label: 'Isle Of Capri, Queensland',
    value: 10003156,
  },
  {
    label: 'Islington, New South Wales',
    value: 10000908,
  },
  {
    label: 'Israelite Bay, Western Australia',
    value: 10009579,
  },
  {
    label: 'Isseka, Western Australia',
    value: 10009819,
  },
  {
    label: 'Ivanhoe, New South Wales',
    value: 10002662,
  },
  {
    label: 'Ivanhoe, Victoria',
    value: 10006815,
  },
  {
    label: 'Ivanhoe East, Victoria',
    value: 10006814,
  },
  {
    label: 'Iveragh, Queensland',
    value: 10004404,
  },
  {
    label: 'Jabiru, Northern Territory',
    value: 10002799,
  },
  {
    label: 'Jabuk, South Australia',
    value: 10005777,
  },
  {
    label: 'Jacana, Victoria',
    value: 10006764,
  },
  {
    label: 'Jackadgery, New South Wales',
    value: 10001468,
  },
  {
    label: 'Jackeys Marsh, Tasmania',
    value: 10006536,
  },
  {
    label: 'Jackitup, Western Australia',
    value: 10009245,
  },
  {
    label: 'Jackson, Queensland',
    value: 10003702,
  },
  {
    label: 'Jackson North, Queensland',
    value: 10003703,
  },
  {
    label: 'Jackson South, Queensland',
    value: 10003701,
  },
  {
    label: 'Jacobs Well, Queensland',
    value: 10003103,
  },
  {
    label: 'Jacup, Western Australia',
    value: 10009252,
  },
  {
    label: 'Jaffa, Queensland',
    value: 10005027,
  },
  {
    label: 'Jalbarragup, Western Australia',
    value: 10008966,
  },
  {
    label: 'Jaloran, Western Australia',
    value: 10009111,
  },
  {
    label: 'Jam Jerrup, Victoria',
    value: 10008371,
  },
  {
    label: 'Jamberoo, New South Wales',
    value: 10001713,
  },
  {
    label: 'Jambin, Queensland',
    value: 10004500,
  },
  {
    label: 'Jamboree Heights, Queensland',
    value: 10002926,
  },
  {
    label: 'James Cook University, Queensland',
    value: 10004877,
  },
  {
    label: 'Jamestown, South Australia',
    value: 10005902,
  },
  {
    label: 'Jamieson, Victoria',
    value: 10007980,
  },
  {
    label: 'Jamison Centre, Australian Capital Territory',
    value: 10000069,
  },
  {
    label: 'Jan Juc, Victoria',
    value: 10007119,
  },
  {
    label: 'Jancourt, Victoria',
    value: 10007192,
  },
  {
    label: 'Jancourt East, Victoria',
    value: 10007191,
  },
  {
    label: 'Jandabup, Western Australia',
    value: 10008560,
  },
  {
    label: 'Jandakot, Western Australia',
    value: 10008719,
  },
  {
    label: 'Jandowae, Queensland',
    value: 10003661,
  },
  {
    label: 'Jane Brook, Western Australia',
    value: 10008523,
  },
  {
    label: 'Jannali, New South Wales',
    value: 10000679,
  },
  {
    label: 'Jardee, Western Australia',
    value: 10008931,
  },
  {
    label: 'Jardine, Queensland',
    value: 10004487,
  },
  {
    label: 'Jarklin, Victoria',
    value: 10007638,
  },
  {
    label: 'Jarrahdale, Western Australia',
    value: 10008653,
  },
  {
    label: 'Jarrahwood, Western Australia',
    value: 10008960,
  },
  {
    label: 'Jarvisfield, Queensland',
    value: 10004835,
  },
  {
    label: 'Jaspers Brush, New South Wales',
    value: 10001728,
  },
  {
    label: 'Jeeralang, Victoria',
    value: 10008143,
  },
  {
    label: 'Jeetho, Victoria',
    value: 10008296,
  },
  {
    label: 'Jelcobine, Western Australia',
    value: 10009071,
  },
  {
    label: 'Jellinbah, Queensland',
    value: 10004529,
  },
  {
    label: 'Jembaicumbene, New South Wales',
    value: 10002048,
  },
  {
    label: 'Jennacubbine, Western Australia',
    value: 10009401,
  },
  {
    label: 'Jennapullin, Western Australia',
    value: 10009397,
  },
  {
    label: 'Jenolan, New South Wales',
    value: 10010189,
  },
  {
    label: 'Jenolan Caves, New South Wales',
    value: 10002437,
  },
  {
    label: 'Jeogla, New South Wales',
    value: 10001153,
  },
  {
    label: 'Jeparit, Victoria',
    value: 10007503,
  },
  {
    label: 'Jerangle, New South Wales',
    value: 10002076,
  },
  {
    label: 'Jerdacuttup, Western Australia',
    value: 10009270,
  },
  {
    label: 'Jeremadra, New South Wales',
    value: 10001747,
  },
  {
    label: 'Jericho, Queensland',
    value: 10004636,
  },
  {
    label: 'Jericho, South Australia',
    value: 10005933,
  },
  {
    label: 'Jericho, Tasmania',
    value: 10006139,
  },
  {
    label: 'Jerilderie, New South Wales',
    value: 10002258,
  },
  {
    label: 'Jerona, Queensland',
    value: 10004855,
  },
  {
    label: 'Jerrabomberra, New South Wales',
    value: 10002032,
  },
  {
    label: 'Jerramungup, Western Australia',
    value: 10009256,
  },
  {
    label: 'Jerrys Plains, New South Wales',
    value: 10001070,
  },
  {
    label: 'Jerseyville, New South Wales',
    value: 10001350,
  },
  {
    label: 'Jerusalem, South Australia',
    value: 10005935,
  },
  {
    label: 'Jervis Bay, Australian Capital Territory',
    value: 10000002,
  },
  {
    label: 'Jervois, South Australia',
    value: 10005720,
  },
  {
    label: 'Jesmond, New South Wales',
    value: 10000913,
  },
  {
    label: 'Jetsonville, Tasmania',
    value: 10006449,
  },
  {
    label: 'Jewells, New South Wales',
    value: 10000834,
  },
  {
    label: 'Jibberding, Western Australia',
    value: 10009882,
  },
  {
    label: 'Jilakin, Western Australia',
    value: 10009314,
  },
  {
    label: 'Jilliby, New South Wales',
    value: 10000766,
  },
  {
    label: 'Jimboomba, Queensland',
    value: 10003197,
  },
  {
    label: 'Jimbour, Queensland',
    value: 10003648,
  },
  {
    label: 'Jimna, Queensland',
    value: 10003846,
  },
  {
    label: 'Jindabyne, New South Wales',
    value: 10002063,
  },
  {
    label: 'Jindalee, Queensland',
    value: 10002924,
  },
  {
    label: 'Jindalee, Western Australia',
    value: 10008480,
  },
  {
    label: 'Jindera, New South Wales',
    value: 10002105,
  },
  {
    label: 'Jindong, Western Australia',
    value: 10008997,
  },
  {
    label: 'Jingalup, Western Australia',
    value: 10009387,
  },
  {
    label: 'Jingellic, New South Wales',
    value: 10002098,
  },
  {
    label: 'Jingili, Northern Territory',
    value: 10002688,
  },
  {
    label: 'Jitarning, Western Australia',
    value: 10009315,
  },
  {
    label: 'Jobs Gate, Queensland',
    value: 10003777,
  },
  {
    label: 'Johanna, Victoria',
    value: 10007135,
  },
  {
    label: 'Johns River, New South Wales',
    value: 10001391,
  },
  {
    label: 'Johnsons Hill, Queensland',
    value: 10004593,
  },
  {
    label: 'Johnsonville, Victoria',
    value: 10008244,
  },
  {
    label: 'Johnstown, Queensland',
    value: 10004119,
  },
  {
    label: 'Jolimont, Western Australia',
    value: 10008424,
  },
  {
    label: 'Jollys Lookout, Queensland',
    value: 10003860,
  },
  {
    label: 'Jondaryan, Queensland',
    value: 10003638,
  },
  {
    label: 'Jones Hill, Queensland',
    value: 10004000,
  },
  {
    label: 'Joondalup, Western Australia',
    value: 10008457,
  },
  {
    label: 'Joondalup Dc, Western Australia',
    value: 10010105,
  },
  {
    label: 'Joondanna, Western Australia',
    value: 10008536,
  },
  {
    label: 'Joskeleigh, Queensland',
    value: 10004485,
  },
  {
    label: 'Joslin, South Australia',
    value: 10005444,
  },
  {
    label: 'Joyner, Queensland',
    value: 10003804,
  },
  {
    label: 'Jubilee Heights, Queensland',
    value: 10005065,
  },
  {
    label: 'Judbury, Tasmania',
    value: 10006167,
  },
  {
    label: 'Judds Creek, New South Wales',
    value: 10002455,
  },
  {
    label: 'Jugiong, New South Wales',
    value: 10002270,
  },
  {
    label: 'Julago, Queensland',
    value: 10004910,
  },
  {
    label: 'Julatten, Queensland',
    value: 10005118,
  },
  {
    label: 'Julia Creek, Queensland',
    value: 10004958,
  },
  {
    label: 'Julimar, Western Australia',
    value: 10009856,
  },
  {
    label: 'Jumbuk, Victoria',
    value: 10008189,
  },
  {
    label: 'Jumbunna, Victoria',
    value: 10008304,
  },
  {
    label: 'Juna Downs, Western Australia',
    value: 10010046,
  },
  {
    label: 'Junction Hill, New South Wales',
    value: 10001465,
  },
  {
    label: 'Junction View, Queensland',
    value: 10003357,
  },
  {
    label: 'Jundah, Queensland',
    value: 10004653,
  },
  {
    label: 'Junee, New South Wales',
    value: 10002177,
  },
  {
    label: 'Jung, Victoria',
    value: 10007474,
  },
  {
    label: 'Junortoun, Victoria',
    value: 10007685,
  },
  {
    label: 'Jurien Bay, Western Australia',
    value: 10009703,
  },
  {
    label: 'Kaban, Queensland',
    value: 10005223,
  },
  {
    label: 'Kadathinni, Western Australia',
    value: 10009709,
  },
  {
    label: 'Kadina, South Australia',
    value: 10005934,
  },
  {
    label: 'Kahibah, New South Wales',
    value: 10000891,
  },
  {
    label: 'Kaimkillenbun, Queensland',
    value: 10003651,
  },
  {
    label: 'Kairi, Queensland',
    value: 10005168,
  },
  {
    label: 'Kalamunda, Western Australia',
    value: 10008586,
  },
  {
    label: 'Kalamunda, Western Australia',
    value: 10010112,
  },
  {
    label: 'Kalang, New South Wales',
    value: 10001449,
  },
  {
    label: 'Kalangadoo, South Australia',
    value: 10005750,
  },
  {
    label: 'Kalannie, Western Australia',
    value: 10009596,
  },
  {
    label: 'Kalapa, Queensland',
    value: 10004539,
  },
  {
    label: 'Kalbar, Queensland',
    value: 10003293,
  },
  {
    label: 'Kalbarri, Western Australia',
    value: 10009829,
  },
  {
    label: 'Kalbarri National Park, Western Australia',
    value: 10009831,
  },
  {
    label: 'Kalbeeba, South Australia',
    value: 10005564,
  },
  {
    label: 'Kaleen, Australian Capital Territory',
    value: 10000091,
  },
  {
    label: 'Kalgan, Western Australia',
    value: 10009197,
  },
  {
    label: 'Kalgoorlie, Western Australia',
    value: 10009488,
  },
  {
    label: 'Kalgoorlie, Western Australia',
    value: 10009515,
  },
  {
    label: 'Kalgup, Western Australia',
    value: 10008996,
  },
  {
    label: 'Kalimna, Victoria',
    value: 10008249,
  },
  {
    label: 'Kalkallo, Victoria',
    value: 10006793,
  },
  {
    label: 'Kalkie, Queensland',
    value: 10004310,
  },
  {
    label: 'Kallangur, Queensland',
    value: 10003807,
  },
  {
    label: 'Kallaroo, Western Australia',
    value: 10008450,
  },
  {
    label: 'Kallista, Victoria',
    value: 10008070,
  },
  {
    label: 'Kaloorup, Western Australia',
    value: 10008998,
  },
  {
    label: 'Kalorama, Victoria',
    value: 10008047,
  },
  {
    label: 'Kalpowar, Queensland',
    value: 10004210,
  },
  {
    label: 'Kaltukatjara, Northern Territory',
    value: 10002794,
  },
  {
    label: 'Kalumburu, Western Australia',
    value: 10010029,
  },
  {
    label: 'Kamarah, New South Wales',
    value: 10002178,
  },
  {
    label: 'Kamarooka North, Victoria',
    value: 10007733,
  },
  {
    label: 'Kambah, Australian Capital Territory',
    value: 10000101,
  },
  {
    label: 'Kambalda, Western Australia',
    value: 10009535,
  },
  {
    label: 'Kamerunga, Queensland',
    value: 10005110,
  },
  {
    label: 'Kamma, Queensland',
    value: 10005087,
  },
  {
    label: 'Kanahooka, New South Wales',
    value: 10001707,
  },
  {
    label: 'Kandanga, Queensland',
    value: 10003976,
  },
  {
    label: 'Kandanga Creek, Queensland',
    value: 10003967,
  },
  {
    label: 'Kandos, New South Wales',
    value: 10002600,
  },
  {
    label: 'Kangaloon, New South Wales',
    value: 10001953,
  },
  {
    label: 'Kangarilla, South Australia',
    value: 10005616,
  },
  {
    label: 'Kangaroo Creek, New South Wales',
    value: 10001481,
  },
  {
    label: 'Kangaroo Flat, Victoria',
    value: 10007704,
  },
  {
    label: 'Kangaroo Ground, Victoria',
    value: 10006844,
  },
  {
    label: 'Kangaroo Gully, Western Australia',
    value: 10008915,
  },
  {
    label: 'Kangaroo Point, New South Wales',
    value: 10000673,
  },
  {
    label: 'Kangaroo Point, Queensland',
    value: 10003049,
  },
  {
    label: 'Kangaroo Valley, New South Wales',
    value: 10001968,
  },
  {
    label: 'Kangy Angy, New South Wales',
    value: 10000759,
  },
  {
    label: 'Kanigan, Queensland',
    value: 10003979,
  },
  {
    label: 'Kanimbla, Queensland',
    value: 10005108,
  },
  {
    label: 'Kaniva, Victoria',
    value: 10007500,
  },
  {
    label: 'Kanmantoo, South Australia',
    value: 10005706,
  },
  {
    label: 'Kanowna, Western Australia',
    value: 10009510,
  },
  {
    label: 'Kanumbra, Victoria',
    value: 10007969,
  },
  {
    label: 'Kanwal, New South Wales',
    value: 10000776,
  },
  {
    label: 'Kanyapella, Victoria',
    value: 10007725,
  },
  {
    label: 'Kaoota, Tasmania',
    value: 10006279,
  },
  {
    label: 'Kapaldo, Queensland',
    value: 10004208,
  },
  {
    label: 'Kapooka, New South Wales',
    value: 10002176,
  },
  {
    label: 'Kapunda, South Australia',
    value: 10005843,
  },
  {
    label: 'Karabar, New South Wales',
    value: 10002034,
  },
  {
    label: 'Karakin, Western Australia',
    value: 10008493,
  },
  {
    label: 'Karalee, Queensland',
    value: 10003277,
  },
  {
    label: 'Karama, Northern Territory',
    value: 10002696,
  },
  {
    label: 'Karana Downs, Queensland',
    value: 10003273,
  },
  {
    label: 'Karangi, New South Wales',
    value: 10001426,
  },
  {
    label: 'Karanja, Tasmania',
    value: 10006246,
  },
  {
    label: 'Karara, Queensland',
    value: 10003437,
  },
  {
    label: 'Karawara, Western Australia',
    value: 10008676,
  },
  {
    label: 'Karawatha, Queensland',
    value: 10002982,
  },
  {
    label: 'Kardella, Victoria',
    value: 10008301,
  },
  {
    label: 'Kardinya, Western Australia',
    value: 10008702,
  },
  {
    label: 'Kareela, New South Wales',
    value: 10000695,
  },
  {
    label: 'Karijini, Western Australia',
    value: 10010044,
  },
  {
    label: 'Karingal, Victoria',
    value: 10007044,
  },
  {
    label: 'Kariong, New South Wales',
    value: 10000716,
  },
  {
    label: 'Karlgarin, Western Australia',
    value: 10009305,
  },
  {
    label: 'Karlkurla, Western Australia',
    value: 10009498,
  },
  {
    label: 'Karloning, Western Australia',
    value: 10009619,
  },
  {
    label: 'Karloo, Western Australia',
    value: 10009754,
  },
  {
    label: 'Karnak, Victoria',
    value: 10007470,
  },
  {
    label: 'Karnup, Western Australia',
    value: 10008756,
  },
  {
    label: 'Karoola, Tasmania',
    value: 10006470,
  },
  {
    label: 'Karoonda, South Australia',
    value: 10005784,
  },
  {
    label: 'Karragarra Island, Queensland',
    value: 10003080,
  },
  {
    label: 'Karragullen, Western Australia',
    value: 10008631,
  },
  {
    label: 'Karrakatta, Western Australia',
    value: 10008416,
  },
  {
    label: 'Karrakup, Western Australia',
    value: 10008650,
  },
  {
    label: 'Karramindie, Western Australia',
    value: 10009481,
  },
  {
    label: 'Karranadgin, Western Australia',
    value: 10009584,
  },
  {
    label: 'Karratha, Western Australia',
    value: 10009992,
  },
  {
    label: 'Karratha Industrial Estate, Western Australia',
    value: 10009980,
  },
  {
    label: 'Karridale, Western Australia',
    value: 10009030,
  },
  {
    label: 'Karrinyup, Western Australia',
    value: 10008435,
  },
  {
    label: 'Karrinyup, Western Australia',
    value: 10010107,
  },
  {
    label: 'Karroun Hill, Western Australia',
    value: 10009604,
  },
  {
    label: 'Kars Springs, New South Wales',
    value: 10001110,
  },
  {
    label: 'Karuah, New South Wales',
    value: 10001010,
  },
  {
    label: 'Karumba, Queensland',
    value: 10005228,
  },
  {
    label: 'Katamatite, Victoria',
    value: 10007837,
  },
  {
    label: 'Katandra, Victoria',
    value: 10007816,
  },
  {
    label: 'Katanning, Western Australia',
    value: 10009130,
  },
  {
    label: 'Katherine, Northern Territory',
    value: 10002760,
  },
  {
    label: 'Katherine, Northern Territory',
    value: 10002761,
  },
  {
    label: 'Katherine, Northern Territory',
    value: 10002763,
  },
  {
    label: 'Katoomba, New South Wales',
    value: 10002407,
  },
  {
    label: 'Katrine, Western Australia',
    value: 10009848,
  },
  {
    label: 'Katunga, Victoria',
    value: 10007828,
  },
  {
    label: 'Kauring, Western Australia',
    value: 10009061,
  },
  {
    label: 'Kawana, Queensland',
    value: 10004459,
  },
  {
    label: 'Kawarren, Victoria',
    value: 10007158,
  },
  {
    label: 'Kawl Kawl, Queensland',
    value: 10004092,
  },
  {
    label: 'Kawungan, Queensland',
    value: 10004250,
  },
  {
    label: 'Kayena, Tasmania',
    value: 10006478,
  },
  {
    label: 'Kealba, Victoria',
    value: 10006708,
  },
  {
    label: 'Kealy, Western Australia',
    value: 10008999,
  },
  {
    label: 'Kearneys Spring, Queensland',
    value: 10003396,
  },
  {
    label: 'Kearns, New South Wales',
    value: 10001882,
  },
  {
    label: 'Kearsley, New South Wales',
    value: 10001028,
  },
  {
    label: 'Kebaringup, Western Australia',
    value: 10009244,
  },
  {
    label: 'Kedron, Queensland',
    value: 10002857,
  },
  {
    label: 'Keilor, Victoria',
    value: 10006736,
  },
  {
    label: 'Keilor Downs, Victoria',
    value: 10006741,
  },
  {
    label: 'Keilor East, Victoria',
    value: 10006734,
  },
  {
    label: 'Keilor North, Victoria',
    value: 10006737,
  },
  {
    label: 'Keilor Park, Victoria',
    value: 10006752,
  },
  {
    label: 'Keiraville, New South Wales',
    value: 10001646,
  },
  {
    label: 'Keith, South Australia',
    value: 10005736,
  },
  {
    label: 'Keith Hall, New South Wales',
    value: 10001559,
  },
  {
    label: 'Kellerberrin, Western Australia',
    value: 10009430,
  },
  {
    label: 'Kellevie, Tasmania',
    value: 10006315,
  },
  {
    label: 'Kellyville, New South Wales',
    value: 10000518,
  },
  {
    label: 'Kellyville Ridge, New South Wales',
    value: 10000519,
  },
  {
    label: 'Kelmscott, Western Australia',
    value: 10008635,
  },
  {
    label: 'Kelmscott, Western Australia',
    value: 10010158,
  },
  {
    label: 'Kelmscott Dc, Western Australia',
    value: 10010160,
  },
  {
    label: 'Kelsey Creek, Queensland',
    value: 10004776,
  },
  {
    label: 'Kelso, New South Wales',
    value: 10002477,
  },
  {
    label: 'Kelso, Queensland',
    value: 10004896,
  },
  {
    label: 'Kelso, Tasmania',
    value: 10006484,
  },
  {
    label: 'Kelvin, New South Wales',
    value: 10001222,
  },
  {
    label: 'Kelvin Grove, Queensland',
    value: 10002892,
  },
  {
    label: 'Kelvin Grove Bc, Queensland',
    value: 10002891,
  },
  {
    label: 'Kelvin Grove Dc, Queensland',
    value: 10002890,
  },
  {
    label: 'Kelvinhaugh, Queensland',
    value: 10003629,
  },
  {
    label: 'Kembla Grange, New South Wales',
    value: 10001686,
  },
  {
    label: 'Kemblawarra, New South Wales',
    value: 10001654,
  },
  {
    label: 'Kemmis, Queensland',
    value: 10004735,
  },
  {
    label: 'Kemps Creek, New South Wales',
    value: 10000593,
  },
  {
    label: 'Kempsey, New South Wales',
    value: 10001364,
  },
  {
    label: 'Kempton, Tasmania',
    value: 10006129,
  },
  {
    label: 'Kendall, New South Wales',
    value: 10001353,
  },
  {
    label: 'Kendenup, Western Australia',
    value: 10009153,
  },
  {
    label: 'Kenebri, New South Wales',
    value: 10001243,
  },
  {
    label: 'Kenilworth, Queensland',
    value: 10004010,
  },
  {
    label: 'Kenmare, Western Australia',
    value: 10009120,
  },
  {
    label: 'Kenmore, Queensland',
    value: 10002911,
  },
  {
    label: 'Kenmore Hills, Queensland',
    value: 10002907,
  },
  {
    label: 'Kennedy, Queensland',
    value: 10004908,
  },
  {
    label: 'Kennedy Range, Western Australia',
    value: 10009945,
  },
  {
    label: 'Kennedys Creek, Victoria',
    value: 10007138,
  },
  {
    label: 'Kennett River, Victoria',
    value: 10007087,
  },
  {
    label: 'Kennington, Victoria',
    value: 10007674,
  },
  {
    label: 'Kensington, New South Wales',
    value: 10000144,
  },
  {
    label: 'Kensington, Queensland',
    value: 10004318,
  },
  {
    label: 'Kensington, South Australia',
    value: 10005427,
  },
  {
    label: 'Kensington, Victoria',
    value: 10006728,
  },
  {
    label: 'Kensington, Western Australia',
    value: 10008671,
  },
  {
    label: 'Kensington Gardens, South Australia',
    value: 10005430,
  },
  {
    label: 'Kensington Grove, Queensland',
    value: 10003334,
  },
  {
    label: 'Kensington Park, South Australia',
    value: 10005432,
  },
  {
    label: 'Kent Town, South Australia',
    value: 10005425,
  },
  {
    label: 'Kent Town, South Australia',
    value: 10005449,
  },
  {
    label: 'Kentdale, Western Australia',
    value: 10009228,
  },
  {
    label: 'Kenthurst, New South Wales',
    value: 10000522,
  },
  {
    label: 'Kentlyn, New South Wales',
    value: 10001896,
  },
  {
    label: 'Kentucky, New South Wales',
    value: 10001167,
  },
  {
    label: 'Kenwick, Western Australia',
    value: 10008618,
  },
  {
    label: 'Keon Park, Victoria',
    value: 10006806,
  },
  {
    label: 'Keperra, Queensland',
    value: 10002883,
  },
  {
    label: 'Kepnock, Queensland',
    value: 10004296,
  },
  {
    label: 'Keppel Sands, Queensland',
    value: 10004535,
  },
  {
    label: 'Kerang, Victoria',
    value: 10007748,
  },
  {
    label: 'Kergunyah, Victoria',
    value: 10007919,
  },
  {
    label: 'Kernot, Victoria',
    value: 10008357,
  },
  {
    label: 'Kerrabee, New South Wales',
    value: 10001053,
  },
  {
    label: 'Kerrie, Victoria',
    value: 10007512,
  },
  {
    label: 'Kerrimuir, Victoria',
    value: 10006888,
  },
  {
    label: 'Kerrisdale, Victoria',
    value: 10007844,
  },
  {
    label: 'Kersbrook, South Australia',
    value: 10005678,
  },
  {
    label: 'Keswick, South Australia',
    value: 10005325,
  },
  {
    label: 'Kettering, Tasmania',
    value: 10006289,
  },
  {
    label: 'Kew, New South Wales',
    value: 10001354,
  },
  {
    label: 'Kew, Victoria',
    value: 10006850,
  },
  {
    label: 'Kew East, Victoria',
    value: 10006851,
  },
  {
    label: 'Kewarra Beach, Queensland',
    value: 10005203,
  },
  {
    label: 'Kewdale, Western Australia',
    value: 10008615,
  },
  {
    label: 'Keyneton, South Australia',
    value: 10005823,
  },
  {
    label: 'Keysborough, Victoria',
    value: 10006987,
  },
  {
    label: 'Keysbrook, Western Australia',
    value: 10008657,
  },
  {
    label: 'Keysland, Queensland',
    value: 10004091,
  },
  {
    label: 'Khancoban, New South Wales',
    value: 10002099,
  },
  {
    label: 'Kholo, Queensland',
    value: 10003283,
  },
  {
    label: 'Ki Ki, South Australia',
    value: 10005725,
  },
  {
    label: 'Kia Ora, Queensland',
    value: 10003994,
  },
  {
    label: 'Kiah, New South Wales',
    value: 10001876,
  },
  {
    label: 'Kialla, New South Wales',
    value: 10002002,
  },
  {
    label: 'Kialla, Victoria',
    value: 10007809,
  },
  {
    label: 'Kialla East, Victoria',
    value: 10007810,
  },
  {
    label: 'Kialla West, Victoria',
    value: 10007808,
  },
  {
    label: 'Kiama, New South Wales',
    value: 10001714,
  },
  {
    label: 'Kiandra, New South Wales',
    value: 10002069,
  },
  {
    label: 'Kianga, Queensland',
    value: 10004619,
  },
  {
    label: 'Kiara, Western Australia',
    value: 10008505,
  },
  {
    label: 'Kiata, Victoria',
    value: 10007495,
  },
  {
    label: 'Kidman Park, South Australia',
    value: 10005305,
  },
  {
    label: 'Kielpa, South Australia',
    value: 10006016,
  },
  {
    label: 'Kiels Mountain, Queensland',
    value: 10003917,
  },
  {
    label: 'Kiewa, Victoria',
    value: 10007903,
  },
  {
    label: 'Kilaben Bay, New South Wales',
    value: 10000857,
  },
  {
    label: 'Kilburn, South Australia',
    value: 10005481,
  },
  {
    label: 'Kilburn North, South Australia',
    value: 10005480,
  },
  {
    label: 'Kilcoy, Queensland',
    value: 10003844,
  },
  {
    label: 'Kilcunda, Victoria',
    value: 10008385,
  },
  {
    label: 'Kilkenny, South Australia',
    value: 10005255,
  },
  {
    label: 'Kilkivan, Queensland',
    value: 10004025,
  },
  {
    label: 'Killabakh, New South Wales',
    value: 10001331,
  },
  {
    label: 'Killara, New South Wales',
    value: 10000332,
  },
  {
    label: 'Killara, Victoria',
    value: 10007269,
  },
  {
    label: 'Killara, Victoria',
    value: 10007910,
  },
  {
    label: 'Killarney, Queensland',
    value: 10003574,
  },
  {
    label: 'Killarney, Victoria',
    value: 10007227,
  },
  {
    label: 'Killarney Heights, New South Wales',
    value: 10000363,
  },
  {
    label: 'Killarney Vale, New South Wales',
    value: 10000797,
  },
  {
    label: 'Killcare, New South Wales',
    value: 10000753,
  },
  {
    label: 'Killiecrankie, Tasmania',
    value: 10006423,
  },
  {
    label: 'Killingworth, New South Wales',
    value: 10000830,
  },
  {
    label: 'Killora, Tasmania',
    value: 10006272,
  },
  {
    label: 'Kilmany, Victoria',
    value: 10008165,
  },
  {
    label: 'Kilmore, Victoria',
    value: 10008045,
  },
  {
    label: 'Kilsyth, Victoria',
    value: 10006914,
  },
  {
    label: 'Kilsyth South, Victoria',
    value: 10006913,
  },
  {
    label: 'Kimba, South Australia',
    value: 10006013,
  },
  {
    label: 'Kimberley, Tasmania',
    value: 10006527,
  },
  {
    label: 'Kimbriki, New South Wales',
    value: 10001325,
  },
  {
    label: 'Kin Kin, Queensland',
    value: 10004003,
  },
  {
    label: 'Kin Kora, Queensland',
    value: 10004425,
  },
  {
    label: 'Kinbombi, Queensland',
    value: 10004031,
  },
  {
    label: 'Kinchant Dam, Queensland',
    value: 10004709,
  },
  {
    label: 'Kincora, Queensland',
    value: 10003503,
  },
  {
    label: 'Kincumber, New South Wales',
    value: 10000738,
  },
  {
    label: 'Kindon, Queensland',
    value: 10003606,
  },
  {
    label: 'Kindred, Tasmania',
    value: 10006580,
  },
  {
    label: 'King River, Western Australia',
    value: 10009191,
  },
  {
    label: 'King Scrub, Queensland',
    value: 10003868,
  },
  {
    label: 'Kingaroy, Queensland',
    value: 10004074,
  },
  {
    label: 'Kingfisher Bay, Queensland',
    value: 10004255,
  },
  {
    label: 'Kinglake, Victoria',
    value: 10008044,
  },
  {
    label: 'Kinglake West, Victoria',
    value: 10008035,
  },
  {
    label: 'Kingoonya, South Australia',
    value: 10006039,
  },
  {
    label: 'Kings Beach, Queensland',
    value: 10003884,
  },
  {
    label: 'Kings Creek, New South Wales',
    value: 10001403,
  },
  {
    label: 'Kings Creek, Queensland',
    value: 10003556,
  },
  {
    label: 'Kings Cross, New South Wales',
    value: 10000134,
  },
  {
    label: 'Kings Cross, New South Wales',
    value: 10000210,
  },
  {
    label: 'Kings Langley, New South Wales',
    value: 10000500,
  },
  {
    label: 'Kings Meadows, Tasmania',
    value: 10006382,
  },
  {
    label: 'Kings Park, New South Wales',
    value: 10000504,
  },
  {
    label: 'Kings Park, South Australia',
    value: 10005322,
  },
  {
    label: 'Kings Park, Victoria',
    value: 10006706,
  },
  {
    label: 'Kings Park, Western Australia',
    value: 10008403,
  },
  {
    label: 'Kingsbury, Victoria',
    value: 10006821,
  },
  {
    label: 'Kingscliff, New South Wales',
    value: 10001637,
  },
  {
    label: 'Kingscote, South Australia',
    value: 10005677,
  },
  {
    label: 'Kingsdale, New South Wales',
    value: 10001975,
  },
  {
    label: 'Kingsdene, New South Wales',
    value: 10000440,
  },
  {
    label: 'Kingsford, New South Wales',
    value: 10000252,
  },
  {
    label: 'Kingsford, South Australia',
    value: 10005567,
  },
  {
    label: 'Kingsford, Western Australia',
    value: 10009940,
  },
  {
    label: 'Kingsgrove, New South Wales',
    value: 10000148,
  },
  {
    label: 'Kingsgrove, New South Wales',
    value: 10000635,
  },
  {
    label: 'Kingsholme, Queensland',
    value: 10003101,
  },
  {
    label: 'Kingsley, Western Australia',
    value: 10008453,
  },
  {
    label: 'Kingsthorpe, Queensland',
    value: 10003616,
  },
  {
    label: 'Kingston, Australian Capital Territory',
    value: 10000030,
  },
  {
    label: 'Kingston, Queensland',
    value: 10002974,
  },
  {
    label: 'Kingston, Tasmania',
    value: 10006149,
  },
  {
    label: 'Kingston, Victoria',
    value: 10007423,
  },
  {
    label: 'Kingston, Western Australia',
    value: 10008924,
  },
  {
    label: 'Kingston Beach, Tasmania',
    value: 10006147,
  },
  {
    label: 'Kingston Park, South Australia',
    value: 10005374,
  },
  {
    label: 'Kingston S.E., South Australia',
    value: 10005745,
  },
  {
    label: 'Kingston-On-Murray, South Australia',
    value: 10005798,
  },
  {
    label: 'Kingstown, New South Wales',
    value: 10001178,
  },
  {
    label: 'Kingsvale, New South Wales',
    value: 10002021,
  },
  {
    label: 'Kingsville, Victoria',
    value: 10006689,
  },
  {
    label: 'Kingsway, Western Australia',
    value: 10008557,
  },
  {
    label: 'Kingsway West, New South Wales',
    value: 10000634,
  },
  {
    label: 'Kingswood, New South Wales',
    value: 10001120,
  },
  {
    label: 'Kingswood, New South Wales',
    value: 10001860,
  },
  {
    label: 'Kingswood, New South Wales',
    value: 10002303,
  },
  {
    label: 'Kingswood, South Australia',
    value: 10005396,
  },
  {
    label: 'Kinkuna, Queensland',
    value: 10004314,
  },
  {
    label: 'Kinross, Western Australia',
    value: 10008465,
  },
  {
    label: 'Kintore, Northern Territory',
    value: 10002790,
  },
  {
    label: 'Kioloa, New South Wales',
    value: 10001781,
  },
  {
    label: 'Kioma, Queensland',
    value: 10003796,
  },
  {
    label: 'Kiora, New South Wales',
    value: 10001769,
  },
  {
    label: 'Kippa-Ring, Queensland',
    value: 10002846,
  },
  {
    label: 'Kippax, Australian Capital Territory',
    value: 10000078,
  },
  {
    label: 'Kippax Centre, Australian Capital Territory',
    value: 10000079,
  },
  {
    label: 'Kirk Rock, Western Australia',
    value: 10009326,
  },
  {
    label: 'Kirknie, Queensland',
    value: 10004827,
  },
  {
    label: 'Kirkstall, Victoria',
    value: 10007228,
  },
  {
    label: 'Kirkwood, Queensland',
    value: 10004405,
  },
  {
    label: 'Kirrama, Queensland',
    value: 10005178,
  },
  {
    label: 'Kirrawee, New South Wales',
    value: 10000697,
  },
  {
    label: 'Kirribilli, New South Wales',
    value: 10000304,
  },
  {
    label: 'Kirup, Western Australia',
    value: 10008905,
  },
  {
    label: 'Kirwan, Queensland',
    value: 10004925,
  },
  {
    label: 'Kitchener, New South Wales',
    value: 10001043,
  },
  {
    label: 'Kithbrook, Victoria',
    value: 10007853,
  },
  {
    label: 'Kitoba, Queensland',
    value: 10004045,
  },
  {
    label: 'Kleinton, Queensland',
    value: 10003468,
  },
  {
    label: 'Klemzig, South Australia',
    value: 10005495,
  },
  {
    label: 'Knorrit Flat, New South Wales',
    value: 10001300,
  },
  {
    label: 'Knowsley, Victoria',
    value: 10007651,
  },
  {
    label: 'Knox City Centre, Victoria',
    value: 10006948,
  },
  {
    label: 'Knoxfield, Victoria',
    value: 10006997,
  },
  {
    label: 'Koetong, Victoria',
    value: 10007939,
  },
  {
    label: 'Kogan, Queensland',
    value: 10003653,
  },
  {
    label: 'Kogarah, New South Wales',
    value: 10000150,
  },
  {
    label: 'Kogarah, New South Wales',
    value: 10000658,
  },
  {
    label: 'Kogarah Bay, New South Wales',
    value: 10000655,
  },
  {
    label: 'Kojaneerup South, Western Australia',
    value: 10009165,
  },
  {
    label: 'Kojarena, Western Australia',
    value: 10009803,
  },
  {
    label: 'Kojonup, Western Australia',
    value: 10009389,
  },
  {
    label: 'Kokeby, Western Australia',
    value: 10009064,
  },
  {
    label: 'Kokotungo, Queensland',
    value: 10004507,
  },
  {
    label: 'Kolodong, New South Wales',
    value: 10001338,
  },
  {
    label: 'Kolonga, Queensland',
    value: 10004348,
  },
  {
    label: 'Koloona, New South Wales',
    value: 10001254,
  },
  {
    label: 'Kolora, Victoria',
    value: 10007188,
  },
  {
    label: 'Kondinin, Western Australia',
    value: 10009316,
  },
  {
    label: 'Kondut, Western Australia',
    value: 10009872,
  },
  {
    label: 'Kongorong, South Australia',
    value: 10005763,
  },
  {
    label: 'Kongwak, Victoria',
    value: 10008302,
  },
  {
    label: 'Konnongorring, Western Australia',
    value: 10009868,
  },
  {
    label: 'Koo Wee Rup, Victoria',
    value: 10008364,
  },
  {
    label: 'Koojan, Western Australia',
    value: 10009689,
  },
  {
    label: 'Kookynie, Western Australia',
    value: 10009508,
  },
  {
    label: 'Koolan Island, Western Australia',
    value: 10010028,
  },
  {
    label: 'Koolanooka, Western Australia',
    value: 10009893,
  },
  {
    label: 'Koolewong, New South Wales',
    value: 10000748,
  },
  {
    label: 'Koolunga, South Australia',
    value: 10005888,
  },
  {
    label: 'Koolyanobbing, Western Australia',
    value: 10009475,
  },
  {
    label: 'Koomberkine, Western Australia',
    value: 10009587,
  },
  {
    label: 'Koombooloomba, Queensland',
    value: 10005171,
  },
  {
    label: 'Koonawarra, New South Wales',
    value: 10001705,
  },
  {
    label: 'Koonda, Victoria',
    value: 10007863,
  },
  {
    label: 'Koondoola, Western Australia',
    value: 10008548,
  },
  {
    label: 'Koondrook, Victoria',
    value: 10007750,
  },
  {
    label: 'Koongal, Queensland',
    value: 10004458,
  },
  {
    label: 'Koongamia, Western Australia',
    value: 10008527,
  },
  {
    label: 'Koonwarra, Victoria',
    value: 10008309,
  },
  {
    label: 'Koonya, Tasmania',
    value: 10006326,
  },
  {
    label: 'Kooragang, New South Wales',
    value: 10000929,
  },
  {
    label: 'Koorainghat, New South Wales',
    value: 10001344,
  },
  {
    label: 'Kooralbyn, Queensland',
    value: 10003198,
  },
  {
    label: 'Kooralgin, Queensland',
    value: 10003631,
  },
  {
    label: 'Koorawatha, New South Wales',
    value: 10002512,
  },
  {
    label: 'Koorda, Western Australia',
    value: 10009609,
  },
  {
    label: 'Kooringal, New South Wales',
    value: 10002131,
  },
  {
    label: 'Kooringal, Queensland',
    value: 10002850,
  },
  {
    label: 'Koorlong, Victoria',
    value: 10007622,
  },
  {
    label: 'Koornalla, Victoria',
    value: 10008153,
  },
  {
    label: 'Kooroona, South Australia',
    value: 10005943,
  },
  {
    label: 'Kooroongarra, Queensland',
    value: 10003526,
  },
  {
    label: 'Kootingal, New South Wales',
    value: 10001160,
  },
  {
    label: 'Kooyong, Victoria',
    value: 10006928,
  },
  {
    label: 'Koraleigh, New South Wales',
    value: 10002287,
  },
  {
    label: 'Korbel, Western Australia',
    value: 10009440,
  },
  {
    label: 'Kordabup, Western Australia',
    value: 10009235,
  },
  {
    label: 'Koriella, Victoria',
    value: 10007958,
  },
  {
    label: 'Korobeit, Victoria',
    value: 10007320,
  },
  {
    label: 'Koroit, Victoria',
    value: 10007226,
  },
  {
    label: 'Korong Vale, Victoria',
    value: 10007646,
  },
  {
    label: 'Korora, New South Wales',
    value: 10001427,
  },
  {
    label: 'Korrelocking, Western Australia',
    value: 10009634,
  },
  {
    label: 'Korumburra, Victoria',
    value: 10008300,
  },
  {
    label: 'Korweinguboora, Victoria',
    value: 10007556,
  },
  {
    label: 'Kotara, New South Wales',
    value: 10000884,
  },
  {
    label: 'Kotara East, New South Wales',
    value: 10000933,
  },
  {
    label: 'Kotara Fair, New South Wales',
    value: 10000886,
  },
  {
    label: 'Kotta, Victoria',
    value: 10007727,
  },
  {
    label: 'Kotupna, Victoria',
    value: 10007824,
  },
  {
    label: 'Koumala, Queensland',
    value: 10004661,
  },
  {
    label: 'Kowanyama, Queensland',
    value: 10005149,
  },
  {
    label: 'Kowguran, Queensland',
    value: 10003668,
  },
  {
    label: 'Koyuga, Victoria',
    value: 10007790,
  },
  {
    label: 'Koyuga South, Victoria',
    value: 10007787,
  },
  {
    label: 'Krambach, New South Wales',
    value: 10001326,
  },
  {
    label: 'Kronkup, Western Australia',
    value: 10009173,
  },
  {
    label: 'Krowera, Victoria',
    value: 10008297,
  },
  {
    label: 'Kudardup, Western Australia',
    value: 10009037,
  },
  {
    label: 'Kudla, South Australia',
    value: 10005557,
  },
  {
    label: 'Kuender, Western Australia',
    value: 10009287,
  },
  {
    label: 'Kukerin, Western Australia',
    value: 10009280,
  },
  {
    label: 'Kulikup, Western Australia',
    value: 10008901,
  },
  {
    label: 'Kulin, Western Australia',
    value: 10009313,
  },
  {
    label: 'Kulin West, Western Australia',
    value: 10009312,
  },
  {
    label: 'Kulja, Western Australia',
    value: 10009598,
  },
  {
    label: 'Kullogum, Queensland',
    value: 10004290,
  },
  {
    label: 'Kulnura, New South Wales',
    value: 10000718,
  },
  {
    label: 'Kulpi, Queensland',
    value: 10003429,
  },
  {
    label: 'Kumarina, Western Australia',
    value: 10009924,
  },
  {
    label: 'Kumbia, Queensland',
    value: 10004082,
  },
  {
    label: 'Kunda Park, Queensland',
    value: 10003907,
  },
  {
    label: 'Kundabung, New South Wales',
    value: 10001385,
  },
  {
    label: 'Kunghur, New South Wales',
    value: 10001624,
  },
  {
    label: 'Kunioon, Queensland',
    value: 10004105,
  },
  {
    label: 'Kunjin, Western Australia',
    value: 10009334,
  },
  {
    label: 'Kununoppin, Western Australia',
    value: 10009642,
  },
  {
    label: 'Kununurra, Western Australia',
    value: 10010035,
  },
  {
    label: 'Kunyung, Victoria',
    value: 10008275,
  },
  {
    label: 'Kuraby, Queensland',
    value: 10002970,
  },
  {
    label: 'Kuranda, Queensland',
    value: 10005207,
  },
  {
    label: 'Kurmond, New South Wales',
    value: 10002342,
  },
  {
    label: 'Kurnalpi, Western Australia',
    value: 10009505,
  },
  {
    label: 'Kurnell, New South Wales',
    value: 10000693,
  },
  {
    label: 'Kurrajong, New South Wales',
    value: 10002343,
  },
  {
    label: 'Kurralta Park, South Australia',
    value: 10005333,
  },
  {
    label: 'Kurrenkutten, Western Australia',
    value: 10009333,
  },
  {
    label: 'Kurri Kurri, New South Wales',
    value: 10001050,
  },
  {
    label: 'Kurrimine Beach, Queensland',
    value: 10005126,
  },
  {
    label: 'Kurrowah, Queensland',
    value: 10003436,
  },
  {
    label: 'Kurumbul, Queensland',
    value: 10003605,
  },
  {
    label: 'Kurunjang, Victoria',
    value: 10007308,
  },
  {
    label: 'Kurwongbah, Queensland',
    value: 10003812,
  },
  {
    label: 'Kuttabul, Queensland',
    value: 10004712,
  },
  {
    label: 'Kweda, Western Australia',
    value: 10009074,
  },
  {
    label: 'Kwelkan, Western Australia',
    value: 10009648,
  },
  {
    label: 'Kwinana, Western Australia',
    value: 10010142,
  },
  {
    label: 'Kwinana Beach, Western Australia',
    value: 10008735,
  },
  {
    label: 'Kwinana Town Centre, Western Australia',
    value: 10008727,
  },
  {
    label: 'Kwolyin, Western Australia',
    value: 10009349,
  },
  {
    label: 'Kyabram, Victoria',
    value: 10007782,
  },
  {
    label: 'Kyabram, Victoria',
    value: 10007785,
  },
  {
    label: 'Kyabram South, Victoria',
    value: 10007783,
  },
  {
    label: 'Kyalite, New South Wales',
    value: 10002286,
  },
  {
    label: 'Kyancutta, South Australia',
    value: 10006018,
  },
  {
    label: 'Kybybolite, South Australia',
    value: 10005731,
  },
  {
    label: 'Kyeemagh, New South Wales',
    value: 10000650,
  },
  {
    label: 'Kyle Bay, New South Wales',
    value: 10000667,
  },
  {
    label: 'Kyneton, Victoria',
    value: 10007526,
  },
  {
    label: 'Kynuna, Queensland',
    value: 10004960,
  },
  {
    label: 'Kyogle, New South Wales',
    value: 10001535,
  },
  {
    label: 'Kyoomba, Queensland',
    value: 10003580,
  },
  {
    label: 'La Perouse, New South Wales',
    value: 10000266,
  },
  {
    label: 'Laanecoorie, Victoria',
    value: 10007566,
  },
  {
    label: 'Labertouche, Victoria',
    value: 10008110,
  },
  {
    label: 'Labrador, Queensland',
    value: 10003146,
  },
  {
    label: 'Laburnum, Victoria',
    value: 10006892,
  },
  {
    label: 'Laceys Creek, Queensland',
    value: 10003870,
  },
  {
    label: 'Lachlan, Tasmania',
    value: 10006257,
  },
  {
    label: 'Lackrana, Tasmania',
    value: 10006425,
  },
  {
    label: 'Lady Barron, Tasmania',
    value: 10006430,
  },
  {
    label: 'Ladysmith, New South Wales',
    value: 10002148,
  },
  {
    label: 'Laggan, New South Wales',
    value: 10002010,
  },
  {
    label: 'Lagrange, Western Australia',
    value: 10010016,
  },
  {
    label: 'Laguna, New South Wales',
    value: 10001044,
  },
  {
    label: 'Laguna Quays, Queensland',
    value: 10004789,
  },
  {
    label: 'Laidley, Queensland',
    value: 10003333,
  },
  {
    label: 'Laidley Creek West, Queensland',
    value: 10003330,
  },
  {
    label: 'Laidley Heights, Queensland',
    value: 10003332,
  },
  {
    label: 'Laidley North, Queensland',
    value: 10003336,
  },
  {
    label: 'Lajamanu, Northern Territory',
    value: 10002764,
  },
  {
    label: 'Lake Albert, New South Wales',
    value: 10002142,
  },
  {
    label: 'Lake Argyle, Western Australia',
    value: 10010038,
  },
  {
    label: 'Lake Austin, Western Australia',
    value: 10009922,
  },
  {
    label: 'Lake Bathurst, New South Wales',
    value: 10001983,
  },
  {
    label: 'Lake Biddy, Western Australia',
    value: 10009296,
  },
  {
    label: 'Lake Boga, Victoria',
    value: 10007753,
  },
  {
    label: 'Lake Bolac, Victoria',
    value: 10007356,
  },
  {
    label: 'Lake Borumba, Queensland',
    value: 10003965,
  },
  {
    label: 'Lake Brown, Western Australia',
    value: 10009618,
  },
  {
    label: 'Lake Camm, Western Australia',
    value: 10009300,
  },
  {
    label: 'Lake Cargelligo, New South Wales',
    value: 10002206,
  },
  {
    label: 'Lake Carnegie, Western Australia',
    value: 10009930,
  },
  {
    label: 'Lake Cathie, New South Wales',
    value: 10001400,
  },
  {
    label: 'Lake Charm, Victoria',
    value: 10007751,
  },
  {
    label: 'Lake Clarendon, Queensland',
    value: 10003359,
  },
  {
    label: 'Lake Clifton, Western Australia',
    value: 10008807,
  },
  {
    label: 'Lake Conjola, New South Wales',
    value: 10001782,
  },
  {
    label: 'Lake Darlot, Western Australia',
    value: 10009527,
  },
  {
    label: 'Lake Deborah, Western Australia',
    value: 10009629,
  },
  {
    label: 'Lake Grace, Western Australia',
    value: 10009288,
  },
  {
    label: 'Lake Haven, New South Wales',
    value: 10000811,
  },
  {
    label: 'Lake Heights, New South Wales',
    value: 10001651,
  },
  {
    label: 'Lake Hinds, Western Australia',
    value: 10009867,
  },
  {
    label: 'Lake Illawarra, New South Wales',
    value: 10001693,
  },
  {
    label: 'Lake Jasper, Western Australia',
    value: 10008953,
  },
  {
    label: 'Lake King, Western Australia',
    value: 10009303,
  },
  {
    label: 'Lake Leake, Tasmania',
    value: 10006338,
  },
  {
    label: 'Lake Macdonald, Queensland',
    value: 10003929,
  },
  {
    label: 'Lake Manchester, Queensland',
    value: 10003269,
  },
  {
    label: 'Lake Margarette, Western Australia',
    value: 10009610,
  },
  {
    label: 'Lake Mary, Queensland',
    value: 10004561,
  },
  {
    label: 'Lake Monduran, Queensland',
    value: 10004350,
  },
  {
    label: 'Lake Muir, Western Australia',
    value: 10008942,
  },
  {
    label: 'Lake Mundi, Victoria',
    value: 10007272,
  },
  {
    label: 'Lake Munmorah, New South Wales',
    value: 10000765,
  },
  {
    label: 'Lake Ninan, Western Australia',
    value: 10009871,
  },
  {
    label: 'Lake Proserpine, Queensland',
    value: 10004792,
  },
  {
    label: 'Lake Rowan, Victoria',
    value: 10007989,
  },
  {
    label: 'Lake St Clair, Tasmania',
    value: 10006238,
  },
  {
    label: 'Lake Tabourie, New South Wales',
    value: 10001778,
  },
  {
    label: 'Lake Tinaroo, Queensland',
    value: 10005169,
  },
  {
    label: 'Lake Toolbrunup, Western Australia',
    value: 10009150,
  },
  {
    label: 'Lake Tyers, Victoria',
    value: 10008223,
  },
  {
    label: 'Lake Tyers Beach, Victoria',
    value: 10008247,
  },
  {
    label: 'Lake View, South Australia',
    value: 10005938,
  },
  {
    label: 'Lake Wells, Western Australia',
    value: 10009529,
  },
  {
    label: 'Lake Wendouree, Victoria',
    value: 10007339,
  },
  {
    label: 'Lake Wyangan, New South Wales',
    value: 10002213,
  },
  {
    label: 'Lakelands, New South Wales',
    value: 10000847,
  },
  {
    label: 'Lakelands, Western Australia',
    value: 10008779,
  },
  {
    label: 'Lakemba, New South Wales',
    value: 10000605,
  },
  {
    label: 'Lakes Entrance, Victoria',
    value: 10008248,
  },
  {
    label: 'Lakesland, New South Wales',
    value: 10001941,
  },
  {
    label: 'Lakewood, Western Australia',
    value: 10009501,
  },
  {
    label: 'Lal Lal, Victoria',
    value: 10007373,
  },
  {
    label: 'Lalbert, Victoria',
    value: 10007665,
  },
  {
    label: 'Lalla, Tasmania',
    value: 10006469,
  },
  {
    label: 'Lalor, Victoria',
    value: 10006809,
  },
  {
    label: 'Lalor Park, New South Wales',
    value: 10000498,
  },
  {
    label: 'Lamb Island, Queensland',
    value: 10003077,
  },
  {
    label: 'Lambton, New South Wales',
    value: 10000914,
  },
  {
    label: 'Lameroo, South Australia',
    value: 10005779,
  },
  {
    label: 'Lamington, Western Australia',
    value: 10009497,
  },
  {
    label: 'Lamington National Park, Queensland',
    value: 10003193,
  },
  {
    label: 'Lammermoor, Queensland',
    value: 10004542,
  },
  {
    label: 'Lancaster, Victoria',
    value: 10007784,
  },
  {
    label: 'Lancefield, Victoria',
    value: 10007514,
  },
  {
    label: 'Lancelin, Western Australia',
    value: 10008492,
  },
  {
    label: 'Landsborough, Queensland',
    value: 10003875,
  },
  {
    label: 'Landsborough, Victoria',
    value: 10007453,
  },
  {
    label: 'Landsdale, Western Australia',
    value: 10008562,
  },
  {
    label: 'Lane Cove, New South Wales',
    value: 10000160,
  },
  {
    label: 'Lane Cove, New South Wales',
    value: 10000321,
  },
  {
    label: 'Lane Cove North, New South Wales',
    value: 10000315,
  },
  {
    label: 'Lane Cove West, New South Wales',
    value: 10000317,
  },
  {
    label: 'Lanefield, Queensland',
    value: 10003324,
  },
  {
    label: 'Lanena, Tasmania',
    value: 10006491,
  },
  {
    label: 'Lang Lang, Victoria',
    value: 10008368,
  },
  {
    label: 'Lange, Western Australia',
    value: 10009208,
  },
  {
    label: 'Langford, Western Australia',
    value: 10008659,
  },
  {
    label: 'Langhorne Creek, South Australia',
    value: 10005714,
  },
  {
    label: 'Langkoop, Victoria',
    value: 10007282,
  },
  {
    label: 'Langley, Queensland',
    value: 10004209,
  },
  {
    label: 'Langley Vale, New South Wales',
    value: 10001308,
  },
  {
    label: 'Langwarrin, Victoria',
    value: 10008250,
  },
  {
    label: 'Langwarrin South, Victoria',
    value: 10008251,
  },
  {
    label: 'Lansdowne, Queensland',
    value: 10003760,
  },
  {
    label: 'Lansvale, New South Wales',
    value: 10000549,
  },
  {
    label: 'Lapoinya, Tasmania',
    value: 10006635,
  },
  {
    label: 'Lapstone, New South Wales',
    value: 10002387,
  },
  {
    label: 'Lara, Victoria',
    value: 10007060,
  },
  {
    label: 'Larapinta, Queensland',
    value: 10002963,
  },
  {
    label: 'Largs, New South Wales',
    value: 10000966,
  },
  {
    label: 'Largs Bay, South Australia',
    value: 10005286,
  },
  {
    label: 'Largs North, South Australia',
    value: 10005285,
  },
  {
    label: 'Larpent, Victoria',
    value: 10007165,
  },
  {
    label: 'Larrakeyah, Northern Territory',
    value: 10002709,
  },
  {
    label: 'Larras Lee, New South Wales',
    value: 10002626,
  },
  {
    label: 'Larrimah, Northern Territory',
    value: 10002768,
  },
  {
    label: 'Lascelles, Victoria',
    value: 10007605,
  },
  {
    label: 'Latham, Australian Capital Territory',
    value: 10000077,
  },
  {
    label: 'Latham, Western Australia',
    value: 10009888,
  },
  {
    label: 'Lathlain, Western Australia',
    value: 10008605,
  },
  {
    label: 'Latrobe, Tasmania',
    value: 10006565,
  },
  {
    label: 'Lauderdale, Tasmania',
    value: 10006116,
  },
  {
    label: 'Laughtondale, New South Wales',
    value: 10002395,
  },
  {
    label: 'Launceston, Tasmania',
    value: 10006389,
  },
  {
    label: 'Launching Place, Victoria',
    value: 10006916,
  },
  {
    label: 'Laura, Queensland',
    value: 10005142,
  },
  {
    label: 'Laura, South Australia',
    value: 10005895,
  },
  {
    label: 'Laurel Hill, New South Wales',
    value: 10002130,
  },
  {
    label: 'Laurieton, New South Wales',
    value: 10001395,
  },
  {
    label: 'Lavelle, Queensland',
    value: 10003529,
  },
  {
    label: 'Lavender Bay, New South Wales',
    value: 10000298,
  },
  {
    label: 'Lavers Hill, Victoria',
    value: 10007134,
  },
  {
    label: 'Laverton, Victoria',
    value: 10006719,
  },
  {
    label: 'Laverton, Western Australia',
    value: 10009528,
  },
  {
    label: 'Laverton North, Victoria',
    value: 10006718,
  },
  {
    label: 'Lavington, New South Wales',
    value: 10002097,
  },
  {
    label: 'Lavington Dc, New South Wales',
    value: 10002091,
  },
  {
    label: 'Law Courts, Victoria',
    value: 10008397,
  },
  {
    label: 'Lawes, Queensland',
    value: 10003344,
  },
  {
    label: 'Lawgi Dawes, Queensland',
    value: 10004609,
  },
  {
    label: 'Lawloit, Victoria',
    value: 10007499,
  },
  {
    label: 'Lawnton, Queensland',
    value: 10003805,
  },
  {
    label: 'Lawrence, New South Wales',
    value: 10001474,
  },
  {
    label: 'Lawson, Australian Capital Territory',
    value: 10000089,
  },
  {
    label: 'Lawson, New South Wales',
    value: 10002410,
  },
  {
    label: 'Leabrook, South Australia',
    value: 10005433,
  },
  {
    label: 'Leadville, New South Wales',
    value: 10002593,
  },
  {
    label: 'Leafdale, Queensland',
    value: 10004060,
  },
  {
    label: 'Leanyer, Northern Territory',
    value: 10002695,
  },
  {
    label: 'Learmonth, Victoria',
    value: 10007387,
  },
  {
    label: 'Learmonth, Western Australia',
    value: 10009959,
  },
  {
    label: 'Leawood Gardens, South Australia',
    value: 10005595,
  },
  {
    label: 'Lebrina, Tasmania',
    value: 10006419,
  },
  {
    label: 'Leda, Western Australia',
    value: 10008750,
  },
  {
    label: 'Ledge Point, Western Australia',
    value: 10008490,
  },
  {
    label: 'Lee Point, Northern Territory',
    value: 10002677,
  },
  {
    label: 'Leederville, Western Australia',
    value: 10008406,
  },
  {
    label: 'Leederville, Western Australia',
    value: 10010088,
  },
  {
    label: 'Leederville, Western Australia',
    value: 10010089,
  },
  {
    label: 'Leeman, Western Australia',
    value: 10009697,
  },
  {
    label: 'Leeming, Western Australia',
    value: 10008665,
  },
  {
    label: 'Leeton, New South Wales',
    value: 10002232,
  },
  {
    label: 'Leeuwin, Western Australia',
    value: 10009039,
  },
  {
    label: 'Leeville, New South Wales',
    value: 10001514,
  },
  {
    label: 'Lefroy, Tasmania',
    value: 10006403,
  },
  {
    label: 'Lefthand Branch, Queensland',
    value: 10003348,
  },
  {
    label: 'Legana, Tasmania',
    value: 10006498,
  },
  {
    label: 'Legerwood, Tasmania',
    value: 10006458,
  },
  {
    label: 'Legume, New South Wales',
    value: 10001544,
  },
  {
    label: 'Leichardt, Victoria',
    value: 10007633,
  },
  {
    label: 'Leichhardt, New South Wales',
    value: 10000271,
  },
  {
    label: 'Leichhardt, Queensland',
    value: 10003229,
  },
  {
    label: 'Leigh Creek, South Australia',
    value: 10006056,
  },
  {
    label: 'Leigh Creek, Victoria',
    value: 10007397,
  },
  {
    label: 'Leinster, Western Australia',
    value: 10009525,
  },
  {
    label: 'Leitchville, Victoria',
    value: 10007729,
  },
  {
    label: 'Leith, Tasmania',
    value: 10006597,
  },
  {
    label: 'Lemnos, Victoria',
    value: 10007806,
  },
  {
    label: 'Lemon Tree, New South Wales',
    value: 10000763,
  },
  {
    label: 'Lemon Tree Passage, New South Wales',
    value: 10000963,
  },
  {
    label: 'Lemont, Tasmania',
    value: 10006224,
  },
  {
    label: 'Lemontree, Queensland',
    value: 10003523,
  },
  {
    label: 'Lenaghan, New South Wales',
    value: 10000988,
  },
  {
    label: 'Lenah Valley, Tasmania',
    value: 10006079,
  },
  {
    label: 'Leneva, Victoria',
    value: 10007911,
  },
  {
    label: 'Lennard Brook, Western Australia',
    value: 10009658,
  },
  {
    label: 'Lennox Head, New South Wales',
    value: 10001554,
  },
  {
    label: 'Lenswood, South Australia',
    value: 10005691,
  },
  {
    label: 'Leonay, New South Wales',
    value: 10002311,
  },
  {
    label: 'Leongatha, Victoria',
    value: 10008306,
  },
  {
    label: 'Leonora, Western Australia',
    value: 10009526,
  },
  {
    label: 'Leopold, Victoria',
    value: 10007112,
  },
  {
    label: 'Leppington, New South Wales',
    value: 10000594,
  },
  {
    label: 'Lerderderg, Victoria',
    value: 10007546,
  },
  {
    label: 'Leschenault, Western Australia',
    value: 10008865,
  },
  {
    label: 'Leslie Vale, Tasmania',
    value: 10006158,
  },
  {
    label: 'Lesmurdie, Western Australia',
    value: 10008591,
  },
  {
    label: 'Lethbridge, Victoria',
    value: 10007302,
  },
  {
    label: 'Lethbridge Park, New South Wales',
    value: 10002384,
  },
  {
    label: 'Lethebrook, Queensland',
    value: 10004788,
  },
  {
    label: 'Leumeah, New South Wales',
    value: 10001897,
  },
  {
    label: 'Leura, New South Wales',
    value: 10002406,
  },
  {
    label: 'Levendale, Tasmania',
    value: 10006216,
  },
  {
    label: 'Lewisham, New South Wales',
    value: 10000291,
  },
  {
    label: 'Lewisham, Tasmania',
    value: 10006307,
  },
  {
    label: 'Lewiston, South Australia',
    value: 10005912,
  },
  {
    label: 'Lexia, Western Australia',
    value: 10008566,
  },
  {
    label: 'Lexton, Victoria',
    value: 10007371,
  },
  {
    label: 'Leyburn, Queensland',
    value: 10003568,
  },
  {
    label: 'Leydens Hill, Queensland',
    value: 10004597,
  },
  {
    label: 'Licola, Victoria',
    value: 10008176,
  },
  {
    label: 'Lidcombe, New South Wales',
    value: 10000183,
  },
  {
    label: 'Lidcombe, New South Wales',
    value: 10000478,
  },
  {
    label: 'Liddell, New South Wales',
    value: 10001087,
  },
  {
    label: 'Lidsdale, New South Wales',
    value: 10002435,
  },
  {
    label: 'Lietinna, Tasmania',
    value: 10006445,
  },
  {
    label: 'Liffey, Tasmania',
    value: 10006513,
  },
  {
    label: 'Lightning Ridge, New South Wales',
    value: 10002573,
  },
  {
    label: 'Lileah, Tasmania',
    value: 10006665,
  },
  {
    label: 'Lilli Pilli, New South Wales',
    value: 10000686,
  },
  {
    label: 'Lilli Pilli, New South Wales',
    value: 10001741,
  },
  {
    label: 'Lillico, Tasmania',
    value: 10006577,
  },
  {
    label: 'Lillimur, Victoria',
    value: 10007502,
  },
  {
    label: 'Lilliput, Victoria',
    value: 10007889,
  },
  {
    label: 'Lilydale, Queensland',
    value: 10003369,
  },
  {
    label: 'Lilydale, Tasmania',
    value: 10006475,
  },
  {
    label: 'Lilydale, Victoria',
    value: 10006922,
  },
  {
    label: 'Lilyfield, New South Wales',
    value: 10000270,
  },
  {
    label: 'Lilyvale, Queensland',
    value: 10003440,
  },
  {
    label: 'Lima, Victoria',
    value: 10007870,
  },
  {
    label: 'Limbri, New South Wales',
    value: 10001159,
  },
  {
    label: 'Lime Lake, Western Australia',
    value: 10009112,
  },
  {
    label: 'Limeburners Creek, New South Wales',
    value: 10001004,
  },
  {
    label: 'Limerick, New South Wales',
    value: 10002001,
  },
  {
    label: 'Limestone, Queensland',
    value: 10004591,
  },
  {
    label: 'Limestone Creek, Queensland',
    value: 10004462,
  },
  {
    label: 'Limestone Ridges, Queensland',
    value: 10003233,
  },
  {
    label: 'Limevale, Queensland',
    value: 10003585,
  },
  {
    label: 'Lindeman Island, Queensland',
    value: 10004711,
  },
  {
    label: 'Linden, New South Wales',
    value: 10002404,
  },
  {
    label: 'Linden, Queensland',
    value: 10003775,
  },
  {
    label: 'Linden Park, South Australia',
    value: 10005413,
  },
  {
    label: 'Lindenow, Victoria',
    value: 10008187,
  },
  {
    label: 'Lindenow South, Victoria',
    value: 10008201,
  },
  {
    label: 'Lindfield, New South Wales',
    value: 10000330,
  },
  {
    label: 'Lindfield West, New South Wales',
    value: 10000329,
  },
  {
    label: 'Lindisfarne, Tasmania',
    value: 10006096,
  },
  {
    label: 'Linfarne, Western Australia',
    value: 10008940,
  },
  {
    label: 'Linley Point, New South Wales',
    value: 10000319,
  },
  {
    label: 'Linthorpe, Queensland',
    value: 10003497,
  },
  {
    label: 'Linton, Victoria',
    value: 10007412,
  },
  {
    label: 'Linville, Queensland',
    value: 10003248,
  },
  {
    label: 'Linwood, South Australia',
    value: 10005852,
  },
  {
    label: 'Lisarow, New South Wales',
    value: 10000723,
  },
  {
    label: 'Lisle, Tasmania',
    value: 10006447,
  },
  {
    label: 'Lismore, New South Wales',
    value: 10001572,
  },
  {
    label: 'Lismore, Victoria',
    value: 10007289,
  },
  {
    label: 'Lissner, Queensland',
    value: 10004949,
  },
  {
    label: 'Liston, New South Wales',
    value: 10001215,
  },
  {
    label: 'Litchfield, Victoria',
    value: 10007598,
  },
  {
    label: 'Lithgow, New South Wales',
    value: 10002433,
  },
  {
    label: 'Littabella, Queensland',
    value: 10004373,
  },
  {
    label: 'Little Bay, New South Wales',
    value: 10000263,
  },
  {
    label: 'Little Grove, Western Australia',
    value: 10009206,
  },
  {
    label: 'Little Hartley, New South Wales',
    value: 10002428,
  },
  {
    label: 'Little Italy, Western Australia',
    value: 10009299,
  },
  {
    label: 'Little Mountain, Queensland',
    value: 10003887,
  },
  {
    label: 'Little Plain, New South Wales',
    value: 10001184,
  },
  {
    label: 'Little River, Victoria',
    value: 10007059,
  },
  {
    label: 'Little Sandy Desert, Western Australia',
    value: 10009929,
  },
  {
    label: 'Little Swanport, Tasmania',
    value: 10006330,
  },
  {
    label: 'Littlehampton, South Australia',
    value: 10005700,
  },
  {
    label: 'Liverpool, New South Wales',
    value: 10000187,
  },
  {
    label: 'Liverpool, New South Wales',
    value: 10000566,
  },
  {
    label: 'Liverpool South, New South Wales',
    value: 10000562,
  },
  {
    label: 'Llandilo, New South Wales',
    value: 10002302,
  },
  {
    label: 'Llanelly, Victoria',
    value: 10007690,
  },
  {
    label: 'Llangothlin, New South Wales',
    value: 10001192,
  },
  {
    label: 'Lobethal, South Australia',
    value: 10005692,
  },
  {
    label: 'Loccota, Tasmania',
    value: 10006422,
  },
  {
    label: 'Loch, Victoria',
    value: 10008298,
  },
  {
    label: 'Loch Sport, Victoria',
    value: 10008166,
  },
  {
    label: 'Lochiel, South Australia',
    value: 10005916,
  },
  {
    label: 'Lochinvar, New South Wales',
    value: 10000985,
  },
  {
    label: 'Lock, South Australia',
    value: 10006010,
  },
  {
    label: 'Lockhart, New South Wales',
    value: 10002167,
  },
  {
    label: 'Lockhart, Queensland',
    value: 10005148,
  },
  {
    label: 'Lockier, Western Australia',
    value: 10009720,
  },
  {
    label: 'Lockington, Victoria',
    value: 10007722,
  },
  {
    label: 'Lockleys, South Australia',
    value: 10005312,
  },
  {
    label: 'Lockridge, Western Australia',
    value: 10008507,
  },
  {
    label: 'Locksley, New South Wales',
    value: 10002458,
  },
  {
    label: 'Locksley, Victoria',
    value: 10007852,
  },
  {
    label: 'Lockwood South, Victoria',
    value: 10007687,
  },
  {
    label: 'Lockyer, Queensland',
    value: 10003374,
  },
  {
    label: 'Lockyer, Western Australia',
    value: 10009201,
  },
  {
    label: 'Lockyer Waters, Queensland',
    value: 10003300,
  },
  {
    label: 'Loftus, New South Wales',
    value: 10000698,
  },
  {
    label: 'Logan, Victoria',
    value: 10007592,
  },
  {
    label: 'Logan Central, Queensland',
    value: 10002975,
  },
  {
    label: 'Logan City Dc, Queensland',
    value: 10002973,
  },
  {
    label: 'Logan Reserve, Queensland',
    value: 10003021,
  },
  {
    label: 'Logan Village, Queensland',
    value: 10003099,
  },
  {
    label: 'Loganholme, Queensland',
    value: 10003013,
  },
  {
    label: 'Loganlea, Queensland',
    value: 10003017,
  },
  {
    label: 'Loira, Tasmania',
    value: 10006493,
  },
  {
    label: 'Lol Gray, Western Australia',
    value: 10009093,
  },
  {
    label: 'Londonderry, New South Wales',
    value: 10002316,
  },
  {
    label: 'Londonderry, Western Australia',
    value: 10009478,
  },
  {
    label: 'Lone Pine, Victoria',
    value: 10007904,
  },
  {
    label: 'Lonesome Creek, Queensland',
    value: 10004623,
  },
  {
    label: 'Long Beach, New South Wales',
    value: 10001729,
  },
  {
    label: 'Long Flat, New South Wales',
    value: 10001405,
  },
  {
    label: 'Long Flat, Queensland',
    value: 10003977,
  },
  {
    label: 'Long Gully, Victoria',
    value: 10007678,
  },
  {
    label: 'Long Island, Queensland',
    value: 10004723,
  },
  {
    label: 'Long Jetty, New South Wales',
    value: 10000794,
  },
  {
    label: 'Long Point, New South Wales',
    value: 10001904,
  },
  {
    label: 'Longford, Tasmania',
    value: 10006514,
  },
  {
    label: 'Longford, Victoria',
    value: 10008163,
  },
  {
    label: 'Longlea, Victoria',
    value: 10007700,
  },
  {
    label: 'Longley, Tasmania',
    value: 10006283,
  },
  {
    label: 'Longreach, Queensland',
    value: 10004640,
  },
  {
    label: 'Longueville, New South Wales',
    value: 10000320,
  },
  {
    label: 'Longwarry, Victoria',
    value: 10008108,
  },
  {
    label: 'Longwood, South Australia',
    value: 10005607,
  },
  {
    label: 'Longwood, Victoria',
    value: 10007851,
  },
  {
    label: 'Lonnavale, Tasmania',
    value: 10006180,
  },
  {
    label: 'Lonsdale, South Australia',
    value: 10005627,
  },
  {
    label: 'Lord Howe Island, New South Wales',
    value: 10002669,
  },
  {
    label: 'Lorinna, Tasmania',
    value: 10006548,
  },
  {
    label: 'Lorn, New South Wales',
    value: 10000975,
  },
  {
    label: 'Lorne, New South Wales',
    value: 10001352,
  },
  {
    label: 'Lorne, Victoria',
    value: 10007127,
  },
  {
    label: 'Lort River, Western Australia',
    value: 10009552,
  },
  {
    label: 'Lostock, New South Wales',
    value: 10000942,
  },
  {
    label: 'Lota, Queensland',
    value: 10003070,
  },
  {
    label: 'Lottah, Tasmania',
    value: 10006373,
  },
  {
    label: 'Lotus Creek, Queensland',
    value: 10004569,
  },
  {
    label: 'Louth, New South Wales',
    value: 10002586,
  },
  {
    label: 'Louth Bay, South Australia',
    value: 10006002,
  },
  {
    label: 'Loveday, South Australia',
    value: 10005813,
  },
  {
    label: 'Lovely Banks, Victoria',
    value: 10007088,
  },
  {
    label: 'Lovett Bay, New South Wales',
    value: 10000403,
  },
  {
    label: 'Low Head, Tasmania',
    value: 10006412,
  },
  {
    label: 'Lowanna, New South Wales',
    value: 10001432,
  },
  {
    label: 'Lowbank, South Australia',
    value: 10005796,
  },
  {
    label: 'Lowden, Western Australia',
    value: 10008893,
  },
  {
    label: 'Lower Bucca, New South Wales',
    value: 10001431,
  },
  {
    label: 'Lower Chittering, Western Australia',
    value: 10008601,
  },
  {
    label: 'Lower Cowley, Queensland',
    value: 10005161,
  },
  {
    label: 'Lower Creek, New South Wales',
    value: 10001365,
  },
  {
    label: 'Lower Cressbrook, Queensland',
    value: 10003316,
  },
  {
    label: 'Lower Hawkesbury, New South Wales',
    value: 10002396,
  },
  {
    label: 'Lower Hermitage, South Australia',
    value: 10005580,
  },
  {
    label: 'Lower Hotham, Western Australia',
    value: 10009355,
  },
  {
    label: 'Lower King, Western Australia',
    value: 10009181,
  },
  {
    label: 'Lower Light, South Australia',
    value: 10005908,
  },
  {
    label: 'Lower Longley, Tasmania',
    value: 10006188,
  },
  {
    label: 'Lower Mangrove, New South Wales',
    value: 10000719,
  },
  {
    label: 'Lower Marshes, Tasmania',
    value: 10006132,
  },
  {
    label: 'Lower Mitcham, South Australia',
    value: 10005393,
  },
  {
    label: 'Lower Mount Walker, Queensland',
    value: 10003321,
  },
  {
    label: 'Lower Plenty, Victoria',
    value: 10006836,
  },
  {
    label: 'Lower Portland, New South Wales',
    value: 10002337,
  },
  {
    label: 'Lower Tenthill, Queensland',
    value: 10003354,
  },
  {
    label: 'Lowesdale, New South Wales',
    value: 10002115,
  },
  {
    label: 'Lowlands, Western Australia',
    value: 10009205,
  },
  {
    label: 'Lowmead, Queensland',
    value: 10004384,
  },
  {
    label: 'Lowood, Queensland',
    value: 10003301,
  },
  {
    label: 'Lowther, New South Wales',
    value: 10002431,
  },
  {
    label: 'Loxford, New South Wales',
    value: 10001045,
  },
  {
    label: 'Loxton, South Australia',
    value: 10005802,
  },
  {
    label: 'Loxton North, South Australia',
    value: 10005800,
  },
  {
    label: 'Loyetea, Tasmania',
    value: 10006604,
  },
  {
    label: 'Lubeck, Victoria',
    value: 10007450,
  },
  {
    label: 'Lucas Heights, New South Wales',
    value: 10000712,
  },
  {
    label: 'Lucaston, Tasmania',
    value: 10006173,
  },
  {
    label: 'Lucinda, Queensland',
    value: 10005005,
  },
  {
    label: 'Lucindale, South Australia',
    value: 10005743,
  },
  {
    label: 'Lucknow, New South Wales',
    value: 10002503,
  },
  {
    label: 'Lucknow, Victoria',
    value: 10008203,
  },
  {
    label: 'Lucyvale, Victoria',
    value: 10007909,
  },
  {
    label: 'Luddenham, New South Wales',
    value: 10002298,
  },
  {
    label: 'Ludlow, Western Australia',
    value: 10008982,
  },
  {
    label: 'Ludmilla, Northern Territory',
    value: 10002706,
  },
  {
    label: 'Lue, New South Wales',
    value: 10002605,
  },
  {
    label: 'Lugarno, New South Wales',
    value: 10000638,
  },
  {
    label: 'Lughrata, Tasmania',
    value: 10006431,
  },
  {
    label: 'Lulworth, Tasmania',
    value: 10006405,
  },
  {
    label: 'Lumeah, Queensland',
    value: 10003759,
  },
  {
    label: 'Lumeah, Western Australia',
    value: 10009384,
  },
  {
    label: 'Lunawanna, Tasmania',
    value: 10006274,
  },
  {
    label: 'Lundavra, Queensland',
    value: 10003614,
  },
  {
    label: 'Lune River, Tasmania',
    value: 10006182,
  },
  {
    label: 'Lurnea, New South Wales',
    value: 10000567,
  },
  {
    label: 'Luscombe, Queensland',
    value: 10003084,
  },
  {
    label: 'Lutana, Tasmania',
    value: 10006082,
  },
  {
    label: 'Lutwyche, Queensland',
    value: 10002854,
  },
  {
    label: 'Lyalls Mill, Western Australia',
    value: 10008824,
  },
  {
    label: 'Lymington, Tasmania',
    value: 10006185,
  },
  {
    label: 'Lynbrook, Victoria',
    value: 10008343,
  },
  {
    label: 'Lynchs Creek, New South Wales',
    value: 10001530,
  },
  {
    label: 'Lyndhurst, New South Wales',
    value: 10002487,
  },
  {
    label: 'Lyndhurst, South Australia',
    value: 10006055,
  },
  {
    label: 'Lyndhurst, Victoria',
    value: 10008344,
  },
  {
    label: 'Lyndoch, South Australia',
    value: 10005817,
  },
  {
    label: 'Lyndon, Western Australia',
    value: 10009953,
  },
  {
    label: 'Lyneham, Australian Capital Territory',
    value: 10000019,
  },
  {
    label: 'Lynford, Queensland',
    value: 10003338,
  },
  {
    label: 'Lynton, South Australia',
    value: 10005389,
  },
  {
    label: 'Lynwood, Western Australia',
    value: 10008660,
  },
  {
    label: 'Lyons, Australian Capital Territory',
    value: 10000036,
  },
  {
    label: 'Lyonville, Victoria',
    value: 10007555,
  },
  {
    label: 'Lyra, Queensland',
    value: 10003424,
  },
  {
    label: 'Lyrup, South Australia',
    value: 10005810,
  },
  {
    label: 'Lysterfield, Victoria',
    value: 10006954,
  },
  {
    label: 'Lytton, Queensland',
    value: 10003065,
  },
  {
    label: 'Ma Ma Creek, Queensland',
    value: 10003381,
  },
  {
    label: 'Maadi, Queensland',
    value: 10005025,
  },
  {
    label: 'Mabins Well, New South Wales',
    value: 10002259,
  },
  {
    label: 'Mabuiag Island, New South Wales',
    value: 10010200,
  },
  {
    label: 'Macalister, Queensland',
    value: 10003652,
  },
  {
    label: 'Macarthur, Australian Capital Territory',
    value: 10000105,
  },
  {
    label: 'Macarthur, Victoria',
    value: 10007238,
  },
  {
    label: 'Macclesfield, South Australia',
    value: 10005605,
  },
  {
    label: 'Macclesfield, Victoria',
    value: 10008063,
  },
  {
    label: 'Macdonald Park, South Australia',
    value: 10005572,
  },
  {
    label: 'Macedon, Victoria',
    value: 10007518,
  },
  {
    label: 'Macfarlane, Queensland',
    value: 10003752,
  },
  {
    label: 'Macgregor, Australian Capital Territory',
    value: 10000080,
  },
  {
    label: 'Macgregor, Queensland',
    value: 10002960,
  },
  {
    label: 'Machans Beach, Queensland',
    value: 10005198,
  },
  {
    label: 'Machine Creek, Queensland',
    value: 10004436,
  },
  {
    label: 'Mackay, Queensland',
    value: 10004705,
  },
  {
    label: 'Mackay Mc, Queensland',
    value: 10004721,
  },
  {
    label: 'Mackenzie, Queensland',
    value: 10003034,
  },
  {
    label: 'Mackenzie, Queensland',
    value: 10004523,
  },
  {
    label: 'Mackenzie River, Queensland',
    value: 10004571,
  },
  {
    label: 'Macknade, Queensland',
    value: 10005006,
  },
  {
    label: 'Macks Creek, Victoria',
    value: 10008342,
  },
  {
    label: 'Macksville, New South Wales',
    value: 10001419,
  },
  {
    label: 'Maclagan, Queensland',
    value: 10003479,
  },
  {
    label: 'Maclean, New South Wales',
    value: 10001490,
  },
  {
    label: 'Macleay Island, Queensland',
    value: 10003079,
  },
  {
    label: 'Macleod, Victoria',
    value: 10006827,
  },
  {
    label: 'Macleod, Western Australia',
    value: 10009933,
  },
  {
    label: 'Macmasters Beach, New South Wales',
    value: 10000739,
  },
  {
    label: 'Macorna, Victoria',
    value: 10007749,
  },
  {
    label: 'Macquarie, Australian Capital Territory',
    value: 10000072,
  },
  {
    label: 'Macquarie, Tasmania',
    value: 10006284,
  },
  {
    label: 'Macquarie Centre, New South Wales',
    value: 10000428,
  },
  {
    label: 'Macquarie Fields, New South Wales',
    value: 10001905,
  },
  {
    label: 'Macquarie Island, Tasmania',
    value: 10006286,
  },
  {
    label: 'Macquarie Links, New South Wales',
    value: 10001907,
  },
  {
    label: 'Macquarie Park, New South Wales',
    value: 10000429,
  },
  {
    label: 'Macquarie Plains, Tasmania',
    value: 10006266,
  },
  {
    label: 'Macquarie University, New South Wales',
    value: 10000413,
  },
  {
    label: 'Macrossan, Queensland',
    value: 10004921,
  },
  {
    label: 'Maddington, Western Australia',
    value: 10008625,
  },
  {
    label: 'Maddington, Western Australia',
    value: 10010156,
  },
  {
    label: 'Madeley, Western Australia',
    value: 10008565,
  },
  {
    label: 'Madora Bay, Western Australia',
    value: 10008795,
  },
  {
    label: 'Madura, Western Australia',
    value: 10009546,
  },
  {
    label: 'Maffra, Victoria',
    value: 10008182,
  },
  {
    label: 'Magenta, Western Australia',
    value: 10009295,
  },
  {
    label: 'Magill, South Australia',
    value: 10005450,
  },
  {
    label: 'Magill North, South Australia',
    value: 10005454,
  },
  {
    label: 'Magitup, Western Australia',
    value: 10009261,
  },
  {
    label: 'Magnetic Island, Queensland',
    value: 10004939,
  },
  {
    label: 'Magpie, Victoria',
    value: 10007399,
  },
  {
    label: 'Magra, Tasmania',
    value: 10006240,
  },
  {
    label: 'Mahogany Creek, Western Australia',
    value: 10008580,
  },
  {
    label: 'Mahomets Flats, Western Australia',
    value: 10009749,
  },
  {
    label: 'Maianbar, New South Wales',
    value: 10000690,
  },
  {
    label: 'Maida Vale, Western Australia',
    value: 10008531,
  },
  {
    label: 'Maiden Gully, Victoria',
    value: 10007693,
  },
  {
    label: 'Maidenhead, Queensland',
    value: 10003586,
  },
  {
    label: 'Maidenwell, Queensland',
    value: 10004110,
  },
  {
    label: 'Maidstone, Victoria',
    value: 10006688,
  },
  {
    label: 'Mailer Flat, Victoria',
    value: 10007212,
  },
  {
    label: 'Main Beach, Queensland',
    value: 10003157,
  },
  {
    label: 'Main Ridge, Victoria',
    value: 10008273,
  },
  {
    label: 'Maitland, New South Wales',
    value: 10000973,
  },
  {
    label: 'Maitland, South Australia',
    value: 10005966,
  },
  {
    label: 'Maitland, Western Australia',
    value: 10009974,
  },
  {
    label: 'Maitland North, New South Wales',
    value: 10000969,
  },
  {
    label: 'Majorca, Victoria',
    value: 10007577,
  },
  {
    label: 'Majors Creek, New South Wales',
    value: 10002054,
  },
  {
    label: 'Malabaine, Western Australia',
    value: 10009412,
  },
  {
    label: 'Malabar, New South Wales',
    value: 10000259,
  },
  {
    label: 'Malaga, Western Australia',
    value: 10008602,
  },
  {
    label: 'Malaga, Western Australia',
    value: 10010124,
  },
  {
    label: 'Malaga Dc, Western Australia',
    value: 10010125,
  },
  {
    label: 'Malak, Northern Territory',
    value: 10002694,
  },
  {
    label: 'Malanda, Queensland',
    value: 10005215,
  },
  {
    label: 'Malarga, Queensland',
    value: 10004121,
  },
  {
    label: 'Malbina, Tasmania',
    value: 10006256,
  },
  {
    label: 'Maldon, Victoria',
    value: 10007567,
  },
  {
    label: 'Malebelling, Western Australia',
    value: 10009057,
  },
  {
    label: 'Maleny, Queensland',
    value: 10003893,
  },
  {
    label: 'Mallabula, New South Wales',
    value: 10000965,
  },
  {
    label: 'Mallacoota, Victoria',
    value: 10008237,
  },
  {
    label: 'Mallala, South Australia',
    value: 10005914,
  },
  {
    label: 'Mallanganee, New South Wales',
    value: 10001496,
  },
  {
    label: 'Mallee Hill, Western Australia',
    value: 10009281,
  },
  {
    label: 'Malling, Queensland',
    value: 10003428,
  },
  {
    label: 'Malmalling, Western Australia',
    value: 10009837,
  },
  {
    label: 'Malmoe, Queensland',
    value: 10004186,
  },
  {
    label: 'Malmsbury, Victoria',
    value: 10007531,
  },
  {
    label: 'Maloneys Beach, New South Wales',
    value: 10001737,
  },
  {
    label: 'Malu, Queensland',
    value: 10003634,
  },
  {
    label: 'Malua Bay, New South Wales',
    value: 10001749,
  },
  {
    label: 'Malvern, South Australia',
    value: 10005387,
  },
  {
    label: 'Malvern, Victoria',
    value: 10006929,
  },
  {
    label: 'Malvern East, Victoria',
    value: 10006932,
  },
  {
    label: 'Malyalling, Western Australia',
    value: 10009327,
  },
  {
    label: 'Mambray Creek, South Australia',
    value: 10005906,
  },
  {
    label: 'Manahan, New South Wales',
    value: 10000617,
  },
  {
    label: 'Manangatang, Victoria',
    value: 10007669,
  },
  {
    label: 'Manapouri, Queensland',
    value: 10003555,
  },
  {
    label: 'Mandagery, New South Wales',
    value: 10002638,
  },
  {
    label: 'Mandalay, Queensland',
    value: 10004811,
  },
  {
    label: 'Mandalong, New South Wales',
    value: 10000824,
  },
  {
    label: 'Mandogalup, Western Australia',
    value: 10008738,
  },
  {
    label: 'Mandurah, Western Australia',
    value: 10008773,
  },
  {
    label: 'Mandurah East, Western Australia',
    value: 10008787,
  },
  {
    label: 'Mandurah North, Western Australia',
    value: 10008782,
  },
  {
    label: 'Mandurama, New South Wales',
    value: 10002443,
  },
  {
    label: 'Mandurang, Victoria',
    value: 10007696,
  },
  {
    label: 'Mandurang South, Victoria',
    value: 10007698,
  },
  {
    label: 'Maneroo, Queensland',
    value: 10004637,
  },
  {
    label: 'Mangalore, Tasmania',
    value: 10006136,
  },
  {
    label: 'Mangalore, Victoria',
    value: 10007849,
  },
  {
    label: 'Mangana, Tasmania',
    value: 10006352,
  },
  {
    label: 'Mangerton, New South Wales',
    value: 10001647,
  },
  {
    label: 'Mango Hill, Queensland',
    value: 10003825,
  },
  {
    label: 'Mangoplah, New South Wales',
    value: 10002150,
  },
  {
    label: 'Mangrove Mountain, New South Wales',
    value: 10000721,
  },
  {
    label: 'Manifold Heights, Victoria',
    value: 10007078,
  },
  {
    label: 'Manildra, New South Wales',
    value: 10002625,
  },
  {
    label: 'Manilla, New South Wales',
    value: 10001145,
  },
  {
    label: 'Maningrida, Northern Territory',
    value: 10002723,
  },
  {
    label: 'Manjimup, Western Australia',
    value: 10008927,
  },
  {
    label: 'Manly, New South Wales',
    value: 10000165,
  },
  {
    label: 'Manly, New South Wales',
    value: 10000375,
  },
  {
    label: 'Manly, Queensland',
    value: 10003069,
  },
  {
    label: 'Manly Vale, New South Wales',
    value: 10000371,
  },
  {
    label: 'Manly West, Queensland',
    value: 10003068,
  },
  {
    label: 'Manmanning, Western Australia',
    value: 10009592,
  },
  {
    label: 'Manna Hill, South Australia',
    value: 10005876,
  },
  {
    label: 'Mannerim, Victoria',
    value: 10007108,
  },
  {
    label: 'Mannering Park, New South Wales',
    value: 10000769,
  },
  {
    label: 'Manning, Western Australia',
    value: 10008672,
  },
  {
    label: 'Manning Point, New South Wales',
    value: 10001335,
  },
  {
    label: 'Manningham, South Australia',
    value: 10005489,
  },
  {
    label: 'Mannuem, Queensland',
    value: 10004071,
  },
  {
    label: 'Mannum, South Australia',
    value: 10005689,
  },
  {
    label: 'Mannus, New South Wales',
    value: 10002163,
  },
  {
    label: 'Manoora, Queensland',
    value: 10005096,
  },
  {
    label: 'Manoora, South Australia',
    value: 10005857,
  },
  {
    label: 'Mansfield, Queensland',
    value: 10002998,
  },
  {
    label: 'Mansfield, Victoria',
    value: 10007974,
  },
  {
    label: 'Mansfield, Victoria',
    value: 10007981,
  },
  {
    label: 'Mansfield Park, South Australia',
    value: 10005267,
  },
  {
    label: 'Manuka, Australian Capital Territory',
    value: 10000026,
  },
  {
    label: 'Manumbar, Queensland',
    value: 10004038,
  },
  {
    label: 'Manunda, Queensland',
    value: 10005111,
  },
  {
    label: 'Manyana, New South Wales',
    value: 10001786,
  },
  {
    label: 'Manypeaks, Western Australia',
    value: 10009166,
  },
  {
    label: 'Manyung, Queensland',
    value: 10004033,
  },
  {
    label: 'Mapleton, Queensland',
    value: 10003921,
  },
  {
    label: 'Mapoon, New South Wales',
    value: 10010206,
  },
  {
    label: 'Marama, South Australia',
    value: 10005785,
  },
  {
    label: 'Marananga, South Australia',
    value: 10005832,
  },
  {
    label: 'Maranboy, Northern Territory',
    value: 10002769,
  },
  {
    label: 'Marangaroo, Western Australia',
    value: 10008547,
  },
  {
    label: 'Maranup, Western Australia',
    value: 10008920,
  },
  {
    label: 'Maraylya, New South Wales',
    value: 10002359,
  },
  {
    label: 'Marayong, New South Wales',
    value: 10000506,
  },
  {
    label: 'Marbelup, Western Australia',
    value: 10009177,
  },
  {
    label: 'Marble Bar, Western Australia',
    value: 10010054,
  },
  {
    label: 'Marburg, Queensland',
    value: 10003378,
  },
  {
    label: 'March, New South Wales',
    value: 10002496,
  },
  {
    label: 'Marchagee, Western Australia',
    value: 10009700,
  },
  {
    label: 'Marcoola, Queensland',
    value: 10003931,
  },
  {
    label: 'Marcus Beach, Queensland',
    value: 10004009,
  },
  {
    label: 'Marcus Hill, Victoria',
    value: 10007106,
  },
  {
    label: 'Mardella, Western Australia',
    value: 10008656,
  },
  {
    label: 'Marden, South Australia',
    value: 10005447,
  },
  {
    label: 'Mardi, New South Wales',
    value: 10000767,
  },
  {
    label: 'Mardie, Western Australia',
    value: 10009977,
  },
  {
    label: 'Mareeba, Queensland',
    value: 10005206,
  },
  {
    label: 'Margaret River, Western Australia',
    value: 10009015,
  },
  {
    label: 'Margate, Queensland',
    value: 10002841,
  },
  {
    label: 'Margate, Tasmania',
    value: 10006162,
  },
  {
    label: 'Margate Beach, Queensland',
    value: 10002842,
  },
  {
    label: 'Maria Creeks, Queensland',
    value: 10005022,
  },
  {
    label: 'Maria Island, Tasmania',
    value: 10006331,
  },
  {
    label: 'Marian, Queensland',
    value: 10004753,
  },
  {
    label: 'Maribyrnong, Victoria',
    value: 10006730,
  },
  {
    label: 'Mariginiup, Western Australia',
    value: 10008552,
  },
  {
    label: 'Marino, South Australia',
    value: 10005376,
  },
  {
    label: 'Marion, South Australia',
    value: 10005352,
  },
  {
    label: 'Marion Bay, South Australia',
    value: 10005972,
  },
  {
    label: 'Marion Bay, Tasmania',
    value: 10006314,
  },
  {
    label: 'Marks Point, New South Wales',
    value: 10000836,
  },
  {
    label: 'Markwell, New South Wales',
    value: 10001289,
  },
  {
    label: 'Marla, South Australia',
    value: 10006049,
  },
  {
    label: 'Marlborough, Queensland',
    value: 10004568,
  },
  {
    label: 'Marlee, New South Wales',
    value: 10001320,
  },
  {
    label: 'Marleston, South Australia',
    value: 10005315,
  },
  {
    label: 'Marlo, Victoria',
    value: 10008225,
  },
  {
    label: 'Marlow Lagoon, Northern Territory',
    value: 10002737,
  },
  {
    label: 'Marmion, Western Australia',
    value: 10008441,
  },
  {
    label: 'Marmong Point, New South Wales',
    value: 10000867,
  },
  {
    label: 'Marne, Western Australia',
    value: 10009877,
  },
  {
    label: 'Marnoo, Victoria',
    value: 10007456,
  },
  {
    label: 'Marodian, Queensland',
    value: 10003969,
  },
  {
    label: 'Marom Creek, New South Wales',
    value: 10001579,
  },
  {
    label: 'Marong, Victoria',
    value: 10007631,
  },
  {
    label: 'Maroochy River, Queensland',
    value: 10003924,
  },
  {
    label: 'Maroochydore, Queensland',
    value: 10003914,
  },
  {
    label: 'Maroochydore Bc, Queensland',
    value: 10003913,
  },
  {
    label: 'Maroona, Victoria',
    value: 10007440,
  },
  {
    label: 'Maroondan, Queensland',
    value: 10004339,
  },
  {
    label: 'Maroota, New South Wales',
    value: 10002339,
  },
  {
    label: 'Maroubra, New South Wales',
    value: 10000257,
  },
  {
    label: 'Marrabel, South Australia',
    value: 10005855,
  },
  {
    label: 'Marracoonda, Western Australia',
    value: 10009128,
  },
  {
    label: 'Marradong, Western Australia',
    value: 10009360,
  },
  {
    label: 'Marrah, Western Australia',
    value: 10009817,
  },
  {
    label: 'Marrangaroo, New South Wales',
    value: 10002439,
  },
  {
    label: 'Marrar, New South Wales',
    value: 10002160,
  },
  {
    label: 'Marrara, Northern Territory',
    value: 10002698,
  },
  {
    label: 'Marrawah, Tasmania',
    value: 10006670,
  },
  {
    label: 'Marree, South Australia',
    value: 10006058,
  },
  {
    label: 'Marrickville, New South Wales',
    value: 10000147,
  },
  {
    label: 'Marrickville, New South Wales',
    value: 10000620,
  },
  {
    label: 'Marrickville Metro, New South Wales',
    value: 10000621,
  },
  {
    label: 'Marrickville South, New South Wales',
    value: 10000622,
  },
  {
    label: 'Marrinup, Western Australia',
    value: 10008800,
  },
  {
    label: 'Marryatville, South Australia',
    value: 10005434,
  },
  {
    label: 'Marsden, Queensland',
    value: 10003019,
  },
  {
    label: 'Marsden Park, New South Wales',
    value: 10002363,
  },
  {
    label: 'Marsfield, New South Wales',
    value: 10000449,
  },
  {
    label: 'Marshall, Victoria',
    value: 10007072,
  },
  {
    label: 'Marshdale, New South Wales',
    value: 10001269,
  },
  {
    label: 'Marshlands, Queensland',
    value: 10004089,
  },
  {
    label: 'Martin, Western Australia',
    value: 10008630,
  },
  {
    label: 'Martinsville, New South Wales',
    value: 10000826,
  },
  {
    label: 'Martynvale, Queensland',
    value: 10005104,
  },
  {
    label: 'Martyville, Queensland',
    value: 10005040,
  },
  {
    label: 'Marulan, New South Wales',
    value: 10001974,
  },
  {
    label: 'Marungi, Victoria',
    value: 10007819,
  },
  {
    label: 'Marvel Loch, Western Australia',
    value: 10009474,
  },
  {
    label: 'Maryborough, Queensland',
    value: 10004241,
  },
  {
    label: 'Maryborough, Victoria',
    value: 10007572,
  },
  {
    label: 'Marybrook, Western Australia',
    value: 10009003,
  },
  {
    label: 'Maryknoll, Victoria',
    value: 10008099,
  },
  {
    label: 'Maryland, New South Wales',
    value: 10000878,
  },
  {
    label: 'Marys Creek, Queensland',
    value: 10003981,
  },
  {
    label: 'Marysville, Victoria',
    value: 10008058,
  },
  {
    label: 'Maryvale, New South Wales',
    value: 10002522,
  },
  {
    label: 'Maryvale, Queensland',
    value: 10003570,
  },
  {
    label: 'Maryvale, Queensland',
    value: 10004553,
  },
  {
    label: 'Maryville, New South Wales',
    value: 10000904,
  },
  {
    label: 'Mascot, New South Wales',
    value: 10000143,
  },
  {
    label: 'Mascot, New South Wales',
    value: 10000225,
  },
  {
    label: 'Masig Island, New South Wales',
    value: 10010197,
  },
  {
    label: 'Maslin Beach, South Australia',
    value: 10005645,
  },
  {
    label: 'Massey Bay, Western Australia',
    value: 10009947,
  },
  {
    label: 'Mataranka, Northern Territory',
    value: 10002770,
  },
  {
    label: 'Matcham, New South Wales',
    value: 10000731,
  },
  {
    label: 'Matheson, New South Wales',
    value: 10001200,
  },
  {
    label: 'Mathinna, Tasmania',
    value: 10006355,
  },
  {
    label: 'Mathoura, New South Wales',
    value: 10002239,
  },
  {
    label: 'Matlock, Victoria',
    value: 10007975,
  },
  {
    label: 'Matong, New South Wales',
    value: 10002154,
  },
  {
    label: 'Matraville, New South Wales',
    value: 10000265,
  },
  {
    label: 'Matta Flat, South Australia',
    value: 10005936,
  },
  {
    label: 'Maude, New South Wales',
    value: 10002247,
  },
  {
    label: 'Maude, Victoria',
    value: 10007300,
  },
  {
    label: 'Mauds Landing, Western Australia',
    value: 10009950,
  },
  {
    label: 'Maudsland, Queensland',
    value: 10003114,
  },
  {
    label: 'Mawbanna, Tasmania',
    value: 10006620,
  },
  {
    label: 'Mawson, Australian Capital Territory',
    value: 10000044,
  },
  {
    label: 'Mawson, Tasmania',
    value: 10006287,
  },
  {
    label: 'Mawson, Western Australia',
    value: 10009340,
  },
  {
    label: 'Mawson Lakes, South Australia',
    value: 10005509,
  },
  {
    label: 'May Downs, Queensland',
    value: 10004746,
  },
  {
    label: 'Maya, Western Australia',
    value: 10009887,
  },
  {
    label: 'Mayanup, Western Australia',
    value: 10008897,
  },
  {
    label: 'Mayberry, Tasmania',
    value: 10006537,
  },
  {
    label: 'Maydena, Tasmania',
    value: 10006248,
  },
  {
    label: 'Mayfield, New South Wales',
    value: 10000931,
  },
  {
    label: 'Mayfield, New South Wales',
    value: 10002044,
  },
  {
    label: 'Mayfield, Tasmania',
    value: 10006377,
  },
  {
    label: 'Mayfield East, New South Wales',
    value: 10000930,
  },
  {
    label: 'Mayfield North, New South Wales',
    value: 10000926,
  },
  {
    label: 'Mayfield West, New South Wales',
    value: 10000928,
  },
  {
    label: 'Maylands, South Australia',
    value: 10005438,
  },
  {
    label: 'Maylands, Western Australia',
    value: 10008499,
  },
  {
    label: 'Maylands, Western Australia',
    value: 10010114,
  },
  {
    label: 'Mayrung, New South Wales',
    value: 10002237,
  },
  {
    label: 'Mays Hill, New South Wales',
    value: 10000492,
  },
  {
    label: 'Mcalinden, Western Australia',
    value: 10008828,
  },
  {
    label: 'Mcbeath, Western Australia',
    value: 10010058,
  },
  {
    label: 'Mccracken, South Australia',
    value: 10005665,
  },
  {
    label: 'Mccrae, Victoria',
    value: 10008285,
  },
  {
    label: 'Mccullys Gap, New South Wales',
    value: 10001079,
  },
  {
    label: 'Mccutcheon, Queensland',
    value: 10005035,
  },
  {
    label: 'Mcdesme, Queensland',
    value: 10004840,
  },
  {
    label: 'Mcdowall, Queensland',
    value: 10002877,
  },
  {
    label: 'Mcewens Beach, Queensland',
    value: 10004683,
  },
  {
    label: 'Mcgraths Hill, New South Wales',
    value: 10002338,
  },
  {
    label: 'Mcilwraith, Queensland',
    value: 10004357,
  },
  {
    label: 'Mcintosh Creek, Queensland',
    value: 10004002,
  },
  {
    label: 'Mckail, Western Australia',
    value: 10009200,
  },
  {
    label: 'Mckees Hill, New South Wales',
    value: 10001580,
  },
  {
    label: 'Mckellar, Australian Capital Territory',
    value: 10000092,
  },
  {
    label: 'Mckinlay, Queensland',
    value: 10004959,
  },
  {
    label: 'Mckinnon, Victoria',
    value: 10007050,
  },
  {
    label: 'Mclaren Flat, South Australia',
    value: 10005648,
  },
  {
    label: 'Mclaren Vale, South Australia',
    value: 10005646,
  },
  {
    label: 'Mcmahons Creek, Victoria',
    value: 10008083,
  },
  {
    label: 'Mcmahons Point, New South Wales',
    value: 10000299,
  },
  {
    label: 'Meadow Flat, New South Wales',
    value: 10002466,
  },
  {
    label: 'Meadow Heights, Victoria',
    value: 10006766,
  },
  {
    label: 'Meadow Springs, Western Australia',
    value: 10008792,
  },
  {
    label: 'Meadowbank, New South Wales',
    value: 10000434,
  },
  {
    label: 'Meadowbrook, Queensland',
    value: 10003016,
  },
  {
    label: 'Meadows, South Australia',
    value: 10005655,
  },
  {
    label: 'Meadowvale, Queensland',
    value: 10004321,
  },
  {
    label: 'Meandarra, Queensland',
    value: 10003695,
  },
  {
    label: 'Meander, Tasmania',
    value: 10006538,
  },
  {
    label: 'Meckering, Western Australia',
    value: 10009415,
  },
  {
    label: 'Medina, Western Australia',
    value: 10008726,
  },
  {
    label: 'Medindie, South Australia',
    value: 10005468,
  },
  {
    label: 'Medlow Bath, New South Wales',
    value: 10002408,
  },
  {
    label: 'Medowie, New South Wales',
    value: 10000958,
  },
  {
    label: 'Meekatharra, Western Australia',
    value: 10009925,
  },
  {
    label: 'Meelon, Western Australia',
    value: 10008762,
  },
  {
    label: 'Meenaar, Western Australia',
    value: 10009398,
  },
  {
    label: 'Meeniyan, Victoria',
    value: 10008315,
  },
  {
    label: 'Meering West, Victoria',
    value: 10007746,
  },
  {
    label: 'Meerlieu, Victoria',
    value: 10008184,
  },
  {
    label: 'Meerschaum Vale, New South Wales',
    value: 10001551,
  },
  {
    label: 'Megalong, New South Wales',
    value: 10002413,
  },
  {
    label: 'Megan, New South Wales',
    value: 10001446,
  },
  {
    label: 'Meikleville Hill, Queensland',
    value: 10004558,
  },
  {
    label: 'Melaleuca, Western Australia',
    value: 10008555,
  },
  {
    label: 'Melawondi, Queensland',
    value: 10003952,
  },
  {
    label: 'Melba, Australian Capital Territory',
    value: 10000074,
  },
  {
    label: 'Melbourne, Victoria',
    value: 10006677,
  },
  {
    label: 'Melbourne Airport, Victoria',
    value: 10006758,
  },
  {
    label: 'Melbourne CBD, Victoria',
    value: 10010181,
  },
  {
    label: 'Melbourne University, Victoria',
    value: 10006773,
  },
  {
    label: 'Mella, Tasmania',
    value: 10006658,
  },
  {
    label: 'Melrose, Queensland',
    value: 10004100,
  },
  {
    label: 'Melrose, South Australia',
    value: 10005899,
  },
  {
    label: 'Melrose, Tasmania',
    value: 10006584,
  },
  {
    label: 'Melrose Park, New South Wales',
    value: 10000430,
  },
  {
    label: 'Melrose Park, South Australia',
    value: 10005339,
  },
  {
    label: 'Melton, Victoria',
    value: 10007310,
  },
  {
    label: 'Melton Mowbray, Tasmania',
    value: 10006137,
  },
  {
    label: 'Melton South, Victoria',
    value: 10007313,
  },
  {
    label: 'Melville, Western Australia',
    value: 10008691,
  },
  {
    label: 'Melville, Western Australia',
    value: 10010133,
  },
  {
    label: 'Melville Forest, Victoria',
    value: 10007278,
  },
  {
    label: 'Memana, Tasmania',
    value: 10006427,
  },
  {
    label: 'Mena Creek, Queensland',
    value: 10005133,
  },
  {
    label: 'Menai, New South Wales',
    value: 10000711,
  },
  {
    label: 'Menai Central, New South Wales',
    value: 10000710,
  },
  {
    label: 'Menangle, New South Wales',
    value: 10001920,
  },
  {
    label: 'Menangle Park, New South Wales',
    value: 10001903,
  },
  {
    label: 'Mendooran, New South Wales',
    value: 10002589,
  },
  {
    label: 'Mengha, Tasmania',
    value: 10006668,
  },
  {
    label: 'Menindee, New South Wales',
    value: 10002664,
  },
  {
    label: 'Meningie, South Australia',
    value: 10005733,
  },
  {
    label: 'Menora, Western Australia',
    value: 10008498,
  },
  {
    label: 'Mentmore, Queensland',
    value: 10004770,
  },
  {
    label: 'Mentone, Victoria',
    value: 10007028,
  },
  {
    label: 'Menzies, Queensland',
    value: 10004987,
  },
  {
    label: 'Menzies, Western Australia',
    value: 10009522,
  },
  {
    label: 'Menzies Creek, Victoria',
    value: 10006959,
  },
  {
    label: 'Mepunga, Victoria',
    value: 10007214,
  },
  {
    label: 'Merbein, Victoria',
    value: 10007626,
  },
  {
    label: 'Meredith, Victoria',
    value: 10007303,
  },
  {
    label: 'Merewether, New South Wales',
    value: 10000901,
  },
  {
    label: 'Merewether Heights, New South Wales',
    value: 10000899,
  },
  {
    label: 'Merilup, Western Australia',
    value: 10009278,
  },
  {
    label: 'Merimbula, New South Wales',
    value: 10001845,
  },
  {
    label: 'Merinda, Queensland',
    value: 10004822,
  },
  {
    label: 'Meringandan, Queensland',
    value: 10003475,
  },
  {
    label: 'Meringandan West, Queensland',
    value: 10003454,
  },
  {
    label: 'Meringo, New South Wales',
    value: 10001761,
  },
  {
    label: 'Meringur, Victoria',
    value: 10007616,
  },
  {
    label: 'Merino, Victoria',
    value: 10007263,
  },
  {
    label: 'Merivale, Western Australia',
    value: 10009571,
  },
  {
    label: 'Merkanooka, Western Australia',
    value: 10009897,
  },
  {
    label: 'Merlwood, Queensland',
    value: 10004041,
  },
  {
    label: 'Merlynston, Victoria',
    value: 10006783,
  },
  {
    label: 'Mermaid Beach, Queensland',
    value: 10003163,
  },
  {
    label: 'Mermaid Waters, Queensland',
    value: 10003164,
  },
  {
    label: 'Mernda, Victoria',
    value: 10008027,
  },
  {
    label: 'Meroo Meadow, New South Wales',
    value: 10001794,
  },
  {
    label: 'Merredin, Western Australia',
    value: 10009439,
  },
  {
    label: 'Merricks, Victoria',
    value: 10008258,
  },
  {
    label: 'Merricks Beach, Victoria',
    value: 10008269,
  },
  {
    label: 'Merricks North, Victoria',
    value: 10008270,
  },
  {
    label: 'Merrigum, Victoria',
    value: 10007781,
  },
  {
    label: 'Merrijig, Victoria',
    value: 10007977,
  },
  {
    label: 'Merrimac, Queensland',
    value: 10003180,
  },
  {
    label: 'Merriton, South Australia',
    value: 10005920,
  },
  {
    label: 'Merritts Creek, Queensland',
    value: 10003434,
  },
  {
    label: 'Merriwa, New South Wales',
    value: 10001055,
  },
  {
    label: 'Merriwa, Western Australia',
    value: 10008471,
  },
  {
    label: 'Merriwagga, New South Wales',
    value: 10002149,
  },
  {
    label: 'Merrygoen, New South Wales',
    value: 10002565,
  },
  {
    label: 'Merrylands, New South Wales',
    value: 10000531,
  },
  {
    label: 'Merrylands West, New South Wales',
    value: 10000530,
  },
  {
    label: 'Merryvale, Queensland',
    value: 10003323,
  },
  {
    label: 'Mersey Forest, Tasmania',
    value: 10006528,
  },
  {
    label: 'Merseylea, Tasmania',
    value: 10006545,
  },
  {
    label: 'Merton, Victoria',
    value: 10007962,
  },
  {
    label: 'Meru, Western Australia',
    value: 10009757,
  },
  {
    label: 'Metcalfe, Victoria',
    value: 10007535,
  },
  {
    label: 'Metford, New South Wales',
    value: 10000998,
  },
  {
    label: 'Metford Dc, New South Wales',
    value: 10001003,
  },
  {
    label: 'Metricup, Western Australia',
    value: 10008984,
  },
  {
    label: 'Metung, Victoria',
    value: 10008246,
  },
  {
    label: 'Metz, New South Wales',
    value: 10001156,
  },
  {
    label: 'Meunna, Tasmania',
    value: 10006648,
  },
  {
    label: 'Mia Mia, Queensland',
    value: 10004755,
  },
  {
    label: 'Mia Mia, Victoria',
    value: 10007529,
  },
  {
    label: 'Miami, Queensland',
    value: 10003170,
  },
  {
    label: 'Miamoon, Western Australia',
    value: 10009884,
  },
  {
    label: 'Miandetta, New South Wales',
    value: 10002534,
  },
  {
    label: 'Miandetta, Tasmania',
    value: 10006581,
  },
  {
    label: 'Miara, Queensland',
    value: 10004370,
  },
  {
    label: 'Mica Creek, Queensland',
    value: 10004979,
  },
  {
    label: 'Michelago, New South Wales',
    value: 10002041,
  },
  {
    label: 'Mickleham, Victoria',
    value: 10006792,
  },
  {
    label: 'Mid North Coast Msc, New South Wales',
    value: 10001386,
  },
  {
    label: 'Mid Valley, Victoria',
    value: 10008142,
  },
  {
    label: 'Middle Beach, South Australia',
    value: 10005907,
  },
  {
    label: 'Middle Camberwell, Victoria',
    value: 10006877,
  },
  {
    label: 'Middle Camp, New South Wales',
    value: 10000842,
  },
  {
    label: 'Middle Cove, New South Wales',
    value: 10000326,
  },
  {
    label: 'Middle Park, Queensland',
    value: 10002920,
  },
  {
    label: 'Middle Park, Victoria',
    value: 10007056,
  },
  {
    label: 'Middle Ridge, Queensland',
    value: 10003384,
  },
  {
    label: 'Middle Swan, Western Australia',
    value: 10008516,
  },
  {
    label: 'Middlemount, Queensland',
    value: 10004747,
  },
  {
    label: 'Middlesex, Western Australia',
    value: 10008939,
  },
  {
    label: 'Middleton, Queensland',
    value: 10004651,
  },
  {
    label: 'Middleton, South Australia',
    value: 10005671,
  },
  {
    label: 'Middleton, Tasmania',
    value: 10006293,
  },
  {
    label: 'Middleton Beach, Western Australia',
    value: 10009219,
  },
  {
    label: 'Midgee, Queensland',
    value: 10004537,
  },
  {
    label: 'Midgeree Bar, Queensland',
    value: 10005012,
  },
  {
    label: 'Midland, Western Australia',
    value: 10008522,
  },
  {
    label: 'Midland Dc, Western Australia',
    value: 10010119,
  },
  {
    label: 'Midvale, Western Australia',
    value: 10008515,
  },
  {
    label: 'Midway Point, Tasmania',
    value: 10006300,
  },
  {
    label: 'Miena, Tasmania',
    value: 10006130,
  },
  {
    label: 'Mighell, Queensland',
    value: 10005063,
  },
  {
    label: 'Mil-Lel, South Australia',
    value: 10005769,
  },
  {
    label: 'Mila, New South Wales',
    value: 10002087,
  },
  {
    label: 'Milabena, Tasmania',
    value: 10006644,
  },
  {
    label: 'Milang, South Australia',
    value: 10005716,
  },
  {
    label: 'Milawa, Victoria',
    value: 10007887,
  },
  {
    label: 'Milbrulong, New South Wales',
    value: 10002168,
  },
  {
    label: 'Mildura, Victoria',
    value: 10007620,
  },
  {
    label: 'Mildura, Victoria',
    value: 10007624,
  },
  {
    label: 'Mildura Centre Plaza, Victoria',
    value: 10007623,
  },
  {
    label: 'Mile End, South Australia',
    value: 10005310,
  },
  {
    label: 'Miles, Queensland',
    value: 10003670,
  },
  {
    label: 'Miles End, Queensland',
    value: 10004973,
  },
  {
    label: 'Miling, Western Australia',
    value: 10009866,
  },
  {
    label: 'Milingimbi, Northern Territory',
    value: 10002732,
  },
  {
    label: 'Mill Park, Victoria',
    value: 10006819,
  },
  {
    label: 'Millaa Millaa, Queensland',
    value: 10005216,
  },
  {
    label: 'Millaroo, Queensland',
    value: 10004846,
  },
  {
    label: 'Millars Well, Western Australia',
    value: 10009979,
  },
  {
    label: 'Millbank, New South Wales',
    value: 10001359,
  },
  {
    label: 'Millbank, Queensland',
    value: 10004324,
  },
  {
    label: 'Millbridge, Western Australia',
    value: 10008861,
  },
  {
    label: 'Millbrook, Victoria',
    value: 10007401,
  },
  {
    label: 'Millbrook, Western Australia',
    value: 10009203,
  },
  {
    label: 'Millchester, Queensland',
    value: 10004948,
  },
  {
    label: 'Millendon, Western Australia',
    value: 10008528,
  },
  {
    label: 'Miller, New South Wales',
    value: 10000553,
  },
  {
    label: 'Millers Forest, New South Wales',
    value: 10001012,
  },
  {
    label: 'Millers Point, New South Wales',
    value: 10000196,
  },
  {
    label: 'Millfield, New South Wales',
    value: 10001027,
  },
  {
    label: 'Millgrove, Victoria',
    value: 10008080,
  },
  {
    label: 'Millicent, South Australia',
    value: 10005754,
  },
  {
    label: 'Millmerran, Queensland',
    value: 10003528,
  },
  {
    label: 'Millmerran Downs, Queensland',
    value: 10003517,
  },
  {
    label: 'Millmerran Woods, Queensland',
    value: 10003533,
  },
  {
    label: 'Millner, Northern Territory',
    value: 10002690,
  },
  {
    label: 'Milloo, Victoria',
    value: 10007737,
  },
  {
    label: 'Mills Lake, Western Australia',
    value: 10009251,
  },
  {
    label: 'Millstream, Queensland',
    value: 10005222,
  },
  {
    label: 'Millstream, Western Australia',
    value: 10009995,
  },
  {
    label: 'Millswood, South Australia',
    value: 10005323,
  },
  {
    label: 'Millthorpe, New South Wales',
    value: 10002490,
  },
  {
    label: 'Millwood, Queensland',
    value: 10003527,
  },
  {
    label: 'Milo, Western Australia',
    value: 10009732,
  },
  {
    label: 'Milpara, Western Australia',
    value: 10009213,
  },
  {
    label: 'Milperra, New South Wales',
    value: 10000191,
  },
  {
    label: 'Milperra, New South Wales',
    value: 10000648,
  },
  {
    label: 'Milsons Passage, New South Wales',
    value: 10000352,
  },
  {
    label: 'Milsons Point, New South Wales',
    value: 10000156,
  },
  {
    label: 'Milsons Point, New South Wales',
    value: 10000303,
  },
  {
    label: 'Milton, New South Wales',
    value: 10001771,
  },
  {
    label: 'Milton, Queensland',
    value: 10002896,
  },
  {
    label: 'Milvale, New South Wales',
    value: 10002028,
  },
  {
    label: 'Mimegarra, Western Australia',
    value: 10009680,
  },
  {
    label: 'Mimosa, Queensland',
    value: 10004477,
  },
  {
    label: 'Min Min, Queensland',
    value: 10004996,
  },
  {
    label: 'Mincha, Victoria',
    value: 10007744,
  },
  {
    label: 'Minchinbury, New South Wales',
    value: 10002380,
  },
  {
    label: 'Mindarabin, Western Australia',
    value: 10009249,
  },
  {
    label: 'Mindarie, Western Australia',
    value: 10008469,
  },
  {
    label: 'Mindarra, Western Australia',
    value: 10009656,
  },
  {
    label: 'Minden, Queensland',
    value: 10003303,
  },
  {
    label: 'Minding, Western Australia',
    value: 10009114,
  },
  {
    label: 'Miners Rest, Victoria',
    value: 10007391,
  },
  {
    label: 'Mingela, Queensland',
    value: 10004905,
  },
  {
    label: 'Mingenew, Western Australia',
    value: 10009723,
  },
  {
    label: 'Mingo, Queensland',
    value: 10004167,
  },
  {
    label: 'Minhamite, Victoria',
    value: 10007240,
  },
  {
    label: 'Minigin, Western Australia',
    value: 10009099,
  },
  {
    label: 'Minilya, Western Australia',
    value: 10009943,
  },
  {
    label: 'Minimay, Victoria',
    value: 10007487,
  },
  {
    label: 'Mininera, Victoria',
    value: 10007354,
  },
  {
    label: 'Minjilang, Northern Territory',
    value: 10002726,
  },
  {
    label: 'Minlaton, South Australia',
    value: 10005969,
  },
  {
    label: 'Minmi, New South Wales',
    value: 10000875,
  },
  {
    label: 'Minnamoolka, Queensland',
    value: 10005165,
  },
  {
    label: 'Minnamurra, New South Wales',
    value: 10001715,
  },
  {
    label: 'Minnenooka, Western Australia',
    value: 10009810,
  },
  {
    label: 'Minnie Downs, Queensland',
    value: 10003754,
  },
  {
    label: 'Minnipa, South Australia',
    value: 10006021,
  },
  {
    label: 'Minnivale, Western Australia',
    value: 10009589,
  },
  {
    label: 'Mintabie, South Australia',
    value: 10006048,
  },
  {
    label: 'Mintaro, South Australia',
    value: 10005858,
  },
  {
    label: 'Minto, New South Wales',
    value: 10001911,
  },
  {
    label: 'Minyama, Queensland',
    value: 10004016,
  },
  {
    label: 'Minyip, Victoria',
    value: 10007461,
  },
  {
    label: 'Minyirr, Western Australia',
    value: 10010018,
  },
  {
    label: 'Mira Mar, Western Australia',
    value: 10009217,
  },
  {
    label: 'Miram, Victoria',
    value: 10007494,
  },
  {
    label: 'Miranda, New South Wales',
    value: 10000151,
  },
  {
    label: 'Miranda, New South Wales',
    value: 10000683,
  },
  {
    label: 'Mirani, Queensland',
    value: 10004759,
  },
  {
    label: 'Mirannie, New South Wales',
    value: 10001061,
  },
  {
    label: 'Mirboo North, Victoria',
    value: 10008192,
  },
  {
    label: 'Miriam Vale, Queensland',
    value: 10004390,
  },
  {
    label: 'Mirimbah, Victoria',
    value: 10007973,
  },
  {
    label: 'Miriwinni, Queensland',
    value: 10005127,
  },
  {
    label: 'Mirrabooka, New South Wales',
    value: 10000821,
  },
  {
    label: 'Mirrabooka, Western Australia',
    value: 10008542,
  },
  {
    label: 'Mirrabooka, Western Australia',
    value: 10010121,
  },
  {
    label: 'Mirrool, New South Wales',
    value: 10002181,
  },
  {
    label: 'Missabotti, New South Wales',
    value: 10001423,
  },
  {
    label: 'Missen Flat, Queensland',
    value: 10003551,
  },
  {
    label: 'Missenden Road, New South Wales',
    value: 10000294,
  },
  {
    label: 'Mission Beach, Queensland',
    value: 10005013,
  },
  {
    label: 'Mitcham, South Australia',
    value: 10005390,
  },
  {
    label: 'Mitcham, Victoria',
    value: 10006899,
  },
  {
    label: 'Mitcham Shopping Centre, South Australia',
    value: 10005395,
  },
  {
    label: 'Mitchell, Australian Capital Territory',
    value: 10000119,
  },
  {
    label: 'Mitchell, Northern Territory',
    value: 10002749,
  },
  {
    label: 'Mitchell, Queensland',
    value: 10003741,
  },
  {
    label: 'Mitchell Park, South Australia',
    value: 10005355,
  },
  {
    label: 'Mitchell Park, Victoria',
    value: 10007386,
  },
  {
    label: 'Mitchell Park, Victoria',
    value: 10007408,
  },
  {
    label: 'Mitchell Plateau, Western Australia',
    value: 10010034,
  },
  {
    label: 'Mitchells Flat, New South Wales',
    value: 10001077,
  },
  {
    label: 'Mitchelton, Queensland',
    value: 10002878,
  },
  {
    label: 'Mitiamo, Victoria',
    value: 10007741,
  },
  {
    label: 'Mitre, Victoria',
    value: 10007484,
  },
  {
    label: 'Mitta Mitta, Victoria',
    value: 10007937,
  },
  {
    label: 'Mittagong, New South Wales',
    value: 10001947,
  },
  {
    label: 'Miva, Queensland',
    value: 10003960,
  },
  {
    label: 'Moa Island, New South Wales',
    value: 10010201,
  },
  {
    label: 'Moama, New South Wales',
    value: 10002281,
  },
  {
    label: 'Moana, South Australia',
    value: 10005641,
  },
  {
    label: 'Mobrup, Western Australia',
    value: 10009386,
  },
  {
    label: 'Mocardy, Western Australia',
    value: 10009869,
  },
  {
    label: 'Moculta, South Australia',
    value: 10005824,
  },
  {
    label: 'Modbury, South Australia',
    value: 10005502,
  },
  {
    label: 'Modbury North, South Australia',
    value: 10005503,
  },
  {
    label: 'Modella, Victoria',
    value: 10008109,
  },
  {
    label: 'Modewarre, Victoria',
    value: 10007142,
  },
  {
    label: 'Moe, Victoria',
    value: 10008130,
  },
  {
    label: 'Moffat Beach, Queensland',
    value: 10003888,
  },
  {
    label: 'Moffatdale, Queensland',
    value: 10004053,
  },
  {
    label: 'Mogareeka, New South Wales',
    value: 10001854,
  },
  {
    label: 'Mogendoura, New South Wales',
    value: 10001758,
  },
  {
    label: 'Moggill, Queensland',
    value: 10002914,
  },
  {
    label: 'Moggs Creek, Victoria',
    value: 10007123,
  },
  {
    label: 'Mogo, New South Wales',
    value: 10001753,
  },
  {
    label: 'Mogriguy, New South Wales',
    value: 10002547,
  },
  {
    label: 'Mogumber, Western Australia',
    value: 10009675,
  },
  {
    label: 'Moil, Northern Territory',
    value: 10002681,
  },
  {
    label: 'Moina, Tasmania',
    value: 10006574,
  },
  {
    label: 'Moira, New South Wales',
    value: 10002240,
  },
  {
    label: 'Mokine, Western Australia',
    value: 10009408,
  },
  {
    label: 'Molangul, Queensland',
    value: 10004343,
  },
  {
    label: 'Mole Creek, Tasmania',
    value: 10006523,
  },
  {
    label: 'Molendinar, Queensland',
    value: 10003142,
  },
  {
    label: 'Molesworth, Tasmania',
    value: 10006234,
  },
  {
    label: 'Molesworth, Victoria',
    value: 10007968,
  },
  {
    label: 'Moliagul, Victoria',
    value: 10007586,
  },
  {
    label: 'Mollerin, Western Australia',
    value: 10009607,
  },
  {
    label: 'Mollongghip, Victoria',
    value: 10007365,
  },
  {
    label: 'Molloy Island, Western Australia',
    value: 10009036,
  },
  {
    label: 'Mollymook, New South Wales',
    value: 10001785,
  },
  {
    label: 'Molong, New South Wales',
    value: 10002627,
  },
  {
    label: 'Moltema, Tasmania',
    value: 10006535,
  },
  {
    label: 'Molyullah, Victoria',
    value: 10007871,
  },
  {
    label: 'Mona Park, Queensland',
    value: 10004843,
  },
  {
    label: 'Mona Vale, New South Wales',
    value: 10000166,
  },
  {
    label: 'Mona Vale, New South Wales',
    value: 10000399,
  },
  {
    label: 'Monak, New South Wales',
    value: 10002291,
  },
  {
    label: 'Monal, Queensland',
    value: 10004200,
  },
  {
    label: 'Monarto South, South Australia',
    value: 10005711,
  },
  {
    label: 'Monash, Australian Capital Territory',
    value: 10000106,
  },
  {
    label: 'Monash, South Australia',
    value: 10005807,
  },
  {
    label: 'Monash Park, New South Wales',
    value: 10000421,
  },
  {
    label: 'Monash University, Victoria',
    value: 10008084,
  },
  {
    label: 'Monbulk, Victoria',
    value: 10008072,
  },
  {
    label: 'Monduran, Queensland',
    value: 10004342,
  },
  {
    label: 'Mondure, Queensland',
    value: 10004090,
  },
  {
    label: 'Monga, New South Wales',
    value: 10002049,
  },
  {
    label: 'Mongarlowe, New South Wales',
    value: 10002047,
  },
  {
    label: 'Mongogarie, New South Wales',
    value: 10001513,
  },
  {
    label: 'Monjebup, Western Australia',
    value: 10009265,
  },
  {
    label: 'Monjingup, Western Australia',
    value: 10009561,
  },
  {
    label: 'Monkerai, New South Wales',
    value: 10001266,
  },
  {
    label: 'Monkland, Queensland',
    value: 10003995,
  },
  {
    label: 'Monogorilby, Queensland',
    value: 10004182,
  },
  {
    label: 'Monomeith, Victoria',
    value: 10008370,
  },
  {
    label: 'Monsildale, Queensland',
    value: 10003843,
  },
  {
    label: 'Mont Albert, Victoria',
    value: 10006883,
  },
  {
    label: 'Mont Albert North, Victoria',
    value: 10006890,
  },
  {
    label: 'Montacute, South Australia',
    value: 10005585,
  },
  {
    label: 'Montagu, Tasmania',
    value: 10006659,
  },
  {
    label: 'Montagu Bay, Tasmania',
    value: 10006108,
  },
  {
    label: 'Montana, Tasmania',
    value: 10006525,
  },
  {
    label: 'Monteagle, New South Wales',
    value: 10002026,
  },
  {
    label: 'Montello, Tasmania',
    value: 10006614,
  },
  {
    label: 'Monterey, New South Wales',
    value: 10000657,
  },
  {
    label: 'Montmorency, Victoria',
    value: 10006837,
  },
  {
    label: 'Monto, Queensland',
    value: 10004203,
  },
  {
    label: 'Montrose, Tasmania',
    value: 10006087,
  },
  {
    label: 'Montrose, Victoria',
    value: 10008046,
  },
  {
    label: 'Montville, Queensland',
    value: 10003923,
  },
  {
    label: 'Mooball, New South Wales',
    value: 10001605,
  },
  {
    label: 'Moodiarrup, Western Australia',
    value: 10009376,
  },
  {
    label: 'Mooga, Queensland',
    value: 10003736,
  },
  {
    label: 'Moogara, Tasmania',
    value: 10006249,
  },
  {
    label: 'Moojebing, Western Australia',
    value: 10009131,
  },
  {
    label: 'Moolap, Victoria',
    value: 10007092,
  },
  {
    label: 'Moolboolaman, Queensland',
    value: 10004353,
  },
  {
    label: 'Mooliabeenee, Western Australia',
    value: 10009673,
  },
  {
    label: 'Mooloo, Queensland',
    value: 10003991,
  },
  {
    label: 'Mooloolaba, Queensland',
    value: 10003910,
  },
  {
    label: 'Mooloolah, Queensland',
    value: 10003899,
  },
  {
    label: 'Moombooldool, New South Wales',
    value: 10002182,
  },
  {
    label: 'Moombra, Queensland',
    value: 10003306,
  },
  {
    label: 'Moomin, Queensland',
    value: 10005217,
  },
  {
    label: 'Moonah, Tasmania',
    value: 10006081,
  },
  {
    label: 'Moonambel, Victoria',
    value: 10007596,
  },
  {
    label: 'Moonan Brook, New South Wales',
    value: 10001100,
  },
  {
    label: 'Moonan Flat, New South Wales',
    value: 10001111,
  },
  {
    label: 'Moonbi, New South Wales',
    value: 10001161,
  },
  {
    label: 'Moondah, Western Australia',
    value: 10009666,
  },
  {
    label: 'Moondarra, Victoria',
    value: 10008136,
  },
  {
    label: 'Moondooner, Queensland',
    value: 10004048,
  },
  {
    label: 'Moondyne, Western Australia',
    value: 10009854,
  },
  {
    label: 'Moonee Beach, New South Wales',
    value: 10001437,
  },
  {
    label: 'Moonee Ponds, Victoria',
    value: 10006743,
  },
  {
    label: 'Mooney Mooney, New South Wales',
    value: 10000354,
  },
  {
    label: 'Moongan, Queensland',
    value: 10004596,
  },
  {
    label: 'Moonie, Queensland',
    value: 10003655,
  },
  {
    label: 'Moonies Hill, Western Australia',
    value: 10009148,
  },
  {
    label: 'Moonta, South Australia',
    value: 10005944,
  },
  {
    label: 'Moonta Bay, South Australia',
    value: 10005946,
  },
  {
    label: 'Moonta Mines, South Australia',
    value: 10005951,
  },
  {
    label: 'Moonyoonooka, Western Australia',
    value: 10009797,
  },
  {
    label: 'Moora, Victoria',
    value: 10007772,
  },
  {
    label: 'Moora, Western Australia',
    value: 10009686,
  },
  {
    label: 'Moorabbin, Victoria',
    value: 10007017,
  },
  {
    label: 'Moorabbin Airport, Victoria',
    value: 10007029,
  },
  {
    label: 'Moorabbin East, Victoria',
    value: 10007019,
  },
  {
    label: 'Moorabool, Victoria',
    value: 10007101,
  },
  {
    label: 'Moorak, South Australia',
    value: 10005766,
  },
  {
    label: 'Mooral Creek, New South Wales',
    value: 10001323,
  },
  {
    label: 'Moore, Queensland',
    value: 10003286,
  },
  {
    label: 'Moore Park, New South Wales',
    value: 10000138,
  },
  {
    label: 'Moore Park Beach, Queensland',
    value: 10004332,
  },
  {
    label: 'Moore River National Park, Western Australia',
    value: 10009664,
  },
  {
    label: 'Moorebank, New South Wales',
    value: 10000188,
  },
  {
    label: 'Moorebank, New South Wales',
    value: 10000569,
  },
  {
    label: 'Moores Pocket, Queensland',
    value: 10003235,
  },
  {
    label: 'Mooriary, Western Australia',
    value: 10009724,
  },
  {
    label: 'Moorina, Tasmania',
    value: 10006466,
  },
  {
    label: 'Moorine Rock, Western Australia',
    value: 10009460,
  },
  {
    label: 'Moorland, New South Wales',
    value: 10001388,
  },
  {
    label: 'Moorland, Queensland',
    value: 10004323,
  },
  {
    label: 'Moorlands, South Australia',
    value: 10005774,
  },
  {
    label: 'Moorleah, Tasmania',
    value: 10006634,
  },
  {
    label: 'Mooroobool, Queensland',
    value: 10005098,
  },
  {
    label: 'Moorooduc, Victoria',
    value: 10008278,
  },
  {
    label: 'Moorook, South Australia',
    value: 10005799,
  },
  {
    label: 'Moorooka, Queensland',
    value: 10002950,
  },
  {
    label: 'Mooroolbark, Victoria',
    value: 10006915,
  },
  {
    label: 'Mooroopna, Victoria',
    value: 10007797,
  },
  {
    label: 'Moraby, Queensland',
    value: 10003673,
  },
  {
    label: 'Moranbah, Queensland',
    value: 10004744,
  },
  {
    label: 'Morangup, Western Australia',
    value: 10008598,
  },
  {
    label: 'Morans Crossing, New South Wales',
    value: 10001856,
  },
  {
    label: 'Morawa, Western Australia',
    value: 10009895,
  },
  {
    label: 'Morayfield, Queensland',
    value: 10003815,
  },
  {
    label: 'Morbinning, Western Australia',
    value: 10009069,
  },
  {
    label: 'Morchard, South Australia',
    value: 10005868,
  },
  {
    label: 'Mordalup, Western Australia',
    value: 10008937,
  },
  {
    label: 'Mordialloc, Victoria',
    value: 10007035,
  },
  {
    label: 'Moree, New South Wales',
    value: 10001249,
  },
  {
    label: 'Moreland, Victoria',
    value: 10006782,
  },
  {
    label: 'Morella, Queensland',
    value: 10004643,
  },
  {
    label: 'Moresby, Queensland',
    value: 10005119,
  },
  {
    label: 'Moresby, Western Australia',
    value: 10009747,
  },
  {
    label: 'Morgan, South Australia',
    value: 10005793,
  },
  {
    label: 'Morgantown, Western Australia',
    value: 10009935,
  },
  {
    label: 'Morganville, Queensland',
    value: 10004364,
  },
  {
    label: 'Moriac, Victoria',
    value: 10007143,
  },
  {
    label: 'Moriarty, Tasmania',
    value: 10006568,
  },
  {
    label: 'Morinish, Queensland',
    value: 10004474,
  },
  {
    label: 'Morinish South, Queensland',
    value: 10004527,
  },
  {
    label: 'Morisset, New South Wales',
    value: 10000822,
  },
  {
    label: 'Morley, Western Australia',
    value: 10008545,
  },
  {
    label: 'Morley, Western Australia',
    value: 10010123,
  },
  {
    label: 'Morningside, Queensland',
    value: 10003053,
  },
  {
    label: 'Mornington, Queensland',
    value: 10004972,
  },
  {
    label: 'Mornington, Tasmania',
    value: 10006107,
  },
  {
    label: 'Mornington, Victoria',
    value: 10008277,
  },
  {
    label: 'Mornington, Western Australia',
    value: 10008819,
  },
  {
    label: 'Morpeth, New South Wales',
    value: 10000978,
  },
  {
    label: 'Morphett Vale, South Australia',
    value: 10005630,
  },
  {
    label: 'Morphettville, South Australia',
    value: 10005353,
  },
  {
    label: 'Morrisons, Victoria',
    value: 10007304,
  },
  {
    label: 'Mortdale, New South Wales',
    value: 10000671,
  },
  {
    label: 'Mortlake, New South Wales',
    value: 10000472,
  },
  {
    label: 'Mortlake, Victoria',
    value: 10007208,
  },
  {
    label: 'Morton Vale, Queensland',
    value: 10003346,
  },
  {
    label: 'Morundah, New South Wales',
    value: 10002224,
  },
  {
    label: 'Moruya, New South Wales',
    value: 10001762,
  },
  {
    label: 'Moruya Heads, New South Wales',
    value: 10001760,
  },
  {
    label: 'Morven, New South Wales',
    value: 10002174,
  },
  {
    label: 'Morven, Queensland',
    value: 10003743,
  },
  {
    label: 'Morwell, Victoria',
    value: 10008144,
  },
  {
    label: 'Mosman, New South Wales',
    value: 10000365,
  },
  {
    label: 'Mosman Park, Queensland',
    value: 10004954,
  },
  {
    label: 'Mosman Park, Western Australia',
    value: 10008421,
  },
  {
    label: 'Mosman Park, Western Australia',
    value: 10010097,
  },
  {
    label: 'Mosquito Creek, Queensland',
    value: 10003599,
  },
  {
    label: 'Moss Vale, New South Wales',
    value: 10001958,
  },
  {
    label: 'Mossgiel, New South Wales',
    value: 10002663,
  },
  {
    label: 'Mossiface, Victoria',
    value: 10008218,
  },
  {
    label: 'Mossman, Queensland',
    value: 10005182,
  },
  {
    label: 'Mossy Point, New South Wales',
    value: 10001757,
  },
  {
    label: 'Motley, Queensland',
    value: 10003512,
  },
  {
    label: 'Moulamein, New South Wales',
    value: 10002285,
  },
  {
    label: 'Moulden, Northern Territory',
    value: 10002742,
  },
  {
    label: 'Moulyinning, Western Australia',
    value: 10009276,
  },
  {
    label: 'Mount Abundance, Queensland',
    value: 10003727,
  },
  {
    label: 'Mount Adams, Western Australia',
    value: 10009737,
  },
  {
    label: 'Mount Alfred, Victoria',
    value: 10007906,
  },
  {
    label: 'Mount Alma, Queensland',
    value: 10004421,
  },
  {
    label: 'Mount Anketell, Western Australia',
    value: 10009973,
  },
  {
    label: 'Mount Annan, New South Wales',
    value: 10001914,
  },
  {
    label: 'Mount Archer, Queensland',
    value: 10003839,
  },
  {
    label: 'Mount Archer, Queensland',
    value: 10004463,
  },
  {
    label: 'Mount Austin, New South Wales',
    value: 10002137,
  },
  {
    label: 'Mount Barker, South Australia',
    value: 10005702,
  },
  {
    label: 'Mount Barker, Western Australia',
    value: 10009155,
  },
  {
    label: 'Mount Beauty, Victoria',
    value: 10007932,
  },
  {
    label: 'Mount Best, Victoria',
    value: 10008323,
  },
  {
    label: 'Mount Bindango, Queensland',
    value: 10003735,
  },
  {
    label: 'Mount Binga, Queensland',
    value: 10003249,
  },
  {
    label: 'Mount Bryan, South Australia',
    value: 10005862,
  },
  {
    label: 'Mount Budd, Western Australia',
    value: 10009722,
  },
  {
    label: 'Mount Buffalo, Victoria',
    value: 10008007,
  },
  {
    label: 'Mount Buller, Victoria',
    value: 10007978,
  },
  {
    label: 'Mount Burges, Western Australia',
    value: 10009483,
  },
  {
    label: 'Mount Burr, South Australia',
    value: 10005752,
  },
  {
    label: 'Mount Byron, Queensland',
    value: 10003304,
  },
  {
    label: 'Mount Carbine, Queensland',
    value: 10005139,
  },
  {
    label: 'Mount Caroline, Western Australia',
    value: 10009429,
  },
  {
    label: 'Mount Chalmers, Queensland',
    value: 10004524,
  },
  {
    label: 'Mount Charlton, Queensland',
    value: 10004724,
  },
  {
    label: 'Mount Claremont, Western Australia',
    value: 10008414,
  },
  {
    label: 'Mount Clarence, Western Australia',
    value: 10009216,
  },
  {
    label: 'Mount Clear, Victoria',
    value: 10007337,
  },
  {
    label: 'Mount Colah, New South Wales',
    value: 10000346,
  },
  {
    label: 'Mount Compass, South Australia',
    value: 10005664,
  },
  {
    label: 'Mount Cooke, Western Australia',
    value: 10009353,
  },
  {
    label: 'Mount Coolon, Queensland',
    value: 10004813,
  },
  {
    label: 'Mount Coolum, Queensland',
    value: 10004006,
  },
  {
    label: 'Mount Coot-Tha, Queensland',
    value: 10002900,
  },
  {
    label: 'Mount Cotton, Queensland',
    value: 10003047,
  },
  {
    label: 'Mount Cottrell, Victoria',
    value: 10006715,
  },
  {
    label: 'Mount Crosby, Queensland',
    value: 10003278,
  },
  {
    label: 'Mount Dandenong, Victoria',
    value: 10008048,
  },
  {
    label: 'Mount David, New South Wales',
    value: 10002464,
  },
  {
    label: 'Mount Debateable, Queensland',
    value: 10004163,
  },
  {
    label: 'Mount Delaney, Queensland',
    value: 10003837,
  },
  {
    label: 'Mount Direction, Tasmania',
    value: 10006401,
  },
  {
    label: 'Mount Druitt, New South Wales',
    value: 10002375,
  },
  {
    label: 'Mount Duneed, Victoria',
    value: 10007073,
  },
  {
    label: 'Mount Egerton, Victoria',
    value: 10007383,
  },
  {
    label: 'Mount Eliza, Victoria',
    value: 10008276,
  },
  {
    label: 'Mount Elphinstone, Western Australia',
    value: 10009199,
  },
  {
    label: 'Mount Emlyn, Queensland',
    value: 10003522,
  },
  {
    label: 'Mount Enniskillen, Queensland',
    value: 10003747,
  },
  {
    label: 'Mount Erin, Western Australia',
    value: 10009813,
  },
  {
    label: 'Mount Evelyn, Victoria',
    value: 10008074,
  },
  {
    label: 'Mount Field, Tasmania',
    value: 10006232,
  },
  {
    label: 'Mount Fox, Queensland',
    value: 10005007,
  },
  {
    label: 'Mount Gambier, South Australia',
    value: 10005759,
  },
  {
    label: 'Mount Gambier, South Australia',
    value: 10005765,
  },
  {
    label: 'Mount Gambier East, South Australia',
    value: 10005764,
  },
  {
    label: 'Mount Gambier West, South Australia',
    value: 10005768,
  },
  {
    label: 'Mount Gardiner, Queensland',
    value: 10004570,
  },
  {
    label: 'Mount Garnet, Queensland',
    value: 10005162,
  },
  {
    label: 'Mount George, New South Wales',
    value: 10001301,
  },
  {
    label: 'Mount George, South Australia',
    value: 10005611,
  },
  {
    label: 'Mount Glorious, Queensland',
    value: 10003858,
  },
  {
    label: 'Mount Gravatt, Queensland',
    value: 10002999,
  },
  {
    label: 'Mount Gravatt East, Queensland',
    value: 10002996,
  },
  {
    label: 'Mount Hampton, Western Australia',
    value: 10009469,
  },
  {
    label: 'Mount Hardey, Western Australia',
    value: 10009053,
  },
  {
    label: 'Mount Hawthorn, Western Australia',
    value: 10008426,
  },
  {
    label: 'Mount Hawthorn, Western Australia',
    value: 10010100,
  },
  {
    label: 'Mount Helen, Victoria',
    value: 10007341,
  },
  {
    label: 'Mount Helena, Western Australia',
    value: 10008596,
  },
  {
    label: 'Mount Hicks, Tasmania',
    value: 10006650,
  },
  {
    label: 'Mount Hill, Western Australia',
    value: 10009742,
  },
  {
    label: 'Mount Holland, Western Australia',
    value: 10009465,
  },
  {
    label: 'Mount Hope, New South Wales',
    value: 10002656,
  },
  {
    label: 'Mount Horeb, New South Wales',
    value: 10002276,
  },
  {
    label: 'Mount Horner, Western Australia',
    value: 10009740,
  },
  {
    label: 'Mount Hotham, Victoria',
    value: 10008009,
  },
  {
    label: 'Mount Hunter, New South Wales',
    value: 10001928,
  },
  {
    label: 'Mount Hutton, New South Wales',
    value: 10000893,
  },
  {
    label: 'Mount Hutton, Queensland',
    value: 10003717,
  },
  {
    label: 'Mount Irvine, New South Wales',
    value: 10002417,
  },
  {
    label: 'Mount Irving, Queensland',
    value: 10003627,
  },
  {
    label: 'Mount Isa, Queensland',
    value: 10004965,
  },
  {
    label: 'Mount Jackson, Western Australia',
    value: 10009471,
  },
  {
    label: 'Mount Jukes, Queensland',
    value: 10004684,
  },
  {
    label: 'Mount Keira, New South Wales',
    value: 10001648,
  },
  {
    label: 'Mount Kelly, Queensland',
    value: 10004838,
  },
  {
    label: 'Mount Kembla, New South Wales',
    value: 10001688,
  },
  {
    label: 'Mount Kuring-Gai, New South Wales',
    value: 10000347,
  },
  {
    label: 'Mount Kynoch, Queensland',
    value: 10003394,
  },
  {
    label: 'Mount Larcom, Queensland',
    value: 10004433,
  },
  {
    label: 'Mount Lawless, Queensland',
    value: 10004166,
  },
  {
    label: 'Mount Lawley, Western Australia',
    value: 10008496,
  },
  {
    label: 'Mount Lawley, Western Australia',
    value: 10010113,
  },
  {
    label: 'Mount Lewis, New South Wales',
    value: 10000598,
  },
  {
    label: 'Mount Lewis, New South Wales',
    value: 10000616,
  },
  {
    label: 'Mount Lindesay, Western Australia',
    value: 10009229,
  },
  {
    label: 'Mount Lloyd, Tasmania',
    value: 10006245,
  },
  {
    label: 'Mount Lofty, Queensland',
    value: 10003390,
  },
  {
    label: 'Mount Lonarch, Victoria',
    value: 10007581,
  },
  {
    label: 'Mount Louisa, Queensland',
    value: 10004892,
  },
  {
    label: 'Mount Luke, Queensland',
    value: 10003448,
  },
  {
    label: 'Mount Macedon, Victoria',
    value: 10007519,
  },
  {
    label: 'Mount Madden, Western Australia',
    value: 10009302,
  },
  {
    label: 'Mount Magnet, Western Australia',
    value: 10009914,
  },
  {
    label: 'Mount Maria, Queensland',
    value: 10004378,
  },
  {
    label: 'Mount Marshall, Queensland',
    value: 10003565,
  },
  {
    label: 'Mount Martha, Victoria',
    value: 10008279,
  },
  {
    label: 'Mount Martin, Queensland',
    value: 10004758,
  },
  {
    label: 'Mount Mceuen, Queensland',
    value: 10004058,
  },
  {
    label: 'Mount Mee, Queensland',
    value: 10003872,
  },
  {
    label: 'Mount Melville, Western Australia',
    value: 10009212,
  },
  {
    label: 'Mount Molar, Queensland',
    value: 10003557,
  },
  {
    label: 'Mount Molloy, Queensland',
    value: 10005117,
  },
  {
    label: 'Mount Morgan, Queensland',
    value: 10004588,
  },
  {
    label: 'Mount Moriac, Victoria',
    value: 10007141,
  },
  {
    label: 'Mount Moriah, Queensland',
    value: 10003635,
  },
  {
    label: 'Mount Mort, Queensland',
    value: 10003326,
  },
  {
    label: 'Mount Nasura, Western Australia',
    value: 10008643,
  },
  {
    label: 'Mount Nathan, Queensland',
    value: 10003120,
  },
  {
    label: 'Mount Nebo, Queensland',
    value: 10003862,
  },
  {
    label: 'Mount Nelson, Tasmania',
    value: 10006076,
  },
  {
    label: 'Mount Ney, Western Australia',
    value: 10009551,
  },
  {
    label: 'Mount Observation, Western Australia',
    value: 10009050,
  },
  {
    label: 'Mount Olive, New South Wales',
    value: 10001065,
  },
  {
    label: 'Mount Ommaney, Queensland',
    value: 10002922,
  },
  {
    label: 'Mount Osmond, South Australia',
    value: 10005405,
  },
  {
    label: 'Mount Ossa, Queensland',
    value: 10004714,
  },
  {
    label: 'Mount Ousley, New South Wales',
    value: 10001679,
  },
  {
    label: 'Mount Palmer, Western Australia',
    value: 10009464,
  },
  {
    label: 'Mount Pelion, Queensland',
    value: 10004716,
  },
  {
    label: 'Mount Perry, Queensland',
    value: 10004347,
  },
  {
    label: 'Mount Peter, Queensland',
    value: 10005094,
  },
  {
    label: 'Mount Pleasant, New South Wales',
    value: 10001676,
  },
  {
    label: 'Mount Pleasant, Queensland',
    value: 10004669,
  },
  {
    label: 'Mount Pleasant, South Australia',
    value: 10005682,
  },
  {
    label: 'Mount Pleasant, Victoria',
    value: 10007334,
  },
  {
    label: 'Mount Pleasant, Western Australia',
    value: 10008677,
  },
  {
    label: 'Mount Pluto, Queensland',
    value: 10004790,
  },
  {
    label: 'Mount Pritchard, New South Wales',
    value: 10000568,
  },
  {
    label: 'Mount Rascal, Queensland',
    value: 10003400,
  },
  {
    label: 'Mount Richon, Western Australia',
    value: 10008637,
  },
  {
    label: 'Mount Riverview, New South Wales',
    value: 10002389,
  },
  {
    label: 'Mount Romance, Western Australia',
    value: 10009234,
  },
  {
    label: 'Mount Rooper, Queensland',
    value: 10004809,
  },
  {
    label: 'Mount Rowan, Victoria',
    value: 10007379,
  },
  {
    label: 'Mount Rumney, Tasmania',
    value: 10006298,
  },
  {
    label: 'Mount Russell, New South Wales',
    value: 10001183,
  },
  {
    label: 'Mount Saint Thomas, New South Wales',
    value: 10001643,
  },
  {
    label: 'Mount Samson, Queensland',
    value: 10003857,
  },
  {
    label: 'Mount Seymour, Tasmania',
    value: 10006208,
  },
  {
    label: 'Mount Sheila, Western Australia',
    value: 10010041,
  },
  {
    label: 'Mount Sheridan, Queensland',
    value: 10005088,
  },
  {
    label: 'Mount Sheridan, Western Australia',
    value: 10009301,
  },
  {
    label: 'Mount St John, Queensland',
    value: 10004932,
  },
  {
    label: 'Mount Stanley, Queensland',
    value: 10003261,
  },
  {
    label: 'Mount Steadman, Queensland',
    value: 10004217,
  },
  {
    label: 'Mount Stirling, Western Australia',
    value: 10009342,
  },
  {
    label: 'Mount Stromlo, Australian Capital Territory',
    value: 10000053,
  },
  {
    label: 'Mount Stuart, Tasmania',
    value: 10006063,
  },
  {
    label: 'Mount Surprise, Queensland',
    value: 10005152,
  },
  {
    label: 'Mount Surround, Queensland',
    value: 10004853,
  },
  {
    label: 'Mount Sylvia, Queensland',
    value: 10003360,
  },
  {
    label: 'Mount Tamborine, Queensland',
    value: 10003191,
  },
  {
    label: 'Mount Tarcoola, Western Australia',
    value: 10009751,
  },
  {
    label: 'Mount Taylor, Victoria',
    value: 10008196,
  },
  {
    label: 'Mount Tom, Queensland',
    value: 10004389,
  },
  {
    label: 'Mount Torrens, South Australia',
    value: 10005695,
  },
  {
    label: 'Mount Tyson, Queensland',
    value: 10003510,
  },
  {
    label: 'Mount Urah, Queensland',
    value: 10004227,
  },
  {
    label: 'Mount Vernon, New South Wales',
    value: 10000591,
  },
  {
    label: 'Mount Victoria, New South Wales',
    value: 10002416,
  },
  {
    label: 'Mount View, New South Wales',
    value: 10001035,
  },
  {
    label: 'Mount Vincent, New South Wales',
    value: 10001001,
  },
  {
    label: 'Mount Walker, Western Australia',
    value: 10009320,
  },
  {
    label: 'Mount Walker West, Queensland',
    value: 10003325,
  },
  {
    label: 'Mount Wallace, Victoria',
    value: 10007326,
  },
  {
    label: 'Mount Warren Park, Queensland',
    value: 10003082,
  },
  {
    label: 'Mount Warrigal, New South Wales',
    value: 10001691,
  },
  {
    label: 'Mount Waverley, Victoria',
    value: 10006939,
  },
  {
    label: 'Mount Wells, Western Australia',
    value: 10009357,
  },
  {
    label: 'Mount White, New South Wales',
    value: 10000732,
  },
  {
    label: 'Mount Whitestone, Queensland',
    value: 10003379,
  },
  {
    label: 'Mount Wilson, New South Wales',
    value: 10002415,
  },
  {
    label: 'Mount Wyatt, Queensland',
    value: 10004816,
  },
  {
    label: 'Mountain Creek, Queensland',
    value: 10003909,
  },
  {
    label: 'Mountain Gate, Victoria',
    value: 10006956,
  },
  {
    label: 'Mountain River, Tasmania',
    value: 10006174,
  },
  {
    label: 'Moura, Queensland',
    value: 10004614,
  },
  {
    label: 'Mourilyan, Queensland',
    value: 10005037,
  },
  {
    label: 'Mourilyan Harbour, Queensland',
    value: 10005039,
  },
  {
    label: 'Mouroubra, Western Australia',
    value: 10009603,
  },
  {
    label: 'Mourquong, New South Wales',
    value: 10002124,
  },
  {
    label: 'Moutajup, Victoria',
    value: 10007247,
  },
  {
    label: 'Mowbray, Tasmania',
    value: 10006378,
  },
  {
    label: 'Moy Pocket, Queensland',
    value: 10004011,
  },
  {
    label: 'Moyhu, Victoria',
    value: 10007995,
  },
  {
    label: 'Moyston, Victoria',
    value: 10007438,
  },
  {
    label: 'Mp Creek, Queensland',
    value: 10004056,
  },
  {
    label: 'Mt Britton, Queensland',
    value: 10004737,
  },
  {
    label: 'Muchea, Western Australia',
    value: 10009650,
  },
  {
    label: 'Muckadilla, Queensland',
    value: 10003739,
  },
  {
    label: 'Muckenburra, Western Australia',
    value: 10009667,
  },
  {
    label: 'Muckleford, Victoria',
    value: 10007540,
  },
  {
    label: 'Muckleford South, Victoria',
    value: 10007562,
  },
  {
    label: 'Mudgee, New South Wales',
    value: 10002617,
  },
  {
    label: 'Mudgeeraba, Queensland',
    value: 10003137,
  },
  {
    label: 'Mudgegonga, Victoria',
    value: 10008000,
  },
  {
    label: 'Mudjimba, Queensland',
    value: 10003934,
  },
  {
    label: 'Mudlo, Queensland',
    value: 10004028,
  },
  {
    label: 'Mueller Ranges, Western Australia',
    value: 10010064,
  },
  {
    label: 'Muirlea, Queensland',
    value: 10003262,
  },
  {
    label: 'Muja, Western Australia',
    value: 10008836,
  },
  {
    label: 'Mukinbudin, Western Australia',
    value: 10009624,
  },
  {
    label: 'Mulambin, Queensland',
    value: 10004544,
  },
  {
    label: 'Mulara, Queensland',
    value: 10004546,
  },
  {
    label: 'Mulataga, Western Australia',
    value: 10009978,
  },
  {
    label: 'Mulbring, New South Wales',
    value: 10000997,
  },
  {
    label: 'Muldu, Queensland',
    value: 10003628,
  },
  {
    label: 'Mulga Downs, Western Australia',
    value: 10010043,
  },
  {
    label: 'Mulgildie, Queensland',
    value: 10004196,
  },
  {
    label: 'Mulgoa, New South Wales',
    value: 10002294,
  },
  {
    label: 'Mulgrave, New South Wales',
    value: 10002336,
  },
  {
    label: 'Mulgrave, Queensland',
    value: 10004836,
  },
  {
    label: 'Mulgrave, Victoria',
    value: 10006982,
  },
  {
    label: 'Mullaley, New South Wales',
    value: 10001219,
  },
  {
    label: 'Mullaloo, Western Australia',
    value: 10008460,
  },
  {
    label: 'Mullalyup, Western Australia',
    value: 10008906,
  },
  {
    label: 'Mullengandra, New South Wales',
    value: 10002110,
  },
  {
    label: 'Mullengudgery, New South Wales',
    value: 10002537,
  },
  {
    label: 'Mullett Creek, Queensland',
    value: 10004327,
  },
  {
    label: 'Mullewa, Western Australia',
    value: 10009901,
  },
  {
    label: 'Mullingar, Western Australia',
    value: 10009495,
  },
  {
    label: 'Mullion Creek, New South Wales',
    value: 10002505,
  },
  {
    label: 'Mullumbimby, New South Wales',
    value: 10001598,
  },
  {
    label: 'Muluckine, Western Australia',
    value: 10009411,
  },
  {
    label: 'Mulwala, New South Wales',
    value: 10002121,
  },
  {
    label: 'Mumballup, Western Australia',
    value: 10008834,
  },
  {
    label: 'Mumberkine, Western Australia',
    value: 10009396,
  },
  {
    label: 'Mumbil, New South Wales',
    value: 10002523,
  },
  {
    label: 'Mumbulla Mountain, New South Wales',
    value: 10001858,
  },
  {
    label: 'Munbura, Queensland',
    value: 10004698,
  },
  {
    label: 'Mundabullangana, Western Australia',
    value: 10010006,
  },
  {
    label: 'Mundaring, Western Australia',
    value: 10008581,
  },
  {
    label: 'Mundijong, Western Australia',
    value: 10008651,
  },
  {
    label: 'Mundingburra, Queensland',
    value: 10004883,
  },
  {
    label: 'Mundoo, Queensland',
    value: 10005049,
  },
  {
    label: 'Mundoora, South Australia',
    value: 10005937,
  },
  {
    label: 'Mundowran, Queensland',
    value: 10004175,
  },
  {
    label: 'Mundrabilla, Western Australia',
    value: 10009540,
  },
  {
    label: 'Mundubbera, Queensland',
    value: 10004168,
  },
  {
    label: 'Mundulla, South Australia',
    value: 10005739,
  },
  {
    label: 'Mungabunda, Queensland',
    value: 10004613,
  },
  {
    label: 'Mungallala, Queensland',
    value: 10003742,
  },
  {
    label: 'Mungalup, Western Australia',
    value: 10008840,
  },
  {
    label: 'Mungar, Queensland',
    value: 10004239,
  },
  {
    label: 'Mungindi, New South Wales',
    value: 10001260,
  },
  {
    label: 'Munglinup, Western Australia',
    value: 10009577,
  },
  {
    label: 'Mungy, Queensland',
    value: 10004362,
  },
  {
    label: 'Muniganeen, Queensland',
    value: 10003420,
  },
  {
    label: 'Munna Creek, Queensland',
    value: 10004001,
  },
  {
    label: 'Munno Para, South Australia',
    value: 10005558,
  },
  {
    label: 'Munruben, Queensland',
    value: 10003005,
  },
  {
    label: 'Munster, Western Australia',
    value: 10008725,
  },
  {
    label: 'Muntadgin, Western Australia',
    value: 10009447,
  },
  {
    label: 'Muradup, Western Australia',
    value: 10009378,
  },
  {
    label: 'Murarrie, Queensland',
    value: 10003058,
  },
  {
    label: 'Murchison, Victoria',
    value: 10007771,
  },
  {
    label: 'Murchison, Western Australia',
    value: 10009904,
  },
  {
    label: 'Murdinga, South Australia',
    value: 10005999,
  },
  {
    label: 'Murdoch, Western Australia',
    value: 10008669,
  },
  {
    label: 'Murdong, Western Australia',
    value: 10009127,
  },
  {
    label: 'Murdunna, Tasmania',
    value: 10006317,
  },
  {
    label: 'Muresk, Western Australia',
    value: 10009403,
  },
  {
    label: 'Murga, New South Wales',
    value: 10002624,
  },
  {
    label: 'Murgheboluc, Victoria',
    value: 10007096,
  },
  {
    label: 'Murgon, Queensland',
    value: 10004055,
  },
  {
    label: 'Murmungee, Victoria',
    value: 10008020,
  },
  {
    label: 'Muronbung, New South Wales',
    value: 10002545,
  },
  {
    label: 'Murphys Creek, Queensland',
    value: 10003457,
  },
  {
    label: 'Murphys Creek, Victoria',
    value: 10007695,
  },
  {
    label: 'Murrabit, Victoria',
    value: 10007747,
  },
  {
    label: 'Murrami, New South Wales',
    value: 10002231,
  },
  {
    label: 'Murray, Queensland',
    value: 10004891,
  },
  {
    label: 'Murray Bridge, South Australia',
    value: 10005707,
  },
  {
    label: 'Murray Bridge, South Australia',
    value: 10005710,
  },
  {
    label: 'Murray Island, New South Wales',
    value: 10010199,
  },
  {
    label: 'Murray Region Mc, New South Wales',
    value: 10002236,
  },
  {
    label: 'Murray Town, South Australia',
    value: 10005896,
  },
  {
    label: 'Murray Upper, Queensland',
    value: 10005018,
  },
  {
    label: 'Murrayville, Victoria',
    value: 10007630,
  },
  {
    label: 'Murrindindi, Victoria',
    value: 10007963,
  },
  {
    label: 'Murringo, New South Wales',
    value: 10002014,
  },
  {
    label: 'Murroon, Victoria',
    value: 10007149,
  },
  {
    label: 'Murrumba Downs, Queensland',
    value: 10003809,
  },
  {
    label: 'Murrumbateman, New South Wales',
    value: 10001994,
  },
  {
    label: 'Murrumbeena, Victoria',
    value: 10006967,
  },
  {
    label: 'Murrumburrah, New South Wales',
    value: 10002020,
  },
  {
    label: 'Murrurundi, New South Wales',
    value: 10001113,
  },
  {
    label: 'Murtoa, Victoria',
    value: 10007459,
  },
  {
    label: 'Murwillumbah, New South Wales',
    value: 10001621,
  },
  {
    label: 'Muscle Creek, New South Wales',
    value: 10001086,
  },
  {
    label: 'Musk, Victoria',
    value: 10007559,
  },
  {
    label: 'Musselroe Bay, Tasmania',
    value: 10006463,
  },
  {
    label: 'Muswellbrook, New South Wales',
    value: 10001085,
  },
  {
    label: 'Mutarnee, Queensland',
    value: 10004922,
  },
  {
    label: 'Mutchilba, Queensland',
    value: 10005174,
  },
  {
    label: 'Muttaburra, Queensland',
    value: 10004647,
  },
  {
    label: 'Muttama, New South Wales',
    value: 10002267,
  },
  {
    label: 'Myall Park, Queensland',
    value: 10003667,
  },
  {
    label: 'Myalla, Tasmania',
    value: 10006639,
  },
  {
    label: 'Myalup, Western Australia',
    value: 10008817,
  },
  {
    label: 'Myara, Western Australia',
    value: 10008760,
  },
  {
    label: 'Myaree, Western Australia',
    value: 10008685,
  },
  {
    label: 'Myaree Bc, Western Australia',
    value: 10010137,
  },
  {
    label: 'Myers Flat, Victoria',
    value: 10007710,
  },
  {
    label: 'Mylestom, New South Wales',
    value: 10001455,
  },
  {
    label: 'Mylor, South Australia',
    value: 10005606,
  },
  {
    label: 'Myola, New South Wales',
    value: 10001815,
  },
  {
    label: 'Mypolonga, South Australia',
    value: 10005708,
  },
  {
    label: 'Myponga, South Australia',
    value: 10005657,
  },
  {
    label: 'Myrniong, Victoria',
    value: 10007322,
  },
  {
    label: 'Myrtle Bank, South Australia',
    value: 10005404,
  },
  {
    label: 'Myrtle Bank, Tasmania',
    value: 10006443,
  },
  {
    label: 'Myrtlebank, Victoria',
    value: 10008162,
  },
  {
    label: 'Myrtleford, Victoria',
    value: 10007999,
  },
  {
    label: 'Myrtleford, Victoria',
    value: 10008003,
  },
  {
    label: 'Myrtlevale, Queensland',
    value: 10004797,
  },
  {
    label: 'Myrup, Western Australia',
    value: 10009573,
  },
  {
    label: 'Mysia, Victoria',
    value: 10007644,
  },
  {
    label: 'Mysterton, Queensland',
    value: 10004882,
  },
  {
    label: 'Nabageena, Tasmania',
    value: 10006669,
  },
  {
    label: 'Nabawa, Western Australia',
    value: 10009791,
  },
  {
    label: 'Nabiac, New South Wales',
    value: 10000946,
  },
  {
    label: 'Nabowla, Tasmania',
    value: 10006452,
  },
  {
    label: 'Nagambie, Victoria',
    value: 10007766,
  },
  {
    label: 'Nahrunda, Queensland',
    value: 10003975,
  },
  {
    label: 'Nailsworth, South Australia',
    value: 10005479,
  },
  {
    label: 'Nain, South Australia',
    value: 10005836,
  },
  {
    label: 'Nairibin, Western Australia',
    value: 10009272,
  },
  {
    label: 'Nairne, South Australia',
    value: 10005705,
  },
  {
    label: 'Nakara, Northern Territory',
    value: 10002689,
  },
  {
    label: 'Nalangil, Victoria',
    value: 10007163,
  },
  {
    label: 'Nalkain, Western Australia',
    value: 10009633,
  },
  {
    label: 'Nalyappa, South Australia',
    value: 10005949,
  },
  {
    label: 'Nalyerlup, Western Australia',
    value: 10009262,
  },
  {
    label: 'Namban, Western Australia',
    value: 10009694,
  },
  {
    label: 'Nambeelup, Western Australia',
    value: 10008757,
  },
  {
    label: 'Nambour, Queensland',
    value: 10003922,
  },
  {
    label: 'Nambrok, Victoria',
    value: 10008155,
  },
  {
    label: 'Nambucca Heads, New South Wales',
    value: 10001420,
  },
  {
    label: 'Nambung, Western Australia',
    value: 10009714,
  },
  {
    label: 'Nana Glen, New South Wales',
    value: 10001436,
  },
  {
    label: 'Nanango, Queensland',
    value: 10004106,
  },
  {
    label: 'Nanarup, Western Australia',
    value: 10009222,
  },
  {
    label: 'Nandaly, Victoria',
    value: 10007658,
  },
  {
    label: 'Nanga Brook, Western Australia',
    value: 10008811,
  },
  {
    label: 'Nangeenan, Western Australia',
    value: 10009437,
  },
  {
    label: 'Nangetty, Western Australia',
    value: 10009728,
  },
  {
    label: 'Nangiloc, Victoria',
    value: 10007610,
  },
  {
    label: 'Nangram, Queensland',
    value: 10003677,
  },
  {
    label: 'Nangus, New South Wales',
    value: 10002268,
  },
  {
    label: 'Nangwarry, South Australia',
    value: 10005747,
  },
  {
    label: 'Nangwee, Queensland',
    value: 10003657,
  },
  {
    label: 'Nankin, Queensland',
    value: 10004452,
  },
  {
    label: 'Nanneella, Victoria',
    value: 10007720,
  },
  {
    label: 'Nannup, Western Australia',
    value: 10008962,
  },
  {
    label: 'Nanson, Western Australia',
    value: 10009779,
  },
  {
    label: 'Nanum, Queensland',
    value: 10005188,
  },
  {
    label: 'Nanutarra, Western Australia',
    value: 10010042,
  },
  {
    label: 'Napier, Western Australia',
    value: 10009195,
  },
  {
    label: 'Napoleons, Victoria',
    value: 10007389,
  },
  {
    label: 'Nar Nar Goon, Victoria',
    value: 10008100,
  },
  {
    label: 'Naracoorte, South Australia',
    value: 10005741,
  },
  {
    label: 'Naradhan, New South Wales',
    value: 10002195,
  },
  {
    label: 'Naraling, Western Australia',
    value: 10009787,
  },
  {
    label: 'Narangba, Queensland',
    value: 10003813,
  },
  {
    label: 'Narara, New South Wales',
    value: 10000713,
  },
  {
    label: 'Narbethong, Victoria',
    value: 10008057,
  },
  {
    label: 'Nareen, Victoria',
    value: 10007277,
  },
  {
    label: 'Narellan, New South Wales',
    value: 10001915,
  },
  {
    label: 'Narellan Dc, New South Wales',
    value: 10001917,
  },
  {
    label: 'Narellan Vale, New South Wales',
    value: 10001919,
  },
  {
    label: 'Narembeen, Western Australia',
    value: 10009321,
  },
  {
    label: 'Naremburn, New South Wales',
    value: 10000314,
  },
  {
    label: 'Nariel Valley, Victoria',
    value: 10007940,
  },
  {
    label: 'Naringal, Victoria',
    value: 10007216,
  },
  {
    label: 'Narko, Queensland',
    value: 10003460,
  },
  {
    label: 'Narngulu, Western Australia',
    value: 10009783,
  },
  {
    label: 'Narooma, New South Wales',
    value: 10001835,
  },
  {
    label: 'Narra Tarra, Western Australia',
    value: 10009767,
  },
  {
    label: 'Narrabeen, New South Wales',
    value: 10000395,
  },
  {
    label: 'Narrabri, New South Wales',
    value: 10001236,
  },
  {
    label: 'Narrabundah, Australian Capital Territory',
    value: 10000028,
  },
  {
    label: 'Narrakine, Western Australia',
    value: 10009366,
  },
  {
    label: 'Narraloggan, Western Australia',
    value: 10009046,
  },
  {
    label: 'Narrambla, New South Wales',
    value: 10002500,
  },
  {
    label: 'Narrandera, New South Wales',
    value: 10002223,
  },
  {
    label: 'Narrapumelap South, Victoria',
    value: 10007244,
  },
  {
    label: 'Narraweena, New South Wales',
    value: 10000386,
  },
  {
    label: 'Narrawong, Victoria',
    value: 10007237,
  },
  {
    label: 'Narre Warren, Victoria',
    value: 10008090,
  },
  {
    label: 'Narre Warren East, Victoria',
    value: 10008087,
  },
  {
    label: 'Narre Warren North, Victoria',
    value: 10008088,
  },
  {
    label: 'Narrikup, Western Australia',
    value: 10009162,
  },
  {
    label: 'Narrogin, Western Australia',
    value: 10009106,
  },
  {
    label: 'Narrogin Valley, Western Australia',
    value: 10009103,
  },
  {
    label: 'Narromine, New South Wales',
    value: 10002530,
  },
  {
    label: 'Narrung, South Australia',
    value: 10005719,
  },
  {
    label: 'Narwee, New South Wales',
    value: 10000636,
  },
  {
    label: 'Nashdale, New South Wales',
    value: 10002497,
  },
  {
    label: 'Nashua, New South Wales',
    value: 10001561,
  },
  {
    label: 'Nathalia, Victoria',
    value: 10007825,
  },
  {
    label: 'Nathan, Queensland',
    value: 10002969,
  },
  {
    label: 'Natimuk, Victoria',
    value: 10007482,
  },
  {
    label: 'National Park, Tasmania',
    value: 10006233,
  },
  {
    label: 'Natone, Tasmania',
    value: 10006624,
  },
  {
    label: 'Nattai, New South Wales',
    value: 10001925,
  },
  {
    label: 'Natte Yallock, Victoria',
    value: 10007576,
  },
  {
    label: 'Natural Bridge, Queensland',
    value: 10003126,
  },
  {
    label: 'Naturaliste, Western Australia',
    value: 10009005,
  },
  {
    label: 'Naughtons Gap, New South Wales',
    value: 10001507,
  },
  {
    label: 'Naval Base, Western Australia',
    value: 10008721,
  },
  {
    label: 'Navarre, Victoria',
    value: 10007452,
  },
  {
    label: 'Navigators, Victoria',
    value: 10007398,
  },
  {
    label: 'Neale, Western Australia',
    value: 10009534,
  },
  {
    label: 'Nearum, Queensland',
    value: 10004344,
  },
  {
    label: 'Nebine, Queensland',
    value: 10003770,
  },
  {
    label: 'Nebo, Queensland',
    value: 10004734,
  },
  {
    label: 'Nedlands, Western Australia',
    value: 10008412,
  },
  {
    label: 'Nedlands, Western Australia',
    value: 10010093,
  },
  {
    label: 'Nedlands, Western Australia',
    value: 10010094,
  },
  {
    label: 'Needilup, Western Australia',
    value: 10009248,
  },
  {
    label: 'Needles, Tasmania',
    value: 10006534,
  },
  {
    label: 'Neendaling, Western Australia',
    value: 10009289,
  },
  {
    label: 'Neerabup, Western Australia',
    value: 10008475,
  },
  {
    label: 'Neergabby, Western Australia',
    value: 10009659,
  },
  {
    label: 'Neerim, Victoria',
    value: 10008139,
  },
  {
    label: 'Neerim Junction, Victoria',
    value: 10008117,
  },
  {
    label: 'Neerim North, Victoria',
    value: 10008115,
  },
  {
    label: 'Neerim South, Victoria',
    value: 10008138,
  },
  {
    label: 'Neika, Tasmania',
    value: 10006161,
  },
  {
    label: 'Neilrex, New South Wales',
    value: 10002551,
  },
  {
    label: 'Nelia, Queensland',
    value: 10004906,
  },
  {
    label: 'Nelligen, New South Wales',
    value: 10001736,
  },
  {
    label: 'Nelly Bay, Queensland',
    value: 10004942,
  },
  {
    label: 'Nelson, New South Wales',
    value: 10001871,
  },
  {
    label: 'Nelson, New South Wales',
    value: 10002364,
  },
  {
    label: 'Nelson, Victoria',
    value: 10007242,
  },
  {
    label: 'Nelson Bay, New South Wales',
    value: 10000951,
  },
  {
    label: 'Nelsons Plains, New South Wales',
    value: 10001020,
  },
  {
    label: 'Nelungaloo, New South Wales',
    value: 10002654,
  },
  {
    label: 'Nembudding, Western Australia',
    value: 10009631,
  },
  {
    label: 'Nemingha, New South Wales',
    value: 10001121,
  },
  {
    label: 'Nerada, Queensland',
    value: 10005052,
  },
  {
    label: 'Nerang, Queensland',
    value: 10003123,
  },
  {
    label: 'Neridup, Western Australia',
    value: 10009569,
  },
  {
    label: 'Nerong, New South Wales',
    value: 10001294,
  },
  {
    label: 'Nerramyne, Western Australia',
    value: 10009906,
  },
  {
    label: 'Nerrena, Victoria',
    value: 10008308,
  },
  {
    label: 'Nerriga, New South Wales',
    value: 10002056,
  },
  {
    label: 'Nerrigundah, New South Wales',
    value: 10001828,
  },
  {
    label: 'Nerrin Nerrin, Victoria',
    value: 10007359,
  },
  {
    label: 'Nerrina, Victoria',
    value: 10007331,
  },
  {
    label: 'Netherby, Queensland',
    value: 10004247,
  },
  {
    label: 'Netherby, South Australia',
    value: 10005391,
  },
  {
    label: 'Nethercote, New South Wales',
    value: 10001848,
  },
  {
    label: 'Netherdale, Queensland',
    value: 10004762,
  },
  {
    label: 'Netley, South Australia',
    value: 10005332,
  },
  {
    label: 'Neuarpurr, Victoria',
    value: 10007489,
  },
  {
    label: 'Neumgna, Queensland',
    value: 10004102,
  },
  {
    label: 'Neurea, New South Wales',
    value: 10002518,
  },
  {
    label: 'Neusa Vale, Queensland',
    value: 10003959,
  },
  {
    label: 'Neutral Bay, New South Wales',
    value: 10000367,
  },
  {
    label: 'Neutral Bay Junction, New South Wales',
    value: 10000366,
  },
  {
    label: 'Nevertire, New South Wales',
    value: 10002557,
  },
  {
    label: 'Neville, New South Wales',
    value: 10002495,
  },
  {
    label: 'Nevilton, Queensland',
    value: 10003549,
  },
  {
    label: 'New Auckland, Queensland',
    value: 10004401,
  },
  {
    label: 'New Berrima, New South Wales',
    value: 10001962,
  },
  {
    label: 'New Brighton, New South Wales',
    value: 10001600,
  },
  {
    label: 'New Chum, Queensland',
    value: 10003216,
  },
  {
    label: 'New England Mc, New South Wales',
    value: 10001148,
  },
  {
    label: 'New Farm, Queensland',
    value: 10002812,
  },
  {
    label: 'New Gisborne, Victoria',
    value: 10007517,
  },
  {
    label: 'New Harbourline, Queensland',
    value: 10005041,
  },
  {
    label: 'New Lambton, New South Wales',
    value: 10000932,
  },
  {
    label: 'New Lambton Heights, New South Wales',
    value: 10000934,
  },
  {
    label: 'New Mollyan, New South Wales',
    value: 10002588,
  },
  {
    label: 'New Moonta, Queensland',
    value: 10004360,
  },
  {
    label: 'New Norcia, Western Australia',
    value: 10009685,
  },
  {
    label: 'New Norfolk, Tasmania',
    value: 10006263,
  },
  {
    label: 'New South Wales, New South Wales',
    value: 10010173,
  },
  {
    label: 'New Town, South Australia',
    value: 10005931,
  },
  {
    label: 'New Town, Tasmania',
    value: 10006078,
  },
  {
    label: 'New Well, South Australia',
    value: 10005835,
  },
  {
    label: 'Newborough, Victoria',
    value: 10008127,
  },
  {
    label: 'Newbridge, New South Wales',
    value: 10002462,
  },
  {
    label: 'Newbridge, Victoria',
    value: 10007688,
  },
  {
    label: 'Newbury, Victoria',
    value: 10007547,
  },
  {
    label: 'Newcarlbeon, Western Australia',
    value: 10009613,
  },
  {
    label: 'Newcastle, New South Wales',
    value: 10000917,
  },
  {
    label: 'Newcastle East, New South Wales',
    value: 10000920,
  },
  {
    label: 'Newcastle University, New South Wales',
    value: 10000937,
  },
  {
    label: 'Newcastle Waters, Northern Territory',
    value: 10002778,
  },
  {
    label: 'Newcastle West, New South Wales',
    value: 10000921,
  },
  {
    label: 'Newcomb, Victoria',
    value: 10007083,
  },
  {
    label: 'Newdegate, Western Australia',
    value: 10009292,
  },
  {
    label: 'Newell, Queensland',
    value: 10005183,
  },
  {
    label: 'Newham, Victoria',
    value: 10007523,
  },
  {
    label: 'Newhaven, Victoria',
    value: 10008268,
  },
  {
    label: 'Newington, New South Wales',
    value: 10000455,
  },
  {
    label: 'Newlands, Queensland',
    value: 10004817,
  },
  {
    label: 'Newlands, Western Australia',
    value: 10008904,
  },
  {
    label: 'Newlands Arm, Victoria',
    value: 10008198,
  },
  {
    label: 'Newlyn, Victoria',
    value: 10007417,
  },
  {
    label: 'Newman, Western Australia',
    value: 10010051,
  },
  {
    label: 'Newmarket, Queensland',
    value: 10002874,
  },
  {
    label: 'Newmerella, Victoria',
    value: 10008220,
  },
  {
    label: 'Newnham, Tasmania',
    value: 10006379,
  },
  {
    label: 'Newport, New South Wales',
    value: 10000405,
  },
  {
    label: 'Newport, Victoria',
    value: 10006695,
  },
  {
    label: 'Newport Beach, New South Wales',
    value: 10000406,
  },
  {
    label: 'Newry, Victoria',
    value: 10008177,
  },
  {
    label: 'Newstead, Queensland',
    value: 10002814,
  },
  {
    label: 'Newstead, Tasmania',
    value: 10006387,
  },
  {
    label: 'Newstead, Victoria',
    value: 10007561,
  },
  {
    label: 'Newton, South Australia',
    value: 10005462,
  },
  {
    label: 'Newton Boyd, New South Wales',
    value: 10001205,
  },
  {
    label: 'Newtown, New South Wales',
    value: 10000275,
  },
  {
    label: 'Newtown, Queensland',
    value: 10003239,
  },
  {
    label: 'Newtown, Queensland',
    value: 10003404,
  },
  {
    label: 'Newtown, Victoria',
    value: 10007085,
  },
  {
    label: 'Nguiu, Northern Territory',
    value: 10002724,
  },
  {
    label: 'Ngukurr, Northern Territory',
    value: 10002766,
  },
  {
    label: 'Ngunnawal, Australian Capital Territory',
    value: 10000121,
  },
  {
    label: 'Nhill, Victoria',
    value: 10007497,
  },
  {
    label: 'Nhulunbuy, Northern Territory',
    value: 10002795,
  },
  {
    label: 'Nhulunbuy, Northern Territory',
    value: 10002797,
  },
  {
    label: 'Niagara Park, New South Wales',
    value: 10000734,
  },
  {
    label: 'Niangala, New South Wales',
    value: 10001164,
  },
  {
    label: 'Nicholls, Australian Capital Territory',
    value: 10000124,
  },
  {
    label: 'Nicholls Rivulet, Tasmania',
    value: 10006194,
  },
  {
    label: 'Nichols Point, Victoria',
    value: 10007621,
  },
  {
    label: 'Nicholson, Victoria',
    value: 10008210,
  },
  {
    label: 'Nickol, Western Australia',
    value: 10009975,
  },
  {
    label: 'Niddrie, Victoria',
    value: 10006750,
  },
  {
    label: 'Nietta, Tasmania',
    value: 10006592,
  },
  {
    label: 'Nightcliff, Northern Territory',
    value: 10002680,
  },
  {
    label: 'Nightcliff, Northern Territory',
    value: 10002701,
  },
  {
    label: 'Nikenbah, Queensland',
    value: 10004262,
  },
  {
    label: 'Nildottie, South Australia',
    value: 10005690,
  },
  {
    label: 'Nile, Tasmania',
    value: 10006344,
  },
  {
    label: 'Nilgen, Western Australia',
    value: 10008494,
  },
  {
    label: 'Nillup, Western Australia',
    value: 10009033,
  },
  {
    label: 'Nilma, Victoria',
    value: 10008118,
  },
  {
    label: 'Nimbin, New South Wales',
    value: 10001567,
  },
  {
    label: 'Nimmitabel, New South Wales',
    value: 10002081,
  },
  {
    label: 'Nindaroo, Queensland',
    value: 10004674,
  },
  {
    label: 'Nine Mile, Queensland',
    value: 10004483,
  },
  {
    label: 'Nine Mile Creek, Queensland',
    value: 10004583,
  },
  {
    label: 'Ningaloo, Western Australia',
    value: 10009942,
  },
  {
    label: 'Ningi, Queensland',
    value: 10003831,
  },
  {
    label: 'Nippering, Western Australia',
    value: 10009274,
  },
  {
    label: 'Nirimba, Western Australia',
    value: 10008766,
  },
  {
    label: 'Nirranda, Victoria',
    value: 10007197,
  },
  {
    label: 'Njatjan, Queensland',
    value: 10005070,
  },
  {
    label: 'No. 4 Branch, Queensland',
    value: 10005031,
  },
  {
    label: 'No. 5 Branch, Queensland',
    value: 10005033,
  },
  {
    label: 'No. 6 Branch, Queensland',
    value: 10005044,
  },
  {
    label: 'Noarlunga Centre, South Australia',
    value: 10005639,
  },
  {
    label: 'Noarlunga Downs, South Australia',
    value: 10005638,
  },
  {
    label: 'Nobby, Queensland',
    value: 10003548,
  },
  {
    label: 'Nobby Beach, Queensland',
    value: 10003160,
  },
  {
    label: 'Noble Park, Victoria',
    value: 10006989,
  },
  {
    label: 'Noble Park North, Victoria',
    value: 10006988,
  },
  {
    label: 'Noggerup, Western Australia',
    value: 10008839,
  },
  {
    label: 'Nokaning, Western Australia',
    value: 10009443,
  },
  {
    label: 'Nolba, Western Australia',
    value: 10009789,
  },
  {
    label: 'Nollamara, Western Australia',
    value: 10008539,
  },
  {
    label: 'Nomans Lake, Western Australia',
    value: 10009100,
  },
  {
    label: 'Nome, Queensland',
    value: 10004912,
  },
  {
    label: 'Nonning, South Australia',
    value: 10006040,
  },
  {
    label: 'Noojee, Victoria',
    value: 10008140,
  },
  {
    label: 'Nook, Tasmania',
    value: 10006558,
  },
  {
    label: 'Noonamah, Northern Territory',
    value: 10002754,
  },
  {
    label: 'Noonbinna, New South Wales',
    value: 10002448,
  },
  {
    label: 'Noorama, Queensland',
    value: 10003773,
  },
  {
    label: 'Noorat, Victoria',
    value: 10007186,
  },
  {
    label: 'Noorinbee, Victoria',
    value: 10008233,
  },
  {
    label: 'Noosa Heads, Queensland',
    value: 10003944,
  },
  {
    label: 'Noosaville, Queensland',
    value: 10003941,
  },
  {
    label: 'Noradjuha, Victoria',
    value: 10007481,
  },
  {
    label: 'Norah Head, New South Wales',
    value: 10000814,
  },
  {
    label: 'Noranda, Western Australia',
    value: 10008543,
  },
  {
    label: 'Noraville, New South Wales',
    value: 10000812,
  },
  {
    label: 'Nords Wharf, New South Wales',
    value: 10000838,
  },
  {
    label: 'Norfolk Island, New South Wales',
    value: 10002670,
  },
  {
    label: 'Norlane, Victoria',
    value: 10007061,
  },
  {
    label: 'Norman Gardens, Queensland',
    value: 10004460,
  },
  {
    label: 'Norman Park, Queensland',
    value: 10003054,
  },
  {
    label: 'Normanhurst, New South Wales',
    value: 10000341,
  },
  {
    label: 'Normanton, Queensland',
    value: 10005227,
  },
  {
    label: 'Normanville, South Australia',
    value: 10005663,
  },
  {
    label: 'Nornalup, Western Australia',
    value: 10009232,
  },
  {
    label: 'Norong, Victoria',
    value: 10007888,
  },
  {
    label: 'Norpa, Western Australia',
    value: 10009442,
  },
  {
    label: 'Norseman, Western Australia',
    value: 10009537,
  },
  {
    label: 'North Adelaide, South Australia',
    value: 10005243,
  },
  {
    label: 'North Adelaide Melbourne St, South Australia',
    value: 10005242,
  },
  {
    label: 'North Albury, New South Wales',
    value: 10002092,
  },
  {
    label: 'North Aramara, Queensland',
    value: 10004129,
  },
  {
    label: 'North Arm Cove, New South Wales',
    value: 10001023,
  },
  {
    label: 'North Avoca, New South Wales',
    value: 10000789,
  },
  {
    label: 'North Baandee, Western Australia',
    value: 10009434,
  },
  {
    label: 'North Bannister, Western Australia',
    value: 10009359,
  },
  {
    label: 'North Batemans Bay, New South Wales',
    value: 10001730,
  },
  {
    label: 'North Beach, Western Australia',
    value: 10008438,
  },
  {
    label: 'North Beach, Western Australia',
    value: 10010106,
  },
  {
    label: 'North Bendigo, Victoria',
    value: 10007681,
  },
  {
    label: 'North Bodallin, Western Australia',
    value: 10009456,
  },
  {
    label: 'North Booval, Queensland',
    value: 10003223,
  },
  {
    label: 'North Boyanup, Western Australia',
    value: 10008878,
  },
  {
    label: 'North Branch, Queensland',
    value: 10003495,
  },
  {
    label: 'North Bungunya, Queensland',
    value: 10003787,
  },
  {
    label: 'North Burngup, Western Australia',
    value: 10009283,
  },
  {
    label: 'North Cascade, Western Australia',
    value: 10009548,
  },
  {
    label: 'North Curl Curl, New South Wales',
    value: 10000383,
  },
  {
    label: 'North Dandalup, Western Australia',
    value: 10008758,
  },
  {
    label: 'North Deep Creek, Queensland',
    value: 10003973,
  },
  {
    label: 'North Eradu, Western Australia',
    value: 10009788,
  },
  {
    label: 'North Fremantle, Western Australia',
    value: 10008696,
  },
  {
    label: 'North Geelong, Victoria',
    value: 10007067,
  },
  {
    label: 'North Greenbushes, Western Australia',
    value: 10008911,
  },
  {
    label: 'North Gregory, Queensland',
    value: 10004281,
  },
  {
    label: 'North Haven, New South Wales',
    value: 10001390,
  },
  {
    label: 'North Haven, South Australia',
    value: 10005290,
  },
  {
    label: 'North Hobart, Tasmania',
    value: 10006067,
  },
  {
    label: 'North Hobart, Tasmania',
    value: 10006069,
  },
  {
    label: 'North Ipswich, Queensland',
    value: 10003243,
  },
  {
    label: 'North Jindong, Western Australia',
    value: 10008986,
  },
  {
    label: 'North Kellerberrin, Western Australia',
    value: 10009428,
  },
  {
    label: 'North Kukerin, Western Australia',
    value: 10009277,
  },
  {
    label: 'North Kununoppin, Western Australia',
    value: 10009643,
  },
  {
    label: 'North Lake, Western Australia',
    value: 10008704,
  },
  {
    label: 'North Lake Grace, Western Australia',
    value: 10009284,
  },
  {
    label: 'North Lakes, Queensland',
    value: 10010186,
  },
  {
    label: 'North Lambton, New South Wales',
    value: 10000915,
  },
  {
    label: 'North Lilydale, Tasmania',
    value: 10006473,
  },
  {
    label: 'North Lismore, New South Wales',
    value: 10001568,
  },
  {
    label: 'North Mackay, Queensland',
    value: 10004688,
  },
  {
    label: 'North Maclagan, Queensland',
    value: 10003458,
  },
  {
    label: 'North Maleny, Queensland',
    value: 10003897,
  },
  {
    label: 'North Manly, New South Wales',
    value: 10000392,
  },
  {
    label: 'North Melbourne, Victoria',
    value: 10006771,
  },
  {
    label: 'North Moonta, South Australia',
    value: 10005945,
  },
  {
    label: 'North Motton, Tasmania',
    value: 10006594,
  },
  {
    label: 'North Moulyinning, Western Australia',
    value: 10009275,
  },
  {
    label: 'North Narooma, New South Wales',
    value: 10001841,
  },
  {
    label: 'North Parramatta, New South Wales',
    value: 10000176,
  },
  {
    label: 'North Parramatta, New South Wales',
    value: 10000510,
  },
  {
    label: 'North Perth, Western Australia',
    value: 10008404,
  },
  {
    label: 'North Perth, Western Australia',
    value: 10010092,
  },
  {
    label: 'North Plantations, Western Australia',
    value: 10009944,
  },
  {
    label: 'North Plympton, South Australia',
    value: 10005330,
  },
  {
    label: 'North Richmond, New South Wales',
    value: 10002324,
  },
  {
    label: 'North Road, Victoria',
    value: 10007012,
  },
  {
    label: 'North Rockhampton, Queensland',
    value: 10004461,
  },
  {
    label: 'North Rocks, New South Wales',
    value: 10000509,
  },
  {
    label: 'North Rothbury, New South Wales',
    value: 10001091,
  },
  {
    label: 'North Ryde, New South Wales',
    value: 10000426,
  },
  {
    label: 'North Ryde Bc, New South Wales',
    value: 10000167,
  },
  {
    label: 'North Shore, Queensland',
    value: 10003938,
  },
  {
    label: 'North Shore, Victoria',
    value: 10007063,
  },
  {
    label: 'North Shore, New South Wales',
    value: 10010191,
  },
  {
    label: 'North Star, New South Wales',
    value: 10001261,
  },
  {
    label: 'North Stirlings, Western Australia',
    value: 10009264,
  },
  {
    label: 'North Strathfield, New South Wales',
    value: 10000469,
  },
  {
    label: 'North Sydney, New South Wales',
    value: 10000297,
  },
  {
    label: 'North Sydney, New South Wales',
    value: 10000302,
  },
  {
    label: 'North Sydney Shoppingworld, New South Wales',
    value: 10000301,
  },
  {
    label: 'North Talwood, Queensland',
    value: 10003790,
  },
  {
    label: 'North Tamborine, Queensland',
    value: 10003192,
  },
  {
    label: 'North Tivoli, Queensland',
    value: 10003246,
  },
  {
    label: 'North Toowoomba, Queensland',
    value: 10003398,
  },
  {
    label: 'North Trayning, Western Australia',
    value: 10009639,
  },
  {
    label: 'North Turramurra, New South Wales',
    value: 10000339,
  },
  {
    label: 'North Walpole, Western Australia',
    value: 10009394,
  },
  {
    label: 'North Ward, Queensland',
    value: 10004869,
  },
  {
    label: 'North Warrandyte, Victoria',
    value: 10006863,
  },
  {
    label: 'North West Cape, Western Australia',
    value: 10009962,
  },
  {
    label: 'North Wialki, Western Australia',
    value: 10009606,
  },
  {
    label: 'North Yelbeni, Western Australia',
    value: 10009635,
  },
  {
    label: 'North Yelta, South Australia',
    value: 10005942,
  },
  {
    label: 'North Yunderup, Western Australia',
    value: 10008770,
  },
  {
    label: 'Northam, Western Australia',
    value: 10009395,
  },
  {
    label: 'Northampton, Western Australia',
    value: 10009827,
  },
  {
    label: 'Northbridge, New South Wales',
    value: 10000155,
  },
  {
    label: 'Northbridge, New South Wales',
    value: 10000306,
  },
  {
    label: 'Northbridge, Western Australia',
    value: 10008399,
  },
  {
    label: 'Northbridge, Western Australia',
    value: 10010084,
  },
  {
    label: 'Northcliffe, Western Australia',
    value: 10008954,
  },
  {
    label: 'Northcote, Victoria',
    value: 10006800,
  },
  {
    label: 'Northdown, Tasmania',
    value: 10006566,
  },
  {
    label: 'Northern Gully, Western Australia',
    value: 10009771,
  },
  {
    label: 'Northern Territory, undefined',
    value: 10010171,
  },
  {
    label: 'Northern Territory, Northern Territory',
    value: 10010174,
  },
  {
    label: 'Northfield, South Australia',
    value: 10005483,
  },
  {
    label: 'Northgate, Queensland',
    value: 10002828,
  },
  {
    label: 'Northgate, South Australia',
    value: 10005484,
  },
  {
    label: 'Northland Centre, Victoria',
    value: 10006805,
  },
  {
    label: 'Northlands, Queensland',
    value: 10003402,
  },
  {
    label: 'Northlands, Western Australia',
    value: 10010091,
  },
  {
    label: 'Northmead, New South Wales',
    value: 10000511,
  },
  {
    label: 'Northwood, New South Wales',
    value: 10000316,
  },
  {
    label: 'Norton Summit, South Australia',
    value: 10005587,
  },
  {
    label: 'Norville, Queensland',
    value: 10004333,
  },
  {
    label: 'Norwell, Queensland',
    value: 10003102,
  },
  {
    label: 'Norwin, Queensland',
    value: 10003500,
  },
  {
    label: 'Norwood, South Australia',
    value: 10005422,
  },
  {
    label: 'Norwood, Tasmania',
    value: 10006398,
  },
  {
    label: 'Norwood South, South Australia',
    value: 10005424,
  },
  {
    label: 'Notley Hills, Tasmania',
    value: 10006485,
  },
  {
    label: 'Notting Hill, Victoria',
    value: 10006979,
  },
  {
    label: 'Novar Gardens, South Australia',
    value: 10005341,
  },
  {
    label: 'Nowa Nowa, Victoria',
    value: 10008222,
  },
  {
    label: 'Nowendoc, New South Wales',
    value: 10001162,
  },
  {
    label: 'Nowergup, Western Australia',
    value: 10008476,
  },
  {
    label: 'Nowra, New South Wales',
    value: 10001827,
  },
  {
    label: 'Nowra Dc, New South Wales',
    value: 10001826,
  },
  {
    label: 'Nubeena, Tasmania',
    value: 10006323,
  },
  {
    label: 'Nudgee, Queensland',
    value: 10002831,
  },
  {
    label: 'Nudgee Beach, Queensland',
    value: 10002832,
  },
  {
    label: 'Nugadong, Western Australia',
    value: 10009880,
  },
  {
    label: 'Nugent, Tasmania',
    value: 10006301,
  },
  {
    label: 'Nukarni, Western Australia',
    value: 10009626,
  },
  {
    label: 'Nukku, Queensland',
    value: 10003256,
  },
  {
    label: 'Nulkaba, New South Wales',
    value: 10001041,
  },
  {
    label: 'Nullagine, Western Australia',
    value: 10010053,
  },
  {
    label: 'Nullaki, Western Australia',
    value: 10009211,
  },
  {
    label: 'Nullamanna, New South Wales',
    value: 10001182,
  },
  {
    label: 'Nullawarre, Victoria',
    value: 10007198,
  },
  {
    label: 'Nullawil, Victoria',
    value: 10007655,
  },
  {
    label: 'Nulsen, Western Australia',
    value: 10009566,
  },
  {
    label: 'Numbaa, New South Wales',
    value: 10001789,
  },
  {
    label: 'Number One, New South Wales',
    value: 10001299,
  },
  {
    label: 'Numbla Vale, New South Wales',
    value: 10002065,
  },
  {
    label: 'Numbulwar, Northern Territory',
    value: 10002767,
  },
  {
    label: 'Numeralla, New South Wales',
    value: 10002075,
  },
  {
    label: 'Numinbah, New South Wales',
    value: 10001618,
  },
  {
    label: 'Numinbah Valley, Queensland',
    value: 10003124,
  },
  {
    label: 'Numulgi, New South Wales',
    value: 10001570,
  },
  {
    label: 'Numurkah, Victoria',
    value: 10007821,
  },
  {
    label: 'Nunamara, Tasmania',
    value: 10006440,
  },
  {
    label: 'Nunawading, Victoria',
    value: 10006897,
  },
  {
    label: 'Nunawading Bc, Victoria',
    value: 10006861,
  },
  {
    label: 'Nundah, Queensland',
    value: 10002824,
  },
  {
    label: 'Nundle, New South Wales',
    value: 10001123,
  },
  {
    label: 'Nungarin, Western Australia',
    value: 10009647,
  },
  {
    label: 'Nunierra, Western Australia',
    value: 10009907,
  },
  {
    label: 'Nunile, Western Australia',
    value: 10009844,
  },
  {
    label: 'Nuriootpa, South Australia',
    value: 10005830,
  },
  {
    label: 'Nutfield, Victoria',
    value: 10006848,
  },
  {
    label: 'Nyabing, Western Australia',
    value: 10009266,
  },
  {
    label: 'Nyah, Victoria',
    value: 10007759,
  },
  {
    label: 'Nyah West, Victoria',
    value: 10007760,
  },
  {
    label: 'Nyamup, Western Australia',
    value: 10008946,
  },
  {
    label: 'Nymagee, New South Wales',
    value: 10002562,
  },
  {
    label: 'Nymboida, New South Wales',
    value: 10001476,
  },
  {
    label: 'Nyngan, New South Wales',
    value: 10002536,
  },
  {
    label: 'Nyora, Victoria',
    value: 10008376,
  },
  {
    label: "O'Briens Hill, Queensland",
    value: 10005078,
  },
  {
    label: "O'Connell, Queensland",
    value: 10004423,
  },
  {
    label: "O'Connor, Australian Capital Territory",
    value: 10000021,
  },
  {
    label: "O'Connor, Western Australia",
    value: 10008706,
  },
  {
    label: "O'Halloran Hill, South Australia",
    value: 10005620,
  },
  {
    label: "O'Malley, Australian Capital Territory",
    value: 10000038,
  },
  {
    label: "O'Sullivan Beach, South Australia",
    value: 10005636,
  },
  {
    label: 'O.B. Flat, South Australia',
    value: 10005770,
  },
  {
    label: 'Oak Flats, New South Wales',
    value: 10001698,
  },
  {
    label: 'Oak Flats Dc, New South Wales',
    value: 10001700,
  },
  {
    label: 'Oak Park, Victoria',
    value: 10006760,
  },
  {
    label: 'Oak Valley, Queensland',
    value: 10004873,
  },
  {
    label: 'Oakajee, Western Australia',
    value: 10009776,
  },
  {
    label: 'Oakbank, South Australia',
    value: 10005694,
  },
  {
    label: 'Oakdale, New South Wales',
    value: 10001923,
  },
  {
    label: 'Oakdale, Queensland',
    value: 10004051,
  },
  {
    label: 'Oakden, South Australia',
    value: 10005490,
  },
  {
    label: 'Oakey, Queensland',
    value: 10003620,
  },
  {
    label: 'Oakey Creek, Queensland',
    value: 10004589,
  },
  {
    label: 'Oakford, Western Australia',
    value: 10008645,
  },
  {
    label: 'Oakhurst, New South Wales',
    value: 10002352,
  },
  {
    label: 'Oakhurst, Queensland',
    value: 10004234,
  },
  {
    label: 'Oaklands, New South Wales',
    value: 10002116,
  },
  {
    label: 'Oaklands Junction, Victoria',
    value: 10006789,
  },
  {
    label: 'Oaklands Park, South Australia',
    value: 10005363,
  },
  {
    label: 'Oakleigh, Victoria',
    value: 10006973,
  },
  {
    label: 'Oakleigh East, Victoria',
    value: 10006975,
  },
  {
    label: 'Oakleigh South, Victoria',
    value: 10006977,
  },
  {
    label: 'Oakley, Western Australia',
    value: 10008772,
  },
  {
    label: 'Oakview, Queensland',
    value: 10004027,
  },
  {
    label: 'Oakville, New South Wales',
    value: 10002360,
  },
  {
    label: 'Oatlands, New South Wales',
    value: 10000438,
  },
  {
    label: 'Oatlands, Tasmania',
    value: 10006218,
  },
  {
    label: 'Oatley, New South Wales',
    value: 10000672,
  },
  {
    label: 'Oberon, New South Wales',
    value: 10002426,
  },
  {
    label: 'Obil Bil, Queensland',
    value: 10004177,
  },
  {
    label: 'Ocean Beach, Western Australia',
    value: 10009241,
  },
  {
    label: 'Ocean Grove, Victoria',
    value: 10007115,
  },
  {
    label: 'Ocean Reef, Western Australia',
    value: 10008458,
  },
  {
    label: 'Ocean Shores, New South Wales',
    value: 10001602,
  },
  {
    label: 'Ocean View, Queensland',
    value: 10003873,
  },
  {
    label: 'Oenpelli, Northern Territory',
    value: 10002725,
  },
  {
    label: 'Officer, Victoria',
    value: 10008096,
  },
  {
    label: 'Ogilvie, Western Australia',
    value: 10009823,
  },
  {
    label: 'Ogmore, Queensland',
    value: 10004572,
  },
  {
    label: 'Okeden, Queensland',
    value: 10004096,
  },
  {
    label: 'Olary, South Australia',
    value: 10005875,
  },
  {
    label: 'Old Bar, New South Wales',
    value: 10001349,
  },
  {
    label: 'Old Beach, Tasmania',
    value: 10006099,
  },
  {
    label: 'Old Bonalbo, New South Wales',
    value: 10001501,
  },
  {
    label: 'Old Cooranga, Queensland',
    value: 10004176,
  },
  {
    label: 'Old Erowal Bay, New South Wales',
    value: 10001816,
  },
  {
    label: 'Old Guildford, New South Wales',
    value: 10000535,
  },
  {
    label: 'Old Junee, New South Wales',
    value: 10002162,
  },
  {
    label: 'Old Koreelah, New South Wales',
    value: 10001542,
  },
  {
    label: 'Old Noarlunga, South Australia',
    value: 10005640,
  },
  {
    label: 'Old Plains, Western Australia',
    value: 10009860,
  },
  {
    label: 'Old Toongabbie, New South Wales',
    value: 10000497,
  },
  {
    label: 'Oldbury, Western Australia',
    value: 10008646,
  },
  {
    label: 'Oldina, Tasmania',
    value: 10006645,
  },
  {
    label: 'Olinda, Victoria',
    value: 10008068,
  },
  {
    label: 'Olympic Dam, South Australia',
    value: 10006050,
  },
  {
    label: 'Oman Ama, Queensland',
    value: 10003418,
  },
  {
    label: 'Omeo, Victoria',
    value: 10008242,
  },
  {
    label: 'Ondit, Victoria',
    value: 10007161,
  },
  {
    label: 'One Mile, Queensland',
    value: 10003236,
  },
  {
    label: 'One Tree Hill, South Australia',
    value: 10005552,
  },
  {
    label: 'Ongerup, Western Australia',
    value: 10009246,
  },
  {
    label: 'Onkaparinga Hills, South Australia',
    value: 10005633,
  },
  {
    label: 'Onslow, Western Australia',
    value: 10009964,
  },
  {
    label: 'Oodnadatta, South Australia',
    value: 10006059,
  },
  {
    label: 'Oombabeer, Queensland',
    value: 10004618,
  },
  {
    label: 'Oombulgurri, Western Australia',
    value: 10010033,
  },
  {
    label: 'Oonoonba, Queensland',
    value: 10004872,
  },
  {
    label: 'Ooralea, Queensland',
    value: 10004699,
  },
  {
    label: 'Oorindi, Queensland',
    value: 10004963,
  },
  {
    label: 'Ootha, New South Wales',
    value: 10002651,
  },
  {
    label: 'Opalton, Queensland',
    value: 10004652,
  },
  {
    label: 'Opossum Bay, Tasmania',
    value: 10006118,
  },
  {
    label: 'Ora Banda, Western Australia',
    value: 10009499,
  },
  {
    label: 'Orallo, Queensland',
    value: 10003726,
  },
  {
    label: 'Orana, Western Australia',
    value: 10009204,
  },
  {
    label: 'Orange, New South Wales',
    value: 10002502,
  },
  {
    label: 'Orange Creek, Queensland',
    value: 10004604,
  },
  {
    label: 'Orange Grove, New South Wales',
    value: 10001223,
  },
  {
    label: 'Orange Grove, Western Australia',
    value: 10008626,
  },
  {
    label: 'Orange Hill, Queensland',
    value: 10003733,
  },
  {
    label: 'Orange Springs, Western Australia',
    value: 10009671,
  },
  {
    label: 'Orangeville, New South Wales',
    value: 10001927,
  },
  {
    label: 'Oranmeir, New South Wales',
    value: 10002053,
  },
  {
    label: 'Orbost, Victoria',
    value: 10008227,
  },
  {
    label: 'Orchard Hills, New South Wales',
    value: 10002307,
  },
  {
    label: 'Orchid Valley, Western Australia',
    value: 10009377,
  },
  {
    label: 'Ord River, Western Australia',
    value: 10010061,
  },
  {
    label: 'Orelia, Western Australia',
    value: 10008730,
  },
  {
    label: 'Orford, Tasmania',
    value: 10006335,
  },
  {
    label: 'Orielton, Tasmania',
    value: 10006304,
  },
  {
    label: 'Orient Point, New South Wales',
    value: 10001803,
  },
  {
    label: 'Ormeau, Queensland',
    value: 10003106,
  },
  {
    label: 'Ormeau Hills, Queensland',
    value: 10003105,
  },
  {
    label: 'Ormiston, Queensland',
    value: 10003042,
  },
  {
    label: 'Ormond, Victoria',
    value: 10007051,
  },
  {
    label: 'Orroroo, South Australia',
    value: 10005867,
  },
  {
    label: 'Osborne, Queensland',
    value: 10004829,
  },
  {
    label: 'Osborne, South Australia',
    value: 10005288,
  },
  {
    label: 'Osborne Park, Western Australia',
    value: 10008428,
  },
  {
    label: 'Osborne Park, Western Australia',
    value: 10010102,
  },
  {
    label: 'Osborne Park, Western Australia',
    value: 10010103,
  },
  {
    label: 'Osborne Park Dc, Western Australia',
    value: 10010101,
  },
  {
    label: 'Osmington, Western Australia',
    value: 10009022,
  },
  {
    label: 'Osterley, New South Wales',
    value: 10001024,
  },
  {
    label: 'Osterley, Tasmania',
    value: 10006251,
  },
  {
    label: 'Otago, Tasmania',
    value: 10006102,
  },
  {
    label: 'Otford, New South Wales',
    value: 10001662,
  },
  {
    label: 'Ottaba, Queensland',
    value: 10003317,
  },
  {
    label: 'Ottoway, South Australia',
    value: 10005269,
  },
  {
    label: 'Ourimbah, New South Wales',
    value: 10000760,
  },
  {
    label: 'Ouse, Tasmania',
    value: 10006247,
  },
  {
    label: 'Outer Harbor, South Australia',
    value: 10005291,
  },
  {
    label: 'Outtrim, Victoria',
    value: 10008303,
  },
  {
    label: 'Ouyen, Victoria',
    value: 10007608,
  },
  {
    label: 'Ovens, Victoria',
    value: 10008005,
  },
  {
    label: 'Ovingham, South Australia',
    value: 10005475,
  },
  {
    label: 'Owanyilla, Queensland',
    value: 10004224,
  },
  {
    label: 'Owen, South Australia',
    value: 10005885,
  },
  {
    label: 'Owens Creek, Queensland',
    value: 10004718,
  },
  {
    label: 'Owens Gap, New South Wales',
    value: 10001112,
  },
  {
    label: 'Oxenford, Queensland',
    value: 10003113,
  },
  {
    label: 'Oxford, Queensland',
    value: 10004733,
  },
  {
    label: 'Oxford Falls, New South Wales',
    value: 10000390,
  },
  {
    label: 'Oxley, Australian Capital Territory',
    value: 10000104,
  },
  {
    label: 'Oxley, New South Wales',
    value: 10002249,
  },
  {
    label: 'Oxley, Queensland',
    value: 10002929,
  },
  {
    label: 'Oxley, Victoria',
    value: 10007884,
  },
  {
    label: 'Oxley Park, New South Wales',
    value: 10002350,
  },
  {
    label: 'Oxley Vale, New South Wales',
    value: 10001124,
  },
  {
    label: 'Oyster Bay, New South Wales',
    value: 10000677,
  },
  {
    label: 'Oyster Cove, New South Wales',
    value: 10000960,
  },
  {
    label: 'Oyster Cove, Tasmania',
    value: 10006280,
  },
  {
    label: 'Oyster Creek, Queensland',
    value: 10004380,
  },
  {
    label: 'Ozenkadnook, Victoria',
    value: 10007490,
  },
  {
    label: 'Pacific Fair, Queensland',
    value: 10003165,
  },
  {
    label: 'Pacific Haven, Queensland',
    value: 10004276,
  },
  {
    label: 'Pacific Heights, Queensland',
    value: 10004556,
  },
  {
    label: 'Pacific Palms, New South Wales',
    value: 10001312,
  },
  {
    label: 'Pacific Paradise, Queensland',
    value: 10003933,
  },
  {
    label: 'Pacific Pines, Queensland',
    value: 10003125,
  },
  {
    label: 'Padbury, Western Australia',
    value: 10008451,
  },
  {
    label: 'Paddington, New South Wales',
    value: 10000228,
  },
  {
    label: 'Paddington, Queensland',
    value: 10002897,
  },
  {
    label: 'Padstow, New South Wales',
    value: 10000642,
  },
  {
    label: 'Padthaway, South Australia',
    value: 10005740,
  },
  {
    label: 'Page, Australian Capital Territory',
    value: 10000068,
  },
  {
    label: 'Paget, Queensland',
    value: 10004665,
  },
  {
    label: 'Pagewood, New South Wales',
    value: 10000256,
  },
  {
    label: 'Painswick, Victoria',
    value: 10007689,
  },
  {
    label: 'Pakenham, Victoria',
    value: 10008098,
  },
  {
    label: 'Pakenham Upper, Victoria',
    value: 10008097,
  },
  {
    label: 'Palana, Tasmania',
    value: 10006428,
  },
  {
    label: 'Palen Creek, Queensland',
    value: 10003202,
  },
  {
    label: 'Palgarup, Western Australia',
    value: 10008933,
  },
  {
    label: 'Palinyewah, New South Wales',
    value: 10002123,
  },
  {
    label: 'Pallamallawa, New South Wales',
    value: 10001246,
  },
  {
    label: 'Pallara, Queensland',
    value: 10002968,
  },
  {
    label: 'Pallarenda, Queensland',
    value: 10004862,
  },
  {
    label: 'Pallinup, Western Australia',
    value: 10009243,
  },
  {
    label: 'Palm Beach, New South Wales',
    value: 10000411,
  },
  {
    label: 'Palm Beach, Queensland',
    value: 10003171,
  },
  {
    label: 'Palm Cove, Queensland',
    value: 10005205,
  },
  {
    label: 'Palm Grove, Queensland',
    value: 10004781,
  },
  {
    label: 'Palm Island, Queensland',
    value: 10004904,
  },
  {
    label: 'Palmdale, Western Australia',
    value: 10009168,
  },
  {
    label: 'Palmer, South Australia',
    value: 10005687,
  },
  {
    label: 'Palmer, Western Australia',
    value: 10008830,
  },
  {
    label: 'Palmerston, Australian Capital Territory',
    value: 10000123,
  },
  {
    label: 'Palmerston, Northern Territory',
    value: 10002736,
  },
  {
    label: 'Palmerston, Northern Territory',
    value: 10002744,
  },
  {
    label: 'Palmerston, Queensland',
    value: 10005066,
  },
  {
    label: 'Palmtree, Queensland',
    value: 10003419,
  },
  {
    label: 'Palmvale, New South Wales',
    value: 10001614,
  },
  {
    label: 'Palmview, Queensland',
    value: 10003901,
  },
  {
    label: 'Palmwoods, Queensland',
    value: 10003904,
  },
  {
    label: 'Palmyra, Western Australia',
    value: 10008694,
  },
  {
    label: 'Palmyra, Western Australia',
    value: 10010134,
  },
  {
    label: 'Palmyra Dc, Western Australia',
    value: 10010138,
  },
  {
    label: 'Paloona, Tasmania',
    value: 10006582,
  },
  {
    label: 'Paluma, Queensland',
    value: 10004902,
  },
  {
    label: 'Pambula, New South Wales',
    value: 10001851,
  },
  {
    label: 'Pampas, Queensland',
    value: 10003449,
  },
  {
    label: 'Panania, New South Wales',
    value: 10000647,
  },
  {
    label: 'Panmure, Victoria',
    value: 10007187,
  },
  {
    label: 'Pannawonica, Western Australia',
    value: 10009994,
  },
  {
    label: 'Panorama, South Australia',
    value: 10005346,
  },
  {
    label: 'Pantapin, Western Australia',
    value: 10009348,
  },
  {
    label: 'Panton Hill, Victoria',
    value: 10008040,
  },
  {
    label: 'Pappinbarra, New South Wales',
    value: 10001407,
  },
  {
    label: 'Papunya, Northern Territory',
    value: 10002783,
  },
  {
    label: 'Para Hills, South Australia',
    value: 10005512,
  },
  {
    label: 'Para Vista, South Australia',
    value: 10005504,
  },
  {
    label: 'Paraburdoo, Western Australia',
    value: 10010052,
  },
  {
    label: 'Parachilna, South Australia',
    value: 10006053,
  },
  {
    label: 'Paracombe, South Australia',
    value: 10005583,
  },
  {
    label: 'Paradise, South Australia',
    value: 10005463,
  },
  {
    label: 'Paradise, Tasmania',
    value: 10006556,
  },
  {
    label: 'Paradise, Western Australia',
    value: 10008875,
  },
  {
    label: 'Paradise Beach, Victoria',
    value: 10008167,
  },
  {
    label: 'Paradise Point, Queensland',
    value: 10003149,
  },
  {
    label: 'Parafield, South Australia',
    value: 10005520,
  },
  {
    label: 'Parafield Gardens, South Australia',
    value: 10005522,
  },
  {
    label: 'Paralowie, South Australia',
    value: 10005525,
  },
  {
    label: 'Parap, Northern Territory',
    value: 10002676,
  },
  {
    label: 'Parap, Northern Territory',
    value: 10002714,
  },
  {
    label: 'Parattah, Tasmania',
    value: 10006222,
  },
  {
    label: 'Pardoo, Western Australia',
    value: 10010002,
  },
  {
    label: 'Parilla, South Australia',
    value: 10005780,
  },
  {
    label: 'Paringa, South Australia',
    value: 10005803,
  },
  {
    label: 'Paringi, New South Wales',
    value: 10002671,
  },
  {
    label: 'Park Avenue, Queensland',
    value: 10004455,
  },
  {
    label: 'Park Holme, South Australia',
    value: 10005354,
  },
  {
    label: 'Park Orchards, Victoria',
    value: 10006865,
  },
  {
    label: 'Park Ridge, Queensland',
    value: 10003006,
  },
  {
    label: 'Parkdale, Victoria',
    value: 10007034,
  },
  {
    label: 'Parker Range, Western Australia',
    value: 10009466,
  },
  {
    label: 'Parkerville, Western Australia',
    value: 10008594,
  },
  {
    label: 'Parkes, Australian Capital Territory',
    value: 10000012,
  },
  {
    label: 'Parkes, New South Wales',
    value: 10002641,
  },
  {
    label: 'Parkeston, Western Australia',
    value: 10009521,
  },
  {
    label: 'Parkfield, Western Australia',
    value: 10008867,
  },
  {
    label: 'Parkham, Tasmania',
    value: 10006539,
  },
  {
    label: 'Parkhurst, Queensland',
    value: 10004476,
  },
  {
    label: 'Parkinson, Queensland',
    value: 10002978,
  },
  {
    label: 'Parklands, Western Australia',
    value: 10008790,
  },
  {
    label: 'Parklea, New South Wales',
    value: 10002373,
  },
  {
    label: 'Parkside, Queensland',
    value: 10004842,
  },
  {
    label: 'Parkside, Queensland',
    value: 10004976,
  },
  {
    label: 'Parkside, South Australia',
    value: 10005399,
  },
  {
    label: 'Parkville, New South Wales',
    value: 10001109,
  },
  {
    label: 'Parkville, Victoria',
    value: 10006772,
  },
  {
    label: 'Parkwood, Queensland',
    value: 10003143,
  },
  {
    label: 'Parkwood, Western Australia',
    value: 10008658,
  },
  {
    label: 'Parliament House, Australian Capital Territory',
    value: 10000011,
  },
  {
    label: 'Parmelia, Western Australia',
    value: 10008736,
  },
  {
    label: 'Parndana, South Australia',
    value: 10005674,
  },
  {
    label: 'Parrakie, South Australia',
    value: 10005776,
  },
  {
    label: 'Parramatta, New South Wales',
    value: 10000451,
  },
  {
    label: 'Parramatta, New South Wales',
    value: 10000452,
  },
  {
    label: 'Parramatta, New South Wales',
    value: 10000507,
  },
  {
    label: 'Parramatta Park, Queensland',
    value: 10005102,
  },
  {
    label: 'Parrawe, Tasmania',
    value: 10006618,
  },
  {
    label: 'Parrearra, Queensland',
    value: 10004015,
  },
  {
    label: 'Parryville, Western Australia',
    value: 10009226,
  },
  {
    label: 'Paruna, South Australia',
    value: 10005790,
  },
  {
    label: 'Parwan, Victoria',
    value: 10007319,
  },
  {
    label: 'Pasadena, South Australia',
    value: 10005351,
  },
  {
    label: 'Pascoe Vale, Victoria',
    value: 10006757,
  },
  {
    label: 'Pascoe Vale South, Victoria',
    value: 10006756,
  },
  {
    label: 'Pasha, Queensland',
    value: 10004627,
  },
  {
    label: 'Paskeville, South Australia',
    value: 10005930,
  },
  {
    label: 'Patchewollock, Victoria',
    value: 10007609,
  },
  {
    label: 'Paterson, New South Wales',
    value: 10001273,
  },
  {
    label: 'Paterson, Queensland',
    value: 10003956,
  },
  {
    label: 'Patersonia, Tasmania',
    value: 10006439,
  },
  {
    label: 'Patho, Victoria',
    value: 10007726,
  },
  {
    label: 'Patonga, New South Wales',
    value: 10000746,
  },
  {
    label: 'Patrick Estate, Queensland',
    value: 10003302,
  },
  {
    label: 'Patterson Lakes, Victoria',
    value: 10007040,
  },
  {
    label: 'Paulls Valley, Western Australia',
    value: 10008585,
  },
  {
    label: 'Pauls Pocket, Queensland',
    value: 10004802,
  },
  {
    label: 'Pawleena, Tasmania',
    value: 10006305,
  },
  {
    label: 'Pawtella, Tasmania',
    value: 10006212,
  },
  {
    label: 'Paxton, New South Wales',
    value: 10001029,
  },
  {
    label: 'Paynedale, Western Australia',
    value: 10008885,
  },
  {
    label: 'Payneham, South Australia',
    value: 10005445,
  },
  {
    label: 'Paynes Crossing, New South Wales',
    value: 10001038,
  },
  {
    label: 'Paynes Find, Western Australia',
    value: 10009883,
  },
  {
    label: 'Paynesville, Victoria',
    value: 10008209,
  },
  {
    label: 'Paynesville, Western Australia',
    value: 10009915,
  },
  {
    label: 'Peaceful Bay, Western Australia',
    value: 10009236,
  },
  {
    label: 'Peachester, Queensland',
    value: 10003851,
  },
  {
    label: 'Peak Crossing, Queensland',
    value: 10003260,
  },
  {
    label: 'Peak Hill, New South Wales',
    value: 10002633,
  },
  {
    label: 'Peak Hill, Western Australia',
    value: 10009923,
  },
  {
    label: 'Peak View, New South Wales',
    value: 10002078,
  },
  {
    label: 'Peake, South Australia',
    value: 10005775,
  },
  {
    label: 'Peakhurst, New South Wales',
    value: 10000641,
  },
  {
    label: 'Peakhurst Heights, New South Wales',
    value: 10000639,
  },
  {
    label: 'Pearce, Australian Capital Territory',
    value: 10000043,
  },
  {
    label: 'Pearcedale, Victoria',
    value: 10008254,
  },
  {
    label: 'Pearl Beach, New South Wales',
    value: 10000744,
  },
  {
    label: 'Pearsall, Western Australia',
    value: 10008558,
  },
  {
    label: 'Peats Ridge, New South Wales',
    value: 10000717,
  },
  {
    label: 'Pechey, Queensland',
    value: 10003461,
  },
  {
    label: 'Pedler Creek, South Australia',
    value: 10005647,
  },
  {
    label: 'Peebinga, South Australia',
    value: 10005781,
  },
  {
    label: 'Peedamulla, Western Australia',
    value: 10009967,
  },
  {
    label: 'Peek-A-Doo, Queensland',
    value: 10003688,
  },
  {
    label: 'Peel, New South Wales',
    value: 10002469,
  },
  {
    label: 'Peel Island, Queensland',
    value: 10003076,
  },
  {
    label: 'Peelwood, New South Wales',
    value: 10002000,
  },
  {
    label: 'Peerabeelup, Western Australia',
    value: 10008952,
  },
  {
    label: 'Pegs Creek, Western Australia',
    value: 10009982,
  },
  {
    label: 'Pelaw Main, New South Wales',
    value: 10001051,
  },
  {
    label: 'Pelham, Queensland',
    value: 10003669,
  },
  {
    label: 'Pelham, Tasmania',
    value: 10006135,
  },
  {
    label: 'Pelican Point, Western Australia',
    value: 10008852,
  },
  {
    label: 'Pelican Waters, Queensland',
    value: 10003885,
  },
  {
    label: 'Pelton, New South Wales',
    value: 10001033,
  },
  {
    label: 'Pelverata, Tasmania',
    value: 10006270,
  },
  {
    label: 'Pemberton, Western Australia',
    value: 10008951,
  },
  {
    label: 'Pembrooke, New South Wales',
    value: 10001404,
  },
  {
    label: 'Pemulwuy, New South Wales',
    value: 10000489,
  },
  {
    label: 'Pendle Hill, New South Wales',
    value: 10000491,
  },
  {
    label: 'Penfield, South Australia',
    value: 10005571,
  },
  {
    label: 'Penguin, Tasmania',
    value: 10006602,
  },
  {
    label: 'Penna, Tasmania',
    value: 10006299,
  },
  {
    label: 'Pennant Hills, New South Wales',
    value: 10000174,
  },
  {
    label: 'Pennant Hills, New South Wales',
    value: 10000447,
  },
  {
    label: 'Penneshaw, South Australia',
    value: 10005676,
  },
  {
    label: 'Pennington, South Australia',
    value: 10005274,
  },
  {
    label: 'Penola, South Australia',
    value: 10005749,
  },
  {
    label: 'Penong, South Australia',
    value: 10006034,
  },
  {
    label: 'Penrith, New South Wales',
    value: 10002310,
  },
  {
    label: 'Penrith, New South Wales',
    value: 10002313,
  },
  {
    label: 'Penrose, New South Wales',
    value: 10001973,
  },
  {
    label: 'Penshurst, New South Wales',
    value: 10000670,
  },
  {
    label: 'Penshurst, Victoria',
    value: 10007241,
  },
  {
    label: 'Pentland, Queensland',
    value: 10004909,
  },
  {
    label: 'Penwhaupell, Queensland',
    value: 10004140,
  },
  {
    label: 'Peppermint Grove, Western Australia',
    value: 10008420,
  },
  {
    label: 'Peppermint Grove Beach, Western Australia',
    value: 10008958,
  },
  {
    label: 'Peranga, Queensland',
    value: 10003455,
  },
  {
    label: 'Peregian Beach, Queensland',
    value: 10004008,
  },
  {
    label: 'Perenjori, Western Australia',
    value: 10009889,
  },
  {
    label: 'Perillup, Western Australia',
    value: 10009158,
  },
  {
    label: 'Perisher Valley, New South Wales',
    value: 10002059,
  },
  {
    label: 'Peron, Western Australia',
    value: 10008742,
  },
  {
    label: 'Peronne, Victoria',
    value: 10007488,
  },
  {
    label: 'Perseverance, Queensland',
    value: 10003467,
  },
  {
    label: 'Perth, Tasmania',
    value: 10006508,
  },
  {
    label: 'Perth, Western Australia',
    value: 10008398,
  },
  {
    label: 'Perth Airport, Western Australia',
    value: 10008616,
  },
  {
    label: 'Perth Bc, Western Australia',
    value: 10010082,
  },
  {
    label: 'Perth East St Georges Tce, Western Australia',
    value: 10010069,
  },
  {
    label: 'Perth St Georges Tce, Western Australia',
    value: 10010068,
  },
  {
    label: 'Perthville, New South Wales',
    value: 10010184,
  },
  {
    label: 'Perup, Western Australia',
    value: 10008941,
  },
  {
    label: 'Perwillowen, Queensland',
    value: 10003920,
  },
  {
    label: 'Petcheys Bay, Tasmania',
    value: 10006166,
  },
  {
    label: 'Peterborough, South Australia',
    value: 10005866,
  },
  {
    label: 'Peterborough, Victoria',
    value: 10007204,
  },
  {
    label: 'Peterhead, South Australia',
    value: 10005287,
  },
  {
    label: 'Petersham, New South Wales',
    value: 10000292,
  },
  {
    label: 'Petrie, Queensland',
    value: 10003806,
  },
  {
    label: 'Petrudor, Western Australia',
    value: 10009597,
  },
  {
    label: 'Pheasant Creek, Queensland',
    value: 10004517,
  },
  {
    label: 'Pheasant Creek, Victoria',
    value: 10008036,
  },
  {
    label: 'Pheasants Nest, New South Wales',
    value: 10001945,
  },
  {
    label: 'Phegans Bay, New South Wales',
    value: 10000745,
  },
  {
    label: 'Phillip, Australian Capital Territory',
    value: 10000034,
  },
  {
    label: 'Phillip Bay, New South Wales',
    value: 10000261,
  },
  {
    label: 'Philpott, Queensland',
    value: 10004172,
  },
  {
    label: 'Pialba, Queensland',
    value: 10004254,
  },
  {
    label: 'Pialligo, Australian Capital Territory',
    value: 10000046,
  },
  {
    label: 'Piangil, Victoria',
    value: 10007762,
  },
  {
    label: 'Piavella, Victoria',
    value: 10007736,
  },
  {
    label: 'Piawaning, Western Australia',
    value: 10009863,
  },
  {
    label: 'Piccadilly, South Australia',
    value: 10005597,
  },
  {
    label: 'Piccadilly, Western Australia',
    value: 10009489,
  },
  {
    label: 'Pickanjinnie, Queensland',
    value: 10003709,
  },
  {
    label: 'Pickering Brook, Western Australia',
    value: 10008590,
  },
  {
    label: 'Picnic Bay, Queensland',
    value: 10004940,
  },
  {
    label: 'Picnic Point, New South Wales',
    value: 10000645,
  },
  {
    label: 'Picola, Victoria',
    value: 10007827,
  },
  {
    label: 'Picton, New South Wales',
    value: 10001936,
  },
  {
    label: 'Picton, Western Australia',
    value: 10008845,
  },
  {
    label: 'Picton East, Western Australia',
    value: 10008846,
  },
  {
    label: 'Pierces Creek, Queensland',
    value: 10003491,
  },
  {
    label: 'Piesse Brook, Western Australia',
    value: 10008583,
  },
  {
    label: 'Piesseville, Western Australia',
    value: 10009116,
  },
  {
    label: 'Pile Gully, Queensland',
    value: 10004152,
  },
  {
    label: 'Pilerwa, Queensland',
    value: 10004218,
  },
  {
    label: 'Pillar Valley, New South Wales',
    value: 10001487,
  },
  {
    label: 'Pilliga, New South Wales',
    value: 10001235,
  },
  {
    label: 'Pimlico, New South Wales',
    value: 10001557,
  },
  {
    label: 'Pimlico, Queensland',
    value: 10004885,
  },
  {
    label: 'Pimpama, Queensland',
    value: 10003110,
  },
  {
    label: 'Pimpimbudgee, Queensland',
    value: 10004111,
  },
  {
    label: 'Pin Gin Hill, Queensland',
    value: 10005061,
  },
  {
    label: 'Pinbarren, Queensland',
    value: 10003948,
  },
  {
    label: 'Pindar, Western Australia',
    value: 10009908,
  },
  {
    label: 'Pindi Pindi, Queensland',
    value: 10004771,
  },
  {
    label: 'Pindimar, New South Wales',
    value: 10001016,
  },
  {
    label: 'Pine Creek, Northern Territory',
    value: 10002759,
  },
  {
    label: 'Pine Hills, Queensland',
    value: 10003679,
  },
  {
    label: 'Pine Lodge, Victoria',
    value: 10007804,
  },
  {
    label: 'Pine Mountain, Queensland',
    value: 10003284,
  },
  {
    label: 'Pine Point, South Australia',
    value: 10005958,
  },
  {
    label: 'Pine Ridge, New South Wales',
    value: 10001141,
  },
  {
    label: 'Pinelands, Northern Territory',
    value: 10002734,
  },
  {
    label: 'Pines Forest, Victoria',
    value: 10007047,
  },
  {
    label: 'Pinevale, Queensland',
    value: 10004757,
  },
  {
    label: 'Pingaring, Western Australia',
    value: 10009304,
  },
  {
    label: 'Pingelly, Western Australia',
    value: 10009079,
  },
  {
    label: 'Pingrup, Western Australia',
    value: 10009267,
  },
  {
    label: 'Pinjar, Western Australia',
    value: 10008567,
  },
  {
    label: 'Pinjarra, Western Australia',
    value: 10008764,
  },
  {
    label: 'Pinjarra Hills, Queensland',
    value: 10002912,
  },
  {
    label: 'Pink Lake, Western Australia',
    value: 10009576,
  },
  {
    label: 'Pink Lily, Queensland',
    value: 10004508,
  },
  {
    label: 'Pinkenba, Queensland',
    value: 10002819,
  },
  {
    label: 'Pinkerton Plains, South Australia',
    value: 10005848,
  },
  {
    label: 'Pinnacle, Queensland',
    value: 10004720,
  },
  {
    label: 'Pinnaroo, South Australia',
    value: 10005782,
  },
  {
    label: 'Pintharuka, Western Australia',
    value: 10009894,
  },
  {
    label: 'Pinwernying, Western Australia',
    value: 10009135,
  },
  {
    label: 'Pioneer, Queensland',
    value: 10004964,
  },
  {
    label: 'Pioneer, Tasmania',
    value: 10006459,
  },
  {
    label: 'Pioneers Rest, Queensland',
    value: 10004219,
  },
  {
    label: 'Piora, New South Wales',
    value: 10001505,
  },
  {
    label: 'Pipers Brook, Tasmania',
    value: 10006418,
  },
  {
    label: 'Pipers Flat, New South Wales',
    value: 10002598,
  },
  {
    label: 'Pipers River, Tasmania',
    value: 10006409,
  },
  {
    label: 'Pippingarra, Western Australia',
    value: 10010010,
  },
  {
    label: 'Pirron Yallock, Victoria',
    value: 10007162,
  },
  {
    label: 'Pithara, Western Australia',
    value: 10009876,
  },
  {
    label: 'Pitt Town, New South Wales',
    value: 10002333,
  },
  {
    label: 'Pittsworth, Queensland',
    value: 10003502,
  },
  {
    label: 'Piturie, Queensland',
    value: 10004978,
  },
  {
    label: 'Placid Hills, Queensland',
    value: 10003352,
  },
  {
    label: 'Plainland, Queensland',
    value: 10003331,
  },
  {
    label: 'Planland, Queensland',
    value: 10004701,
  },
  {
    label: 'Pleasant Hills, New South Wales',
    value: 10002172,
  },
  {
    label: 'Pleasure Point, New South Wales',
    value: 10000577,
  },
  {
    label: 'Plenty, Tasmania',
    value: 10006237,
  },
  {
    label: 'Plenty, Victoria',
    value: 10006834,
  },
  {
    label: 'Pleystowe, Queensland',
    value: 10004728,
  },
  {
    label: 'Plumpton, New South Wales',
    value: 10002354,
  },
  {
    label: 'Plumpton, Victoria',
    value: 10007306,
  },
  {
    label: 'Plumridge Lakes, Western Australia',
    value: 10009502,
  },
  {
    label: 'Plympton, South Australia',
    value: 10005336,
  },
  {
    label: 'Plympton Park, South Australia',
    value: 10005337,
  },
  {
    label: 'Poatina, Tasmania',
    value: 10006515,
  },
  {
    label: 'Point Clare, New South Wales',
    value: 10000729,
  },
  {
    label: 'Point Cook, Victoria',
    value: 10006727,
  },
  {
    label: 'Point Grey, Western Australia',
    value: 10008767,
  },
  {
    label: 'Point Lonsdale, Victoria',
    value: 10007113,
  },
  {
    label: 'Point Lookout, Queensland',
    value: 10003071,
  },
  {
    label: 'Point Mcleay, South Australia',
    value: 10005721,
  },
  {
    label: 'Point Pearce, South Australia',
    value: 10005961,
  },
  {
    label: 'Point Piper, New South Wales',
    value: 10000240,
  },
  {
    label: 'Point Samson, Western Australia',
    value: 10010001,
  },
  {
    label: 'Point Turton, South Australia',
    value: 10005973,
  },
  {
    label: 'Point Vernon, Queensland',
    value: 10004268,
  },
  {
    label: 'Pokolbin, New South Wales',
    value: 10000972,
  },
  {
    label: 'Police Point, Tasmania',
    value: 10006203,
  },
  {
    label: 'Pomborneit, Victoria',
    value: 10007177,
  },
  {
    label: 'Pomona, New South Wales',
    value: 10002126,
  },
  {
    label: 'Pomona, Queensland',
    value: 10003947,
  },
  {
    label: 'Pomonal, Victoria',
    value: 10007448,
  },
  {
    label: 'Pontville, Tasmania',
    value: 10006131,
  },
  {
    label: 'Pony Hills, Queensland',
    value: 10003720,
  },
  {
    label: 'Poochera, South Australia',
    value: 10006022,
  },
  {
    label: 'Poolaijelo, Victoria',
    value: 10007265,
  },
  {
    label: 'Pooncarie, New South Wales',
    value: 10002128,
  },
  {
    label: 'Pooraka, South Australia',
    value: 10005510,
  },
  {
    label: 'Pootilla, Victoria',
    value: 10007395,
  },
  {
    label: 'Poowong, Victoria',
    value: 10008377,
  },
  {
    label: 'Popanyinning, Western Australia',
    value: 10009086,
  },
  {
    label: 'Porepunkah, Victoria',
    value: 10008008,
  },
  {
    label: 'Pormpuraaw, Queensland',
    value: 10005151,
  },
  {
    label: 'Porongurup, Western Australia',
    value: 10009157,
  },
  {
    label: 'Port Adelaide, South Australia',
    value: 10005281,
  },
  {
    label: 'Port Albany, Western Australia',
    value: 10009184,
  },
  {
    label: 'Port Albert, Victoria',
    value: 10008340,
  },
  {
    label: 'Port Alma, Queensland',
    value: 10004439,
  },
  {
    label: 'Port Arthur, Tasmania',
    value: 10006320,
  },
  {
    label: 'Port Augusta, South Australia',
    value: 10006038,
  },
  {
    label: 'Port Augusta, South Australia',
    value: 10006042,
  },
  {
    label: 'Port Augusta North, South Australia',
    value: 10006036,
  },
  {
    label: 'Port Augusta West, South Australia',
    value: 10006037,
  },
  {
    label: 'Port Botany, New South Wales',
    value: 10000264,
  },
  {
    label: 'Port Broughton, South Australia',
    value: 10005919,
  },
  {
    label: 'Port Campbell, Victoria',
    value: 10007202,
  },
  {
    label: 'Port Clinton, South Australia',
    value: 10005957,
  },
  {
    label: 'Port Curtis, Queensland',
    value: 10004447,
  },
  {
    label: 'Port Denison, Western Australia',
    value: 10009735,
  },
  {
    label: 'Port Douglas, Queensland',
    value: 10005193,
  },
  {
    label: 'Port Elliot, South Australia',
    value: 10005670,
  },
  {
    label: 'Port Fairy, Victoria',
    value: 10007231,
  },
  {
    label: 'Port Flinders, South Australia',
    value: 10005904,
  },
  {
    label: 'Port Franklin, Victoria',
    value: 10008329,
  },
  {
    label: 'Port Gawler, South Australia',
    value: 10005909,
  },
  {
    label: 'Port Germein, South Australia',
    value: 10005905,
  },
  {
    label: 'Port Hacking, New South Wales',
    value: 10000684,
  },
  {
    label: 'Port Hedland, Western Australia',
    value: 10010003,
  },
  {
    label: 'Port Hughes, South Australia',
    value: 10005953,
  },
  {
    label: 'Port Huon, Tasmania',
    value: 10006204,
  },
  {
    label: 'Port Kembla, New South Wales',
    value: 10001655,
  },
  {
    label: 'Port Kennedy, Western Australia',
    value: 10008752,
  },
  {
    label: 'Port Kenny, South Australia',
    value: 10006027,
  },
  {
    label: 'Port Lincoln, South Australia',
    value: 10005992,
  },
  {
    label: 'Port Lincoln, South Australia',
    value: 10005996,
  },
  {
    label: 'Port Macdonnell, South Australia',
    value: 10005767,
  },
  {
    label: 'Port Macquarie, New South Wales',
    value: 10001397,
  },
  {
    label: 'Port Macquarie Bc, New South Wales',
    value: 10001399,
  },
  {
    label: 'Port Melbourne, Victoria',
    value: 10007057,
  },
  {
    label: 'Port Neill, South Australia',
    value: 10005989,
  },
  {
    label: 'Port Noarlunga, South Australia',
    value: 10005637,
  },
  {
    label: 'Port Of Brisbane, Queensland',
    value: 10003064,
  },
  {
    label: 'Port Pirie, South Australia',
    value: 10005927,
  },
  {
    label: 'Port Pirie South, South Australia',
    value: 10005923,
  },
  {
    label: 'Port Pirie West, South Australia',
    value: 10005924,
  },
  {
    label: 'Port Rickaby, South Australia',
    value: 10005968,
  },
  {
    label: 'Port Sorell, Tasmania',
    value: 10006569,
  },
  {
    label: 'Port Victoria, South Australia',
    value: 10005965,
  },
  {
    label: 'Port Vincent, South Australia',
    value: 10005978,
  },
  {
    label: 'Port Wakefield, South Australia',
    value: 10005929,
  },
  {
    label: 'Port Welshpool, Victoria',
    value: 10008330,
  },
  {
    label: 'Port Willunga, South Australia',
    value: 10005652,
  },
  {
    label: 'Portarlington, Victoria',
    value: 10007110,
  },
  {
    label: 'Porters Retreat, New South Wales',
    value: 10002422,
  },
  {
    label: 'Portland, New South Wales',
    value: 10002599,
  },
  {
    label: 'Portland, Victoria',
    value: 10007261,
  },
  {
    label: 'Portsea, Victoria',
    value: 10008294,
  },
  {
    label: 'Postans, Western Australia',
    value: 10008728,
  },
  {
    label: 'Postmans Ridge, Queensland',
    value: 10003442,
  },
  {
    label: 'Potts Hill, New South Wales',
    value: 10000485,
  },
  {
    label: 'Potts Point, New South Wales',
    value: 10000133,
  },
  {
    label: 'Potts Point, New South Wales',
    value: 10000211,
  },
  {
    label: 'Pottsville, New South Wales',
    value: 10001640,
  },
  {
    label: 'Powelltown, Victoria',
    value: 10008078,
  },
  {
    label: 'Powranna, Tasmania',
    value: 10006509,
  },
  {
    label: 'Pozieres, Queensland',
    value: 10003451,
  },
  {
    label: 'Prahran, Victoria',
    value: 10006998,
  },
  {
    label: 'Prairie, Queensland',
    value: 10004901,
  },
  {
    label: 'Prairie, Victoria',
    value: 10007739,
  },
  {
    label: 'Prairiewood, New South Wales',
    value: 10000584,
  },
  {
    label: 'Premaydena, Tasmania',
    value: 10006324,
  },
  {
    label: 'Premer, New South Wales',
    value: 10001228,
  },
  {
    label: 'Prentice North, Victoria',
    value: 10007893,
  },
  {
    label: 'Preolenna, Tasmania',
    value: 10006637,
  },
  {
    label: 'Preservation Bay, Tasmania',
    value: 10006599,
  },
  {
    label: 'Preston, Queensland',
    value: 10003444,
  },
  {
    label: 'Preston, Queensland',
    value: 10004787,
  },
  {
    label: 'Preston, Tasmania',
    value: 10006591,
  },
  {
    label: 'Preston, Victoria',
    value: 10006802,
  },
  {
    label: 'Preston Beach, Western Australia',
    value: 10008809,
  },
  {
    label: 'Preston Settlement, Western Australia',
    value: 10008826,
  },
  {
    label: 'Preston South, Victoria',
    value: 10006804,
  },
  {
    label: 'Prestons, New South Wales',
    value: 10000564,
  },
  {
    label: 'Pretty Beach, New South Wales',
    value: 10000756,
  },
  {
    label: 'Prevelly, Western Australia',
    value: 10009020,
  },
  {
    label: 'Price, South Australia',
    value: 10005956,
  },
  {
    label: 'Priestdale, Queensland',
    value: 10003010,
  },
  {
    label: 'Primbee, New South Wales',
    value: 10001650,
  },
  {
    label: 'Primrose Sands, Tasmania',
    value: 10006308,
  },
  {
    label: 'Prince Henry Heights, Queensland',
    value: 10003410,
  },
  {
    label: 'Prince Regent River, Western Australia',
    value: 10010032,
  },
  {
    label: 'Princetown, Victoria',
    value: 10007203,
  },
  {
    label: 'Promisedland, Queensland',
    value: 10004292,
  },
  {
    label: 'Proserpine, Queensland',
    value: 10004782,
  },
  {
    label: 'Prospect, New South Wales',
    value: 10000505,
  },
  {
    label: 'Prospect, Queensland',
    value: 10004600,
  },
  {
    label: 'Prospect, South Australia',
    value: 10005472,
  },
  {
    label: 'Prospect, Tasmania',
    value: 10006392,
  },
  {
    label: 'Prospect East, South Australia',
    value: 10005474,
  },
  {
    label: 'Prospect Hill, South Australia',
    value: 10005656,
  },
  {
    label: 'Prospect Vale, Tasmania',
    value: 10006396,
  },
  {
    label: 'Proston, Queensland',
    value: 10004101,
  },
  {
    label: 'Puckapunyal Milpo, Victoria',
    value: 10007848,
  },
  {
    label: 'Pularumpi, Northern Territory',
    value: 10002729,
  },
  {
    label: 'Pullabooka, New South Wales',
    value: 10002517,
  },
  {
    label: 'Pullaming, New South Wales',
    value: 10001229,
  },
  {
    label: 'Pullenvale, Queensland',
    value: 10002908,
  },
  {
    label: 'Pumphreys Bridge, Western Australia',
    value: 10009078,
  },
  {
    label: 'Punchbowl, New South Wales',
    value: 10000608,
  },
  {
    label: 'Punchbowl, Tasmania',
    value: 10006385,
  },
  {
    label: 'Punchs Creek, Queensland',
    value: 10003535,
  },
  {
    label: 'Pura Pura, Victoria',
    value: 10007207,
  },
  {
    label: 'Purfleet, New South Wales',
    value: 10001345,
  },
  {
    label: 'Purlewaugh, New South Wales',
    value: 10001174,
  },
  {
    label: 'Purnim, Victoria',
    value: 10007217,
  },
  {
    label: 'Purnululu, Western Australia',
    value: 10010062,
  },
  {
    label: 'Purrawunda, Queensland',
    value: 10003499,
  },
  {
    label: 'Putney, New South Wales',
    value: 10000424,
  },
  {
    label: 'Putty, New South Wales',
    value: 10001075,
  },
  {
    label: 'Pyalong, Victoria',
    value: 10007647,
  },
  {
    label: 'Pyengana, Tasmania',
    value: 10006371,
  },
  {
    label: 'Pymble, New South Wales',
    value: 10000334,
  },
  {
    label: 'Pyramid Hill, Victoria',
    value: 10007742,
  },
  {
    label: 'Pyree, New South Wales',
    value: 10001818,
  },
  {
    label: 'Pyrmont, New South Wales',
    value: 10000205,
  },
  {
    label: 'Q Supercentre, Queensland',
    value: 10003159,
  },
  {
    label: 'Quaama, New South Wales',
    value: 10001859,
  },
  {
    label: 'Quairading, Western Australia',
    value: 10009346,
  },
  {
    label: 'Quakers Hill, New South Wales',
    value: 10002358,
  },
  {
    label: 'Qualeup, Western Australia',
    value: 10009382,
  },
  {
    label: 'Quambatook, Victoria',
    value: 10007664,
  },
  {
    label: 'Quambone, New South Wales',
    value: 10002556,
  },
  {
    label: 'Quandialla, New South Wales',
    value: 10002264,
  },
  {
    label: 'Quantong, Victoria',
    value: 10007471,
  },
  {
    label: 'Quarry Hill, Victoria',
    value: 10007673,
  },
  {
    label: 'Queanbeyan, New South Wales',
    value: 10002040,
  },
  {
    label: 'Queanbeyan East, New South Wales',
    value: 10002042,
  },
  {
    label: 'Quedjinup, Western Australia',
    value: 10009008,
  },
  {
    label: 'Queen Victoria Building, New South Wales',
    value: 10000129,
  },
  {
    label: 'Queens Beach, Queensland',
    value: 10004821,
  },
  {
    label: 'Queens Park, New South Wales',
    value: 10000230,
  },
  {
    label: 'Queens Park, Western Australia',
    value: 10008622,
  },
  {
    label: 'Queenscliff, New South Wales',
    value: 10000377,
  },
  {
    label: 'Queenscliff, Victoria',
    value: 10007114,
  },
  {
    label: 'Queensland, Queensland',
    value: 10010175,
  },
  {
    label: 'Queenstown, South Australia',
    value: 10005279,
  },
  {
    label: 'Queenstown, Tasmania',
    value: 10006672,
  },
  {
    label: 'Queenton, Queensland',
    value: 10004947,
  },
  {
    label: 'Queenwood, Western Australia',
    value: 10008892,
  },
  {
    label: 'Quelagetting, Western Australia',
    value: 10009417,
  },
  {
    label: 'Quellington, Western Australia',
    value: 10009044,
  },
  {
    label: 'Quilpie, Queensland',
    value: 10003762,
  },
  {
    label: 'Quinalow, Queensland',
    value: 10003636,
  },
  {
    label: 'Quindalup, Western Australia',
    value: 10009006,
  },
  {
    label: 'Quindanning, Western Australia',
    value: 10009369,
  },
  {
    label: 'Quinninup, Western Australia',
    value: 10008932,
  },
  {
    label: 'Quinns Rocks, Western Australia',
    value: 10008467,
  },
  {
    label: 'Quirindi, New South Wales',
    value: 10001137,
  },
  {
    label: 'Qunaba, Queensland',
    value: 10004326,
  },
  {
    label: 'Quoiba, Tasmania',
    value: 10006572,
  },
  {
    label: 'Quorn, South Australia',
    value: 10005873,
  },
  {
    label: 'Quorrobolong, New South Wales',
    value: 10001036,
  },
  {
    label: 'Raby, New South Wales',
    value: 10001912,
  },
  {
    label: 'Racecourse, Queensland',
    value: 10004679,
  },
  {
    label: 'Raceview, Queensland',
    value: 10003244,
  },
  {
    label: 'Raglan, New South Wales',
    value: 10002481,
  },
  {
    label: 'Raglan, Queensland',
    value: 10004438,
  },
  {
    label: 'Railton, Tasmania',
    value: 10006546,
  },
  {
    label: 'Railway Estate, Queensland',
    value: 10004861,
  },
  {
    label: 'Rainbow, Victoria',
    value: 10007505,
  },
  {
    label: 'Rainbow Beach, Queensland',
    value: 10004023,
  },
  {
    label: 'Raleigh, New South Wales',
    value: 10001452,
  },
  {
    label: 'Ramco, South Australia',
    value: 10005795,
  },
  {
    label: 'Raminea, Tasmania',
    value: 10006178,
  },
  {
    label: 'Ramingining, Northern Territory',
    value: 10002731,
  },
  {
    label: 'Ramsay, Queensland',
    value: 10003478,
  },
  {
    label: 'Ramsgate, New South Wales',
    value: 10000656,
  },
  {
    label: 'Ramsgate Beach, New South Wales',
    value: 10000654,
  },
  {
    label: 'Rand, New South Wales',
    value: 10002104,
  },
  {
    label: 'Randalls Bay, Tasmania',
    value: 10006195,
  },
  {
    label: 'Randwick, New South Wales',
    value: 10000251,
  },
  {
    label: 'Ranelagh, Tasmania',
    value: 10006184,
  },
  {
    label: 'Ranford, Western Australia',
    value: 10009356,
  },
  {
    label: 'Ranga, Tasmania',
    value: 10006429,
  },
  {
    label: 'Rangemore, Queensland',
    value: 10003477,
  },
  {
    label: 'Rangemore, Queensland',
    value: 10004825,
  },
  {
    label: 'Rangeview, Victoria',
    value: 10006898,
  },
  {
    label: 'Rangeville, Queensland',
    value: 10003415,
  },
  {
    label: 'Rangeway, Western Australia',
    value: 10009761,
  },
  {
    label: 'Rankin Park, New South Wales',
    value: 10000881,
  },
  {
    label: 'Rankins Springs, New South Wales',
    value: 10002197,
  },
  {
    label: 'Ransome, Queensland',
    value: 10003032,
  },
  {
    label: 'Rapid Bay, South Australia',
    value: 10005661,
  },
  {
    label: 'Rapid Creek, Northern Territory',
    value: 10002682,
  },
  {
    label: 'Rappville, New South Wales',
    value: 10001497,
  },
  {
    label: 'Rasmussen, Queensland',
    value: 10004897,
  },
  {
    label: 'Rathdowney, Queensland',
    value: 10003203,
  },
  {
    label: 'Rathmines, New South Wales',
    value: 10000854,
  },
  {
    label: 'Rathscar, Victoria',
    value: 10007578,
  },
  {
    label: 'Raukkan, South Australia',
    value: 10005717,
  },
  {
    label: 'Ravensbourne, Queensland',
    value: 10003480,
  },
  {
    label: 'Ravensdale, New South Wales',
    value: 10000785,
  },
  {
    label: 'Ravenshoe, Queensland',
    value: 10005226,
  },
  {
    label: 'Ravensthorpe, Western Australia',
    value: 10009269,
  },
  {
    label: 'Ravenswood, Queensland',
    value: 10004899,
  },
  {
    label: 'Ravenswood, Tasmania',
    value: 10006400,
  },
  {
    label: 'Ravenswood, Victoria',
    value: 10007544,
  },
  {
    label: 'Ravenswood, Western Australia',
    value: 10008771,
  },
  {
    label: 'Ravensworth, New South Wales',
    value: 10001073,
  },
  {
    label: 'Rawdon Vale, New South Wales',
    value: 10001279,
  },
  {
    label: 'Rawlinna, Western Australia',
    value: 10009518,
  },
  {
    label: 'Raworth, New South Wales',
    value: 10000983,
  },
  {
    label: 'Rawson, Victoria',
    value: 10008129,
  },
  {
    label: 'Raymond Island, Victoria',
    value: 10008208,
  },
  {
    label: 'Raymond Terrace, New South Wales',
    value: 10001025,
  },
  {
    label: 'Raymond Terrace East, New South Wales',
    value: 10001022,
  },
  {
    label: 'Raywood, Victoria',
    value: 10007732,
  },
  {
    label: 'Razorback, New South Wales',
    value: 10001939,
  },
  {
    label: 'Red Bluff, Victoria',
    value: 10007927,
  },
  {
    label: 'Red Cliffs, Victoria',
    value: 10007613,
  },
  {
    label: 'Red Gully, Western Australia',
    value: 10009670,
  },
  {
    label: 'Red Hill, Australian Capital Territory',
    value: 10000024,
  },
  {
    label: 'Red Hill, Queensland',
    value: 10002889,
  },
  {
    label: 'Red Hill, Victoria',
    value: 10008283,
  },
  {
    label: 'Red Hill, Western Australia',
    value: 10008521,
  },
  {
    label: 'Red Hill South, Victoria',
    value: 10008284,
  },
  {
    label: 'Red Hills, Tasmania',
    value: 10006530,
  },
  {
    label: 'Red Range, New South Wales',
    value: 10001201,
  },
  {
    label: 'Red Rock, New South Wales',
    value: 10001459,
  },
  {
    label: 'Redan, Victoria',
    value: 10007336,
  },
  {
    label: 'Redbank, Queensland',
    value: 10003213,
  },
  {
    label: 'Redbank, Victoria',
    value: 10007595,
  },
  {
    label: 'Redbank, Western Australia',
    value: 10010009,
  },
  {
    label: 'Redbank Creek, Queensland',
    value: 10003312,
  },
  {
    label: 'Redbank Plains, Queensland',
    value: 10003215,
  },
  {
    label: 'Redcliffe, Queensland',
    value: 10002844,
  },
  {
    label: 'Redcliffe, Western Australia',
    value: 10008613,
  },
  {
    label: 'Redesdale, Victoria',
    value: 10007527,
  },
  {
    label: 'Redfern, New South Wales',
    value: 10000217,
  },
  {
    label: 'Redgate, Queensland',
    value: 10004047,
  },
  {
    label: 'Redgate, Western Australia',
    value: 10009027,
  },
  {
    label: 'Redhead, New South Wales',
    value: 10000897,
  },
  {
    label: 'Redhill, South Australia',
    value: 10005918,
  },
  {
    label: 'Redhill Farms, Queensland',
    value: 10004358,
  },
  {
    label: 'Redland Bay, Queensland',
    value: 10003048,
  },
  {
    label: 'Redlynch, Queensland',
    value: 10005109,
  },
  {
    label: 'Redmond, Western Australia',
    value: 10009164,
  },
  {
    label: 'Redmond West, Western Australia',
    value: 10009163,
  },
  {
    label: 'Redpa, Tasmania',
    value: 10006656,
  },
  {
    label: 'Redwood, Queensland',
    value: 10003416,
  },
  {
    label: 'Redwood Park, South Australia',
    value: 10005516,
  },
  {
    label: 'Reedy, Western Australia',
    value: 10009919,
  },
  {
    label: 'Reedy Creek, New South Wales',
    value: 10001069,
  },
  {
    label: 'Reedy Creek, Queensland',
    value: 10003183,
  },
  {
    label: 'Reedy Creek, Victoria',
    value: 10007841,
  },
  {
    label: 'Reedy Marsh, Tasmania',
    value: 10006524,
  },
  {
    label: 'Reefton, New South Wales',
    value: 10002188,
  },
  {
    label: 'Reeves Plains, South Australia',
    value: 10005915,
  },
  {
    label: 'Regans Ford, Western Australia',
    value: 10009678,
  },
  {
    label: 'Regency Park, South Australia',
    value: 10005260,
  },
  {
    label: 'Regency Park, South Australia',
    value: 10006060,
  },
  {
    label: 'Regency Park Bc, South Australia',
    value: 10005261,
  },
  {
    label: 'Regent West, Victoria',
    value: 10006803,
  },
  {
    label: 'Regents Park, New South Wales',
    value: 10000486,
  },
  {
    label: 'Regents Park, Queensland',
    value: 10002986,
  },
  {
    label: 'Regentville, New South Wales',
    value: 10002296,
  },
  {
    label: 'Reid, Australian Capital Territory',
    value: 10000062,
  },
  {
    label: 'Reids Creek, Queensland',
    value: 10004162,
  },
  {
    label: 'Reids Flat, New South Wales',
    value: 10002015,
  },
  {
    label: 'Reidsdale, New South Wales',
    value: 10002051,
  },
  {
    label: 'Reinscourt, Western Australia',
    value: 10008979,
  },
  {
    label: 'Relbia, Tasmania',
    value: 10006436,
  },
  {
    label: 'Remlap, Western Australia',
    value: 10009601,
  },
  {
    label: 'Remote work, undefined',
    value: 10010187,
  },
  {
    label: 'Rendelsham, South Australia',
    value: 10005757,
  },
  {
    label: 'Renison Bell, Tasmania',
    value: 10006675,
  },
  {
    label: 'Renmark, South Australia',
    value: 10005804,
  },
  {
    label: 'Renmark South, South Australia',
    value: 10005806,
  },
  {
    label: 'Rennie, New South Wales',
    value: 10002118,
  },
  {
    label: 'Renown Park, South Australia',
    value: 10005253,
  },
  {
    label: 'Repton, New South Wales',
    value: 10001451,
  },
  {
    label: 'Research, Victoria',
    value: 10006839,
  },
  {
    label: 'Reservoir, Victoria',
    value: 10006807,
  },
  {
    label: 'Reservoir, Western Australia',
    value: 10008587,
  },
  {
    label: 'Retreat, Tasmania',
    value: 10006416,
  },
  {
    label: 'Revesby, New South Wales',
    value: 10000644,
  },
  {
    label: 'Revesby North, New South Wales',
    value: 10000643,
  },
  {
    label: 'Reynella, South Australia',
    value: 10005628,
  },
  {
    label: 'Rhodes, New South Wales',
    value: 10000474,
  },
  {
    label: 'Rhydding, Queensland',
    value: 10004616,
  },
  {
    label: 'Rhyll, Victoria',
    value: 10008265,
  },
  {
    label: 'Rhyndaston, Tasmania',
    value: 10006223,
  },
  {
    label: 'Riana, Tasmania',
    value: 10006606,
  },
  {
    label: 'Richardson, Australian Capital Territory',
    value: 10000109,
  },
  {
    label: 'Richlands, Queensland',
    value: 10002936,
  },
  {
    label: 'Richmond, New South Wales',
    value: 10002317,
  },
  {
    label: 'Richmond, Queensland',
    value: 10004681,
  },
  {
    label: 'Richmond, South Australia',
    value: 10005317,
  },
  {
    label: 'Richmond, Tasmania',
    value: 10006120,
  },
  {
    label: 'Richmond, Victoria',
    value: 10006870,
  },
  {
    label: 'Richmond Hill, New South Wales',
    value: 10001591,
  },
  {
    label: 'Richmond Hill, Queensland',
    value: 10004953,
  },
  {
    label: 'Richmond Raaf, New South Wales',
    value: 10002325,
  },
  {
    label: 'Riddells Creek, Victoria',
    value: 10007510,
  },
  {
    label: 'Ridgehaven, South Australia',
    value: 10005514,
  },
  {
    label: 'Ridgelands, Queensland',
    value: 10004536,
  },
  {
    label: 'Ridgeway, Tasmania',
    value: 10006157,
  },
  {
    label: 'Ridgewood, Western Australia',
    value: 10008472,
  },
  {
    label: 'Ridgley, Tasmania',
    value: 10006631,
  },
  {
    label: 'Ridleyton, South Australia',
    value: 10005251,
  },
  {
    label: 'Rileys Hill, New South Wales',
    value: 10001527,
  },
  {
    label: 'Ringarooma, Tasmania',
    value: 10006457,
  },
  {
    label: 'Ringbark, Western Australia',
    value: 10008926,
  },
  {
    label: 'Ringtail Creek, Queensland',
    value: 10003939,
  },
  {
    label: 'Ringwood, Queensland',
    value: 10003351,
  },
  {
    label: 'Ringwood, Victoria',
    value: 10006902,
  },
  {
    label: 'Ringwood East, Victoria',
    value: 10006908,
  },
  {
    label: 'Ringwood North, Victoria',
    value: 10006905,
  },
  {
    label: 'Ripley, Queensland',
    value: 10003270,
  },
  {
    label: 'Ripplebrook, Victoria',
    value: 10008111,
  },
  {
    label: 'Ripponlea, Victoria',
    value: 10007008,
  },
  {
    label: 'Risdon Park, South Australia',
    value: 10005922,
  },
  {
    label: 'Risdon Park South, South Australia',
    value: 10005926,
  },
  {
    label: 'Risdon Vale, Tasmania',
    value: 10006098,
  },
  {
    label: 'Rite Island, Queensland',
    value: 10004832,
  },
  {
    label: 'River Ranch, Queensland',
    value: 10004411,
  },
  {
    label: 'Riverhills, Queensland',
    value: 10002921,
  },
  {
    label: 'Riverleigh, Queensland',
    value: 10004170,
  },
  {
    label: 'Riverside, Tasmania',
    value: 10006393,
  },
  {
    label: 'Riverside, Victoria',
    value: 10007467,
  },
  {
    label: 'Riverside Centre, Queensland',
    value: 10002804,
  },
  {
    label: 'Riverstone, New South Wales',
    value: 10002366,
  },
  {
    label: 'Riverton, Queensland',
    value: 10003594,
  },
  {
    label: 'Riverton, South Australia',
    value: 10005854,
  },
  {
    label: 'Riverton, Western Australia',
    value: 10008663,
  },
  {
    label: 'Rivervale, Western Australia',
    value: 10008610,
  },
  {
    label: 'Riverview, New South Wales',
    value: 10000318,
  },
  {
    label: 'Riverview, Queensland',
    value: 10003217,
  },
  {
    label: 'Riverwood, New South Wales',
    value: 10000640,
  },
  {
    label: 'Rivett, Australian Capital Territory',
    value: 10000058,
  },
  {
    label: 'Roadvale, Queensland',
    value: 10003295,
  },
  {
    label: 'Robe, South Australia',
    value: 10005746,
  },
  {
    label: 'Robertson, New South Wales',
    value: 10001967,
  },
  {
    label: 'Robertson, Queensland',
    value: 10002962,
  },
  {
    label: 'Robertstown, South Australia',
    value: 10005847,
  },
  {
    label: 'Robigana, Tasmania',
    value: 10006495,
  },
  {
    label: 'Robina, Queensland',
    value: 10003181,
  },
  {
    label: 'Robina Town Centre, Queensland',
    value: 10003188,
  },
  {
    label: 'Robinson, Victoria',
    value: 10006701,
  },
  {
    label: 'Robinson, Western Australia',
    value: 10009192,
  },
  {
    label: 'Robinvale, Victoria',
    value: 10007671,
  },
  {
    label: 'Rochedale, Queensland',
    value: 10003001,
  },
  {
    label: 'Rochedale South, Queensland',
    value: 10003002,
  },
  {
    label: 'Rocherlea, Tasmania',
    value: 10006380,
  },
  {
    label: 'Roches Beach, Tasmania',
    value: 10006297,
  },
  {
    label: 'Rochester, Victoria',
    value: 10007718,
  },
  {
    label: 'Rochford, Victoria',
    value: 10007525,
  },
  {
    label: 'Rock Flat, New South Wales',
    value: 10002077,
  },
  {
    label: 'Rock Valley, New South Wales',
    value: 10001574,
  },
  {
    label: 'Rockbank, Victoria',
    value: 10007307,
  },
  {
    label: 'Rockdale, New South Wales',
    value: 10000651,
  },
  {
    label: 'Rockhampton, Queensland',
    value: 10004444,
  },
  {
    label: 'Rockhampton City, Queensland',
    value: 10004441,
  },
  {
    label: 'Rockingham, Western Australia',
    value: 10008739,
  },
  {
    label: 'Rockingham, Western Australia',
    value: 10010144,
  },
  {
    label: 'Rockingham Beach, Western Australia',
    value: 10010145,
  },
  {
    label: 'Rockingham Dc, Western Australia',
    value: 10010143,
  },
  {
    label: 'Rocklands, Victoria',
    value: 10007469,
  },
  {
    label: 'Rocklea, Queensland',
    value: 10002955,
  },
  {
    label: 'Rocklea, Western Australia',
    value: 10010048,
  },
  {
    label: 'Rocklea Dc, Queensland',
    value: 10002954,
  },
  {
    label: 'Rockley, New South Wales',
    value: 10002475,
  },
  {
    label: 'Rocklyn, Victoria',
    value: 10007421,
  },
  {
    label: 'Rockmount, Queensland',
    value: 10003376,
  },
  {
    label: 'Rockside, Queensland',
    value: 10003347,
  },
  {
    label: 'Rockville, Queensland',
    value: 10003411,
  },
  {
    label: 'Rockwell, Western Australia',
    value: 10009801,
  },
  {
    label: 'Rocky Cape, Tasmania',
    value: 10006632,
  },
  {
    label: 'Rocky Creek, Queensland',
    value: 10003530,
  },
  {
    label: 'Rocky Glen, New South Wales',
    value: 10001175,
  },
  {
    label: 'Rocky Gully, Western Australia',
    value: 10009391,
  },
  {
    label: 'Rocky Hall, New South Wales',
    value: 10001869,
  },
  {
    label: 'Rocky Point, Queensland',
    value: 10005186,
  },
  {
    label: 'Rockyview, Queensland',
    value: 10004457,
  },
  {
    label: 'Rodd Point, New South Wales',
    value: 10000283,
  },
  {
    label: 'Rodds Bay, Queensland',
    value: 10004395,
  },
  {
    label: 'Roebourne, Western Australia',
    value: 10009997,
  },
  {
    label: 'Roebuck, Western Australia',
    value: 10010020,
  },
  {
    label: 'Roelands, Western Australia',
    value: 10008842,
  },
  {
    label: 'Rokeby, Tasmania',
    value: 10006112,
  },
  {
    label: 'Rokeby, Victoria',
    value: 10008119,
  },
  {
    label: 'Rokewood, Victoria',
    value: 10007294,
  },
  {
    label: 'Rokewood Junction, Victoria',
    value: 10007350,
  },
  {
    label: 'Roland, Tasmania',
    value: 10006560,
  },
  {
    label: 'Roleystone, Western Australia',
    value: 10008633,
  },
  {
    label: 'Rollands Plains, New South Wales',
    value: 10001383,
  },
  {
    label: 'Rolleston, Queensland',
    value: 10004495,
  },
  {
    label: 'Rollingstone, Queensland',
    value: 10004917,
  },
  {
    label: 'Roma, Queensland',
    value: 10003734,
  },
  {
    label: 'Romaine, Tasmania',
    value: 10006610,
  },
  {
    label: 'Romsey, Victoria',
    value: 10007513,
  },
  {
    label: 'Rooty Hill, New South Wales',
    value: 10002368,
  },
  {
    label: 'Ropeley, Queensland',
    value: 10003341,
  },
  {
    label: 'Rosa Brook, Western Australia',
    value: 10009019,
  },
  {
    label: 'Rosa Glen, Western Australia',
    value: 10009023,
  },
  {
    label: 'Rosalie Plains, Queensland',
    value: 10003626,
  },
  {
    label: 'Rosanna, Victoria',
    value: 10006825,
  },
  {
    label: 'Rose Bay, New South Wales',
    value: 10000243,
  },
  {
    label: 'Rose Bay, Tasmania',
    value: 10006095,
  },
  {
    label: 'Rose Bay North, New South Wales',
    value: 10000244,
  },
  {
    label: 'Rose Park, South Australia',
    value: 10005423,
  },
  {
    label: 'Rose Valley, New South Wales',
    value: 10002073,
  },
  {
    label: 'Rosebank, New South Wales',
    value: 10001573,
  },
  {
    label: 'Rosebery, New South Wales',
    value: 10000140,
  },
  {
    label: 'Rosebery, New South Wales',
    value: 10000221,
  },
  {
    label: 'Rosebery, Northern Territory',
    value: 10002746,
  },
  {
    label: 'Rosebery, Tasmania',
    value: 10006676,
  },
  {
    label: 'Rosebrook, Victoria',
    value: 10007233,
  },
  {
    label: 'Rosebud, Victoria',
    value: 10008287,
  },
  {
    label: 'Rosebud West, Victoria',
    value: 10008288,
  },
  {
    label: 'Rosedale, New South Wales',
    value: 10001744,
  },
  {
    label: 'Rosedale, Queensland',
    value: 10004377,
  },
  {
    label: 'Rosedale, Victoria',
    value: 10008156,
  },
  {
    label: 'Rosegarland, Tasmania',
    value: 10006242,
  },
  {
    label: 'Rosehill, New South Wales',
    value: 10000482,
  },
  {
    label: 'Roselands, New South Wales',
    value: 10000607,
  },
  {
    label: 'Rosella, Queensland',
    value: 10004678,
  },
  {
    label: 'Rosemeadow, New South Wales',
    value: 10001893,
  },
  {
    label: 'Roseneath, Queensland',
    value: 10004876,
  },
  {
    label: 'Rosetta, Tasmania',
    value: 10006088,
  },
  {
    label: 'Rosevale, Tasmania',
    value: 10006504,
  },
  {
    label: 'Rosevears, Tasmania',
    value: 10006500,
  },
  {
    label: 'Roseville, New South Wales',
    value: 10000328,
  },
  {
    label: 'Rosewater, South Australia',
    value: 10005273,
  },
  {
    label: 'Rosewater East, South Australia',
    value: 10005271,
  },
  {
    label: 'Rosewood, New South Wales',
    value: 10002152,
  },
  {
    label: 'Rosewood, Queensland',
    value: 10003328,
  },
  {
    label: 'Roseworthy, South Australia',
    value: 10005839,
  },
  {
    label: 'Roslyn, New South Wales',
    value: 10001985,
  },
  {
    label: 'Rosny, Tasmania',
    value: 10006110,
  },
  {
    label: 'Rosny Park, Tasmania',
    value: 10006105,
  },
  {
    label: 'Ross, Tasmania',
    value: 10006337,
  },
  {
    label: 'Ross Creek, Queensland',
    value: 10003993,
  },
  {
    label: 'Ross Creek, Victoria',
    value: 10007348,
  },
  {
    label: 'Ross River, Queensland',
    value: 10004911,
  },
  {
    label: 'Rossarden, Tasmania',
    value: 10006350,
  },
  {
    label: 'Rosslea, Queensland',
    value: 10004881,
  },
  {
    label: 'Rosslyn, Queensland',
    value: 10004559,
  },
  {
    label: 'Rosslyn Park, South Australia',
    value: 10005452,
  },
  {
    label: 'Rossmore, New South Wales',
    value: 10001881,
  },
  {
    label: 'Rossmore, Western Australia',
    value: 10009404,
  },
  {
    label: 'Rossmoya, Queensland',
    value: 10004521,
  },
  {
    label: 'Rossmoyne, Western Australia',
    value: 10008662,
  },
  {
    label: 'Rossvale, Queensland',
    value: 10003511,
  },
  {
    label: 'Rostrevor, South Australia',
    value: 10005458,
  },
  {
    label: 'Rothbury, New South Wales',
    value: 10000970,
  },
  {
    label: 'Rothsay, Western Australia',
    value: 10009890,
  },
  {
    label: 'Rothwell, Queensland',
    value: 10002847,
  },
  {
    label: 'Roto, New South Wales',
    value: 10002208,
  },
  {
    label: 'Rottnest Island, Western Australia',
    value: 10008698,
  },
  {
    label: 'Rouchel, New South Wales',
    value: 10001096,
  },
  {
    label: 'Round Corner, New South Wales',
    value: 10000524,
  },
  {
    label: 'Round Hill, Queensland',
    value: 10004392,
  },
  {
    label: 'Roundstone, Queensland',
    value: 10004611,
  },
  {
    label: 'Rous, New South Wales',
    value: 10001548,
  },
  {
    label: 'Rous Mill, New South Wales',
    value: 10001553,
  },
  {
    label: 'Rouse Hill, New South Wales',
    value: 10000517,
  },
  {
    label: 'Rowella, Tasmania',
    value: 10006477,
  },
  {
    label: 'Rowena, New South Wales',
    value: 10001232,
  },
  {
    label: 'Rowes Bay, Queensland',
    value: 10004863,
  },
  {
    label: 'Rowland Flat, South Australia',
    value: 10005820,
  },
  {
    label: 'Rowsley, Victoria',
    value: 10007318,
  },
  {
    label: 'Rowville, Victoria',
    value: 10006995,
  },
  {
    label: 'Roxburgh Park, Victoria',
    value: 10006790,
  },
  {
    label: 'Roxby Downs, South Australia',
    value: 10006051,
  },
  {
    label: 'Royal Australian Navy Warships, Western Australia',
    value: 10010135,
  },
  {
    label: 'Royal Brisbane Hospital, Queensland',
    value: 10002853,
  },
  {
    label: 'Royal Exchange, New South Wales',
    value: 10000128,
  },
  {
    label: 'Royal George, Tasmania',
    value: 10006351,
  },
  {
    label: 'Royal Melbourne Hospital, Victoria',
    value: 10006769,
  },
  {
    label: 'Royal North Shore Hospital, New South Wales',
    value: 10000308,
  },
  {
    label: 'Royal Park, South Australia',
    value: 10005278,
  },
  {
    label: 'Royalla, New South Wales',
    value: 10002033,
  },
  {
    label: 'Royston Park, South Australia',
    value: 10005448,
  },
  {
    label: 'Rozelle, New South Wales',
    value: 10000269,
  },
  {
    label: 'Ruabon, Western Australia',
    value: 10009000,
  },
  {
    label: 'Rubicon, Victoria',
    value: 10007953,
  },
  {
    label: 'Rubyanna, Queensland',
    value: 10004328,
  },
  {
    label: 'Rubyvale, Queensland',
    value: 10004470,
  },
  {
    label: 'Rudall, South Australia',
    value: 10006014,
  },
  {
    label: 'Rudds Gully, Western Australia',
    value: 10009800,
  },
  {
    label: 'Ruffy, Victoria',
    value: 10007859,
  },
  {
    label: 'Rufus River, New South Wales',
    value: 10002125,
  },
  {
    label: 'Rugby, New South Wales',
    value: 10002006,
  },
  {
    label: 'Rukenvale, New South Wales',
    value: 10001539,
  },
  {
    label: 'Rules Beach, Queensland',
    value: 10004376,
  },
  {
    label: 'Runaway Bay, Queensland',
    value: 10003150,
  },
  {
    label: 'Runcorn, Queensland',
    value: 10002972,
  },
  {
    label: 'Rundle Mall, South Australia',
    value: 10005235,
  },
  {
    label: 'Running Stream, New South Wales',
    value: 10002606,
  },
  {
    label: 'Runnyford, New South Wales',
    value: 10001754,
  },
  {
    label: 'Runnymede, Queensland',
    value: 10004115,
  },
  {
    label: 'Runnymede, Tasmania',
    value: 10006336,
  },
  {
    label: 'Rupanyup, Victoria',
    value: 10007458,
  },
  {
    label: 'Rural View, Queensland',
    value: 10004677,
  },
  {
    label: 'Ruse, New South Wales',
    value: 10001889,
  },
  {
    label: 'Rush Creek, Queensland',
    value: 10003869,
  },
  {
    label: 'Rushcutters Bay, New South Wales',
    value: 10000209,
  },
  {
    label: 'Rushworth, Victoria',
    value: 10007774,
  },
  {
    label: 'Russell, Australian Capital Territory',
    value: 10000009,
  },
  {
    label: 'Russell Island, Queensland',
    value: 10003078,
  },
  {
    label: 'Russell Lea, New South Wales',
    value: 10000287,
  },
  {
    label: 'Russell Vale, New South Wales',
    value: 10001670,
  },
  {
    label: 'Russells Bridge, Victoria',
    value: 10007295,
  },
  {
    label: 'Rutherford, New South Wales',
    value: 10000968,
  },
  {
    label: 'Rutherglen, Victoria',
    value: 10007896,
  },
  {
    label: 'Ruthven, New South Wales',
    value: 10001571,
  },
  {
    label: 'Ryan, New South Wales',
    value: 10002171,
  },
  {
    label: 'Ryan, Queensland',
    value: 10004975,
  },
  {
    label: 'Ryansbrook, Western Australia',
    value: 10009385,
  },
  {
    label: 'Rydal, New South Wales',
    value: 10002441,
  },
  {
    label: 'Rydalmere, New South Wales',
    value: 10000436,
  },
  {
    label: 'Rydalmere Bc, New South Wales',
    value: 10000172,
  },
  {
    label: 'Ryde, New South Wales',
    value: 10000169,
  },
  {
    label: 'Ryde, New South Wales',
    value: 10000423,
  },
  {
    label: 'Rye, Victoria',
    value: 10008289,
  },
  {
    label: 'Rye Park, New South Wales',
    value: 10002016,
  },
  {
    label: 'Ryeford, Queensland',
    value: 10003554,
  },
  {
    label: 'Rylstone, New South Wales',
    value: 10002604,
  },
  {
    label: 'Sabina River, Western Australia',
    value: 10008994,
  },
  {
    label: 'Sabine, Queensland',
    value: 10003630,
  },
  {
    label: 'Saddleworth, South Australia',
    value: 10005856,
  },
  {
    label: 'Sadleir, New South Wales',
    value: 10000555,
  },
  {
    label: 'Sadliers Crossing, Queensland',
    value: 10003230,
  },
  {
    label: 'Safety Bay, Western Australia',
    value: 10008745,
  },
  {
    label: 'Safety Beach, New South Wales',
    value: 10001464,
  },
  {
    label: 'Safety Beach, Victoria',
    value: 10008282,
  },
  {
    label: 'Saibai Island, New South Wales',
    value: 10010203,
  },
  {
    label: 'Saint Helena, Victoria',
    value: 10006831,
  },
  {
    label: 'Salamander Bay, New South Wales',
    value: 10000955,
  },
  {
    label: 'Sale, Victoria',
    value: 10008158,
  },
  {
    label: 'Sale, Victoria',
    value: 10008169,
  },
  {
    label: 'Salisbury, New South Wales',
    value: 10001268,
  },
  {
    label: 'Salisbury, Queensland',
    value: 10002956,
  },
  {
    label: 'Salisbury, South Australia',
    value: 10005523,
  },
  {
    label: 'Salisbury Downs, South Australia',
    value: 10005524,
  },
  {
    label: 'Salisbury East, South Australia',
    value: 10005527,
  },
  {
    label: 'Salisbury Heights, South Australia',
    value: 10005529,
  },
  {
    label: 'Salisbury North, South Australia',
    value: 10005526,
  },
  {
    label: 'Salisbury Park, South Australia',
    value: 10005530,
  },
  {
    label: 'Salisbury Plain, South Australia',
    value: 10005528,
  },
  {
    label: 'Salisbury South, South Australia',
    value: 10005519,
  },
  {
    label: 'Salisbury West, Victoria',
    value: 10007635,
  },
  {
    label: 'Salmon Gums, Western Australia',
    value: 10009547,
  },
  {
    label: 'Salt Ash, New South Wales',
    value: 10000962,
  },
  {
    label: 'Salter Point, Western Australia',
    value: 10008673,
  },
  {
    label: 'Saltwater River, Tasmania',
    value: 10006325,
  },
  {
    label: 'Samford, Queensland',
    value: 10003859,
  },
  {
    label: 'Samson, Western Australia',
    value: 10008707,
  },
  {
    label: 'Samsonvale, Queensland',
    value: 10003865,
  },
  {
    label: 'San Remo, New South Wales',
    value: 10000805,
  },
  {
    label: 'San Remo, Victoria',
    value: 10008267,
  },
  {
    label: 'San Remo, Western Australia',
    value: 10008794,
  },
  {
    label: 'Sanctuary Cove, Queensland',
    value: 10003128,
  },
  {
    label: 'Sanctuary Point, New South Wales',
    value: 10001809,
  },
  {
    label: 'Sandalwood, South Australia',
    value: 10005787,
  },
  {
    label: 'Sanderson, Northern Territory',
    value: 10002697,
  },
  {
    label: 'Sanderson, Northern Territory',
    value: 10002700,
  },
  {
    label: 'Sanderston, South Australia',
    value: 10005686,
  },
  {
    label: 'Sandfly, Tasmania',
    value: 10006273,
  },
  {
    label: 'Sandford, Tasmania',
    value: 10006114,
  },
  {
    label: 'Sandford, Victoria',
    value: 10007268,
  },
  {
    label: 'Sandgate, New South Wales',
    value: 10000925,
  },
  {
    label: 'Sandgate, Queensland',
    value: 10002835,
  },
  {
    label: 'Sandhurst East, Victoria',
    value: 10007676,
  },
  {
    label: 'Sandiford, Queensland',
    value: 10004664,
  },
  {
    label: 'Sandown Village, Victoria',
    value: 10006983,
  },
  {
    label: 'Sandpatch, Western Australia',
    value: 10009209,
  },
  {
    label: 'Sandringham, New South Wales',
    value: 10000661,
  },
  {
    label: 'Sandringham, Queensland',
    value: 10004464,
  },
  {
    label: 'Sandringham, Victoria',
    value: 10007021,
  },
  {
    label: 'Sandsprings, Western Australia',
    value: 10009790,
  },
  {
    label: 'Sandstone, Western Australia',
    value: 10009917,
  },
  {
    label: 'Sandstone Point, Queensland',
    value: 10003832,
  },
  {
    label: 'Sandy Bay, Tasmania',
    value: 10006072,
  },
  {
    label: 'Sandy Bay, Tasmania',
    value: 10006075,
  },
  {
    label: 'Sandy Beach, New South Wales',
    value: 10001461,
  },
  {
    label: 'Sandy Camp, Queensland',
    value: 10003560,
  },
  {
    label: 'Sandy Creek, South Australia',
    value: 10005815,
  },
  {
    label: 'Sandy Creek, Victoria',
    value: 10007924,
  },
  {
    label: 'Sandy Flat, New South Wales',
    value: 10001213,
  },
  {
    label: 'Sandy Gully, Western Australia',
    value: 10009828,
  },
  {
    label: 'Sandy Hollow, New South Wales',
    value: 10001082,
  },
  {
    label: 'Sandy Pocket, Queensland',
    value: 10005159,
  },
  {
    label: 'Sandy Point, New South Wales',
    value: 10000576,
  },
  {
    label: 'Sandy Point, Victoria',
    value: 10008320,
  },
  {
    label: 'Sandy Ridges, Queensland',
    value: 10004117,
  },
  {
    label: 'Sans Souci, New South Wales',
    value: 10000662,
  },
  {
    label: 'Santa Teresa, Northern Territory',
    value: 10002781,
  },
  {
    label: 'Sapphire, Queensland',
    value: 10004518,
  },
  {
    label: 'Saratoga, New South Wales',
    value: 10000740,
  },
  {
    label: 'Sarina, Queensland',
    value: 10004654,
  },
  {
    label: 'Sarina Beach, Queensland',
    value: 10004660,
  },
  {
    label: 'Sarina Range, Queensland',
    value: 10004658,
  },
  {
    label: 'Sarsfield, Victoria',
    value: 10008197,
  },
  {
    label: 'Sassafras, Tasmania',
    value: 10006570,
  },
  {
    label: 'Sassafras, Victoria',
    value: 10008067,
  },
  {
    label: 'Sassafras Gully, Victoria',
    value: 10008066,
  },
  {
    label: 'Satur, New South Wales',
    value: 10001106,
  },
  {
    label: 'Saunders Beach, Queensland',
    value: 10004929,
  },
  {
    label: 'Savage River, Tasmania',
    value: 10006619,
  },
  {
    label: 'Savernake, New South Wales',
    value: 10002120,
  },
  {
    label: 'Sawtell, New South Wales',
    value: 10001439,
  },
  {
    label: 'Sawyers Valley, Western Australia',
    value: 10008582,
  },
  {
    label: 'Scaddan, Western Australia',
    value: 10009553,
  },
  {
    label: 'Scamander, Tasmania',
    value: 10006359,
  },
  {
    label: 'Scarborough, New South Wales',
    value: 10001665,
  },
  {
    label: 'Scarborough, Queensland',
    value: 10002845,
  },
  {
    label: 'Scarborough, Western Australia',
    value: 10008436,
  },
  {
    label: 'Scarborough, Western Australia',
    value: 10010108,
  },
  {
    label: 'Scarness, Queensland',
    value: 10004270,
  },
  {
    label: 'Scarsdale, Victoria',
    value: 10007355,
  },
  {
    label: 'Scheyville, New South Wales',
    value: 10002327,
  },
  {
    label: 'Schofields, New South Wales',
    value: 10002356,
  },
  {
    label: 'Schroeder, Western Australia',
    value: 10009016,
  },
  {
    label: 'Scone, New South Wales',
    value: 10001101,
  },
  {
    label: 'Scoresby, Victoria',
    value: 10006996,
  },
  {
    label: 'Scoresby Bc, Victoria',
    value: 10006993,
  },
  {
    label: 'Scotchy Pocket, Queensland',
    value: 10003958,
  },
  {
    label: 'Scotland Island, New South Wales',
    value: 10000401,
  },
  {
    label: 'Scotsburn, Victoria',
    value: 10007392,
  },
  {
    label: 'Scotsdale, Western Australia',
    value: 10009233,
  },
  {
    label: 'Scott Creek, South Australia',
    value: 10005600,
  },
  {
    label: 'Scott River, Western Australia',
    value: 10009034,
  },
  {
    label: 'Scott River East, Western Australia',
    value: 10008965,
  },
  {
    label: 'Scotts Brook, Western Australia',
    value: 10008898,
  },
  {
    label: 'Scotts Creek, Victoria',
    value: 10007196,
  },
  {
    label: 'Scotts Head, New South Wales',
    value: 10001418,
  },
  {
    label: 'Scottsdale, Tasmania',
    value: 10006450,
  },
  {
    label: 'Scrub Creek, Queensland',
    value: 10003314,
  },
  {
    label: 'Scrubby Creek, Queensland',
    value: 10003753,
  },
  {
    label: 'Scrubby Creek, Queensland',
    value: 10003990,
  },
  {
    label: 'Scrubby Mountain, Queensland',
    value: 10003509,
  },
  {
    label: 'Scullin, Australian Capital Territory',
    value: 10000070,
  },
  {
    label: 'Sea Lake, Victoria',
    value: 10007659,
  },
  {
    label: 'Seabird, Western Australia',
    value: 10008489,
  },
  {
    label: 'Seabrook, Tasmania',
    value: 10006638,
  },
  {
    label: 'Seabrook, Victoria',
    value: 10006721,
  },
  {
    label: 'Seacliff, South Australia',
    value: 10005372,
  },
  {
    label: 'Seacliff Park, South Australia',
    value: 10005373,
  },
  {
    label: 'Seacombe Gardens, South Australia',
    value: 10005365,
  },
  {
    label: 'Seacombe Heights, South Australia',
    value: 10005367,
  },
  {
    label: 'Seaford, South Australia',
    value: 10005643,
  },
  {
    label: 'Seaford, Victoria',
    value: 10007043,
  },
  {
    label: 'Seaford Heights, South Australia',
    value: 10005642,
  },
  {
    label: 'Seaford Meadows, South Australia',
    value: 10005644,
  },
  {
    label: 'Seaforth, New South Wales',
    value: 10000370,
  },
  {
    label: 'Seaforth, Queensland',
    value: 10004729,
  },
  {
    label: 'Seaham, New South Wales',
    value: 10001021,
  },
  {
    label: 'Seaholme, Victoria',
    value: 10006699,
  },
  {
    label: 'Seal Rocks, New South Wales',
    value: 10001292,
  },
  {
    label: 'Seaspray, Victoria',
    value: 10008161,
  },
  {
    label: 'Seaton, South Australia',
    value: 10005301,
  },
  {
    label: 'Seaton, Victoria',
    value: 10008175,
  },
  {
    label: 'Seaview Downs, South Australia',
    value: 10005375,
  },
  {
    label: 'Sebastian, Victoria',
    value: 10007706,
  },
  {
    label: 'Sebastopol, New South Wales',
    value: 10002189,
  },
  {
    label: 'Sebastopol, Victoria',
    value: 10007409,
  },
  {
    label: 'Second Valley, South Australia',
    value: 10005660,
  },
  {
    label: 'Secret Harbour, Western Australia',
    value: 10008753,
  },
  {
    label: 'Sedan, South Australia',
    value: 10005825,
  },
  {
    label: 'Seddon, Victoria',
    value: 10006685,
  },
  {
    label: 'Seddon West, Victoria',
    value: 10006686,
  },
  {
    label: 'Seelands, New South Wales',
    value: 10001480,
  },
  {
    label: 'Sefton, New South Wales',
    value: 10000537,
  },
  {
    label: 'Sefton Park, South Australia',
    value: 10005478,
  },
  {
    label: 'Selbourne, Tasmania',
    value: 10006505,
  },
  {
    label: 'Selby, Victoria',
    value: 10006958,
  },
  {
    label: 'Selene, Queensland',
    value: 10004195,
  },
  {
    label: 'Sellheim, Queensland',
    value: 10004913,
  },
  {
    label: 'Sellicks Beach, South Australia',
    value: 10005653,
  },
  {
    label: 'Sellicks Hill, South Australia',
    value: 10005654,
  },
  {
    label: 'Semaphore, South Australia',
    value: 10005292,
  },
  {
    label: 'Seppeltsfield, South Australia',
    value: 10005829,
  },
  {
    label: 'Seppings, Western Australia',
    value: 10009210,
  },
  {
    label: 'Septimus, Queensland',
    value: 10004760,
  },
  {
    label: 'Serpentine, Victoria',
    value: 10007636,
  },
  {
    label: 'Serpentine, Western Australia',
    value: 10008655,
  },
  {
    label: 'Serviceton, Victoria',
    value: 10007501,
  },
  {
    label: 'Seven Hills, New South Wales',
    value: 10000175,
  },
  {
    label: 'Seven Hills, New South Wales',
    value: 10000499,
  },
  {
    label: 'Seven Hills, Queensland',
    value: 10003052,
  },
  {
    label: 'Seven Hills West, New South Wales',
    value: 10000501,
  },
  {
    label: 'Seven Mile Beach, Tasmania',
    value: 10006294,
  },
  {
    label: 'Seventeen Mile, Queensland',
    value: 10003375,
  },
  {
    label: 'Seventeen Mile Rocks, Queensland',
    value: 10002918,
  },
  {
    label: 'Seventeen Seventy, Queensland',
    value: 10004388,
  },
  {
    label: 'Severnlea, Queensland',
    value: 10003453,
  },
  {
    label: 'Seville, Victoria',
    value: 10006921,
  },
  {
    label: 'Seville Grove, Western Australia',
    value: 10008641,
  },
  {
    label: 'Sexton, Queensland',
    value: 10003968,
  },
  {
    label: 'Sextonville, New South Wales',
    value: 10001511,
  },
  {
    label: 'Seymour, Tasmania',
    value: 10006361,
  },
  {
    label: 'Seymour, Victoria',
    value: 10007846,
  },
  {
    label: 'Seymour, Victoria',
    value: 10007847,
  },
  {
    label: 'Shackleton, Western Australia',
    value: 10009350,
  },
  {
    label: 'Shadforth, Western Australia',
    value: 10009225,
  },
  {
    label: 'Shailer Park, Queensland',
    value: 10003012,
  },
  {
    label: 'Shalvey, New South Wales',
    value: 10002383,
  },
  {
    label: 'Shanes Park, New South Wales',
    value: 10002305,
  },
  {
    label: 'Shannon, Tasmania',
    value: 10006124,
  },
  {
    label: 'Shannon Brook, New South Wales',
    value: 10001503,
  },
  {
    label: 'Shannon River Mill, Western Australia',
    value: 10008947,
  },
  {
    label: 'Sharon, Queensland',
    value: 10004297,
  },
  {
    label: 'Shay Gap, Western Australia',
    value: 10010055,
  },
  {
    label: 'She Oaks, Victoria',
    value: 10007296,
  },
  {
    label: 'Shea-Oak Log, South Australia',
    value: 10005838,
  },
  {
    label: 'Shearwater, Tasmania',
    value: 10006561,
  },
  {
    label: 'Sheep Hills, Victoria',
    value: 10007462,
  },
  {
    label: 'Sheffield, Tasmania',
    value: 10006550,
  },
  {
    label: 'Sheidow Park, South Australia',
    value: 10005621,
  },
  {
    label: 'Shelbourne, Victoria',
    value: 10007564,
  },
  {
    label: 'Sheldon, Queensland',
    value: 10003038,
  },
  {
    label: 'Shelford, Victoria',
    value: 10007292,
  },
  {
    label: 'Shell Cove, New South Wales',
    value: 10001702,
  },
  {
    label: 'Shell Pocket, Queensland',
    value: 10005028,
  },
  {
    label: 'Shelley, Victoria',
    value: 10007934,
  },
  {
    label: 'Shelley, Western Australia',
    value: 10008661,
  },
  {
    label: 'Shellharbour, New South Wales',
    value: 10001701,
  },
  {
    label: 'Shellharbour Square, New South Wales',
    value: 10001704,
  },
  {
    label: 'Shelly Beach, New South Wales',
    value: 10000792,
  },
  {
    label: 'Shelly Beach, Queensland',
    value: 10003877,
  },
  {
    label: 'Shelly Beach, Queensland',
    value: 10004867,
  },
  {
    label: 'Shenton Park, Western Australia',
    value: 10008407,
  },
  {
    label: 'Shepparton, Victoria',
    value: 10007801,
  },
  {
    label: 'Shepparton, Victoria',
    value: 10007813,
  },
  {
    label: 'Shepparton East, Victoria',
    value: 10007807,
  },
  {
    label: 'Sherbrooke, Victoria',
    value: 10008069,
  },
  {
    label: 'Sherlock, South Australia',
    value: 10005773,
  },
  {
    label: 'Sherlock, Western Australia',
    value: 10009987,
  },
  {
    label: 'Sherwood, Queensland',
    value: 10002931,
  },
  {
    label: 'Shirbourne, Queensland',
    value: 10004856,
  },
  {
    label: 'Shoal Bay, New South Wales',
    value: 10000949,
  },
  {
    label: 'Shoal Point, Queensland',
    value: 10004748,
  },
  {
    label: 'Shoalhaven Heads, New South Wales',
    value: 10001727,
  },
  {
    label: 'Shoalwater, Queensland',
    value: 10004519,
  },
  {
    label: 'Shoalwater, Western Australia',
    value: 10008748,
  },
  {
    label: 'Shooters Hill, New South Wales',
    value: 10002420,
  },
  {
    label: 'Shoreham, Victoria',
    value: 10008259,
  },
  {
    label: 'Shorncliffe, Queensland',
    value: 10002837,
  },
  {
    label: 'Shortland, New South Wales',
    value: 10000936,
  },
  {
    label: 'Shotts, Western Australia',
    value: 10008825,
  },
  {
    label: 'Shute Harbour, Queensland',
    value: 10004805,
  },
  {
    label: 'Sidmouth, Tasmania',
    value: 10006482,
  },
  {
    label: 'Siesta Park, Western Australia',
    value: 10008977,
  },
  {
    label: 'Silkstone, Queensland',
    value: 10003220,
  },
  {
    label: 'Silkwood, Queensland',
    value: 10005032,
  },
  {
    label: 'Silkwood East, Queensland',
    value: 10005036,
  },
  {
    label: 'Silvan, Victoria',
    value: 10008073,
  },
  {
    label: 'Silver Creek, Queensland',
    value: 10004785,
  },
  {
    label: 'Silver Ridge, Queensland',
    value: 10003372,
  },
  {
    label: 'Silver Sands, Western Australia',
    value: 10008785,
  },
  {
    label: 'Silver Spur, Queensland',
    value: 10003589,
  },
  {
    label: 'Silver Valley, Queensland',
    value: 10005177,
  },
  {
    label: 'Silverdale, New South Wales',
    value: 10002315,
  },
  {
    label: 'Silverleaf, Queensland',
    value: 10004044,
  },
  {
    label: 'Silverleigh, Queensland',
    value: 10003622,
  },
  {
    label: 'Silverton, New South Wales',
    value: 10002666,
  },
  {
    label: 'Silverwater, New South Wales',
    value: 10000182,
  },
  {
    label: 'Silverwater, New South Wales',
    value: 10000457,
  },
  {
    label: 'Silverwater, New South Wales',
    value: 10000825,
  },
  {
    label: 'Simmie, Queensland',
    value: 10003718,
  },
  {
    label: 'Simmie, Victoria',
    value: 10007723,
  },
  {
    label: 'Simpson, Victoria',
    value: 10007193,
  },
  {
    label: 'Simpsons Bay, Tasmania',
    value: 10006275,
  },
  {
    label: 'Simson, Victoria',
    value: 10007569,
  },
  {
    label: 'Sinagra, Western Australia',
    value: 10008556,
  },
  {
    label: 'Sinclair, Western Australia',
    value: 10009562,
  },
  {
    label: 'Singleton, New South Wales',
    value: 10001072,
  },
  {
    label: 'Singleton, Western Australia',
    value: 10008755,
  },
  {
    label: 'Singleton Milpo, New South Wales',
    value: 10001078,
  },
  {
    label: 'Sinnamon Park, Queensland',
    value: 10002919,
  },
  {
    label: 'Sir Samuel, Western Australia',
    value: 10009524,
  },
  {
    label: 'Sisters Beach, Tasmania',
    value: 10006623,
  },
  {
    label: 'Sisters Creek, Tasmania',
    value: 10006643,
  },
  {
    label: 'Skeleton Rock, Western Australia',
    value: 10009463,
  },
  {
    label: 'Skenes Creek, Victoria',
    value: 10007129,
  },
  {
    label: 'Skinners Flat, Victoria',
    value: 10007643,
  },
  {
    label: 'Skipton, Victoria',
    value: 10007414,
  },
  {
    label: 'Skye, South Australia',
    value: 10005456,
  },
  {
    label: 'Skye, Victoria',
    value: 10008352,
  },
  {
    label: 'Skyring Reserve, Queensland',
    value: 10004354,
  },
  {
    label: 'Slacks Creek, Queensland',
    value: 10003009,
  },
  {
    label: 'Slade Point, Queensland',
    value: 10004666,
  },
  {
    label: 'Smeaton, Victoria',
    value: 10007419,
  },
  {
    label: 'Smiggin Holes, New South Wales',
    value: 10002060,
  },
  {
    label: 'Smith Brook, Western Australia',
    value: 10008928,
  },
  {
    label: 'Smithfield, New South Wales',
    value: 10000541,
  },
  {
    label: 'Smithfield, Queensland',
    value: 10005196,
  },
  {
    label: 'Smithfield, South Australia',
    value: 10005550,
  },
  {
    label: 'Smithfield Plains, South Australia',
    value: 10005553,
  },
  {
    label: 'Smithfield West, New South Wales',
    value: 10000540,
  },
  {
    label: 'Smithlea, Queensland',
    value: 10003587,
  },
  {
    label: 'Smiths Gully, Victoria',
    value: 10008041,
  },
  {
    label: 'Smiths Lake, New South Wales',
    value: 10001315,
  },
  {
    label: 'Smithton, Tasmania',
    value: 10006655,
  },
  {
    label: 'Smithtown, New South Wales',
    value: 10001369,
  },
  {
    label: 'Smoky Bay, South Australia',
    value: 10006028,
  },
  {
    label: 'Smoky Creek, Queensland',
    value: 10004509,
  },
  {
    label: 'Smythes Creek, Victoria',
    value: 10007358,
  },
  {
    label: 'Smythesdale, Victoria',
    value: 10007361,
  },
  {
    label: 'Snake Valley, Victoria',
    value: 10007360,
  },
  {
    label: 'Snowtown, South Australia',
    value: 10005917,
  },
  {
    label: 'Snug, Tasmania',
    value: 10006155,
  },
  {
    label: 'Sodwalls, New South Wales',
    value: 10002440,
  },
  {
    label: 'Sofala, New South Wales',
    value: 10002485,
  },
  {
    label: 'Soldiers Hill, Queensland',
    value: 10004986,
  },
  {
    label: 'Soldiers Point, New South Wales',
    value: 10000956,
  },
  {
    label: 'Solomontown, South Australia',
    value: 10005925,
  },
  {
    label: 'Solus, Western Australia',
    value: 10008761,
  },
  {
    label: 'Somers, Victoria',
    value: 10008272,
  },
  {
    label: 'Somersby, New South Wales',
    value: 10000715,
  },
  {
    label: 'Somerset, Tasmania',
    value: 10006633,
  },
  {
    label: 'Somerset Dam, Queensland',
    value: 10003311,
  },
  {
    label: 'Somerton, New South Wales',
    value: 10001130,
  },
  {
    label: 'Somerton, Victoria',
    value: 10006787,
  },
  {
    label: 'Somerton Park, South Australia',
    value: 10005357,
  },
  {
    label: 'Somerville, Victoria',
    value: 10008253,
  },
  {
    label: 'Somerville, Western Australia',
    value: 10009490,
  },
  {
    label: 'Sorell, Tasmania',
    value: 10006303,
  },
  {
    label: 'Sorell Creek, Tasmania',
    value: 10006228,
  },
  {
    label: 'Sorrento, Victoria',
    value: 10008293,
  },
  {
    label: 'Sorrento, Western Australia',
    value: 10008439,
  },
  {
    label: 'South Albury, New South Wales',
    value: 10002094,
  },
  {
    label: 'South Arm, New South Wales',
    value: 10001473,
  },
  {
    label: 'South Arm, Tasmania',
    value: 10006117,
  },
  {
    label: 'South Australia, South Australia',
    value: 10010176,
  },
  {
    label: 'South Bingera, Queensland',
    value: 10004329,
  },
  {
    label: 'South Bodallin, Western Australia',
    value: 10009458,
  },
  {
    label: 'South Boulder, Western Australia',
    value: 10009514,
  },
  {
    label: 'South Brighton, South Australia',
    value: 10005368,
  },
  {
    label: 'South Brisbane, Queensland',
    value: 10002941,
  },
  {
    label: 'South Bunbury, Western Australia',
    value: 10008859,
  },
  {
    label: 'South Burracoppin, Western Australia',
    value: 10009449,
  },
  {
    label: 'South Carnarvon, Western Australia',
    value: 10009951,
  },
  {
    label: 'South Coast Mc, New South Wales',
    value: 10001681,
  },
  {
    label: 'South Datatine, Western Australia',
    value: 10009138,
  },
  {
    label: 'South Doodlakine, Western Australia',
    value: 10009432,
  },
  {
    label: 'South Dudley, Victoria',
    value: 10008382,
  },
  {
    label: 'South Durras, New South Wales',
    value: 10001750,
  },
  {
    label: 'South East Nanango, Queensland',
    value: 10004108,
  },
  {
    label: 'South Fremantle, Western Australia',
    value: 10008699,
  },
  {
    label: 'South Gladstone, Queensland',
    value: 10004415,
  },
  {
    label: 'South Glencoe, Western Australia',
    value: 10009137,
  },
  {
    label: 'South Golden Beach, New South Wales',
    value: 10001608,
  },
  {
    label: 'South Grafton, New South Wales',
    value: 10001479,
  },
  {
    label: 'South Greenough, Western Australia',
    value: 10009743,
  },
  {
    label: 'South Guildford, Western Australia',
    value: 10008511,
  },
  {
    label: 'South Hedland, Western Australia',
    value: 10010012,
  },
  {
    label: 'South Hobart, Tasmania',
    value: 10006070,
  },
  {
    label: 'South Hurstville, New South Wales',
    value: 10000666,
  },
  {
    label: 'South Innisfail, Queensland',
    value: 10005062,
  },
  {
    label: 'South Johnstone, Queensland',
    value: 10005043,
  },
  {
    label: 'South Kalgoorlie, Western Australia',
    value: 10009485,
  },
  {
    label: 'South Kempsey, New South Wales',
    value: 10001370,
  },
  {
    label: 'South Kilkerran, South Australia',
    value: 10005964,
  },
  {
    label: 'South Kingsville, Victoria',
    value: 10006697,
  },
  {
    label: 'South Kolan, Queensland',
    value: 10004313,
  },
  {
    label: 'South Kukerin, Western Australia',
    value: 10009279,
  },
  {
    label: 'South Kumminin, Western Australia',
    value: 10009317,
  },
  {
    label: 'South Kununoppin, Western Australia',
    value: 10009641,
  },
  {
    label: 'South Lake, Western Australia',
    value: 10008713,
  },
  {
    label: 'South Lake Grace, Western Australia',
    value: 10009285,
  },
  {
    label: 'South Launceston, Tasmania',
    value: 10006384,
  },
  {
    label: 'South Mackay, Queensland',
    value: 10004693,
  },
  {
    label: 'South Melbourne, Victoria',
    value: 10007053,
  },
  {
    label: 'South Melbourne Dc, Victoria',
    value: 10007054,
  },
  {
    label: 'South Molle, Queensland',
    value: 10004713,
  },
  {
    label: 'South Morang, Victoria',
    value: 10008024,
  },
  {
    label: 'South Mount Cameron, Tasmania',
    value: 10006467,
  },
  {
    label: 'South Murchison, Western Australia',
    value: 10009911,
  },
  {
    label: 'South Newdegate, Western Australia',
    value: 10009293,
  },
  {
    label: 'South Pambula, New South Wales',
    value: 10001849,
  },
  {
    label: 'South Perth, Western Australia',
    value: 10008670,
  },
  {
    label: 'South Perth, Western Australia',
    value: 10010128,
  },
  {
    label: 'South Plantations, Western Australia',
    value: 10009939,
  },
  {
    label: 'South Plympton, South Australia',
    value: 10005334,
  },
  {
    label: 'South Quairading, Western Australia',
    value: 10009336,
  },
  {
    label: 'South Riana, Tasmania',
    value: 10006600,
  },
  {
    label: 'South Ripley, Queensland',
    value: 10003250,
  },
  {
    label: 'South Stirling, Western Australia',
    value: 10009156,
  },
  {
    label: 'South Stradbroke, Queensland',
    value: 10003148,
  },
  {
    label: 'South Talwood, Queensland',
    value: 10003792,
  },
  {
    label: 'South Toowoomba, Queensland',
    value: 10003399,
  },
  {
    label: 'South Townsville, Queensland',
    value: 10004865,
  },
  {
    label: 'South Trayning, Western Australia',
    value: 10009640,
  },
  {
    label: 'South Trees, Queensland',
    value: 10004413,
  },
  {
    label: 'South Turramurra, New South Wales',
    value: 10000336,
  },
  {
    label: 'South West Rocks, New South Wales',
    value: 10001351,
  },
  {
    label: 'South Yaamba, Queensland',
    value: 10004511,
  },
  {
    label: 'South Yarra, Victoria',
    value: 10006923,
  },
  {
    label: 'South Yelbeni, Western Australia',
    value: 10009637,
  },
  {
    label: 'South Yilgarn, Western Australia',
    value: 10009461,
  },
  {
    label: 'South Yuna, Western Australia',
    value: 10009812,
  },
  {
    label: 'South Yunderup, Western Australia',
    value: 10008769,
  },
  {
    label: 'Southampton, Western Australia',
    value: 10008907,
  },
  {
    label: 'Southbank, Victoria',
    value: 10006682,
  },
  {
    label: 'Southbrook, Queensland',
    value: 10003566,
  },
  {
    label: 'Southend, South Australia',
    value: 10005753,
  },
  {
    label: 'Southern Brook, Western Australia',
    value: 10009399,
  },
  {
    label: 'Southern Cross, Victoria',
    value: 10007230,
  },
  {
    label: 'Southern Cross, Western Australia',
    value: 10009470,
  },
  {
    label: 'Southern River, Western Australia',
    value: 10008628,
  },
  {
    label: 'Southland Centre, Victoria',
    value: 10007022,
  },
  {
    label: 'Southport, Queensland',
    value: 10003144,
  },
  {
    label: 'Southport, Tasmania',
    value: 10006172,
  },
  {
    label: 'Southtown, Queensland',
    value: 10003397,
  },
  {
    label: 'Sovereign Hill, Victoria',
    value: 10007343,
  },
  {
    label: 'Spalding, South Australia',
    value: 10005883,
  },
  {
    label: 'Spalding, Western Australia',
    value: 10009763,
  },
  {
    label: 'Spalford, Tasmania',
    value: 10006587,
  },
  {
    label: 'Spearwood, Western Australia',
    value: 10008710,
  },
  {
    label: 'Speed, Victoria',
    value: 10007606,
  },
  {
    label: 'Speers Point, New South Wales',
    value: 10000866,
  },
  {
    label: 'Spence, Australian Capital Territory',
    value: 10000076,
  },
  {
    label: 'Spencer, New South Wales',
    value: 10002392,
  },
  {
    label: 'Spencer Park, Western Australia',
    value: 10009214,
  },
  {
    label: 'Spencers Brook, Western Australia',
    value: 10009407,
  },
  {
    label: 'Spicers Creek, New South Wales',
    value: 10002525,
  },
  {
    label: 'Spit Junction, New South Wales',
    value: 10000364,
  },
  {
    label: 'Splinter Creek, Queensland',
    value: 10004197,
  },
  {
    label: 'Spotswood, Victoria',
    value: 10006696,
  },
  {
    label: 'Sprent, Tasmania',
    value: 10006598,
  },
  {
    label: 'Spreyton, Tasmania',
    value: 10006576,
  },
  {
    label: 'Spring Beach, Tasmania',
    value: 10006327,
  },
  {
    label: 'Spring Bluff, Queensland',
    value: 10003463,
  },
  {
    label: 'Spring Creek, Queensland',
    value: 10003349,
  },
  {
    label: 'Spring Creek, Queensland',
    value: 10003559,
  },
  {
    label: 'Spring Grove, New South Wales',
    value: 10001519,
  },
  {
    label: 'Spring Gully, Victoria',
    value: 10007679,
  },
  {
    label: 'Spring Hill, New South Wales',
    value: 10002501,
  },
  {
    label: 'Spring Hill, Queensland',
    value: 10002802,
  },
  {
    label: 'Spring Hill, Queensland',
    value: 10002811,
  },
  {
    label: 'Spring Ridge, New South Wales',
    value: 10001135,
  },
  {
    label: 'Springbank, Victoria',
    value: 10007370,
  },
  {
    label: 'Springbrook, Queensland',
    value: 10003132,
  },
  {
    label: 'Springdale, New South Wales',
    value: 10002187,
  },
  {
    label: 'Springfield, New South Wales',
    value: 10000730,
  },
  {
    label: 'Springfield, Queensland',
    value: 10003207,
  },
  {
    label: 'Springfield, South Australia',
    value: 10005398,
  },
  {
    label: 'Springfield, Tasmania',
    value: 10006448,
  },
  {
    label: 'Springfield, Western Australia',
    value: 10009730,
  },
  {
    label: 'Springfield Lakes, Queensland',
    value: 10003209,
  },
  {
    label: 'Springhurst, Victoria',
    value: 10007890,
  },
  {
    label: 'Springlands, Queensland',
    value: 10004814,
  },
  {
    label: 'Springs, Western Australia',
    value: 10009085,
  },
  {
    label: 'Springside, Queensland',
    value: 10003504,
  },
  {
    label: 'Springsure, Queensland',
    value: 10004630,
  },
  {
    label: 'Springton, South Australia',
    value: 10005683,
  },
  {
    label: 'Springvale, Victoria',
    value: 10006984,
  },
  {
    label: 'Springvale South, Victoria',
    value: 10006986,
  },
  {
    label: 'Springwood, New South Wales',
    value: 10002401,
  },
  {
    label: 'Springwood, Queensland',
    value: 10003008,
  },
  {
    label: 'St Agnes, Queensland',
    value: 10004365,
  },
  {
    label: 'St Agnes, South Australia',
    value: 10005515,
  },
  {
    label: 'St Albans, New South Wales',
    value: 10002393,
  },
  {
    label: 'St Albans, Victoria',
    value: 10006707,
  },
  {
    label: 'St Albans Park, Victoria',
    value: 10007084,
  },
  {
    label: 'St Andrews, New South Wales',
    value: 10001909,
  },
  {
    label: 'St Andrews, Victoria',
    value: 10008042,
  },
  {
    label: 'St Andrews Beach, Victoria',
    value: 10008291,
  },
  {
    label: 'St Arnaud, Victoria',
    value: 10007593,
  },
  {
    label: 'St Aubyn, Queensland',
    value: 10003476,
  },
  {
    label: 'St Clair, New South Wales',
    value: 10001076,
  },
  {
    label: 'St Clair, New South Wales',
    value: 10002347,
  },
  {
    label: 'St George, Queensland',
    value: 10003769,
  },
  {
    label: 'St Georges, South Australia',
    value: 10005409,
  },
  {
    label: 'St Georges Basin, New South Wales',
    value: 10001819,
  },
  {
    label: 'St Helens, Queensland',
    value: 10003498,
  },
  {
    label: 'St Helens, Queensland',
    value: 10004230,
  },
  {
    label: 'St Helens, Tasmania',
    value: 10006374,
  },
  {
    label: 'St Helens, Victoria',
    value: 10007232,
  },
  {
    label: 'St Helens Park, New South Wales',
    value: 10001895,
  },
  {
    label: 'St Huberts Island, New South Wales',
    value: 10000751,
  },
  {
    label: 'St Ives, New South Wales',
    value: 10000340,
  },
  {
    label: 'St James, Victoria',
    value: 10007987,
  },
  {
    label: 'St James, Western Australia',
    value: 10008609,
  },
  {
    label: 'St Johns Park, New South Wales',
    value: 10000586,
  },
  {
    label: 'St Kilda, Queensland',
    value: 10004359,
  },
  {
    label: 'St Kilda, South Australia',
    value: 10005536,
  },
  {
    label: 'St Kilda, Victoria',
    value: 10007000,
  },
  {
    label: 'St Kilda East, Victoria',
    value: 10007004,
  },
  {
    label: 'St Kilda Road, Victoria',
    value: 10008391,
  },
  {
    label: 'St Kilda Road Central, Victoria',
    value: 10008395,
  },
  {
    label: 'St Kilda South, Victoria',
    value: 10007001,
  },
  {
    label: 'St Kilda West, Victoria',
    value: 10007002,
  },
  {
    label: 'St Lawrence, Queensland',
    value: 10004575,
  },
  {
    label: 'St Leonards, New South Wales',
    value: 10000159,
  },
  {
    label: 'St Leonards, New South Wales',
    value: 10000310,
  },
  {
    label: 'St Leonards, Tasmania',
    value: 10006391,
  },
  {
    label: 'St Leonards, Victoria',
    value: 10007109,
  },
  {
    label: 'St Lucia, Queensland',
    value: 10002902,
  },
  {
    label: 'St Mary, Queensland',
    value: 10003962,
  },
  {
    label: 'St Marys, New South Wales',
    value: 10000179,
  },
  {
    label: 'St Marys, New South Wales',
    value: 10002349,
  },
  {
    label: 'St Marys, South Australia',
    value: 10005350,
  },
  {
    label: 'St Marys, Tasmania',
    value: 10006356,
  },
  {
    label: 'St Morris, South Australia',
    value: 10005431,
  },
  {
    label: 'St Pauls, New South Wales',
    value: 10000249,
  },
  {
    label: 'St Peters, New South Wales',
    value: 10000279,
  },
  {
    label: 'St Peters, South Australia',
    value: 10005439,
  },
  {
    label: 'St Ronans, Western Australia',
    value: 10009052,
  },
  {
    label: 'Stafford, Queensland',
    value: 10002876,
  },
  {
    label: 'Stafford Heights, Queensland',
    value: 10002880,
  },
  {
    label: 'Staghorn Flat, Victoria',
    value: 10007908,
  },
  {
    label: 'Stake Hill, Western Australia',
    value: 10008774,
  },
  {
    label: 'Stamford, Queensland',
    value: 10004955,
  },
  {
    label: 'Stanage, Queensland',
    value: 10004513,
  },
  {
    label: 'Stanford Merthyr, New South Wales',
    value: 10001049,
  },
  {
    label: 'Stanhope, New South Wales',
    value: 10001093,
  },
  {
    label: 'Stanhope, Victoria',
    value: 10007792,
  },
  {
    label: 'Stanhope Gardens, New South Wales',
    value: 10002372,
  },
  {
    label: 'Stanley, Tasmania',
    value: 10006671,
  },
  {
    label: 'Stanley, Victoria',
    value: 10008018,
  },
  {
    label: 'Stanmore, New South Wales',
    value: 10000290,
  },
  {
    label: 'Stannifer, New South Wales',
    value: 10001198,
  },
  {
    label: 'Stannum, New South Wales',
    value: 10001207,
  },
  {
    label: 'Stansbury, South Australia',
    value: 10005979,
  },
  {
    label: 'Stanthorpe, Queensland',
    value: 10003581,
  },
  {
    label: 'Stanwell, Queensland',
    value: 10004534,
  },
  {
    label: 'Stanwell Park, New South Wales',
    value: 10001658,
  },
  {
    label: 'Stanwell Tops, New South Wales',
    value: 10001661,
  },
  {
    label: 'Stapylton, Queensland',
    value: 10003085,
  },
  {
    label: 'Station Arcade, South Australia',
    value: 10005237,
  },
  {
    label: 'Staughton Vale, Victoria',
    value: 10007100,
  },
  {
    label: 'Stavely, Victoria',
    value: 10007443,
  },
  {
    label: 'Staverton, Tasmania',
    value: 10006551,
  },
  {
    label: 'Stawell, Victoria',
    value: 10007446,
  },
  {
    label: 'Steels Creek, Victoria',
    value: 10008053,
  },
  {
    label: 'Steiglitz, Queensland',
    value: 10003095,
  },
  {
    label: 'Steiglitz, Victoria',
    value: 10007301,
  },
  {
    label: 'Stenhouse Bay, South Australia',
    value: 10005974,
  },
  {
    label: 'Stepney, South Australia',
    value: 10005440,
  },
  {
    label: 'Steppes, Tasmania',
    value: 10006143,
  },
  {
    label: 'Stewarton, Queensland',
    value: 10004515,
  },
  {
    label: 'Stewarts Brook, New South Wales',
    value: 10001102,
  },
  {
    label: 'Stieglitz, Tasmania',
    value: 10006368,
  },
  {
    label: 'Stirling, Australian Capital Territory',
    value: 10000051,
  },
  {
    label: 'Stirling, South Australia',
    value: 10005598,
  },
  {
    label: 'Stirling, Western Australia',
    value: 10008444,
  },
  {
    label: 'Stirling Estate, Western Australia',
    value: 10008959,
  },
  {
    label: 'Stirling North, South Australia',
    value: 10006043,
  },
  {
    label: 'Stirling Range National Park, Western Australia',
    value: 10009260,
  },
  {
    label: 'Stockhaven, Queensland',
    value: 10004143,
  },
  {
    label: 'Stockinbingal, New South Wales',
    value: 10002269,
  },
  {
    label: 'Stockport, South Australia',
    value: 10005851,
  },
  {
    label: 'Stockton, New South Wales',
    value: 10000907,
  },
  {
    label: 'Stockton, Queensland',
    value: 10005155,
  },
  {
    label: 'Stockwell, South Australia',
    value: 10005831,
  },
  {
    label: 'Stockyard, Queensland',
    value: 10003366,
  },
  {
    label: 'Stockyard, Queensland',
    value: 10004547,
  },
  {
    label: 'Stokers Siding, New South Wales',
    value: 10001613,
  },
  {
    label: 'Stone Well, South Australia',
    value: 10005819,
  },
  {
    label: 'Stonehaven, Victoria',
    value: 10007089,
  },
  {
    label: 'Stonehenge, Queensland',
    value: 10003520,
  },
  {
    label: 'Stonehenge, Queensland',
    value: 10004644,
  },
  {
    label: 'Stonehenge, Tasmania',
    value: 10006214,
  },
  {
    label: 'Stonelands, Queensland',
    value: 10004093,
  },
  {
    label: 'Stoneleigh, Queensland',
    value: 10003508,
  },
  {
    label: 'Stones Corner, Queensland',
    value: 10002991,
  },
  {
    label: 'Stoneville, Western Australia',
    value: 10008595,
  },
  {
    label: 'Stonor, Tasmania',
    value: 10006206,
  },
  {
    label: 'Stony Creek, Queensland',
    value: 10003838,
  },
  {
    label: 'Stony Creek, Victoria',
    value: 10007428,
  },
  {
    label: 'Stony Creek, Victoria',
    value: 10008317,
  },
  {
    label: 'Stonyfell, South Australia',
    value: 10005417,
  },
  {
    label: 'Stonyford, Victoria',
    value: 10007176,
  },
  {
    label: 'Stoodley, Tasmania',
    value: 10006559,
  },
  {
    label: 'Stormlea, Tasmania',
    value: 10006322,
  },
  {
    label: 'Stoters Hill, Queensland',
    value: 10005056,
  },
  {
    label: 'Stotts Creek, New South Wales',
    value: 10001634,
  },
  {
    label: 'Stove Hill, Western Australia',
    value: 10009988,
  },
  {
    label: 'Stowport, Tasmania',
    value: 10006628,
  },
  {
    label: 'Strahan, Tasmania',
    value: 10006673,
  },
  {
    label: 'Stratford, New South Wales',
    value: 10001283,
  },
  {
    label: 'Stratford, Queensland',
    value: 10005103,
  },
  {
    label: 'Stratford, Victoria',
    value: 10008185,
  },
  {
    label: 'Strath Creek, Victoria',
    value: 10007839,
  },
  {
    label: 'Strathalbyn, South Australia',
    value: 10005713,
  },
  {
    label: 'Strathalbyn, Western Australia',
    value: 10009764,
  },
  {
    label: 'Strathallan, Victoria',
    value: 10007791,
  },
  {
    label: 'Stratham, Western Australia',
    value: 10008881,
  },
  {
    label: 'Strathblane, Tasmania',
    value: 10006187,
  },
  {
    label: 'Strathbogie, Victoria',
    value: 10007857,
  },
  {
    label: 'Strathdale, Victoria',
    value: 10007680,
  },
  {
    label: 'Strathdownie, Victoria',
    value: 10007266,
  },
  {
    label: 'Stratheden, New South Wales',
    value: 10001520,
  },
  {
    label: 'Stratherne, Western Australia',
    value: 10009089,
  },
  {
    label: 'Strathewen, Victoria',
    value: 10006849,
  },
  {
    label: 'Strathfield, New South Wales',
    value: 10000465,
  },
  {
    label: 'Strathfield, Queensland',
    value: 10004740,
  },
  {
    label: 'Strathfield South, New South Wales',
    value: 10000467,
  },
  {
    label: 'Strathfieldsaye, Victoria',
    value: 10007684,
  },
  {
    label: 'Strathgordon, Tasmania',
    value: 10006225,
  },
  {
    label: 'Strathkellar, Victoria',
    value: 10007252,
  },
  {
    label: 'Strathmerton, Victoria',
    value: 10007829,
  },
  {
    label: 'Strathmore, Victoria',
    value: 10006749,
  },
  {
    label: 'Strathmore Heights, Victoria',
    value: 10006748,
  },
  {
    label: 'Strathpine, Queensland',
    value: 10003799,
  },
  {
    label: 'Stratton, Western Australia',
    value: 10008517,
  },
  {
    label: 'Strawberry Hills, New South Wales',
    value: 10000213,
  },
  {
    label: 'Streaky Bay, South Australia',
    value: 10006030,
  },
  {
    label: 'Streatham, Victoria',
    value: 10007357,
  },
  {
    label: 'Strelley, Western Australia',
    value: 10010005,
  },
  {
    label: 'Stretton, Queensland',
    value: 10002980,
  },
  {
    label: 'Strickland, Tasmania',
    value: 10006239,
  },
  {
    label: 'Stroud, New South Wales',
    value: 10001303,
  },
  {
    label: 'Stroud Road, New South Wales',
    value: 10001265,
  },
  {
    label: 'Stuart, Queensland',
    value: 10004871,
  },
  {
    label: 'Stuart Mill, Victoria',
    value: 10007594,
  },
  {
    label: 'Stuart Park, Northern Territory',
    value: 10002710,
  },
  {
    label: 'Stuart Town, New South Wales',
    value: 10002521,
  },
  {
    label: 'Stuarts Point, New South Wales',
    value: 10001376,
  },
  {
    label: 'Studfield, Victoria',
    value: 10006946,
  },
  {
    label: 'Studio Village, Queensland',
    value: 10003115,
  },
  {
    label: 'Sturt, Queensland',
    value: 10004994,
  },
  {
    label: 'Sturt, South Australia',
    value: 10005364,
  },
  {
    label: 'Sturt Creek, Western Australia',
    value: 10010059,
  },
  {
    label: 'Subiaco, Western Australia',
    value: 10008409,
  },
  {
    label: 'Subiaco, Western Australia',
    value: 10010090,
  },
  {
    label: 'Success, Western Australia',
    value: 10008714,
  },
  {
    label: 'Success, Western Australia',
    value: 10010140,
  },
  {
    label: 'Suffolk Park, New South Wales',
    value: 10001596,
  },
  {
    label: 'Sugarloaf, Queensland',
    value: 10004793,
  },
  {
    label: 'Sulphur Creek, Tasmania',
    value: 10006607,
  },
  {
    label: 'Summer Hill, New South Wales',
    value: 10000459,
  },
  {
    label: 'Summer Hill, New South Wales',
    value: 10000877,
  },
  {
    label: 'Summerhill, Tasmania',
    value: 10006395,
  },
  {
    label: 'Summerland Point, New South Wales',
    value: 10000784,
  },
  {
    label: 'Summertown, South Australia',
    value: 10005592,
  },
  {
    label: 'Sumner, Queensland',
    value: 10002925,
  },
  {
    label: 'Sumner Park Bc, Queensland',
    value: 10002923,
  },
  {
    label: 'Sun Valley, Queensland',
    value: 10004420,
  },
  {
    label: 'Sunbury, Victoria',
    value: 10007508,
  },
  {
    label: 'Sundown, Queensland',
    value: 10005046,
  },
  {
    label: 'Sunny Corner, New South Wales',
    value: 10001453,
  },
  {
    label: 'Sunny Corner, New South Wales',
    value: 10002456,
  },
  {
    label: 'Sunny Nook, Queensland',
    value: 10004046,
  },
  {
    label: 'Sunnybank, Queensland',
    value: 10002959,
  },
  {
    label: 'Sunnybank Hills, Queensland',
    value: 10002961,
  },
  {
    label: 'Sunnycliffs, Victoria',
    value: 10007618,
  },
  {
    label: 'Sunnyside, Queensland',
    value: 10003678,
  },
  {
    label: 'Sunnyside, Tasmania',
    value: 10006547,
  },
  {
    label: 'Sunnyside, Western Australia',
    value: 10008922,
  },
  {
    label: 'Sunrise Beach, Queensland',
    value: 10003943,
  },
  {
    label: 'Sunset, Queensland',
    value: 10004974,
  },
  {
    label: 'Sunset Beach, Western Australia',
    value: 10009752,
  },
  {
    label: 'Sunshine, New South Wales',
    value: 10000823,
  },
  {
    label: 'Sunshine, Victoria',
    value: 10006703,
  },
  {
    label: 'Sunshine Acres, Queensland',
    value: 10004269,
  },
  {
    label: 'Sunshine Bay, New South Wales',
    value: 10001734,
  },
  {
    label: 'Sunshine Beach, Queensland',
    value: 10003945,
  },
  {
    label: 'Sunshine Coast, Queensland',
    value: 10010188,
  },
  {
    label: 'Sunshine Plaza, Queensland',
    value: 10003912,
  },
  {
    label: 'Surat, Queensland',
    value: 10003680,
  },
  {
    label: 'Surf Beach, New South Wales',
    value: 10001746,
  },
  {
    label: 'Surfers Paradise, Queensland',
    value: 10003153,
  },
  {
    label: 'Surfside, New South Wales',
    value: 10001738,
  },
  {
    label: 'Surges Bay, Tasmania',
    value: 10006202,
  },
  {
    label: 'Surrey Downs, South Australia',
    value: 10005578,
  },
  {
    label: 'Surrey Hills, Victoria',
    value: 10006882,
  },
  {
    label: 'Surry Hills, New South Wales',
    value: 10000207,
  },
  {
    label: 'Susan River, Queensland',
    value: 10004267,
  },
  {
    label: 'Sussex Inlet, New South Wales',
    value: 10001814,
  },
  {
    label: 'Sutherland, New South Wales',
    value: 10000153,
  },
  {
    label: 'Sutherland, New South Wales',
    value: 10000700,
  },
  {
    label: 'Sutherlands, South Australia',
    value: 10005846,
  },
  {
    label: 'Sutherlands Creek, Victoria',
    value: 10007299,
  },
  {
    label: 'Sutton, New South Wales',
    value: 10002038,
  },
  {
    label: 'Sutton Forest, New South Wales',
    value: 10001957,
  },
  {
    label: 'Sutton Grange, Victoria',
    value: 10007533,
  },
  {
    label: 'Suttor, Queensland',
    value: 10004742,
  },
  {
    label: 'Svensson Heights, Queensland',
    value: 10004301,
  },
  {
    label: 'Swan Bay, New South Wales',
    value: 10001008,
  },
  {
    label: 'Swan Bay, New South Wales',
    value: 10001525,
  },
  {
    label: 'Swan Bay, Tasmania',
    value: 10006402,
  },
  {
    label: 'Swan Hill, Victoria',
    value: 10007754,
  },
  {
    label: 'Swan Marsh, Victoria',
    value: 10007160,
  },
  {
    label: 'Swan Reach, South Australia',
    value: 10005828,
  },
  {
    label: 'Swan Reach, Victoria',
    value: 10008245,
  },
  {
    label: 'Swan Vale, New South Wales',
    value: 10001202,
  },
  {
    label: 'Swan View, Western Australia',
    value: 10008524,
  },
  {
    label: 'Swanbourne, Western Australia',
    value: 10008415,
  },
  {
    label: 'Swanhaven, New South Wales',
    value: 10001811,
  },
  {
    label: 'Swanpool, Victoria',
    value: 10007873,
  },
  {
    label: 'Swans Lagoon, Queensland',
    value: 10004845,
  },
  {
    label: 'Swansea, New South Wales',
    value: 10000844,
  },
  {
    label: 'Swansea, Tasmania',
    value: 10006333,
  },
  {
    label: 'Swifts Creek, Victoria',
    value: 10008240,
  },
  {
    label: 'Swinger Hill, Australian Capital Territory',
    value: 10000037,
  },
  {
    label: 'Sydenham, New South Wales',
    value: 10000278,
  },
  {
    label: 'Sydenham, Victoria',
    value: 10006738,
  },
  {
    label: 'Sydney, New South Wales',
    value: 10000195,
  },
  {
    label: 'Sydney CBD, New South Wales',
    value: 10010182,
  },
  {
    label: 'Sydney Domestic Airport, New South Wales',
    value: 10000226,
  },
  {
    label: 'Sydney International Airport, New South Wales',
    value: 10000224,
  },
  {
    label: 'Sydney Markets, New South Wales',
    value: 10000458,
  },
  {
    label: 'Sydney South, New South Wales',
    value: 10000130,
  },
  {
    label: 'Sydney South, New South Wales',
    value: 10000193,
  },
  {
    label: 'Sylvania, New South Wales',
    value: 10000675,
  },
  {
    label: 'Sylvania Southgate, New South Wales',
    value: 10000674,
  },
  {
    label: 'Sylvania Waters, New South Wales',
    value: 10000676,
  },
  {
    label: 'Symonston, Australian Capital Territory',
    value: 10000048,
  },
  {
    label: 'Syndal, Victoria',
    value: 10006940,
  },
  {
    label: 'Taabinga, Queensland',
    value: 10004078,
  },
  {
    label: 'Tabbita, New South Wales',
    value: 10002151,
  },
  {
    label: 'Tabilk, Victoria',
    value: 10007764,
  },
  {
    label: 'Table Cape, Tasmania',
    value: 10006642,
  },
  {
    label: 'Table Top, New South Wales',
    value: 10002090,
  },
  {
    label: 'Tablelands, Queensland',
    value: 10004054,
  },
  {
    label: 'Tablelands, Queensland',
    value: 10004398,
  },
  {
    label: 'Tabulam, New South Wales',
    value: 10001498,
  },
  {
    label: 'Tacoma, New South Wales',
    value: 10000779,
  },
  {
    label: 'Taggerty, Victoria',
    value: 10007959,
  },
  {
    label: 'Tahlee, New South Wales',
    value: 10001014,
  },
  {
    label: 'Tahmoor, New South Wales',
    value: 10001943,
  },
  {
    label: 'Taigum, Queensland',
    value: 10002839,
  },
  {
    label: 'Tailem Bend, South Australia',
    value: 10005722,
  },
  {
    label: 'Takalarup, Western Australia',
    value: 10009161,
  },
  {
    label: 'Takilberan, Queensland',
    value: 10004369,
  },
  {
    label: 'Takone, Tasmania',
    value: 10006651,
  },
  {
    label: 'Talandji, Western Australia',
    value: 10009968,
  },
  {
    label: 'Talbingo, New South Wales',
    value: 10002261,
  },
  {
    label: 'Talbot, Victoria',
    value: 10007430,
  },
  {
    label: 'Talbot, Western Australia',
    value: 10009045,
  },
  {
    label: 'Talbot West, Western Australia',
    value: 10009058,
  },
  {
    label: 'Taldra, South Australia',
    value: 10005789,
  },
  {
    label: 'Talegalla Weir, Queensland',
    value: 10004237,
  },
  {
    label: 'Talgarno, Victoria',
    value: 10007915,
  },
  {
    label: 'Talgomine, Western Australia',
    value: 10009646,
  },
  {
    label: 'Tallai, Queensland',
    value: 10003136,
  },
  {
    label: 'Tallangatta, Victoria',
    value: 10007933,
  },
  {
    label: 'Tallarook, Victoria',
    value: 10007843,
  },
  {
    label: 'Tallebudgera, Queensland',
    value: 10003186,
  },
  {
    label: 'Tallebudgera Valley, Queensland',
    value: 10003185,
  },
  {
    label: 'Tallegalla, Queensland',
    value: 10003329,
  },
  {
    label: 'Tallimba, New South Wales',
    value: 10002202,
  },
  {
    label: 'Tallong, New South Wales',
    value: 10001972,
  },
  {
    label: 'Tallwood, New South Wales',
    value: 10002489,
  },
  {
    label: 'Tallygaroopna, Victoria',
    value: 10007818,
  },
  {
    label: 'Talmalmo, New South Wales',
    value: 10002096,
  },
  {
    label: 'Talwood, Queensland',
    value: 10003791,
  },
  {
    label: 'Tamala Park, Western Australia',
    value: 10008470,
  },
  {
    label: 'Tamarama, New South Wales',
    value: 10000238,
  },
  {
    label: 'Tambar Springs, New South Wales',
    value: 10001227,
  },
  {
    label: 'Tambellup, Western Australia',
    value: 10009144,
  },
  {
    label: 'Tambo, Queensland',
    value: 10003755,
  },
  {
    label: 'Tambo Crossing, Victoria',
    value: 10008238,
  },
  {
    label: 'Tambo Upper, Victoria',
    value: 10008216,
  },
  {
    label: 'Tamborine, Queensland',
    value: 10003189,
  },
  {
    label: 'Tamleugh, Victoria',
    value: 10007864,
  },
  {
    label: 'Tamleugh West, Victoria',
    value: 10007812,
  },
  {
    label: 'Tammin, Western Australia',
    value: 10009424,
  },
  {
    label: 'Tamworth, New South Wales',
    value: 10001122,
  },
  {
    label: 'Tamworth South, New South Wales',
    value: 10001126,
  },
  {
    label: 'Tanah Merah, Queensland',
    value: 10003011,
  },
  {
    label: 'Tanami, Western Australia',
    value: 10010060,
  },
  {
    label: 'Tanawha, Queensland',
    value: 10003906,
  },
  {
    label: 'Tanbar, Queensland',
    value: 10003765,
  },
  {
    label: 'Tanby, Queensland',
    value: 10004557,
  },
  {
    label: 'Tandarra, Victoria',
    value: 10007734,
  },
  {
    label: 'Tandegin, Western Australia',
    value: 10009438,
  },
  {
    label: 'Tandora, Queensland',
    value: 10004214,
  },
  {
    label: 'Tangalooma, Queensland',
    value: 10002851,
  },
  {
    label: 'Tangambalanga, Victoria',
    value: 10007905,
  },
  {
    label: 'Tanilba Bay, New South Wales',
    value: 10000964,
  },
  {
    label: 'Tanjil South, Victoria',
    value: 10008134,
  },
  {
    label: 'Tankerton, Victoria',
    value: 10008263,
  },
  {
    label: 'Tannum Sands, Queensland',
    value: 10004424,
  },
  {
    label: 'Tannymorel, Queensland',
    value: 10003573,
  },
  {
    label: 'Tansey, Queensland',
    value: 10004035,
  },
  {
    label: 'Tantanoola, South Australia',
    value: 10005756,
  },
  {
    label: 'Tanunda, South Australia',
    value: 10005821,
  },
  {
    label: 'Taperoo, South Australia',
    value: 10005289,
  },
  {
    label: 'Tapitallee, New South Wales',
    value: 10001801,
  },
  {
    label: 'Taplan, South Australia',
    value: 10005801,
  },
  {
    label: 'Tapping, Western Australia',
    value: 10008554,
  },
  {
    label: 'Tara, Queensland',
    value: 10003693,
  },
  {
    label: 'Taradale, Victoria',
    value: 10007532,
  },
  {
    label: 'Tarago, New South Wales',
    value: 10001986,
  },
  {
    label: 'Taragoola, Queensland',
    value: 10004418,
  },
  {
    label: 'Taralga, New South Wales',
    value: 10001984,
  },
  {
    label: 'Tarana, New South Wales',
    value: 10002423,
  },
  {
    label: 'Taranganba, Queensland',
    value: 10004549,
  },
  {
    label: 'Taranna, Tasmania',
    value: 10006319,
  },
  {
    label: 'Tarawera, Queensland',
    value: 10003789,
  },
  {
    label: 'Tarbuck Bay, New South Wales',
    value: 10001318,
  },
  {
    label: 'Tarcoola, South Australia',
    value: 10006044,
  },
  {
    label: 'Tarcoola Beach, Western Australia',
    value: 10009762,
  },
  {
    label: 'Tarcowie, South Australia',
    value: 10005870,
  },
  {
    label: 'Tarcutta, New South Wales',
    value: 10002147,
  },
  {
    label: 'Tardun, Western Australia',
    value: 10009899,
  },
  {
    label: 'Taree, New South Wales',
    value: 10001346,
  },
  {
    label: 'Taren Point, New South Wales',
    value: 10000688,
  },
  {
    label: 'Targa, Tasmania',
    value: 10006442,
  },
  {
    label: 'Targinie, Queensland',
    value: 10004430,
  },
  {
    label: 'Tarin Rock, Western Australia',
    value: 10009290,
  },
  {
    label: 'Taringa, Queensland',
    value: 10002904,
  },
  {
    label: 'Tarlee, South Australia',
    value: 10005853,
  },
  {
    label: 'Tarnagulla, Victoria',
    value: 10007694,
  },
  {
    label: 'Tarneit, Victoria',
    value: 10006724,
  },
  {
    label: 'Tarong, Queensland',
    value: 10004116,
  },
  {
    label: 'Taroom, Queensland',
    value: 10003685,
  },
  {
    label: 'Taroomball, Queensland',
    value: 10004551,
  },
  {
    label: 'Taroona, Tasmania',
    value: 10006151,
  },
  {
    label: 'Tarpeena, South Australia',
    value: 10005748,
  },
  {
    label: 'Tarragindi, Queensland',
    value: 10002995,
  },
  {
    label: 'Tarraleah, Tasmania',
    value: 10006259,
  },
  {
    label: 'Tarramba, Queensland',
    value: 10004599,
  },
  {
    label: 'Tarranyurk, Victoria',
    value: 10007492,
  },
  {
    label: 'Tarraville, Victoria',
    value: 10008341,
  },
  {
    label: 'Tarrawanna, New South Wales',
    value: 10001675,
  },
  {
    label: 'Tarrington, Victoria',
    value: 10007250,
  },
  {
    label: 'Tarro, New South Wales',
    value: 10000991,
  },
  {
    label: 'Tarwin, Victoria',
    value: 10008311,
  },
  {
    label: 'Tarwin Lower, Victoria',
    value: 10008316,
  },
  {
    label: 'Tarwonga, Western Australia',
    value: 10009362,
  },
  {
    label: 'Tascott, New South Wales',
    value: 10000727,
  },
  {
    label: 'Tasmania, Tasmania',
    value: 10010177,
  },
  {
    label: 'Tatham, New South Wales',
    value: 10001523,
  },
  {
    label: 'Tathra, New South Wales',
    value: 10001857,
  },
  {
    label: 'Tatong, Victoria',
    value: 10007874,
  },
  {
    label: 'Tatura, Victoria',
    value: 10007777,
  },
  {
    label: 'Tatyoon, Victoria',
    value: 10007441,
  },
  {
    label: 'Taunton, Queensland',
    value: 10004381,
  },
  {
    label: 'Tawonga, Victoria',
    value: 10007928,
  },
  {
    label: 'Tawonga South, Victoria',
    value: 10007929,
  },
  {
    label: 'Tayene, Tasmania',
    value: 10006441,
  },
  {
    label: 'Taylors Arm, New South Wales',
    value: 10001416,
  },
  {
    label: 'Taylors Beach, Queensland',
    value: 10005000,
  },
  {
    label: 'Taylors Lakes, Victoria',
    value: 10006742,
  },
  {
    label: 'Te Kowai, Queensland',
    value: 10004676,
  },
  {
    label: 'Tea Gardens, New South Wales',
    value: 10001018,
  },
  {
    label: 'Tea Tree, Tasmania',
    value: 10006101,
  },
  {
    label: 'Tea Tree Gully, South Australia',
    value: 10005500,
  },
  {
    label: 'Tecoma, Victoria',
    value: 10006960,
  },
  {
    label: 'Teddington, Queensland',
    value: 10004248,
  },
  {
    label: 'Teebar, Queensland',
    value: 10004126,
  },
  {
    label: 'Teelah, Queensland',
    value: 10003268,
  },
  {
    label: 'Teesdale, Victoria',
    value: 10007291,
  },
  {
    label: 'Teesdale, Western Australia',
    value: 10008797,
  },
  {
    label: 'Telangatuk East, Victoria',
    value: 10007475,
  },
  {
    label: 'Telarah, New South Wales',
    value: 10000974,
  },
  {
    label: 'Telegraph Point, New South Wales',
    value: 10001381,
  },
  {
    label: 'Telfer, Western Australia',
    value: 10010056,
  },
  {
    label: 'Telford, Victoria',
    value: 10007994,
  },
  {
    label: 'Telina, Queensland',
    value: 10004396,
  },
  {
    label: 'Tellebang, Queensland',
    value: 10004205,
  },
  {
    label: 'Telopea, New South Wales',
    value: 10000439,
  },
  {
    label: 'Temora, New South Wales',
    value: 10002186,
  },
  {
    label: 'Tempe, New South Wales',
    value: 10000277,
  },
  {
    label: 'Templers, South Australia',
    value: 10005840,
  },
  {
    label: 'Templestowe, Victoria',
    value: 10006855,
  },
  {
    label: 'Templestowe Lower, Victoria',
    value: 10006856,
  },
  {
    label: 'Tempy, Victoria',
    value: 10007607,
  },
  {
    label: 'Tenambit, New South Wales',
    value: 10000999,
  },
  {
    label: 'Tenby Point, Victoria',
    value: 10008375,
  },
  {
    label: 'Tenindewa, Western Australia',
    value: 10009909,
  },
  {
    label: 'Tennant Creek, Northern Territory',
    value: 10002773,
  },
  {
    label: 'Tennant Creek, Northern Territory',
    value: 10002774,
  },
  {
    label: 'Tennant Creek, Northern Territory',
    value: 10002775,
  },
  {
    label: 'Tennyson, New South Wales',
    value: 10002323,
  },
  {
    label: 'Tennyson, Queensland',
    value: 10002952,
  },
  {
    label: 'Tennyson, South Australia',
    value: 10005296,
  },
  {
    label: 'Tennyson, Victoria',
    value: 10007738,
  },
  {
    label: 'Tennyson Point, New South Wales',
    value: 10000417,
  },
  {
    label: 'Tenterden, Western Australia',
    value: 10009152,
  },
  {
    label: 'Tenterfield, New South Wales',
    value: 10001211,
  },
  {
    label: 'Teralba, New South Wales',
    value: 10000864,
  },
  {
    label: 'Terang, Victoria',
    value: 10007181,
  },
  {
    label: 'Terara, New South Wales',
    value: 10001817,
  },
  {
    label: 'Teringie, South Australia',
    value: 10005455,
  },
  {
    label: 'Termeil, New South Wales',
    value: 10001779,
  },
  {
    label: 'Terowie, South Australia',
    value: 10005865,
  },
  {
    label: 'Terranora, New South Wales',
    value: 10001630,
  },
  {
    label: 'Terrey Hills, New South Wales',
    value: 10000357,
  },
  {
    label: 'Terrica, Queensland',
    value: 10003596,
  },
  {
    label: 'Terrigal, New South Wales',
    value: 10000787,
  },
  {
    label: 'Terry Hie Hie, New South Wales',
    value: 10001247,
  },
  {
    label: 'Teven, New South Wales',
    value: 10001558,
  },
  {
    label: 'Tewantin, Queensland',
    value: 10003937,
  },
  {
    label: 'Texas, Queensland',
    value: 10003592,
  },
  {
    label: 'Thabeban, Queensland',
    value: 10004305,
  },
  {
    label: 'Thagoona, Queensland',
    value: 10003252,
  },
  {
    label: 'Thallon, Queensland',
    value: 10003795,
  },
  {
    label: 'Thangool, Queensland',
    value: 10004608,
  },
  {
    label: 'Tharbogang, New South Wales',
    value: 10002219,
  },
  {
    label: 'Thargomindah, Queensland',
    value: 10003785,
  },
  {
    label: 'Tharwa, Australian Capital Territory',
    value: 10000097,
  },
  {
    label: 'The Basin, New South Wales',
    value: 10000412,
  },
  {
    label: 'The Basin, Victoria',
    value: 10006951,
  },
  {
    label: 'The Bluff, Queensland',
    value: 10003322,
  },
  {
    label: 'The Bluff, Queensland',
    value: 10003494,
  },
  {
    label: 'The Channon, New South Wales',
    value: 10001576,
  },
  {
    label: 'The Common, Queensland',
    value: 10004454,
  },
  {
    label: 'The Dimonds, Queensland',
    value: 10004216,
  },
  {
    label: 'The Entrance, New South Wales',
    value: 10000803,
  },
  {
    label: 'The Gap, Queensland',
    value: 10002895,
  },
  {
    label: 'The Gap, Queensland',
    value: 10004981,
  },
  {
    label: 'The Gardens, Northern Territory',
    value: 10002707,
  },
  {
    label: 'The Gums, Queensland',
    value: 10003654,
  },
  {
    label: 'The Gurdies, Victoria',
    value: 10008373,
  },
  {
    label: 'The Hill, New South Wales',
    value: 10000919,
  },
  {
    label: 'The Junction, New South Wales',
    value: 10000900,
  },
  {
    label: 'The Keppels, Queensland',
    value: 10004450,
  },
  {
    label: 'The Lakes, Western Australia',
    value: 10009836,
  },
  {
    label: 'The Leap, Queensland',
    value: 10004682,
  },
  {
    label: 'The Levels, South Australia',
    value: 10005511,
  },
  {
    label: 'The Limits, Queensland',
    value: 10004157,
  },
  {
    label: 'The Mine, Queensland',
    value: 10004594,
  },
  {
    label: 'The Monument, Queensland',
    value: 10004982,
  },
  {
    label: 'The Narrows, Northern Territory',
    value: 10002713,
  },
  {
    label: 'The Narrows, Queensland',
    value: 10004435,
  },
  {
    label: 'The Oaks, New South Wales',
    value: 10001931,
  },
  {
    label: 'The Patch, Victoria',
    value: 10008071,
  },
  {
    label: 'The Percy Group, Queensland',
    value: 10004574,
  },
  {
    label: 'The Pines, Queensland',
    value: 10003532,
  },
  {
    label: 'The Plains, Western Australia',
    value: 10008880,
  },
  {
    label: 'The Range, Queensland',
    value: 10004446,
  },
  {
    label: 'The Risk, New South Wales',
    value: 10001534,
  },
  {
    label: 'The Rock, New South Wales',
    value: 10002165,
  },
  {
    label: 'The Rocks, New South Wales',
    value: 10000197,
  },
  {
    label: 'The Rocks, New South Wales',
    value: 10002463,
  },
  {
    label: 'The Sisters, Victoria',
    value: 10007184,
  },
  {
    label: 'The Spectacles, Western Australia',
    value: 10008731,
  },
  {
    label: 'The Summit, Queensland',
    value: 10003578,
  },
  {
    label: 'The University Of Adelaide, South Australia',
    value: 10005240,
  },
  {
    label: 'The University Of Sydney, New South Wales',
    value: 10000200,
  },
  {
    label: 'The Vines, Western Australia',
    value: 10008575,
  },
  {
    label: 'Thebarton, South Australia',
    value: 10005307,
  },
  {
    label: 'Theodore, Australian Capital Territory',
    value: 10000111,
  },
  {
    label: 'Theodore, Queensland',
    value: 10004620,
  },
  {
    label: 'Thevenard, South Australia',
    value: 10006031,
  },
  {
    label: 'Thevenard Island, Western Australia',
    value: 10009969,
  },
  {
    label: 'Thinoomba, Queensland',
    value: 10004232,
  },
  {
    label: 'Thirlmere, New South Wales',
    value: 10001942,
  },
  {
    label: 'Thirlstane, Tasmania',
    value: 10006564,
  },
  {
    label: 'Thirroul, New South Wales',
    value: 10001663,
  },
  {
    label: 'Thomastown, Victoria',
    value: 10006808,
  },
  {
    label: 'Thompson Point, Queensland',
    value: 10004512,
  },
  {
    label: 'Thomson Brook, Western Australia',
    value: 10008890,
  },
  {
    label: 'Thoona, Victoria',
    value: 10007985,
  },
  {
    label: 'Thoopara, Queensland',
    value: 10004778,
  },
  {
    label: 'Thora, New South Wales',
    value: 10001450,
  },
  {
    label: 'Thornbury, Victoria',
    value: 10006801,
  },
  {
    label: 'Thorneside, Queensland',
    value: 10003039,
  },
  {
    label: 'Thorngate, South Australia',
    value: 10005473,
  },
  {
    label: 'Thornlands, Queensland',
    value: 10003045,
  },
  {
    label: 'Thornleigh, New South Wales',
    value: 10000446,
  },
  {
    label: 'Thornlie, Western Australia',
    value: 10008624,
  },
  {
    label: 'Thornlie, Western Australia',
    value: 10010155,
  },
  {
    label: 'Thornton, New South Wales',
    value: 10000989,
  },
  {
    label: 'Thornton, Victoria',
    value: 10007952,
  },
  {
    label: 'Thornville, Queensland',
    value: 10003459,
  },
  {
    label: 'Thorpdale, Victoria',
    value: 10008141,
  },
  {
    label: 'Thorpdale South, Victoria',
    value: 10008124,
  },
  {
    label: 'Thowgla Valley, Victoria',
    value: 10007944,
  },
  {
    label: 'Thredbo Village, New South Wales',
    value: 10002061,
  },
  {
    label: 'Three Bridges, Victoria',
    value: 10008075,
  },
  {
    label: 'Three Hummock Island, Tasmania',
    value: 10006654,
  },
  {
    label: 'Three Moon, Queensland',
    value: 10004211,
  },
  {
    label: 'Three Springs, Western Australia',
    value: 10009708,
  },
  {
    label: 'Throssell, Western Australia',
    value: 10009400,
  },
  {
    label: 'Thulimbah, Queensland',
    value: 10003577,
  },
  {
    label: 'Thumb Creek, New South Wales',
    value: 10001417,
  },
  {
    label: 'Thurgoona, New South Wales',
    value: 10002093,
  },
  {
    label: 'Thuringowa Central, Queensland',
    value: 10004926,
  },
  {
    label: 'Thursday Island, Queensland',
    value: 10005190,
  },
  {
    label: 'Ti Tree, Northern Territory',
    value: 10002789,
  },
  {
    label: 'Tiaro, Queensland',
    value: 10004240,
  },
  {
    label: 'Tiberias, Tasmania',
    value: 10006207,
  },
  {
    label: 'Tibooburra, New South Wales',
    value: 10002665,
  },
  {
    label: 'Tibradden, Western Australia',
    value: 10009809,
  },
  {
    label: 'Tichborne, New South Wales',
    value: 10002639,
  },
  {
    label: 'Tidal River, Victoria',
    value: 10008326,
  },
  {
    label: 'Tieri, Queensland',
    value: 10004576,
  },
  {
    label: 'Tighes Hill, New South Wales',
    value: 10000909,
  },
  {
    label: 'Tilba Tilba, New South Wales',
    value: 10001831,
  },
  {
    label: 'Tilpa, New South Wales',
    value: 10002584,
  },
  {
    label: 'Timboon, Victoria',
    value: 10007201,
  },
  {
    label: 'Tin Can Bay, Queensland',
    value: 10004019,
  },
  {
    label: 'Tinamba, Victoria',
    value: 10008178,
  },
  {
    label: 'Tinana, Queensland',
    value: 10004245,
  },
  {
    label: 'Tinana South, Queensland',
    value: 10004243,
  },
  {
    label: 'Tinaroo, Queensland',
    value: 10005167,
  },
  {
    label: 'Tinbeerwah, Queensland',
    value: 10003928,
  },
  {
    label: 'Tincurrin, Western Australia',
    value: 10009308,
  },
  {
    label: 'Tindal Raaf, Northern Territory',
    value: 10002771,
  },
  {
    label: 'Tinderbox, Tasmania',
    value: 10006153,
  },
  {
    label: 'Tingalpa, Queensland',
    value: 10003059,
  },
  {
    label: 'Tingalpa Bc, Queensland',
    value: 10003060,
  },
  {
    label: 'Tingalpa Dc, Queensland',
    value: 10003061,
  },
  {
    label: 'Tingha, New South Wales',
    value: 10001199,
  },
  {
    label: 'Tingira Heights, New South Wales',
    value: 10000889,
  },
  {
    label: 'Tingledale, Western Australia',
    value: 10009227,
  },
  {
    label: 'Tingoora, Queensland',
    value: 10004065,
  },
  {
    label: 'Tingun, Queensland',
    value: 10003732,
  },
  {
    label: 'Tinonee, New South Wales',
    value: 10001340,
  },
  {
    label: 'Tinpot, New South Wales',
    value: 10001837,
  },
  {
    label: 'Tintaldra, Victoria',
    value: 10007947,
  },
  {
    label: 'Tintenbar, New South Wales',
    value: 10001555,
  },
  {
    label: 'Tintinara, South Australia',
    value: 10005735,
  },
  {
    label: 'Tipton, Queensland',
    value: 10003646,
  },
  {
    label: 'Tirroan, Queensland',
    value: 10004368,
  },
  {
    label: 'Tivoli, Queensland',
    value: 10003226,
  },
  {
    label: 'Tiwi, Northern Territory',
    value: 10002684,
  },
  {
    label: 'Tocal, Queensland',
    value: 10004645,
  },
  {
    label: 'Tocumwal, New South Wales',
    value: 10002255,
  },
  {
    label: 'Togari, Tasmania',
    value: 10006664,
  },
  {
    label: 'Toiberry, Tasmania',
    value: 10006510,
  },
  {
    label: 'Toko, Queensland',
    value: 10004990,
  },
  {
    label: 'Tolga, Queensland',
    value: 10005208,
  },
  {
    label: 'Toll, Queensland',
    value: 10004951,
  },
  {
    label: 'Tolland, New South Wales',
    value: 10002139,
  },
  {
    label: 'Tolmans Hill, Tasmania',
    value: 10006077,
  },
  {
    label: 'Tom Price, Western Australia',
    value: 10010049,
  },
  {
    label: 'Tomago, New South Wales',
    value: 10000987,
  },
  {
    label: 'Tomahawk, Tasmania',
    value: 10006454,
  },
  {
    label: 'Tomakin, New South Wales',
    value: 10001764,
  },
  {
    label: 'Tomerong, New South Wales',
    value: 10001787,
  },
  {
    label: 'Tomingley, New South Wales',
    value: 10002635,
  },
  {
    label: 'Toms Creek, New South Wales',
    value: 10001410,
  },
  {
    label: 'Tonebridge, Western Australia',
    value: 10008896,
  },
  {
    label: 'Tongala, Victoria',
    value: 10007789,
  },
  {
    label: 'Tonganah, Tasmania',
    value: 10006451,
  },
  {
    label: 'Tonimbuk, Victoria',
    value: 10008105,
  },
  {
    label: 'Toobeah, Queensland',
    value: 10003797,
  },
  {
    label: 'Tooborac, Victoria',
    value: 10007648,
  },
  {
    label: 'Toodyay, Western Australia',
    value: 10009853,
  },
  {
    label: 'Toogong, New South Wales',
    value: 10002623,
  },
  {
    label: 'Toogoolawah, Queensland',
    value: 10003320,
  },
  {
    label: 'Toolakea, Queensland',
    value: 10004936,
  },
  {
    label: 'Toolamba, Victoria',
    value: 10007775,
  },
  {
    label: 'Toolangi, Victoria',
    value: 10008056,
  },
  {
    label: 'Toolara Forest, Queensland',
    value: 10003974,
  },
  {
    label: 'Toolern Vale, Victoria',
    value: 10007309,
  },
  {
    label: 'Tooleybuc, New South Wales',
    value: 10002288,
  },
  {
    label: 'Toolibin, Western Australia',
    value: 10009098,
  },
  {
    label: 'Toolijooa, New South Wales',
    value: 10001718,
  },
  {
    label: 'Toolleen, Victoria',
    value: 10007682,
  },
  {
    label: 'Toolondo, Victoria',
    value: 10007472,
  },
  {
    label: 'Toolong, Victoria',
    value: 10007234,
  },
  {
    label: 'Toolooa, Queensland',
    value: 10004416,
  },
  {
    label: 'Tooma, New South Wales',
    value: 10002103,
  },
  {
    label: 'Toombul, Queensland',
    value: 10002827,
  },
  {
    label: 'Toompup, Western Australia',
    value: 10009250,
  },
  {
    label: 'Toondahra, Queensland',
    value: 10004161,
  },
  {
    label: 'Toongabbie, New South Wales',
    value: 10000496,
  },
  {
    label: 'Toongabbie, Victoria',
    value: 10008171,
  },
  {
    label: 'Toongabbie East, New South Wales',
    value: 10000495,
  },
  {
    label: 'Toonumbar, New South Wales',
    value: 10001537,
  },
  {
    label: 'Toora, Victoria',
    value: 10008327,
  },
  {
    label: 'Tooradin, Victoria',
    value: 10008359,
  },
  {
    label: 'Toorak, Victoria',
    value: 10006924,
  },
  {
    label: 'Toorak Gardens, South Australia',
    value: 10005414,
  },
  {
    label: 'Tooraweenah, New South Wales',
    value: 10002558,
  },
  {
    label: 'Toorbul, Queensland',
    value: 10003826,
  },
  {
    label: 'Toormina, New South Wales',
    value: 10001440,
  },
  {
    label: 'Toorooka, New South Wales',
    value: 10001362,
  },
  {
    label: 'Tootgarook, Victoria',
    value: 10008290,
  },
  {
    label: 'Toowong, Queensland',
    value: 10002899,
  },
  {
    label: 'Toowoomba, Queensland',
    value: 10003417,
  },
  {
    label: 'Toowoomba City, Queensland',
    value: 10003407,
  },
  {
    label: 'Toowoomba East, Queensland',
    value: 10003391,
  },
  {
    label: 'Toowoomba Mc, Queensland',
    value: 10003474,
  },
  {
    label: 'Toowoomba South, Queensland',
    value: 10003412,
  },
  {
    label: 'Toowoomba West, Queensland',
    value: 10003409,
  },
  {
    label: 'Toowoon Bay, New South Wales',
    value: 10000796,
  },
  {
    label: 'Top Camp, Queensland',
    value: 10003393,
  },
  {
    label: 'Torbanlea, Queensland',
    value: 10004293,
  },
  {
    label: 'Torbay, Western Australia',
    value: 10009185,
  },
  {
    label: 'Torndirrup, Western Australia',
    value: 10009218,
  },
  {
    label: 'Toronto, New South Wales',
    value: 10000856,
  },
  {
    label: 'Torquay, Queensland',
    value: 10004266,
  },
  {
    label: 'Torquay, Victoria',
    value: 10007121,
  },
  {
    label: 'Torrens, Australian Capital Territory',
    value: 10000041,
  },
  {
    label: 'Torrens Creek, Queensland',
    value: 10004907,
  },
  {
    label: 'Torrens Park, South Australia',
    value: 10005392,
  },
  {
    label: 'Torrensville, South Australia',
    value: 10005308,
  },
  {
    label: 'Torrensville Plaza, South Australia',
    value: 10005309,
  },
  {
    label: 'Torrington, New South Wales',
    value: 10001209,
  },
  {
    label: 'Torrumbarry, Victoria',
    value: 10007721,
  },
  {
    label: 'Tottenham, New South Wales',
    value: 10002647,
  },
  {
    label: 'Tottenham, Victoria',
    value: 10006690,
  },
  {
    label: 'Toukley, New South Wales',
    value: 10000810,
  },
  {
    label: 'Towamba, New South Wales',
    value: 10001861,
  },
  {
    label: 'Tower Hill, Victoria',
    value: 10007229,
  },
  {
    label: 'Towers Hill, Queensland',
    value: 10004944,
  },
  {
    label: 'Town Common, Queensland',
    value: 10004864,
  },
  {
    label: 'Townsendale, Western Australia',
    value: 10009097,
  },
  {
    label: 'Townson, Queensland',
    value: 10003335,
  },
  {
    label: 'Townsville, Queensland',
    value: 10004858,
  },
  {
    label: 'Townsville City, Queensland',
    value: 10004868,
  },
  {
    label: 'Townsville Milpo, Queensland',
    value: 10004886,
  },
  {
    label: 'Townview, Queensland',
    value: 10004983,
  },
  {
    label: 'Towong, Victoria',
    value: 10007942,
  },
  {
    label: 'Towradgi, New South Wales',
    value: 10001674,
  },
  {
    label: 'Towrang, New South Wales',
    value: 10001976,
  },
  {
    label: 'Trafalgar, Victoria',
    value: 10008123,
  },
  {
    label: 'Trafalgar, Western Australia',
    value: 10009503,
  },
  {
    label: 'Trangie, New South Wales',
    value: 10002531,
  },
  {
    label: 'Tranmere, South Australia',
    value: 10005457,
  },
  {
    label: 'Tranmere, Tasmania',
    value: 10006111,
  },
  {
    label: 'Tranmere North, South Australia',
    value: 10005460,
  },
  {
    label: 'Traralgon, Victoria',
    value: 10008147,
  },
  {
    label: 'Travancore, Victoria',
    value: 10006733,
  },
  {
    label: 'Travellers Rest, Tasmania',
    value: 10006397,
  },
  {
    label: 'Trawalla, Victoria',
    value: 10007431,
  },
  {
    label: 'Trawool, Victoria',
    value: 10007845,
  },
  {
    label: 'Trayning, Western Australia',
    value: 10009638,
  },
  {
    label: 'Treeton, Western Australia',
    value: 10009013,
  },
  {
    label: 'Tregeagle, New South Wales',
    value: 10001582,
  },
  {
    label: 'Tregear, New South Wales',
    value: 10002377,
  },
  {
    label: 'Trent, Western Australia',
    value: 10009239,
  },
  {
    label: 'Trentham, Victoria',
    value: 10007550,
  },
  {
    label: 'Tresco, Victoria',
    value: 10007752,
  },
  {
    label: 'Trevallyn, New South Wales',
    value: 10001275,
  },
  {
    label: 'Trevallyn, Tasmania',
    value: 10006390,
  },
  {
    label: 'Trewilga, New South Wales',
    value: 10002634,
  },
  {
    label: 'Triabunna, Tasmania',
    value: 10006328,
  },
  {
    label: 'Trida, New South Wales',
    value: 10002661,
  },
  {
    label: 'Trigg, Western Australia',
    value: 10008466,
  },
  {
    label: 'Trigwell, Western Australia',
    value: 10009373,
  },
  {
    label: 'Trihi, South Australia',
    value: 10005751,
  },
  {
    label: 'Trinity Beach, Queensland',
    value: 10005200,
  },
  {
    label: 'Trinity Gardens, South Australia',
    value: 10005428,
  },
  {
    label: 'Trinity Park, Queensland',
    value: 10005204,
  },
  {
    label: 'Trott Park, South Australia',
    value: 10005619,
  },
  {
    label: 'Trotter Creek, Queensland',
    value: 10004584,
  },
  {
    label: 'Trowutta, Tasmania',
    value: 10006663,
  },
  {
    label: 'Truganina, Victoria',
    value: 10006722,
  },
  {
    label: 'Trunding, Queensland',
    value: 10005189,
  },
  {
    label: 'Trundle, New South Wales',
    value: 10002652,
  },
  {
    label: 'Trungley Hall, New South Wales',
    value: 10002190,
  },
  {
    label: 'Trunkey Creek, New South Wales',
    value: 10002468,
  },
  {
    label: 'Truro, South Australia',
    value: 10005833,
  },
  {
    label: 'Tuan Forest, Queensland',
    value: 10004226,
  },
  {
    label: 'Tuart Hill, Western Australia',
    value: 10008537,
  },
  {
    label: 'Tuart Hill, Western Australia',
    value: 10010120,
  },
  {
    label: 'Tubbut, Victoria',
    value: 10008226,
  },
  {
    label: 'Tucabia, New South Wales',
    value: 10001486,
  },
  {
    label: 'Tuckurimba, New South Wales',
    value: 10001590,
  },
  {
    label: 'Tuen, Queensland',
    value: 10003780,
  },
  {
    label: 'Tuena, New South Wales',
    value: 10002004,
  },
  {
    label: 'Tuerong, Victoria',
    value: 10008256,
  },
  {
    label: 'Tuggerah, New South Wales',
    value: 10000783,
  },
  {
    label: 'Tuggeranong, Australian Capital Territory',
    value: 10000099,
  },
  {
    label: 'Tuggeranong Dc, Australian Capital Territory',
    value: 10000100,
  },
  {
    label: 'Tuggerawong, New South Wales',
    value: 10000778,
  },
  {
    label: 'Tugun, Queensland',
    value: 10003177,
  },
  {
    label: 'Tulendeena, Tasmania',
    value: 10006444,
  },
  {
    label: 'Tullah, Tasmania',
    value: 10006627,
  },
  {
    label: 'Tullamarine, Victoria',
    value: 10006753,
  },
  {
    label: 'Tullamore, New South Wales',
    value: 10002649,
  },
  {
    label: 'Tullibigeal, New South Wales',
    value: 10002199,
  },
  {
    label: 'Tully, Queensland',
    value: 10005020,
  },
  {
    label: 'Tullymorgan, New South Wales',
    value: 10001492,
  },
  {
    label: 'Tumbarumba, New South Wales',
    value: 10002164,
  },
  {
    label: 'Tumbi Umbi, New South Wales',
    value: 10000800,
  },
  {
    label: 'Tumblong, New South Wales',
    value: 10002275,
  },
  {
    label: 'Tumbulgum, New South Wales',
    value: 10001642,
  },
  {
    label: 'Tumby Bay, South Australia',
    value: 10005991,
  },
  {
    label: 'Tummaville, Queensland',
    value: 10003433,
  },
  {
    label: 'Tumoulin, Queensland',
    value: 10005224,
  },
  {
    label: 'Tumut, New South Wales',
    value: 10002262,
  },
  {
    label: 'Tunbridge, Tasmania',
    value: 10006217,
  },
  {
    label: 'Tuncester, New South Wales',
    value: 10001566,
  },
  {
    label: 'Tuncurry, New South Wales',
    value: 10001316,
  },
  {
    label: 'Tungamah, Victoria',
    value: 10007990,
  },
  {
    label: 'Tungamull, Queensland',
    value: 10004530,
  },
  {
    label: 'Tungkillo, South Australia',
    value: 10005685,
  },
  {
    label: 'Tunnack, Tasmania',
    value: 10006215,
  },
  {
    label: 'Tunnel, Tasmania',
    value: 10006414,
  },
  {
    label: 'Tunstall Square Po, Victoria',
    value: 10006860,
  },
  {
    label: 'Tura Beach, New South Wales',
    value: 10001846,
  },
  {
    label: 'Turallin, Queensland',
    value: 10003524,
  },
  {
    label: 'Turill, New South Wales',
    value: 10002615,
  },
  {
    label: 'Turkey Hill, Western Australia',
    value: 10009473,
  },
  {
    label: 'Turlinjah, New South Wales',
    value: 10001765,
  },
  {
    label: 'Turner, Australian Capital Territory',
    value: 10000064,
  },
  {
    label: 'Turners Beach, Tasmania',
    value: 10006593,
  },
  {
    label: 'Turners Marsh, Tasmania',
    value: 10006472,
  },
  {
    label: 'Turondale, New South Wales',
    value: 10002484,
  },
  {
    label: 'Tuross Head, New South Wales',
    value: 10001767,
  },
  {
    label: 'Turramurra, New South Wales',
    value: 10000338,
  },
  {
    label: 'Turrawan, New South Wales',
    value: 10001238,
  },
  {
    label: 'Turrawulla, Queensland',
    value: 10004736,
  },
  {
    label: 'Turrella, New South Wales',
    value: 10000624,
  },
  {
    label: 'Turvey Park, New South Wales',
    value: 10002140,
  },
  {
    label: 'Tusmore, South Australia',
    value: 10005412,
  },
  {
    label: 'Tutunup, Western Australia',
    value: 10008975,
  },
  {
    label: 'Tweed Heads, New South Wales',
    value: 10001625,
  },
  {
    label: 'Tweed Heads South, New South Wales',
    value: 10001628,
  },
  {
    label: 'Tweed Heads West, New South Wales',
    value: 10001626,
  },
  {
    label: 'Twelve Mile, New South Wales',
    value: 10002616,
  },
  {
    label: 'Twelve Mile Creek, New South Wales',
    value: 10001019,
  },
  {
    label: 'Twin Waters, Queensland',
    value: 10003932,
  },
  {
    label: 'Two Mile Flat, New South Wales',
    value: 10002619,
  },
  {
    label: 'Two Rocks, Western Australia',
    value: 10008482,
  },
  {
    label: 'Two Wells, South Australia',
    value: 10005911,
  },
  {
    label: 'Tyabb, Victoria',
    value: 10008255,
  },
  {
    label: 'Tyagarah, New South Wales',
    value: 10001597,
  },
  {
    label: 'Tyalgum, New South Wales',
    value: 10001610,
  },
  {
    label: 'Tyenna, Tasmania',
    value: 10006258,
  },
  {
    label: 'Tyers, Victoria',
    value: 10008152,
  },
  {
    label: 'Tylden, Victoria',
    value: 10007528,
  },
  {
    label: 'Tynong, Victoria',
    value: 10008101,
  },
  {
    label: 'Tyrendarra, Victoria',
    value: 10007235,
  },
  {
    label: 'Tyringham, New South Wales',
    value: 10001442,
  },
  {
    label: 'Tysons Reef, Victoria',
    value: 10007677,
  },
  {
    label: 'Uarbry, New South Wales',
    value: 10001056,
  },
  {
    label: 'Ubobo, Queensland',
    value: 10004414,
  },
  {
    label: 'Ucarty, Western Australia',
    value: 10009588,
  },
  {
    label: 'Ucarty West, Western Australia',
    value: 10009583,
  },
  {
    label: 'Uduc, Western Australia',
    value: 10008815,
  },
  {
    label: 'Uki, New South Wales',
    value: 10001615,
  },
  {
    label: 'Ulamambri, New South Wales',
    value: 10001171,
  },
  {
    label: 'Ulan, New South Wales',
    value: 10002609,
  },
  {
    label: 'Ularring, Western Australia',
    value: 10009523,
  },
  {
    label: 'Uleybury, South Australia',
    value: 10005556,
  },
  {
    label: 'Ulladulla, New South Wales',
    value: 10001777,
  },
  {
    label: 'Ullina, Victoria',
    value: 10007425,
  },
  {
    label: 'Ulmarra, New South Wales',
    value: 10001485,
  },
  {
    label: 'Ulogie, Queensland',
    value: 10004472,
  },
  {
    label: 'Ulong, New South Wales',
    value: 10001435,
  },
  {
    label: 'Ultima, Victoria',
    value: 10007666,
  },
  {
    label: 'Ultimo, New South Wales',
    value: 10000202,
  },
  {
    label: 'Ulverstone, Tasmania',
    value: 10006596,
  },
  {
    label: 'Umbakumba, Northern Territory',
    value: 10002719,
  },
  {
    label: 'Umbiram, Queensland',
    value: 10003464,
  },
  {
    label: 'Umina Beach, New South Wales',
    value: 10000754,
  },
  {
    label: 'Unanderra, New South Wales',
    value: 10001685,
  },
  {
    label: 'Undera, Victoria',
    value: 10007795,
  },
  {
    label: 'Underbool, Victoria',
    value: 10007629,
  },
  {
    label: 'Undercliffe, New South Wales',
    value: 10000628,
  },
  {
    label: 'Underdale, South Australia',
    value: 10005313,
  },
  {
    label: 'Underwood, Queensland',
    value: 10002989,
  },
  {
    label: 'Underwood, Tasmania',
    value: 10006474,
  },
  {
    label: 'Ungarie, New South Wales',
    value: 10002196,
  },
  {
    label: 'Ungarra, South Australia',
    value: 10006001,
  },
  {
    label: 'University Of Canberra, Australian Capital Territory',
    value: 10000088,
  },
  {
    label: 'University Of Melbourne, Victoria',
    value: 10006684,
  },
  {
    label: 'University Of New England, New South Wales',
    value: 10001158,
  },
  {
    label: 'University Of Queensland, Queensland',
    value: 10002917,
  },
  {
    label: 'University Of Tasmania, Tasmania',
    value: 10006073,
  },
  {
    label: 'University Of Wollongong, New South Wales',
    value: 10001682,
  },
  {
    label: 'Unley, South Australia',
    value: 10005386,
  },
  {
    label: 'Unsw Sydney, New South Wales',
    value: 10000145,
  },
  {
    label: 'Unsw Sydney, New South Wales',
    value: 10000295,
  },
  {
    label: 'Upper Barron, Queensland',
    value: 10005210,
  },
  {
    label: 'Upper Bowman, New South Wales',
    value: 10001278,
  },
  {
    label: 'Upper Brookfield, Queensland',
    value: 10002906,
  },
  {
    label: 'Upper Capel, Western Australia',
    value: 10008883,
  },
  {
    label: 'Upper Castra, Tasmania',
    value: 10006595,
  },
  {
    label: 'Upper Coomera, Queensland',
    value: 10003107,
  },
  {
    label: 'Upper Daradgee, Queensland',
    value: 10005077,
  },
  {
    label: 'Upper Dawson, Queensland',
    value: 10003712,
  },
  {
    label: 'Upper Esk, Tasmania',
    value: 10006353,
  },
  {
    label: 'Upper Ferntree Gully, Victoria',
    value: 10006953,
  },
  {
    label: 'Upper Flagstone, Queensland',
    value: 10003368,
  },
  {
    label: 'Upper Glastonbury, Queensland',
    value: 10003986,
  },
  {
    label: 'Upper Haughton, Queensland',
    value: 10004850,
  },
  {
    label: 'Upper Hermitage, South Australia',
    value: 10005582,
  },
  {
    label: 'Upper Kandanga, Queensland',
    value: 10003970,
  },
  {
    label: 'Upper Kedron, Queensland',
    value: 10002885,
  },
  {
    label: 'Upper Lansdowne, New South Wales',
    value: 10001341,
  },
  {
    label: 'Upper Lockyer, Queensland',
    value: 10003439,
  },
  {
    label: 'Upper Mount Gravatt, Queensland',
    value: 10002997,
  },
  {
    label: 'Upper Murray, Western Australia',
    value: 10009351,
  },
  {
    label: 'Upper Myall, New South Wales',
    value: 10001296,
  },
  {
    label: 'Upper Orara, New South Wales',
    value: 10001428,
  },
  {
    label: 'Upper Plenty, Victoria',
    value: 10008032,
  },
  {
    label: 'Upper Rouchel, New South Wales',
    value: 10001099,
  },
  {
    label: 'Upper Stone, Queensland',
    value: 10005004,
  },
  {
    label: 'Upper Sturt, South Australia',
    value: 10005613,
  },
  {
    label: 'Upper Swan, Western Australia',
    value: 10008571,
  },
  {
    label: 'Upper Tenthill, Queensland',
    value: 10003353,
  },
  {
    label: 'Upper Warren, Western Australia',
    value: 10008936,
  },
  {
    label: 'Upper Woodstock, Tasmania',
    value: 10006269,
  },
  {
    label: 'Upper Yarraman, Queensland',
    value: 10004104,
  },
  {
    label: 'Upwey, Victoria',
    value: 10006957,
  },
  {
    label: 'Uraidla, South Australia',
    value: 10005593,
  },
  {
    label: 'Uralba, New South Wales',
    value: 10001552,
  },
  {
    label: 'Uralla, New South Wales',
    value: 10001177,
  },
  {
    label: 'Urana, New South Wales',
    value: 10002113,
  },
  {
    label: 'Urangan, Queensland',
    value: 10004264,
  },
  {
    label: 'Urangeline East, New South Wales',
    value: 10002169,
  },
  {
    label: 'Uranquinty, New South Wales',
    value: 10002156,
  },
  {
    label: 'Urbenville, New South Wales',
    value: 10001540,
  },
  {
    label: 'Uriarra, Australian Capital Territory',
    value: 10000057,
  },
  {
    label: 'Uriarra, New South Wales',
    value: 10002031,
  },
  {
    label: 'Urisino, New South Wales',
    value: 10002579,
  },
  {
    label: 'Urraween, Queensland',
    value: 10004258,
  },
  {
    label: 'Urrbrae, South Australia',
    value: 10005406,
  },
  {
    label: 'Urunga, New South Wales',
    value: 10001457,
  },
  {
    label: 'Usher, Western Australia',
    value: 10008856,
  },
  {
    label: 'Utakarra, Western Australia',
    value: 10009744,
  },
  {
    label: 'Utchee Creek, Queensland',
    value: 10005157,
  },
  {
    label: 'Uxbridge, Tasmania',
    value: 10006254,
  },
  {
    label: 'Vacy, New South Wales',
    value: 10001274,
  },
  {
    label: 'Vale Park, South Australia',
    value: 10005469,
  },
  {
    label: 'Vale View, Queensland',
    value: 10003541,
  },
  {
    label: 'Valencia Creek, Victoria',
    value: 10008180,
  },
  {
    label: 'Valentine, New South Wales',
    value: 10000833,
  },
  {
    label: 'Valentine, Western Australia',
    value: 10009804,
  },
  {
    label: 'Valkyrie, Queensland',
    value: 10004738,
  },
  {
    label: 'Valla Beach, New South Wales',
    value: 10001421,
  },
  {
    label: 'Valley Heights, New South Wales',
    value: 10002400,
  },
  {
    label: 'Valley View, South Australia',
    value: 10005505,
  },
  {
    label: 'Vancouver Peninsula, Western Australia',
    value: 10009220,
  },
  {
    label: 'Varley, Western Australia',
    value: 10009298,
  },
  {
    label: 'Varroville, New South Wales',
    value: 10001913,
  },
  {
    label: 'Varsity Lakes, Queensland',
    value: 10003184,
  },
  {
    label: 'Vasa Views, Queensland',
    value: 10005081,
  },
  {
    label: 'Vasey, Victoria',
    value: 10007479,
  },
  {
    label: 'Vasse, Western Australia',
    value: 10008978,
  },
  {
    label: 'Vaucluse, New South Wales',
    value: 10000245,
  },
  {
    label: 'Veitch, South Australia',
    value: 10005792,
  },
  {
    label: 'Ventnor, Queensland',
    value: 10004201,
  },
  {
    label: 'Venus Bay, South Australia',
    value: 10005995,
  },
  {
    label: 'Venus Bay, Victoria',
    value: 10008310,
  },
  {
    label: 'Veradilla, Queensland',
    value: 10003383,
  },
  {
    label: 'Verdun, South Australia',
    value: 10005698,
  },
  {
    label: 'Vergemont, Queensland',
    value: 10004638,
  },
  {
    label: 'Verges Creek, New South Wales',
    value: 10001356,
  },
  {
    label: 'Vermont, Victoria',
    value: 10006900,
  },
  {
    label: 'Vermont South, Victoria',
    value: 10006901,
  },
  {
    label: 'Verona Sands, Tasmania',
    value: 10006193,
  },
  {
    label: 'Vervale, Victoria',
    value: 10008103,
  },
  {
    label: 'Victor Harbor, South Australia',
    value: 10005668,
  },
  {
    label: 'Victoria, Victoria',
    value: 10010178,
  },
  {
    label: 'Victoria Hill, Queensland',
    value: 10003550,
  },
  {
    label: 'Victoria Park, Western Australia',
    value: 10008604,
  },
  {
    label: 'Victoria Park, Western Australia',
    value: 10010147,
  },
  {
    label: 'Victoria Plains, Queensland',
    value: 10004750,
  },
  {
    label: 'Victoria Plantation, Queensland',
    value: 10005009,
  },
  {
    label: 'Victoria Point, Queensland',
    value: 10003046,
  },
  {
    label: 'Victoria River Downs, Northern Territory',
    value: 10002765,
  },
  {
    label: 'Victoria Rock, Western Australia',
    value: 10009477,
  },
  {
    label: 'Victoria Valley, Tasmania',
    value: 10006267,
  },
  {
    label: 'Victoria Valley, Victoria',
    value: 10007246,
  },
  {
    label: 'Victory Heights, Western Australia',
    value: 10009513,
  },
  {
    label: 'Viewbank, Victoria',
    value: 10006823,
  },
  {
    label: 'Villawood, New South Wales',
    value: 10000539,
  },
  {
    label: 'Vincent, Queensland',
    value: 10004890,
  },
  {
    label: 'Vincentia, New South Wales',
    value: 10001822,
  },
  {
    label: 'Vinegar Hill, Queensland',
    value: 10003364,
  },
  {
    label: 'Vineyard, New South Wales',
    value: 10002362,
  },
  {
    label: 'Vinifera, Victoria',
    value: 10007758,
  },
  {
    label: 'Violet Town, Victoria',
    value: 10007861,
  },
  {
    label: 'Virginia, Northern Territory',
    value: 10002750,
  },
  {
    label: 'Virginia, Queensland',
    value: 10002830,
  },
  {
    label: 'Virginia, South Australia',
    value: 10005569,
  },
  {
    label: 'Vista, South Australia',
    value: 10005501,
  },
  {
    label: 'Vittoria, New South Wales',
    value: 10002493,
  },
  {
    label: 'Vittoria, Western Australia',
    value: 10008850,
  },
  {
    label: 'Viveash, Western Australia',
    value: 10008519,
  },
  {
    label: 'Voyager Point, New South Wales',
    value: 10000575,
  },
  {
    label: 'W Tree, Victoria',
    value: 10008215,
  },
  {
    label: 'Waaia, Victoria',
    value: 10007823,
  },
  {
    label: 'Wacol, Queensland',
    value: 10002934,
  },
  {
    label: 'Wadbilliga, New South Wales',
    value: 10001833,
  },
  {
    label: 'Waddamana, Tasmania',
    value: 10006146,
  },
  {
    label: 'Wadderin, Western Australia',
    value: 10009319,
  },
  {
    label: 'Waddington, Western Australia',
    value: 10009683,
  },
  {
    label: 'Waddy Forest, Western Australia',
    value: 10009701,
  },
  {
    label: 'Wadeye, Northern Territory',
    value: 10002717,
  },
  {
    label: 'Waeel, Western Australia',
    value: 10009421,
  },
  {
    label: 'Wagaman, Northern Territory',
    value: 10002686,
  },
  {
    label: 'Wagerup, Western Australia',
    value: 10008808,
  },
  {
    label: 'Wagga Wagga, New South Wales',
    value: 10002134,
  },
  {
    label: 'Wagga Wagga Raaf, New South Wales',
    value: 10002146,
  },
  {
    label: 'Wagga Wagga South, New South Wales',
    value: 10002141,
  },
  {
    label: 'Waggrakine, Western Australia',
    value: 10009748,
  },
  {
    label: 'Wagin, Western Australia',
    value: 10009109,
  },
  {
    label: 'Wahgunyah, Victoria',
    value: 10007897,
  },
  {
    label: 'Wahoon, Queensland',
    value: 10004151,
  },
  {
    label: 'Wahring, Victoria',
    value: 10007765,
  },
  {
    label: 'Wahroonga, New South Wales',
    value: 10000342,
  },
  {
    label: 'Waikerie, South Australia',
    value: 10005797,
  },
  {
    label: 'Waikiki, Western Australia',
    value: 10008746,
  },
  {
    label: 'Wainui, Queensland',
    value: 10003642,
  },
  {
    label: 'Wairewa, Victoria',
    value: 10008221,
  },
  {
    label: 'Wairuna, Queensland',
    value: 10005175,
  },
  {
    label: 'Waitara, New South Wales',
    value: 10000345,
  },
  {
    label: 'Waitchie, Victoria',
    value: 10007667,
  },
  {
    label: 'Wakefield, New South Wales',
    value: 10000829,
  },
  {
    label: 'Wakeley, New South Wales',
    value: 10000588,
  },
  {
    label: 'Wakerley, Queensland',
    value: 10003031,
  },
  {
    label: 'Wakool, New South Wales',
    value: 10002242,
  },
  {
    label: 'Walbundrie, New South Wales',
    value: 10002107,
  },
  {
    label: 'Walcha, New South Wales',
    value: 10001163,
  },
  {
    label: 'Walebing, Western Australia',
    value: 10009687,
  },
  {
    label: 'Walgett, New South Wales',
    value: 10002568,
  },
  {
    label: 'Walgoolan, Western Australia',
    value: 10009451,
  },
  {
    label: 'Walhalla, Victoria',
    value: 10008128,
  },
  {
    label: 'Walkamin, Queensland',
    value: 10005176,
  },
  {
    label: 'Walkaway, Western Australia',
    value: 10009741,
  },
  {
    label: 'Walkers Point, Queensland',
    value: 10004222,
  },
  {
    label: 'Walkerston, Queensland',
    value: 10004752,
  },
  {
    label: 'Walkervale, Queensland',
    value: 10004317,
  },
  {
    label: 'Walkerville, South Australia',
    value: 10005471,
  },
  {
    label: 'Walkerville, Victoria',
    value: 10008313,
  },
  {
    label: 'Walkerville South, Victoria',
    value: 10008314,
  },
  {
    label: 'Walkley Heights, South Australia',
    value: 10005518,
  },
  {
    label: 'Walla Walla, New South Wales',
    value: 10002173,
  },
  {
    label: 'Wallabadah, New South Wales',
    value: 10001140,
  },
  {
    label: 'Wallace, Victoria',
    value: 10007367,
  },
  {
    label: 'Wallacia, New South Wales',
    value: 10002299,
  },
  {
    label: 'Wallaga Lake, New South Wales',
    value: 10001842,
  },
  {
    label: 'Wallaga Lake Heights, New South Wales',
    value: 10001838,
  },
  {
    label: 'Wallan, Victoria',
    value: 10008030,
  },
  {
    label: 'Wallangarra, Queensland',
    value: 10003584,
  },
  {
    label: 'Wallangra, New South Wales',
    value: 10001187,
  },
  {
    label: 'Wallarah, New South Wales',
    value: 10000770,
  },
  {
    label: 'Wallareenya, Western Australia',
    value: 10010004,
  },
  {
    label: 'Wallarobba, New South Wales',
    value: 10001272,
  },
  {
    label: 'Wallaroo, Queensland',
    value: 10004520,
  },
  {
    label: 'Wallaroo, South Australia',
    value: 10005940,
  },
  {
    label: 'Wallaroo, Western Australia',
    value: 10009479,
  },
  {
    label: 'Wallaroo Mines, South Australia',
    value: 10005932,
  },
  {
    label: 'Wallaville, Queensland',
    value: 10004367,
  },
  {
    label: 'Wallendbeen, New South Wales',
    value: 10002022,
  },
  {
    label: 'Wallerawang, New South Wales',
    value: 10002595,
  },
  {
    label: 'Walliebum, Queensland',
    value: 10004260,
  },
  {
    label: 'Walligan, Queensland',
    value: 10004252,
  },
  {
    label: 'Wallington, Victoria',
    value: 10007098,
  },
  {
    label: 'Walliston, Western Australia',
    value: 10008593,
  },
  {
    label: 'Walliston Dc, Western Australia',
    value: 10010111,
  },
  {
    label: 'Walloon, Queensland',
    value: 10003264,
  },
  {
    label: 'Wallsend, New South Wales',
    value: 10000879,
  },
  {
    label: 'Wallsend South, New South Wales',
    value: 10000882,
  },
  {
    label: 'Wallumbilla, Queensland',
    value: 10003707,
  },
  {
    label: 'Wallumbilla North, Queensland',
    value: 10003708,
  },
  {
    label: 'Wallumbilla South, Queensland',
    value: 10003710,
  },
  {
    label: 'Wallup, Victoria',
    value: 10007468,
  },
  {
    label: 'Walmer, New South Wales',
    value: 10002520,
  },
  {
    label: 'Walmsley, Western Australia',
    value: 10009186,
  },
  {
    label: 'Walmul, Queensland',
    value: 10004592,
  },
  {
    label: 'Walpa, Victoria',
    value: 10008206,
  },
  {
    label: 'Walpeup, Victoria',
    value: 10007628,
  },
  {
    label: 'Walpole, Western Australia',
    value: 10009392,
  },
  {
    label: 'Walsall, Western Australia',
    value: 10008971,
  },
  {
    label: 'Walter Lever Estate, Queensland',
    value: 10005030,
  },
  {
    label: 'Walwa, Victoria',
    value: 10007950,
  },
  {
    label: 'Walyormouring, Western Australia',
    value: 10009585,
  },
  {
    label: 'Walyurin, Western Australia',
    value: 10009310,
  },
  {
    label: 'Wamberal, New South Wales',
    value: 10000790,
  },
  {
    label: 'Wamboin, New South Wales',
    value: 10002037,
  },
  {
    label: 'Wambool, New South Wales',
    value: 10002454,
  },
  {
    label: 'Wamenusking, Western Australia',
    value: 10009344,
  },
  {
    label: 'Wamoon, New South Wales',
    value: 10002230,
  },
  {
    label: 'Wamuran, Queensland',
    value: 10003834,
  },
  {
    label: 'Wamuran Basin, Queensland',
    value: 10003835,
  },
  {
    label: 'Wanaaring, New South Wales',
    value: 10002585,
  },
  {
    label: 'Wanalta, Victoria',
    value: 10007773,
  },
  {
    label: 'Wanbi, South Australia',
    value: 10005788,
  },
  {
    label: 'Wandal, Queensland',
    value: 10004442,
  },
  {
    label: 'Wandana, Western Australia',
    value: 10009780,
  },
  {
    label: 'Wandana Heights, Victoria',
    value: 10007071,
  },
  {
    label: 'Wandandian, New South Wales',
    value: 10001791,
  },
  {
    label: 'Wandella, New South Wales',
    value: 10001853,
  },
  {
    label: 'Wandering, Western Australia',
    value: 10009077,
  },
  {
    label: 'Wandi, Western Australia',
    value: 10008729,
  },
  {
    label: 'Wandiligong, Victoria',
    value: 10008014,
  },
  {
    label: 'Wandillup, Western Australia',
    value: 10008923,
  },
  {
    label: 'Wandin North, Victoria',
    value: 10006920,
  },
  {
    label: 'Wandina, Western Australia',
    value: 10009750,
  },
  {
    label: 'Wando Vale, Victoria',
    value: 10007274,
  },
  {
    label: 'Wandoan, Queensland',
    value: 10003683,
  },
  {
    label: 'Wandong, Victoria',
    value: 10008039,
  },
  {
    label: 'Wandsworth, New South Wales',
    value: 10001193,
  },
  {
    label: 'Wanerie, Western Australia',
    value: 10009654,
  },
  {
    label: 'Wang Wauk, New South Wales',
    value: 10001297,
  },
  {
    label: 'Wangan, Queensland',
    value: 10005135,
  },
  {
    label: 'Wanganella, New South Wales',
    value: 10002244,
  },
  {
    label: 'Wangara, Western Australia',
    value: 10008553,
  },
  {
    label: 'Wangara Dc, Western Australia',
    value: 10010127,
  },
  {
    label: 'Wangaratta, Queensland',
    value: 10004830,
  },
  {
    label: 'Wangaratta, Victoria',
    value: 10007879,
  },
  {
    label: 'Wangaratta, Victoria',
    value: 10007882,
  },
  {
    label: 'Wangary, South Australia',
    value: 10005998,
  },
  {
    label: 'Wangi Wangi, New South Wales',
    value: 10000828,
  },
  {
    label: 'Wangoom, Victoria',
    value: 10007218,
  },
  {
    label: 'Wanguri, Northern Territory',
    value: 10002678,
  },
  {
    label: 'Wanilla, South Australia',
    value: 10006000,
  },
  {
    label: 'Wanjuru, Queensland',
    value: 10005045,
  },
  {
    label: 'Wannamal, Western Australia',
    value: 10009674,
  },
  {
    label: 'Wannanup, Western Australia',
    value: 10008777,
  },
  {
    label: 'Wanneroo, Western Australia',
    value: 10008551,
  },
  {
    label: 'Wanneroo, Western Australia',
    value: 10010126,
  },
  {
    label: 'Wanniassa, Australian Capital Territory',
    value: 10000102,
  },
  {
    label: 'Wansbrough, Western Australia',
    value: 10009146,
  },
  {
    label: 'Wantirna, Victoria',
    value: 10006947,
  },
  {
    label: 'Wantirna South, Victoria',
    value: 10006945,
  },
  {
    label: 'Wapengo, New South Wales',
    value: 10001873,
  },
  {
    label: 'Warabrook, New South Wales',
    value: 10000927,
  },
  {
    label: 'Waramanga, Australian Capital Territory',
    value: 10000055,
  },
  {
    label: 'Warana, Queensland',
    value: 10004012,
  },
  {
    label: 'Waratah, New South Wales',
    value: 10000912,
  },
  {
    label: 'Waratah, Tasmania',
    value: 10006622,
  },
  {
    label: 'Waratah Bay, Victoria',
    value: 10008319,
  },
  {
    label: 'Waratah West, New South Wales',
    value: 10000911,
  },
  {
    label: 'Warawarrup, Western Australia',
    value: 10008813,
  },
  {
    label: 'Warburton, Queensland',
    value: 10004961,
  },
  {
    label: 'Warburton, Victoria',
    value: 10008082,
  },
  {
    label: 'Wardell, New South Wales',
    value: 10001546,
  },
  {
    label: 'Wardering, Western Australia',
    value: 10009091,
  },
  {
    label: 'Warding East, Western Australia',
    value: 10009414,
  },
  {
    label: 'Wards River, New South Wales',
    value: 10001281,
  },
  {
    label: 'Wareek, Victoria',
    value: 10007574,
  },
  {
    label: 'Wareemba, New South Wales',
    value: 10000285,
  },
  {
    label: 'Warenda, Queensland',
    value: 10004991,
  },
  {
    label: 'Warialda, New South Wales',
    value: 10001253,
  },
  {
    label: 'Warilla, New South Wales',
    value: 10001695,
  },
  {
    label: 'Warkworth, New South Wales',
    value: 10001074,
  },
  {
    label: 'Warmun, Western Australia',
    value: 10010040,
  },
  {
    label: 'Warnbro, Western Australia',
    value: 10008747,
  },
  {
    label: 'Warncoort, Victoria',
    value: 10007152,
  },
  {
    label: 'Warneet, Victoria',
    value: 10008360,
  },
  {
    label: 'Warner, Queensland',
    value: 10003801,
  },
  {
    label: 'Warner Glen, Western Australia',
    value: 10009031,
  },
  {
    label: 'Warners Bay, New South Wales',
    value: 10000846,
  },
  {
    label: 'Warnervale, New South Wales',
    value: 10000772,
  },
  {
    label: 'Warnoah, Queensland',
    value: 10004612,
  },
  {
    label: 'Warnung, Queensland',
    value: 10004042,
  },
  {
    label: 'Warooka, South Australia',
    value: 10005976,
  },
  {
    label: 'Waroona, Western Australia',
    value: 10008810,
  },
  {
    label: 'Warra, Queensland',
    value: 10003662,
  },
  {
    label: 'Warraber Islet, New South Wales',
    value: 10010207,
  },
  {
    label: 'Warrachie, South Australia',
    value: 10005997,
  },
  {
    label: 'Warrachuppin, Western Australia',
    value: 10009455,
  },
  {
    label: 'Warracknabeal, Victoria',
    value: 10007463,
  },
  {
    label: 'Warradale, South Australia',
    value: 10005361,
  },
  {
    label: 'Warradale North, South Australia',
    value: 10005362,
  },
  {
    label: 'Warradarge, Western Australia',
    value: 10009706,
  },
  {
    label: 'Warragamba, New South Wales',
    value: 10002314,
  },
  {
    label: 'Warragul, Victoria',
    value: 10008114,
  },
  {
    label: 'Warrah Creek, New South Wales',
    value: 10001117,
  },
  {
    label: 'Warrak, Victoria',
    value: 10007436,
  },
  {
    label: 'Warramboo, South Australia',
    value: 10006017,
  },
  {
    label: 'Warrandyte, Victoria',
    value: 10006864,
  },
  {
    label: 'Warrandyte South, Victoria',
    value: 10006904,
  },
  {
    label: 'Warrane, Tasmania',
    value: 10006106,
  },
  {
    label: 'Warranwood, Victoria',
    value: 10006903,
  },
  {
    label: 'Warrawee, New South Wales',
    value: 10000337,
  },
  {
    label: 'Warrawong, New South Wales',
    value: 10001652,
  },
  {
    label: 'Warrego, Northern Territory',
    value: 10002776,
  },
  {
    label: 'Warrell Creek, New South Wales',
    value: 10001415,
  },
  {
    label: 'Warren, New South Wales',
    value: 10002532,
  },
  {
    label: 'Warrenbayne, Victoria',
    value: 10007866,
  },
  {
    label: 'Warrenheip, Victoria',
    value: 10007400,
  },
  {
    label: 'Warrenup, Western Australia',
    value: 10009190,
  },
  {
    label: 'Warriewood, New South Wales',
    value: 10000397,
  },
  {
    label: 'Warriewood Shopping Square, New South Wales',
    value: 10000398,
  },
  {
    label: 'Warrimoo, New South Wales',
    value: 10002390,
  },
  {
    label: 'Warringah Mall, New South Wales',
    value: 10000393,
  },
  {
    label: 'Warrion, Victoria',
    value: 10007168,
  },
  {
    label: 'Warriwillah, New South Wales',
    value: 10001328,
  },
  {
    label: 'Warrnambool, Victoria',
    value: 10007220,
  },
  {
    label: 'Warroo, Queensland',
    value: 10003600,
  },
  {
    label: 'Warrow, South Australia',
    value: 10005994,
  },
  {
    label: 'Warrubullen, Queensland',
    value: 10005121,
  },
  {
    label: 'Warrumbungle, New South Wales',
    value: 10002542,
  },
  {
    label: 'Warwick, Queensland',
    value: 10003569,
  },
  {
    label: 'Warwick, Western Australia',
    value: 10008448,
  },
  {
    label: 'Warwick Farm, New South Wales',
    value: 10000570,
  },
  {
    label: 'Washpool, New South Wales',
    value: 10001306,
  },
  {
    label: 'Washpool, Queensland',
    value: 10003292,
  },
  {
    label: 'Wasleys, South Australia',
    value: 10005849,
  },
  {
    label: 'Watalgan, Queensland',
    value: 10004307,
  },
  {
    label: 'Watanobbi, New South Wales',
    value: 10000780,
  },
  {
    label: 'Watchem, Victoria',
    value: 10007601,
  },
  {
    label: 'Watchupga, Victoria',
    value: 10007603,
  },
  {
    label: 'Wateranga, Queensland',
    value: 10004136,
  },
  {
    label: 'Waterbank, Western Australia',
    value: 10010024,
  },
  {
    label: 'Watercarrin, Western Australia',
    value: 10009419,
  },
  {
    label: 'Waterfall, New South Wales',
    value: 10000702,
  },
  {
    label: 'Waterfall Gully, South Australia',
    value: 10005419,
  },
  {
    label: 'Waterford, Queensland',
    value: 10003020,
  },
  {
    label: 'Waterford, Western Australia',
    value: 10008675,
  },
  {
    label: 'Waterford West, Queensland',
    value: 10003022,
  },
  {
    label: 'Waterfront Place, Queensland',
    value: 10002806,
  },
  {
    label: 'Waterhouse, Tasmania',
    value: 10006456,
  },
  {
    label: 'Waterloo, New South Wales',
    value: 10000218,
  },
  {
    label: 'Waterloo, Queensland',
    value: 10004371,
  },
  {
    label: 'Waterloo, Tasmania',
    value: 10006176,
  },
  {
    label: 'Waterloo, Western Australia',
    value: 10008844,
  },
  {
    label: 'Waterloo Corner, South Australia',
    value: 10005532,
  },
  {
    label: 'Watermans Bay, Western Australia',
    value: 10008442,
  },
  {
    label: 'Watervale, South Australia',
    value: 10005880,
  },
  {
    label: 'Waterview Heights, New South Wales',
    value: 10001470,
  },
  {
    label: 'Waterways, Victoria',
    value: 10007033,
  },
  {
    label: 'Watheroo, Western Australia',
    value: 10009695,
  },
  {
    label: 'Watson, Australian Capital Territory',
    value: 10000017,
  },
  {
    label: 'Watsonia, Victoria',
    value: 10006829,
  },
  {
    label: 'Watsons Bay, New South Wales',
    value: 10000246,
  },
  {
    label: 'Watsons Creek, New South Wales',
    value: 10001169,
  },
  {
    label: 'Watsons Creek, Victoria',
    value: 10006842,
  },
  {
    label: 'Watsons Crossing, Queensland',
    value: 10003593,
  },
  {
    label: 'Watsonville, Queensland',
    value: 10005220,
  },
  {
    label: 'Wattamolla, New South Wales',
    value: 10001724,
  },
  {
    label: 'Wattamondara, New South Wales',
    value: 10002446,
  },
  {
    label: 'Wattening, Western Australia',
    value: 10009857,
  },
  {
    label: 'Wattle Camp, Queensland',
    value: 10004114,
  },
  {
    label: 'Wattle Flat, New South Wales',
    value: 10002483,
  },
  {
    label: 'Wattle Glen, Victoria',
    value: 10006841,
  },
  {
    label: 'Wattle Grove, New South Wales',
    value: 10000579,
  },
  {
    label: 'Wattle Grove, Queensland',
    value: 10004080,
  },
  {
    label: 'Wattle Grove, Tasmania',
    value: 10006171,
  },
  {
    label: 'Wattle Grove, Western Australia',
    value: 10008619,
  },
  {
    label: 'Wattle Hill, Tasmania',
    value: 10006302,
  },
  {
    label: 'Wattle Park, South Australia',
    value: 10005420,
  },
  {
    label: 'Wattle Park, Victoria',
    value: 10006886,
  },
  {
    label: 'Wattle Ridge, Queensland',
    value: 10003534,
  },
  {
    label: 'Wattlebank, Queensland',
    value: 10004565,
  },
  {
    label: 'Wattleup, Western Australia',
    value: 10008724,
  },
  {
    label: 'Wattoning, Western Australia',
    value: 10009625,
  },
  {
    label: 'Waubra, Victoria',
    value: 10007385,
  },
  {
    label: 'Wauchope, New South Wales',
    value: 10001414,
  },
  {
    label: 'Waurn Ponds, Victoria',
    value: 10007075,
  },
  {
    label: 'Wavell Heights, Queensland',
    value: 10002826,
  },
  {
    label: 'Wavell Heights North, Queensland',
    value: 10002825,
  },
  {
    label: 'Waverley, New South Wales',
    value: 10000232,
  },
  {
    label: 'Waverley, Queensland',
    value: 10004969,
  },
  {
    label: 'Waverley, Tasmania',
    value: 10006394,
  },
  {
    label: 'Waverton, New South Wales',
    value: 10000300,
  },
  {
    label: 'Wayatinah, Tasmania',
    value: 10006226,
  },
  {
    label: 'Wayville, South Australia',
    value: 10005320,
  },
  {
    label: 'Weatherboard, Victoria',
    value: 10007369,
  },
  {
    label: 'Webb, Queensland',
    value: 10005057,
  },
  {
    label: 'Webberton, Western Australia',
    value: 10009745,
  },
  {
    label: 'Wedderburn, New South Wales',
    value: 10001887,
  },
  {
    label: 'Wedderburn, Victoria',
    value: 10007640,
  },
  {
    label: 'Wedge Island, Western Australia',
    value: 10008495,
  },
  {
    label: 'Wedgecarrup, Western Australia',
    value: 10009108,
  },
  {
    label: 'Wedgefield, Western Australia',
    value: 10010008,
  },
  {
    label: 'Wee Jasper, New South Wales',
    value: 10001993,
  },
  {
    label: 'Wee Waa, New South Wales',
    value: 10001234,
  },
  {
    label: 'Weegena, Tasmania',
    value: 10006531,
  },
  {
    label: 'Weengallon, Queensland',
    value: 10003794,
  },
  {
    label: 'Weering, Victoria',
    value: 10007173,
  },
  {
    label: 'Weerriba, Queensland',
    value: 10004545,
  },
  {
    label: 'Weetah, Tasmania',
    value: 10006529,
  },
  {
    label: 'Weetaliba, New South Wales',
    value: 10001241,
  },
  {
    label: 'Weetangera, Australian Capital Territory',
    value: 10000066,
  },
  {
    label: 'Weethalle, New South Wales',
    value: 10002200,
  },
  {
    label: 'Weetulta, South Australia',
    value: 10005962,
  },
  {
    label: 'Weilmoringle, New South Wales',
    value: 10002578,
  },
  {
    label: 'Weipa, Queensland',
    value: 10005185,
  },
  {
    label: 'Weipa Airport, Queensland',
    value: 10005187,
  },
  {
    label: 'Weir River, Queensland',
    value: 10003647,
  },
  {
    label: 'Welbungin, Western Australia',
    value: 10009616,
  },
  {
    label: 'Welby, New South Wales',
    value: 10001950,
  },
  {
    label: 'Welcome Creek, Queensland',
    value: 10004299,
  },
  {
    label: 'Weld Range, Western Australia',
    value: 10009918,
  },
  {
    label: 'Weldborough, Tasmania',
    value: 10006460,
  },
  {
    label: 'Welland, South Australia',
    value: 10005248,
  },
  {
    label: 'Wellard, Western Australia',
    value: 10008749,
  },
  {
    label: 'Wellcamp, Queensland',
    value: 10003389,
  },
  {
    label: 'Wellers Hill, Queensland',
    value: 10002993,
  },
  {
    label: 'Wellesley, Western Australia',
    value: 10008863,
  },
  {
    label: 'Wellington, New South Wales',
    value: 10002526,
  },
  {
    label: 'Wellington, South Australia',
    value: 10005718,
  },
  {
    label: 'Wellington Forest, Western Australia',
    value: 10008874,
  },
  {
    label: 'Wellington Mill, Western Australia',
    value: 10008868,
  },
  {
    label: 'Wellington Point, Queensland',
    value: 10003041,
  },
  {
    label: 'Wellstead, Western Australia',
    value: 10009169,
  },
  {
    label: 'Welsby, Queensland',
    value: 10003819,
  },
  {
    label: 'Welshpool, Victoria',
    value: 10008331,
  },
  {
    label: 'Welshpool, Western Australia',
    value: 10008617,
  },
  {
    label: 'Welshpool Dc, Western Australia',
    value: 10010153,
  },
  {
    label: 'Wembley, Western Australia',
    value: 10008423,
  },
  {
    label: 'Wembley, Western Australia',
    value: 10010098,
  },
  {
    label: 'Wembley Downs, Western Australia',
    value: 10008437,
  },
  {
    label: 'Wendoree Park, New South Wales',
    value: 10000726,
  },
  {
    label: 'Wendouree, Victoria',
    value: 10007407,
  },
  {
    label: 'Wendouree Village, Victoria',
    value: 10007406,
  },
  {
    label: 'Wengenville, Queensland',
    value: 10004118,
  },
  {
    label: 'Wentworth, New South Wales',
    value: 10002127,
  },
  {
    label: 'Wentworth Falls, New South Wales',
    value: 10002409,
  },
  {
    label: 'Wentworthville, New South Wales',
    value: 10000494,
  },
  {
    label: 'Werneth, Victoria',
    value: 10007402,
  },
  {
    label: 'Werombi, New South Wales',
    value: 10001930,
  },
  {
    label: 'Werri Beach, New South Wales',
    value: 10001720,
  },
  {
    label: 'Werribee, Victoria',
    value: 10006726,
  },
  {
    label: 'Werrimull, Victoria',
    value: 10007615,
  },
  {
    label: 'Werrington, New South Wales',
    value: 10002304,
  },
  {
    label: 'Werrington County, New South Wales',
    value: 10002306,
  },
  {
    label: 'Werrington Downs, New South Wales',
    value: 10002301,
  },
  {
    label: 'Werris Creek, New South Wales',
    value: 10001133,
  },
  {
    label: 'Wesburn, Victoria',
    value: 10008081,
  },
  {
    label: 'Wesley Vale, Tasmania',
    value: 10006567,
  },
  {
    label: 'West Armidale, New South Wales',
    value: 10001154,
  },
  {
    label: 'West Ballidu, Western Australia',
    value: 10009873,
  },
  {
    label: 'West Bathurst, New South Wales',
    value: 10002471,
  },
  {
    label: 'West Beach, South Australia',
    value: 10005303,
  },
  {
    label: 'West Beach, Western Australia',
    value: 10009558,
  },
  {
    label: 'West Binnu, Western Australia',
    value: 10009806,
  },
  {
    label: 'West Burleigh, Queensland',
    value: 10003166,
  },
  {
    label: 'West Busselton, Western Australia',
    value: 10009001,
  },
  {
    label: 'West Cape Howe, Western Australia',
    value: 10009176,
  },
  {
    label: 'West Casuarinas, Western Australia',
    value: 10009902,
  },
  {
    label: 'West Chatswood, New South Wales',
    value: 10000154,
  },
  {
    label: 'West Coolup, Western Australia',
    value: 10008805,
  },
  {
    label: 'West Croydon, South Australia',
    value: 10005250,
  },
  {
    label: 'West Devonport, Tasmania',
    value: 10006579,
  },
  {
    label: 'West End, Queensland',
    value: 10002943,
  },
  {
    label: 'West End, Queensland',
    value: 10004860,
  },
  {
    label: 'West End, Western Australia',
    value: 10009759,
  },
  {
    label: 'West Fitzgerald, Western Australia',
    value: 10009253,
  },
  {
    label: 'West Footscray, Victoria',
    value: 10006691,
  },
  {
    label: 'West Gladstone, Queensland',
    value: 10004400,
  },
  {
    label: 'West Gosford, New South Wales',
    value: 10000722,
  },
  {
    label: 'West Haldon, Queensland',
    value: 10003544,
  },
  {
    label: 'West Haven, New South Wales',
    value: 10001394,
  },
  {
    label: 'West Hindmarsh, South Australia',
    value: 10005244,
  },
  {
    label: 'West Hobart, Tasmania',
    value: 10006066,
  },
  {
    label: 'West Holleton, Western Australia',
    value: 10009318,
  },
  {
    label: 'West Hoxton, New South Wales',
    value: 10000572,
  },
  {
    label: 'West Ipswich, Queensland',
    value: 10003238,
  },
  {
    label: 'West Island Cocos (Keeling) Islands, Western Australia',
    value: 10010066,
  },
  {
    label: 'West Kalgoorlie, Western Australia',
    value: 10009494,
  },
  {
    label: 'West Lakes, South Australia',
    value: 10005295,
  },
  {
    label: 'West Lakes Shore, South Australia',
    value: 10005294,
  },
  {
    label: 'West Lamington, Western Australia',
    value: 10009491,
  },
  {
    label: 'West Launceston, Tasmania',
    value: 10006399,
  },
  {
    label: 'West Leederville, Western Australia',
    value: 10008405,
  },
  {
    label: 'West Leederville, Western Australia',
    value: 10010087,
  },
  {
    label: 'West Lyons River, Western Australia',
    value: 10009956,
  },
  {
    label: 'West Mackay, Queensland',
    value: 10004672,
  },
  {
    label: 'West Melbourne, Victoria',
    value: 10006680,
  },
  {
    label: 'West Pennant Hills, New South Wales',
    value: 10000453,
  },
  {
    label: 'West Perth, Western Australia',
    value: 10008402,
  },
  {
    label: 'West Perth, Western Australia',
    value: 10010085,
  },
  {
    label: 'West Pine, Tasmania',
    value: 10006609,
  },
  {
    label: 'West Pingelly, Western Australia',
    value: 10009080,
  },
  {
    label: 'West Pinjarra, Western Australia',
    value: 10008763,
  },
  {
    label: 'West Point, Queensland',
    value: 10004938,
  },
  {
    label: 'West Popanyinning, Western Australia',
    value: 10009087,
  },
  {
    label: 'West Prairie, Queensland',
    value: 10003637,
  },
  {
    label: 'West Pymble, New South Wales',
    value: 10000335,
  },
  {
    label: 'West Richmond, South Australia',
    value: 10005314,
  },
  {
    label: 'West River, Western Australia',
    value: 10009268,
  },
  {
    label: 'West Rockhampton, Queensland',
    value: 10004448,
  },
  {
    label: 'West Ryde, New South Wales',
    value: 10000170,
  },
  {
    label: 'West Ryde, New South Wales',
    value: 10000432,
  },
  {
    label: 'West Stowe, Queensland',
    value: 10004402,
  },
  {
    label: 'West Swan, Western Australia',
    value: 10008509,
  },
  {
    label: 'West Tamworth, New South Wales',
    value: 10001128,
  },
  {
    label: 'West Toodyay, Western Australia',
    value: 10009849,
  },
  {
    label: 'West Wallsend, New South Wales',
    value: 10000873,
  },
  {
    label: 'West Wyalong, New South Wales',
    value: 10002203,
  },
  {
    label: 'Westbourne Park, South Australia',
    value: 10005344,
  },
  {
    label: 'Westbrook, Queensland',
    value: 10003385,
  },
  {
    label: 'Westbury, Tasmania',
    value: 10006519,
  },
  {
    label: 'Westcourt, Queensland',
    value: 10005097,
  },
  {
    label: 'Westdale, New South Wales',
    value: 10001131,
  },
  {
    label: 'Westdale, Western Australia',
    value: 10009065,
  },
  {
    label: 'Western Australia, Western Australia',
    value: 10010179,
  },
  {
    label: 'Western Creek, Queensland',
    value: 10003516,
  },
  {
    label: 'Western Creek, Tasmania',
    value: 10006522,
  },
  {
    label: 'Western Junction, Tasmania',
    value: 10006346,
  },
  {
    label: 'Westerway, Tasmania',
    value: 10006262,
  },
  {
    label: 'Westfield, Western Australia',
    value: 10008636,
  },
  {
    label: 'Westgate, New South Wales',
    value: 10000289,
  },
  {
    label: 'Westgrove, Queensland',
    value: 10003714,
  },
  {
    label: 'Westlake, Queensland',
    value: 10002927,
  },
  {
    label: 'Westleigh, New South Wales',
    value: 10000445,
  },
  {
    label: 'Westmead, New South Wales',
    value: 10000493,
  },
  {
    label: 'Westmeadows, Victoria',
    value: 10006768,
  },
  {
    label: 'Westmere, Victoria',
    value: 10007353,
  },
  {
    label: 'Westminster, Western Australia',
    value: 10008541,
  },
  {
    label: 'Weston, Australian Capital Territory',
    value: 10000059,
  },
  {
    label: 'Weston, New South Wales',
    value: 10001046,
  },
  {
    label: 'Weston Creek, Australian Capital Territory',
    value: 10000060,
  },
  {
    label: 'Westonia, Western Australia',
    value: 10009453,
  },
  {
    label: 'Westville, New South Wales',
    value: 10002450,
  },
  {
    label: 'Westwood, Queensland',
    value: 10004504,
  },
  {
    label: 'Westwood, Tasmania',
    value: 10006503,
  },
  {
    label: 'Westwood, Western Australia',
    value: 10009125,
  },
  {
    label: 'Wetherill Park, New South Wales',
    value: 10000542,
  },
  {
    label: 'Wetherill Park Dc, New South Wales',
    value: 10000185,
  },
  {
    label: 'Wetheron, Queensland',
    value: 10004155,
  },
  {
    label: 'Weymouth, Tasmania',
    value: 10006408,
  },
  {
    label: 'Whalan, New South Wales',
    value: 10002379,
  },
  {
    label: 'Whale Beach, New South Wales',
    value: 10000408,
  },
  {
    label: 'Wharminda, South Australia',
    value: 10005987,
  },
  {
    label: 'Wheatlands, Queensland',
    value: 10004059,
  },
  {
    label: 'Wheeler Heights, New South Wales',
    value: 10000379,
  },
  {
    label: 'Wheelers Hill, Victoria',
    value: 10006942,
  },
  {
    label: 'Wherrol Flat, New South Wales',
    value: 10001322,
  },
  {
    label: 'Whetstone, Queensland',
    value: 10003598,
  },
  {
    label: 'Whian Whian, New South Wales',
    value: 10001589,
  },
  {
    label: 'Whichello, Queensland',
    value: 10003435,
  },
  {
    label: 'Whim Creek, Western Australia',
    value: 10009998,
  },
  {
    label: 'Whitby, Western Australia',
    value: 10008652,
  },
  {
    label: 'White Cliffs, New South Wales',
    value: 10002576,
  },
  {
    label: 'White Gum Valley, Western Australia',
    value: 10008700,
  },
  {
    label: 'White Hills, Tasmania',
    value: 10006438,
  },
  {
    label: 'White Hills, Victoria',
    value: 10007675,
  },
  {
    label: 'White Mountain, Queensland',
    value: 10003466,
  },
  {
    label: 'White Patch, Queensland',
    value: 10003817,
  },
  {
    label: 'White Peak, Western Australia',
    value: 10009782,
  },
  {
    label: 'White Rock, Queensland',
    value: 10005089,
  },
  {
    label: 'Whitebridge, New South Wales',
    value: 10000898,
  },
  {
    label: 'Whitefoord, Tasmania',
    value: 10006221,
  },
  {
    label: 'Whiteman, Western Australia',
    value: 10008570,
  },
  {
    label: 'Whitemark, Tasmania',
    value: 10006424,
  },
  {
    label: 'Whitemore, Tasmania',
    value: 10006521,
  },
  {
    label: 'Whiteside, Queensland',
    value: 10003808,
  },
  {
    label: 'Whitfield, Queensland',
    value: 10005101,
  },
  {
    label: 'Whitfield, Victoria',
    value: 10007996,
  },
  {
    label: 'Whitsundays, Queensland',
    value: 10004804,
  },
  {
    label: 'Whittaker, Western Australia',
    value: 10008759,
  },
  {
    label: 'Whittington, Victoria',
    value: 10007082,
  },
  {
    label: 'Whittlesea, Victoria',
    value: 10008034,
  },
  {
    label: 'Whitton, New South Wales',
    value: 10002228,
  },
  {
    label: 'Whorouly, Victoria',
    value: 10007998,
  },
  {
    label: 'Whyalla, South Australia',
    value: 10005984,
  },
  {
    label: 'Whyalla Jenkins, South Australia',
    value: 10006006,
  },
  {
    label: 'Whyalla Norrie, South Australia',
    value: 10006004,
  },
  {
    label: 'Whyalla Playford, South Australia',
    value: 10005982,
  },
  {
    label: 'Whyalla Stuart, South Australia',
    value: 10006005,
  },
  {
    label: 'Whyte Yarcowie, South Australia',
    value: 10005864,
  },
  {
    label: 'Wialki, Western Australia',
    value: 10009605,
  },
  {
    label: 'Wiangaree, New South Wales',
    value: 10001531,
  },
  {
    label: 'Wicherina, Western Australia',
    value: 10009770,
  },
  {
    label: 'Wicherina South, Western Australia',
    value: 10009778,
  },
  {
    label: 'Wickepin, Western Australia',
    value: 10009323,
  },
  {
    label: 'Wickham, New South Wales',
    value: 10000903,
  },
  {
    label: 'Wickham, Western Australia',
    value: 10009999,
  },
  {
    label: 'Wickliffe, Victoria',
    value: 10007444,
  },
  {
    label: 'Widgee Crossing North, Queensland',
    value: 10003950,
  },
  {
    label: 'Widgee Crossing South, Queensland',
    value: 10003972,
  },
  {
    label: 'Widgeegoara, Queensland',
    value: 10003776,
  },
  {
    label: 'Widgelli, New South Wales',
    value: 10002214,
  },
  {
    label: 'Widgiemooltha, Western Australia',
    value: 10009539,
  },
  {
    label: 'Wieambilla, Queensland',
    value: 10003666,
  },
  {
    label: 'Wights Mountain, Queensland',
    value: 10003855,
  },
  {
    label: 'Wigton, Queensland',
    value: 10004094,
  },
  {
    label: 'Wilberforce, New South Wales',
    value: 10002331,
  },
  {
    label: 'Wilberforce, Western Australia',
    value: 10009055,
  },
  {
    label: 'Wilbetree, New South Wales',
    value: 10002610,
  },
  {
    label: 'Wilbinga, Western Australia',
    value: 10008486,
  },
  {
    label: 'Wilby, Victoria',
    value: 10007991,
  },
  {
    label: 'Wilcannia, New South Wales',
    value: 10002575,
  },
  {
    label: 'Wildes Meadow, New South Wales',
    value: 10001964,
  },
  {
    label: 'Wiley Park, New South Wales',
    value: 10000606,
  },
  {
    label: 'Wilga, Western Australia',
    value: 10008895,
  },
  {
    label: 'Wilga West, Western Australia',
    value: 10008894,
  },
  {
    label: 'Wilgarrup, Western Australia',
    value: 10008945,
  },
  {
    label: 'Wilgoyne, Western Australia',
    value: 10009617,
  },
  {
    label: 'Wilkesdale, Queensland',
    value: 10004063,
  },
  {
    label: 'Willagee, Western Australia',
    value: 10008692,
  },
  {
    label: 'Willagee Central, Western Australia',
    value: 10008690,
  },
  {
    label: 'Willaston, South Australia',
    value: 10005565,
  },
  {
    label: 'Willaura, Victoria',
    value: 10007445,
  },
  {
    label: 'Willawarrin, New South Wales',
    value: 10001361,
  },
  {
    label: 'Willawong, Queensland',
    value: 10002966,
  },
  {
    label: 'Willbriggie, New South Wales',
    value: 10002217,
  },
  {
    label: 'Willetton, Western Australia',
    value: 10008687,
  },
  {
    label: 'Willetton, Western Australia',
    value: 10010132,
  },
  {
    label: 'William Bay, Western Australia',
    value: 10009240,
  },
  {
    label: 'Williams, Western Australia',
    value: 10009367,
  },
  {
    label: 'Williamsdale, New South Wales',
    value: 10002035,
  },
  {
    label: 'Williamstown, South Australia',
    value: 10005816,
  },
  {
    label: 'Williamstown, Victoria',
    value: 10006698,
  },
  {
    label: 'Williamstown, Western Australia',
    value: 10009486,
  },
  {
    label: 'Williamtown, New South Wales',
    value: 10000961,
  },
  {
    label: 'Williamtown Raaf, New South Wales',
    value: 10000947,
  },
  {
    label: 'Willina, New South Wales',
    value: 10001295,
  },
  {
    label: 'Willmot, New South Wales',
    value: 10002381,
  },
  {
    label: 'Willoughby, New South Wales',
    value: 10000324,
  },
  {
    label: 'Willow Grove, Victoria',
    value: 10008126,
  },
  {
    label: 'Willow Tree, New South Wales',
    value: 10001116,
  },
  {
    label: 'Willow Vale, Queensland',
    value: 10003109,
  },
  {
    label: 'Willowbank, Queensland',
    value: 10003258,
  },
  {
    label: 'Willows Gemfields, Queensland',
    value: 10004514,
  },
  {
    label: 'Wills, Queensland',
    value: 10004995,
  },
  {
    label: 'Willung, Victoria',
    value: 10008154,
  },
  {
    label: 'Willung South, Victoria',
    value: 10008150,
  },
  {
    label: 'Willunga, South Australia',
    value: 10005649,
  },
  {
    label: 'Willyung, Western Australia',
    value: 10009194,
  },
  {
    label: 'Wilmington, South Australia',
    value: 10005900,
  },
  {
    label: 'Wilmot, Tasmania',
    value: 10006578,
  },
  {
    label: 'Wilson, Western Australia',
    value: 10008621,
  },
  {
    label: 'Wilson Beach, Queensland',
    value: 10004786,
  },
  {
    label: 'Wilson Valley, Queensland',
    value: 10004159,
  },
  {
    label: 'Wilsonton, Queensland',
    value: 10003387,
  },
  {
    label: 'Wilston, Queensland',
    value: 10002872,
  },
  {
    label: 'Wilton, New South Wales',
    value: 10001937,
  },
  {
    label: 'Wiluna, Western Australia',
    value: 10009928,
  },
  {
    label: 'Wilyabrup, Western Australia',
    value: 10008990,
  },
  {
    label: 'Wimbledon, New South Wales',
    value: 10002474,
  },
  {
    label: 'Winchelsea, Victoria',
    value: 10007146,
  },
  {
    label: 'Winchester, Western Australia',
    value: 10009705,
  },
  {
    label: 'Windabout, Western Australia',
    value: 10009567,
  },
  {
    label: 'Windale, New South Wales',
    value: 10000935,
  },
  {
    label: 'Windang, New South Wales',
    value: 10001696,
  },
  {
    label: 'Windaroo, Queensland',
    value: 10003093,
  },
  {
    label: 'Windermere, Queensland',
    value: 10004315,
  },
  {
    label: 'Windermere, Tasmania',
    value: 10006406,
  },
  {
    label: 'Windermere, Victoria',
    value: 10007375,
  },
  {
    label: 'Windeyer, New South Wales',
    value: 10002611,
  },
  {
    label: 'Windeyer, Queensland',
    value: 10003751,
  },
  {
    label: 'Windorah, Queensland',
    value: 10003764,
  },
  {
    label: 'Windsor, New South Wales',
    value: 10002329,
  },
  {
    label: 'Windsor, Queensland',
    value: 10002856,
  },
  {
    label: 'Windsor, South Australia',
    value: 10005913,
  },
  {
    label: 'Windsor, Victoria',
    value: 10006999,
  },
  {
    label: 'Windsor Gardens, South Australia',
    value: 10005494,
  },
  {
    label: 'Winegrove, New South Wales',
    value: 10001478,
  },
  {
    label: 'Wingala, New South Wales',
    value: 10000385,
  },
  {
    label: 'Wingello, New South Wales',
    value: 10001970,
  },
  {
    label: 'Wingen, New South Wales',
    value: 10001104,
  },
  {
    label: 'Wingfield, South Australia',
    value: 10005270,
  },
  {
    label: 'Wingham, New South Wales',
    value: 10001332,
  },
  {
    label: 'Winkie, South Australia',
    value: 10005808,
  },
  {
    label: 'Winkleigh, Tasmania',
    value: 10006486,
  },
  {
    label: 'Winmalee, New South Wales',
    value: 10002402,
  },
  {
    label: 'Winnaleah, Tasmania',
    value: 10006468,
  },
  {
    label: 'Winnap, Victoria',
    value: 10007255,
  },
  {
    label: 'Winnejup, Western Australia',
    value: 10008917,
  },
  {
    label: 'Winnellie, Northern Territory',
    value: 10002711,
  },
  {
    label: 'Winnellie, Northern Territory',
    value: 10002715,
  },
  {
    label: 'Winnellie, Northern Territory',
    value: 10002721,
  },
  {
    label: 'Winslow, Victoria',
    value: 10007223,
  },
  {
    label: 'Winston, Queensland',
    value: 10004967,
  },
  {
    label: 'Winston Hills, New South Wales',
    value: 10000513,
  },
  {
    label: 'Wintergarden, Queensland',
    value: 10002808,
  },
  {
    label: 'Winthrop, Western Australia',
    value: 10008667,
  },
  {
    label: 'Winton, Queensland',
    value: 10004650,
  },
  {
    label: 'Winton, Victoria',
    value: 10007875,
  },
  {
    label: 'Winwill, Queensland',
    value: 10003382,
  },
  {
    label: 'Wirrabara, South Australia',
    value: 10005897,
  },
  {
    label: 'Wirrimah, New South Wales',
    value: 10002507,
  },
  {
    label: 'Wirrinya, New South Wales',
    value: 10002645,
  },
  {
    label: 'Wirrulla, South Australia',
    value: 10006024,
  },
  {
    label: 'Wiseleigh, Victoria',
    value: 10008213,
  },
  {
    label: 'Wisemans Creek, New South Wales',
    value: 10002465,
  },
  {
    label: 'Wisemans Ferry, New South Wales',
    value: 10002394,
  },
  {
    label: 'Wishart, Queensland',
    value: 10003000,
  },
  {
    label: 'Wishart, Victoria',
    value: 10007018,
  },
  {
    label: 'Wistow, South Australia',
    value: 10005703,
  },
  {
    label: 'Witchcliffe, Western Australia',
    value: 10009026,
  },
  {
    label: 'Withcott, Queensland',
    value: 10003421,
  },
  {
    label: 'Witheren, Queensland',
    value: 10003195,
  },
  {
    label: 'Withers, Western Australia',
    value: 10008857,
  },
  {
    label: 'Wittenoom, Western Australia',
    value: 10010047,
  },
  {
    label: 'Wittenoom Hills, Western Australia',
    value: 10009550,
  },
  {
    label: 'Wivenhoe, Tasmania',
    value: 10006611,
  },
  {
    label: 'Wivenhoe Hill, Queensland',
    value: 10003299,
  },
  {
    label: 'Woden, Australian Capital Territory',
    value: 10000039,
  },
  {
    label: 'Wodonga, Victoria',
    value: 10007899,
  },
  {
    label: 'Wodonga, Victoria',
    value: 10007901,
  },
  {
    label: 'Wodonga Plaza, Victoria',
    value: 10007900,
  },
  {
    label: 'Wogolin, Western Australia',
    value: 10009324,
  },
  {
    label: 'Wokalup, Western Australia',
    value: 10008820,
  },
  {
    label: 'Wolffdene, Queensland',
    value: 10003083,
  },
  {
    label: 'Wollar, New South Wales',
    value: 10002612,
  },
  {
    label: 'Wollert, Victoria',
    value: 10008022,
  },
  {
    label: 'Wolli Creek, New South Wales',
    value: 10000625,
  },
  {
    label: 'Wollombi, New South Wales',
    value: 10001040,
  },
  {
    label: 'Wollomombi, New South Wales',
    value: 10001152,
  },
  {
    label: 'Wollongbar, New South Wales',
    value: 10001549,
  },
  {
    label: 'Wollongong, New South Wales',
    value: 10001644,
  },
  {
    label: 'Wollongong, New South Wales',
    value: 10001680,
  },
  {
    label: 'Wollstonecraft, New South Wales',
    value: 10000312,
  },
  {
    label: 'Wollun, New South Wales',
    value: 10001166,
  },
  {
    label: 'Wolseley, South Australia',
    value: 10005738,
  },
  {
    label: 'Wolumla, New South Wales',
    value: 10001865,
  },
  {
    label: 'Womarden, Western Australia',
    value: 10009713,
  },
  {
    label: 'Wombarra, New South Wales',
    value: 10001667,
  },
  {
    label: 'Wombat, New South Wales',
    value: 10002019,
  },
  {
    label: 'Wombelano, Victoria',
    value: 10007483,
  },
  {
    label: 'Wombeyan Caves, New South Wales',
    value: 10001977,
  },
  {
    label: 'Womboota, New South Wales',
    value: 10002282,
  },
  {
    label: 'Won Wron, Victoria',
    value: 10008339,
  },
  {
    label: 'Wonbah, Queensland',
    value: 10004341,
  },
  {
    label: 'Wonbah Forest, Queensland',
    value: 10004355,
  },
  {
    label: 'Wonboyn, New South Wales',
    value: 10001874,
  },
  {
    label: 'Wonboyn Lake, New South Wales',
    value: 10001875,
  },
  {
    label: 'Wondai, Queensland',
    value: 10004061,
  },
  {
    label: 'Wondalga, New South Wales',
    value: 10002277,
  },
  {
    label: 'Wondalli, Queensland',
    value: 10003613,
  },
  {
    label: 'Wondecla, Queensland',
    value: 10005219,
  },
  {
    label: 'Wondunna, Queensland',
    value: 10004256,
  },
  {
    label: 'Wonga, Queensland',
    value: 10005179,
  },
  {
    label: 'Wonga Park, Victoria',
    value: 10006866,
  },
  {
    label: 'Wongabel, Queensland',
    value: 10005211,
  },
  {
    label: 'Wongaling Beach, Queensland',
    value: 10005015,
  },
  {
    label: 'Wongamine, Western Australia',
    value: 10009409,
  },
  {
    label: 'Wongan Hills, Western Australia',
    value: 10009870,
  },
  {
    label: 'Wongarbon, New South Wales',
    value: 10002560,
  },
  {
    label: 'Wongarra, Victoria',
    value: 10007099,
  },
  {
    label: 'Wongawallan, Queensland',
    value: 10003112,
  },
  {
    label: 'Wongawilli, New South Wales',
    value: 10001708,
  },
  {
    label: 'Wonglepong, Queensland',
    value: 10003196,
  },
  {
    label: 'Wongoondy, Western Australia',
    value: 10009905,
  },
  {
    label: 'Wonnerup, Western Australia',
    value: 10008972,
  },
  {
    label: 'Wonthaggi, Victoria',
    value: 10008383,
  },
  {
    label: 'Wonthella, Western Australia',
    value: 10009756,
  },
  {
    label: 'Woocoo, Queensland',
    value: 10004124,
  },
  {
    label: 'Wood Wood, Victoria',
    value: 10007761,
  },
  {
    label: 'Woodanilling, Western Australia',
    value: 10009123,
  },
  {
    label: 'Woodberry, New South Wales',
    value: 10000990,
  },
  {
    label: 'Woodbine, New South Wales',
    value: 10001899,
  },
  {
    label: 'Woodbine, Queensland',
    value: 10003361,
  },
  {
    label: 'Woodbridge, Tasmania',
    value: 10006291,
  },
  {
    label: 'Woodbridge, Western Australia',
    value: 10008525,
  },
  {
    label: 'Woodburn, New South Wales',
    value: 10001526,
  },
  {
    label: 'Woodbury, Tasmania',
    value: 10006211,
  },
  {
    label: 'Woodcroft, New South Wales',
    value: 10002369,
  },
  {
    label: 'Woodcroft, South Australia',
    value: 10005629,
  },
  {
    label: 'Woodenbong, New South Wales',
    value: 10001543,
  },
  {
    label: 'Woodend, Queensland',
    value: 10003234,
  },
  {
    label: 'Woodend, Victoria',
    value: 10007522,
  },
  {
    label: 'Wooderson, Queensland',
    value: 10004410,
  },
  {
    label: 'Woodfield, Victoria',
    value: 10007961,
  },
  {
    label: 'Woodford, New South Wales',
    value: 10001491,
  },
  {
    label: 'Woodford, New South Wales',
    value: 10002403,
  },
  {
    label: 'Woodford, Queensland',
    value: 10003840,
  },
  {
    label: 'Woodford, Victoria',
    value: 10007224,
  },
  {
    label: 'Woodforde, South Australia',
    value: 10005451,
  },
  {
    label: 'Woodgate, Queensland',
    value: 10004286,
  },
  {
    label: 'Woodhill, New South Wales',
    value: 10001722,
  },
  {
    label: 'Woodhouselee, New South Wales',
    value: 10001981,
  },
  {
    label: 'Woodlands, New South Wales',
    value: 10001748,
  },
  {
    label: 'Woodlands, Queensland',
    value: 10003343,
  },
  {
    label: 'Woodlands, Western Australia',
    value: 10008432,
  },
  {
    label: 'Woodleigh, Queensland',
    value: 10003431,
  },
  {
    label: 'Woodleigh, Victoria',
    value: 10008295,
  },
  {
    label: 'Woodleigh Gardens, Northern Territory',
    value: 10002699,
  },
  {
    label: 'Woodmillar, Queensland',
    value: 10004149,
  },
  {
    label: 'Woodridge, Queensland',
    value: 10002976,
  },
  {
    label: 'Woodridge, Western Australia',
    value: 10008488,
  },
  {
    label: 'Woodrising, New South Wales',
    value: 10000862,
  },
  {
    label: 'Woodroffe, Northern Territory',
    value: 10002738,
  },
  {
    label: 'Woods Point, Victoria',
    value: 10007979,
  },
  {
    label: 'Woodsdale, Tasmania',
    value: 10006213,
  },
  {
    label: 'Woodside, South Australia',
    value: 10005696,
  },
  {
    label: 'Woodside, Victoria',
    value: 10008195,
  },
  {
    label: 'Woodstock, New South Wales',
    value: 10002445,
  },
  {
    label: 'Woodstock, Queensland',
    value: 10004915,
  },
  {
    label: 'Woodstock, Tasmania',
    value: 10006183,
  },
  {
    label: 'Woodstock, Victoria',
    value: 10008023,
  },
  {
    label: 'Woodstock On Loddon, Victoria',
    value: 10007697,
  },
  {
    label: 'Woodstock West, Victoria',
    value: 10007563,
  },
  {
    label: 'Woodvale, Western Australia',
    value: 10008454,
  },
  {
    label: 'Woodview, New South Wales',
    value: 10001509,
  },
  {
    label: 'Woodville, New South Wales',
    value: 10000981,
  },
  {
    label: 'Woodville, South Australia',
    value: 10005264,
  },
  {
    label: 'Woodville Gardens, South Australia',
    value: 10005266,
  },
  {
    label: 'Woodville North, South Australia',
    value: 10005268,
  },
  {
    label: 'Woodville Park, South Australia',
    value: 10005263,
  },
  {
    label: 'Woodville South, South Australia',
    value: 10005262,
  },
  {
    label: 'Woodwark, Queensland',
    value: 10004808,
  },
  {
    label: 'Woody Point, Queensland',
    value: 10002843,
  },
  {
    label: 'Woogenellup, Western Australia',
    value: 10009159,
  },
  {
    label: 'Wool Bay, South Australia',
    value: 10005970,
  },
  {
    label: 'Wool Wool, Victoria',
    value: 10007166,
  },
  {
    label: 'Woolamai, Victoria',
    value: 10008386,
  },
  {
    label: 'Woolbrook, New South Wales',
    value: 10001165,
  },
  {
    label: 'Woolein, Queensland',
    value: 10004510,
  },
  {
    label: 'Woolgoolga, New South Wales',
    value: 10001463,
  },
  {
    label: 'Woolgorong, Western Australia',
    value: 10009903,
  },
  {
    label: 'Wooli, New South Wales',
    value: 10001488,
  },
  {
    label: 'Woollahra, New South Wales',
    value: 10000135,
  },
  {
    label: 'Woollahra, New South Wales',
    value: 10000234,
  },
  {
    label: 'Woollamia, New South Wales',
    value: 10001823,
  },
  {
    label: 'Woolloomooloo, New South Wales',
    value: 10000212,
  },
  {
    label: 'Woolloongabba, Queensland',
    value: 10002945,
  },
  {
    label: 'Woolmer, Queensland',
    value: 10003472,
  },
  {
    label: 'Woolner, Northern Territory',
    value: 10002704,
  },
  {
    label: 'Woolnorth, Tasmania',
    value: 10006653,
  },
  {
    label: 'Woolocutty, Western Australia',
    value: 10009322,
  },
  {
    label: 'Woolomin, New South Wales',
    value: 10001127,
  },
  {
    label: 'Woolooga, Queensland',
    value: 10003988,
  },
  {
    label: 'Woolooma, New South Wales',
    value: 10001108,
  },
  {
    label: 'Woolooware, New South Wales',
    value: 10000691,
  },
  {
    label: 'Wooloowin, Queensland',
    value: 10002855,
  },
  {
    label: 'Woolshed Flat, Victoria',
    value: 10007641,
  },
  {
    label: 'Woolsthorpe, Victoria',
    value: 10007213,
  },
  {
    label: 'Woolwich, New South Wales',
    value: 10000414,
  },
  {
    label: 'Woomargama, New South Wales',
    value: 10002111,
  },
  {
    label: 'Woombye, Queensland',
    value: 10003916,
  },
  {
    label: 'Woomelang, Victoria',
    value: 10007604,
  },
  {
    label: 'Woomera, South Australia',
    value: 10006045,
  },
  {
    label: 'Woondul, Queensland',
    value: 10003514,
  },
  {
    label: 'Woongarrah, New South Wales',
    value: 10000762,
  },
  {
    label: 'Woongoolba, Queensland',
    value: 10003097,
  },
  {
    label: 'Woonona, New South Wales',
    value: 10001671,
  },
  {
    label: 'Woorabinda, Queensland',
    value: 10004582,
  },
  {
    label: 'Wooragee, Victoria',
    value: 10008016,
  },
  {
    label: 'Wooramel, Western Australia',
    value: 10009941,
  },
  {
    label: 'Woori Yallock, Victoria',
    value: 10006918,
  },
  {
    label: 'Woorim, Queensland',
    value: 10003821,
  },
  {
    label: 'Woorinen, Victoria',
    value: 10007756,
  },
  {
    label: 'Woorinen South, Victoria',
    value: 10007755,
  },
  {
    label: 'Woorndoo, Victoria',
    value: 10007209,
  },
  {
    label: 'Wooroloo, Western Australia',
    value: 10009838,
  },
  {
    label: 'Wooroolin, Queensland',
    value: 10004066,
  },
  {
    label: 'Wooroona, Queensland',
    value: 10004533,
  },
  {
    label: 'Wooroonden, Queensland',
    value: 10004052,
  },
  {
    label: 'Wooroonooran, Queensland',
    value: 10005047,
  },
  {
    label: 'Woorree, Western Australia',
    value: 10009758,
  },
  {
    label: 'Wootha, Queensland',
    value: 10003891,
  },
  {
    label: 'Woottating, Western Australia',
    value: 10009840,
  },
  {
    label: 'Wootton, New South Wales',
    value: 10001287,
  },
  {
    label: 'Woowoonga, Queensland',
    value: 10004130,
  },
  {
    label: 'Woree, Queensland',
    value: 10005091,
  },
  {
    label: 'World Trade Centre, Victoria',
    value: 10006681,
  },
  {
    label: 'World Trade Centre, Victoria',
    value: 10008392,
  },
  {
    label: 'Worongary, Queensland',
    value: 10003134,
  },
  {
    label: 'Woronora, New South Wales',
    value: 10000696,
  },
  {
    label: 'Woronora Heights, New South Wales',
    value: 10000703,
  },
  {
    label: 'Worrigee, New South Wales',
    value: 10001807,
  },
  {
    label: 'Worsley, Western Australia',
    value: 10008831,
  },
  {
    label: 'Wowan, Queensland',
    value: 10004506,
  },
  {
    label: 'Woy Woy, New South Wales',
    value: 10000749,
  },
  {
    label: 'Wrattens Forest, Queensland',
    value: 10004037,
  },
  {
    label: 'Wrights Beach, New South Wales',
    value: 10001797,
  },
  {
    label: 'Wrights Creek, Queensland',
    value: 10005093,
  },
  {
    label: 'Wubin, Western Australia',
    value: 10009885,
  },
  {
    label: 'Wudinna, South Australia',
    value: 10006019,
  },
  {
    label: 'Wujal Wujal, New South Wales',
    value: 10010205,
  },
  {
    label: 'Wulagi, Northern Territory',
    value: 10002693,
  },
  {
    label: 'Wulgulmerang, Victoria',
    value: 10008214,
  },
  {
    label: 'Wulguru, Queensland',
    value: 10004874,
  },
  {
    label: 'Wulkuraka, Queensland',
    value: 10003240,
  },
  {
    label: 'Wundowie, Western Australia',
    value: 10009839,
  },
  {
    label: 'Wunghnu, Victoria',
    value: 10007820,
  },
  {
    label: 'Wungong, Western Australia',
    value: 10008640,
  },
  {
    label: 'Wunjunga, Queensland',
    value: 10004831,
  },
  {
    label: 'Wura, Queensland',
    value: 10004586,
  },
  {
    label: 'Wuraming, Western Australia',
    value: 10009361,
  },
  {
    label: 'Wurdiboluc, Victoria',
    value: 10007147,
  },
  {
    label: 'Wurruk, Victoria',
    value: 10008159,
  },
  {
    label: 'Wurtulla, Queensland',
    value: 10004014,
  },
  {
    label: 'Wuruma Dam, Queensland',
    value: 10004190,
  },
  {
    label: 'Wutul, Queensland',
    value: 10003470,
  },
  {
    label: 'Wy Yung, Victoria',
    value: 10008199,
  },
  {
    label: 'Wyaga, Queensland',
    value: 10003611,
  },
  {
    label: 'Wyalkatchem, Western Australia',
    value: 10009632,
  },
  {
    label: 'Wyalla, Queensland',
    value: 10004112,
  },
  {
    label: 'Wyalla Plaza, Queensland',
    value: 10003403,
  },
  {
    label: 'Wyalong, New South Wales',
    value: 10002205,
  },
  {
    label: 'Wyandra, Queensland',
    value: 10003772,
  },
  {
    label: 'Wybong, New South Wales',
    value: 10001083,
  },
  {
    label: 'Wycarbah, Queensland',
    value: 10004538,
  },
  {
    label: 'Wycheproof, Victoria',
    value: 10007654,
  },
  {
    label: 'Wychitella, Victoria',
    value: 10007652,
  },
  {
    label: 'Wye River, Victoria',
    value: 10007094,
  },
  {
    label: 'Wyee, New South Wales',
    value: 10000768,
  },
  {
    label: 'Wyena, Tasmania',
    value: 10006417,
  },
  {
    label: 'Wyening, Western Australia',
    value: 10009859,
  },
  {
    label: 'Wylie Creek, New South Wales',
    value: 10001216,
  },
  {
    label: 'Wynarka, South Australia',
    value: 10005783,
  },
  {
    label: 'Wyndham, New South Wales',
    value: 10001864,
  },
  {
    label: 'Wyndham, Western Australia',
    value: 10010030,
  },
  {
    label: 'Wyndham Vale, Victoria',
    value: 10006716,
  },
  {
    label: 'Wynn Vale, South Australia',
    value: 10005579,
  },
  {
    label: 'Wynnum, Queensland',
    value: 10003063,
  },
  {
    label: 'Wynnum North, Queensland',
    value: 10003066,
  },
  {
    label: 'Wynnum West, Queensland',
    value: 10003067,
  },
  {
    label: 'Wynyard, Tasmania',
    value: 10006641,
  },
  {
    label: 'Wyola, Western Australia',
    value: 10009422,
  },
  {
    label: 'Wyola West, Western Australia',
    value: 10009423,
  },
  {
    label: 'Wyoming, New South Wales',
    value: 10000725,
  },
  {
    label: 'Wyong, New South Wales',
    value: 10000764,
  },
  {
    label: 'Wyongah, New South Wales',
    value: 10000782,
  },
  {
    label: 'Wyrallah, New South Wales',
    value: 10001575,
  },
  {
    label: 'Wyreema, Queensland',
    value: 10003469,
  },
  {
    label: 'Wyuna, Victoria',
    value: 10007786,
  },
  { label: 'Xantippe, Western Australia', value: 10009881 },

  {
    label: 'Yaamba, Queensland',
    value: 10004566,
  },
  {
    label: 'Yaapeet, Victoria',
    value: 10007504,
  },
  {
    label: 'Yabba North, Victoria',
    value: 10007835,
  },
  {
    label: 'Yabberup, Western Australia',
    value: 10008891,
  },
  {
    label: 'Yabulu, Queensland',
    value: 10004928,
  },
  {
    label: 'Yacka, South Australia',
    value: 10005890,
  },
  {
    label: 'Yackandandah, Victoria',
    value: 10008021,
  },
  {
    label: 'Yagaburne, Queensland',
    value: 10003607,
  },
  {
    label: 'Yagoona, New South Wales',
    value: 10000612,
  },
  {
    label: 'Yagoona West, New South Wales',
    value: 10000611,
  },
  {
    label: 'Yahl, South Australia',
    value: 10005761,
  },
  {
    label: 'Yakamia, Western Australia',
    value: 10009215,
  },
  {
    label: 'Yalangur, Queensland',
    value: 10003441,
  },
  {
    label: 'Yalata, South Australia',
    value: 10006033,
  },
  {
    label: 'Yalbraith, New South Wales',
    value: 10001988,
  },
  {
    label: 'Yalgoo, Western Australia',
    value: 10009912,
  },
  {
    label: 'Yallabatharra, Western Australia',
    value: 10009820,
  },
  {
    label: 'Yallah, New South Wales',
    value: 10001710,
  },
  {
    label: 'Yallambie, Victoria',
    value: 10006828,
  },
  {
    label: 'Yalleroi, Queensland',
    value: 10004516,
  },
  {
    label: 'Yallingup, Western Australia',
    value: 10009009,
  },
  {
    label: 'Yallingup Siding, Western Australia',
    value: 10009010,
  },
  {
    label: 'Yallourn North, Victoria',
    value: 10008133,
  },
  {
    label: 'Yalwal, New South Wales',
    value: 10001812,
  },
  {
    label: 'Yalyalup, Western Australia',
    value: 10008995,
  },
  {
    label: 'Yamanto, Queensland',
    value: 10003242,
  },
  {
    label: 'Yamba, New South Wales',
    value: 10001493,
  },
  {
    label: 'Yambuk, Victoria',
    value: 10007236,
  },
  {
    label: 'Yambuna, Victoria',
    value: 10007788,
  },
  {
    label: 'Yan Yean, Victoria',
    value: 10008028,
  },
  {
    label: 'Yanac, Victoria',
    value: 10007498,
  },
  {
    label: 'Yanakie, Victoria',
    value: 10008322,
  },
  {
    label: 'Yanchep, Western Australia',
    value: 10008479,
  },
  {
    label: 'Yanco, New South Wales',
    value: 10002227,
  },
  {
    label: 'Yandanooka, Western Australia',
    value: 10009721,
  },
  {
    label: 'Yandaran, Queensland',
    value: 10004372,
  },
  {
    label: 'Yandarlo, Queensland',
    value: 10003757,
  },
  {
    label: 'Yanderra, New South Wales',
    value: 10001944,
  },
  {
    label: 'Yandilla, Queensland',
    value: 10003427,
  },
  {
    label: 'Yandina, Queensland',
    value: 10003925,
  },
  {
    label: 'Yandoit, Victoria',
    value: 10007553,
  },
  {
    label: 'Yandoo Creek, Western Australia',
    value: 10009946,
  },
  {
    label: 'Yangan, Queensland',
    value: 10003572,
  },
  {
    label: 'Yangebup, Western Australia',
    value: 10008715,
  },
  {
    label: 'Yaninee, South Australia',
    value: 10006020,
  },
  {
    label: 'Yankalilla, South Australia',
    value: 10005658,
  },
  {
    label: 'Yanmah, Western Australia',
    value: 10008929,
  },
  {
    label: 'Yannarie, Western Australia',
    value: 10009965,
  },
  {
    label: 'Yannathan, Victoria',
    value: 10008363,
  },
  {
    label: 'Yantabulla, New South Wales',
    value: 10002583,
  },
  {
    label: 'Yaouk, New South Wales',
    value: 10002070,
  },
  {
    label: 'Yaraka, Queensland',
    value: 10004466,
  },
  {
    label: 'Yarawindah, Western Australia',
    value: 10009682,
  },
  {
    label: 'Yarck, Victoria',
    value: 10007971,
  },
  {
    label: 'Yardarino, Western Australia',
    value: 10009729,
  },
  {
    label: 'Yarding, Western Australia',
    value: 10009444,
  },
  {
    label: 'Yargullen, Queensland',
    value: 10003624,
  },
  {
    label: 'Yarloop, Western Australia',
    value: 10008812,
  },
  {
    label: 'Yaroomba, Queensland',
    value: 10004005,
  },
  {
    label: 'Yarra, New South Wales',
    value: 10001980,
  },
  {
    label: 'Yarra Glen, Victoria',
    value: 10008052,
  },
  {
    label: 'Yarra Junction, Victoria',
    value: 10008076,
  },
  {
    label: 'Yarrabah, Queensland',
    value: 10005125,
  },
  {
    label: 'Yarragadee, Western Australia',
    value: 10009719,
  },
  {
    label: 'Yarragon, Victoria',
    value: 10008121,
  },
  {
    label: 'Yarralumla, Australian Capital Territory',
    value: 10000008,
  },
  {
    label: 'Yarram, Victoria',
    value: 10008335,
  },
  {
    label: 'Yarramalong, New South Wales',
    value: 10000781,
  },
  {
    label: 'Yarraman, Queensland',
    value: 10004103,
  },
  {
    label: 'Yarrambat, Victoria',
    value: 10006835,
  },
  {
    label: 'Yarramundi, New South Wales',
    value: 10002319,
  },
  {
    label: 'Yarranlea, Queensland',
    value: 10003496,
  },
  {
    label: 'Yarraville, Victoria',
    value: 10006693,
  },
  {
    label: 'Yarraville West, Victoria',
    value: 10006694,
  },
  {
    label: 'Yarrawalla, Victoria',
    value: 10007743,
  },
  {
    label: 'Yarrawarrah, New South Wales',
    value: 10000704,
  },
  {
    label: 'Yarrawonga, New South Wales',
    value: 10002614,
  },
  {
    label: 'Yarrawonga, Northern Territory',
    value: 10002740,
  },
  {
    label: 'Yarrawonga, Victoria',
    value: 10007993,
  },
  {
    label: 'Yarrawonga Park, New South Wales',
    value: 10000819,
  },
  {
    label: 'Yarrol, Queensland',
    value: 10004199,
  },
  {
    label: 'Yarroweyah, Victoria',
    value: 10007831,
  },
  {
    label: 'Yarrunga, New South Wales',
    value: 10001956,
  },
  {
    label: 'Yarrunga, Victoria',
    value: 10007881,
  },
  {
    label: 'Yarwun, Queensland',
    value: 10004431,
  },
  {
    label: 'Yass, New South Wales',
    value: 10001995,
  },
  {
    label: 'Yatala, Queensland',
    value: 10003089,
  },
  {
    label: 'Yatala Vale, South Australia',
    value: 10005576,
  },
  {
    label: 'Yathroo, Western Australia',
    value: 10009677,
  },
  {
    label: 'Yattalunga, New South Wales',
    value: 10000736,
  },
  {
    label: 'Yatte Yattah, New South Wales',
    value: 10001773,
  },
  {
    label: 'Yea, Victoria',
    value: 10007966,
  },
  {
    label: 'Yeal, Western Australia',
    value: 10009653,
  },
  {
    label: 'Yealering, Western Australia',
    value: 10009328,
  },
  {
    label: 'Yeelanna, South Australia',
    value: 10006009,
  },
  {
    label: 'Yeerongpilly, Queensland',
    value: 10002951,
  },
  {
    label: 'Yelarbon, Queensland',
    value: 10003604,
  },
  {
    label: 'Yelbeni, Western Australia',
    value: 10009636,
  },
  {
    label: 'Yelgun, New South Wales',
    value: 10001607,
  },
  {
    label: 'Yellingbo, Victoria',
    value: 10006917,
  },
  {
    label: 'Yellow Rock, New South Wales',
    value: 10002399,
  },
  {
    label: 'Yellowdine, Western Australia',
    value: 10009468,
  },
  {
    label: 'Yelta, South Australia',
    value: 10005952,
  },
  {
    label: 'Yelverton, Western Australia',
    value: 10008980,
  },
  {
    label: 'Yenda, New South Wales',
    value: 10002221,
  },
  {
    label: 'Yenda, Queensland',
    value: 10004139,
  },
  {
    label: 'Yendon, Victoria',
    value: 10007388,
  },
  {
    label: 'Yengarie, Queensland',
    value: 10004246,
  },
  {
    label: 'Yennora, New South Wales',
    value: 10000533,
  },
  {
    label: 'Yeo, Victoria',
    value: 10007156,
  },
  {
    label: 'Yeodene, Victoria',
    value: 10007167,
  },
  {
    label: 'Yeoval, New South Wales',
    value: 10002632,
  },
  {
    label: 'Yeppoon, Queensland',
    value: 10004543,
  },
  {
    label: 'Yerecoin, Western Australia',
    value: 10009862,
  },
  {
    label: 'Yerong Creek, New South Wales',
    value: 10002101,
  },
  {
    label: 'Yeronga, Queensland',
    value: 10002949,
  },
  {
    label: 'Yerrinbool, New South Wales',
    value: 10001949,
  },
  {
    label: 'Yetholme, New South Wales',
    value: 10002460,
  },
  {
    label: 'Yetman, New South Wales',
    value: 10001263,
  },
  {
    label: 'Yetna, Western Australia',
    value: 10009795,
  },
  {
    label: 'Yilkari, Western Australia',
    value: 10009492,
  },
  {
    label: 'Yilliminning, Western Australia',
    value: 10009104,
  },
  {
    label: 'Yimbun, Queensland',
    value: 10003319,
  },
  {
    label: 'Yinnar, Victoria',
    value: 10008188,
  },
  {
    label: 'Yirrkala, Northern Territory',
    value: 10002796,
  },
  {
    label: 'Yoganup, Western Australia',
    value: 10008967,
  },
  {
    label: 'Yokine, Western Australia',
    value: 10008535,
  },
  {
    label: 'Yolla, Tasmania',
    value: 10006646,
  },
  {
    label: 'Yongala, South Australia',
    value: 10005903,
  },
  {
    label: 'Yoogali, New South Wales',
    value: 10002216,
  },
  {
    label: 'Yoongarillup, Western Australia',
    value: 10008983,
  },
  {
    label: 'York, Western Australia',
    value: 10009041,
  },
  {
    label: 'York Plains, Tasmania',
    value: 10006219,
  },
  {
    label: 'Yorke Island, New South Wales',
    value: 10010198,
  },
  {
    label: 'Yorketown, South Australia',
    value: 10005975,
  },
  {
    label: 'Yorkeys Knob, Queensland',
    value: 10005195,
  },
  {
    label: 'Yorkrakine, Western Australia',
    value: 10009426,
  },
  {
    label: 'Yornaning, Western Australia',
    value: 10009090,
  },
  {
    label: 'Yornup, Western Australia',
    value: 10008919,
  },
  {
    label: 'Yoting, Western Australia',
    value: 10009345,
  },
  {
    label: 'Youanmite, Victoria',
    value: 10007833,
  },
  {
    label: 'Youndegin, Western Australia',
    value: 10009420,
  },
  {
    label: 'Young, New South Wales',
    value: 10002027,
  },
  {
    label: 'Youngs Siding, Western Australia',
    value: 10009175,
  },
  {
    label: 'Youngtown, Tasmania',
    value: 10006383,
  },
  {
    label: 'Yourdamung Lake, Western Australia',
    value: 10008832,
  },
  {
    label: 'Yowah, Queensland',
    value: 10003782,
  },
  {
    label: 'Yowie Bay, New South Wales',
    value: 10000682,
  },
  {
    label: 'Yowrie, New South Wales',
    value: 10001866,
  },
  {
    label: 'Yuendumu, Northern Territory',
    value: 10002782,
  },
  {
    label: 'Yugar, Queensland',
    value: 10003863,
  },
  {
    label: 'Yulabilla, Queensland',
    value: 10003676,
  },
  {
    label: 'Yulara, Northern Territory',
    value: 10002793,
  },
  {
    label: 'Yuleba, Queensland',
    value: 10003704,
  },
  {
    label: 'Yuleba North, Queensland',
    value: 10003705,
  },
  {
    label: 'Yuleba South, Queensland',
    value: 10003706,
  },
  {
    label: 'Yumali, South Australia',
    value: 10005726,
  },
  {
    label: 'Yuna, Western Australia',
    value: 10009769,
  },
  {
    label: 'Yundool, Victoria',
    value: 10007988,
  },
  {
    label: 'Yungaburra, Queensland',
    value: 10005214,
  },
  {
    label: 'Yunta, South Australia',
    value: 10005878,
  },
  {
    label: 'Yuroke, Victoria',
    value: 10006788,
  },
  {
    label: 'Zanthus, Western Australia',
    value: 10009520,
  },
  {
    label: 'Zeehan, Tasmania',
    value: 10006674,
  },
  {
    label: 'Zetland, New South Wales',
    value: 10000219,
  },
  {
    label: 'Zillmere, Queensland',
    value: 10002863,
  },
  {
    label: 'Zilzie, Queensland',
    value: 10004578,
  },
  {
    label: 'Zuytdorp, Western Australia',
    value: 10009830,
  },
];
