import React from "react";
import { TextField, Input } from "@mui/material";
import { Colors } from "../../../theme/colors";

type Props = {};

const CssTextField = (props: any) => {
  return (
    <div>
      <Input
        
        inputProps={{
          style: {
            padding: 5,
          },
        }}
        sx={{
          color: Colors.Maximum_blue_purple,
          border: `1px solid ${Colors.lavender_blue}`,
          "&:focus-within": {
            borderColor: Colors.slate_blue,
            color: Colors.slate_blue,
          },
        }}
        {...props}
      />
    </div>
  );
};

export default CssTextField;
