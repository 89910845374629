import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip, Typography, CircularProgress } from '@mui/material';
import { Colors } from '../../theme/colors';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ReactComponent as CopyIcon } from '../../images/icons/copyIcon.svg';
import { useAppDispatch } from '../../redux/hooks';
import {
  InvitationData,
  checkEmailExits,
  userInvitationByRole,
} from '../../redux/invitation/invitation-slice';
import { useSelector } from 'react-redux';
import { toastSuccess, toastFailure } from '../../utils/toast';
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp';
import Selectbox from '../Utils/Selectbox/Selectbox';
import CancelIcon from '@mui/icons-material/Cancel';

const useStylesCss = makeStyles((theme: Theme) => ({
  root: {
    '&::-webkit-scrollbar': {
      width: 2,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${Colors.primary}`,
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      borderRadius: '12px',
    },
  },
  inputClass: {
    '::placeholder': {
      color: 'rgb(206, 212, 255)',
    },
  },
}));

interface PropsTypes {
  open: boolean;
  title: string;
  isCampaign: boolean;
  EnterpriseName?: string;
  CampaignName?: string;
  fkEnterpriseID?: number;
  fkJobID?: number;
  fkUserID?: number;
  pkCampaignID?: number;
  isSendMail?: boolean;
  setOpen: (data: boolean) => void;
  copyInvitationLink?: (isCopyInvite?: boolean) => void;
}

const InviteUser = (props: PropsTypes) => {
  const [isOpened, setOpened] = useState<boolean>(true);
  const [currValue, setCurrValue] = useState<any>('');
  // const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isNotValidEmail, setIsNotValidEmail] = useState<boolean>(false);
  const classes = useStylesCss();
  ////////// for MULTIPLE INPUT FIELD ////////
  const [values, setValues] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { userData } = useSelector((state: any) => state.user);
  const enterpriseData = useSelector((state: any) => state.enterprises);

  const [isCallInviteCandidateApi, setIsCallInviteCandidateApi] =
    useState<boolean>(false);
  const [anchorElOpen, setAnchorElOpen] = useState<null | HTMLElement>(null);
  const [selectedRole, setSelectedRole] = useState<any>();
  const [tempArray, setTempArray] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [isValidMail, setIsValidMail] = useState<boolean>(false);
  const [isExistEmail, setIsExistEmail] = useState<boolean>(false);

  const handleClose = () => {
    props.setOpen(false);
    setIsExistEmail(false);
    setIsValidMail(false);
    setValues([]);
    setInputValue('');
    setSelectedRole(null);
  };

  // useEffect(() => {
  //   const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  //   if (values.every((item) => validEmailRegex.test(item))) {
  //     setIsValidEmail(false);
  //   } else {
  //     setIsValidEmail(true);
  //   }
  // }, [values]);

  const handleSend = () => {
    let emails = values;
    if (!selectedRole) {
      toastFailure('Select a Access level of User');
      return;
    }
    if (!isValidMail && !isExistEmail) {
      setIsCallInviteCandidateApi(true);

      const paramsData = {
        FromName:
          (userData?.User_FirstName || '') +
          ' ' +
          (userData?.User_LastName || ''),
        FromMail: userData?.User_Email,
        Enterprise_Name: enterpriseData?.enterpris?.Enterprise_Name || '',
        bodyData: new Array(),
        enterpriseId: userData?.fk_Enterprise_ID,
        accesslevelId:
          selectedRole === 'Administrator'
            ? 3
            : selectedRole === 'Admin'
            ? 250
            : selectedRole === 'Hiring Manager'
            ? 270
            : 310,
        role: selectedRole,
      };

      emails.map((value, index) => {
        if (value) {
          paramsData.bodyData.push(value);
        }
      });

      dispatch(userInvitationByRole(paramsData))
        .unwrap()
        .then((originalPromiseResult: any) => {
          props.setOpen(false);
          setIsCallInviteCandidateApi(false);
          setValues([]);
          if (originalPromiseResult.response.status == 200) {
            if (originalPromiseResult.response.data.length <= 0) {
              toastFailure('Something went wrong.');
            } else {
              originalPromiseResult.response.data.map((res: any) => {
                res.map((response: any, index: number) => {
                  if (index == 1 && response != '200') {
                    // toastFailure(`Already mail sent to ${res[3]}.`);
                    toastSuccess(`Successfully mail sent`);
                  } else if (index == 1) {
                    toastSuccess(`Successfully mail sent to ${res[3]}.`);
                  }
                });
              });
            }
          } else {
            toastFailure('Something went wrong.');
          }
        })
        .catch((rejectedValueOrSerializedError: any) => {
          setIsCallInviteCandidateApi(false);
          props.setOpen(false);
          setValues([]);
          toastFailure('Something went wrong.');
          console.log('getting error', rejectedValueOrSerializedError);
        });
    }
    // let emails = values;
    // // let isValidMail = isValidEmail;
    // // if (emails.length <= 0) {
    // var inputValue = (
    //   document.getElementById('tags-filled') as HTMLInputElement
    // ).value;
    // if (inputValue) {
    //   setValues(inputValue.split(' '));
    //   emails = inputValue.split(' ');
    // }
    // // }
    // const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    // if (emails.every((item) => validEmailRegex.test(item))) {
    //   isValidMail = false;
    // } else {
    //   isValidMail = true;
    // }
    // console.log('email.em', emails);

    // if (!isValidMail && emails.length > 0) {
    //   setIsCallInviteCandidateApi(true);
    //   const paramsData = {
    //     FromName:
    //       (userData?.User_FirstName || '') +
    //       ' ' +
    //       (userData?.User_LastName || ''),
    //     FromMail: userData?.User_Email,
    //     Enterprise_Name: enterpriseData?.enterpris?.Enterprise_Name ||'',
    //     bodyData: new Array(),
    //     enterpriseId: userData?.fk_Enterprise_ID,
    //     accesslevelId:
    //       selectedRole === 'Owner'
    //         ? 220
    //         : selectedRole === 'Admin'
    //         ? 250
    //           : selectedRole === 'Limited' ? 270 :
    //         310,
    //     role: selectedRole,
    //   };

    // emails.map((value, index) => {
    //   if (value) {
    //     paramsData.bodyData.push(value);
    //   }
    // });

    // dispatch(userInvitationByRole(paramsData))
    //   .unwrap()
    //   .then((originalPromiseResult: any) => {
    //     props.setOpen(false);
    //     setIsCallInviteCandidateApi(false);
    //     setValues([]);
    //     if (originalPromiseResult.response.status == 200) {
    //       if (originalPromiseResult.response.data.length <= 0) {
    //         toastFailure('Something went wrong.');
    //       } else {
    //         originalPromiseResult.response.data.map((res: any) => {
    //           res.map((response: any, index: number) => {

    //             if (index == 1 && response != '200') {
    //               // toastFailure(`Already mail sent to ${res[3]}.`);
    //               toastSuccess(`Successfully mail sent`);

    //             } else if (index == 1) {
    //               toastSuccess(`Successfully mail sent to ${res[3]}.`);
    //             }
    //           });
    //         });
    //       }
    //     } else {
    //       toastFailure('Something went wrong.');
    //     }
    //   })
    //   .catch((rejectedValueOrSerializedError: any) => {
    //     setIsCallInviteCandidateApi(false);
    //     props.setOpen(false);
    //     setValues([]);
    //     toastFailure('Something went wrong.');
    //     console.log('getting error', rejectedValueOrSerializedError);
    //   });
    // }
  };
  const checkForSingleEmail = (newValue: any) => {
    if (newValue) {
      const trimmedValue = newValue.trim();
      if (trimmedValue) {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(trimmedValue);
        if (isValid) {
          setIsValidMail(false);
          //  setValues((prevValues) => [...prevValues, trimmedValue]);
          //  setInputValue('');
          const data = {
            emailId: trimmedValue,
            enterpriseID: userData?.fk_Enterprise_ID,
          };
          dispatch(checkEmailExits(data))
            .unwrap()
            .then((res: any) => {
              if (res.response.data === true) {
                setIsExistEmail(true);
              } else {
                setValues((prevValues) => [...prevValues, trimmedValue]);
                setInputValue('');
                setIsExistEmail(false);
              }
            })
            .catch((err) => {
              console.log('errr', err);
            });
        } else {
          setIsValidMail(true);
        }
      }
    }
  };
  const handleClickMainCategory = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElOpen(event.currentTarget);
  };
  const handleCloseMainCategory = () => {
    setAnchorElOpen(null);
  };
  const onSelectRole = (value: any) => {
    setSelectedRole(value);
    setAnchorElOpen(null);
  };

  const handleChange = (e: any) => {
    if (e.key !== ' ' || e.key === 'Enter') {
      setInputValue(e.target.value);
    }
  };

  const handleInputKeyDownCustom = (e: any) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(trimmedValue);
        if (isValid) {
          setIsValidMail(false);
          //  setValues((prevValues) => [...prevValues, trimmedValue]);
          //  setInputValue('');
          const data = {
            emailId: trimmedValue,
            enterpriseID: userData?.fk_Enterprise_ID,
          };
          dispatch(checkEmailExits(data))
            .unwrap()
            .then((res: any) => {
              if (res.response.data === true) {
                setIsExistEmail(true);
              } else {
                setValues((prevValues) => [...prevValues, trimmedValue]);
                setInputValue('');
                setIsExistEmail(false);
              }
            })
            .catch((err) => {
              console.log('errr', err);
            });
        } else {
          setIsValidMail(true);
        }
      }
    }
  };

  const removeChip = (index: number) => {
    const testValues = Array.from(values);
    testValues.splice(index, 1);
    setValues(testValues);
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '0px' }}>
          <Box
            sx={{
              width: '400px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                color: Colors.philippine_gray,
              }}
              fontWeight={400}
            >
              Enter the email id of the user to invite.
            </Typography>

            <Box
              sx={{
                // flexGrow: 1,
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                // justifyContent: 'center',
                // alignItems: 'center',
                border: 'none',
                marginTop: '5px',
              }}
            >
              {/* <Autocomplete
                sx={{ border: 'none' }}
                fullWidth
                multiple
                id="tags-filled"
                options={[]}
                defaultValue={[]}
                onChange={(e, value) => {
                  console.log("jkjkjkjkjjkjk");
                  
                  setValues(value);
                }}
                freeSolo
                renderTags={(
                  value: any[],
                  getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
                ) => (
                  <>
                    {values.map((itm: any, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        variant="outlined"
                        label={itm}
                        color={tempArray[index]?.value ? 'error' : 'primary'}
                        // onDelete={() => handleDeleteEmail(index)}
                      />
                    ))}
                  </>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={isValidEmail}
                    placeholder="Enter a Email"
                    type="text"
                    helperText={
                      isValidEmail ? 'Please Enter a valid email' : ''
                    }
                    
                  />
                )}
              /> */}
              <Box
                sx={{
                  width: '94%',
                  borderRadius: '5px',
                  padding: '10px',
                  color: 'rgb(156, 167, 248)',
                  border: '1px solid rgb(206, 212, 255)',
                  display: 'flex',
                  gap: '10px',
                  flexWrap: 'wrap',
                }}
              >
                {values?.map((item, index) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '5px',
                        border: '1px solid rgb(68, 91, 107)',
                        borderRadius: '20px',
                        width: 'auto',
                        gap: '5px',
                        alignItems: 'center',
                      }}
                      key={index}
                    >
                      <Typography
                        sx={{
                          paddingTop: '0px !important',
                          fontSize: '14px',
                          color: 'rgb(68, 91, 107)',
                        }}
                      >
                        {item}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => removeChip(index)}
                      >
                        <CancelIcon style={{ color: 'rgb(68, 91, 107)' }} />
                      </Box>
                    </Box>
                  );
                })}

                <Typography
                  sx={{
                    width: `${values.length === 0 ? '100%' : '270px'}`,
                    paddingTop: '0px !important',
                  }}
                >
                  <input
                    type='text'
                    className={classes.inputClass}
                    style={{
                      outline: 'none',
                      border: 'none',
                      width: '90%',
                      fontSize: '18px',
                      fontStyle: 'Poppins',
                      height: '100%',
                      color: Colors.slate_blue1,
                    }}
                    placeholder='Enter a Email'
                    value={inputValue}
                    onChange={(e) => {
                      handleChange(e);
                      setIsExistEmail(false);
                      setIsValidMail(false);
                    }}
                    onKeyDown={handleInputKeyDownCustom}
                    onBlur={() => {
                      checkForSingleEmail(inputValue);
                    }}
                  />
                  {/* <TextField
                variant="outlined"
                placeholder="Type and press spacebar"
                // value={inputValue}
                // onChange={handleInputChange}
                // onKeyDown={handleInputKeyDown}
              /> */}
                </Typography>
              </Box>
              {isValidMail && (
                <>
                  <Box
                    sx={{
                      paddingLeft: '5px',
                      // fontFamily: 'Poppins',
                      color: 'red',
                      fontWeight: 400,
                      display: 'flex',
                      alignItems: 'start',
                      justifyContent: 'start',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px !important' }}>
                      Please Enter a valid email
                    </Typography>
                  </Box>
                </>
              )}
              {isExistEmail && (
                <>
                  <Box
                    sx={{
                      paddingLeft: '5px',
                      // fontFamily: 'Poppins',

                      color: 'red',
                      fontWeight: 400,
                    }}
                  >
                    <Typography sx={{ fontSize: '14px !important' }}>
                      Email is already exist
                    </Typography>
                  </Box>
                </>
              )}

              <Typography
                sx={{
                  mt: '5px',
                  width: '100%',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  color: Colors.philippine_gray,
                }}
              >
                Select Access level of user
              </Typography>
              <Selectbox
                handleClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  handleClickMainCategory(e);
                }}
                handleClose={() => {
                  handleCloseMainCategory();
                }}
                changeBoxValue={(val) => {
                  onSelectRole(val);
                }}
                open={true}
                anchorEl={anchorElOpen}
                isCreatePage={true}
                isNotBorder={true}
                title={selectedRole || 'Select a role'}
                menuOption={['Administrator', 'Hiring Manager']}
              />
              {/* <Box
                sx={{
                  fontSize: '18px',
                  color: Colors.philippine_gray,
                  fontWeight: 500,
                  marginTop: '14px',
                }}
              >
                or
              </Box>
              <Button
                startIcon={<ContentCopySharpIcon />}
                sx={{ marginTop: '5px' }}
                onClick={() => {
                  if (props.copyInvitationLink) {
                    props.copyInvitationLink();
                  }
                  handleClose();
                }}
              >
                Copy Link
              </Button> */}
            </Box>
          </Box>
        </DialogContent>

        <Box
          sx={{
            padding: '20px 24px',
            display: 'flex',
            columnGap: '8px',
          }}
        >
          <Box
            sx={{
              width: '50%',
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              sx={{
                padding: 0,
                marginRight: '16px',
                color: Colors.slate_blue1,
                background: Colors.anti_flash_white,
                ':hover, :focus, :active': {
                  background: Colors.anti_flash_white,
                },
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </Box>
          <Tooltip
            title={isValidMail || isExistEmail ? 'Disabled' : 'Send Invite'}
          >
            <Box
              sx={{
                width: '50%',
              }}
            >
              <Button
                variant='contained'
                color='primary'
                // disabled={isValidEmail || values.length <= 0}
                disabled={isCallInviteCandidateApi}
                onClick={() => {
                  if (!isCallInviteCandidateApi) {
                    handleSend();
                  }
                }}
              >
                {isCallInviteCandidateApi ? (
                  <>
                    <CircularProgress
                      style={{
                        width: '30px',
                        height: '30px',
                      }}
                      color='inherit'
                    />
                  </>
                ) : (
                  'Send'
                )}
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Dialog>
    </>
  );
};

export default InviteUser;
