import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { toastFailure, toastSuccess } from '../../utils/toast';
import { IClients } from '../../modals/modals';
import { getAllCampaignLineCandidates } from '../../api/candidate';
import { searchUser } from '../../api/auth';
import {
  AddEmployerImage,
  createEmployerClient,
  getAllEmployers,
  getAllEmployersByEnterprisse,
  updateEmployerClient,
} from '../../api/clients';

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export const fetchAllClients = createAsyncThunk(
  'clients/fetch',
  async (obj: any) => {
    return {
      clientsResponse: await getAllEmployers(),
    };
  }
);

export const fetchAllEmployerByEnterprise = createAsyncThunk(
  'clients/fetchByEnterprise',
  async (obj: any) => {
    return {
      employerResponse: await getAllEmployersByEnterprisse(obj),
    };
  }
);

export const createEmployer = createAsyncThunk(
  'clients/createEmployer',
  async (data: any) => {
    return { response: await createEmployerClient(data) };
  }
);

export const addEmployersImage = createAsyncThunk(
  'clients/addimage',
  async (object: any) => {
    return {
      response: await AddEmployerImage(object),
    };
  }
);

export const updateEmployer = createAsyncThunk(
  'clients/updateEmployer',
  async (data: any) => {
    return { response: await updateEmployerClient(data) };
  }
);

export interface clientState {
  clientsList: IClients[] | null;
  employersList: IClients[] | null;
}

const initialState: clientState = {
  clientsList: null,
  employersList: null,
};

export const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    allClientsreducer: (state, action) => {
      state.clientsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllClients.fulfilled, (state, action) => {
      // console.log('PAYLOAD', action.payload);
      if (action.payload.clientsResponse.successful) {
        state.clientsList = action.payload.clientsResponse.data.reverse();
      } else {
        if (!action.payload.clientsResponse.successful) {
          toastFailure(action.payload.clientsResponse.message);
          state.clientsList = [];
        }
      }
    });

    builder.addCase(fetchAllEmployerByEnterprise.fulfilled, (state, action) => {
      if (action.payload.employerResponse.successful) {
        state.employersList = action.payload.employerResponse.data.reverse();
      } else {
        if (!action.payload.employerResponse.successful) {
          toastFailure(action.payload.employerResponse.message);
          state.employersList = [];
        }
      }
    });

    ///Create Campaigns
    builder.addCase(createEmployer.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        // toastSuccess(`Employer created ${action.payload.response.data}`);
        toastSuccess(`Employer created`);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    ///Update Employer
    builder.addCase(updateEmployer.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        // toastSuccess(`Employer created ${action.payload.response.data}`);
        toastSuccess(`${action.payload.response.data}`);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    ///Add Employer Image

    builder.addCase(addEmployersImage.fulfilled, (state, action) => {
      // console.log(action.payload, 'action.payload');
    });
  },
});
export default clientSlice.reducer;
