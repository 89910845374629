import React from "react";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import { ReactComponent as ErrorIcon } from "../images/ErrorIcon.svg";
import { ReactComponent as SuccessIcon } from "../images/SucessIcon.svg";

const defaultArguments: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toastInfo = (content: ToastContent): void => {
  toast.info(content, defaultArguments);
};

export const toastWarn = (content: ToastContent): void => {
  toast.warn(content, defaultArguments);
};

export const toastSuccess = (content: ToastContent): void => {
  toast.success(content, {
    ...defaultArguments,
    className: "toast-sucess",
    icon: () => <SuccessIcon />,
  });
};

export const toastFailure = (content: ToastContent): void => {
  toast.error(content, {
    ...defaultArguments,
    className: "toast-error",
    icon: () => <ErrorIcon />,
  });
};
