import React from 'react';
import { Box, Popover, Typography } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Colors } from '../../../theme/colors';
import {
  formatDateMonthToString,
  formatStringToDate,
} from '../../../utils/dateFormatter';
import CustomCalendarNew from '../../customCalendar/CustomCalenderNew';
import { ReactComponent as CalenderIcon } from '../../../images/icons/CalenderIcon.svg';
import { useLocation } from 'react-router-dom';

interface Props {
  isEdit?: boolean;
  startDate: string;
  endDate: string;
  pageType?: string;
  setStartDate: (startDate: string) => void;
  setEndDate: (startDate: string) => void;
  commencementDate: (date: Date) => void;
  completionDate: (date: Date) => void;
}
const DateSelectorNew = (props: Props) => {
  const location = useLocation();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: props.pageType === 'create' ? '15px' : '15px',
          placeContent: 'end',
          pointerEvents: props.pageType === 'create' ? 'auto' : 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: props.pageType === 'create' ? '140px' : '130px',
          }}
        >
          <Typography
            sx={{ marginTop: '15px' }}
            color={Colors.black}
            fontSize={'14px'}
            variant='h6'
          >
            Start date
          </Typography>
          <Box
            sx={{
              height: '40px',
              background: Colors.background_grey,
              borderRadius: '8px',
            }}
          >
            <PopupState variant='popover' popupId='startDate'>
              {(popupState: any) => (
                <>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '100%',
                      borderRadius: 2,
                      alignItems: 'center',
                      cursor: 'pointer',
                      paddingX: '8px',
                    }}
                    {...bindTrigger(popupState)}
                  >
                    <Box>
                      <Typography
                        variant={'body1'}
                        color={Colors.grey}
                        sx={{
                          fontSize: 12,
                          padding: 0,
                          color: Colors.outerSpace,
                        }}
                      >
                        {props.startDate == 'Invalid date'
                          ? formatDateMonthToString(new Date())
                          : props.startDate}
                      </Typography>
                    </Box>
                    <CalenderIcon
                      style={{
                        width: props.pageType === 'edit' ? '14px' : '18px',
                      }}
                    />
                  </Box>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    style={{ borderRadius: 20 }}
                  >
                    <CustomCalendarNew
                      onClose={popupState.close}
                      date={props.startDate}
                      onApply={(startDate: string) => {
                        props.commencementDate(formatStringToDate(startDate));
                        props.setStartDate(startDate);
                        popupState.close();
                      }}
                    />
                  </Popover>
                </>
              )}
            </PopupState>
          </Box>
        </Box>
        {/* COMMENT ENDDATE */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: props.pageType === 'create' ? '140px' : '130px',
          }}
        >
          <Typography
            sx={{ marginTop: '15px' }}
            color={Colors.black}
            fontSize={'14px'}
            variant='h6'
          >
            End date
          </Typography>
          <Box
            sx={{
              height: '40px',
              background: Colors.background_grey,
              borderRadius: '8px',
            }}
          >
            <PopupState variant='popover' popupId='endDate'>
              {(popupState: any) => (
                <>
                  <Box
                    sx={{
                      backgroundColor: 'transparent',
                      justifyContent: 'space-between',
                      display: 'flex',
                      paddingX: '8px',
                      height: '100%',
                      borderRadius: 2,
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    {...bindTrigger(popupState)}
                  >
                    <Typography
                      variant={'body1'}
                      color={Colors.grey}
                      sx={{
                        fontSize: 12,
                        padding: 0,
                        color: Colors.outerSpace,
                      }}
                    >
                      {props.endDate == 'Invalid date'
                        ? formatDateMonthToString(new Date())
                        : props.endDate}
                    </Typography>
                    <CalenderIcon
                      style={{
                        width: props.pageType === 'edit' ? '14px' : '18px',
                      }}
                    />
                  </Box>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    style={{ borderRadius: 20 }}
                  >
                    <CustomCalendarNew
                      onClose={popupState.close}
                      date={props.endDate}
                      onApply={(endDate: string) => {
                        props.completionDate(formatStringToDate(endDate));
                        props.setEndDate(endDate);
                        popupState.close();
                      }}
                      minDate={
                        props.startDate
                          ? formatStringToDate(props.startDate)
                          : new Date()
                      }
                    />
                  </Popover>
                </>
              )}
            </PopupState>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DateSelectorNew;
