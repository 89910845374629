import moment from 'moment';

export const formatDateToString = (date: Date | string) => {
  return moment(new Date(date)).format('DD[th] MMM, YYYY').toString();
};

export const formatDateAndTimeToString = (date: Date) => {
  return moment(new Date(date)).format('DD[th] MMM, HH:mm').toString();
};

export const formatDateMonthToString = (date: Date) => {
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth();
  return moment(new Date(year, month, day))
    .format('DD[th] MMM,YYYY')
    .toString();
};

export const formatStringToDate = (date: string | Date) => {
  const stringDate = date == 'Invalid date' ? '30th Jan,2023' : date;
  const dateString = String(stringDate).split('th');
  const day = dateString[0];
  const month = dateString[1].split(',')[0];
  const year = dateString[1].split(',')[1];

  const formattedDate = `${year}-${month}-${day}`;

  return new Date(formattedDate);
};

export const formatDateToStringForPayoad = (date: Date) => {
  const newDate = date
    .toLocaleDateString()
    .toString()
    .replace('/', '-')
    .replace('/', '-');
  return newDate;
};

export const formatDateStringForPayoad = (date: Date) => {
  return moment(date).format('MM-DD-YYYY');
};

export const formatStringToDateRange = (dateList: any) => {
  let converted: any = [];
  dateList.map((date: any) => {
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth();
    converted.push(
      moment(new Date(year, month, day))
        .format('DD[th] of MMM, YYYY')
        .toString()
    );
  });
  return converted;
};

export const checkMonthsFromDate = (initialDate: any) => {
  const monthDifference = Math.floor(
    moment(new Date()).diff(new Date(initialDate), 'months', true)
  );

  return monthDifference;
};
