import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Menu,
  Typography,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { AccountCircleOutlined, CameraAltOutlined } from '@mui/icons-material';
import { Colors } from '../../../theme/colors';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as CameraIcon } from '../../../images/icons/cameraicon.svg';
import { Urls } from '../../../utils/urls';

interface IUserMenuProps {
  isHeader: boolean;
  User_Initials?: string;
  User_BackgroundColour?: string;
  User_Colour?: string;
  User_FirstName?: string;
  User_SecondName?: string;
  isAnswerTab?: boolean;
}

const UserMenu = (props: IUserMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElPrivacy, setAnchorElPrivacy] =
    React.useState<null | HTMLElement>(null);
  const [anchorElTerms, setAnchorElPrivacyTerms] =
    React.useState<null | HTMLElement>(null);
  const userData = useSelector((state: any) => state.user);
  const enterpriseData = useSelector((state: any) => state.enterprises);
  const open = Boolean(anchorEl);
  const openPrivacy = Boolean(anchorElPrivacy);
  const openTerms = Boolean(anchorElTerms);
  const navigate = useNavigate();
  const [enterpriseName, setEnterpriseName] = useState<string>('');
  const [enterprise, setEnterprise] = useState();

  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  // const [openPrivacyModal, setOpenPrivacyModal] =
  //   React.useState<boolean>(false);
  const clickOpenEditModal = () => {
    // setOpenEditModal(true);
    handleClose();
    navigate(Urls.CompanyInformation);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
  };
  // const handlePrivacyModal = () => {
  //   setOpenPrivacyModal(true);
  // };

  // const closePrivacyModal = () => {
  //   setOpenPrivacyModal(false);
  // };

  const closePrivacyModal = () => {
    setAnchorElPrivacy(null);
  };
  const closeTermsModal = () => {
    setAnchorElPrivacyTerms(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props?.isHeader) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isImage = (url: any) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };
  useEffect(() => {
    if (enterpriseData.enterprises) {
      const name = enterpriseData?.enterprises?.filter((item: any) => {
        if (item?.pk_Enterprise_ID === userData?.userData?.fk_Enterprise_ID) {
          return item.Enterprise_Name;
        }
      });
      if (name[0]) {
        setEnterpriseName(name[0].Enterprise_Name);
        setEnterprise(name[0]);
      }
    }
  }, [enterpriseData.enterprises, userData]);

  return (
    <>
      <Tooltip
        title={
          !props?.isHeader
            ? props?.isAnswerTab
              ? `${props?.User_FirstName} ${props?.User_SecondName}`
              : `${userData?.userData?.User_FirstName} ${userData?.userData?.User_SecondName}`
            : ''
        }
      >
        <IconButton
          size={'small'}
          sx={{
            padding: props?.isHeader ? '5px' : '0px',
            color: Colors.primary,
          }}
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Box
            sx={{
              height: props?.isHeader ? '40px' : '26px',
              width: props?.isHeader ? '40px' : '26px',
              fontSize: props?.isHeader
                ? userData?.userData?.User_Initials?.length <= 2
                  ? '1.25rem'
                  : '13px'
                : '8px',
              borderRadius: '50% !important',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 600,
              background: props?.isAnswerTab
                ? props?.User_BackgroundColour
                : userData?.userData?.User_BackgroundColour !== ''
                ? userData?.userData?.User_BackgroundColour
                : Colors.Maximum_blue_purple,
              color: props?.isAnswerTab
                ? props?.User_Colour
                : userData?.userData?.User_Colour !== ''
                ? userData?.userData?.User_Colour
                : Colors.white,
              textTransform: 'uppercase',
            }}
          >
            {props?.isHeader &&
            userData?.userData?.User_AvatarEnable &&
            isImage(userData.userData.User_ImageLink) ? (
              <>
                <img
                  src={userData?.userData?.User_ImageLink}
                  alt={userData?.userData?.User_Initials}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </>
            ) : (
              <>
                {props?.isAnswerTab
                  ? props?.User_Initials
                  : userData?.userData?.User_Initials}
              </>
            )}
          </Box>
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            minWidth: '260px',
            borderRadius: '10px',
            padding: '16px 20px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
          },
        }}
        onClose={handleClose}
        // onClick={handleClose}
        anchorEl={anchorEl}
        id='user-detail-menu'
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* <div
            style={{
              background: Colors.bright_gray,
              width: '65px',
              height: '65px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CameraIcon />
          </div> */}
          <Box
            sx={{
              height: '65px',
              width: '65px',
              borderRadius: '50% !important',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 600,
              fontSize: '18px',
              background: userData?.userData?.User_AvatarEnable
                ? 'white'
                : userData?.userData?.User_BackgroundColour !== ''
                ? userData?.userData?.User_BackgroundColour
                : Colors.Maximum_blue_purple,
              color:
                userData?.userData?.User_Colour !== ''
                  ? userData?.userData?.User_Colour
                  : Colors.white,
              textTransform: 'uppercase',
            }}
            onClick={() => navigate(Urls.User)}
          >
            {userData?.userData?.User_AvatarEnable ? (
              userData?.userData?.User_ImageLink !== null ||
              userData?.userData?.User_ImageLink !== '' ? (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                    }}
                  >
                    <img
                      src={userData?.userData?.User_ImageLink}
                      alt='User_profile'
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                </>
              ) : (
                <Box>
                  {userData?.userData?.User_Initials
                    ? userData?.userData?.User_Initials
                    : userData?.userData?.User_FirstName?.charAt(0) +
                      userData?.userData?.User_SecondName?.charAt(0)}
                </Box>
              )
            ) : (
              <>
                <Box>
                  {userData?.userData?.User_Initials
                    ? userData?.userData?.User_Initials
                    : userData?.userData?.User_FirstName?.charAt(0) +
                      userData?.userData?.User_SecondName?.charAt(0)}
                </Box>
              </>
            )}
          </Box>
          <Typography
            color={Colors.slate_blue1}
            style={{
              cursor: 'pointer',
              marginTop: '7px',
              wordWrap: 'break-word',
              maxWidth: '280px',
            }}
            fontSize='14px'
            fontWeight={600}
            onClick={() => {
              navigate(Urls.User);
              handleClose();
            }}
          >
            {userData?.userData?.User_Email || 'User Email'}
          </Typography>
          <Box
            onClick={() => {
              if (enterprise) {
                const item = Object.assign(enterprise);
                if (
                  userData?.userData?.User_AccessLevel !== 270 &&
                  userData?.userData?.User_AccessLevel !== 3
                ) {
                  navigate(Urls.CompanyInformation);
                  // navigate(Urls.EditCompanyInformation, {
                  //   state: { item },
                  // });
                  handleClose();
                }
              }
            }}
          >
            <Typography
              color={Colors.philippine_gray}
              style={{ cursor: 'pointer', paddingTop: '0px' }}
              fontSize='12px'
              fontWeight={500}
            >
              {enterpriseName}
            </Typography>
          </Box>
          {userData?.userData?.User_AccessLevel !== 270 && (
            <Typography
              color={Colors.outerSpace}
              style={{ cursor: 'pointer', marginTop: '38px' }}
              fontSize='14px'
              fontWeight={500}
              onClick={clickOpenEditModal}
            >
              Edit Enterprise Account
            </Typography>
          )}
          {userData?.userData?.User_AccessLevel !== 3 &&
            userData?.userData?.fk_Enterprise_ID && (
              <Typography
                color={Colors.outerSpace}
                style={{ cursor: 'pointer', marginTop: '8px' }}
                fontSize='14px'
                fontWeight={500}
                onClick={() => {
                  navigate(Urls.Clients);
                  handleClose();
                }}
              >
                Client Preferences
              </Typography>
            )}

          {userData?.userData?.User_AccessLevel !== 270 &&
            userData?.userData?.User_AccessLevel !== 3 && (
              <Typography
                color={Colors.outerSpace}
                style={{ cursor: 'pointer', marginTop: '5px' }}
                fontSize='14px'
                fontWeight={500}
                onClick={() => {
                  navigate(Urls.SelectPackages);
                  handleClose();
                }}
              >
                Select Package
              </Typography>
            )}
          {/* <Typography
            color={Colors.outerSpace}
            style={{ cursor: 'pointer', marginTop: '5px' }}
            fontSize='14px'
            fontWeight={500}
            onClick={() => {
              navigate(Urls.BillingMethod);
              handleClose();
            }}
          >
            Billing Method
          </Typography> */}
          {/* <Typography
            color={Colors.outerSpace}
            style={{ cursor: 'pointer', marginTop: '38px' }}
            fontSize="14px"
            fontWeight={500}
          >
            Pricing plan
          </Typography>
          <Typography
            color={Colors.outerSpace}
            style={{ cursor: 'pointer', marginTop: '38px' }}
            fontSize="14px"
            fontWeight={500}
          >
            Upgrade
          </Typography> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '38px',
            }}
          >
            <div>
              <Typography
                color={Colors.outerSpace}
                style={{ cursor: 'pointer' }}
                fontSize='11px'
                onClick={() => {
                  navigate(Urls.Privacypage);
                  handleClose();
                }}
                id='basic-button'
                aria-controls={openPrivacy ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openPrivacy ? 'true' : undefined}
              >
                Privacy Policy
              </Typography>
            </div>
            <div>
              <Typography
                color={Colors.outerSpace}
                style={{ cursor: 'pointer' }}
                fontSize='11px'
                id='terms-details'
                aria-controls={openTerms ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openTerms ? 'true' : undefined}
                onClick={() => {
                  navigate(Urls.TermsofSrvice);
                  handleClose();
                }}
              >
                Terms of Service
              </Typography>
            </div>
          </div>
        </div>
        <Dialog
          open={openEditModal}
          onClose={closeEditModal}
          PaperProps={{
            style: {
              borderRadius: '12px',
            },
          }}
        >
          <DialogContent sx={{ paddingBottom: '0px' }}>
            <Box
              sx={{
                width: '400px',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  color: Colors.philippine_gray,
                }}
                fontWeight={400}
              >
                This feature is currently unavailable,
                <br /> please check back again soon
              </Typography>

              <Box
                sx={{
                  // flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none',
                  marginTop: '5px',
                }}
              ></Box>
            </Box>
          </DialogContent>

          <Box
            sx={{
              padding: '20px 24px',
              display: 'flex',
              columnGap: '8px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
              }}
            >
              <Button
                variant={'contained'}
                color={'primary'}
                sx={{
                  padding: 0,
                  marginRight: '16px',
                  color: Colors.slate_blue1,
                  background: Colors.anti_flash_white,
                  ':hover, :focus, :active': {
                    background: Colors.anti_flash_white,
                  },
                }}
                onClick={() => {
                  closeEditModal();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Menu
          open={openPrivacy}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              minWidth: '160px',
              borderRadius: '10px',
              padding: '2px 2px',
              marginTop: '2px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
            },
          }}
          onClose={closePrivacyModal}
          onClick={closePrivacyModal}
          anchorEl={anchorElPrivacy}
          id='notification-detail'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: Colors.outerSpace,
                padding: '2px',
              }}
            >
              Coming soon
            </Typography>
          </div>
        </Menu>
        <Menu
          open={openTerms}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={{
            sx: {
              minWidth: '160px',
              borderRadius: '10px',
              padding: '2px 2px',
              marginTop: '2px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
            },
          }}
          onClose={closeTermsModal}
          onClick={closeTermsModal}
          anchorEl={anchorElTerms}
          id='privacy-details'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: Colors.outerSpace,
                padding: '2px',
              }}
            >
              Coming soon
            </Typography>
          </div>
        </Menu>
      </Menu>
    </>
  );
};

export default UserMenu;
