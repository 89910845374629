import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../theme/colors";
import { TextInput } from "../../ui/InputFields";
import { ReactComponent as LocationIcon } from "../../images/icons/LocationIcon.svg";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import PhoneInput from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import "react-phone-number-input/style.css";
import { parsePhoneNumber } from "react-phone-number-input";

const ContactInfo = ({ dataObj, handleChange }: any) => {
  // COUNTRY SELECT
  const CountrySelect = ({ value, onChange, labels, ...rest }: any) => (
    <select
      {...rest}
      style={{
        width: "100px !important",
      }}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]}
        </option>
      ))}
    </select>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  // COUNTRY CODE SELECT
  const CountryCodeSelect = ({ value, onChange, labels, ...rest }: any) => (
    <select
      {...rest}
      style={{
        width: "100px !important",
      }}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          +{getCountryCallingCode(country)} {labels[country]}
        </option>
      ))}
    </select>
  );

  CountryCodeSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          paddingBottom: "20px",
        }}
      >
        {/* Phone Number Countrycode  1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Phone Number Countrycode
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          >
            <img
              style={{ width: "40px", height: "20px" }}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
                dataObj.Enterprise_PhoneNumber_CountryCode || "AU"
              }.svg`}
            />
            <CountryCodeSelect
              labels={en}
              value={dataObj.Enterprise_PhoneNumber_CountryCode || "AU"}
              onChange={(value) => {
                handleChange(
                  value,
                  "select",
                  "Enterprise_PhoneNumber_CountryCode"
                );
              }}
            />
          </Box>
        </Box>

        {/* Phone Number Areacode */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Phone Number Areacode
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          > */}
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Phone Number Areacode`}
            value={dataObj.Enterprise_PhoneNumber_AreaCode || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) =>
              handleChange(value, "text", "Enterprise_PhoneNumber_AreaCode")
            }
          />
          {/* </Box> */}
        </Box>

        {/* Phone Number */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Phone Number
          </Typography>
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Phone Number`}
            value={dataObj.Enterprise_PhoneNumber || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) => {
              handleChange(value, "number", "Enterprise_PhoneNumber");
            }}
          />
          {/* </Box> */}
        </Box>

        {/* Phone Number Extensiom */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Phone Number Extention
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          > */}
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Phone Number Areacode`}
            value={dataObj.Enterprise_PhoneNumber_Extention || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) => {
              handleChange(value, "text", "Enterprise_PhoneNumber_Extention");
            }}
          />
          {/* </Box> */}
        </Box>

        {/* Phone Number Countrycode  1 */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Alternate Number Countrycode
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          >
            <img
              style={{ width: "40px", height: "20px" }}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
                dataObj.Enterprise_AlternateNumber_CountryCode || "AU"
              }.svg`}
            />
            <CountryCodeSelect
              labels={en}
              value={dataObj.Enterprise_AlternateNumber_CountryCode || "AU"}
              onChange={(value) => {
                handleChange(
                  value,
                  "select",
                  "Enterprise_AlternateNumber_CountryCode"
                );
              }}
            />
          </Box>
        </Box>

        {/* Phone Number Extensiom */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Alternate Number Areacode
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          > */}
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Alternate Number Areacode`}
            value={dataObj.Enterprise_AlternateNumber_AreaCode || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) => {
              handleChange(
                value,
                "text",
                "Enterprise_AlternateNumber_AreaCode"
              );
            }}
          />
          {/* </Box> */}
        </Box>

        {/* Alternate Number */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Alternate Number
          </Typography>
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Phone Number`}
            value={dataObj.Enterprise_AlternateNumber || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) => {
              handleChange(value, "number", "Enterprise_AlternateNumber");
            }}
          />
        </Box>

        {/*   Alternate Number Extention */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Alternate Number Extention
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          > */}
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Phone Number Areacode`}
            value={dataObj.Enterprise_AlternateNumber_Extention || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) =>
              handleChange(
                value,
                "text",
                "Enterprise_AlternateNumber_Extention"
              )
            }
          />
          {/* </Box> */}
        </Box>

        {/* Email */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            marginRight: "45px",
            marginTop: "45px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: "7px",
            }}
          >
            Email
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: `1px solid ${Colors.default}`,
              height: "50px",
              borderRadius: "6px",
              padding: "0px 10px",
            }}
          > */}
          <TextInput
            type={"text"}
            maxWidth="300px"
            height="50px"
            placeholder={`Enter Phone Number Areacode`}
            value={dataObj.Enterprise_Email || ""}
            border={`1px solid ${Colors.default}`}
            padding={"0px 10px"}
            onChange={(value) =>
              handleChange(value, "text", "Enterprise_Email")
            }
          />
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default ContactInfo;
