import {
  Campaigns,
  CampaignsCreate,
  CampaignsUpdate,
  CampaignLineUpdate,
  CampaignsUpdateNew,
  ISettingResponse,
} from '../modals/modals';
import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';

export const getAllCampaigns = (): Promise<Response<Campaigns[]>> =>
  generalMiddleware.request({
    url: '/Campaign',
    method: 'GET',
  });
export const getAllCampaignsByEnterprise = (
  id: number
): Promise<Response<Campaigns[]>> =>
  generalMiddleware.request({
    url: `/Campaign?Enterprise_ID=${id}&SearchText=`,
    method: 'GET',
  });

export const getCampaignById = (id: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Campaign/${id}`,
    method: 'GET',
  });
export const getCampaignDetails = (data: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/QuestionResponse/getQeustionResponseByCampaignLineID/${data.pk_Campaign_ID}/${data.pk_userId}`,
    method: 'GET',
  });
export const getResumeFromCampaignLine = (id: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/CandidateResume/GetByCampaignLine?campaignLineId=${id}`,
    method: 'GET',
  });
export const getInterviewVideosByUser = (id: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/InterviewVideo/GetByCampaignLine?campaignLineId=${id}`,
    method: 'GET',
  });
export const getAllActiveCampaign = (id: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Dashboard/GetAllActiveCampaign?enterprise_ID=${id}`,
    method: 'GET',
  });

export const createCampaign = (
  campaign: CampaignsCreate
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/Campaign',
    method: 'POST',
    data: campaign,
  });

export const updateCampaign = (
  campaign: CampaignsUpdate
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Campaign`,
    method: 'PUT',
    data: campaign,
  });

export const cloneCampagin = (campaignId: number): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Campaign/CloneJob/${campaignId}`,
    method: 'GET',
  });

export const campaignLineByCampaignID = (
  data: CampaignsUpdateNew
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/CampaignLine/GetCampaigLineResponseByCampaignID?CampaignID=${data.pk_Campaign_ID}&UserID=${data.pk_userId}`,
    method: 'GET',
  });
export const getCampaignLineByCampaignID = (
  id: number
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/CampaignLine/${id}`,
    method: 'GET',
  });

export const updateCampaignLineStatusById = (
  data: CampaignLineUpdate
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/CampaignLine/UpdateCampaignLineStatus?pk_CampaignLine_ID=${data.pk_CampaignLine_ID}&CampaignLine_Status=${data.status}`,
    method: 'PUT',
  });

export const campaignInterviewVideo = (
  campaignId: CampaignsUpdate
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/QuestionResponse/getQuestionResponseVideos/${campaignId}`,
    method: 'GET',
  });

export const getJobMainCategories = (): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/SeekJob`,
    method: 'GET',
  });
export const getJobSubCategories = (id: number): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/SeekJob/GetJobCategory?jobId=${id}`,
    method: 'GET',
  });

export const getSeekIdibuJobs = (data: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/SeekJob/GetApplicants?fk_Enterprise_ID=${data.pk_Enterprise_ID}&fk_Campaign_ID=${data.pk_Campaign_ID}`,
    method: 'GET',
  });

export const getJobLocationRegion = (): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Job/GetJobLocationRegion`,
    method: 'GET',
  });

export const getJobLocationByIdibu = (): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `https://www.idibu.com/clients/upp/getlocation.php?hash=f9091e6f36c4ec28fb63057c48f41528&ccode=AU`,
    method: 'GET',
  });

export const getInvitesAndInterview = (id: number): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Campaign/GetCampaignLineCount?campaignId=${id}`,
    method: 'GET',
  });
export const createCampaignSetting = (data: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Setting`,
    method: 'POST',
    data: data,
  });

export const rateQuestionResponse = (id: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/QuestionResponse/RateQuestionResponseByCampaign?CampaignID=${id}`,
    method: 'POST',
  });
export const getCampaignSettingById = (
  campaignId: number,
  enterpriseId: number
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Setting?fk_Campaign_ID=${campaignId}&fk_Enterprise_ID=${enterpriseId}`,
    method: 'GET',
  });
export const UpdateCampaignSetting = (
  data: ISettingResponse
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Setting`,
    method: 'PUT',
    data: data,
  });
