export default {
  control: {
    backgroundColor: '#F9F9F9',
    fontSize: 16,
    minHeight: '40px',
    borderRadius: '10px',
    width: '80%',
    // fontWeight: 'normal',
  },
  '&multiLine': {},
  '&singleLine': {
    display: 'inline-block',
    width: '100%',
    fontFamily: 'Poppins',
    // minHeight: 30,
    backgroundColor: '#F9F9F9',
    borderRadius: '10px',
    outline: 'none',
    highlighter: {
      padding: 9,
      border: 'none',
      borderRadius: '10px',
      color: '#ECE8FF',
    },
    input: {
      padding: 9,
      border: 'none',
      borderRadius: '10px',
      outline: 'none',
    },
  },
  suggestions: {
    maxHeight: '200px',
    maxWidth: '280px',
    width: '280px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    m: '10px 0 10px 5px',
    '&::-webkit-scrollbar': {
      width: '5px',
      borderRadius: '5px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '12px',
      backgroundColor: '#ECE8FF',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#A51ED4',
      height: '80px',
    },
    list: {
      backgroundColor: 'white',
      fontSize: 16,
      borderRadius: '20px',
    },
    item: {
      padding: '5px 15px',
      //   borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#ECE8FF',
      },
    },
  },
};
