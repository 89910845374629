import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { toastFailure, toastSuccess } from "../../utils/toast";
import { ICandidates } from "../../modals/modals";
import { getAllCampaignLineCandidates } from "../../api/candidate";
import {  searchUser } from "../../api/auth";

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export const fetchAllCandidates = createAsyncThunk("candidates/fetch", async (obj:any) => {
  return {
    candidatesResponse: await searchUser(obj.enterpriseId,obj.campaignId, obj.searchText),
  };
});


export interface candidatesState {
  candidatesList: ICandidates[] | null;
}

const initialState: candidatesState = {
  candidatesList: null,
}

export const candidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    allCandidatesreducer: (state, action) => {
      state.candidatesList = action.payload;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchAllCandidates.fulfilled, (state, action) => {
      if (action.payload.candidatesResponse.successful) {
        state.candidatesList = action.payload.candidatesResponse.data.reverse();
      } else {
        if (!action.payload.candidatesResponse.successful) {
          toastFailure(action.payload.candidatesResponse.message);
        }
      }
    })
  }
})
export default candidateSlice.reducer;