// @ts-nocheck
import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './styles.css';
import { Calendar } from 'react-calendar';
import { Button } from '@mui/material';
import { Colors } from '../../theme/colors';
import { formatDateMonthToString, formatStringToDate } from '../../utils/dateFormatter';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';

interface CustomCalendarProps {
  date?: any;
  onClose: () => void;
  onApply: (date: string) => void;
  minDate?: Date;
}

const CustomCalendarNew = ({
  date,
  onClose,
  onApply,
  minDate,
}: CustomCalendarProps) => {
  const [selectedDate, setSelectedDate] = useState<string>(formatStringToDate(date));
  const [selectedMonth, setSelectedMonth] = useState({
    months: new Date().getMonth() + 1,
  });

  return (
    <div
      style={{
        padding: 12,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          // paddingTop: 20,
          paddingBottom: 20,
          fontSize: 17,
        }}
      >
        <Calendar
          minDate={minDate ? minDate : new Date()}
          onChange={(date: Date) => {
            setSelectedDate(date);
          }}
          value={selectedDate}
          showNavigation={false}
          className={['calendarContainer']}
          nextAriaLabel="Next"
          showNavigation={true}
          showNeighboringMonth={true}
          prevLabel={<ChevronLeftRoundedIcon />}
          prev2Label={<FirstPageRoundedIcon />}
          nextLabel={<ChevronRightRoundedIcon />}
          next2Label={<LastPageRoundedIcon />}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: 10,
          paddingBottom: 0,
          marginTop: 4,
          borderTop: `1px solid ${Colors.default}`,
        }}
      >
        <Button
          variant={'contained'}
          color={'secondary'}
          size={'large'}
          sx={{
            width: '25%',
            height: 40,
            marginRight: 2,
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ece8ff',
            '&:hover': {
              backgroundColor: '#ece8ff',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            },
          }}
          onClick={onClose}
        >
          <span>Close</span>
        </Button>

        <Button
          variant={'contained'}
          color={'primary'}
          size={'large'}
          sx={{ width: '25%', height: 40 }}
          onClick={() => {
            onApply(formatDateMonthToString(selectedDate));
          }}
        >
          <span>Apply</span>
        </Button>
      </div>
    </div>
  );
};

export default CustomCalendarNew;
