import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Typography, Theme } from '@mui/material';
import { Colors } from '../../../../theme/colors';
import { toastFailure, toastSuccess } from '../../../../utils/toast';
import { Campaigns } from '../../../../modals/modals';
import { useDrop } from 'react-dnd';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  fetchQuestions,
  clearQuestion,
} from '../../../../redux/questions/question-slice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
import { createAIJobDescription } from '../../../../api/questions';
import { DrawerRightNew } from '../../../../components/dashboard/DrawerRightNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fetchAllEnterprises } from '../../../../redux/enterprise/enterprise-slice';
import { useSelector } from 'react-redux';
import 'react-phone-number-input/style.css';
import { getUserByIDSlice } from '../../../../redux/user/user-slice';
import { getPaymentDetailsByUser } from '../../../../redux/payment/payments-slice';

interface LocationPropsState {
  item: Campaigns;
}
interface ICreateCampaignprops {
  pageType: string;
  campaignJobTitle: string;
  onUpdateJobDescription: (jd: any, html: any) => void;
}
interface IHeaderProps {
  commencementDate: (date: Date) => void;
  completionDate: (date: Date) => void;
}

const useStylesCss = makeStyles((theme: Theme) => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '2px',
      backgroundColor: `${Colors.Maximum_blue_purple}`,
    },
  },
}));

const JobDescriptionForm = (
  { pageType, campaignJobTitle, onUpdateJobDescription }: ICreateCampaignprops,
  props: IHeaderProps
) => {
  const classes = useStylesCss();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationPropsState;

  const candidateRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [campaignDesc, setCampaignDesc] = useState<string>('');
  const [campaignDescHtml, setcampaignDescHtml] = useState<string>('');

  const isSideBarOpenRight = useSelector(
    (state: any) => state.dashboard.isSideBarOpenRight
  );
  const [isDropdownOpen, setisDropdownOpen] = useState<boolean>(false);
  const [selectedCompany, setselectedCompany] = useState<any>({});
  const { questions, jobdescriptionAPICall, jobDescriptionHtml } =
    useAppSelector((state) => state.questions);

  const { jobTemplates } = useAppSelector((state) => state.jobtemplates);

  const { enterprises, enterpris } = useAppSelector(
    (state) => state.enterprises
  );

  const [anchorElJD, setAnchorElJD] = React.useState<null | HTMLElement>(null);
  const [isAIApiCallJD, setisAIApiCallJD] = useState<boolean>(
    jobdescriptionAPICall
  );

  const [openModal, setOpenModal] = React.useState(false);
  const [storeAIResponse, setStoreAIResponse] = useState<any | null>(
    jobDescriptionHtml
  );

  const [anchorJobEl, setanchorJobEl] = React.useState<null | HTMLElement>(
    null
  );

  const [isInsertedJD, setIsInsertedJD] = useState<boolean>(false);
  const AiRef = useRef(null);

  useEffect(() => {
    if (campaignJobTitle) {
      generateJobDescription();
    }
  }, [campaignJobTitle]);

  const generateJobDescription = () => {
    createAIJobDescription(`${campaignJobTitle}`)
      .then((res: any) => {
        if (res?.data) {
          setStoreAIResponse(res?.data);
        }
        setisAIApiCallJD(false);
        // setOpenModal(false);
      })
      .catch((err) => {
        setisAIApiCallJD(false);
        setOpenModal(false);
        // console.log(err);
        handlejobMenuClose();
      });
  };

  useEffect(() => {
    const handlePopstate = () => {
      // Logic to handle the browser's back or forward button click
      navigate('/campaign');
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (jobDescriptionHtml !== null && !jobdescriptionAPICall) {
      // scrollDownFun(AIMenu);
      // handlejobMenuClose();
      // setTimeout(() => {
      //   if (pageType === 'create') {
      //     setAnchorElJD(AiRef?.current);
      //   }
      // }, 800);
    }
    setStoreAIResponse(jobDescriptionHtml);
  }, [jobDescriptionHtml, jobdescriptionAPICall]);

  useEffect(() => {
    if (storeAIResponse !== null) {
      handleInsertJD();
    }
  }, [storeAIResponse]);

  const handlejobMenuClose = () => {
    setanchorJobEl(null);
  };

  const handleInsertJD = () => {
    setisAIApiCallJD(false);
    setIsInsertedJD(true);
    setAnchorElJD(null);

    setcampaignDescHtml((prev) => prev + storeAIResponse?.join('<br /> '));

    setCampaignDesc((prev) => prev + storeAIResponse?.join(' '));

    onUpdateJobDescription(
      campaignDesc + storeAIResponse?.join(' '),
      campaignDescHtml + storeAIResponse?.join('<br /> ')
    );
  };

  useEffect(() => {
    if (pageType !== 'create') {
      dispatch(fetchAllEnterprises());
    }
  }, []);

  useEffect(() => {
    if (state && enterpris) {
      const data = {
        userId: enterpris?.fk_User_ID,
        enterpriseId: state?.item?.fk_Enterprise_ID,
      };
      dispatch(getPaymentDetailsByUser(data));
    }
  }, [state, enterpris]);

  useEffect(() => {
    if (enterpris) {
      dispatch(getUserByIDSlice(enterpris.fk_UserID_MainContact));
    }
  }, [enterpris]);

  useEffect(() => {
    if (enterprises && enterprises.length > 0 && state) {
      const getSpecificCompany = enterprises.filter(
        (itm) => itm.pk_Enterprise_ID === state.item.fk_Enterprise_ID
      );
      if (getSpecificCompany.length > 0) {
        setselectedCompany(getSpecificCompany[0]);
      }
    }
  }, [enterprises]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        candidateRef.current &&
        !candidateRef.current.contains(event.target)
      ) {
        setisDropdownOpen(false);
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [candidateRef]);

  useEffect(() => {
    if (state) {
      if (pageType !== 'create') {
        dispatch(fetchQuestions(state?.item?.pk_Campaign_ID));
      }
    } else {
      dispatch(clearQuestion());
    }
  }, [state]);

  const [{ isOver: isOver2 }, dropJob] = useDrop(
    () => ({
      accept: ['jobItem'],
      drop: (item: any) => {
        // if()
        if (!state) {
          toastFailure('Job Title is required.');
        }

        if (item.data[0]) {
          setcampaignDescHtml(item.data[0].Job_Description);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [jobTemplates]
  );

  const scrollDownFun = (id: any) => {
    id?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
    ],
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  // Comment 5555555

  const copyToClipboard = () => {
    if (campaignDesc) {
      const clipboard = window.navigator.clipboard;
      if (!clipboard) {
        console.log('Clipboard not supported');
      }
      clipboard.writeText(campaignDesc);
      toastSuccess('Successfully copied.');
    }
  };
  // const style = {
  //   position: 'absolute' as 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   boxShadow: 24,
  //   borderRadius: '10px',
  //   '&:focus-visible': {
  //     outline: 'none !important',
  //   },
  // };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          width: '100%',
          overflowX: 'hidden',
          height: 'calc(100vh - 250px)',
        }}
      >
        <Box
          className={classes.root}
          sx={{
            width: '60%',
            height: 'calc(100vh - 250px)',
            position: 'relative',
            overflowY: 'scroll',
            transition: 'width 0.1s ease-in-out',
            paddingLeft: '0px',
            paddingRight: '22px',
            marginTop: '0px',
          }}
        >
          {(isSideBarOpenRight || pageType === 'create') && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <div
                style={{
                  position: 'relative',
                  marginTop: '20px',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: '11px',
                    top: '11px',
                  }}
                  onClick={copyToClipboard}
                >
                  <ContentCopyIcon
                    sx={{
                      fontSize: 20,
                      cursor: 'pointer',
                      color: Colors.primary,
                    }}
                  />
                </div>
                <ReactQuill
                  readOnly={false}
                  modules={modules}
                  formats={formats}
                  value={campaignDescHtml}
                  className={'job-title'}
                  placeholder='Enter the job description here'
                  onChange={(content, delta, source, editor) => {
                    setcampaignDescHtml(content);
                    setCampaignDesc(editor.getText(0));
                    onUpdateJobDescription(editor.getText(0), content);
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: Colors.white,
                    display: 'flex',
                    justifyContent: 'center',
                    height: 80,
                    borderRadius: 2,
                    alignItems: 'center',
                    color: Colors.gray1,
                    border: `1px dotted ${Colors.gray_A9}`,
                    marginTop: '10px',
                  }}
                  ref={dropJob}
                >
                  <Typography
                    fontSize={'12px'}
                    fontWeight={600}
                    color={Colors.gray_97}
                    variant={'body1'}
                  >
                    Drop job description from templates here
                  </Typography>
                </Box>
              </div>
            </Box>
          )}
        </Box>
        <Box
          className={classes.root}
          sx={{
            display: 'flex',
            width: '40%',
            transition: 'width 0.1s ease-in-out',
            paddingRight: pageType === 'edit' ? '22px' : '0px',
            paddingLeft: pageType === 'edit' ? '22px' : '0px',
            marginTop: pageType === 'edit' ? '110px' : '0px',
            height:
              pageType == 'create'
                ? 'calc(100vh - 250px)'
                : 'calc(100vh - 210px)',
            // overflowY: "scroll",
            background: pageType === 'edit' ? Colors.white : 'transparent',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <DrawerRightNew
              isCreateCampaign={true}
              selectedCompany={selectedCompany}
              stateData={state}
              pageType={pageType}
              isQuestionTemplate={false}
              labelColor={Colors.black}
              isInstantJob={true}
            />
          </Box>
        </Box>
      </Box>
      <br />
      <br />
      <br />
    </>
  );
};

export default JobDescriptionForm;
