import { ITemplatesResponse } from '../modals/modals';
import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';

export const getAllTemplate = (): Promise<Response<ITemplatesResponse[]>> =>
  generalMiddleware.request({
    url: '/Template',
    method: 'GET',
  });

export const addNewTemplate = (
  data: ITemplatesResponse
): Promise<Response<ITemplatesResponse[]>> =>
  generalMiddleware.request({
    url: '/Template',
    method: 'POST',
    data: data,
  });
export const UpdateTemplate = (
  data: ITemplatesResponse
): Promise<Response<ITemplatesResponse[]>> =>
  generalMiddleware.request({
    url: '/Template',
    method: 'PUT',
    data: data,
  });
export const deleteTemplate = (
  id: number
): Promise<Response<ITemplatesResponse[]>> =>
  generalMiddleware.request({
    url: `/Template/${id}`,
    method: 'DELETE',
  });

export const getTemplateQuestionsById = (
  id: number
): Promise<Response<ITemplatesResponse[]>> =>
  generalMiddleware.request({
    url: `/QuestionTemplate/GetByTemplate?templateId=${id}`,
    method: 'GET',
  });

export const getPrivacyPolicy = (): Promise<any> =>
  generalMiddleware.request({
    url: `/Snippet/Get/Privacy_Policy`,
    method: 'GET',
  });

export const getTermsAndConditions = (): Promise<any> =>
  generalMiddleware.request({
    url: `/Snippet/Get/Terms_Of_Service`,
    method: 'GET',
  });
