import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  UpdateTemplate,
  addNewTemplate,
  deleteTemplate,
  getAllTemplate,
  getPrivacyPolicy,
  getTemplateQuestionsById,
  getTermsAndConditions,
} from '../../api/templates';
import { ITemplatesResponse, QuestionTemplate } from '../../modals/modals';
import { toastFailure, toastSuccess } from '../../utils/toast';

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export const fetchTemplates = createAsyncThunk('template/fetch', async () => {
  return {
    templateResponse: await getAllTemplate(),
  };
});

export const createNewTemplates = createAsyncThunk(
  'template/create',
  async (data: ITemplatesResponse) => {
    return {
      createtemplateResponse: await addNewTemplate(data),
    };
  }
);

export const updateTemplates = createAsyncThunk(
  'template/update',
  async (data: ITemplatesResponse) => {
    return {
      createtemplateResponse: await UpdateTemplate(data),
    };
  }
);
export const deleteTemplates = createAsyncThunk(
  'template/delete',
  async (data: number) => {
    return {
      createtemplateResponse: await deleteTemplate(data),
    };
  }
);

export const fetchTemplatesQuestionsById = createAsyncThunk(
  'template/fetchbyId',
  async (id: number) => {
    return {
      templateQuestionResponse: await getTemplateQuestionsById(id),
    };
  }
);

export const fetchPrivacyPolicyTemplate = createAsyncThunk(
  'template/fetchPrivacyPolicy',
  async () => {
    return {
      privacyPolicy: await getPrivacyPolicy(),
    };
  }
);

export const fetchTermsTemplate = createAsyncThunk(
  'template/fetchTermsCondition',
  async () => {
    return {
      termsAndCondition: await getTermsAndConditions(),
    };
  }
);

export interface templatesState {
  templates: ITemplatesResponse[];
  templateQuestions: any;
  privacyTemplate: any;
  termsTemplate: any;
}

const initialState: templatesState = {
  templates: [],
  templateQuestions: [],
  privacyTemplate: null,
  termsTemplate: null,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    allTemplatesreducer: (state, action) => {
      state.templates = action.payload;
    },
    deleteQuestionReducer: (state, action) => {
      const filterState = state.templateQuestions.filter((question: any) => {
        return (
          question.pk_QuestionTemplate_ID !=
          action.payload.item.pk_QuestionTemplate_ID
        );
      });
      state.templateQuestions = filterState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      if (action.payload.templateResponse.successful) {
        state.templates = action.payload.templateResponse.data.reverse();
      } else {
        if (!action.payload.templateResponse.successful) {
          toastFailure(action.payload.templateResponse.message);
        }
      }
    });
    builder.addCase(fetchTemplatesQuestionsById.fulfilled, (state, action) => {
      if (action.payload.templateQuestionResponse.successful) {
        state.templateQuestions = action.payload.templateQuestionResponse.data;
      } else {
        if (!action.payload.templateQuestionResponse.successful) {
          toastFailure(action.payload.templateQuestionResponse.message);
        }
      }
    });

    builder.addCase(fetchPrivacyPolicyTemplate.fulfilled, (state, action) => {
      if (action.payload.privacyPolicy.successful) {
        state.privacyTemplate = action.payload.privacyPolicy.data;
      } else {
        if (!action.payload.privacyPolicy.successful) {
          toastFailure(action.payload.privacyPolicy.message);
        }
      }
    });

    builder.addCase(fetchTermsTemplate.fulfilled, (state, action) => {
      if (action.payload.termsAndCondition.successful) {
        state.privacyTemplate = action.payload.termsAndCondition.data;
      } else {
        if (!action.payload.termsAndCondition.successful) {
          toastFailure(action.payload.termsAndCondition.message);
        }
      }
    });

    builder.addCase(createNewTemplates.fulfilled, (state, action) => {
      if (action.payload.createtemplateResponse.successful) {
      } else {
        if (!action.payload.createtemplateResponse.successful) {
          toastFailure(action.payload.createtemplateResponse.message);
        }
      }
    });
  },
});
export const { deleteQuestionReducer } = templatesSlice.actions;

export default templatesSlice.reducer;
