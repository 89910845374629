import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Invitation, checkEmailExit, copyInvitation, generateDescToSendMail, sendEmailToCandidate, sendUserInvitation } from "../../api/invitation";

export const InvitationData = createAsyncThunk(
    "dashboard/interview",
    async (paramsData: any) => {
        return {
        response: await Invitation(paramsData),
        };
    }
);
export const userInvitationByRole = createAsyncThunk(
    "dashboard/userInvitation",
    async (paramsData: any) => {
        return {
          response: await sendUserInvitation(paramsData),
        };
    }
);
export const senMailToCandidates = createAsyncThunk(
    "dashboard/sendMail",
    async (paramsData: any) => {
        return {
          response: await sendEmailToCandidate(paramsData),
        };
    }
);
export const CopyInvitationLink = createAsyncThunk(
    "campaign/copyinvitation",
    async (id: number) => {
        return {
          response: await copyInvitation(id),
        };
    }
);
export const checkEmailExits = createAsyncThunk(
  'campaign/emailExist',
  async (data: any) => {
    return {
      response: await checkEmailExit(data.emailId, data.enterpriseID),
    };
  }
);
export const generateDescToSendMails = createAsyncThunk(
  'campaign/generateMaildesc',
  async (status: string) => {
    return {
      response: await generateDescToSendMail(status),
    };
  }
);

interface InviteState {
    token: string;
}

const initialState: InviteState = {
    token: ""
};

export const InviteSlice = createSlice({
    name: "invitation",
    initialState,
    reducers: {
        inviteReducer: (state, action) => {
        state.token = action.payload.token;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(InvitationData.fulfilled, (state, action) => {
            
        });
    },
});

export const { inviteReducer } = InviteSlice.actions;

export default InviteSlice.reducer;
