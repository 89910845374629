import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { registrationReducer } from '../../redux/registration/registration-slice';
import AuthBackgroundImage from '../../images/background/authbackground.png';
import TIRONEM_LOGO from '../../images/TIRONEM_LOGO.png';


const InviteUserLinkPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, eId, statusId } = useParams();
  useEffect(() => {
    const getToken = localStorage.getItem('token');                  
    if (getToken) {
      localStorage.clear();
    }
    if (id) {
      const data = {
        id: id,
        enterpriseId: eId,
        userStatusId: statusId,
      };
      dispatch(registrationReducer(data));
      navigate('/registration');
    }
    
  }, [id]);

  return (
    <>
      <div
        style={{
          position: 'relative',
          background: 'white',
          height: '100vh',
          // width: "100vw",
          // minHeight: "750px",
          overflow: 'hidden',
        }}
      >
        <img
          src={AuthBackgroundImage}
          alt="AuthBackground_Image"
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
          }}
        />
        <div
          style={{
            position: 'relative',
            zIndex: 10,
          }}
        >
          {/* header */}
          <div
            style={{
              marginTop: '65px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={TIRONEM_LOGO}
              alt="TIRONEM_logo"
              style={{
                width: '216px',
                height: '49px',
              }}
            />
          </div>

          {/* body content */}
          
        </div>
      </div>
    </>
  );
};

export default InviteUserLinkPage;
