import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import formatResponse from './format-response';
import { BASE_URL, BASE_URL_PREFIX } from '../../utils/config';

const token = () => {
  const getToken = localStorage.getItem('token')
  return getToken;
}


const generalMiddleware = axios.create({
  baseURL: BASE_URL,
});

const axiosInstanceMiddleware = axios.create({
  baseURL: BASE_URL_PREFIX,
});

generalMiddleware.interceptors.request.use(
  (requestConfig: AxiosRequestConfig<AxiosInstance>) => {
    requestConfig.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token()}`,
    };
    return requestConfig;
  }
);

axiosInstanceMiddleware.interceptors.request.use(
  (requestConfig: AxiosRequestConfig<AxiosInstance>) => {
    requestConfig.headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token()}`,
    };
    return requestConfig;
  }
);

export const axiosBaseInstance = formatResponse(axiosInstanceMiddleware);

export default formatResponse(generalMiddleware);
