import React, { Fragment } from "react";
import { Typography, Slider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Colors } from "../../../theme/colors";
import RunnerIcon from "../../../images/runer.png";

interface ISliderInputProps {
  title?: string;
  sliderWidth?: string;
  helperLabel?: string;
  max?: number;
  value?: number;
  onChange: (value:number | number[]) => void;
  // labeldefault?: string;
  labeldefault?: "on" | "off" | "auto";
}

const SliderInput = (props: ISliderInputProps) => {
  const theme = useTheme();

  return (
    <Fragment>
      <div style={{ width: "180px" }}>
        <Typography variant={"h6"} fontSize="14px" color={Colors.outerSpace}>
          {props.title}
        </Typography>
      </div>
      <div
        style={{
          width: props.sliderWidth ? props.sliderWidth : "calc(100% - 180px)",
        }}
      >
        <Slider
          aria-label="Volume"
          // defaultValue={props.value}
          valueLabelDisplay={props.labeldefault ? props.labeldefault : "auto"}
          min={1}
          max={props.max || 100}
          value={props.value}
          valueLabelFormat={(value: number) => {
            return `${props.value} ${props.helperLabel || ""}`;
          }}
          getAriaValueText={(value: number) => {
            return `${props.value} ${props.helperLabel || ""}`;
          }}
          sx={{
            color: "transparent",
            height: "1px",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-rail": {
              border: "none",
              opacity: 1,
              backgroundColor:
                theme.palette.mode === "dark" ? "#CBDFFF" : "#CBDFFF",
              color: theme.palette.mode === "dark" ? "#CBDFFF" : "#CBDFFF",
            },
            "& .MuiSlider-thumb": {
              width: 24,
              height: 24,
              backgroundColor: "tranparent",
              backgroundImage: `url(${RunnerIcon})`,
              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: "none",
              },
            },
            "& .MuiSlider-valueLabel": {
              color: `${Colors.outerSpace} !important`,
              borderRadius: "6px",
              border: `1px solid ${Colors.dark_orchid} !important`,
              background: "white !important",
              width: "11px !important",
              fontSize: "11px",
              fontWeight: "500",
            },
            "& .MuiSlider-valueLabel:before": {
              color: "red !important",
              bottom: "-1px",
              border: `1px solid ${Colors.dark_orchid} !important`,
              borderTop: "transparent !important",
              borderLeft: "transparent !important",
              background: "white !important",
              width: "6px",
              height: "6px",
            },
          }}
          onChange={(event, value) => {
            props.onChange(value);
          }}
        />
      </div>
    </Fragment>
  );
};

export default SliderInput;
