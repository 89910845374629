import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';
import { enterpriseResponse } from '../modals/modals';

export const getAllEmployers = (): Promise<Response<enterpriseResponse[]>> =>
  generalMiddleware.request({
    url: `/Employer`,
    method: 'GET',
  });

export const getAllEmployersByEnterprisse = (
  obj: any
): Promise<Response<enterpriseResponse[]>> =>
  generalMiddleware.request({
    url: `/Employer/GetEmployerByEnterpirseID?ps=${obj.ps}&pn=${obj.pn}&id=${obj.enterpriseId}`,
    method: 'GET',
  });

export const createEmployerClient = (data: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/employer`,
    method: 'POST',
    data: data,
  });

export const AddEmployerImage = (data: any): Promise<Response<any>> => {
  const formData = new FormData();
  formData.append('', data.imageData);
  return generalMiddleware.request({
    url: `Employer/SaveImage?employerId=${data.id}`,
    method: 'Post',
    data: formData,
  });
};
export const updateEmployerClient = (data: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/employer/${data.pk_Employer_ID}`,
    method: 'PUT',
    data: data,
  });

// export const getEnterprisesById = (
//   id: string
// ): Promise<Response<enterpriseResponse>> =>
//   generalMiddleware.request({
//     url: `/Enterprise/${id}`,
//     method: "GET",
//   });

// export const updateEnterprises = (
//   data: enterpriseResponse
// ): Promise<Response<any>> =>
//   generalMiddleware.request({
//     url: `/Enterprise`,
//     method: "PUT",
//     data:data
//   });
// export const createEnterprises = (
//   data: enterpriseResponse
// ): Promise<Response<any>> =>
//   generalMiddleware.request({
//     url: `/Enterprise`,
//     method: "POST",
//     data:data
//   });
