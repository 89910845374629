import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';
import { Comment, commentCreate } from '../modals/modals';

export const getComments = (data: number): Promise<Response<Comment[]>> =>
  generalMiddleware.request({
    url: `/CommentGetPerCampaign/Getcomments/${data}`,
    method: 'GET',
  });

export const getCampaignComments = (): Promise<any> =>
  generalMiddleware.request({
    url: `/Comment/`,
    method: 'GET',
  });

export const getAnswersComments = (data: any): Promise<any> =>
  generalMiddleware.request({
    url: `/Comment/GetCommentsByQuestion/${data.CampaignID}/${data.QuestionID}/${data.UserID}/${data.QuestionResponseID}`,
    method: 'GET',
  });

export const createComments = (comment: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: '/Comment',
    method: 'POST',
    data: comment,
  });

export const updateComments = (comment: Comment): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Comment`,
    method: 'PUT',
    data: comment,
  });

export const deleteComments = (comment: Comment): Promise<Response<string>> =>
  generalMiddleware.request({
    // url: `/Comment?id=${comment.pk_Comment_ID}`,
    url: `/Comment/${comment.pk_Comment_ID}`,
    method: 'DELETE',
    data: comment,
  });

  export const createNotification = (data: any): Promise<Response<any>> =>
    generalMiddleware.request({
      url: '/Notification',
      method: 'POST',
      data: data,
    });
  export const getNotificationById = (id: number): Promise<Response<any>> =>
    generalMiddleware.request({
      url: `/Notification?id=${id}`,
      method: 'GET',
    });
  export const updateNotification = (data:any): Promise<Response<any>> =>
    generalMiddleware.request({
      url: `/Notification`,
      method: 'PUT',
      data : data
    });