import { Questions, QuestionsSynthesia, SynthesiaVideo } from '../modals/modals';
import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';

export const getQuestions = (
  campainId: number
): Promise<Response<Questions[]>> =>
  generalMiddleware.request({
    url: `/QuestionGetPerCampaign/Getquestions/${campainId}`,
    method: 'GET',
  });

export const createQuestion = (
  question: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/Question',
    method: 'POST',
    data: question,
  });

export const createAIQuestion = (
  number?: any,
  title?: any,
  data?: any
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Question/GenerateQuestion?QuestionNumber=${number}&JobTitle=${title}/`,
    method: 'POST',
    data: data,
  });

export const createAIJobDescription = (title?: any): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Snippet/GenerateJobDescriptionPrompt/${title}/`,
    method: 'GET',
  });

export const createAIJobDescriptionNew = (
  title?: any
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Snippet/GenerateJobDescriptionPromptNew/${title}/`,
    method: 'GET',
  });

export const updateQuestion = (
  question: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Question`,
    method: 'PUT',
    data: question,
  });

export const generateAudioQuestion = (
  question: Questions[]
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Question/SaveAudioURLList`,
    method: 'PUT',
    data: question,
  });

export const deleteQuestion = (
  question: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Question/${question.pk_Question_ID}`,
    method: 'DELETE',
    data: question,
  });

export const questionResponseRating = (
  questionData: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/QuestionResponseRating',
    method: 'POST',
    data: questionData,
  });
export const questionResponseUpdateRating = (
  questionData: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/QuestionResponseRating',
    method: 'PUT',
    data: questionData,
  });
export const userRating = (
  questionData: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/CandidateRating',
    method: 'POST',
    data: questionData,
  });
export const userUpdateRating = (
  questionData: Questions
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: '/CandidateRating',
    method: 'PUT',
    data: questionData,
  });
export const generateSynthesia = (
  question: QuestionsSynthesia[]
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Question/SynthesiaVideoList`,
    method: 'PUT',
    data: question,
  });
export const generateInterviewEngagementVideos = (
  data: SynthesiaVideo[]
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Synthesia/TextToVideoFromSynthesia`,
    method: 'POST',
    data: data,
  });
