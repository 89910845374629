import { MoreVert, StarBorder } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { Fragment } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Colors } from '../../../../theme/colors';

interface Props {
  dropdown?: boolean;
  pageType?: string;
  isJobCreate?: boolean;
  candidateData: any;
  isEdit?: boolean;
}

const CandidateList = ({
  pageType,
  dropdown,
  candidateData,
  isEdit,
  isJobCreate = false,
}: Props) => {
  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          // background: dropdown ? Colors.background_grey : '#FFFFFF',
          background: Colors.white,
          padding: dropdown ? '10px 10px' : '0px 10px',
          borderRadius: '6px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {candidateData?.Enterprise_AvatarEnable ||
          candidateData?.Employer_AvatarEnable ||
          (candidateData?.Enterprise_AvatarEnable === undefined &&
            candidateData?.Enterprise_ImageLink) ? (
            <>
              <div
                style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '50%',
                  marginRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '18px',
                  fontWeight: 500,
                  background: Colors.white,
                }}
              >
                <img
                  src={
                    candidateData?.Enterprise_ImageLink ??
                    candidateData?.Employer_ImageLink
                  }
                  alt='company_logo'
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  minWidth: '48px',
                  minHeight: '48px',
                  borderRadius: '50%',
                  marginRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  fontWeight: 500,
                  background: candidateData?.Enterprise_BackgroundColour
                    ? candidateData?.Enterprise_BackgroundColour
                    : candidateData?.Employer_BackgroundColour
                    ? candidateData?.Employer_BackgroundColour
                    : Colors.Maximum_blue_purple,
                  textTransform: 'uppercase',
                  color: candidateData?.Enterprise_Colour
                    ? candidateData?.Enterprise_Colour
                    : candidateData?.Employer_Colour
                    ? candidateData?.Employer_Colour
                    : Colors.black,
                }}
              >
                <div>
                  {candidateData?.Enterprise_Initials
                    ? candidateData?.Enterprise_Initials
                    : candidateData?.Employer_Initials
                    ? candidateData?.Employer_Initials
                    : candidateData?.Employer_Name
                    ? candidateData?.Employer_Name?.substring(0, 1)
                    : candidateData?.Enterprise_Name?.substring(0, 1)}
                </div>
              </div>
            </>
          )}
          {/* <div
            style={{
              backgroundColor: Colors.primary,
              height: pageType === 'create' ? '40px' : '27px',
              width: pageType === 'create' ? '40px' : '27px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
          /> */}
          <div>
            <Typography
              sx={{
                padding: '0',
                color: Colors.outerSpace,
                fontWeight: '400',
              }}
              fontSize='14px'
            >
              {candidateData?.Enterprise_Name ||
                candidateData?.Employer_Name ||
                'Company Name'}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {dropdown && pageType === 'create' && isEdit && (
            <div>
              <KeyboardArrowDownIcon
                sx={{
                  color: Colors.outerSpace,
                  fontSize: 20,
                }}
              />
            </div>
          )}
          {isJobCreate && (
            <div>
              <KeyboardArrowDownIcon
                sx={{
                  color: Colors.Gray6b,
                  fontSize: 32,
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CandidateList;
