import React, { useEffect, useRef, useState } from 'react';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ListItem,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { Search, Add, ExpandMore, Check, Close } from '@mui/icons-material';
import { Colors } from '../../theme/colors';
import { useDrag } from 'react-dnd';
import { QuestionTemplates } from '../questionTemplates/questionTemplates';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { createQuestionTemplates } from '../../redux/questionTemplate/questiontemplate-slice';
import { TextInput } from '../../ui/InputFields';
import QuestionsTemplateList from '../questionTemplates/QuestionsTemplatesList';
import {
  createNewTemplates,
  fetchTemplates,
  fetchTemplatesQuestionsById,
} from '../../redux/templates/templates-slice';
import { toastFailure, toastSuccess } from '../../utils/toast';
import { makeStyles } from '@mui/styles';
import Settings from '../../../src/screens/campaign/Common/CampaignSection/Settings';
import NO_DATA_ICON from '../../images/icons/NO_DATA_ICON.png';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as BackIcon } from '../../images/icons/backIcon.svg';
import { useNavigate } from 'react-router-dom';
import { Urls } from '../../utils/urls';
import { DrawerRightJob } from './DrawerRightJob';
import { CircleLoader } from 'react-spinners';

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 99,
  height: '100%',
  border: 0,
  [theme.breakpoints.up('md')]: {
    position: 'static',
    borderRadius: '6px',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(13)} + 1px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 99,
  border: 0,
  height: '100%',
  [theme.breakpoints.up('md')]: {
    position: 'static',
  },
});

const DrawerContent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  minWidth: `calc(${theme.spacing(13)} + 1px)`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useStylesCss = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${Colors.Maximum_blue_purple}`,
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      borderRadius: '12px',
    },
  },
}));

export const DrawerRightNew = ({
  stateData,
  selectedCompany,
  pageType,
  isCreateCampaign,
  isQuestionTemplate,
  labelColor,
  isInstantJob = false,
}: any): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(true);
  const templateRef = useRef<any>();
  const theme = useTheme();
  const classes = useStylesCss();
  const [addNewQuestion, setAddNewQuestion] = useState<boolean>(false);
  const [addNewJobTemplate, setAddNewJobTemplate] = useState<boolean>(false);
  const [NewQuestionValue, setNewQuestionValue] = useState('');
  const [newTemplateName, setnewTemplateName] = useState<string>('');
  const [isLoadingQuestions, setisLoadingQuestions] = useState<boolean>(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(true);
  const [menuList, setmenuList] = useState(
    isQuestionTemplate ? ['Templates'] : ['Job description']
  );
  const [menuTemplates, setMenuTemplate] = useState([
    'Question Templates',
    'Job description Templates',
  ]);
  const [currentActive, setcurrentActive] = useState<number>(0);
  const [currentActiveIndex, setcurrentActiveIndex] = useState<number>(0);
  const { userData } = useAppSelector((state: any) => state.user);
  const { templates, templateQuestions } = useAppSelector(
    (state) => state.templates
  );
  const [questionTemplates, setQuestionTemplates] = useState<any>([]);

  const [expanded, setExpanded] = React.useState<undefined | number>(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    setQuestionTemplates(templateQuestions);
  }, [templateQuestions]);

  // call Api Specific tempkate que usind id
  useEffect(() => {
    if (expanded !== undefined) {
      setisLoadingQuestions(true);
      setQuestionTemplates([]);
      dispatch(fetchTemplatesQuestionsById(expanded))
        .unwrap()
        .then(() => {
          setisLoadingQuestions(false);
        })
        .catch(() => {
          setisLoadingQuestions(false);
        });
    }
  }, [expanded]);

  // Close Select Outside Click
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (templateRef.current && !templateRef.current.contains(event.target)) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [templateRef]);

  // Fetch fetchTemplates
  useEffect(() => {
    setIsLoadingTemplates(true);
    dispatch(fetchTemplates())
      .unwrap()
      .then((response) => {
        setIsLoadingTemplates(false);
      })
      .catch(() => setIsLoadingTemplates(false));
  }, []);

  const hanldeAddNewQuestion = (templateData: any) => {
    const data = {
      QuestionTemplate_Record_GUID: templateData.Template_Record_GUID,
      fk_Template_ID: templateData.pk_Template_ID,
      sSession: templateData.sSession,
      fk_User_ID: templateData.fk_User_ID,
      QuestionTemplate_Text: NewQuestionValue,
      QuestionTemplate_RecruiterRating: 7,
      QuestionTemplate_Order: 8,
      QuestionTemplate_Deleted: 11,
      QuestionTemplate_Status: 12,
      QuestionTemplate_AccessLevel: 13,
      QuestionTemplate_AudioFileUrl: '',
    };

    dispatch(createQuestionTemplates(data))
      .unwrap()
      .then((originalPromiseResult: any) => {
        dispatch(fetchTemplatesQuestionsById(templateData.pk_Template_ID));
        setNewQuestionValue('');
      })
      .catch((rejectedValueOrSerializedError: any) => {
        setNewQuestionValue('');
        console.log('getting error', rejectedValueOrSerializedError);
      });
  };

  const handleNewTemplateAdd = () => {
    // if (!selectedCompany.pk_Enterprise_ID) {
    //   toastFailure('Please select a Company.');
    //   return;
    // }
    // if (stateData === undefined || stateData === null) {
    //   toastFailure("Campaign Title is required.");
    //   return;
    // }

    const body = {
      Template_Record_GUID: 'fcd3abb8-443c-4b81-a296-9cd8abcb3743',
      fk_Enterprise_ID: selectedCompany
        ? selectedCompany.pk_Enterprise_ID
        : userData?.fk_Enterprise_ID,
      sSession: selectedCompany ? selectedCompany.sSession : userData?.sSession,
      fk_User_ID: selectedCompany
        ? selectedCompany.fk_User_ID
        : userData?.pk_User_ID,
      Template_Name: newTemplateName,
      Template_Description: 'sample string 7',
      Template_Deleted: 10,
      Template_Status: 11,
    };
    dispatch(createNewTemplates(body))
      .unwrap()
      .then((originalPromiseResult: any) => {
        setnewTemplateName('');
        setAddNewQuestion(false);
        dispatch(fetchTemplates());
        toastSuccess('Question Template is created!');
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const questionTemplate = () => {
    return (
      <DrawerContent variant='permanent' open={open} anchor={'right'}>
        <List
          style={{
            marginLeft: 16,
            height: '100%',
          }}
        >
          <ListItem key={'item3'} style={{ paddingLeft: '0' }}>
            {open ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'flex-end',
                  borderRadius: '4px',
                  padding: 0,
                }}
              >
                <Box
                  sx={{
                    background: 'transparent',
                    borderRadius: 2,
                    alignItems: 'center',
                    minHeight: 80,
                    width: '100%',
                  }}
                >
                  {isCreateCampaign && (
                    <>
                      <Stack
                        sx={{
                          // borderBottom: `1px solid ${Colors.default}`,
                          background: Colors.white,
                          padding: '30px 0 10px 0',
                          borderRadius: '10px',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                        direction='row'
                        justifyContent='space-between'
                      >
                        <span>
                          <Typography
                            // variant={'body1'}
                            sx={{
                              fontSize: '14px',
                              fontWeight: '500',
                              color:
                                isCreateCampaign && !labelColor
                                  ? Colors.primary
                                  : Colors.black,
                            }}
                          >
                            Question Templates
                          </Typography>
                        </span>
                        <span>
                          <Button
                            variant={'text'}
                            onClick={() => {
                              setAddNewQuestion(true);
                            }}
                            sx={{
                              color: Colors.primary,
                              alignSelf: 'flex-end',
                              fontSize: '14px',
                              padding: 0,
                              height: 'auto',
                              fontWeight: 500,
                            }}
                          >
                            <Add
                              sx={{
                                color: Colors.primary,
                                fontSize: 22,
                              }}
                            />
                            Create Template
                          </Button>
                        </span>
                      </Stack>
                    </>
                  )}

                  {addNewQuestion && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: '10px',
                      }}
                    >
                      <TextInput
                        placeholder={'Enter new template name'}
                        border={`1px solid ${Colors.default}`}
                        value={newTemplateName}
                        onChange={(value) => setnewTemplateName(value)}
                        width={85}
                      />
                      <IconButton
                        edge='end'
                        aria-label='edit'
                        onClick={() => {
                          if (newTemplateName.trim() === '') {
                            toastFailure('Please enter a template name');
                            return;
                          }
                          handleNewTemplateAdd();
                        }}
                      >
                        <Check
                          sx={{
                            color: Colors.primary,
                            fontSize: 30,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        edge='end'
                        aria-label='edit'
                        onClick={() => {
                          setAddNewQuestion(false);
                          setnewTemplateName('');
                        }}
                      >
                        <Close
                          sx={{
                            color: Colors.secondary,
                            fontSize: 30,
                          }}
                        />
                      </IconButton>
                    </div>
                  )}

                  <div
                    ref={templateRef}
                    style={{
                      margin: '20px 0px',
                      borderRadius: '6px',
                      width: '100%',
                    }}
                  >
                    {templates.filter((template) => {
                      return (
                        template.fk_Enterprise_ID === userData?.fk_Enterprise_ID
                      );
                    }).length > 0 ? (
                      <>
                        {templates
                          .filter((template) => {
                            return (
                              template.fk_Enterprise_ID ===
                              userData?.fk_Enterprise_ID
                            );
                          })
                          .map((item: any, index: number) => (
                            <div key={index} style={{ width: '100%' }}>
                              <QuestionsTemplateList
                                expanded={expanded}
                                setExpanded={setExpanded}
                                isLoadingQuestions={isLoadingQuestions}
                                NewQuestionValue={NewQuestionValue}
                                setNewQuestionValue={setNewQuestionValue}
                                tempSpecificData={questionTemplates}
                                hanldeAddNewQuestion={hanldeAddNewQuestion}
                                item={item}
                                index={index}
                              />
                            </div>
                          ))}
                      </>
                    ) : isLoadingTemplates ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '5%',
                          }}
                        >
                          <CircleLoader color='#AA19D5' />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginTop: '12%',
                          }}
                        >
                          <img
                            alt='TIRONEM_logo'
                            height='60px'
                            src={NO_DATA_ICON}
                          />
                          <h3 style={{ fontFamily: 'Poppins' }}>
                            You have no Question Template, yet.
                          </h3>
                          <span style={{ fontFamily: 'Poppins' }}>
                            Create a new question template
                          </span>
                          <br />
                          <br />
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                              setAddNewQuestion(true);
                            }}
                            sx={{
                              height: '50px',
                              width: '300px',
                            }}
                          >
                            <AddIcon
                              color={'secondary'}
                              sx={{ fontSize: 30 }}
                            />
                            Create Template
                          </Button>
                        </Box>
                      </>
                    )}
                  </div>
                </Box>
              </Box>
            ) : (
              <Button
                variant={'contained'}
                color={'secondary'}
                size={'large'}
                sx={{ width: open ? '100%' : '25%', height: 60 }}
              >
                <Search />
              </Button>
            )}
          </ListItem>
        </List>
      </DrawerContent>
    );
  };

  return (
    <>
      {isCreateCampaign ? (
        <>
          {!isInstantJob && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderBottomRightRadius: '6px',
                borderBottomLeftRadius: '6px',
              }}
            >
              <Box
                sx={{
                  borderBottom: '1px solid #D9D9D9',
                  borderRight: `1px solid #D9D9D9`,
                  height: '53px',
                  minWidth: '30px',
                  borderBottomRightRadius: currentActive == 0 ? '7px' : '0px',
                  background: Colors.white,
                  marginRight: '-1px',
                }}
              />
              {menuList.map((menu, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottomRightRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    background: Colors.white,
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      cursor: 'pointer',
                      border:
                        currentActive === index
                          ? '1px solid #D9D9D9'
                          : 'transparent',
                      borderBottom: 'transparent',
                      borderTopLeftRadius:
                        currentActive === index ? '7px' : '0px',
                      borderTopRightRadius:
                        currentActive === index ? '7px' : '0px',
                      whiteSpace: 'nowrap',
                      background: Colors.white,
                      color:
                        currentActive === index
                          ? Colors.dark_orchid
                          : Colors.gray1,
                    }}
                    onClick={() => {
                      setcurrentActive(index);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        paddingX: '32px',
                        paddingTop: '14px',
                        paddingBottom: '14px',
                      }}
                    >
                      {menu}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      position: 'absolute',
                      bottom: '-1px',
                      left: currentActive + 1 === index ? '-1px' : '0px',
                      right: currentActive - 1 === index ? '-1px' : '0px',
                      borderRight:
                        currentActive - 1 === index
                          ? `1px solid #D9D9D9`
                          : '0px',
                      borderLeft:
                        currentActive + 1 === index
                          ? `1px solid  #D9D9D9`
                          : '0px',
                      minHeight: '6.3px',
                      background: Colors.white,
                      borderBottom:
                        currentActive === index
                          ? 'transparent'
                          : '1px solid #D9D9D9',
                      borderBottomRightRadius:
                        currentActive - 1 === index ? '32px' : '0px',
                      borderBottomLeftRadius:
                        currentActive + 1 === index ? '10px' : '0px',
                      borderTopLeftRadius: '3.2px',
                      borderTopRightRadius: '3.2px',
                    }}
                  />
                </Box>
              ))}
              <Box
                sx={{
                  borderBottom: '1px solid #D9D9D9',
                  marginLeft: '-1px',
                  height: '53px',
                  minWidth: '68px',
                  background: Colors.white,
                  borderLeft: `1px solid #D9D9D9`,
                  borderBottomLeftRadius:
                    currentActive == menuList.length - 1 ? '7px' : '0px',
                }}
              />
              <Box
                sx={{
                  borderBottom: '1px solid #D9D9D9',
                  height: '53px',
                  width: '100%',
                  background: Colors.white,
                }}
              />
            </Box>
          )}

          <Box
            className={classes.root}
            sx={{
              background: Colors.white,
              height: 'calc(100% - 0px)',
              overflowY: 'scroll',
            }}
          >
            <Box>
              {menuList[currentActive] === 'Templates' && questionTemplate()}
              {menuList[currentActive] === 'Job description' && (
                <DrawerRightJob
                  isCreateCampaign={true}
                  setAddNewJobTemplate={setAddNewJobTemplate}
                  addNewJobTemplate={addNewJobTemplate}
                />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ width: '100%', padding: '15px 0px 0px 0px' }}>
            <Box sx={{ width: '100%', height: '156px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '5px',
                  marginLeft: '44px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: Colors.black,
                  }}
                >
                  Templates
                </Typography>
                <Box>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                      if (
                        menuTemplates[currentActiveIndex] ===
                        'Question Templates'
                      ) {
                        setAddNewQuestion(true);
                      } else {
                        setAddNewJobTemplate(true);
                      }
                    }}
                    sx={{
                      height: '50px',
                      fontSize: '16px',
                    }}
                  >
                    <AddIcon color={'secondary'} sx={{ fontSize: 30 }} />
                    Create Template
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '44px',
                  width: 'fit-content',
                }}
                onClick={() => {
                  navigate(Urls.Campaign);
                }}
              >
                <BackIcon />
                <Typography
                  fontSize={'15px'}
                  sx={{
                    paddingTop: 0,
                    fontWeight: 600,
                    cursor: 'pointer',
                    paddingX: '12px',
                  }}
                  color={Colors.primary}
                  variant={'body1'}
                >
                  Back
                </Typography>
              </Box>

              {/* MENU TAB */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottomRightRadius: '6px',
                  borderBottomLeftRadius: '6px',
                  mt: '30px',
                }}
              >
                <Box
                  sx={{
                    borderBottom: '1px solid #D9D9D9',
                    height: '53px',
                    minWidth: '68px',
                    borderRight: `1px solid #D9D9D9`,
                    borderBottomRightRadius:
                      currentActiveIndex == 0 ? '7px' : '0px',
                    background: Colors.white,
                    marginRight: '-1px',
                  }}
                />
                {menuTemplates.map((menu, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      borderBottomRightRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      background: Colors.white,
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        cursor: 'pointer',
                        border:
                          currentActiveIndex === index
                            ? '1px solid #D9D9D9'
                            : 'transparent',
                        borderBottom: 'transparent',
                        borderTopLeftRadius:
                          currentActiveIndex === index ? '7px' : '0px',
                        borderTopRightRadius:
                          currentActiveIndex === index ? '7px' : '0px',
                        whiteSpace: 'nowrap',
                        background: Colors.white,
                        color:
                          currentActiveIndex === index
                            ? Colors.dark_orchid
                            : Colors.gray1,
                      }}
                      onClick={() => {
                        setcurrentActiveIndex(index);
                        setAddNewJobTemplate(false);
                        setAddNewQuestion(false);
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          paddingX: '32px',
                          paddingTop: '14px',
                          paddingBottom: '14px',
                        }}
                      >
                        {menu}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        position: 'absolute',
                        bottom: '-1px',
                        left: currentActiveIndex + 1 === index ? '-1px' : '0px',
                        right:
                          currentActiveIndex - 1 === index ? '-1px' : '0px',
                        borderRight:
                          currentActiveIndex - 1 === index
                            ? `1px solid #D9D9D9`
                            : '0px',
                        borderLeft:
                          currentActiveIndex + 1 === index
                            ? `1px solid  #D9D9D9`
                            : '0px',
                        minHeight: '6.3px',
                        background: Colors.white,
                        borderBottom:
                          currentActiveIndex === index
                            ? 'transparent'
                            : '1px solid #D9D9D9',
                        borderBottomRightRadius:
                          currentActiveIndex - 1 === index ? '10px' : '0px',
                        borderBottomLeftRadius:
                          currentActiveIndex + 1 === index ? '10px' : '0px',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '3.2px',
                      }}
                    />
                  </Box>
                ))}
                <Box
                  sx={{
                    borderBottom: '1px solid #D9D9D9',
                    borderLeft: `1px solid #D9D9D9`,
                    marginLeft: '-1px',
                    height: '53px',
                    minWidth: '68px',
                    background: Colors.white,
                    borderBottomLeftRadius:
                      currentActiveIndex == menuTemplates.length - 1
                        ? '7px'
                        : '0px',
                  }}
                />
                <Box
                  sx={{
                    borderBottom: '1px solid #D9D9D9',
                    height: '53px',
                    width: '100%',
                    background: Colors.white,
                  }}
                />
              </Box>
            </Box>

            <Box
              className={classes.root}
              sx={{
                background: Colors.white,
                height: 'calc(100vh - 280px)',
                overflowY: 'scroll',
                ml: '22px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                }}
              >
                {menuTemplates[currentActiveIndex] === 'Question Templates' && (
                  <>{questionTemplate()}</>
                )}
                {menuTemplates[currentActiveIndex] ===
                  'Job description Templates' && (
                  <>
                    <DrawerRightJob
                      setAddNewJobTemplate={setAddNewJobTemplate}
                      addNewJobTemplate={addNewJobTemplate}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
