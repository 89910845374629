import { createTheme } from '@mui/material/styles';
import { BackgroundColors, Colors, TextColors } from './colors';
import { ButtonThemeStyles } from './button-theme-styles';
import { IconButtonBaseThemeStyles } from './icon-button-base-theme-styles';
import { InputThemeStyles } from './input-base-theme-style';
import {
  TableThemeStyles,
  TableBodyThemeStyles,
} from './table-row-theme-styles';
import { TypographyThemeStyles } from './typography-theme-styles';
import { TextAreaFieldThemeStyles } from './text-area-fields-theme.styles';
import { PopoverThemeStyles } from './popover-theme-styles';

export const mainTheme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: Colors.dark_grey,
      light: Colors.light_grey,
      dark: Colors.dark_grey,
      contrastText: TextColors.white,
    },
    secondary: {
      main: TextColors.white,
      dark: Colors.dark_grey,
    },
    text: {
      primary: Colors.black,
      secondary: Colors.light_grey,
    },
  },
  components: {
    MuiButton: ButtonThemeStyles,
    MuiIconButton: IconButtonBaseThemeStyles,
    MuiInput: InputThemeStyles,
    MuiTable: TableThemeStyles,
    MuiTableBody: TableBodyThemeStyles,
    MuiTypography: TypographyThemeStyles,
    MuiTextField: TextAreaFieldThemeStyles,
    MuiPopover: PopoverThemeStyles,
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 100,
          height: 100,
          boxShadow: 'none',
          backgroundColor: BackgroundColors.white,
          justifyContent: 'center',
        },
      },
    },
  },
});
