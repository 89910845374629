import { ExpandMore } from '@mui/icons-material';
import { Colors } from '../../theme/colors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

const AccordionExpandable = ({
  expanded,
  children,
  onExpandAccordion,
  title,
}: any) => {
  return (
    <Accordion
      expanded={expanded}
      sx={{
        boxShadow: 'none',
        background: Colors.background_grey,
        borderRadius: '10px',
        width: '100%',
        marginY: '10px',
        '&.Mui-expanded': {
          margin: '0',
          paddingBottom: '10px',
          background: Colors.background_grey,
          borderRadius: '10px',
        },
        '&:before': {
          border: 'none',
          backgroundColor: Colors.background_grey,
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
        '&:first-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      }}
    >
      <AccordionSummary
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{
          flexDirection: 'row-reverse',
          padding: '8px',
          width: '100%',
          borderRadius: '10px',
          background: 'transparent',
          '&.Mui-expanded': {
            minHeight: 0,
            background: Colors.background_grey,
          },
          '& .MuiAccordionSummary-content': {
            margin: '0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            // paddingLeft: "10px",
            paddingRight: '5px',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
            alignItems: 'center',
          },
          '.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {},
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            color: Colors.philippine_gray,
          }}
          onClick={onExpandAccordion}
        >
          <ExpandMore
            style={{
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              width: '25px',
            }}
          />
          <>
            <Typography
              sx={{
                padding: 0,
                fontSize: '15px',
                fontWeight: 500,
                overflowWrap: 'break-word',
                textWrap: 'wrap',
              }}
            >
              {title}
            </Typography>
          </>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '4px 16px',
          background: Colors.background_grey,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionExpandable;
