import React, { Fragment } from 'react';
import { Typography, Menu, MenuItem } from '@mui/material';
import { ArrowBackIosOutlined } from '@mui/icons-material';

import { Colors } from '../../../theme/colors';
import { type } from 'os';

interface ISelectboxProps {
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  title: string;
  menuOption: string[];
  isCreatePage?: boolean;
  isNotBorder?: boolean;
  changeBoxValue: (data: any, id: any) => void;
  name: string;
}

const SelectboxforJobCategory = (props: ISelectboxProps) => {
  return (
    <Fragment>
      <div style={{ width: '100%', cursor: 'pointer' }}>
        <div
          onClick={(e) => {
            props.handleClick(e);
          }}
          id='basic-client-div'
          aria-controls={props.open ? 'basic-client-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={props.open ? 'true' : undefined}
          style={{
            display: 'flex',
            padding: '9px 11px',
            borderRadius: '8px',
            color: Colors.outerSpace,
            border: `1px solid ${
              props?.isCreatePage
                ? props?.isNotBorder
                  ? 'transparent'
                  : '#D0D0D0'
                : '#CBDFFF'
            }`,
            background: props?.isCreatePage
              ? Colors.background_grey
              : Colors.white,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Typography
              sx={{
                paddingTop: 0,
                fontSize: props?.isCreatePage ? '12px' : '14px',
                fontWeight: props?.isCreatePage ? 400 : 600,
              }}
              variant={'h6'}
            >
              {props?.title}
            </Typography>{' '}
          </div>
          <div>
            <ArrowBackIosOutlined
              sx={{
                transform: `rotate(-90deg)`,
                color: Colors.philippine_gray,
                width: '15px',
                marginRight: '13px',
              }}
            />
          </div>
        </div>
        <div>
          <Menu
            id='basic-client-menu'
            anchorEl={props.anchorEl}
            sx={{
              '& ul': {
                paddingTop: '8px',
                paddingBottom: '0px',
                height:
                  props.menuOption && props.menuOption.length > 10
                    ? '300px'
                    : 'auto',
              },
              '& .css-rfwwdb-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
                {
                  borderRadius: '8px',
                  boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 20px',
                },
            }}
            open={Boolean(props.anchorEl)}
            onClose={() => {
              props.handleClose();
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-client-div',
              sx: {
                width: props.anchorEl && props.anchorEl.offsetWidth,
              },
            }}
          >
            <div>
              {props.menuOption
                ? props.menuOption.map((typeTitle: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <MenuItem
                          sx={{
                            lineHeight: 1.5,
                            fontSize: '14px',
                            color: '#464646',
                            fontWeight: 400,
                          }}
                          onClick={() => {
                            props?.changeBoxValue(
                              `${
                                props.name === 'MainCategory'
                                  ? typeTitle?.JobName
                                  : typeTitle.JobSubCategoryName
                              }`,
                              `${
                                props.name === 'MainCategory'
                                  ? typeTitle.JobId
                                  : typeTitle.JobSubCategoryId
                              }`
                            );
                          }}
                        >
                          {props.name === 'MainCategory'
                            ? typeTitle?.JobName
                            : typeTitle.JobSubCategoryName}
                        </MenuItem>
                      </Fragment>
                    );
                  })
                : null}
            </div>
          </Menu>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectboxforJobCategory;
