import React from 'react';
import { Input, InputAdornment, Box } from '@mui/material';
import { BackgroundColors, Colors } from '../theme/colors';

import Search from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom';
import { ReactComponent as DollarIcon } from "../images/icons/dollar.svg";
interface TextInputProps {
  type?: string;
  // | "button" //Literal Union
  // | "email"
  // | "file"
  // | "hidden"
  // | "number"
  // | "search"
  // | "submit"
  // | "tel"
  // | "password"
  // | "text";
  name?: string | undefined;
  value: any;
  onChange: (value: any) => void;
  onBlur?: () => void;
  disabled?: boolean;
  variant?: string;
  width?: number;
  background?: string;
  placeholder?: string;
  leftIcon?: boolean;
  border?: string;
  iconColor?: string;
  iconSize?: number;
  padding?: string;
  multiline?: boolean;
  height?: string;
  maxWidth?: string;
  textTransform?: string;
  isComment?: boolean;
  setIscallApiByEnter?: any;
  iscallApiByEnter?: boolean;
  isIcopn?: boolean;
  readOnly?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  const location = useLocation();
  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    var code = e.keyCode || e.which;
    if (props?.isComment && code === 13) {
      if (!props?.iscallApiByEnter) {
        props?.setIscallApiByEnter(true);
      }
    }
  };

  return (
    <Input
      autoFocus={props?.isComment ? true : false}
      disabled={props.disabled}
      type={props.type}
      name={props.name}
      multiline={props.multiline}
      value={props.value}
      placeholder={props.placeholder}
      readOnly={props.readOnly ? true : false}
      sx={{
        width: props.width ? `${props.width}%` : '100%',
        height: props.height ? props.height : '45px',
        backgroundColor:
          location.pathname == props.background
            ? props.background
            : Colors.background_grey,
        border: props.border ? props.border : '1px solid black',
        padding: props.padding ? props.padding : '10px',
        maxWidth: props.maxWidth ? props.maxWidth : '100%',
        '& input': {
          padding: 0,
          textTransform: props.textTransform ? props.textTransform : 'none',
        },
      }}
      onBlur={props.onBlur}
      onChange={(e) => {
        e.preventDefault();
        return props.onChange(e.target.value);
      }}
      onKeyPress={(e) => handleKeypress(e)}
      size="small"
      startAdornment={
        props.leftIcon && (
          <InputAdornment position="start">
            {props?.isIcopn ? <Box><DollarIcon /></Box> : <Search
              sx={{
                fontSize: props.iconSize ? props.iconSize : 30,
                color: props.iconColor ? props.iconColor : 'black',
              }}
            />}
            
          </InputAdornment>
        )
      }
    />
  );
};
