import React, { useState, useEffect } from 'react';
import { Box, IconButton, ListItem, Stack, Typography } from '@mui/material';
import { Check, Close, PlayArrow, Pause } from '@mui/icons-material';
import { useAudio } from '../../../Hooks/useAudio';
import { TextInput } from '../../../ui/InputFields';
import { Colors } from '../../../theme/colors';
import { Questions, Comment } from '../../../modals/modals';
import { ReactComponent as DragIcon } from '../../../images/icons/DragIcon.svg';
import { ReactComponent as EditIcon } from '../../../images/icons/Editicon.svg';
import { ReactComponent as DeleteIcon } from '../../../images/icons/bin.svg';
import UserMenu from '../../../components/dashboard/UserMenu/UserMenu';
import { formatDateAndTimeToString } from '../../../../src/utils/dateFormatter';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByIDSlice } from '../../../redux/user/user-slice';
import { toastFailure } from '../../../utils/toast';
import { AppDispatch } from '../../../redux/store';
import { useDrag, useDrop } from 'react-dnd';
import { Mention, MentionsInput } from 'react-mentions';
// import mentionsInputStyles from '../mentionsInputStyles';
import mentionStyles from './mentionStyles';
import mentionInputEditStyle from './mentionInputEditStyle';
import { useLocation } from 'react-router-dom';

type EType = 'question' | 'comment';

interface IListsProps<IType extends EType> {
  id: number;
  listtext: string;
  type: IType;
  pageType?: string;
  item?: any;
  isComment?: boolean;
  index?: number;
  playAudioIndex?: number;
  onDelete?: (question: Questions) => void;
  onDeleteComment?: (question: Comment) => void;
  onUpdate?: (questionId: number, value: string) => void;
  setPlayAudioIndex?: any;
  isQuestion?: string;
  moveItem?: (dragIndex: number, hoverIndex: number) => void;
  commentColor?: string;
  userListData?: any;
  setMentionsData?: any;
}

interface commentUserType {
  User_Initials: string;
  User_BackgroundColour: string;
  User_Colour: string;
  User_FirstName: string;
  User_SecondName: string;
}

type MyItem = {
  id: number;
  name: string;
};

type DragItem = {
  item: any;
  originalIndex: number;
};

const Lists = (props: IListsProps<EType>) => {
  const location = useLocation();
  const [playing, toggleAudio] = useAudio(
    props?.item?.Question_AudioFileUrl || ''
  );
  const dispatch = useDispatch<AppDispatch>();
  const [commentUserData, setCommentUserData] =
    useState<commentUserType | null>(null);
  const userData = useSelector((state: any) => state.user);

  useEffect(() => {
    if (props?.playAudioIndex != props?.index) {
      toggleAudio(false);
    }
  }, [props?.playAudioIndex]);

  const [isEditable, setEditable] = useState<boolean>(false);
  const [listTextValue, setListTextValue] = useState<string>('');
  const [isCallCommetApi, setIsCallCommetApi] = useState<boolean>(false);

  const [{ isDragging }, drag] = useDrag({
    type: 'box',
    item: { item: props?.item, originalIndex: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'box',
    hover: (item: DragItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) {
        return;
      }
      const dragIndex = item.originalIndex;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }
      props?.moveItem !== undefined &&
        props?.moveItem(dragIndex, hoverIndex || 0);
      item.originalIndex = hoverIndex || 0;
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  useEffect(() => {
    if (props.type == 'comment') {
      dispatch(getUserByIDSlice(props?.item?.fk_User_ID))
        .unwrap()
        .then((originalPromiseResult: any) => {
          if (originalPromiseResult.response.successful) {
            setCommentUserData({
              User_Initials:
                originalPromiseResult?.response?.data?.User_Initials,
              User_BackgroundColour:
                originalPromiseResult?.response?.data?.User_BackgroundColour,
              User_Colour: originalPromiseResult?.response?.data?.User_Colour,
              User_FirstName:
                originalPromiseResult?.response?.data?.User_FirstName,
              User_SecondName:
                originalPromiseResult?.response?.data?.User_SecondName,
            });
          }
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    }
  }, [props?.item]);

  useEffect(() => {
    if (isCallCommetApi) {
      if (props.onUpdate) {
        props.onUpdate(props.id, listTextValue);
      }
      setEditable(!isEditable);
      setListTextValue('');
      setIsCallCommetApi(false);
      // onEdit(question.id);
    }
  }, [isCallCommetApi]);

  return (
    <React.Fragment>
      {props.type === 'question' &&
      props.pageType === 'create' &&
      props.isQuestion === 'ForDragAndDrop' ? (
        <>
          <div
            ref={drop}
            style={{
              borderRadius: '4px',
              border: '1px solid rgb(255, 255, 255)',
              margin: '10px 0px',
            }}
          >
            <div
              key={props.id}
              ref={drag}
              style={{
                background: Colors.white,
                display: 'flex',
                width: '100%',
                minHeight: isEditable ? '55px' : '45px',
                // justifyContent: 'space-between',
                alignItems: 'center',
                // background: 'rgb(255, 255, 255)',
              }}
            >
              <div style={{ margin: '0px 8px', cursor: 'move' }}>
                {props.pageType === 'create' && (
                  <DragIndicatorIcon style={{ color: '#2e88dd' }} />
                )}
              </div>
              <div style={{ width: isEditable ? '84%' : '80%' }}>
                {isEditable ? (
                  <TextInput
                    isComment={true}
                    placeholder={'Please fill in details'}
                    value={listTextValue}
                    onChange={(value) => {
                      setListTextValue(value);
                    }}
                    border={`1px solid ${Colors.default}`}
                    width={85}
                  />
                ) : (
                  <div
                    style={{
                      paddingTop: 0,
                      color: Colors.primary,
                      width: 'calc(100% - 75px)',
                      maxWidth: '74%',
                      fontWeight: 500,
                      fontSize: '14px',
                      fontFamily: 'Poppins,Arial,sans-serif',
                    }}
                  >
                    {props?.item?.Tironem_Question_Text}
                  </div>
                )}
              </div>

              {isEditable ? (
                <div
                  style={{
                    display: 'flex',
                    width: '10%',
                    paddingRight: '8px',
                    justifyContent: 'start',
                  }}
                >
                  <IconButton
                    edge='end'
                    onClick={() => {
                      if (props.onUpdate) {
                        props.onUpdate(props.id, listTextValue);
                      }
                      setEditable(!isEditable);
                      setListTextValue('');
                      // onEdit(question.id);
                    }}
                    aria-label='check'
                  >
                    <Check
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                    />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='close'
                    onClick={() => {
                      setEditable(!isEditable);
                      setListTextValue('');
                    }}
                  >
                    <Close
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                    />
                  </IconButton>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: '8px',
                    width: '15%',
                  }}
                >
                  {!!props?.item?.Question_AudioFileUrl && (
                    <IconButton
                      edge='end'
                      onClick={() => {
                        if (playing) {
                          toggleAudio(false);
                        } else {
                          toggleAudio(true);
                          props.setPlayAudioIndex(props.index);
                        }
                      }}
                      aria-label='play'
                    >
                      {playing ? (
                        <>
                          <Pause
                            sx={{
                              color: Colors.primary,
                              fontSize: 25,
                            }}
                          />
                        </>
                      ) : (
                        <PlayArrow
                          sx={{
                            color: Colors.primary,
                            fontSize: 25,
                          }}
                        />
                      )}
                    </IconButton>
                  )}
                  {props.pageType === 'create' && (
                    <IconButton
                      edge='end'
                      onClick={() => {
                        setEditable(!isEditable);
                        setListTextValue(props.listtext);
                        // onEdit(question.id);
                      }}
                      aria-label='edit'
                    >
                      <EditIcon style={{ marginLeft: '10px' }} />
                    </IconButton>
                  )}
                  {props.pageType === 'create' && (
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        if (props.onDelete) {
                          if (props.type == 'question') {
                            props.onDelete(props.item as Questions);
                          }
                        } else if (props.onDeleteComment) {
                          props.onDeleteComment(props.item as Comment);
                        }
                      }}
                    >
                      <DeleteIcon style={{ marginLeft: '10px' }} />
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            borderRadius: '4px',
            margin: '10px 0',
            border: `1px solid ${Colors.white}`,
          }}
        >
          <ListItem
            key={props.id}
            sx={{
              background: Colors.white,
              alignItems: 'center',
            }}
            secondaryAction={
              isEditable ? (
                <>
                  <IconButton
                    edge='end'
                    onClick={() => {
                      if (props.onUpdate) {
                        props.onUpdate(props.id, listTextValue);
                      }
                      setEditable(!isEditable);
                      setListTextValue('');
                      // onEdit(question.id);
                    }}
                    aria-label='check'
                  >
                    <Check
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                    />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='close'
                    onClick={() => {
                      setEditable(!isEditable);
                      setListTextValue('');
                    }}
                  >
                    <Close
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                    />
                  </IconButton>
                </>
              ) : (
                <Stack direction='row' alignItems={'center'}>
                  {props.type == 'question' &&
                    !!props?.item?.Question_AudioFileUrl && (
                      <IconButton
                        edge='end'
                        onClick={() => {
                          if (playing) {
                            toggleAudio(false);
                          } else {
                            toggleAudio(true);
                            props.setPlayAudioIndex(props.index);
                          }
                        }}
                        aria-label='play'
                      >
                        {playing ? (
                          <>
                            <Pause
                              sx={{
                                color: Colors.primary,
                                fontSize: 25,
                              }}
                            />
                          </>
                        ) : (
                          <PlayArrow
                            sx={{
                              color: Colors.primary,
                              fontSize: 25,
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                  {(props.pageType === 'create' || props.pageType === 'edit') &&
                    props?.isComment && (
                      <div
                        style={{
                          color: '#979797',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        {formatDateAndTimeToString(
                          props?.item?.Comment_DateOfCreation
                        )}
                      </div>
                    )}
                  {props.pageType === 'create' && (
                    <IconButton
                      edge='end'
                      onClick={() => {
                        setEditable(!isEditable);
                        setListTextValue(props.listtext);
                        // onEdit(question.id);
                      }}
                      aria-label='edit'
                    >
                      <EditIcon style={{ marginLeft: '10px' }} />
                    </IconButton>
                  )}
                  {props.pageType === 'create' && (
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        if (props.onDelete) {
                          if (props.type == 'question') {
                            props.onDelete(props.item as Questions);
                          }
                        } else if (props.onDeleteComment) {
                          props.onDeleteComment(props.item as Comment);
                        }
                      }}
                    >
                      <DeleteIcon style={{ marginLeft: '10px' }} />
                    </IconButton>
                  )}
                  {props.pageType === 'edit' && props?.isComment && (
                    <IconButton
                      edge='end'
                      onClick={() => {
                        if (
                          userData?.userData?.pk_User_ID ==
                          props?.item?.fk_User_ID
                        ) {
                          setEditable(!isEditable);
                          setListTextValue(props.listtext);
                        } else {
                          toastFailure("Can't update other user's comment");
                        }
                      }}
                      aria-label='edit'
                    >
                      <EditIcon style={{ marginLeft: '10px' }} />
                    </IconButton>
                  )}
                  {props.pageType === 'edit' && props?.isComment && (
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={() => {
                        if (
                          userData?.userData?.pk_User_ID ==
                          props?.item?.fk_User_ID
                        ) {
                          if (props.onDeleteComment) {
                            props.onDeleteComment(props.item as Comment);
                          }
                        } else {
                          toastFailure("Can't delete other user's comment");
                        }
                      }}
                    >
                      <DeleteIcon style={{ marginLeft: '10px' }} />
                    </IconButton>
                  )}
                </Stack>
              )
            }
          >
            {/* NOTE Left side Icon or image */}
            <>
              <IconButton
                sx={{
                  padding: 0,
                  paddingRight: '8px',
                }}
                onClick={() => console.log('Drag')}
              >
                {props.type == 'comment' ? (
                  <>
                    <UserMenu
                      isHeader={false}
                      User_Initials={commentUserData?.User_Initials}
                      User_FirstName={commentUserData?.User_FirstName}
                      User_SecondName={commentUserData?.User_SecondName}
                      User_BackgroundColour={
                        commentUserData?.User_BackgroundColour
                      }
                      User_Colour={commentUserData?.User_Colour}
                      isAnswerTab={true}
                    />
                  </>
                ) : (
                  // <div
                  //   style={{
                  //     width: "26px",
                  //     height: "26px",
                  //     background: Colors.lavender_blue,
                  //     borderRadius: "50%",
                  //     fontSize: "8px",
                  //     display: "flex",
                  //     alignItems: "center",
                  //     justifyContent: "center",
                  //     color: Colors.primary,
                  //   }}
                  // >
                  //   VMS
                  // </div>
                  // <>{props.pageType === "create" && <DragIcon />}</>
                  <>
                    {props.pageType === 'create' && (
                      <DragIndicatorIcon style={{ color: '#2e88dd' }} />
                    )}
                  </>
                )}
              </IconButton>
            </>
            {/* NOTE ListItemText or TextInput for list */}
            <>
              {isEditable ? (
                // <TextInput
                //   isComment={true}
                //   placeholder={'Please fill in details'}
                //   value={listTextValue}
                //   onChange={(value) => {
                //     setListTextValue(value);
                //   }}
                //   border={`1px solid ${Colors.default}`}
                //   width={80}
                // />
                <Box sx={{ width: '80%', maxWidth: '348px' }}>
                  <MentionsInput
                    singleLine
                    style={mentionInputEditStyle}
                    value={listTextValue}
                    onChange={(e) => setListTextValue(e.target.value)}
                    placeholder='Add a comment'
                  >
                    <Mention
                      style={mentionStyles}
                      data={props.userListData ? props.userListData : []}
                      trigger={'@'}
                      onAdd={(id, display) =>
                        props.setMentionsData((prevMentions: any) => [
                          ...prevMentions,
                          { id, display },
                        ])
                      }
                    />
                  </MentionsInput>
                </Box>
              ) : (
                <Typography
                  sx={{
                    paddingTop: 0,
                    color: props?.commentColor || Colors.primary,
                    width: 'calc(100% - 75px)',
                    maxWidth:
                      location.pathname === '/campaign/edit'
                        ? '270px'
                        : location.pathname === '/campaign/create'
                        ? '75%'
                        : '345px',
                    overflowWrap: 'break-word',
                    textWrap: 'wrap',
                  }}
                  fontWeight={500}
                  fontSize={'14px'}
                  variant='body1'
                >
                  {props.listtext}
                </Typography>
              )}
            </>
          </ListItem>
        </div>
      )}
    </React.Fragment>
  );
};

export default Lists;
