import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  createQuestionTemplate,
  deleteQuestionTemplate,
  getAllQuestionTemplate,
  updateQuestionTemplate,
} from '../../api/questiontemplate';
import { QuestionTemplateCreate, QuestionTemplate } from '../../modals/modals';
import { toastFailure, toastSuccess } from '../../utils/toast';

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export interface QuestionState {
  questionstemplate: QuestionTemplate[];
}
interface UpdateQuestionTemplates {
  item: QuestionTemplate;
  index: number;
}

const initialState: QuestionState = {
  questionstemplate: [],
};

export const fetchQuestionTemplates = createAsyncThunk(
  'questionstemplate/fetch',
  async () => {
    return {
      questionsResponse: await getAllQuestionTemplate(),
    };
  }
);

export const createQuestionTemplates = createAsyncThunk(
  'questionstemplate/create',
  async (data: QuestionTemplate) => {
    return {
      response: await createQuestionTemplate(data),
    };
  }
);

export const updateQuestionTemplates = createAsyncThunk(
  'questionstemplate/update',
  async (data: QuestionTemplate) => {
    return {
      response: await updateQuestionTemplate(data),
    };
  }
);

export const deleteQuestionTemplates = createAsyncThunk(
  'questionstemplate/delete',
  async (data: string) => {
    return {
      response: await deleteQuestionTemplate(data),
    };
  }
);

export const questionTemplatesSlice = createSlice({
  name: 'questionstemplate',
  initialState,
  reducers: {
    updateQuestionTemplateReducer: (
      state,
      action: PayloadAction<UpdateQuestionTemplates>
    ) => {
      const indexValue: number = action.payload.index;
      state.questionstemplate[indexValue] = action.payload.item;
    },
    deleteQuestionTemplateReducer: (state, action) => {
      const filterState = state.questionstemplate.filter((question) => {
        return (
          question.pk_QuestionTemplate_ID !=
          action.payload.item.pk_QuestionTemplate_ID
        );
      });

      state.questionstemplate = filterState;
    },
  },
  extraReducers: (builder) => {
    // get all question templates
    builder.addCase(fetchQuestionTemplates.fulfilled, (state, action) => {
      if (action.payload.questionsResponse.successful) {
        state.questionstemplate = action.payload.questionsResponse.data;
      } else {
        if (!action.payload.questionsResponse.successful) {
          toastFailure(action.payload.questionsResponse.message);
        }
      }
    });

    //  create comment extra reducer
    builder.addCase(createQuestionTemplates.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    // update question templates
    builder.addCase(updateQuestionTemplates.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    // update question templates
    builder.addCase(deleteQuestionTemplates.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });
  },
});
export const { updateQuestionTemplateReducer, deleteQuestionTemplateReducer } =
  questionTemplatesSlice.actions;

export default questionTemplatesSlice.reducer;
