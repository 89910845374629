import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  getComments,
  createComments,
  deleteComments,
  updateComments,
  getCampaignComments,
  getAnswersComments,
  createNotification,
  getNotificationById,
  updateNotification
} from "../../api/Comment";
import { toastFailure, toastSuccess } from "../../utils/toast";
import { Comment, Notification, commentCreate } from "../../modals/modals";

export enum StoreStage {
  Initial,
  Loading,
  Error,
}
export interface commentsState {
  comments: Comment[];
  campaigncomments: Comment[];
  answerscomments: Comment[];
  notifications: Notification[];
}

const initialState: commentsState = {
  comments: [],
  campaigncomments: [],
  answerscomments: [],
  notifications:[]
};
interface UpdateQuestion {
  item: Comment;
  index: number;
}

export const fetchComments = createAsyncThunk("comments/fetch", async (data: number) => {
  return {
    commentsResponse: await getComments(data),
  };
});

export const getCampaignComment = createAsyncThunk("comments/campaign/get", async () => {
  return {
    commentsResponse: await getCampaignComments(),
  };
});

export const getAnswersComment = createAsyncThunk("comments/answers/get", async (data: any) => {
  return {
    commentsResponse: await getAnswersComments(data),
  };
});

export const createComment = createAsyncThunk(
  "comments/create",
  async (data: any) => {
    return { response: await createComments(data) };
  }
);

export const updateComment = createAsyncThunk(
  "comments/update",
  async (data: Comment) => {
    return { response: await updateComments(data) };
  }
);

export const deleteComment = createAsyncThunk(
  "comments/delete",
  async (data: Comment) => {
    return { response: await deleteComments(data) };
  }
);
export const createNotifications = createAsyncThunk(
  'notification/create',
  async (data: any) => {
    return { response: await createNotification(data) };
  }
);
export const getNotificationsByIds = createAsyncThunk(
  'notification/getById',
  async (id: any) => {
    return { response: await getNotificationById(id) };
  }
);
export const updateNotifications = createAsyncThunk(
  'notification/update',
  async (data: any) => {
    return { response: await updateNotification(data) };
  }
);

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    updateCommentsListReducer: (state, action: PayloadAction<{ item: Comment[] }>) => {
        state.campaigncomments = action.payload.item;
      },
    deleteCommentReducer: (state, action: PayloadAction<{ item: Comment }>) => {
      const filteredQuestions = state.campaigncomments.filter(
        (comment) =>
        comment.pk_Comment_ID !== action.payload.item.pk_Comment_ID
      );
      state.campaigncomments = filteredQuestions;
    },
    clearComment: (state) =>{
      state.comments = []
    }
  },
  extraReducers: (builder) => {
    // fetch comments
    builder.addCase(fetchComments.pending, (state) => {});
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      if (action.payload.commentsResponse.successful) {
        state.comments = action.payload.commentsResponse.data.reverse();
      } else {
        if (!action.payload.commentsResponse.successful) {
          toastFailure(action.payload.commentsResponse.message);
        }
      }
    });
    builder.addCase(fetchComments.rejected, (state) => {
      // console.log('REJECT===>', state);
      // state.campaigns = StoreStage.Error;
    });

    // get campaign comments
    builder.addCase(getCampaignComment.fulfilled, (state, action) => {
      if(action.payload?.commentsResponse && action.payload?.commentsResponse?.successful){
        state.campaigncomments = action.payload.commentsResponse?.data.reverse();
      }
    });

    // get answers Comments
    builder.addCase(getAnswersComment.fulfilled, (state, action) => {
      if(action.payload.commentsResponse.successful){
        state.answerscomments = action.payload.commentsResponse.data;
      }
    });

    //  create comment extra reducer
    builder.addCase(createComment.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    // update comment extra reducer

    builder.addCase(updateComment.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    //  delete comment extra reducer
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    //  get notifications reducers
    builder.addCase(getNotificationsByIds.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
          state.notifications = action.payload.response.data;
      }
    });
  },
});
export const { deleteCommentReducer, clearComment, updateCommentsListReducer } =
  commentsSlice.actions;
export default commentsSlice.reducer;
