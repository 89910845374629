import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { useDrag } from 'react-dnd';
import { IJobTemplatesResponse } from '../../modals/modals';
import { Check, Close, Clear } from '@mui/icons-material';
import { Colors } from '../../theme/colors';
import { TextInput } from '../../ui/InputFields';
import { useAppDispatch } from '../../redux/hooks';
import { ReactComponent as DragIcon } from '../../images/icons/DragIcon.svg';
import { ReactComponent as EditIcon } from '../../images/icons/Editicon.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/bin.svg';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ReactQuill from 'react-quill';
import { deleteJobDescription, fetchJobTemplate, updateJobDescription } from '../../redux/jobTemplates/jobtemplates-slice';
import { toastSuccess } from '../../utils/toast';
import { useLocation } from 'react-router-dom';

interface QuestionTemplatesProps {
  item: IJobTemplatesResponse;
  questionIndex: number;
  specificTemplateId?: number;
}

export const JobTemplates = ({
  item,
  questionIndex,
  specificTemplateId,
}: QuestionTemplatesProps) => {
  const dispatch = useAppDispatch();
  const [isEditable, setEditable] = useState<boolean>(false);
  const [editQuestionValue, setEditQuestionValue] = useState<string>('');
  const [{ isDragging: isDraggingJobList }, dragJobItem] = useDrag(() => ({
    type: 'jobItem',
    item: { data: [item] },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    setEditQuestionValue(item.Job_Description || '');
  }, [item]);

  const submitUpdateQuestion = () => {
    
    const existingItem = Object.assign({}, item);
    
    if (existingItem.Job_Description != editQuestionValue) {
      existingItem.Job_Description = editQuestionValue;

      dispatch(updateJobDescription(existingItem))
        .unwrap()
        .then(() => {
          const dataObj = {
            jobId: 0,
            enterpriseId: existingItem.fk_Enterprise_ID,
          };
          // dispatch(fetchTemplatesQuestionsById(templateData.pk_Template_ID));
          dispatch(fetchJobTemplate(dataObj));
        })
        .catch(() => {});
      
    }
  };

  const deleteJobDesc = (itemData: any) => {    
    if (itemData?.pk_JobDescription_ID) {
      const updateJobData = {
        ...itemData,
        Job_Description : "",
      };
      dispatch(updateJobDescription(updateJobData)).unwrap().then((responseUpdate) => {
        toastSuccess("Job description deleted")
        dispatch(deleteJobDescription(updateJobData));
      })
      
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      // ['link'],
      ['clean'],
    ],
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
  ];
  const formatsCreate = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
  ]
  const location = useLocation()
  

  return (
    <div
      ref={dragJobItem}
      style={{
        borderRadius: '4px',
        border: `1px solid ${Colors.white}`,
        margin: '10px 0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          minHeight: 45,
          justifyContent: 'space-between',
          alignItems: 'center',
          background: Colors.white,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 70px)',
          }}
        >
          {/* <DragIcon
            style={{ padding: "0px 8px", cursor: "move", minWidth: "19px" }}
        /> */}
          {location.pathname !== '/job-template' && (
            <>
              <DragIndicatorIcon
                style={{
                  padding: '0px 8px',
                  cursor: 'move',
                  minWidth: '19px',
                  color: '#2e88dd',
                }}
              />
            </>
          )}
          {isEditable ? (
            // <TextInput
            //   value={editQuestionValue}
            //   onChange={(value) => {
            //     setEditQuestionValue(value);
            //   }}
            //   border={`1px solid ${Colors.default}`}
            //   placeholder={'Please fill in details'}
            //   width={90}
            // />
            <div
              style={{
                width: '100%',
                height: '100%',
                maxHeight: '230px',
                overflowY: 'hidden',
                margin: '6px 0px',
              }}
              className="jobListEdit"
            >
              <ReactQuill
                modules={modules}
                formats={formats}
                value={editQuestionValue || ''}
                className="job-title"
                placeholder="Enter job description..."
                onChange={(content, delta, source, editor) => {
                  
                  setEditQuestionValue(content);
                }}
              />
            </div>
          ) : (
            <Typography
              sx={{
                paddingTop: 0,
                color: Colors.black,
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                width: '100%',
                height: '100%',
              }}
              fontWeight={500}
              fontSize={'16px'}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '200px',
                  overflowY: 'auto',
                  margin: '6px 10px 6px 0px',
                }}
                className="jobDisplay"
              >
                <ReactQuill
                  readOnly
                  modules={modules}
                  formats={formats}
                  value={item.Job_Description}
                  className="job-title"
                  placeholder="Enter job description..."
                />
              </div>
            </Typography>
          )}
        </div>
        <div style={{ width: '70px', display: 'flex', alignSelf: 'end' }}>
          {isEditable ? (
            <>
              <IconButton edge="end" aria-label="check">
                <Check
                  sx={{
                    color: Colors.primary,
                    fontSize: 25,
                  }}
                  onClick={() => {
                    setEditable(!isEditable);
                    submitUpdateQuestion();

                    // onEdit(question.id); already
                  }}
                />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="check"
                onClick={() => {
                  setEditable(!isEditable);
                  setEditQuestionValue('');
                  // onEdit(question.id);
                }}
              >
                <Clear
                  sx={{
                    color: Colors.primary,
                    fontSize: 25,
                  }}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => {
                  setEditQuestionValue(item.Job_Description || '');
                  setEditable(!isEditable);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  deleteJobDesc(item);
                }}
              >
                <DeleteIcon style={{ marginLeft: '10px' }} />
              </IconButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
