import React from 'react';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Colors } from '../../theme/colors';
import { toastFailure } from '../../utils/toast';
import { Campaigns } from '../../modals/modals';

interface LocationPropsState {
  item: Campaigns;
}

interface CardHeaderProps {
  setAddNewQuestion?: (value: any) => void;
  state: LocationPropsState | {};
  title: string;
  buttonTxt?: string;
  extraButton?: any;
  pageType?: string;
  isAnswersComment?: boolean;
  index?: number;
  settingValue?: string;
  headerColor?: string;
}

const CardHeader = (props: CardHeaderProps) => {
  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography
            color={props.headerColor ?? Colors.primary}
            fontSize={'14px'}
            variant='h6'
          >
            {props.title}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.extraButton && props.extraButton()}
          {/* {props?.buttonTxt && (
            <Button
              variant={'text'}
              sx={{
                color: Colors.primary,
                fontSize: 12,
                fontWeight: 600,
              }}
              onClick={() => {
                // setNewQuestion('');
                if (!props.state) {
                  toastFailure('Please enter campaign title!');
                } else {
                  if (props.setAddNewQuestion) {
                    if (props?.isAnswersComment) {
                      props?.setAddNewQuestion(props?.index || null);
                    } else {
                      props?.setAddNewQuestion(true);
                    }
                  }
                }
              }}
            >
              <AddIcon
                sx={{
                  color: Colors.primary,
                  fontSize: 20,
                }}
              />
              {props.buttonTxt}
            </Button>
          )} */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardHeader;
