import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  dashboardInterView,
  dashboardCampaign,
  dashboardLatestInterview,
  dashboardInvites,
  getResumeByCampaignLine,
  getIntervideoByCampaignLine,
  topSixCampaign,
  topFiveCandidateByCampaign,
} from '../../api/dashboard';
import { dashboradInterviewCampaign } from '../../modals/modals';

export const dashboradInterviewData = createAsyncThunk(
  'dashboard/interview',
  async (data: dashboradInterviewCampaign) => {
    return {
      response: await dashboardInterView(data),
    };
  }
);
export const dashboradCampaignData = createAsyncThunk(
  'dashboard/campaign',
  async (data: dashboradInterviewCampaign) => {
    return {
      response: await dashboardCampaign(data),
    };
  }
);
export const dashboardInvitesData = createAsyncThunk(
  'CampaignLine/GetInvitesPerMonth',
  async (data: dashboradInterviewCampaign) => {
    return {
      response: await dashboardInvites(data),
    };
  }
);
export const dashboardLatestInterviewData = createAsyncThunk(
  'dashboard/latestinterview',
  async (userId: number) => {
    return {
      response: await dashboardLatestInterview(userId),
    };
  }
);
export const dashboardTopSixCampaign = createAsyncThunk(
  'dashboard/latestCampaign',
  async (id: number) => {
    return {
      response: await topSixCampaign(id),
    };
  }
);
export const topFiveCandidateByCampaignId = createAsyncThunk(
  'dashboard/topFiveCampaignById',
  async (campaignId: number) => {
    return {
      response: await topFiveCandidateByCampaign(campaignId),
    };
  }
);
export const getResumeByCampaignLines = createAsyncThunk(
  'candidate/resumeByCampaignline',
  async (emailId: any) => {
    return {
      response: await getResumeByCampaignLine(emailId),
    };
  }
);
export const getInterviewvideoCampaignLines = createAsyncThunk(
  'candidate/interviewvideo',
  async (emailId: any) => {
    return {
      response: await getIntervideoByCampaignLine(emailId),
    };
  }
);

interface DashboardState {
  token: string;
  interviewLength: any;
  campaignLength: any;
  inviteLength: any;
  LatestInterview: any;
  isSideBarOpenLeft: boolean;
  isSideBarOpenRight: boolean;
}

const initialState: DashboardState = {
  token: '',
  interviewLength: null,
  campaignLength: null,
  inviteLength: null,
  LatestInterview: null,
  isSideBarOpenLeft: false,
  isSideBarOpenRight: false,
};

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardReducer: (state, action) => {
      state.token = action.payload.token;
    },
    setIsSideBarOpenLeft: (state, action) => {
      state.isSideBarOpenLeft = action.payload;
    },
    setIsSideBarOpenRight: (state, action) => {
      state.isSideBarOpenRight = action.payload;
    },
    clearDashboardData: (state, action) => {
      if (action.payload != null) {
        state.interviewLength = null;
        state.campaignLength = null;
        state.campaignLength = null;
        state.inviteLength = null;
        state.LatestInterview = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dashboradInterviewData.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.interviewLength = action.payload.response.data.length;
      }
    });
    builder.addCase(dashboradCampaignData.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.campaignLength = action.payload.response.data.length;
      }
    });
    builder.addCase(dashboardInvitesData.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.inviteLength = action.payload.response.data.length;
      }
    });
    builder.addCase(dashboardLatestInterviewData.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.LatestInterview = action.payload.response.data[0];
      }
    });
  },
});

export const {
  dashboardReducer,
  setIsSideBarOpenLeft,
  setIsSideBarOpenRight,
  clearDashboardData,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
