import { QuestionTemplate } from '../modals/modals';
import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';

export const getAllQuestionTemplate = (): Promise<Response<QuestionTemplate[]>> =>
    generalMiddleware.request({
        url: '/QuestionTemplate',
        method: 'GET',
    });

export const createQuestionTemplate = (
    question: QuestionTemplate
): Promise<Response<string>> =>
    generalMiddleware.request({
        url: '/QuestionTemplate',
        method: 'POST',
        data: question,
    });

export const updateQuestionTemplate = (
    question: QuestionTemplate
): Promise<Response<string>> =>
    generalMiddleware.request({
        url: `/QuestionTemplate`,
        method: 'PUT',
        data: question,
    });
    
export const deleteQuestionTemplate = (
    id: string | number
): Promise<Response<string>> =>
    generalMiddleware.request({
        url: `/QuestionTemplate/?id=${id}`,
        method: 'DELETE'
    });
