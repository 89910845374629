import generalMiddleware from "./middleware/middleware";
import { Response } from "./middleware/format-response";
import { dashboradInterviewCampaign } from "../modals/modals";

export const dashboardInterView = (
  data: dashboradInterviewCampaign
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Interview/GetInterviewsPerMonth/${data.startDate}/${data.endDate}/${data.id}`,
    method: "GET",
  });

export const dashboardCampaign = (
  data: dashboradInterviewCampaign
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Campaign/GetCampaignsPerMonth/${data.startDate}/${data.endDate}/${data.id}`,
    method: "GET",
  });

export const dashboardLatestInterview = (userId: number): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Interview/GetLatestInterviews/1/${userId}`,
    method: 'GET',
  });
export const topSixCampaign = (id:number): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Campaign/GetTopSixActiveCampaigns?enterprise_ID=${id}`,
    method: 'GET',
  });
export const topFiveCandidateByCampaign = (CampaignId:number): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Dashboard/GetTopFiveCandidateByCampaignId?CampaignId=${CampaignId}`,
    method: 'GET',
  });

export const dashboardInvites = (
  data: dashboradInterviewCampaign
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/CampaignLine/GetInvitesPerMonth/${data.startDate}/${data.endDate}/${data.id}`,
    method: "GET",
  });

  export const getResumeByCampaignLine = (
    emailId: string
  ): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/CandidateResume/GetCandidateResumeByUser?email=${emailId}`,
      method: 'GET',
    });
  export const getIntervideoByCampaignLine = (
    emailId: string
  ): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/InterviewVideo/GetInterviewVideoByUser?email=${emailId}`,
      method: 'GET',
    });

