import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createPaymenEntry,
  getPaymentDetailsByUserId,
  getRemainingJobsByEnterprise,
  getRemainingUsersByEnterprise,
  getSubscriptionByEnterprise,
} from '../../api/payment';
import {
  PaymentDetails,
  PaymentResponse,
  SubscriberResponse,
} from '../../modals/modals';

export interface PaymentState {
  paymentList: PaymentResponse[];
  paymentDetails: PaymentDetails;
  paymentInfo: PaymentResponse[] | null;
  subscriberInfo: SubscriberResponse[];
  remainingInvites: number;
  remainingJobs: number;
  isPackageExpired?: boolean;
}
const initialState: PaymentState = {
  paymentList: [],
  paymentDetails: {
    price_id: '',
    package_name: '',
  },
  subscriberInfo: [],
  paymentInfo: null,
  remainingInvites: 0,
  remainingJobs: 0,
  isPackageExpired: false,
};

export const addUserPayment = createAsyncThunk(
  'addpayment/delete',
  async (data: any) => {
    return {
      response: await createPaymenEntry(data),
    };
  }
);
export const getPaymentDetailsByUser = createAsyncThunk(
  'payment/get',
  async (data: any) => {
    return {
      response: await getPaymentDetailsByUserId(data),
    };
  }
);

export const getSubcriberByEnterpriseId = createAsyncThunk(
  'subscriber/get',
  async (data: any) => {
    return {
      response: await getSubscriptionByEnterprise(data),
    };
  }
);

export const getRemainingUsersByEnterpriseId = createAsyncThunk(
  'usersInvited/get',
  async (data: any) => {
    return {
      response: await getRemainingUsersByEnterprise(data),
    };
  }
);

export const getRemainingJobsByEnterpriseId = createAsyncThunk(
  'jobsRemaining/get',
  async (data: any) => {
    return {
      response: await getRemainingJobsByEnterprise(data),
    };
  }
);

export const paymentSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    updatePackageExpiry: (state, actions) => {
      state.isPackageExpired = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentDetailsByUser.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.paymentInfo = action.payload.response.data;
      }
    });
    builder.addCase(getSubcriberByEnterpriseId.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        const response = action.payload.response.data.filter(
          (i) =>
            // i.fk_User_ID === action?.meta?.arg.userId &&
            i.StripePayment_Status === 1
        );
        state.subscriberInfo = response;
      }
    });
    builder.addCase(
      getRemainingUsersByEnterpriseId.fulfilled,
      (state, action) => {
        if (action.payload.response.successful) {
          state.remainingInvites = action.payload.response?.data;
        }
      }
    );
    builder.addCase(
      getRemainingJobsByEnterpriseId.fulfilled,
      (state, action) => {
        if (action.payload.response.successful) {
          state.remainingJobs = action.payload.response?.data;
        }
      }
    );
  },
});

export const { updatePackageExpiry } = paymentSlice.actions;
export default paymentSlice.reducer;
