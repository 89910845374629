import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface InviteState {
  email: string;
  enterpriseId: number;
  userStatusId: number;
}

const initialState: InviteState = {
  email: '',
  enterpriseId: 0,
  userStatusId: 0,
};

export const RegistrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    registrationReducer: (state, action) => {
      state.email = action.payload.id;
      state.enterpriseId = action.payload.enterpriseId;
      state.userStatusId = action.payload.userStatusId;
    },
  },
});

export const { registrationReducer } = RegistrationSlice.actions;

export default RegistrationSlice.reducer;
