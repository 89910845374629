import { useState, useEffect } from "react";

export const useAudio = (url: any) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState<boolean>(false);

  const toggleAudio: (type: boolean) => void = (type: boolean) =>
    setPlaying(type);

  useEffect(() => {
    audio.src = url
    audio.currentTime = 0;
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const finalValue: [boolean, (type: boolean) => void] = [playing, toggleAudio];

  return finalValue;
};