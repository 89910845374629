import { PaymentResponse, SubscriberResponse } from '../modals/modals';
import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';

export const createPaymenEntry = (
  data: any
): Promise<Response<PaymentResponse[]>> =>
  generalMiddleware.request({
    url: `/StripePayment`,
    method: 'POST',
    data: data,
  });
export const getPaymentDetailsByUserId = (
  data: any
): Promise<Response<PaymentResponse[]>> =>
  generalMiddleware.request({
    url: `/StripePayment/GetByUser?fk_User_ID=${data.userId}&fk_Enterprise_ID=${data.enterpriseId}`,
    method: 'GET',
  });

export const getSubscriptionByEnterprise = (
  data: any
): Promise<Response<SubscriberResponse[]>> =>
  generalMiddleware.request({
    url: `/StripePayment/GetByEnterprise?fk_Enterprise_ID=${data.enterpriseId}`,
    method: 'GET',
  });

export const getRemainingUsersByEnterprise = (
  data: any
): Promise<Response<number>> =>
  generalMiddleware.request({
    url: `/Subscriber/GetRemainingUsers?fk_Enterprise_ID=${data.enterpriseId}`,
    method: 'GET',
  });

export const getRemainingJobsByEnterprise = (
  data: any
): Promise<Response<number>> =>
  generalMiddleware.request({
    url: `/Campaign/GetRemainingJobsToLaunch?fk_Enterprise_ID=${data.enterpriseId}`,
    method: 'GET',
  });
