import generalMiddleware from './middleware/middleware';
import { Response } from './middleware/format-response';
import { IJobTemplatesResponse } from '../modals/modals';

export const getJobDescription = (
  jobId: number,
  enterpriseId: number
): Promise<Response<IJobTemplatesResponse>> =>
  generalMiddleware.request({
    url: `/JobDescription?id=${jobId}&Enterprise_ID=${enterpriseId}`,
    method: 'GET',
  });
export const createJobDescription = (
  data: any
): Promise<Response<IJobTemplatesResponse>> =>
  generalMiddleware.request({
    url: `/JobDescription`,
    method: 'POST',
    data: data,
  });
export const updateJobDescriptionApi = (
  data: any
): Promise<Response<IJobTemplatesResponse>> =>
  generalMiddleware.request({
    url: `/JobDescription`,
    method: 'PUT',
    data: data,
  });
