import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Colors } from '../../theme/colors';
import { TextInput } from '../../ui/InputFields';
import { ReactComponent as LocationIcon } from '../../images/icons/LocationIcon.svg';
import PropTypes from 'prop-types';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';

import countriesJSON from '../../countryJson/countries.json';

const AddressDetails = ({ dataObj, handleChange }: any) => {
  const structArray = [
    {
      Label: 'Street Address',
      Name: 'Enterprise_StreetAddress',
      type: 'text',
      isIcon: true,
    },
    {
      Label: 'Street Address 2',
      Name: 'Enterprise_StreetAddress2',
      type: 'text',
      isIcon: true,
    },
    {
      Label: 'Street Address 3',
      Name: 'Enterprise_StreetAddress3',
      type: 'text',
      isIcon: true,
    },
    {
      Label: 'City',
      Name: 'Enterprise_City',
      type: 'text',
      isIcon: true,
    },
    {
      Label: 'Region',
      Name: 'Enterprise_Region',
      type: 'text',
      isIcon: true,
    },
    {
      Label: 'Postcode',
      Name: 'Enterprise_Postcode',
      type: 'number',
      isIcon: false,
    },
  ];

  const [country, setCountry] = useState<any>(
    dataObj?.Enterprise_Country || 'AU'
  );
  const [countryCode, setcountryCode] = useState<any>(
    dataObj?.Enterprise_CountryCoded || 'AU'
  );

  useEffect(() => {
    if (dataObj?.Enterprise_Country) {
      setCountry(getKeyByValue(countriesJSON, dataObj?.Enterprise_Country));
    }
  }, []);
  useEffect(() => {
    handleChange(
      (countriesJSON as { [country: string]: string })[country],
      'select',
      'Enterprise_Country'
    );
  }, [country]);

  useEffect(() => {
    handleChange(countryCode, 'select', 'Enterprise_CountryCoded');
  }, [countryCode]);

  function getKeyByValue(object: any, value: string) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  // COUNTRY SELECT
  const CountrySelect = ({ value, onChange, labels, ...rest }: any) => (
    <select
      {...rest}
      style={{
        width: '100px !important',
      }}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value=''>{labels['ZZ']}</option>
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {labels[country]}
        </option>
      ))}
    </select>
  );

  CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  // COUNTRY CODE SELECT
  const CountryCodeSelect = ({ value, onChange, labels, ...rest }: any) => {
    return (
      <select
        {...rest}
        style={{
          width: '100px !important',
        }}
        value={value}
        onChange={(event) => onChange(event.target.value || undefined)}
      >
        <option value=''>{labels['ZZ']}</option>
        {getCountries().map((country) => (
          <option key={country} value={country}>
            +{getCountryCallingCode(country)} {labels[country]}
          </option>
        ))}
      </select>
    );
  };

  CountryCodeSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingBottom: '20px',
        }}
      >
        {structArray.map((itm, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '300px',
                marginRight: '45px',
                marginTop: '45px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: Colors.philippine_gray,
                  marginBottom: '7px',
                }}
              >
                {itm?.Label}
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <TextInput
                  type={itm.type}
                  maxWidth='300px'
                  height='50px'
                  placeholder={`Enter ${itm?.Label}`}
                  value={dataObj?.[itm?.Name] ?? ''}
                  border={`1px solid ${Colors.default}`}
                  padding={'0px 10px'}
                  onChange={(value) => handleChange(value, itm.type, itm.Name)}
                />
                {itm.isIcon && (
                  <Box
                    sx={{
                      position: 'absolute',
                      right: '20px',
                      top: '15px',
                    }}
                  >
                    <LocationIcon />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
        {/* COUNTRYSELECT */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            marginRight: '45px',
            marginTop: '45px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: '7px',
            }}
          >
            Country
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${Colors.default}`,
              height: '50px',
              borderRadius: '6px',
              padding: '0px 10px',
            }}
          >
            <img
              style={{ width: '40px', height: '20px' }}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
            />
            <CountrySelect labels={en} value={country} onChange={setCountry} />
          </Box>
        </Box>
        {/* COUNTRYCodeSELECT */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            marginRight: '45px',
            marginTop: '45px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: '7px',
            }}
          >
            Country Code
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${Colors.default}`,
              height: '50px',
              borderRadius: '6px',
              padding: '0px 10px',
            }}
          >
            <img
              style={{ width: '40px', height: '20px' }}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
            />
            <CountryCodeSelect
              labels={en}
              value={countryCode}
              onChange={setcountryCode}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddressDetails;
