import React from 'react';
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children, redirectTo }: any) => {
  const token = () => {
    const getToken = localStorage.getItem("token");                  
    return !!getToken ? true : false;
  };

  return token() ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoutes;
