import React, { useEffect, useState } from 'react';

import { Add, Check, Clear, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  colors,
  useMediaQuery,
} from '@mui/material';
import { Colors } from '../../theme/colors';
// import { QuestionTemplates } from './questionTemplates';
import { useDrag } from 'react-dnd';
import { TextInput } from '../../ui/InputFields';
import { useAppDispatch } from '../../redux/hooks';
import { CircleLoader } from 'react-spinners';
import { ReactComponent as EditIcon } from '../../images/icons/Editicon.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/bin.svg';
import { UpdateTemplate } from '../../api/templates';
import {
  deleteTemplates,
  fetchTemplates,
  updateTemplates,
} from '../../redux/templates/templates-slice';
import { toastFailure, toastSuccess } from '../../utils/toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './JobTemplateList.css';
import { JobTemplates } from './JobTemplates';
import { IJobTemplatesResponse } from '../../modals/modals';
import {
  deleteJobDescription,
  deleteJobTemplateReducer,
  updateJobDescription,
} from '../../redux/jobTemplates/jobtemplates-slice';
import { useLocation } from 'react-router-dom';

const JobTemplatesList = ({
  item,
  index,
  setExpanded,
  expanded,
  isLoadingQuestions,
  hanldeAddNewQuestion,
  tempSpecificData,
  NewQuestionValue,
  setNewQuestionValue,
}: any) => {
  const dispatch = useAppDispatch();
  const maxWidth1620 = useMediaQuery('(max-width: 1620px)');
  const location = useLocation();
  const [addQNA, setAddQNA] = useState(false);
  const [isEditableTemplate, setIsEditableTemplate] = useState<boolean>(false);
  const [editTempalteValue, setEditTempalteValue] = useState<string>('');
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSizeJob, setWindowSizeJob] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSizeJob(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  useEffect(() => {
    setEditTempalteValue(item.QuestionTemplate_Text || '');
  }, [item]);

  // const [{ isDragging:isDraggingJobList }, dragJobItem] = useDrag(() => ({
  //   type: 'jobItem',
  //   item: { data: [item] },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // }));
  const updateTemplate = (data: any) => {
    const jobData = {
      ...data,
      Job_Name: editTempalteValue,
    };
    dispatch(updateJobDescription(jobData))
      .unwrap()
      .then((responseJob) => {
        if (
          responseJob.response.status === 200 ||
          responseJob.response.status === 201
        ) {
          toastSuccess('Template name was updated');
          dispatch(deleteJobDescription(jobData));
        } else {
          toastFailure('Something went wrong');
        }
      })
      .catch((error) => {});
    setIsEditableTemplate(false);
  };

  const deleteTemplate = (item: IJobTemplatesResponse) => {
    const deleteJobTemp = {
      ...item,
      JobDescription_Deleted: 1,
    };
    dispatch(updateJobDescription(deleteJobTemp))
      .unwrap()
      .then(() => {
        dispatch(deleteJobTemplateReducer(deleteJobTemp));
      });
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      // ['link'],
      ['clean'],
    ],
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
  ];

  return (
    <Accordion
      expanded={expanded === item.pk_JobDescription_ID}
      sx={{
        boxShadow: 'none',
        background: Colors.background_grey,
        // border: '1px solid #CBDFFF',
        borderRadius: '10px',
        marginY: '20px',
        '&.Mui-expanded': {
          margin: '0',
          paddingBottom: '10px',
          background: Colors.background_grey,
          borderRadius: '10px',
          // border: '1px solid #FFF',
        },
        '&:before': {
          border: 'none',
          backgroundColor: Colors.background_grey,
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
        '&:first-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      }}
    >
      <AccordionSummary
        // ref={dragJobItem}
        // expandIcon={<ExpandMore />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{
          flexDirection: 'row-reverse',
          padding: '8px',
          borderRadius: '10px',
          background: 'transparent',
          '&.Mui-expanded': {
            minHeight: 0,
            background: Colors.background_grey,
          },
          '& .MuiAccordionSummary-content': {
            margin: '0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            // paddingLeft: "10px",
            paddingRight: '5px',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
            alignItems: 'center',
          },
          '.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {},
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            color: Colors.philippine_gray,
          }}
          onClick={() => {
            if (!isEditableTemplate) {
              if (item.pk_JobDescription_ID !== expanded) {
                setExpanded(item.pk_JobDescription_ID);
                return;
              } else {
                setExpanded(undefined);
                return;
              }
            }
          }}
        >
          <ExpandMore
            style={{
              transform:
                item.pk_JobDescription_ID == expanded
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
            }}
          />
          {isEditableTemplate ? (
            <>
              <TextInput
                value={editTempalteValue}
                onChange={(value) => {
                  setEditTempalteValue(value);
                }}
                border={`1px solid ${Colors.default}`}
                placeholder={'Please fill in details'}
                width={90}
              />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  padding: 0,
                  fontSize: '15px',
                  fontWeight: 500,
                  overflowWrap: 'break-word',
                  textWrap: 'wrap',
                }}
                style={{
                  maxWidth: maxWidth1620
                    ? location.pathname === '/templates'
                      ? windowSizeJob.innerWidth - 450
                      : windowSizeJob.innerWidth - 1100
                    : location.pathname === '/templates'
                    ? windowSizeJob.innerWidth - 450
                    : windowSizeJob.innerWidth - 1300,
                }}
              >
                {item.Job_Name}
              </Typography>
            </>
          )}
        </div>
        {item.pk_JobDescription_ID == expanded && (
          <>
            {isEditableTemplate ? (
              <>
                <div style={{ marginRight: '10px' }}>
                  <IconButton edge='end' aria-label='check'>
                    <Check
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                      onClick={() => {
                        updateTemplate(item);
                        // setEditable(!isEditable);
                        // submitUpdateQuestion();
                        // onEdit(question.id);
                      }}
                    />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='check'
                    onClick={() => {
                      setIsEditableTemplate(!isEditableTemplate);
                      setEditTempalteValue('');
                      // onEdit(question.id);
                    }}
                  >
                    <Clear
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                    />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginRight: '12px' }}>
                  <IconButton
                    edge='end'
                    aria-label='edit'
                    onClick={() => {
                      setEditTempalteValue(item.Job_Name || '');
                      setIsEditableTemplate(!isEditableTemplate);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={() => {
                      deleteTemplate(item);
                      // deleteQuestionTemplate(item);
                    }}
                  >
                    <DeleteIcon style={{ marginLeft: '10px' }} />
                  </IconButton>
                </div>
              </>
            )}
          </>
        )}
        {/* {expanded &&
          expanded === item.pk_JobDescription_ID &&
          item.Job_Description === '' && (
            <> */}
        <div style={{ width: '' }}>
          <Add
            sx={{
              color: Colors.primary,
            }}
            onClick={(e) => {
              if (item.pk_JobDescription_ID !== expanded) {
                setExpanded(item.pk_JobDescription_ID);
                // setAddQNA(true);
              } else {
                setAddQNA(true);
              }
            }}
          />
        </div>
        {/* </>
          )} */}
      </AccordionSummary>

      {/* For Add a job description..... */}

      <AccordionDetails
        sx={{
          padding: '4px 16px',
          background: Colors.background_grey,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        }}
      >
        {addQNA && (
          <div
            style={{
              borderRadius: '4px',
              margin: '10px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: 200,
                justifyContent: 'space-between',
                // alignItems: 'center',
                margin: '10px',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'calc(100% - 70px)',
                  height: '100%',
                }}
              >
                {/* <TextInput
                  value={NewQuestionValue}
                  onChange={(value) => {
                    setNewQuestionValue(value);
                  }}
                  border={`1px solid ${Colors.default}`}
                  placeholder={'Enter job description'}
                  width={98}
                /> */}
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  className='jobList'
                >
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    value={NewQuestionValue || ''}
                    className='job-title'
                    placeholder='Enter job description...'
                    onChange={(content, delta, source, editor) => {
                      // setcampaignDescHtml(content);
                      setNewQuestionValue(content);
                    }}
                  />
                </div>
              </div>
              <div
                style={{ width: '70px', display: 'flex', alignItems: 'end' }}
              >
                <>
                  <IconButton edge='end' aria-label='check'>
                    <Check
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                      onClick={() => {
                        if (NewQuestionValue.trim() === '') {
                          toastFailure('Enter a description');
                          return;
                        }

                        hanldeAddNewQuestion(item);
                        setAddQNA(false);
                        // onEdit(question.id);
                      }}
                    />
                  </IconButton>
                  <IconButton edge='end' aria-label='check'>
                    <Clear
                      sx={{
                        color: Colors.secondary,
                        fontSize: 25,
                      }}
                      onClick={() => {
                        setAddQNA(false);
                        setNewQuestionValue('');
                        // onEdit(question.id);
                      }}
                    />
                  </IconButton>
                </>
              </div>
            </div>
          </div>
        )}
        {expanded && expanded === item.pk_JobDescription_ID && (
          <>
            {item.Job_Description === '' ? (
              <>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {isLoadingQuestions ? (
                    <CircleLoader color='#AA19D5' />
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        color: '#464646',
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {/* Template is empty, click the <span style={{color:`${Colors.primary}`,fontSize:'18px',fontWeight:600}}>+</span> button to create a job
                      description */}
                      Template is empty, click the{' '}
                      <span style={{ lineHeight: 0.8 }}>
                        <Add
                          sx={{
                            color: Colors.primary,
                          }}
                        />
                      </span>{' '}
                      button to create a job description
                    </Typography>
                  )}
                </Typography>
              </>
            ) : (
              <>
                {/* {tempSpecificData?.filter((i: any) => i.JobDescription_Deleted !== 1)
                  .map((itm: any, index: number) => (
                    <React.Fragment key={index}> */}
                <JobTemplates item={item} questionIndex={index} />
                {/* </React.Fragment>
                  ))} */}
              </>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default JobTemplatesList;
