import { Box, Grid, Switch, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Colors } from '../../theme/colors';
import { TextInput } from '../../ui/InputFields';

import { useTheme } from '@mui/material/styles';

const colorMap = [
  '#AC131A',
  '#800A4B',
  '#461886',
  '#15479A',
  '#E8781F',
  '#F3CF42',
  '#6BB56B',
  '#000000',
  '#5E5E5E',
  '#838383',
  '#B7B7B7',
  '#4BA2EB',
  '#F15023',
  '#FFFFFF',
];

const InitialDetails = ({
  dataObj,
  handleChange,
  backgroundImageURL,
  handleDrop,
  handleChangeNew,
  handleDrag,
  handleChangeToggle,
  isImageBackgroundIsOn,
  dragActive,
  isImageValid,
}: any) => {
  const [TextColor, setTextColor] = useState<string>('');
  const [Backgroundcolor, setBackgroundcolor] = useState<string>('');
  // drag state

  const theme = useTheme();
  // ref
  const inputRef = useRef<any>(null);

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingBottom: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            marginRight: '45px',
            marginTop: '45px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: '7px',
            }}
          >
            Initial
          </Typography>
          <TextInput
            type={'text'}
            maxWidth='300px'
            height='50px'
            placeholder={`e.g. vms`}
            value={dataObj?.Enterprise_Initials || ''}
            border={`1px solid ${Colors.default}`}
            padding={'0px 10px'}
            textTransform={'uppercase'}
            onChange={(value) => {
              handleChange(value, 'text1', 'Enterprise_Initials');
            }}
          />
          <Typography
            sx={{
              fontSize: '11px',
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginTop: '2px',
            }}
          >
            Enter Max Three Words
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            marginRight: '45px',
            marginTop: '45px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: '7px',
            }}
          >
            Text Color
          </Typography>
          <Grid container gap='10px'>
            {colorMap.map((colorItem, index) => (
              <Grid item sx={{ width: '34px', height: '34px' }} key={index}>
                <div
                  onClick={() => {
                    setTextColor(colorItem);
                    handleChange(colorItem, 'color', 'Enterprise_Colour');
                  }}
                  style={{
                    background: `${
                      colorItem == '#FFFFFF' ? 'black' : colorItem
                    }`,
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '28px',
                      height: '28px',
                      borderRadius: '4px',
                      border: `2px solid ${
                        TextColor == colorItem ||
                        dataObj?.Enterprise_Colour == colorItem
                          ? 'white'
                          : colorItem
                      }`,
                      background: colorItem,
                      cursor: 'pointer',
                    }}
                  ></div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            marginRight: '45px',
            marginTop: '45px',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: Colors.philippine_gray,
              marginBottom: '7px',
            }}
          >
            Background color
          </Typography>
          <Grid container gap='10px'>
            {colorMap.map((colorItem, index) => (
              <Grid item sx={{ width: '34px', height: '34px' }} key={index}>
                {/* <Item
                      onClick={() => {
                        setBackgroundcolor(colorItem);
                      }}
                      style={{ background: colorItem, cursor: "pointer" }}
                    ></Item> */}
                <div
                  onClick={() => {
                    setBackgroundcolor(colorItem);
                    handleChange(
                      colorItem,
                      'color',
                      'Enterprise_BackgroundColour'
                    );
                  }}
                  style={{
                    background: `${
                      colorItem == '#FFFFFF' ? 'black' : colorItem
                    }`,
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '28px',
                      height: '28px',
                      borderRadius: '4px',
                      border: `2px solid ${
                        colorItem == Backgroundcolor ||
                        dataObj?.Enterprise_BackgroundColour == colorItem
                          ? 'white'
                          : colorItem
                      }`,
                      background: colorItem,
                      cursor: 'pointer',
                    }}
                  ></div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Add background Switch */}
      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '300px',
            display: 'flex',
            gap: '10px',
            justifyContent: 'center',
            marginRight: '45px',
          }}
        >
          <Typography
            sx={{
              color: '#8A8A8A',
              fontSize: '16px',
              fontWeight: '500',
              paddingTop: '0px',
            }}
          >
            Add Background image
          </Typography>
          <Box>
            <Switch
              checked={isImageBackgroundIsOn}
              onChange={(e) => handleChangeToggle(e, 'Enterprise_AvatarEnable')}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                padding: 0,
                height: '22px',
                width: '36px',
                display: 'flex',

                '& .MuiSwitch-switchBase.Mui-checked': {
                  '& .MuiSwitch-thumb': {
                    background: 'white !important',
                    border: `2px solid ${Colors.primary} !important`,
                  },
                },

                '&:active': {
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    transform: 'translateX(9px)',
                  },
                },
                '&:hover': {
                  '& .MuiSwitch-switchBase': {
                    backgroundColor: 'rgba(0, 0, 0, 0); !important',
                  },
                },
                '& .MuiSwitch-switchBase': {
                  padding: '4.5px',
                  top: '-3px !important',
                  '&.Mui-checked': {
                    padding: '4.5px',
                    transform: 'translateX(15px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                      opacity: 1,
                      backgroundColor: Colors.primary,
                      border: `2px solid ${Colors.primary}`,
                    },
                  },
                },
                '& .MuiSwitch-thumb': {
                  boxShadow: 'none',
                  width: '16px',
                  height: '16px',
                  marginLeft: '-4px',
                  background: `${Colors.primary}`,
                  border: `2px solid ${Colors.primary}`,
                  borderRadius: 6,
                  transition: theme.transitions.create(['width'], {
                    duration: 200,
                  }),
                },
                '& .MuiSwitch-track': {
                  borderRadius: 16 / 2,
                  opacity: 1,
                  height: '14px',
                  backgroundColor: 'transparent',
                  border: `2px solid ${Colors.primary}`,
                  boxSizing: 'border-box',
                  marginTop: '4px',
                  marginRight: '2px',
                },
              }}
            />
          </Box>
        </Box>

        {isImageValid && (
          <>
            <Typography
              sx={{ color: '#8A8A8A', fontSize: '16px', paddingTop: '0px' }}
            >
              Please upload an image
            </Typography>
          </>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          marginTop: '10px',
        }}
      >
        <Box
          sx={{
            width: '300px',
            marginRight: '45px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {backgroundImageURL && isImageBackgroundIsOn ? (
            <>
              <Box
                sx={{
                  width: '200px',
                  height: '200px',
                  background: 'white',
                  color: 'white',
                  borderRadius: '50% !important',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 700,
                  fontSize: '52px',
                  textTransform: 'uppercase',
                }}
              >
                <img
                  src={backgroundImageURL}
                  alt='AvtarImage'
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: '200px',
                  height: '200px',
                  background: dataObj?.Enterprise_BackgroundColour,
                  color: dataObj?.Enterprise_Colour,
                  borderRadius: '50% !important',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 700,
                  fontSize: '52px',
                  textTransform: 'uppercase',
                }}
              >
                {dataObj?.Enterprise_Initials}
              </Box>
            </>
          )}
        </Box>

        {isImageBackgroundIsOn && (
          <Box sx={{ width: '640px' }}>
            <form
              id='form-file-upload'
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              style={{
                background: '#f8f9ff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                fontSize: '18px',
                fontWeight: '500',
                color: '#babee8',
                borderRadius: '10px',
                cursor: 'pointer',
                maxWidth: '100%',
                textAlign: 'center',
                position: 'relative',
              }}
            >
              <input
                ref={inputRef}
                type='file'
                id='input-file-upload'
                // multiple={true}
                onChange={handleChangeNew}
                style={{
                  display: 'none',
                }}
              />
              <label
                id='label-file-upload'
                htmlFor='input-file-upload'
                className={dragActive ? 'drag-active' : ''}
              >
                <div>
                  <p>Drag and Drop Your Image</p>
                  <button
                    className='upload-button'
                    onClick={onButtonClick}
                    style={{
                      cursor: 'pointer',
                      padding: '0.25rem',
                      fontSize: '1rem',
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#babee8',
                    }}
                  >
                    Upload a Image
                  </button>
                </div>
              </label>
              {dragActive && (
                <div
                  id='drag-file-element'
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: '1rem',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                ></div>
              )}
            </form>
          </Box>
        )}
      </Box>
    </>
  );
};

export default InitialDetails;
