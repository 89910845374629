import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Colors } from '../../theme/colors';
import CloseIcon from '@mui/icons-material/Close';

const PackageUpgradeDialog = ({
  packageLimitModal,
  onClose,
  onUpgrade,
}: any) => {
  return (
    <Dialog
      open={packageLimitModal}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: '18px',
          fontWeight: 500,
          textAlign: 'right',
        }}
      >
        <CloseIcon
          style={{ width: '1.2em', height: '1.2em', cursor: 'pointer' }}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '0px' }}>
        <Box
          sx={{
            width: '400px',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant='h4'>It's time to update</Typography>
          <br />
          <Typography
            sx={{
              fontSize: '14px',
              color: Colors.philippine_gray,
            }}
            fontWeight={400}
          >
            You have reached the limit of your current plan.
            <br />
            choose higher plan to unlock more features.
          </Typography>

          <Box
            sx={{
              // flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: 'none',
              marginTop: '5px',
            }}
          ></Box>
        </Box>
      </DialogContent>

      <Box
        sx={{
          width: '90%',
          display: 'flex',
          marginTop: 2,
          paddingBottom: 2,
          alignSelf: 'center',
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          sx={{
            padding: 0,
          }}
          onClick={onUpgrade}
        >
          Upgrade
        </Button>
      </Box>
    </Dialog>
  );
};

export default PackageUpgradeDialog;
