import {
  Box,
  Button,
  Theme,
  Tooltip,
  Typography,
  CircularProgress,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../images/icons/backIcon.svg';
import { Colors } from '../../../theme/colors';
import { Urls } from '../../../utils/urls';
import { Campaigns, Questions, Comment } from '../../../modals/modals';
import { makeStyles } from '@material-ui/core';
import QRCode from 'react-qr-code';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from '../../../redux/hooks';
import { useSelector } from 'react-redux';
import {
  CopyInvitationLink,
  InvitationData,
} from '../../../redux/invitation/invitation-slice';
import { toastFailure, toastSuccess } from '../../../utils/toast';
import {
  UploadFile,
  Email,
  CopyAll,
  QrCode2Outlined,
} from '@mui/icons-material';

interface LocationPropsState {
  item: Campaigns;
}

const useStyles: any = makeStyles((theme: Theme) => ({
  containerForm: {
    // overflow: 'auto',
    scrollbarWidth: 'none', // Hide the scrollbar for firefox
    '&::-webkit-scrollbar': {
      display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
    },
    '&-ms-overflow-style:': {
      display: 'none', // Hide the scrollbar for IE
    },
  },
}));

const InstantInvite = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationPropsState;

  const [jobTitle, setJobTitle] = useState<string>('');

  useEffect(() => {
    if (state) {
      const { item } = state;

      setJobTitle(item.Campaign_Name);
      getInvitationLink(item.pk_Campaign_ID);
    }
  }, [state]);

  const componentRef = useRef();

  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  ////////// for MULTIPLE INPUT FIELD ////////
  const [values, setValues] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { userData } = useSelector((state: any) => state.user);
  const [isCallInviteCandidateApi, setIsCallInviteCandidateApi] =
    useState<boolean>(false);

  const [isQrViewModalActive, setQrViewModalActive] = useState<boolean>(false);

  const [isEmailInviteActive, setEmailInviteActive] = useState(false);
  const [interviewLink, setInterviewLink] = useState('');

  const handleClose = () => {
    if (isEmailInviteActive || isQrViewModalActive) {
      setEmailInviteActive(false);
      setQrViewModalActive(false);
      return;
    }
    // props.setOpen(false);
    setQrViewModalActive(false);
  };

  useEffect(() => {
    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (values.every((item) => validEmailRegex.test(item))) {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(true);
    }
  }, [values]);

  const handleSend = () => {
    let emails = values;
    let isValidMail = isValidEmail;
    var inputValue = (
      document.getElementById('tags-filled') as HTMLInputElement
    ).value;
    if (inputValue) {
      setValues(inputValue.split(' '));
      emails = inputValue.split(' ');
    }
    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emails.every((item) => validEmailRegex.test(item))) {
      isValidMail = false;
    } else {
      isValidMail = true;
    }
    if (!isValidMail && emails.length > 0) {
      setIsCallInviteCandidateApi(true);
      const paramsData = {
        FromName:
          (userData?.User_FirstName || '') +
          ' ' +
          (userData?.User_LastName || ''),
        FromMail: userData?.User_Email,
        Campaign_Name: state?.item.Campaign_Name || '',
        Enterprise_Name: state?.Enterperise_Name || '',
        bodyData: new Array(),
      };
      emails.map((value, index) => {
        if (value) {
          paramsData.bodyData.push({
            fk_Enterprise_ID: state?.item?.fk_Enterprise_ID,
            fk_Job_ID: state?.item?.fk_Job_ID,
            fk_Campaign_ID: state?.item?.pk_Campaign_ID,
            CampaignLine_CandidateEmail: value,
          });
        }
      });
      dispatch(InvitationData(paramsData))
        .unwrap()
        .then((originalPromiseResult: any) => {
          console.log('originalPromiseResult', originalPromiseResult);
          setEmailInviteActive(false);
          setIsCallInviteCandidateApi(false);
          setValues([]);
          if (originalPromiseResult.response.status == 200) {
            if (originalPromiseResult.response.data.length <= 0) {
              toastFailure('Something went wrong.');
            } else {
              originalPromiseResult.response.data.map((res: any) => {
                res.map((response: any, index: number) => {
                  if (index == 1 && response != '200') {
                    toastFailure(`Already mail sent to ${res[3]}.`);
                  } else if (index == 1) {
                    toastSuccess(`Successfully mail sent to ${res[3]}.`);
                  }
                });
              });
            }
          } else {
            toastFailure('Something went wrong.');
          }
        })
        .catch((rejectedValueOrSerializedError: any) => {
          setIsCallInviteCandidateApi(false);
          // props.setOpen(false);
          setEmailInviteActive(false);
          setValues([]);
          toastFailure('Something went wrong.');
          console.log('getting error', rejectedValueOrSerializedError);
        });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getInvitationLink = (id: number) => {
    dispatch(CopyInvitationLink(id))
      .unwrap()
      .then((invitationResponse: any) => {
        if (
          invitationResponse?.response?.status === 200 ||
          invitationResponse?.response?.successful === true
        )
          setInterviewLink(
            invitationResponse.response.data.Campaign_Job_Video_Link
          );
      })
      .catch((invitationResponse: any) => {
        console.log('getting error', invitationResponse);
        // setIsQuestionDragged(false);
      });
  };

  const copyInvitationLink = (isCopyInvite = false) => {
    const encryptionKey = 'C-link';
    if (interviewLink) {
      const clipboard = window.navigator.clipboard;
      if (!clipboard) {
        console.log('Clipboard not supported');
      }
      const domainWithProtocol = new URL(interviewLink).origin;

      const modifiedUrl = isCopyInvite
        ? domainWithProtocol +
          '/invite/' +
          state?.item?.pk_Campaign_ID +
          '/' +
          state?.item?.fk_Enterprise_ID
        : interviewLink;
      clipboard.writeText(modifiedUrl);
      toastSuccess('Successfully copied.');
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignSelf: 'flex-start',
      }}
    >
      <Box
        sx={{
          width: '60%',
          position: 'relative',
          paddingTop: '20px',
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            fontFamily: 'Poppins',
            marginY: 2,
          }}
        >
          {jobTitle}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate(Urls.Campaign);
            }}
          >
            <BackIcon />
            <Typography
              fontSize={'15px'}
              sx={{
                paddingTop: 0,
                fontWeight: 600,
                cursor: 'pointer',
                paddingX: '15px',
              }}
              color={Colors.primary}
              variant={'body1'}
            >
              Back
            </Typography>
          </Box>
          {/* <Box sx={{}}>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'large'}
              sx={{
                width: '120px',
                height: 40,
                fontSize: '13px',
                fontWeight: 600,
              }}
              //   onClick={() => {
              //     handleSubmit();
              //   }}
            >
              {'Send'}
            </Button>
          </Box> */}
        </Box>

        <Box
          className={classes.containerForm}
          sx={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 180px)',
            overflowY: 'scroll',
            paddingY: 10,
            alignSelf: 'center',
          }}
        >
          <>
            <Box>
              {/* <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  // textAlign: 'center',
                }}
              >
                {jobTitle}
              </Typography> */}

              <Box sx={{ paddingBottom: '0px' }}>
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: Colors.philippine_gray,
                      marginBottom: 2,
                      minWidth: 500,
                    }}
                    fontWeight={400}
                  >
                    Select a method for inviting candidates to interview for
                    this job
                  </Typography>
                  <Box>
                    <Grid container>
                      <Grid
                        item
                        xs={3}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        style={{ cursor: 'pointer' }}
                      >
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: Colors.philippine_gray,
                          }}
                          fontWeight={400}
                        >
                          Upload a CSV
                        </Typography>
                        <Grid xs={12} paddingY={2}>
                          <UploadFile
                            sx={{
                              color: Colors.primary,
                              fontSize: 60,
                            }}
                          />
                        </Grid>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: Colors.philippine_gray,
                          }}
                          fontWeight={400}
                        >
                          Coming Soon
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEmailInviteActive(true)}
                      >
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: Colors.philippine_gray,
                          }}
                          fontWeight={400}
                        >
                          Email Invite
                        </Typography>
                        <Grid xs={12} paddingY={2}>
                          <Email
                            sx={{
                              color: Colors.primary,
                              fontSize: 60,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          copyInvitationLink(true);
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: Colors.philippine_gray,
                          }}
                          fontWeight={400}
                        >
                          Copy Link
                        </Typography>
                        <Grid xs={12} paddingY={2}>
                          <CopyAll
                            sx={{
                              color: Colors.primary,
                              fontSize: 60,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => setQrViewModalActive(true)}
                      >
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: Colors.philippine_gray,
                            textAlign: 'center',
                          }}
                          fontWeight={400}
                        >
                          Generate QR Code
                        </Typography>
                        <Grid xs={12} paddingY={2}>
                          <QrCode2Outlined
                            sx={{
                              color: Colors.primary,
                              fontSize: 60,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </Box>
              {!isQrViewModalActive && isEmailInviteActive && (
                <Dialog open={isEmailInviteActive} onClose={handleClose}>
                  <DialogTitle
                    sx={{
                      fontSize: '18px',
                      fontWeight: 500,
                      // textAlign: 'center',
                    }}
                  >
                    Invite By Email
                  </DialogTitle>
                  <DialogContent sx={{ paddingBottom: '0px' }}>
                    <Box
                      sx={{
                        width: '400px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: Colors.philippine_gray,
                        }}
                        fontWeight={400}
                      >
                        Enter the email id of the user to invite.
                      </Typography>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: 'none',
                          marginTop: '5px',
                        }}
                      >
                        <Autocomplete
                          sx={{ border: 'none' }}
                          fullWidth
                          multiple
                          id='tags-filled'
                          options={[]}
                          defaultValue={[]}
                          onChange={(e, value) => {
                            setValues(value);
                          }}
                          freeSolo
                          renderTags={(
                            value: any[],
                            getTagProps: (arg0: {
                              index: any;
                            }) => JSX.IntrinsicAttributes
                          ) =>
                            value.map((option: any, index: any) => {
                              let isNotValidEmail = false;
                              if (
                                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(option)
                              ) {
                                isNotValidEmail = false;
                              } else {
                                isNotValidEmail = true;
                              }

                              return (
                                <Chip
                                  key={index}
                                  variant='outlined'
                                  label={option}
                                  color={isNotValidEmail ? 'error' : 'primary'}
                                  {...getTagProps({ index })}
                                />
                              );
                            })
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              error={isValidEmail}
                              placeholder='Enter a Email'
                              type='text'
                              helperText={
                                isValidEmail ? 'Please Enter a valid email' : ''
                              }
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <br />
                    {!isQrViewModalActive && isEmailInviteActive && (
                      <Tooltip
                        title={isValidEmail ? 'Disabled' : 'Send Invite'}
                      >
                        <Box
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Button
                            variant='contained'
                            color='primary'
                            disabled={isCallInviteCandidateApi}
                            onClick={() => {
                              if (!isCallInviteCandidateApi) {
                                handleSend();
                              }
                            }}
                          >
                            {isCallInviteCandidateApi ? (
                              <>
                                <CircularProgress
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                  }}
                                  color='inherit'
                                />
                              </>
                            ) : (
                              'Send'
                            )}
                          </Button>
                        </Box>
                      </Tooltip>
                    )}
                    <br />
                  </DialogContent>
                </Dialog>
              )}

              {isQrViewModalActive && (
                <Dialog
                  open={isQrViewModalActive}
                  onClose={() => setQrViewModalActive(false)}
                >
                  <DialogContent
                    sx={{ paddingBottom: '0px' }}
                    ref={componentRef}
                  >
                    <Box
                      sx={{
                        width: '400px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: 'none',
                          marginTop: '5px',
                        }}
                      >
                        <TextField
                          type='text'
                          value={'We’re hiring'}
                          sx={{
                            borderColor: '#D0D0D0',
                            borderRadius: '10px',
                            marginBottom: 1,
                            '& .MuiInputBase-root': {
                              borderColor: '#D0D0D0',
                              height: 47,
                              borderRadius: '10px',
                              color: '#000',
                            },
                            '& .MuiAutocomplete-inputFocused': {
                              borderColor: '#D0D0D0',
                              color: '#919191',
                            },
                          }}
                        />
                        <TextField
                          type='text'
                          value={'apply via QRCode'}
                          sx={{
                            borderColor: '#D0D0D0',
                            borderRadius: '10px',
                            marginBottom: 1,
                            '& .MuiInputBase-root': {
                              borderColor: '#D0D0D0',
                              height: 47,
                              borderRadius: '10px',
                              color: '#000',
                            },
                            '& .MuiAutocomplete-inputFocused': {
                              borderColor: '#D0D0D0',
                              color: '#919191',
                            },
                          }}
                        />
                        <QRCode
                          value={'' + interviewLink}
                          style={{
                            width: 150,
                            height: 150,
                            margin: 10,
                          }}
                        />
                        <TextField
                          type='text'
                          value={
                            'Welcome to Tironem! We’re excited to have you onboard. '
                          }
                          multiline
                          rows={4}
                          sx={{
                            borderColor: '#D0D0D0',
                            borderRadius: '10px',
                            marginBottom: 1,
                            '& .MuiInputBase-root': {
                              borderColor: '#D0D0D0',
                              height: 120,
                              borderRadius: '10px',
                              color: '#000',
                            },
                            '& .MuiAutocomplete-inputFocused': {
                              borderColor: '#D0D0D0',
                              color: '#919191',
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </DialogContent>
                  {isQrViewModalActive && (
                    <Box
                      sx={{
                        width: '90%',
                        marginTop: 5,
                        alignSelf: 'center',
                      }}
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handlePrint}
                      >
                        Print
                      </Button>
                    </Box>
                  )}
                  {isQrViewModalActive && (
                    <Box
                      sx={{
                        width: '90%',
                        marginTop: 2,
                        marginBottom: 2,
                        alignSelf: 'center',
                      }}
                    >
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        sx={{
                          padding: 0,
                          marginRight: '16px',
                          color: Colors.slate_blue1,
                          background: Colors.anti_flash_white,
                          ':hover, :focus, :active': {
                            background: Colors.anti_flash_white,
                          },
                        }}
                        onClick={() => {
                          setQrViewModalActive(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Dialog>
              )}
              <Box
                sx={{
                  padding: '20px 24px',
                  display: 'flex',
                  columnGap: '8px',
                }}
              >
                {isQrViewModalActive && (
                  <Box
                    sx={{
                      width: '50%',
                    }}
                  >
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      sx={{
                        padding: 0,
                        marginRight: '16px',
                        color: Colors.slate_blue1,
                        background: Colors.anti_flash_white,
                        ':hover, :focus, :active': {
                          background: Colors.anti_flash_white,
                        },
                      }}
                      onClick={() => {
                        setQrViewModalActive(false);
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        </Box>
      </Box>
      {/* ////////////////////////////////////////////////////////////////////////////////// */}
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingY: '32px',
        }}
        style={{
          backgroundColor: '#fff',
          position: 'absolute',
          bottom: 0,
          width: '75%',
        }}
      >
        <Box>
          <Button
            variant={'contained'}
            color={'primary'}
            sx={{
              width: '120px',
              height: 40,
              padding: 0,
              marginRight: '16px',
              color: Colors.slate_blue1,
              background: Colors.anti_flash_white,
              ':hover, :focus, :active': {
                background: Colors.anti_flash_white,
              },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Box>
        <Box sx={{ marginRight: 3 }}>
          <Button
            variant={'contained'}
            color={'primary'}
            size={'large'}
            sx={{
              width: '120px',
              height: 40,
              fontSize: '13px',
              fontWeight: 600,
              marginRight: '30px',
            }}
            // onClick={() => {
            //   handleSubmit();
            // }}
          >
            {'Send'}
          </Button>
        </Box>
      </Box> */}
    </Box>
  );
};

export default InstantInvite;
