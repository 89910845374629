import { ComponentsOverrides } from '@mui/material/styles/overrides';

export const PopoverThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiPopover'];
} = {
  styleOverrides: {
    paper: {
      borderRadius: 10,
    },
  },
};
