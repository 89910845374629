import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import {
  Button,
  IconButton,
  Pagination,
  Theme,
  Typography,
} from '@mui/material';
import { Colors } from '../../../theme/colors';
import { useDrag } from 'react-dnd';
import {
  fetchAllEnterprises,
  updateEnterprisesAPI,
} from '../../../redux/enterprise/enterprise-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { MenuItem } from '@mui/material';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { ReactComponent as MoreIcon } from '../../images/icons/moreIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Urls } from '../../../utils/urls';
import TIRONEM_LOGO from '../../images/TIRONEM_LOGO.png';
import Select from '@mui/material/Select';
import { CircleLoader } from 'react-spinners';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as BackIcon } from '../../../images/icons/backIcon.svg';
import NO_DATA_ICON from '../../images/icons/NO_DATA_ICON.png';
import {
  AddEnterprisesImage,
  getUserDataEmailSlice,
} from '../../../redux/user/user-slice';
import BasicDetails from '../../../components/CompanyInfo/BasicDetails';
import InitialDetails from '../../../components/CompanyInfo/InitialDetails';
import AddressDetails from '../../../components/CompanyInfo/AddressDetails';
import ContactInfo from '../../../components/CompanyInfo/ContactInfo';
import CompanySetting from '../../../components/CompanyInfo/CompanySetting';
import {
  addEmployersImage,
  createEmployer,
} from '../../../redux/clients/clients-slice';

const useStylesCss = makeStyles((theme: Theme) => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${Colors.Maximum_blue_purple}`,
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      borderRadius: '12px',
    },
  },
}));

const CreateEmployer = () => {
  const classes = useStylesCss();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = location.state;
  const { enterpris, enterprises } = useAppSelector(
    (state) => state.enterprises
  );
  const { userData } = useAppSelector((state) => state.user);

  const [enterpriseData, setEnterpriseData] = useState<any>();
  const [clientInfo, setClientInfo] = useState<any>();
  const [companyName, setcompanyName] = useState('Comapny Name');
  const [companyImage, setCompanyImage] = useState();
  const [menuList, setmenuList] = useState([
    'Basic Detail',
    'Initials',
    'Address',
    'Contact',
    // 'Setting',
    // 'Other',
  ]);
  const [currentActive, setcurrentActive] = useState<number>(0);
  const [backgroundImageURL, setBackgroundImageURL] = useState<any>();
  const [imgOBJ, setImgOBJ] = useState<any>(null);
  const [isImageBackgroundIsOn, setIsImageBackgroundIsOn] =
    React.useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isImageChanged, setIsImageChanged] = React.useState(false);
  const [isImageValid, setIsImageValid] = React.useState(false);

  const isImage = (url: any) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  useEffect(() => {
    if (enterprises && userData) {
      const activeEnterprise: any = enterprises?.filter((item, index) => {
        return item.pk_Enterprise_ID === userData?.fk_Enterprise_ID;
      });
      if (activeEnterprise) {
        setEnterpriseData(activeEnterprise[0]);
      }
    }
  }, [enterprises, userData]);

  useEffect(() => {
    if (!userData) {
      const userId: any = localStorage.getItem('userName');
      dispatch(getUserDataEmailSlice(userId));
    }
  }, []);

  useEffect(() => {
    if (!enterpris) {
      dispatch(fetchAllEnterprises());
    }
  }, []);

  useEffect(() => {
    if (enterpriseData) {
      setcompanyName(enterpriseData.Enterprise_Name);
      if (
        enterpriseData?.Enterprise_AvatarEnable &&
        enterpriseData?.Enterprise_ImageLink !== '0'
      ) {
        setCompanyImage(enterpriseData?.Enterprise_ImageLink);
      }
    }
  }, [enterpriseData]);

  useEffect(() => {
    if (clientInfo) {
      if (clientInfo?.Enterprise_AvatarEnable === true) {
        setIsImageBackgroundIsOn(true);

        if (isImage(clientInfo?.Enterprise_ImageLink)) {
          setBackgroundImageURL(clientInfo?.Enterprise_ImageLink);
        } else {
          setIsImageValid(true);
        }
      }
    }
  }, [clientInfo?.Enterprise_AvatarEnable, clientInfo]);

  const handleChange = (value: any, type: string, fieldName: string) => {
    const tmp = Object.assign({}, clientInfo);

    if (type === 'text' || type === 'color' || type === 'select') {
      setClientInfo({ ...tmp, [fieldName]: value });
    }
    if (type === 'number') {
      setClientInfo({ ...tmp, [fieldName]: Number(value) });
    }
    if (type === 'Phone') {
      setClientInfo({ ...tmp, [fieldName]: value });
    }
    if (fieldName === 'Enterprise_Initials') {
      if (value.length < 4) {
        setClientInfo({ ...tmp, [fieldName]: value });
      }
    }

    if (type === 'Setting') {
      setClientInfo({ ...tmp, [fieldName]: value?.target.checked });
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setImgOBJ(e.dataTransfer.files[0]);
      setBackgroundImageURL(URL.createObjectURL(e.dataTransfer.files[0]));
      setIsImageChanged(true);
      setIsImageValid(false);
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChangeNew = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImgOBJ(e.target.files[0]);
      setBackgroundImageURL(URL.createObjectURL(e.target.files[0]));
      setIsImageChanged(true);
      setIsImageValid(false);

      // handleFiles(e.target.files);
    }
  };

  const handleChangeToggle = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const tmp = Object.assign({}, clientInfo);
    if (event.target.checked === false) {
      setIsImageValid(false);
      setIsImageBackgroundIsOn(event.target.checked);
      setBackgroundImageURL('');
      setClientInfo({ ...tmp, [fieldName]: event.target.checked });
      setIsImageChanged(false);
    } else {
      setIsImageBackgroundIsOn(event.target.checked);
      if (clientInfo?.Enterprise_AvatarEnable === true) {
        if (isImage(clientInfo?.Enterprise_ImageLink)) {
          setBackgroundImageURL(clientInfo?.Enterprise_ImageLink);
        }
        setClientInfo({ ...tmp, [fieldName]: event.target.checked });
      } else {
        setBackgroundImageURL('');
        setClientInfo({ ...tmp, [fieldName]: event.target.checked });
      }
    }
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleSubmit = () => {
    if (isImageChanged) {
      const object: any = {
        id: clientInfo?.pk_Enterprise_ID,
        imageData: imgOBJ,
      };

      // dispatch(AddEnterprisesImage(object))
      // .unwrap()
      // .then(({ response }: any) => {

      //   // dispatch(fetchAllEnterprises());
      //   // navigate(Urls.CompanyInformation);
      // });
      // API
    }

    const body = {
      Employer_AccessLevel: 0,
      Employer_AlternateNumber: '',
      Employer_AlternateNumber_AreaCode: '',
      Employer_AlternateNumber_CountryCode: '',
      Employer_AlternateNumber_Extention: '',
      Employer_BusinessNumber: clientInfo?.Enterprise_BussinessNumber,
      Employer_City: clientInfo?.Enterprise_City ?? 'Sydney',
      Employer_CompanyNumber: clientInfo?.Enterprise_CompanyNumber,
      Employer_Country: clientInfo?.Enterprise_Country ?? 'Australia',
      Employer_CountryCoded: clientInfo?.Enterprise_CountryCoded ?? 'AU',
      Employer_DUNs: clientInfo?.Enterprise_DUNs,
      Employer_Email: '',
      Employer_Name: clientInfo?.Enterprise_Name,
      Employer_Name2: clientInfo?.Enterprise_Name2,
      Employer_Notes: '',
      Employer_PhoneNumber: clientInfo?.Enterprise_PhoneNumber,
      Employer_PhoneNumber_AreaCode:
        clientInfo?.Enterprise_PhoneNumber_AreaCode,
      Employer_PhoneNumber_CountryCode:
        clientInfo?.Enterprise_PhoneNumber_CountryCode ?? '+61',
      Employer_PhoneNumber_Extention:
        clientInfo?.Enterprise_PhoneNumber_Extention ?? '145',
      Employer_Postcode: clientInfo?.Enterprise_Postcode,
      Employer_Region: clientInfo?.Enterprise_Region,
      Employer_Initials: clientInfo?.Enterprise_Initials,
      Employer_BackgroundColour: clientInfo?.Enterprise_BackgroundColour,
      Employer_Colour: clientInfo?.Enterprise_Colour,
      Employer_StreetAddress: clientInfo?.Enterprise_StreetAddress,
      Employer_StreetAddress2: clientInfo?.Enterprise_StreetAddress2,
      Employer_StreetAddress3: clientInfo?.Enterprise_StreetAddress3,
      Employer_TaxRegistrationNumber:
        clientInfo?.Enterprise_TaxRegistrationNumber,
      fk_Enterprise_ID: userData?.fk_Enterprise_ID,
      fk_User_ID: userData?.pk_User_ID,
      sSession: 'sdfs444',
    };

    dispatch(createEmployer(body)).then((res: any) => {
      console.log('create empl', res);
      const object: any = {
        id: enterpriseData?.pk_Enterprise_ID,
        imageData: imgOBJ,
      };

      dispatch(addEmployersImage(object));
      // navigate(Urls.Clients);
    });
    // .unwrap()
    // .then((res: any) => {
    //   // navigate(Urls.CompanyInformation);
    //   console.log('RESPONSE');
    //   // setIsImageChanged(false);
    // });
  };

  return (
    <Box
      sx={{
        // width: '0%',
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '75%',
          position: 'relative',
          // alignSelf: 'center',
          display: 'flex',
          // backgroundColor: 'red',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            marginLeft: '42px',
            marginTop: '15px',
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>
            Company detail
          </Typography>
        </Box>
        <Box
          sx={{
            height: '257px',
          }}
        >
          {/* NOTE NAVIGATION */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginX: '42px',
              paddingTop: '12px',
              cursor: 'pointer',
              width: 'max-content',
            }}
            onClick={() => {
              navigate('/clients');
            }}
          >
            <BackIcon />
            <Typography
              fontSize={'15px'}
              sx={{
                paddingTop: 0,
                fontWeight: 600,
                cursor: 'pointer',
                paddingX: '15px',
              }}
              color={Colors.primary}
              variant={'body1'}
            >
              Back
            </Typography>
          </Box>

          {/* NOTE TOP SECTION PART */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginY: '45px',
              paddingX: '42px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {companyImage ? (
                <>
                  <Box
                    sx={{
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      src={companyImage}
                      alt='logo'
                    />
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    width: '60px',
                    height: '60px',
                    background: clientInfo?.Enterprise_BackgroundColour,
                    color: clientInfo?.Enterprise_Colour,
                    borderRadius: '50% !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 600,
                    fontSize: '16px',
                    textTransform: 'uppercase',
                  }}
                >
                  {clientInfo?.Enterprise_Initials}
                </Box>
              )}

              <Typography
                sx={{
                  marginLeft: '30px',
                }}
              >
                {companyName}
              </Typography>
            </Box>
            <Box>
              <Button
                variant={'contained'}
                color={'primary'}
                sx={{
                  width: '140px',
                }}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Box>
          </Box>
          {/* NOTE HEADER PART */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottomRightRadius: '6px',
              borderBottomLeftRadius: '6px',
              // backgroundColor: Colors.white,
              // justifyContent: "center",
            }}
          >
            <Box
              sx={{
                borderBottom: '1px solid #D9D9D9',
                borderRight: `1px solid #D9D9D9`,
                height: '53px',
                minWidth: '68px',
                borderBottomRightRadius: currentActive == 0 ? '7px' : '0px',
                background: Colors.white,
                marginRight: '-1px',
              }}
            />
            {menuList.map((menu, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderBottomRightRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  background: Colors.white,
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    cursor: 'pointer',
                    border:
                      currentActive === index
                        ? '1px solid #D9D9D9'
                        : 'transparent',
                    borderBottom: 'transparent',
                    borderTopLeftRadius:
                      currentActive === index ? '7px' : '0px',
                    borderTopRightRadius:
                      currentActive === index ? '7px' : '0px',
                    whiteSpace: 'nowrap',
                    background: Colors.white,
                    color:
                      currentActive === index
                        ? Colors.dark_orchid
                        : Colors.gray1,
                  }}
                  onClick={() => {
                    setcurrentActive(index);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      paddingX: '32px',
                      paddingTop: '14px',
                      paddingBottom: '14px',
                    }}
                  >
                    {menu}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    position: 'absolute',
                    bottom: '-1px',
                    left: currentActive + 1 === index ? '-1px' : '0px',
                    right: currentActive - 1 === index ? '-1px' : '0px',
                    borderRight:
                      currentActive - 1 === index ? `1px solid #D9D9D9` : '0px',
                    borderLeft:
                      currentActive + 1 === index
                        ? `1px solid  #D9D9D9`
                        : '0px',
                    minHeight: '6.3px',
                    background: Colors.white,
                    borderBottom:
                      currentActive === index
                        ? 'transparent'
                        : '1px solid #D9D9D9',
                    borderBottomRightRadius:
                      currentActive - 1 === index ? '32px' : '0px',
                    borderBottomLeftRadius:
                      currentActive + 1 === index ? '10px' : '0px',
                    borderTopLeftRadius: '3.2px',
                    borderTopRightRadius: '3.2px',
                  }}
                />
              </Box>
            ))}
            <Box
              sx={{
                borderBottom: '1px solid #D9D9D9',
                marginLeft: '-1px',
                height: '53px',
                minWidth: '68px',
                borderLeft: `1px solid #D9D9D9`,
                background: Colors.white,
                borderBottomLeftRadius:
                  currentActive == menuList.length - 1 ? '7px' : '0px',
              }}
            />
            <Box
              sx={{
                borderBottom: '1px solid #D9D9D9',
                height: '53px',
                width: '100%',
                background: Colors.white,
              }}
            />
          </Box>
        </Box>
        {/* NOTE FORM CONTENT */}
        <Box
          className={classes.root}
          sx={{
            background: Colors.white,
            height: 'calc(100% - 320px)',
            overflowY: 'scroll',
          }}
        >
          <Box
            sx={{
              paddingX: '68px',
            }}
          >
            {menuList[currentActive] === 'Basic Detail' && (
              <BasicDetails
                handleChange={handleChange}
                // handleChange={() => console.log('CHANGE Basic Detail')}
                dataObj={clientInfo ? clientInfo : ''}
              />
            )}
            {menuList[currentActive] === 'Initials' && (
              <InitialDetails
                handleChange={handleChange}
                // handleChange={() => console.log('CHANGE Initials')}
                dataObj={clientInfo}
                backgroundImageURL={backgroundImageURL}
                handleChangeToggle={handleChangeToggle}
                handleChangeNew={handleChangeNew}
                handleDrop={handleDrop}
                handleDrag={handleDrag}
                isImageBackgroundIsOn={isImageBackgroundIsOn}
                dragActive={dragActive}
                isImageValid={isImageValid}
              />
            )}
            {menuList[currentActive] === 'Address' && (
              <AddressDetails
                handleChange={handleChange}
                // handleChange={() => console.log('CHANGE Address')}
                dataObj={clientInfo}
              />
            )}
            {menuList[currentActive] === 'Contact' && (
              <ContactInfo
                handleChange={handleChange}
                // handleChange={() => console.log('CHANGE Contact')}
                dataObj={clientInfo}
              />
            )}
            {/* {menuList[currentActive] === 'Setting' && (
              <CompanySetting
                handleChange={handleChange}
                // handleChange={() => console.log('CHANGE Setting')}
                dataObj={clientInfo}
              />
            )} */}
            {/* {menuList[currentActive] === 'Other' && (
              <OtherDetails
                handleChange={handleChange}
                dataObj={enterpriseData}
              />
            )} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default CreateEmployer;
