import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  createQuestion,
  getQuestions,
  updateQuestion,
  deleteQuestion,
  createAIQuestion,
  createAIJobDescription,
  questionResponseRating,
  userRating,
  questionResponseUpdateRating,
  userUpdateRating,
  createAIJobDescriptionNew,
} from '../../api/questions';
import { Questions } from '../../modals/modals';
import { toastFailure, toastSuccess } from '../../utils/toast';

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export interface QuestionState {
  questions: Questions[];
  jobDescriptionHtml: any;
  jobdescriptionAPICall: boolean;
}
interface UpdateQuestion {
  item: Questions;
  index: number;
}

const initialState: QuestionState = {
  questions: [],
  jobDescriptionHtml: null,
  jobdescriptionAPICall: false,
};

export const fetchQuestions = createAsyncThunk(
  'questions/fetch',
  async (data: number) => {
    return {
      questionsResponse: await getQuestions(data),
    };
  }
);

export const createQuestions = createAsyncThunk(
  'questions/create',
  async (data: Questions) => {
    return { response: await createQuestion(data) };
  }
);
export const createAIQuestionAPI = createAsyncThunk(
  'questions/create',
  async (data: any) => {
    return {
      response: await createAIQuestion(data?.number, data?.title, data?.data),
    };
  }
);
export const createAIJobDescriptionAPI = createAsyncThunk(
  'create/Jobdescription',
  async (data: any) => {
    return {
      response: await createAIJobDescription(data),
    };
  }
);
export const createAIJobDescriptionNewAPI = createAsyncThunk(
  'create/JobdescriptionNew',
  async (data: any) => {
    return {
      response: await createAIJobDescriptionNew(data),
    };
  }
);

export const updateQuestions = createAsyncThunk(
  'questions/update',
  async (data: Questions) => {
    return { response: await updateQuestion(data) };
  }
);
export const deleteQuestions = createAsyncThunk(
  'questions/delete',
  async (data: Questions) => {
    return { response: await deleteQuestion(data) };
  }
);
export const questionResponseRatings = createAsyncThunk(
  'questions/rating',
  async (data: any) => {
    return { response: await questionResponseRating(data) };
  }
);
export const questionResponseUpdateRatings = createAsyncThunk(
  'questions/updateRating',
  async (data: any) => {
    return { response: await questionResponseUpdateRating(data) };
  }
);
export const userRatings = createAsyncThunk(
  'user/rating',
  async (data: any) => {
    return { response: await userRating(data) };
  }
);
export const userUpdateRatings = createAsyncThunk(
  'user/ratingUpdate',
  async (data: any) => {
    return { response: await userUpdateRating(data) };
  }
);

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    updateQuestionReducer: (state, action: PayloadAction<UpdateQuestion>) => {
      const indexValue: number = action.payload.index;
      state.questions[indexValue] = action.payload.item;
    },
    addQuestionReducer: (state, action: PayloadAction<{ item: Questions }>) => {
      state.questions.push(action.payload.item);
    },
    updateQuestionsListReducer: (
      state,
      action: PayloadAction<{ item: Questions[] }>
    ) => {
      state.questions = action.payload.item;
    },
    deleteQuestionReducer: (
      state,
      action: PayloadAction<{ item: Questions }>
    ) => {
      const filteredQuestions = state.questions.filter(
        (question) =>
          question.pk_Question_ID !== action.payload.item.pk_Question_ID
      );
      state.questions = filteredQuestions;
    },
    clearQuestion: (state) => {
      state.questions = [];
    },
    updateJobDesc: (state, action) => {
      state.jobdescriptionAPICall = action.payload;
    },
    updateHtmlDes: (state, action) => {
      state.jobDescriptionHtml = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      if (action.payload.questionsResponse.successful) {
        let finalData = action.payload.questionsResponse.data.sort(
          (a, b) => a.Question_Order - b.Question_Order
        );
        state.questions = finalData;
      } else {
        if (!action.payload.questionsResponse.successful) {
          toastFailure(action.payload.questionsResponse.message);
        }
      }
    });

    //Create Questions
    builder.addCase(createQuestions.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    //Update Questions
    builder.addCase(updateQuestions.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    // Delete Questions
    builder.addCase(deleteQuestions.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    //create Job Description
    builder.addCase(createAIJobDescriptionAPI.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.jobDescriptionHtml = action.payload.response.data;
        state.jobdescriptionAPICall = false;
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });
  },
});

export const {
  updateQuestionReducer,
  clearQuestion,
  addQuestionReducer,
  updateQuestionsListReducer,
  deleteQuestionReducer,
  updateJobDesc,
  updateHtmlDes,
} = questionsSlice.actions;
export default questionsSlice.reducer;
