import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ITemplatesResponse, QuestionTemplate } from '../../modals/modals';
import { toastFailure, toastSuccess } from '../../utils/toast';
import { createJobDescription, getJobDescription, updateJobDescriptionApi } from '../../api/jobtemplates';

export enum StoreStage {
  Initial,
  Loading,
  Error,
}
export const fetchJobTemplate = createAsyncThunk('jobtemplate/fetch', async (data:any) => {
  return {
    response: await getJobDescription(data.jobId, data.enterpriseId),
  };
});
export const fetchJobTemplateById = createAsyncThunk('jobtemplateById/fetch', async (data:any) => {
  return {
    response: await getJobDescription(data.jobId, data.enterpriseId),
  };
});
export const createJobDesc = createAsyncThunk('jobtemplate/create', async (data:any) => {
  return {
    response: await createJobDescription(data),
  };
});
export const updateJobDescription = createAsyncThunk('jobtemplate/update', async (data:any) => {
  return {
    response: await updateJobDescriptionApi(data),
  };
});


export interface jobTemplatesState {
  jobTemplates: any;
}

const initialState: jobTemplatesState = {
  jobTemplates: [],
};

export const jobTemplatesSlice = createSlice({
  name: 'jobtemplates',
  initialState,
  reducers: {
    deleteJobDescription: (state, action) => {

      const index = state.jobTemplates.findIndex((res:any) =>res.pk_JobDescription_ID == action.payload.pk_JobDescription_ID);      
      if (index !== -1) { 
        state.jobTemplates[index] = action.payload
      }
    },
    deleteJobTemplateReducer: (state, action) => {
      const index = state.jobTemplates.findIndex((res:any) =>res.pk_JobDescription_ID == action.payload.pk_JobDescription_ID);      
      if (index !== -1) { 
        state.jobTemplates.splice(index,1)
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobTemplate.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.jobTemplates = action.payload.response.data;
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });
    
  },
});
export const { deleteJobDescription,deleteJobTemplateReducer } =
  jobTemplatesSlice.actions;

export default jobTemplatesSlice.reducer;