import { configureStore } from '@reduxjs/toolkit';
import campaignsReducer from './campaigns/campaigns-slice';
import commentsReducer from './comments/comments-slice';
import questionReducer from './questions/question-slice';
import questionstemplateReducer from './questionTemplate/questiontemplate-slice';
import userReducer from './user/user-slice';
import enterpriseReducer from './enterprise/enterprise-slice';
import templatesReducer from './templates/templates-slice';
import candidatesReducer from './candidates/candidates-slice';
import clientsReducer from './clients/clients-slice';
import dashboardReducer from './dashboard/dashboard-slice';
import inviteReducer from './invitation/invitation-slice';
import registrationReducer from './registration/registration-slice';
import jobtemplateReducer from './jobTemplates/jobtemplates-slice';
import paymentReducer from './payment/payments-slice';
export const store = configureStore({
  reducer: {
    campaigns: campaignsReducer,
    questions: questionReducer,
    clients: clientsReducer,
    questionstemplate: questionstemplateReducer,
    comments: commentsReducer,
    user: userReducer,
    enterprises: enterpriseReducer,
    templates: templatesReducer,
    candidates: candidatesReducer,
    dashboard: dashboardReducer,
    invite: inviteReducer,
    register: registrationReducer,
    jobtemplates: jobtemplateReducer,
    payment: paymentReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
