import generalMiddleware from "./middleware/middleware";
import { Response } from "./middleware/format-response";
import { enterpriseResponse } from "../modals/modals";

export const getAllEnterprises = (): Promise<Response<enterpriseResponse[]>> =>
  generalMiddleware.request({
    url: `/Enterprise`,
    method: "GET",
  });

export const getEnterprisesById = (
  id: string
): Promise<Response<enterpriseResponse>> =>
  generalMiddleware.request({
    url: `/Enterprise/${id}`,
    method: "GET",
  });

export const updateEnterprises = (
  data: enterpriseResponse
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Enterprise`,
    method: "PUT",
    data:data
  });
export const createEnterprises = (
  data: enterpriseResponse
): Promise<Response<any>> =>
  generalMiddleware.request({
    url: `/Enterprise`,
    method: "POST",
    data:data
  });
