import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Colors } from '../../theme/colors';
import { TextInput } from '../../ui/InputFields';

import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { border } from '@mui/system';

const BasicDetails = ({ dataObj, handleChange }: any) => {
  // const [value, setValue] = useState<any>();

  const structArray = [
    // {
    //   Label: "Reference Code",
    //   Name: "Enterprise_RefernceCode",
    //   type: "text",
    // },
    {
      Label: 'Name',
      Name: 'Enterprise_Name',
      type: 'text',
    },
    {
      Label: 'Name 2',
      Name: 'Enterprise_Name2',
      type: 'text',
    },
    {
      Label: 'Company number',
      Name: 'Enterprise_CompanyNumber',
      type: 'Phone',
    },
    {
      Label: 'Business number',
      Name: 'Enterprise_BussinessNumber',
      type: 'Phone',
    },
    {
      Label: 'TaxRegisteration number ',
      Name: 'Enterprise_TaxRegistrationNumber',
      type: 'text',
    },
    {
      Label: 'DUNs',
      Name: 'Enterprise_DUNs',
      type: 'text',
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          paddingBottom: '20px',
        }}
      >
        {structArray?.map((itm, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '300px',
                marginRight: '45px',
                marginTop: '45px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: Colors.philippine_gray,
                  marginBottom: '7px',
                }}
              >
                {itm.Label}
              </Typography>
              {itm.type === 'text' && (
                <TextInput
                  type={itm.type}
                  maxWidth='300px'
                  height='50px'
                  placeholder={`Enter ${itm.Label}`}
                  value={dataObj[itm.Name] || ''}
                  border={`1px solid ${Colors.default}`}
                  padding={'0px 10px'}
                  onChange={(value) => handleChange(value, itm.type, itm.Name)}
                />
              )}
              {itm.type === 'Phone' && (
                <PhoneInput
                  international
                  limitMaxLength
                  style={{
                    border: `1px solid ${Colors.default}`,
                    height: '50px',
                    borderRadius: '6px',
                    padding: '0px 10px',
                  }}
                  defaultCountry={'AU'}
                  countryCallingCodeEditable={false}
                  value={dataObj[itm.Name]}
                  onChange={(value) => {
                    handleChange(value, itm.type, itm.Name);
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default BasicDetails;
