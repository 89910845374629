import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createEnterprises, getAllEnterprises, getEnterprisesById, updateEnterprises } from "../../api/enterprise";

import { toastFailure, toastSuccess } from "../../utils/toast";
import { enterpriseResponse } from "../../modals/modals";

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export const fetchAllEnterprises = createAsyncThunk("enterprise/fetch", async () => {
  return {
    enterpriseResponse: await getAllEnterprises(),
  };
});
export const fetchEnterprisesById = createAsyncThunk("enterprise/fetchbyId", async (id: string) => {
  return {
    enterpriseResponse: await getEnterprisesById(id),
  };
});

export const updateEnterprisesAPI = createAsyncThunk("enterprise/update", async (data: enterpriseResponse) => {
  return {
    enterpriseResponse: await updateEnterprises(data),
  };
});
export const createEnterprisesAPI = createAsyncThunk("enterprise/create", async (data: enterpriseResponse) => {
  return {
    enterpriseResponse: await createEnterprises(data),
  };
});


export interface enterpriseState {
  enterprises: enterpriseResponse[] | null;
  enterpris: enterpriseResponse | null;
}

const initialState: enterpriseState = {
  enterprises: null,
  enterpris: null
}

export const enterpriseSlice = createSlice({
  name: "enterprise",
  initialState,
  reducers: {
    allEnterprisereducer: (state, action) => {
      state.enterprises = action.payload;
    },
    clearEnterpriseData: (state, action) => {
      if (action.payload != null) {
        state.enterprises = null;
        state.enterpris = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllEnterprises.fulfilled, (state, action) => {
      if (action.payload.enterpriseResponse.successful) {
        state.enterprises = action.payload.enterpriseResponse.data;
      } else {
        if (!action.payload.enterpriseResponse.successful) {
          toastFailure(action.payload.enterpriseResponse.message);
        }
      }
    })

    // get by id
    builder.addCase(fetchEnterprisesById.fulfilled, (state, action) => {
      if (action.payload.enterpriseResponse.successful) {
        state.enterpris = action.payload.enterpriseResponse.data;
      } else {
        if (!action.payload.enterpriseResponse.successful) {
          toastFailure(action.payload.enterpriseResponse.message);
        }
      }
    })
    // update comment extra reducer

    builder.addCase(updateEnterprisesAPI.fulfilled, (state, action) => {
      if (action.payload.enterpriseResponse.successful) {
        toastSuccess(action.payload.enterpriseResponse.data);
      } else {
        if (!action.payload.enterpriseResponse.successful) {
          toastFailure(action.payload.enterpriseResponse.message);
        }
      }
    });
  }
})

export const { clearEnterpriseData } = enterpriseSlice.actions;
// export const { loginUserReducer } = UserSlice.actions;
export default enterpriseSlice.reducer;