import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const GooglePlacesInput = ({
  setCityValue,
  selectedCityValue,
  countryCode,
}: any) => {
  const [options, setOptions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    const loadScript = (url, callback) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.head.appendChild(script);
    };

    const initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['(cities)'],
          componentRestrictions: { country: countryCode },
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const cityComponent = place.address_components.find((component) =>
          component.types.includes('locality')
        );
        if (cityComponent) {
          const cityName = cityComponent.long_name;
          setCityValue(cityName);
          setOptions([{ label: cityName, value: cityName }]);
        }
      });

      inputRef.current.addEventListener('input', () => {
        if (inputRef.current.value) {
          const service = new window.google.maps.places.AutocompleteService();
          service.getPlacePredictions(
            {
              input: inputRef.current.value,
              types: ['(cities)'],
              componentRestrictions: { country: countryCode },
            },
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const cityOptions = predictions.map((prediction) => {
                  const cityTerm = prediction.terms.find((term) =>
                    prediction.types.includes('locality')
                  );

                  return {
                    label: cityTerm
                      ? cityTerm.value
                      : prediction.terms[0].value,
                    value: cityTerm
                      ? cityTerm.value
                      : prediction.terms[0].value,
                  };
                });
                setOptions(cityOptions);
              }
            }
          );
        } else {
          setOptions([]);
        }
      });
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`,
      initAutocomplete
    );
  }, [setCityValue]);

  const handleInputClick = () => {
    setCityValue('');
  };

  return (
    <Autocomplete
      sx={{
        borderRadius: '10px',
        '& .MuiInputBase-root': {
          height: 47,
          borderRadius: '10px',
          paddingTop: '2px',
          borderColor: '#D0D0D0 !important',
          backgroundColor: '#f5f5f5',
        },
      }}
      onChange={(event, selection) => {
        setCityValue(selection?.value || '');
      }}
      value={
        selectedCityValue
          ? { label: selectedCityValue, value: selectedCityValue }
          : null
      }
      options={options}
      disableClearable
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          placeholder='Choose city'
          onClick={handleInputClick}
          sx={{
            borderColor: '#D0D0D0',
            borderRadius: '10px',
            '& .MuiInputBase-root': {
              borderColor: '#D0D0D0',
              height: 47,
              borderRadius: '10px',
              paddingTop: '2px',
              color: '#919191',
            },
            '& .MuiAutocomplete-inputFocused': {
              borderColor: '#D0D0D0',
              color: '#626161',
            },
          }}
        />
      )}
    />
  );
};

export default GooglePlacesInput;
