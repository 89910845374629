import { ComponentsOverrides } from '@mui/material/styles/overrides';

export const TableThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiTable'];
} = {
  styleOverrides: {
    root: {
      borderCollapse: 'separate',
      // borderSpacing: '0px 10px',
    },
  },
};

export const TableBodyThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiTableBody'];
} = {
  styleOverrides: {
    root: {
      borderRadius: 5,
      fontSize: 16,
      lineHeight: '24px',
      textTransform: 'none',
      padding: 10,
      width: '100%',
      '& .MuiTableRow-root': {
        borderRadius: 10,
      },
      '& .MuiTableCell-root': {
        border: 0,
      },
    },
  },
};
