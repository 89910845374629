export const Colors = {
  black: '#000000',
  Phlox: '#E728F8',
  white: '#ffffff',
  white_smoke: '#EFEFEF',
  ghost_white: '#F8F9FF',
  lavender: '#ECE8FF',
  lavender_blue: '#CED4FF',
  Maximum_blue_purple: '#9CA7F8',
  slate_blue: '#6559D7',
  vivid_mulberry: '#AA19D5',
  dark_orchid: '#A51ED4',
  spanish_gray: '#9D9D9D',
  gray1: '#7C7C7C',
  outerSpace: '#464646',
  jet: '#343434',
  aero_blue: '#C1F6DF',
  chinese_silver: '#CDCDCD',
  wintergreen_dream: '#4E9381',
  nickel: '#727171',
  magic_potion: '#FD8A8A',
  yellow: '#FDF18A',
  philippine_gray: '#8A8A8A',
  alice_blue: '#EBF2FF',
  bright_gray: '#EFEFEF',
  slate_blue1: '#724CD6',
  anti_flash_white: '#F2EFFB',
  light_silver: '#D9D9D9',
  grey_toggle: '#AAAAAA',
  bg_grey_light: '#E7E7E7',
  grey_light_txt: '#A7A7A7',
  bg_blue_light: '#D7E5FC',
  blue_light_txt: '#2B7AFC',

  background_grey: '#F9F9F9',
  grey: '#848484',
  dark_grey: '#445B6B',
  light_grey: '#F5F5F5',
  gray_d4: '#D4D4D4',
  gray_A9: '#A9A9A9',
  gray_F2: '#F2F2F2',
  gray_97: '#979797',
  gray_9C: '#9C9C9C',
  gray_E5: '#E5E5E5',
  gray_Text: '#9399A9',
  red: '#D32F2F',
  default: '#E7E7E7',
  primary: '#2e88dd',
  secondary: '#4F0B4E',
  success: '#1EB648',
  warning: '#FFCA28',
  gradient_second: '#090979',
  lawnGreen: '#75F321',
  Gray6b: '#6b6b6b',
  SuvaGrey: '#8F8F8F',
  silver: '#C0C0C0',
  purple: '#AD19D5',
  VividViolet: '#AC18D5',
  JoustBlue: '#5668D8',
  DimGray: '#6d6d6d',
  light_Pink: '#F5D6FF',
  light_border: '#CBDFFF',
  blueColor: '#4285F4',
  dark_gray: '#F0F0F0',
  font_gray: '#929292',
  light_gray_border: '#EDEDED',
};

export const TextColors = {
  lightGrey: Colors.light_grey,
  white: Colors.white,
  black: Colors.black,
  default: Colors.default,
};

export const BackgroundColors = {
  white: Colors.white,
  lightGrey: Colors.light_grey,
  darkGrey: Colors.dark_grey,
  defaultGrey: Colors.gray_F2,
};
