import React, { useEffect, useState } from 'react';

import { Add, Check, Clear, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Colors } from '../../theme/colors';
import { QuestionTemplates } from './questionTemplates';
import { useDrag } from 'react-dnd';
import { TextInput } from '../../ui/InputFields';
import { useAppDispatch } from '../../redux/hooks';
import { CircleLoader } from 'react-spinners';
import { ReactComponent as EditIcon } from '../../images/icons/Editicon.svg';
import { ReactComponent as DeleteIcon } from '../../images/icons/bin.svg';
import { UpdateTemplate } from '../../api/templates';
import {
  deleteTemplates,
  fetchTemplates,
  updateTemplates,
} from '../../redux/templates/templates-slice';
import { toastFailure } from '../../utils/toast';
import { useLocation } from 'react-router-dom';

const QuestionsTemplateList = ({
  item,
  index,
  setExpanded,
  expanded,
  isLoadingQuestions,
  hanldeAddNewQuestion,
  tempSpecificData,
  NewQuestionValue,
  setNewQuestionValue,
}: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const maxWidth1620 = useMediaQuery('(max-width: 1620px)');
  const [addQNA, setAddQNA] = useState(false);
  const [isEditableTemplate, setIsEditableTemplate] = useState<boolean>(false);
  const [editTempalteValue, setEditTempalteValue] = useState<string>('');
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setEditTempalteValue(item.QuestionTemplate_Text || '');
  }, [item]);

  const [{ isDragging: isDraggingTableItem }, dragTableItem] = useDrag(() => ({
    type: 'tableItem',
    item: { data: [item] },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const updateTemplate = (data: any) => {
    const body = {
      pk_Template_ID: data.pk_Template_ID,
      Template_Record_GUID: data.Template_Record_GUID,
      fk_Enterprise_ID: data.fk_Enterprise_ID,
      sSession: data.sSession,
      fk_User_ID: data.fk_User_ID,
      Template_Name: editTempalteValue,
      Template_Description: data.Template_Description,
      Template_Deleted: data.Template_Deleted,
      Template_Status: data.Template_Status,
      Template_DateOfLastUpdate: '',
      Template_DateOfCreation: data.Template_DateOfCreation,
    };
    dispatch(updateTemplates(body))
      .then(() => {
        dispatch(fetchTemplates());
      })
      .catch(() => {});
    setIsEditableTemplate(false);
  };

  const deleteTemplate = (id: number) => {
    dispatch(deleteTemplates(id)).then(() => {
      dispatch(fetchTemplates());
    });
  };

  return (
    <Accordion
      expanded={expanded === item.pk_Template_ID}
      sx={{
        boxShadow: 'none',
        background: Colors.background_grey,
        // border: '1px solid #CBDFFF',
        borderRadius: '10px',
        width: '100%',
        marginY: '20px',
        '&.Mui-expanded': {
          margin: '0',
          paddingBottom: '10px',
          background: Colors.background_grey,
          borderRadius: '10px',
        },
        '&:before': {
          border: 'none',
          backgroundColor: Colors.background_grey,
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
        '&:first-of-type': {
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      }}
    >
      <AccordionSummary
        ref={dragTableItem}
        // expandIcon={<ExpandMore />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={{
          flexDirection: 'row-reverse',
          padding: '8px',
          width: '100%',
          borderRadius: '10px',
          background: 'transparent',
          '&.Mui-expanded': {
            minHeight: 0,
            background: Colors.background_grey,
          },
          '& .MuiAccordionSummary-content': {
            margin: '0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            // paddingLeft: "10px",
            paddingRight: '5px',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
            alignItems: 'center',
          },
          '.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {},
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            color: Colors.philippine_gray,
          }}
          onClick={() => {
            if (!isEditableTemplate) {
              if (item.pk_Template_ID !== expanded) {
                setExpanded(item.pk_Template_ID);
                return;
              } else {
                setExpanded(undefined);
                return;
              }
            }
          }}
        >
          <ExpandMore
            style={{
              transform:
                item.pk_Template_ID == expanded
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              width: '25px',
            }}
          />
          {isEditableTemplate ? (
            <>
              <TextInput
                value={editTempalteValue}
                onChange={(value) => {
                  setEditTempalteValue(value);
                }}
                border={`1px solid ${Colors.default}`}
                placeholder={'Please fill in details'}
                width={90}
              />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  padding: 0,
                  fontSize: '15px',
                  fontWeight: 500,
                  overflowWrap: 'break-word',
                  textWrap: 'wrap',
                }}
                style={{
                  maxWidth: maxWidth1620
                    ? location.pathname === '/templates'
                      ? windowSize.innerWidth - 450
                      : windowSize.innerWidth - 1100
                    : location.pathname === '/templates'
                    ? windowSize.innerWidth - 450
                    : windowSize.innerWidth - 1300,
                }}
              >
                {item.Template_Name}
              </Typography>
            </>
          )}
        </div>
        {item.pk_Template_ID == expanded && (
          <>
            {isEditableTemplate ? (
              <>
                <div style={{ marginRight: '10px' }}>
                  <IconButton edge='end' aria-label='check'>
                    <Check
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                      onClick={() => {
                        updateTemplate(item);
                        // setEditable(!isEditable);
                        // submitUpdateQuestion();
                        // onEdit(question.id);
                      }}
                    />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='check'
                    onClick={() => {
                      setIsEditableTemplate(!isEditableTemplate);
                      setEditTempalteValue('');
                      // onEdit(question.id);
                    }}
                  >
                    <Clear
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                    />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginRight: '12px' }}>
                  <IconButton
                    edge='end'
                    aria-label='edit'
                    onClick={() => {
                      setEditTempalteValue(item.Template_Name || '');
                      setIsEditableTemplate(!isEditableTemplate);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge='end'
                    aria-label='delete'
                    onClick={() => {
                      deleteTemplate(item?.pk_Template_ID);
                      // deleteQuestionTemplate(item);
                    }}
                  >
                    <DeleteIcon style={{ marginLeft: '10px' }} />
                  </IconButton>
                </div>
              </>
            )}
          </>
        )}

        <div style={{ width: '' }}>
          <Add
            sx={{
              color: Colors.primary,
            }}
            onClick={(e) => {
              if (item.pk_Template_ID !== expanded) {
                setExpanded(item.pk_Template_ID);
                // setAddQNA(true);
              } else {
                setAddQNA(true);
              }
            }}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '4px 16px',
          background: Colors.background_grey,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        }}
      >
        {addQNA && (
          <div
            style={{
              borderRadius: '4px',
              margin: '10px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: 45,
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '10px',
                marginBottom: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'calc(100% - 70px)',
                }}
              >
                <TextInput
                  value={NewQuestionValue}
                  onChange={(value) => {
                    setNewQuestionValue(value);
                  }}
                  border={`1px solid ${Colors.default}`}
                  placeholder={'Enter new template question'}
                  width={98}
                />
              </div>
              <div style={{ width: '70px' }}>
                <>
                  <IconButton edge='end' aria-label='check'>
                    <Check
                      sx={{
                        color: Colors.primary,
                        fontSize: 25,
                      }}
                      onClick={() => {
                        if (NewQuestionValue.trim() === '') {
                          toastFailure('Enter a Question');
                          return;
                        }
                        hanldeAddNewQuestion(item);
                        setAddQNA(false);
                        // onEdit(question.id);
                      }}
                    />
                  </IconButton>
                  <IconButton edge='end' aria-label='check'>
                    <Clear
                      sx={{
                        color: Colors.secondary,
                        fontSize: 25,
                      }}
                      onClick={() => {
                        setAddQNA(false);
                        setNewQuestionValue('');
                        // onEdit(question.id);
                      }}
                    />
                  </IconButton>
                </>
              </div>
            </div>
          </div>
        )}

        {tempSpecificData.filter(
          (itm: any) => itm.QuestionTemplate_Deleted !== 1
        ).length === 0 ? (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {isLoadingQuestions ? (
              <CircleLoader color='#AA19D5' />
            ) : (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 500,
                  color: '#464646',
                  fontSize: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Template is empty, click the{' '}
                <span style={{ lineHeight: 0.8 }}>
                  <Add
                    sx={{
                      color: Colors.primary,
                    }}
                  />
                </span>{' '}
                button to create a question
              </Typography>
            )}
          </Typography>
        ) : (
          tempSpecificData
            .filter((i: any) => i.QuestionTemplate_Deleted !== 1)
            .map((itm: any, index: number) => (
              <React.Fragment key={index}>
                <QuestionTemplates item={itm} questionIndex={index} />
              </React.Fragment>
            ))
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionsTemplateList;
