import { ComponentsOverrides } from '@mui/material/styles/overrides';

export const IconButtonBaseThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiIconButton'];
} = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      boxShadow: 'none',
      '&:hover': {
        background: 'transparent',
        boxShadow: 'none',
      },
    },
  },
};
