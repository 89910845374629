import React, { useEffect, useRef, useState } from 'react';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ListItem,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { Search, Add, ExpandMore, Check, Close } from '@mui/icons-material';
import { Colors } from '../../theme/colors';
import { useDrag } from 'react-dnd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { TextInput } from '../../ui/InputFields';

import { toastFailure, toastSuccess } from '../../utils/toast';
import { makeStyles } from '@mui/styles';
import Settings from '../../../src/screens/campaign/Common/CampaignSection/Settings';
import NO_DATA_ICON from '../../images/icons/NO_DATA_ICON.png';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as BackIcon } from '../../images/icons/backIcon.svg';
import { useNavigate } from 'react-router-dom';
import { Urls } from '../../utils/urls';
import JobTemplatesList from '../JonTemplates/JobTemplatesList';
import {
  createJobDesc,
  fetchJobTemplate,
  updateJobDescription,
} from '../../redux/jobTemplates/jobtemplates-slice';
import { CircleLoader } from 'react-spinners';

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 99,
  height: '100%',
  border: 0,
  [theme.breakpoints.up('md')]: {
    position: 'static',
    borderRadius: '6px',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${theme.spacing(13)} + 1px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 99,
  border: 0,
  height: '100%',
  [theme.breakpoints.up('md')]: {
    position: 'static',
  },
});

const DrawerContent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  minWidth: `calc(${theme.spacing(13)} + 1px)`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useStylesCss = makeStyles(() => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '2px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${Colors.Maximum_blue_purple}`,
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      borderRadius: '12px',
    },
  },
}));

export const DrawerRightJob = ({
  stateData,
  selectedCompany,
  pageType,
  isCreateCampaign,
  setAddNewJobTemplate,
  addNewJobTemplate,
  labelColor,
}: any): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(true);
  const templateRef = useRef<any>();
  const theme = useTheme();
  const classes = useStylesCss();
  const [addNewQuestion, setAddNewQuestion] = useState<boolean>(false);
  const [NewQuestionValue, setNewQuestionValue] = useState('');
  const [newTemplateName, setnewTemplateName] = useState<string>('');
  const [isLoadingQuestions, setisLoadingQuestions] = useState<boolean>(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(true);

  const [menuList, setmenuList] = useState(['Templates', 'Settings']);
  const [currentActive, setcurrentActive] = useState<number>(0);
  const { userData } = useAppSelector((state: any) => state.user);
  // const { templates, templateQuestions } = useAppSelector(
  //   (state) => state.templates
  // );
  const { jobTemplates } = useAppSelector((state: any) => state.jobtemplates);
  const [questionTemplates, setQuestionTemplates] = useState<any>([]);

  const [expanded, setExpanded] = React.useState<undefined | number>(undefined);
  const [openJob, setOpenJob] = useState<null | {}>();
  const navigate = useNavigate();
  // useEffect(() => {
  //   setQuestionTemplates(templateQuestions);
  // }, [templateQuestions]);
  useEffect(() => {
    setQuestionTemplates(jobTemplates);
  }, [jobTemplates]);

  // call Api Specific tempkate que usind id
  useEffect(() => {
    if (expanded !== undefined) {
    }
  }, [expanded]);

  // Close Select Outside Click
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (templateRef.current && !templateRef.current.contains(event.target)) {
        setExpanded(undefined);
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [templateRef]);

  // Fetch fetchTemplates
  // useEffect(() => {
  //   dispatch(fetchTemplates());
  // }, []);
  useEffect(() => {
    if (userData) {
      const data = {
        jobId: 0,
        enterpriseId: userData.fk_Enterprise_ID,
      };
      // setIsLoadingTemplates(true)
      dispatch(fetchJobTemplate(data))
        .unwrap()
        .then((response: any) => {
          setIsLoadingTemplates(false);
        })
        .catch((error: any) => {
          setIsLoadingTemplates(false);
        });
    }
  }, [userData]);

  const hanldeAddNewQuestion = (templateData: any) => {
    const updatedData = {
      ...templateData,
      Job_Description: NewQuestionValue,
    };
    dispatch(updateJobDescription(updatedData))
      .unwrap()
      .then((originalPromiseResult: any) => {
        const dataObj = {
          jobId: 0,
          enterpriseId: userData.fk_Enterprise_ID,
        };
        // dispatch(fetchTemplatesQuestionsById(templateData.pk_Template_ID));
        dispatch(fetchJobTemplate(dataObj));
        setNewQuestionValue('');
      })
      .catch((rejectedValueOrSerializedError: any) => {
        setNewQuestionValue('');
        console.log('getting error', rejectedValueOrSerializedError);
      });
  };

  const handleNewTemplateAdd = () => {
    // if (!selectedCompany.pk_Enterprise_ID) {
    //   toastFailure('Please select a Company.');
    //   return;
    // }
    // if (stateData === undefined || stateData === null) {
    //   toastFailure("Campaign Title is required.");
    //   return;
    // }

    // const body = {
    //   Template_Record_GUID: 'fcd3abb8-443c-4b81-a296-9cd8abcb3743',
    //   fk_Enterprise_ID: selectedCompany
    //     ? selectedCompany.pk_Enterprise_ID
    //     : userData?.fk_Enterprise_ID,
    //   sSession: selectedCompany ? selectedCompany.sSession : userData?.sSession,
    //   fk_User_ID: selectedCompany
    //     ? selectedCompany.fk_User_ID
    //     : userData?.pk_User_ID,
    //   Template_Name: newTemplateName,
    //   Template_Description: 'sample string 7',
    //   Template_Deleted: 10,
    //   Template_Status: 11,
    // };
    const body = {
      pk_JobDescription_ID: 0,
      fk_Enterprise_ID: userData.fk_Enterprise_ID,
      fk_User_ID: userData.pk_User_ID,
      Job_Name: newTemplateName,
      Job_Description: '',
    };
    dispatch(createJobDesc(body))
      .unwrap()
      .then((originalPromiseResult: any) => {
        setnewTemplateName('');
        setAddNewJobTemplate(false);
        // dispatch(fetchTemplates());
        const data = {
          jobId: 0,
          enterpriseId: userData.fk_Enterprise_ID,
        };
        dispatch(fetchJobTemplate(data));
        toastSuccess('Job description Template is created!');
      })
      .catch((error: any) => {
        console.log('error', error);
      });
  };

  const JobTemplate = () => {
    return (
      <DrawerContent variant='permanent' open={open} anchor={'right'}>
        <List
          style={{
            marginLeft: 16,
            height: '100%',
          }}
        >
          <ListItem key={'item3'} style={{ paddingLeft: '0' }}>
            {open ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'flex-end',
                  borderRadius: '4px',
                  padding: 0,
                }}
              >
                <Box
                  sx={{
                    background: 'transparent',
                    borderRadius: 2,
                    alignItems: 'center',
                    minHeight: 80,
                  }}
                >
                  {isCreateCampaign && (
                    <>
                      <Stack
                        sx={{
                          // borderBottom: `1px solid ${Colors.default}`,
                          background: Colors.white,
                          padding: '30px 0 10px 0',
                          borderRadius: '10px',
                          alignItems: 'center',
                          marginBottom: '10px',
                        }}
                        direction='row'
                        justifyContent='space-between'
                      >
                        <span>
                          <Typography
                            // variant={'body1'}
                            sx={{
                              fontSize: '14px',
                              fontWeight: '500',
                              // color: isCreateCampaign
                              //   ? Colors.primary
                              //   : Colors.black,
                              color: Colors.black,
                            }}
                          >
                            Job description Templates
                          </Typography>
                        </span>
                        <span>
                          {isCreateCampaign && (
                            <>
                              <Button
                                variant={'text'}
                                onClick={() => {
                                  setAddNewJobTemplate(true);
                                }}
                                sx={{
                                  color: Colors.primary,
                                  alignSelf: 'flex-end',
                                  fontSize: '14px',
                                  padding: 0,
                                  height: 'auto',
                                  fontWeight: 500,
                                }}
                              >
                                <Add
                                  sx={{
                                    color: Colors.primary,
                                    fontSize: 22,
                                  }}
                                />
                                Create Template
                              </Button>
                            </>
                          )}
                        </span>
                      </Stack>
                    </>
                  )}

                  {addNewJobTemplate && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        padding: '10px',
                      }}
                    >
                      <TextInput
                        placeholder={'Enter job name'}
                        border={`1px solid ${Colors.default}`}
                        value={newTemplateName}
                        onChange={(value) => setnewTemplateName(value)}
                        width={85}
                      />
                      <IconButton
                        edge='end'
                        aria-label='edit'
                        onClick={() => {
                          if (newTemplateName.trim() === '') {
                            toastFailure('Please enter a job name');
                            return;
                          }
                          handleNewTemplateAdd();
                        }}
                      >
                        <Check
                          sx={{
                            color: Colors.primary,
                            fontSize: 30,
                          }}
                        />
                      </IconButton>
                      <IconButton
                        edge='end'
                        aria-label='edit'
                        onClick={() => {
                          setAddNewJobTemplate(false);
                          setnewTemplateName('');
                        }}
                      >
                        <Close
                          sx={{
                            color: Colors.secondary,
                            fontSize: 30,
                          }}
                        />
                      </IconButton>
                    </div>
                  )}

                  <div
                    ref={templateRef}
                    style={{
                      margin: '20px 0px',
                      borderRadius: '6px',
                    }}
                  >
                    {isLoadingTemplates ? (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '5%',
                          }}
                        >
                          <CircleLoader color='#AA19D5' />
                        </Box>
                      </>
                    ) : jobTemplates.filter((template: any) => {
                        return (
                          template.fk_Enterprise_ID ===
                          userData?.fk_Enterprise_ID
                        );
                      }).length > 0 ? (
                      <>
                        {jobTemplates.map((item: any, index: number) => (
                          <div key={index}>
                            <JobTemplatesList
                              expanded={expanded}
                              setExpanded={setExpanded}
                              isLoadingQuestions={isLoadingQuestions}
                              NewQuestionValue={NewQuestionValue}
                              setNewQuestionValue={setNewQuestionValue}
                              tempSpecificData={openJob}
                              hanldeAddNewQuestion={hanldeAddNewQuestion}
                              item={item}
                              index={index}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginTop: '12%',
                          }}
                        >
                          <img
                            alt='TIRONEM_logo'
                            height='60px'
                            src={NO_DATA_ICON}
                          />
                          <h3 style={{ fontFamily: 'Poppins' }}>
                            You have no job description Template, yet.
                          </h3>
                          <span style={{ fontFamily: 'Poppins' }}>
                            Create a new job description template
                          </span>
                          <br />
                          <br />
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => {
                              setAddNewJobTemplate(true);
                            }}
                            sx={{
                              height: '50px',
                              width: '300px',
                            }}
                          >
                            <AddIcon
                              color={'secondary'}
                              sx={{ fontSize: 30 }}
                            />
                            Create Template
                          </Button>
                        </Box>
                      </>
                    )}
                  </div>
                </Box>
              </Box>
            ) : (
              <Button
                variant={'contained'}
                color={'secondary'}
                size={'large'}
                sx={{ width: open ? '100%' : '25%', height: 60 }}
              >
                <Search />
              </Button>
            )}
          </ListItem>
        </List>
      </DrawerContent>
    );
  };

  return <>{JobTemplate()}</>;
};
