import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Colors } from '../../theme/colors';
import { Box, Button } from '@mui/material';
import InviteUser from '../InviteUserModal/InviteUser';
import { CircleLoader } from 'react-spinners';
import { relative } from 'path';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import PackageUpgradeDialog from '../PackageUpgradeDialog/PackageUpgradeDialog';
import { useNavigate } from 'react-router-dom';
import { Urls } from '../../utils/urls';
import { updateUserDataSlice } from '../../redux/user/user-slice';
import {
  getRemainingJobsByEnterpriseId,
  getRemainingUsersByEnterpriseId,
} from '../../redux/payment/payments-slice';

const TeamComp = ({
  teamInfo,
  userInfo,
  userData,
  enterpriseData,
  isCreateEditJob = false,
  onSelectEmployer,
  currentEmployer = 0,
}: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modifiedArray, setModifiedArray] = useState<[]>([]);
  const [isInviteUserOpen, setIsInviteUserOpen] = useState<boolean>(false);
  const { remainingInvites } = useAppSelector((state) => state.payment);
  const [packageLimitModal, setPackageLimitModal] = useState<boolean>(false);
  const [isConfirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>({});

  const [teamOwner, setTeamOwner] = useState('');

  const { isPackageExpired } = useAppSelector((state) => state.payment);

  useEffect(() => {
    let filterOwner = teamInfo?.filter(
      (owner) => owner?.User_AccessLevel === 0
    );

    // if (isCreateEditJob) {
    //   filterOwner.filter((i) => i.User_Status === 1);
    // }

    if (filterOwner) {
      setTeamOwner(
        filterOwner[0]?.User_FirstName +
          ' ' +
          filterOwner[0]?.User_SecondName || ' '
      );
    }

    if (teamInfo && userInfo) {
      const indexToMove = teamInfo.findIndex(
        // (value: any) => value.pk_User_ID === userInfo?.pk_User_ID
        (value: any) => value.User_AccessLevel === 0
      );
      if (indexToMove > -1 && teamInfo) {
        let newArray: any = Array.from(teamInfo);

        if (isCreateEditJob) {
          newArray = newArray.filter((i) => i.User_Status === 1);
        } else {
          newArray?.splice(indexToMove, 1);
        }
        // newArray.unshift(userInfo);
        setModifiedArray(newArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [teamInfo, userInfo]);

  const handleActiveDeactiveUser = (user: any) => {
    if ((remainingInvites === 0 || isPackageExpired) && !isCreateEditJob) {
      setPackageLimitModal(true);
      return;
    }
    setSelectedUser(user);
    setConfirmationModal(true);
  };

  const handleUserStatusUpdate = () => {
    const body = {
      ...selectedUser,
      User_Status: selectedUser.User_Status === 0 ? 1 : 0,
    };
    const data = {
      enterpriseId: userData.fk_Enterprise_ID,
      userId: userData.pk_User_ID,
    };
    dispatch(getRemainingUsersByEnterpriseId(data)).then((resp) => {
      if (resp?.payload?.response.data > 0) {
        dispatch(updateUserDataSlice(body))
          .unwrap()
          .then(({ response }: any) => {
            const temp: any = [...modifiedArray];
            const index = temp.indexOf(selectedUser);
            temp[index] = { ...selectedUser };
            temp[index].User_Status = selectedUser.User_Status === 0 ? 1 : 0;

            setModifiedArray(temp);
            setSelectedUser({});
            setConfirmationModal(false);
          });
      } else {
        setSelectedUser({});
        setConfirmationModal(false);
        setPackageLimitModal(true);
        return;
      }
    });
  };

  return (
    <>
      {!isCreateEditJob && (
        <InviteUser
          isCampaign={false}
          title={'Invite User'}
          open={isInviteUserOpen}
          setOpen={setIsInviteUserOpen}
        />
      )}
      <div
        style={{
          paddingTop: '22px',
          width: '100%',
          paddingBottom: '22px',
          display: 'flex',
          position: 'relative',
        }}
      >
        {loading && !isCreateEditJob ? (
          <>
            <Box
              sx={{
                width: 'inherit',
                height: 'inherit',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: '40px',
                ml: '50px',
              }}
            >
              <CircleLoader color='#AA19D5' />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                // justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              {!isCreateEditJob && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: `2px solid ${Colors.background_grey}`,
                    borderRadius: '8px',
                    padding: '5px',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      sx={{
                        padding: 0,
                        fontSize: '16px',
                        fontWeight: 500,
                        color: Colors.philippine_gray,
                        marginBottom: '5px',
                        paddingTop: '0px !important',
                      }}
                    >
                      Company Name
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        background: Colors.white,
                        borderRadius: '8px',
                        // backgroundColor: Colors.background_grey,
                        // padding: '5px 30px 5px 10px',
                      }}
                    >
                      {enterpriseData?.Enterprise_AvatarEnable ? (
                        <>
                          <div
                            style={{
                              width: '42px',
                              height: '42px',
                              borderRadius: '50%',
                              marginRight: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '18px',
                              fontWeight: 500,
                              background: Colors.white,
                            }}
                          >
                            <img
                              src={enterpriseData?.Enterprise_ImageLink}
                              alt='company_logo'
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              minWidth: '48px',
                              minHeight: '48px',
                              borderRadius: '50%',
                              marginRight: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '16px',
                              fontWeight: 500,
                              background:
                                enterpriseData?.Enterprise_BackgroundColour
                                  ? enterpriseData?.Enterprise_BackgroundColour
                                  : Colors.Maximum_blue_purple,
                              color:
                                enterpriseData?.Enterprise_Colour &&
                                enterpriseData?.Enterprise_Colour.includes('#')
                                  ? enterpriseData?.Enterprise_Colour
                                  : Colors.white,
                              textTransform: 'uppercase',
                            }}
                          >
                            <div>
                              {enterpriseData?.Enterprise_Initials
                                ? enterpriseData?.Enterprise_Initials
                                : enterpriseData?.Enterprise_Name?.substring(
                                    0,
                                    3
                                  )}
                            </div>
                          </div>
                        </>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '5px',
                        }}
                      >
                        <Typography
                          sx={{
                            paddingTop: 0,
                            fontSize: '18px',
                            fontWeight: 500,
                          }}
                        >
                          {enterpriseData?.Enterprise_Name || 'Company Name'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: '30%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: 'fit-content',
                      alignItems: 'center',
                      // background: Colors.background_grey,
                      borderRadius: '8px',
                      // padding: '5px 10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div style={{ marginRight: '20px' }}>
                        <IconButton
                          size={'small'}
                          sx={{
                            color: Colors.primary,
                            textTransform: 'uppercase',
                          }}
                        >
                          <Box
                            sx={{
                              height: '40px',
                              width: '40px',
                              borderRadius: '50% !important',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontWeight: 600,
                              fontSize: '14px',
                              background:
                                userInfo?.User_BackgroundColour ||
                                Colors.Maximum_blue_purple,
                              color:
                                userInfo?.User_Colour &&
                                userInfo?.User_Colour.includes('#')
                                  ? userInfo?.User_Colour
                                  : Colors.white,
                            }}
                          >
                            {userInfo?.User_AvatarEnable &&
                            (userInfo?.User_ImageLink !== null ||
                              userInfo?.User_ImageLink !== '') ? (
                              <>
                                <Box sx={{ width: '100%', height: '100%' }}>
                                  <img
                                    src={userInfo?.User_ImageLink}
                                    alt='User_profile'
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </Box>
                              </>
                            ) : (
                              userInfo?.User_Initials
                            )}
                          </Box>
                        </IconButton>
                      </div>
                      <div>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: '18px',
                            lineHeight: '27px',
                            color: '#343434',
                          }}
                        >
                          {teamOwner}
                        </div>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#7C7C7C',
                          }}
                        >
                          Owner
                        </div>
                      </div>
                    </div>
                    <Box
                      sx={{
                        top: '20px',
                        right: 0,
                      }}
                    >
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        sx={{
                          width: '100%',
                          color: Colors.slate_blue1,
                          background: Colors.anti_flash_white,
                          ':hover, :focus, :active': {
                            background: Colors.anti_flash_white,
                          },
                        }}
                        onClick={() => {
                          if (remainingInvites <= 0 || isPackageExpired) {
                            setPackageLimitModal(true);
                          } else {
                            setIsInviteUserOpen(true);
                          }
                        }}
                      >
                        Invite User
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  marginTop: '5px',
                  overflowY: 'auto',
                  '::-webkit-scrollbar': {
                    display: 'none',
                  },
                  height: isCreateEditJob
                    ? 'calc(100vh - 465px)'
                    : 'calc(100vh - 565px)',
                }}
              >
                {modifiedArray
                  ? modifiedArray.map((data: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          <div
                            style={{
                              marginTop: '14px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ marginRight: '20px' }}>
                              <IconButton
                                size={'small'}
                                sx={{
                                  color: Colors.primary,
                                  textTransform: 'uppercase',
                                }}
                              >
                                <Box
                                  sx={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '50% !important',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    background:
                                      data?.User_BackgroundColour ||
                                      Colors.Maximum_blue_purple,
                                    color:
                                      data?.User_Colour &&
                                      data?.User_Colour.includes('#')
                                        ? data?.User_Colour
                                        : Colors.white,
                                  }}
                                >
                                  {data?.User_AvatarEnable &&
                                  (data?.User_ImageLink !== null ||
                                    data?.User_ImageLink !== '') ? (
                                    <>
                                      <Box
                                        sx={{ width: '100%', height: '100%' }}
                                      >
                                        <img
                                          src={data?.User_ImageLink}
                                          alt='User_profile'
                                          style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                          }}
                                        />
                                      </Box>
                                    </>
                                  ) : (
                                    data?.User_Initials
                                  )}
                                </Box>
                              </IconButton>
                            </div>
                            <div>
                              <Box
                                display={'flex'}
                                style={{
                                  width: '400px',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    lineHeight: '27px',
                                    color: '#343434',
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  {data?.User_FirstName +
                                    ' ' +
                                    data?.User_SecondName || ' '}{' '}
                                  {currentEmployer === data?.pk_User_ID && (
                                    <span
                                      style={{
                                        fontSize: 10,
                                        fontWeight: '800',
                                        paddingLeft: 2,
                                      }}
                                    >
                                      (Selected)
                                    </span>
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {!isCreateEditJob && (
                                    <Chip
                                      label={
                                        data.User_Status === 0
                                          ? 'Inactive'
                                          : 'Active'
                                      }
                                      sx={{
                                        borderRadius: '5px',
                                        width: '90px',
                                        background:
                                          data.User_Status === 0
                                            ? '#e70d0d9e'
                                            : '#47e287b8',
                                      }}
                                    />
                                  )}

                                  {isCreateEditJob &&
                                    currentEmployer !== data?.pk_User_ID && (
                                      <Button
                                        variant='contained'
                                        color='primary'
                                        sx={{
                                          paddingX: 2,
                                        }}
                                        onClick={() => onSelectEmployer(data)}
                                      >
                                        Select
                                      </Button>
                                    )}
                                  {userData?.User_AccessLevel === 0 &&
                                    !isCreateEditJob && (
                                      <Typography
                                        variant='caption'
                                        style={{
                                          textDecoration: 'underline',
                                          width: 80,
                                          marginLeft: 5,
                                          textAlign: 'center',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          handleActiveDeactiveUser(data)
                                        }
                                      >
                                        {data.User_Status === 0
                                          ? 'activate'
                                          : 'deactivate'}
                                      </Typography>
                                    )}
                                </div>
                              </Box>
                              <div
                                style={{
                                  fontWeight: 500,
                                  fontSize: '16px',
                                  lineHeight: '24px',
                                  color: '#7C7C7C',
                                  marginTop: isCreateEditJob ? -10 : 0,
                                }}
                              >
                                {data.User_AccessLevel === 3
                                  ? 'Administrator'
                                  : data.User_AccessLevel === 250
                                  ? 'Admin'
                                  : data.User_AccessLevel === 270
                                  ? 'Hiring Manager'
                                  : 'Owner'}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                  : null}
              </Box>
            </Box>

            {!isCreateEditJob && (
              <PackageUpgradeDialog
                packageLimitModal={packageLimitModal}
                onClose={() => setPackageLimitModal(false)}
                onUpgrade={() => {
                  setPackageLimitModal(false);
                  navigate(Urls.SelectPackages);
                }}
              />
            )}

            {!isCreateEditJob && (
              <Dialog
                open={isConfirmationModal}
                onClose={() => {
                  setSelectedUser({});
                  setConfirmationModal(false);
                }}
                PaperProps={{
                  style: {
                    borderRadius: '12px',
                  },
                }}
              >
                <DialogTitle
                  sx={{
                    fontSize: '18px',
                    fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  Confirmation
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: '0px' }}>
                  <Box
                    sx={{
                      width: '400px',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: Colors.philippine_gray,
                      }}
                      fontWeight={400}
                    >
                      Are you sure you would like to{' '}
                      {selectedUser.User_Status === 1
                        ? 'deactivate'
                        : 'activate'}{' '}
                      this user?
                    </Typography>

                    <Box
                      sx={{
                        // flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                        marginTop: '5px',
                      }}
                    ></Box>
                  </Box>
                </DialogContent>

                <Box
                  sx={{
                    padding: '20px 24px',
                    display: 'flex',
                    columnGap: '8px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      sx={{
                        padding: 0,
                        marginRight: '16px',
                        color: Colors.slate_blue1,
                        background: Colors.anti_flash_white,
                        ':hover, :focus, :active': {
                          background: Colors.anti_flash_white,
                        },
                      }}
                      onClick={() => {
                        setSelectedUser({});
                        setConfirmationModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      sx={{
                        padding: 0,
                      }}
                      onClick={handleUserStatusUpdate}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Dialog>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TeamComp;
