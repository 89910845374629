import generalMiddleware from "./middleware/middleware";
import { Response } from "./middleware/format-response";

export const Invitation = (
  paramsData: any
): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Message/SendEmail?FromName=${paramsData.FromName}&FromMail=${paramsData.FromMail}&Campaign_Name=${paramsData.Campaign_Name}&Enterprise_Name=${paramsData.Enterprise_Name}`,
    data: paramsData.bodyData,
    method: "POST",
  });
export const copyInvitation = (campaign_id: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Campaign/SaveInterviewCampaignLink?pk_Campaign_ID=${campaign_id}`,
    method: 'POST',
  });
export const sendUserInvitation = (paramsData: any): Promise<Response<string>> =>
  generalMiddleware.request({
    url: `/Message/SendInviteUser?enterpriseID=${paramsData.enterpriseId}&userAccessLevelId=${paramsData.accesslevelId}&userAccessLevel=${paramsData.role}&FromName=${paramsData.FromName}&FromMail=${paramsData.FromMail}&Enterprise_Name=${paramsData.Enterprise_Name}`,
    method: 'POST',
    data: paramsData.bodyData,
  });

  export const checkEmailExit = (
    emailId: any,
    enterpriseID: number
  ): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/Account/isEmailExists?email=${emailId}&id=${enterpriseID}`,
      method: 'POST',
    });
  export const generateDescToSendMail = (
    status: string,
  ): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/Snippet/Get/${status}`,
      method: 'GET',
    });
  // export const generateDescToSendMail = (
  //   candidateName: string,
  //   status: string,
  //   jobName: string,
  //   enterpriseName:string
  // ): Promise<Response<string>> =>
  //   generalMiddleware.request({
  //     url: `/Snippet/GetEmailContent/${candidateName}/${status}/${jobName}/${enterpriseName}`,
  //     method: 'GET',
  //   });
  export const sendEmailToCandidate = (paramsData: any): Promise<Response<string>> =>
    generalMiddleware.request({
      url: `/Message/sendApplicationResponse?enterpriseID=${paramsData.enterpriseId}&FromMail=${paramsData.FromMail}&FromName=${paramsData.FromName}&title=${paramsData.title}&jobName=${paramsData.jobName}`,
      method: 'POST',
      data: paramsData.bodyData,
    });
