import { ComponentsOverrides } from '@mui/material/styles/overrides';

export const TypographyThemeStyles: {
  styleOverrides: ComponentsOverrides['MuiTypography'];
} = {
  styleOverrides: {
    body1: {
      fontSize: 18,
      paddingTop: 5,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 2,
      //   letterSpacing: -0.5,
    },
    h4:{
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '39px',
    },
    h5:{
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '30px',
    },
    paragraph:{
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
    }
  },
};
