export enum Urls {
  Onboarding = '/onboarding',
  Dashboard = '/dashboard',
  User = '/user',
  Campaign = '/campaign',
  CreateCampaign = '/campaign/create',
  CreateCampaignTitle = '/campaign/title',
  InstantCamaign = '/campaign/instant',
  InstantCreateCamaign = '/campaign/instant/create',
  InstantInviteCamaign = '/campaign/instant/invite',
  GenerateQuestion = '/campaign/generate',
  EditCampaign = '/campaign/edit',
  Template = '/templates',
  CustomForm = '/custom-form',
  Clients = '/clients',
  ClientsEdit = '/clients/edit',
  ClientCreate = '/clients/create',
  CompanyInformation = '/company',
  EditCompanyInformation = '/company/edit',
  CandidateList = '/candidates',
  CandidateInformation = '/candidates/info',
  Privacypage = '/privacy-policy',
  TermsofSrvice = '/terms-of-condition',
  SelectPackages = '/packages',
  BillingMethods = '/billing-method',
}
